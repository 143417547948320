import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import { Box, Button, Card, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import SmartEditList from "src/components/misc/SmartEditList";
import SmartEditSwitch from "src/components/misc/SmartEditSwitch";
import SmartEditTextField from "src/components/misc/SmartEditTextField";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { updateCompany } from "src/slices/companySlice";

export default function CompanySettingsPage() {
  const dispatch = useAppDispatch();
  const company = useAppSelector((state) => state.company.company);
  const { enqueueSnackbar } = useSnackbar();

  if (!company) {
    return <LoadingPage message="Getting company details" />;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Card variant="outlined" sx={{ p: 2, mb: 2 }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          General
        </Typography>
        <SmartEditSwitch
          label="Pause dash for non-admins"
          value={company.disable_dash ?? false}
          onSave={(value) => {
            dispatch(updateCompany({ disable_dash: value }));
          }}
        />
        <SmartEditSwitch
          label="Allow clients to select their preferred trainer identity"
          value={company.allow_female_picking ?? false}
          onSave={(value) => {
            dispatch(updateCompany({ allow_female_picking: value }));
          }}
        />
        <SmartEditSwitch
          label="Force CAB clients to use dev"
          value={company.cab_clients_use_dev ?? false}
          onSave={(value) => {
            dispatch(updateCompany({ cab_clients_use_dev: value }));
          }}
        />
        <SmartEditTextField
          label="Latest iOS version"
          value={company.latest_version_ios}
          onSave={(value) => {
            dispatch(
              updateCompany({
                latest_version_ios: value as string,
              }),
            );
          }}
        />
        <SmartEditTextField
          label="Latest Android version"
          value={company.latest_version_android}
          onSave={(value) => {
            dispatch(
              updateCompany({
                latest_version_android: value as string,
              }),
            );
          }}
        />
        <SmartEditList
          label="Test labels"
          values={company.metrics_test_labels}
          onSave={(values) => {
            dispatch(updateCompany({ metrics_test_labels: values }));
          }}
        />
        <Button
          startIcon={<QuestionAnswerRoundedIcon />}
          onClick={() => {
            dispatch(
              updateCompany({
                last_trainer_survey_date: new Date().toISOString(),
              }),
            );

            enqueueSnackbar("Survey sent", { variant: "success" });
          }}
          sx={{ mt: 2 }}
        >
          Send trainer survey to all trainers
        </Button>
      </Card>
    </Box>
  );
}
