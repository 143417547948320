import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { isBlank } from "@trainwell/features";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { dismissActionItemWithType } from "src/slices/actionItemSlice";
import { selectSelectedChat, sendTextMessage } from "src/slices/chatSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

export default function SendPrecallSurveyButton() {
  const dispatch = useAppDispatch();
  const selectedChat = useAppSelector(selectSelectedChat);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [sentSurvey, setSentSurvey] = useState(false);
  const [preCallSurveyDialogOpen, setPreCallSurveyDialogOpen] = useState(false);
  const [preCallSurveyMessage, setPreCallSurveyMessage] = useState(
    trainer?.pre_call_survey_checkin,
  );

  return (
    <>
      <Button
        variant="contained"
        size="small"
        startIcon={<SendRoundedIcon />}
        onClick={() => {
          if (!selectedChat) {
            return;
          }

          setSentSurvey(true);
          setPreCallSurveyDialogOpen(true);
        }}
        sx={{ mb: 1 }}
        disabled={sentSurvey}
      >
        Send checkin-call survey
      </Button>
      <Dialog
        open={preCallSurveyDialogOpen}
        onClose={() => {
          setPreCallSurveyDialogOpen(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Send a pre-call survey</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Send a survey to {selectedChat?.clientName} before their check-in
            call to save time on the call.
          </DialogContentText>
          <TextField
            multiline
            minRows={2}
            fullWidth
            label="Message"
            value={preCallSurveyMessage}
            onChange={(event) => {
              setPreCallSurveyMessage(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              if (!selectedChat) {
                return;
              }

              dispatch(
                dismissActionItemWithType({
                  userId: selectedChat.id,
                  type: "send_pre_check_in_survey",
                }),
              );

              setPreCallSurveyDialogOpen(false);
            }}
          >
            This client doesn&apos;t need a message
          </Button>
          <Button
            variant="contained"
            startIcon={<SendRoundedIcon />}
            onClick={() => {
              if (preCallSurveyMessage && selectedChat?.id) {
                dispatch(
                  sendTextMessage({
                    text: preCallSurveyMessage,
                    userId: selectedChat.id,
                  }),
                );

                dispatch(
                  dismissActionItemWithType({
                    userId: selectedChat.id,
                    type: "send_pre_check_in_survey",
                  }),
                );

                setPreCallSurveyDialogOpen(false);
              }
            }}
            disabled={isBlank(preCallSurveyMessage)}
          >
            Send message
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
