import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Test } from "@trainwell/types";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "./store";

export const fetchAllTests = createAsyncThunk(
  "tests/fetchAllTests",
  async () => {
    const response = await api.tests.getAll();
    return response;
  },
);

export const createTest = createAsyncThunk(
  "tests/createTest",
  async (data: Omit<Test, "id">) => {
    const response = await api.tests.create(data);
    return response;
  },
);

export const editTest = createAsyncThunk(
  "tests/editTest",
  async (obj: { testId: string; data: Partial<Test> & Omit<Test, "id"> }) => {
    const { testId, data } = obj;
    const response = await api.tests.update(testId, data);
    return response;
  },
);

export const archiveTest = createAsyncThunk(
  "tests/archiveTest",
  async (testId: string) => {
    const response = await api.tests.archive(testId);
    return response;
  },
);

// Define a type for the slice state
interface TestsState {
  tests: Test[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
}

// Define the initial state using that type
const initialState: TestsState = {
  tests: [],
  status: "idle",
  error: undefined,
};

export const testsSlice = createSlice({
  name: "tests",
  initialState,
  reducers: {
    resetTests: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllTests.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchAllTests.fulfilled, (state, action) => {
      console.log("Redux: Got all tests");
      state.status = "succeeded";

      const abTests = action.payload;

      abTests.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });

      state.tests = abTests;
    });
    builder.addCase(fetchAllTests.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(createTest.fulfilled, (state, action) => {
      console.log("Redux: Test created");

      state.tests.push(action.payload);
    });

    builder.addCase(editTest.fulfilled, (state, action) => {
      console.log("Redux: Test edited");
      const index = state.tests.findIndex(
        (test) => test.id === action.payload.test.id,
      );
      if (index !== -1) {
        state.tests[index] = action.payload.test;
      }
    });

    builder.addCase(archiveTest.fulfilled, (state, action) => {
      console.log("Redux: Test archived");

      const testId = action.meta.arg;

      const index = state.tests.findIndex((test) => test.id === testId);

      if (index !== -1) {
        state.tests[index].date_archived = new Date().toISOString();
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetTests } = testsSlice.actions;

export default testsSlice.reducer;

export const selectTestById = (state: RootState, testID: string) =>
  state.tests.tests.find((test) => test.id === testID);
