import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  selectCheckpointText,
  selectIsAuditing,
  selectPrimaryTrainer,
} from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";
import ControlBar from "../misc/ControlBar";
import ActionItemSelector from "./ActionItemSelector";
import ClientSelector from "./ClientSelector";

const actionDrawerColumnWidth = 300;

export default function HomePage() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const dashMode = useAppSelector((state) => state.app.dashMode);
  const checkpointText = useAppSelector(selectCheckpointText);
  const [open, setOpen] = useState(
    trainer?.settings?.action_item_panel_open ?? false,
  );
  const isAuditing = useAppSelector(selectIsAuditing);

  console.log("Render: home");

  return (
    <>
      <ControlBar
        leftContent={
          dashMode === "default" && (
            <>
              <IconButton
                aria-label="toggle drawer"
                size="small"
                onClick={() => {
                  const newVal = !open;

                  setOpen(newVal);

                  if (!isAuditing) {
                    dispatch(
                      updateTrainer({
                        trainer_id: trainer!.trainer_id,
                        settings: {
                          ...trainer!.settings,
                          action_item_panel_open: newVal,
                        },
                      }),
                    );
                  }
                }}
                sx={{
                  mr: 2,
                  color: (theme) => theme.palette.backgroundDark.contrastText,
                }}
              >
                {open ? <ChevronLeftRoundedIcon /> : <MenuRoundedIcon />}
              </IconButton>
              <Typography
                sx={{
                  color: (theme) => theme.palette.backgroundDark.contrastText,
                }}
              >
                {checkpointText}
              </Typography>
            </>
          )
        }
      />
      <Box sx={{ display: "flex" }}>
        {dashMode === "default" && open && (
          <Box
            sx={{
              width: actionDrawerColumnWidth,
              flexShrink: 0,
            }}
          >
            <ActionItemSelector />
          </Box>
        )}
        <main style={{ flex: 1 }}>
          <ClientSelector />
        </main>
      </Box>
    </>
  );
}
