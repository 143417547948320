import type { SxProps, Theme } from "@mui/material";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";

type InputType = "text" | "number" | "email";

type Props = {
  label: string;
  value: string | number | undefined;
  inputType?: InputType;
  multiline?: boolean;
  onSave: (newValue: string | number) => void;
  validationFunction?: (value: string) => boolean;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  helperText?: string;
};

export default function SmartEditTextField({
  label,
  value,
  inputType = "text",
  multiline,
  onSave,
  validationFunction,
  sx = [],
  disabled,
  helperText,
}: Props) {
  const [isHovering, setIsHovering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(value ?? "");

  function save() {
    if (!isEditing) {
      return;
    }

    setIsEditing(false);

    if (inputType === "number") {
      onSave(Number(newValue));
    } else {
      onSave(newValue);
    }
  }

  const hasError =
    validationFunction && !validationFunction(newValue as string);

  return (
    <Box
      onMouseEnter={() => {
        if (disabled) {
          return;
        }

        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      sx={Array.isArray(sx) ? sx : [sx]}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
        <Typography variant="h6">{label}</Typography>
        <Box
          sx={{
            opacity: isHovering || isEditing ? undefined : 0,
            pointerEvents: isHovering || isEditing ? undefined : "none",
          }}
        >
          <Button
            variant={isEditing ? "contained" : "text"}
            size="small"
            sx={{
              ml: 1,
              lineHeight: 1.25,
              px: 1,
              py: 0.5,
              minWidth: "0px",
            }}
            onClick={() => {
              if (isEditing) {
                save();
              } else {
                setIsEditing(true);
              }
            }}
            disabled={isEditing && hasError}
          >
            {isEditing ? "Save" : "Edit"}
          </Button>
          {isEditing && (
            <Button
              variant="text"
              size="small"
              sx={{
                ml: 1,
                lineHeight: 1.25,
                px: 1,
                py: 0.5,
                minWidth: "0px",
              }}
              onClick={() => {
                setIsEditing(false);
                setNewValue(value ?? "");
              }}
            >
              Cancel
            </Button>
          )}
        </Box>
      </Box>
      {isEditing ? (
        <TextField
          size="small"
          fullWidth
          multiline={multiline}
          value={newValue}
          type={inputType}
          onChange={(event) => {
            setNewValue(event.target.value);
          }}
          onKeyDown={(event) => {
            if (!multiline && event.key === "Enter") {
              save();
            }
          }}
          error={hasError}
          helperText={hasError ? "Invalid" : helperText}
        />
      ) : (
        <Typography>{value}</Typography>
      )}
    </Box>
  );
}
