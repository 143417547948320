import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  campaignRatings,
  campaignSponsorTypes,
  campaignTypes,
  isBlank,
  useCreateCampaign,
  useUpdateCampaign,
  type Campaign,
} from "@trainwell/features";
import { isValidUuidv4 } from "@trainwell/workout-lib";
import { startOfDay } from "date-fns";
import { useEffect, useState } from "react";
import {
  getDateForDatabase,
  getDateFromDatabase,
  getZeroedOutUtcDate,
} from "src/lib/date";
import { ShortUrlEditDialog } from "../short-urls/ShortUrlEditDialog";

type CampaignData = Pick<
  Campaign,
  | "type"
  | "sponsor_type"
  | "is_fitness_themed"
  | "is_faceless"
  | "is_health_themed"
  | "is_new_partner"
  | "is_shown_working_out"
  | "cost"
  | "genuineness"
  | "integration_smoothness"
  | "passion"
  | "detail_of_experience"
  | "trainer_relationship"
  | "progress_shared"
  | "expected_views"
  | "url_content"
  | "date_content_released"
  | "short_url_id"
>;

const blankCampaignData: CampaignData = {
  type: "youtube",
  sponsor_type: null,
  is_fitness_themed: false,
  is_faceless: false,
  is_health_themed: false,
  is_new_partner: false,
  is_shown_working_out: false,
  genuineness: null,
  integration_smoothness: null,
  passion: null,
  detail_of_experience: null,
  trainer_relationship: null,
  progress_shared: null,
  cost: 0,
  expected_views: 0,
  url_content: "",
  date_content_released: new Date(),
  short_url_id: "",
};

type Props = {
  influencerId: string;
  open: boolean;
  onClose: () => void;
  defaultCampaign?: Campaign;
};

export function CampaignEditDialog({
  influencerId,
  open,
  onClose,
  defaultCampaign,
}: Props) {
  const [saving, setSaving] = useState(false);
  const [shortUrlDialogOpen, setShortUrlDialogOpen] = useState(false);
  const [shortUrlMode, setShortUrlMode] = useState<"new" | "existing" | null>(
    defaultCampaign ? "existing" : null,
  );
  const [campaignData, setCampaignData] = useState<CampaignData>(
    defaultCampaign ?? blankCampaignData,
  );
  const updateCampaignMutation = useUpdateCampaign();
  const createCampaignMutation = useCreateCampaign();

  useEffect(() => {
    if (!open) {
      setCampaignData(defaultCampaign ?? blankCampaignData);
      setShortUrlDialogOpen(false);
      setShortUrlMode(defaultCampaign ? "existing" : null);
    }
  }, [open, defaultCampaign]);

  const handleChangeCampaignData = (data: Partial<CampaignData>) => {
    setCampaignData({ ...campaignData, ...data });
  };

  const isDataValid =
    !isBlank(campaignData.url_content) &&
    !isBlank(campaignData.short_url_id) &&
    isValidUuidv4(campaignData.short_url_id) &&
    Boolean(campaignData.sponsor_type);

  return (
    <>
      <Dialog
        open={open && !shortUrlDialogOpen}
        onClose={() => {
          if (saving) {
            return;
          }

          onClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {defaultCampaign ? "Edit" : "Create"} campaign
        </DialogTitle>
        <DialogContent>
          <Stack direction={"column"} spacing={2} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Campaign type</InputLabel>
              <Select
                value={campaignData.type}
                label="Campaign type"
                onChange={(event) => {
                  handleChangeCampaignData({
                    type: event.target.value as any,
                  });
                }}
              >
                {Object.keys(campaignTypes).map((type) => (
                  <MenuItem key={type} value={type}>
                    {campaignTypes[type]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Sponsor type</InputLabel>
              <Select
                value={campaignData.sponsor_type}
                label="Sponsor type"
                onChange={(event) => {
                  handleChangeCampaignData({
                    sponsor_type: (event.target.value as any) || null,
                  });
                }}
              >
                {Object.keys(campaignSponsorTypes).map((sponsorType) => (
                  <MenuItem key={sponsorType} value={sponsorType}>
                    {campaignSponsorTypes[sponsorType]}
                  </MenuItem>
                ))}
                <MenuItem value={""}>None</MenuItem>
              </Select>
            </FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={campaignData.is_fitness_themed ?? false}
                    onChange={(event) => {
                      handleChangeCampaignData({
                        is_fitness_themed: event.target.checked,
                      });
                    }}
                  />
                }
                label="Fitness themed"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={campaignData.is_health_themed ?? false}
                    onChange={(event) => {
                      handleChangeCampaignData({
                        is_health_themed: event.target.checked,
                      });
                    }}
                  />
                }
                label="Health themed"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={campaignData.is_new_partner ?? false}
                    onChange={(event) => {
                      handleChangeCampaignData({
                        is_new_partner: event.target.checked,
                      });
                    }}
                  />
                }
                label="New partner"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={campaignData.is_faceless ?? false}
                    onChange={(event) => {
                      handleChangeCampaignData({
                        is_faceless: event.target.checked,
                      });
                    }}
                  />
                }
                label="Faceless"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={campaignData.is_shown_working_out ?? false}
                    onChange={(event) => {
                      handleChangeCampaignData({
                        is_shown_working_out: event.target.checked,
                      });
                    }}
                  />
                }
                label="Shown working out"
              />
            </FormGroup>
            <TextField
              label="Expected number of views"
              value={campaignData.expected_views}
              onChange={(event) => {
                handleChangeCampaignData({
                  expected_views: Number(event.target.value),
                });
              }}
              fullWidth
              type="number"
            />
            <TextField
              label="Content url"
              placeholder="https://youtube.com"
              value={campaignData.url_content}
              onChange={(event) => {
                handleChangeCampaignData({
                  url_content: event.target.value,
                });
              }}
              fullWidth
            />
            <TextField
              label="Cost"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={campaignData.cost}
              onChange={(event) => {
                handleChangeCampaignData({
                  cost: Number(event.target.value),
                });
              }}
              fullWidth
            />
            <DatePicker
              label="Content release date"
              value={
                campaignData.date_content_released
                  ? getDateFromDatabase(
                      campaignData.date_content_released as string,
                    )
                  : new Date()
              }
              onChange={(newValue) => {
                const transformedDate = getZeroedOutUtcDate(
                  getDateForDatabase(startOfDay(newValue!).toISOString()),
                );

                handleChangeCampaignData({
                  date_content_released: transformedDate ?? null,
                });
              }}
            />
            {!shortUrlMode && !defaultCampaign && (
              <Stack direction={"row"} spacing={2}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setShortUrlMode("new");
                    setShortUrlDialogOpen(true);
                  }}
                >
                  New short url
                </Button>
                <Button
                  size="small"
                  variant="text"
                  onClick={() => {
                    setShortUrlMode("existing");
                  }}
                >
                  Existing short url
                </Button>
              </Stack>
            )}
            {shortUrlMode === "existing" && (
              <TextField
                label="Short url id"
                value={campaignData.short_url_id}
                onChange={(event) => {
                  handleChangeCampaignData({
                    short_url_id: event.target.value,
                  });
                }}
                fullWidth
              />
            )}
            <Typography variant="h3">Subjective quality</Typography>
            <FormControl fullWidth size="small">
              <InputLabel>Genuineness</InputLabel>
              <Select
                value={campaignData.genuineness ?? ""}
                label="Genuineness"
                onChange={(event) => {
                  handleChangeCampaignData({
                    genuineness: (event.target.value || null) as any,
                  });
                }}
              >
                {Object.keys(campaignRatings).map((rating) => (
                  <MenuItem key={rating} value={rating}>
                    {campaignRatings[rating]}
                  </MenuItem>
                ))}
                <MenuItem value={""}>None</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel>Integration smoothness</InputLabel>
              <Select
                value={campaignData.integration_smoothness ?? ""}
                label="Integration smoothness"
                onChange={(event) => {
                  handleChangeCampaignData({
                    integration_smoothness: (event.target.value || null) as any,
                  });
                }}
              >
                {Object.keys(campaignRatings).map((rating) => (
                  <MenuItem key={rating} value={rating}>
                    {campaignRatings[rating]}
                  </MenuItem>
                ))}
                <MenuItem value={""}>None</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel>Passion</InputLabel>
              <Select
                value={campaignData.passion ?? ""}
                label="Passion"
                onChange={(event) => {
                  handleChangeCampaignData({
                    passion: (event.target.value || null) as any,
                  });
                }}
              >
                {Object.keys(campaignRatings).map((rating) => (
                  <MenuItem key={rating} value={rating}>
                    {campaignRatings[rating]}
                  </MenuItem>
                ))}
                <MenuItem value={""}>None</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel>Detail of experience</InputLabel>
              <Select
                value={campaignData.detail_of_experience ?? ""}
                label="Detail of experience"
                onChange={(event) => {
                  handleChangeCampaignData({
                    detail_of_experience: (event.target.value || null) as any,
                  });
                }}
              >
                {Object.keys(campaignRatings).map((rating) => (
                  <MenuItem key={rating} value={rating}>
                    {campaignRatings[rating]}
                  </MenuItem>
                ))}
                <MenuItem value={""}>None</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel>Trainer relationship</InputLabel>
              <Select
                value={campaignData.trainer_relationship ?? ""}
                label="Trainer relationship"
                onChange={(event) => {
                  handleChangeCampaignData({
                    trainer_relationship: (event.target.value || null) as any,
                  });
                }}
              >
                {Object.keys(campaignRatings).map((rating) => (
                  <MenuItem key={rating} value={rating}>
                    {campaignRatings[rating]}
                  </MenuItem>
                ))}
                <MenuItem value={""}>None</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel>Progress shared</InputLabel>
              <Select
                value={campaignData.progress_shared ?? ""}
                label="Progress shared"
                onChange={(event) => {
                  handleChangeCampaignData({
                    progress_shared: (event.target.value || null) as any,
                  });
                }}
              >
                {Object.keys(campaignRatings).map((rating) => (
                  <MenuItem key={rating} value={rating}>
                    {campaignRatings[rating]}
                  </MenuItem>
                ))}
                <MenuItem value={""}>None</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              onClose();
            }}
            disabled={saving}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={saving}
            disabled={!isDataValid}
            onClick={async () => {
              setSaving(true);

              if (defaultCampaign) {
                updateCampaignMutation.mutate({
                  campaignId: defaultCampaign._id,
                  data: {
                    type: campaignData.type,
                    sponsor_type: campaignData.sponsor_type,
                    url_content: campaignData.url_content,
                    cost: campaignData.cost,
                    expected_views: campaignData.expected_views,
                    is_fitness_themed: campaignData.is_fitness_themed,
                    is_new_partner: campaignData.is_new_partner,
                    is_faceless: campaignData.is_faceless,
                    is_health_themed: campaignData.is_health_themed,
                    is_shown_working_out: campaignData.is_shown_working_out,
                    detail_of_experience: campaignData.detail_of_experience,
                    genuineness: campaignData.genuineness,
                    integration_smoothness: campaignData.integration_smoothness,
                    passion: campaignData.passion,
                    progress_shared: campaignData.progress_shared,
                    trainer_relationship: campaignData.trainer_relationship,
                    date_content_released: campaignData.date_content_released,
                    short_url_id: campaignData.short_url_id,
                  },
                });
              } else {
                createCampaignMutation.mutate({
                  data: {
                    date_content_released: campaignData.date_content_released,
                    influencer_id: influencerId,
                    type: campaignData.type,
                    sponsor_type: campaignData.sponsor_type,
                    url_content: campaignData.url_content,
                    cost: campaignData.cost,
                    expected_views: campaignData.expected_views,
                    is_fitness_themed: campaignData.is_fitness_themed,
                    is_new_partner: campaignData.is_new_partner,
                    is_shown_working_out: campaignData.is_shown_working_out,
                    short_url_id: campaignData.short_url_id,
                    is_faceless: campaignData.is_faceless,
                    is_health_themed: campaignData.is_health_themed,
                    detail_of_experience: campaignData.detail_of_experience,
                    genuineness: campaignData.genuineness,
                    integration_smoothness: campaignData.integration_smoothness,
                    passion: campaignData.passion,
                    progress_shared: campaignData.progress_shared,
                    trainer_relationship: campaignData.trainer_relationship,
                  },
                });
              }

              onClose();
              setSaving(false);
            }}
          >
            {defaultCampaign ? "Save" : "Create"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ShortUrlEditDialog
        open={shortUrlDialogOpen}
        onClose={(newShortUrl) => {
          if (newShortUrl) {
            handleChangeCampaignData({
              short_url_id: newShortUrl.id,
            });
          } else {
            setShortUrlMode(null);
          }

          setShortUrlDialogOpen(false);
        }}
      />
    </>
  );
}
