import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Tab, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ClientName from "src/components/misc/ClientName";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { fetchClientEdit } from "src/slices/clientEditSlice";
import { ClientMoreButton } from "./ClientMoreButton";
import TabAudits from "./TabAudits";
import TabEvents from "./TabEvents";
import TabInfo from "./TabInfo";
import TabTickets from "./TabTickets";

export function ClientPageAdmin() {
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const clientEditStatus = useAppSelector((state) => state.clientEdit.status);
  const client = useAppSelector((state) => state.clientEdit.client);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get("tab") ?? "info";

  useEffect(() => {
    if (
      (clientEditStatus === "idle" ||
        (clientEditStatus === "succeeded" && userId !== client?.user_id)) &&
      userId
    ) {
      dispatch(fetchClientEdit(userId));
    }
  }, [clientEditStatus, dispatch, userId, client?.user_id]);

  if (!client || !userId) {
    return <LoadingPage message="Getting your client" />;
  }

  return (
    <Box sx={{ mb: 6, width: "100%", maxWidth: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
          mt: 2,
          mx: 4,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            style={{
              borderRadius: "25px",
              display: "block",
            }}
            src={client.headshot_url || "/assets/profile.png"}
            alt={client.full_name}
            width={50}
            height={50}
          />
          <Box sx={{ ml: 2 }}>
            <ClientName variant="h1" userId={client.user_id} />
            <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>
              User id: {client.user_id}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="text"
            target="_blank"
            href={`/clients/${client.user_id}`}
            LinkComponent={"a"}
            endIcon={<LoginRoundedIcon />}
          >
            Ghost
          </Button>
          <ClientMoreButton userId={client.user_id} />
        </Box>
      </Box>
      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <TabList
            onChange={(event, newValue) => {
              searchParams.set("tab", newValue);
              setSearchParams(searchParams);
            }}
            aria-label="client tabs"
            sx={{
              mx: 4,
              minHeight: 32,
            }}
          >
            <Tab label="Info" value="info" sx={{ py: 1, minHeight: 32 }} />
            <Tab
              label="Tickets"
              value="tickets"
              sx={{ py: 1, minHeight: 32 }}
            />
            <Tab label="Audits" value="audits" sx={{ py: 1, minHeight: 32 }} />
            <Tab label="Events" value="events" sx={{ py: 1, minHeight: 32 }} />
          </TabList>
        </Box>
        <TabPanel value="info" sx={{ p: 0, mx: 4 }}>
          <TabInfo client={client} />
        </TabPanel>
        <TabPanel value="tickets" sx={{ p: 0, mx: 4 }}>
          <TabTickets userId={userId} />
        </TabPanel>
        <TabPanel value="audits" sx={{ p: 0, mx: 4 }}>
          <TabAudits userId={userId} />
        </TabPanel>
        <TabPanel
          value="events"
          sx={{
            p: 0,
            mx: 4,
          }}
        >
          <TabEvents userId={userId} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
