import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import SearchField from "src/components/misc/SearchField";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  sortChatsByNewest,
  sortChatsByUnanswered,
  sortChatsByUnread,
} from "src/lib/chat";
import type { Chat } from "src/slices/chatSlice";
import { selectTicketChats } from "src/slices/chatSlice";
import { fetchTicketCategories } from "src/slices/ticketsSlice";
import { selectPrimaryCoachTrainerId } from "src/slices/trainerSlice";
import ChatCell from "./ChatCell";

type SortMode = "unread" | "unanswered" | "newest";

export default function PanelTicketChats() {
  const dispatch = useAppDispatch();
  const [displayChats, setDisplayChats] = useState<Chat[]>([]);
  const ticketChats = useAppSelector(selectTicketChats);
  const [search, setSearch] = useState("");
  const trainerId = useAppSelector(selectPrimaryCoachTrainerId);
  const [chatSort, setChatSort] = useState<SortMode>("unread");
  const ticketChatStatus = useAppSelector(
    (state) => state.chat.ticketChatsStatus,
  );
  const ticketCategoriesStatus = useAppSelector(
    (state) => state.tickets.categoriesStatus,
  );

  useEffect(() => {
    if (ticketCategoriesStatus === "idle") {
      dispatch(fetchTicketCategories());
    }
  }, [ticketCategoriesStatus, dispatch]);

  useEffect(() => {
    let newChats = JSON.parse(JSON.stringify(ticketChats)) as Chat[];

    if (search) {
      // Convert special characters to normal characters
      const modifiedSearch = search
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toLowerCase();

      newChats = newChats.filter((chat) =>
        chat.clientName
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .toLowerCase()
          .includes(modifiedSearch),
      );
    }

    if (chatSort === "unread") {
      newChats.sort(sortChatsByUnread);
    } else if (chatSort === "newest") {
      newChats.sort(sortChatsByNewest);
    } else if (chatSort === "unanswered") {
      newChats.sort((a, b) => {
        return sortChatsByUnanswered(a, b, trainerId!);
      });
    }

    setDisplayChats(newChats);
  }, [search, chatSort, ticketChats, trainerId]);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          p: 1,
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <SearchField
          value={search}
          onChange={(newValue) => {
            setSearch(newValue);
          }}
          onClear={() => {
            setSearch("");
          }}
        />
        <FormControl
          size="small"
          sx={{
            ml: 2,
          }}
          fullWidth
        >
          <InputLabel id="chat-sort">Sort</InputLabel>
          <Select
            labelId="chat-sort"
            label="Sort"
            value={chatSort}
            onChange={(event) => {
              setChatSort(event.target.value as SortMode);
            }}
          >
            <MenuItem value={"unanswered" as SortMode}>Unanswered</MenuItem>
            <MenuItem value={"unread" as SortMode}>Unread</MenuItem>
            <MenuItem value={"newest" as SortMode}>Newest</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ flex: 1 }}>
        {ticketChatStatus === "loading" ? (
          <LoadingComponent message="Fetching chats" />
        ) : (
          <Virtuoso
            style={{ height: "100%" }}
            data={displayChats}
            itemContent={(index, chat) => (
              <ChatCell chat={chat} key={chat.id} />
            )}
          />
        )}
      </Box>
    </Box>
  );
}
