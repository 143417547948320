import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import {
  createShortUrl,
  isBlank,
  useInfluencer,
  useUpdateInfluencer,
  type InfluencerClient,
} from "@trainwell/features";
import { useEffect, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";

type Props = {
  influencerId: string;
  defaultInfluencerUser?: InfluencerClient;
  open: boolean;
  onClose: () => void;
};

export function InfluencerClientEditDialog({
  influencerId,
  defaultInfluencerUser,
  open,
  onClose,
}: Props) {
  const { data: influencer } = useInfluencer({
    influencerId: influencerId,
  });
  const updateInfluencerMutation = useUpdateInfluencer();
  const [forceTrainerId, setForceTrainerId] = useState<string | null>(null);
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);

  const trainerNameOptions = trainerNames
    .filter((c) => c.is_employee && !c.was_employee)
    .sort((a, b) => a.full_name.localeCompare(b.full_name));

  const [userData, setUserData] = useState<
    Pick<InfluencerClient, "user_id" | "temporary_name">
  >(
    defaultInfluencerUser ?? {
      temporary_name: "",
    },
  );

  useEffect(() => {
    if (!open) {
      setUserData(
        defaultInfluencerUser ?? {
          temporary_name: "",
        },
      );
      setForceTrainerId(null);
    }
  }, [open, defaultInfluencerUser]);

  const handleChangeUserData = (data: {
    temporary_name?: string;
    user_id?: string;
  }) => {
    setUserData({ ...userData, ...data });
  };

  const isDataValid =
    defaultInfluencerUser ||
    !isBlank(userData.user_id) ||
    Boolean(forceTrainerId);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {defaultInfluencerUser ? "Edit" : "Create"} influencer client
      </DialogTitle>
      <DialogContent>
        <Stack direction={"column"} spacing={2} sx={{ mt: 2 }}>
          {!userData.user_id && (
            <TextField
              label="Temporary name (optional)"
              helperText="Helps keep track of multiple sign up urls"
              value={userData.temporary_name}
              disabled={Boolean(defaultInfluencerUser?.user_id)}
              onChange={(event) => {
                handleChangeUserData({
                  temporary_name: event.target.value,
                });
              }}
              fullWidth
            />
          )}
          {!userData.user_id && (
            <Autocomplete
              fullWidth
              options={trainerNameOptions}
              onChange={(event, value) => {
                setForceTrainerId(value?.trainer_id ?? null);
              }}
              getOptionLabel={(option) => option.full_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Trainer"
                  placeholder="Select a trainer"
                />
              )}
            />
          )}
          {!forceTrainerId && (
            <TextField
              label="User id"
              helperText="Leave blank to generate an invite url"
              value={userData.user_id}
              disabled={Boolean(defaultInfluencerUser?.user_id)}
              onChange={(event) => {
                handleChangeUserData({
                  user_id: event.target.value,
                });
              }}
              fullWidth
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!isDataValid}
          onClick={async () => {
            const users = [...(influencer?.users ?? [])].filter(
              (u) => u.id !== defaultInfluencerUser?.id,
            );

            const newUser: InfluencerClient = {
              id: crypto.randomUUID(),
              ...defaultInfluencerUser,
              ...userData,
            };

            if (!newUser.signup_short_url_id && !defaultInfluencerUser) {
              // Generate signup url
              const shortUrl = await createShortUrl({
                data: {
                  long_url: `https://join.trainwell.net/?payment_type=free&skip_call=true&source=influencer_${influencer?.source_ids[0]}&trainer_id=${forceTrainerId}&utm_source=influencer&utm_medium=direct&utm_campaign=${influencer?.source_ids[0]}_free_signup&pair_influencer_user_id=${newUser.id}`,
                },
              });

              newUser.signup_short_url_id = shortUrl.id;
            }

            users.push(newUser);

            updateInfluencerMutation.mutate({
              influencerId: influencerId,
              data: {
                users,
              },
            });

            onClose();
          }}
        >
          {defaultInfluencerUser ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
