import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { Box, CardActionArea, Divider, Typography, alpha } from "@mui/material";
import type { Audit } from "@trainwell/types";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectClientById } from "src/slices/clientsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

type Props = {
  audit: Audit;
  isSelected: boolean;
};

export default function AuditCell({ audit, isSelected }: Props) {
  const navigate = useNavigate();
  const client = useAppSelector((state) =>
    selectClientById(state, audit.subject_user_id!),
  );
  const trainer = useAppSelector(selectPrimaryTrainer);

  return (
    <>
      <CardActionArea
        sx={{
          p: 1,
          backgroundColor: (theme) =>
            isSelected ? alpha(theme.palette.primary.main, 0.2) : undefined,
        }}
        onClick={() => {
          navigate("/audits/" + audit.id);
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              style={{
                borderRadius: "15px",
              }}
              width={30}
              height={30}
              src={client?.headshot_url || "/assets/profile.png"}
              alt={client?.full_name ?? ""}
            />
            <Box sx={{ ml: 1 }}>
              <Typography>{client?.full_name}</Typography>
              <Typography variant="overline">
                {format(new Date(audit.date_created), "MMMM do, yyyy")}
              </Typography>
            </Box>
          </Box>
          {trainer?.trainer_id !== audit.auditor_trainer_id && (
            <AdminPanelSettingsIcon />
          )}
        </Box>
      </CardActionArea>
      <Divider />
    </>
  );
}
