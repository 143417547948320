import { Draggable } from "@hello-pangea/dnd";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import {
  Box,
  Button,
  CardActionArea,
  IconButton,
  Typography,
} from "@mui/material";
import type { HabitTemplate } from "@trainwell/types";
import { useSnackbar } from "notistack";
import { useState } from "react";
import HabitDot from "src/components/misc/HabitDot";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";
import { isProgramHabit } from "src/lib/habits";
import {
  duplicateHabitTemplate,
  removeHabitTemplate,
  restoreHabitTemplate,
} from "src/slices/clientSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import HabitEditDialog from "./HabitEditDialog";

type Props = {
  habit: HabitTemplate;
  index: number;
};

export default function HabitCell({ habit, index }: Props) {
  const dispatch = useAppDispatch();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const trainer = useAppSelector(selectPrimaryTrainer);

  const isCoachTemplate = habit.trainer_id && !habit.user_id;
  const isTrainwellTemplate = !habit.trainer_id && !habit.user_id;

  const canEditGlobalHabits = trainerHasAccess(
    trainer?.access_roles,
    "global_habits",
  );

  function content(isDragging: boolean) {
    return (
      <CardActionArea
        disabled={!(habit.user_id || habit.trainer_id) && !canEditGlobalHabits}
        onClick={() => {
          setIsEditDialogOpen(true);
        }}
        onContextMenu={(event) => {
          event.preventDefault();
          setIsEditDialogOpen(true);
        }}
      >
        <Box
          sx={{
            p: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: 1,
            borderColor: (theme) =>
              isDragging ? theme.palette.primary.main : "divider",
            backgroundColor: (theme) => theme.palette.background.paper,
            borderWidth: isDragging ? "2px" : undefined,
            borderStyle: isDragging ? "solid" : "none",
            borderBottomStyle: "solid",
            borderRadius: isDragging ? 1 : undefined,
            boxShadow: isDragging ? 6 : undefined,
          }}
        >
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {habit.trainer_id && (
                <LocalLibraryRoundedIcon
                  sx={{
                    fontSize: 14,
                    mr: 0.5,
                    color: (theme) => theme.palette.text.secondary,
                    // color: (theme) => getHabitColor(habit.type, theme)
                  }}
                />
              )}
              {!habit.trainer_id && !habit.user_id && (
                <LanguageRoundedIcon
                  sx={{
                    fontSize: 14,
                    mr: 0.5,
                    color: (theme) => theme.palette.text.secondary,
                    // color: (theme) => getHabitColor(habit.type, theme)
                  }}
                />
              )}
              <HabitDot
                type={habit.type}
                complete
                size="small"
                sx={{ mr: 1, flexShrink: 0 }}
              />
              <Typography>{habit.name}</Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {habit.notes_coach_default}
            </Typography>
          </Box>
          {!isProgramHabit(habit) && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {(habit.user_id || habit.trainer_id) && (
                <IconButton
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    dispatch(
                      duplicateHabitTemplate({
                        habitTemplateId: habit.id,
                        index: index,
                      }),
                    );
                  }}
                  size="small"
                >
                  <ContentCopyRoundedIcon fontSize="inherit" />
                </IconButton>
              )}
              {(habit.user_id || habit.trainer_id || canEditGlobalHabits) && (
                <IconButton
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  onClick={(event) => {
                    event.stopPropagation();

                    dispatch(removeHabitTemplate(habit.id)).then(() => {
                      enqueueSnackbar(`Deleted ${habit.name}`, {
                        variant: "success",
                        action: (key) => (
                          <>
                            <Button
                              onClick={() => {
                                closeSnackbar(key);
                                dispatch(
                                  restoreHabitTemplate({
                                    habitTemplateId: habit.id,
                                    index: index,
                                  }),
                                );
                              }}
                              color="inherit"
                              variant="text"
                            >
                              Undo
                            </Button>
                            <Button
                              color="inherit"
                              variant="text"
                              onClick={() => {
                                closeSnackbar(key);
                              }}
                            >
                              Dismiss
                            </Button>
                          </>
                        ),
                      });
                    });
                  }}
                  size="small"
                  color="error"
                >
                  <DeleteRoundedIcon fontSize="inherit" />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      </CardActionArea>
    );
  }

  return (
    <>
      <Draggable
        draggableId={
          isCoachTemplate || isTrainwellTemplate
            ? `template.${habit.id}`
            : `habit.${habit.id}`
        }
        index={index}
        key={habit.id}
        disableInteractiveElementBlocking
      >
        {(provided, snapshot) => (
          <>
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              style={{
                ...provided.draggableProps.style,
                transform: snapshot.isDragging
                  ? provided.draggableProps.style?.transform
                  : "translate(0px, 0px)",
              }}
            >
              {content(snapshot.isDragging)}
            </div>
            {snapshot.isDragging && (
              <div style={{ transform: "none !important" }}>
                {content(false)}
              </div>
            )}
          </>
        )}
      </Draggable>
      {(habit.user_id || habit.trainer_id || canEditGlobalHabits) && (
        <HabitEditDialog
          open={isEditDialogOpen}
          onClose={() => {
            setIsEditDialogOpen(false);
          }}
          habitTemplateId={habit.id}
          defaultHabit={habit}
        />
      )}
    </>
  );
}
