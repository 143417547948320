import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  influencerGenres,
  influencerManagers,
  useCampaigns,
  useDeleteInfluencer,
  useInfluencer,
} from "@trainwell/features";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationDialog from "src/components/misc/ConfirmationDialog";
import RestrictAccess from "src/components/misc/RestrictAccess";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { CampaignCell } from "../campaigns/CampaignCell";
import { CampaignEditDialog } from "../campaigns/CampaignEditDialog";
import { InfluencerClientCell } from "./InfluencerClientCell";
import { InfluencerClientEditDialog } from "./InfluencerClientEditDialog";
import { InfluencerEditDialog } from "./InfluencerEditDialog";

export function InfluencerPage() {
  const navigate = useNavigate();
  const { influencerId } = useParams();
  const { data: influencer, isLoading: isLoadingInfluencer } = useInfluencer({
    influencerId: influencerId ?? "",
  });
  const { data: campaigns = [], isLoading: isLoadingCampaigns } = useCampaigns({
    influencerId: influencerId ?? "",
  });
  const deleteInfluencerMutation = useDeleteInfluencer({
    mutationConfig: {
      onSuccess: () => {
        enqueueSnackbar("Deleted", {
          variant: "success",
        });

        navigate("/admin/influencers");
      },
      onError: () => {
        enqueueSnackbar("Error deleting influencer", {
          variant: "error",
        });
      },
    },
  });
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [createCampaignDialogOpen, setCreateCampaignDialogOpen] =
    useState(false);
  const [influencerClientDialogOpen, setInfluencerClientDialogOpen] =
    useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  if (isLoadingInfluencer) {
    return <LoadingPage message="Loading influencer" />;
  }

  if (!influencer) {
    return <Typography>Error finding influencer</Typography>;
  }

  return (
    <RestrictAccess location={"campaigns"} showNoAccessMessage>
      <Box sx={{ pt: 2, mb: 6 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 4,
            mx: 4,
          }}
        >
          <Box>
            <Typography variant="h1">{influencer.display_name}</Typography>
            <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>
              Influencer id: {influencer.id}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", ml: 4 }}>
            <IconButton
              size="small"
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
              sx={{
                mr: 2,
              }}
              color="error"
            >
              <DeleteRoundedIcon />
            </IconButton>
            <Button
              size="small"
              startIcon={<EditRoundedIcon />}
              onClick={() => {
                setEditDialogOpen(true);
              }}
            >
              Edit
            </Button>
          </Box>
        </Box>
        <Box sx={{ mx: 4 }}>
          <Stack direction="column" spacing={4} sx={{ mb: 8 }}>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h2" sx={{ mb: 2 }}>
                    Info
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Source ids
                  </Typography>
                  <Typography sx={{ mb: 2 }}>
                    {influencer.source_ids.join(", ")}
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }}>Manager</Typography>
                  <Typography sx={{ mb: 2 }}>
                    {influencer.manager
                      ? influencerManagers[influencer.manager]
                      : "--"}
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }}>Genres</Typography>
                  <Typography sx={{ mb: 2 }}>
                    {influencer.genres
                      ? influencer.genres
                          .map((genre) => influencerGenres[genre])
                          .join(", ")
                      : "--"}
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Percent under 25
                  </Typography>
                  <Typography>
                    {influencer.percent_under_25 !== undefined &&
                    influencer.percent_under_25 !== null
                      ? `${influencer.percent_under_25}%`
                      : "--"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h2" sx={{ mb: 2 }}>
                    Splash asset
                  </Typography>
                  {influencer.assets.splash_asset ? (
                    <img
                      height={250}
                      src={influencer.assets.splash_asset}
                      style={{
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <Typography
                      sx={{ color: (theme) => theme.palette.error.dark }}
                    >
                      No splash asset
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Card>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Typography variant="h2">Clients</Typography>
                <Button
                  variant="text"
                  size="small"
                  startIcon={<AddRoundedIcon />}
                  onClick={() => {
                    setInfluencerClientDialogOpen(true);
                  }}
                  sx={{ ml: 4 }}
                >
                  New client
                </Button>
              </Box>
              {(influencer.users ?? []).length > 0 && (
                <Stack spacing={2} divider={<Divider />}>
                  {(influencer.users ?? []).map((user) => (
                    <InfluencerClientCell
                      key={user.id}
                      influencerUser={user}
                      influencerId={influencer.id}
                    />
                  ))}
                </Stack>
              )}
            </Card>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Typography variant="h2">Campaigns</Typography>
                <Button
                  variant="text"
                  size="small"
                  startIcon={<AddRoundedIcon />}
                  onClick={() => {
                    setCreateCampaignDialogOpen(true);
                  }}
                  sx={{ ml: 4 }}
                >
                  New campaign
                </Button>
              </Box>
              {isLoadingCampaigns ? (
                <LoadingComponent />
              ) : (
                campaigns.length > 0 && (
                  <Stack spacing={4}>
                    {campaigns.map((campaign, i) => (
                      <CampaignCell key={campaign._id} campaign={campaign} />
                    ))}
                  </Stack>
                )
              )}
            </Card>
          </Stack>
        </Box>
      </Box>
      <InfluencerEditDialog
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
        }}
        defaultInfluencer={influencer}
      />
      <CampaignEditDialog
        influencerId={influencer.id}
        open={createCampaignDialogOpen}
        onClose={() => {
          setCreateCampaignDialogOpen(false);
        }}
      />
      <InfluencerClientEditDialog
        influencerId={influencer.id}
        open={influencerClientDialogOpen}
        onClose={() => {
          setInfluencerClientDialogOpen(false);
        }}
      />
      <ConfirmationDialog
        open={Boolean(deleteDialogOpen)}
        onClose={(didConfirm) => {
          if (didConfirm) {
            deleteInfluencerMutation.mutate({ influencerId: influencer.id });
          }

          setDeleteDialogOpen(false);
        }}
        variant="error"
        title="Delete influencer"
        message="Delete this influencer"
        confirmButtonText="Delete"
      />
    </RestrictAccess>
  );
}
