import type { SelectChangeEvent } from "@mui/material";
import { endOfDay, startOfDay, startOfToday, subDays } from "date-fns";
import { useEffect, useState } from "react";
import { api } from "src/lib/trainwellApi";

export enum DateRange {
  Today = 1,
  Last24Hours = 2,
  LastThreeDays = 3,
  LastWeek = 4,
  LastMonth = 5,
  Custom = 6,
}

export interface Possibles {
  possibleSourceIDs: string[];
  possibleUtmSources: string[];
  possibleUtmMediums: string[];
  possibleUtmCampaigns: string[];
  possibleUtmContents: string[];
  possibleOSNames: string[];
  possibleBrowserNames: string[];
  possibleDeviceTypes: string[];
}

export interface Comparison {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  onlyUnique: boolean;
  sourceID: string[];
  utmSource: string[];
  utmMedium: string[];
  utmCampaign: string[];
  utmContent: string[];
  osName: string[];
  browserName: string[];
  deviceType: string[];
  testsInclude: string[];
  testsExclude: string[];
  possibles: Possibles;
}

export const defaultPossible: Possibles = {
  possibleSourceIDs: [],
  possibleUtmSources: [],
  possibleUtmMediums: [],
  possibleUtmCampaigns: [],
  possibleUtmContents: [],
  possibleOSNames: [],
  possibleBrowserNames: [],
  possibleDeviceTypes: [],
};

export const defaultComparison: Comparison = {
  id: crypto.randomUUID(),
  name: "Comparison",
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
  onlyUnique: true,
  sourceID: [],
  utmSource: [],
  utmMedium: [],
  utmCampaign: [],
  utmContent: [],
  osName: [],
  browserName: [],
  deviceType: [],
  testsInclude: [],
  testsExclude: [],
  possibles: defaultPossible,
};

export const useComparison = () => {
  const [comparisons, setComparisons] = useState<Comparison[]>([
    defaultComparison,
  ]);
  const [dateRange, setDateRange] = useState(DateRange.Last24Hours);
  const [didDatesChange, setDidDatesChange] = useState(true);
  const [gettingPossibles, setGettingPossibles] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function doAsync() {
      if (dateRange !== DateRange.Custom) {
        let startDate = startOfToday().toString();

        if (dateRange === DateRange.Last24Hours) {
          startDate = subDays(new Date(), 1).toString();
        } else if (dateRange === DateRange.LastThreeDays) {
          startDate = subDays(new Date(), 3).toString();
        } else if (dateRange === DateRange.LastWeek) {
          console.log("Last 7 days");
          startDate = subDays(new Date(), 7).toString();
        } else if (dateRange === DateRange.LastMonth) {
          startDate = subDays(new Date(), 30).toString();
          console.log("Last 30 days: " + startDate);
        }

        getPossibles(comparisons, 0, startDate, new Date().toString());
      } else {
        for (let i = 0; i < comparisons.length; i++) {
          await getPossibles(
            comparisons,
            i,
            new Date(comparisons[i].startDate).toString(),
            new Date(comparisons[i].endDate).toString(),
          );
        }
      }
    }

    doAsync();
  }, [dateRange]);

  async function getPossibles(
    comparisons: Comparison[],
    comparisonIndex: number,
    startDate: string,
    endDate: string,
  ) {
    setGettingPossibles(true);

    const newComparisons = JSON.parse(
      JSON.stringify(comparisons),
    ) as Comparison[];

    const fetchedPossibles = await api.analytics.getFunnelPossibles(
      startDate,
      endDate,
    );

    const newPossibles: Possibles = {
      possibleUtmSources: fetchedPossibles.possible_utm_source.sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase()),
      ),
      possibleUtmMediums: fetchedPossibles.possible_utm_medium.sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase()),
      ),
      possibleUtmCampaigns: fetchedPossibles.possible_utm_campaign.sort(
        (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
      ),
      possibleUtmContents: fetchedPossibles.possible_utm_content.sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase()),
      ),
      possibleSourceIDs: fetchedPossibles.possible_source_id.sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase()),
      ),
      possibleDeviceTypes: fetchedPossibles.possible_device_type.sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase()),
      ),
      possibleBrowserNames: fetchedPossibles.possible_browser_name.sort(
        (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
      ),
      possibleOSNames: fetchedPossibles.possible_os_name.sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase()),
      ),
    };

    newComparisons[comparisonIndex].possibles = newPossibles;

    if (dateRange !== DateRange.Custom) {
      for (let i = 1; i < newComparisons.length; i++) {
        newComparisons[i].possibles = newPossibles;
      }
    }

    setComparisons(newComparisons);
    setGettingPossibles(false);
  }

  function handleChangeDateRange(event: SelectChangeEvent<DateRange>) {
    setDateRange(event.target.value as DateRange);
  }

  return [
    comparisons,
    gettingPossibles,
    dateRange,
    loading,
    setLoading,
    setDidDatesChange,
    setComparisons,
    getPossibles,
    handleChangeDateRange,
  ] as const;
};
