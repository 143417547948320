import DirectionsRunRoundedIcon from "@mui/icons-material/DirectionsRunRounded";
import {
  Avatar,
  Box,
  ButtonBase,
  CardActionArea,
  Dialog,
  DialogContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import type { HabitTaskMessage } from "@trainwell/types";
import { format } from "date-fns";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import HabitDot from "src/components/misc/HabitDot";
import HabitTaskPopover from "src/components/misc/HabitTaskPopover";
import { useAppSelector } from "src/hooks/stateHooks";
import { useGetWorkoutLogAlertsQuery } from "src/slices/api/logApi";

type Props = {
  message: HabitTaskMessage;
  combineBottom: boolean;
  disableInteractive?: boolean;
};

export default function HabitMessage({
  message,
  combineBottom,
  disableInteractive,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const chatVisualState = useAppSelector((state) => state.chat.chatMode);
  const [fitframOpen, setFitframeOpen] = useState(false);
  const { data: logUpdates } = useGetWorkoutLogAlertsQuery(
    message.habit_task.workout_log_id ?? "",
    {
      skip:
        !message.habit_task.workout_log_id || !message.habit_task.workout_id,
    },
  );
  const incompleteLogUpdates =
    logUpdates?.workout_log_alerts.filter((u) => !u.date_addressed) ?? [];

  if (!message.habit_task) {
    return <Typography>Error loading habit message</Typography>;
  }

  const prCount = !message.habit_task.workout_log
    ? null
    : message.habit_task.workout_log.sections
        .map((s) =>
          s.cycles.map((c) =>
            c.exercises.map((e) => e.sets.filter((s) => s.is_pr_new)),
          ),
        )
        .flat()
        .flat()
        .flat().length;

  return (
    <>
      <CardActionArea
        href={
          chatVisualState === "full"
            ? ""
            : message.habit_task.workout_log_id
              ? `/clients/${message.habit_task.user_id}/logs/${message.habit_task.workout_log_id}`
              : ""
        }
        onClick={
          disableInteractive
            ? undefined
            : (event) => {
                if (chatVisualState === "full") {
                  setAnchorEl(event.currentTarget);
                }
              }
        }
        onContextMenu={
          !message.habit_task.workout_log_id
            ? undefined
            : (event) => {
                event.preventDefault();

                setAnchorEl(event.currentTarget);
              }
        }
        sx={{
          maxWidth: "300px",
          borderRadius: `10px 10px 10px ${!combineBottom ? "0px" : "10px"}`,
          overflow: "hidden",
          backgroundColor: (theme) => theme.palette.background.default,
          p: 1,
          display: "flex",
          alignItems: "center",
          border: 1,
          borderColor: "divider",
        }}
      >
        <Stack
          direction={"column"}
          spacing={1}
          sx={{
            whiteSpace: "pre-line",
            wordWrap: "break-word",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {message.habit_task.workout_id ? (
              <Avatar
                sx={{
                  backgroundColor: "#a3e635",
                  mr: 1,
                  width: 20,
                  height: 20,
                  borderRadius: "6px",
                }}
              >
                <DirectionsRunRoundedIcon
                  sx={{
                    fontSize: 16,
                    color: (theme) => theme.palette.text.primary,
                  }}
                />
              </Avatar>
            ) : (
              <HabitDot
                complete
                type={message.habit_task.type}
                sx={{ mr: 1 }}
                widthAndHeight={18}
              />
            )}
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                {message.habit_task.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {format(
                  new Date(message.habit_task.date_completed as string),
                  "MMM do, yyyy h:mm a",
                )}
              </Typography>
            </Box>
          </Box>
          {message.habit_task.notes_client && (
            <Typography
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {message.habit_task.notes_client}
            </Typography>
          )}
          {message.habit_task.workout_log?.share_photo_with_coach &&
            message.habit_task.workout_log.photo_url && (
              <ButtonBase
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();

                  setFitframeOpen(true);
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
                sx={{
                  borderRadius: 1,
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <img
                  height={150}
                  style={{
                    maxWidth: "120px",
                    display: "block",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                  src={message.habit_task.workout_log.photo_url}
                />
              </ButtonBase>
            )}
          {message.habit_task.workout_id && (
            <Typography variant="overline">
              {incompleteLogUpdates.length ?? "No"} workout alerts
            </Typography>
          )}
          {prCount !== null && prCount > 0 && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <SvgIcon fontSize="inherit" sx={{ color: "#9D7F10", mr: 0.5 }}>
                <path d="M12 8L15 13.2L18 10.5L17.3 14H6.7L6 10.5L9 13.2L12 8M12 4L8.5 10L3 5L5 16H19L21 5L15.5 10L12 4M19 18H5V19C5 19.6 5.4 20 6 20H18C18.6 20 19 19.6 19 19V18Z" />
              </SvgIcon>
              <Typography variant="body2" sx={{ color: "#9D7F10" }}>
                {prCount} personal record{prCount > 1 ? "s" : ""}
              </Typography>
            </Box>
          )}
          {message.habit_task.share_photo_with_coach &&
            message.habit_task.photo_url && (
              <img
                style={{ borderRadius: "8px", objectFit: "cover" }}
                width={100}
                height={133}
                alt="fitframe"
                src={message.habit_task.photo_url}
              />
            )}
        </Stack>
      </CardActionArea>
      {!message.habit_task.workout_log_id && (
        <HabitTaskPopover
          habitTask={message.habit_task}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
        />
      )}
      <Dialog
        onClose={() => {
          setFitframeOpen(false);
        }}
        open={fitframOpen}
      >
        <DialogTitleWithClose
          onClose={() => {
            setFitframeOpen(false);
          }}
        >
          FitFrame
        </DialogTitleWithClose>
        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            <img
              width={500}
              src={
                message.habit_task.workout_log?.photo_url ||
                "/assets/profile.png"
              }
              alt="fitframe"
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
