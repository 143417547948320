import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import DesktopMacRoundedIcon from "@mui/icons-material/DesktopMacRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import EmojiEmotionsRoundedIcon from "@mui/icons-material/EmojiEmotionsRounded";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import PhoneIphoneRoundedIcon from "@mui/icons-material/PhoneIphoneRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import type { AnalyticsEvent } from "@trainwell/types";
import { format } from "date-fns";
import { useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";

type Props = {
  event: AnalyticsEvent;
};

export default function EventCell({ event }: Props) {
  const theme = useTheme();
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);

  let icon = <MoreHorizRoundedIcon />;

  if (event.type === "page_view") {
    icon = <VisibilityRoundedIcon />;
  } else if (event.type === "create_account") {
    icon = <AccountBoxRoundedIcon />;
  } else if (event.type === "coach_switched") {
    icon = <SwapHorizRoundedIcon />;
  } else if (event.type === "membership_started") {
    icon = <EmojiEmotionsRoundedIcon />;
  } else if (event.type === "sms_send") {
    icon = <PhoneRoundedIcon />;
  } else if (event.type === "session_start") {
    icon = <LoginRoundedIcon />;
  } else if (event.type === "calendly_call_scheduled") {
    icon = <EventAvailableRoundedIcon />;
  } else if (event.type === "calendly_call_canceled") {
    icon = <EventBusyRoundedIcon />;
  } else if (
    event.type === "pause_request_revoked" ||
    event.type === "pause_ended" ||
    event.type === "cancel_ended" ||
    event.type === "cancel_request_revoked"
  ) {
    icon = <PlayArrowRoundedIcon />;
  } else if (
    event.type === "pause_extended" ||
    event.type === "pause_requested" ||
    event.type === "pause_occurred"
  ) {
    icon = <PauseRoundedIcon />;
  } else if (event.type === "payment_succeeded" || event.type === "purchase") {
    icon = <AttachMoneyRoundedIcon />;
  } else if (event.type === "invoice_finalized") {
    icon = <DescriptionRoundedIcon />;
  } else if (event.type === "select_coach") {
    icon = <PeopleRoundedIcon />;
  } else if (
    event.type === "cancel_requested" ||
    event.type === "cancel_occurred"
  ) {
    icon = <CancelRoundedIcon />;
  } else if (event.type === "email_send") {
    icon = <EmailRoundedIcon />;
  } else if (event.type === "card_added" || event.type === "card_removed") {
    icon = <CreditCardRoundedIcon />;
  } else if (event.platform === "ios") {
    icon = <PhoneIphoneRoundedIcon />;
  } else if (event.platform === "web") {
    icon = <DesktopMacRoundedIcon />;
  }

  let iconColor = theme.palette.blueSurface.main;

  if (event.type === "page_view") {
    iconColor = theme.palette.blueSurface.main;
  } else if (
    event.type === "create_account" ||
    event.type === "membership_started" ||
    event.type === "pause_ended" ||
    event.type === "pause_request_revoked" ||
    event.type === "cancel_ended" ||
    event.type === "cancel_request_revoked" ||
    event.type === "card_added" ||
    event.type === "payment_succeeded" ||
    event.type === "purchase"
  ) {
    iconColor = theme.palette.successSurface.main;
  } else if (
    event.type === "cancel_occurred" ||
    event.type === "cancel_requested" ||
    event.type === "pause_occurred" ||
    event.type === "pause_requested" ||
    event.type === "pause_extended" ||
    event.type === "card_removed" ||
    event.type === "payment_failed"
  ) {
    iconColor = theme.palette.errorSurface.main;
  }

  let content: JSX.Element | null = null;

  if (event.type === "page_view") {
    content = (
      <Typography>
        {(event.content.hostname ?? "") + event.content.path}
      </Typography>
    );
  } else if (event.type === "select_coach") {
    content = (
      <>
        <Typography>
          Selected:{" "}
          {getTrainerName(event.content.trainer_id_selected, trainerNames)}
        </Typography>
        <Typography>
          Top Suggestion:{" "}
          {getTrainerName(event.content.trainer_id_selected, trainerNames)}
        </Typography>
        <Typography>
          Suggested:{" "}
          {event.content.trainer_ids_suggested
            .map((trainerID) => getTrainerName(trainerID, trainerNames))
            .join(", ")}
        </Typography>
        <Typography>
          {event.content.viewed_all_coaches
            ? "👀 Saw all trainers"
            : "😞 Didn't see all trainers"}
        </Typography>
      </>
    );
  } else if (event.type === "session_start") {
    content = (
      <>
        <Typography>IP: {event.content.user_ip}</Typography>
        {event.content.source_id && (
          <Typography>Source id: {event.content.source_id}</Typography>
        )}
        {event.content.referrer && (
          <Typography>Referrer: {event.content.referrer}</Typography>
        )}
        {event.content.utm_source && (
          <Typography>UTM source: {event.content.utm_source}</Typography>
        )}
        {event.content.utm_medium && (
          <Typography>UTM medium: {event.content.utm_medium}</Typography>
        )}
        {event.content.utm_campaign && (
          <Typography>UTM campaign: {event.content.utm_campaign}</Typography>
        )}
        {event.content.utm_content && (
          <Typography>UTM content: {event.content.utm_content}</Typography>
        )}
        {event.content.user_agent?.browser_name && (
          <Typography>
            Browser: {event.content.user_agent?.browser_name}
          </Typography>
        )}
        {event.content.user_agent?.device_type && (
          <Typography>
            Device type: {event.content.user_agent?.device_type}
          </Typography>
        )}
        {event.content.user_agent?.device_vendor && (
          <Typography>
            Device vendor: {event.content.user_agent?.device_vendor}
          </Typography>
        )}
        {event.content.user_agent?.device_model && (
          <Typography>
            Device model: {event.content.user_agent?.device_model}
          </Typography>
        )}
        {event.content.user_agent?.os_name && (
          <Typography>OS: {event.content.user_agent?.os_name}</Typography>
        )}
        {event.content.user_agent?.os_version && (
          <Typography>
            OS version: {event.content.user_agent?.os_version}
          </Typography>
        )}
      </>
    );
  } else if (event.type === "purchase") {
    content = <Typography>Value: ${event.content.value}</Typography>;
  } else if (event.type === "payment_succeeded") {
    content = (
      <>
        <Typography>Invoice id: {event.content.invoice_id}</Typography>
        <Typography>Subscription id: {event.content.invoice_id}</Typography>
        <Typography>
          Amount paid:{" "}
          {(Math.round(event.content.amount_paid * 100) / 100).toFixed(2)}
        </Typography>
        <Typography>
          Discounts:{" "}
          {(Math.round(event.content.discount_applied * 100) / 100).toFixed(2)}
        </Typography>
      </>
    );
  } else if (
    event.type === "calendly_call_scheduled" ||
    event.type === "calendly_call_canceled"
  ) {
    content = (
      <>
        <Typography>
          Sheduled to happen:{" "}
          {format(
            new Date(event.content.date_of_call),
            "MMMM do, yyyy h:mm aaa",
          )}
        </Typography>
        <Typography>Call type: {event.content.call_type}</Typography>
        <Typography>
          Trainer: {getTrainerName(event.content.trainer_id, trainerNames)}
        </Typography>
      </>
    );
  } else if (event.content) {
    content = (
      <Typography
        sx={{
          whiteSpace: "pre-wrap",
        }}
      >
        <pre style={{ overflow: "hidden" }}>
          <code style={{ overflow: "hidden" }}>
            {JSON.stringify(event.content, null, 2)}
          </code>
        </pre>
      </Typography>
    );
  }

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Avatar
          sx={{
            bgcolor: iconColor,
            mr: 2,
            color: (theme) => theme.palette.text.primary,
          }}
        >
          {icon}
        </Avatar>
        <Box>
          <Typography sx={{ fontWeight: "bold", lineHeight: 1 }}>
            {event.type}
          </Typography>
          <Typography variant="overline">
            {format(new Date(event.date), "MMM d, yyyy h:mm aaa")}
          </Typography>
        </Box>
      </Box>
      {content}
    </Box>
  );
}
