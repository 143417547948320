import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import EmojiEmotionsRoundedIcon from "@mui/icons-material/EmojiEmotionsRounded";
import MouseIcon from "@mui/icons-material/Mouse";
import PreviewRoundedIcon from "@mui/icons-material/PreviewRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { LoadingButton } from "@mui/lab";
import type { PopperProps } from "@mui/material";
import {
  alpha,
  Box,
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Grow,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Popper,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import type { BaseEditor, Descendant } from "slate";
import { createEditor, Editor, Range, Transforms } from "slate";
import {
  Editable,
  ReactEditor,
  Slate,
  useFocused,
  useSelected,
  withReact,
} from "slate-react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getDateWithTimezoneOffset } from "src/lib/date";
import {
  addMassMessage,
  readChat,
  removeMassMessage,
  resetMassMessaging,
  selectMassMessages,
  sendMassMessages,
  setChatView,
  setFocusedUserId,
  setPreviewMassMessages,
  setRawMassMessage,
  stageMassMessages,
} from "src/slices/chatSlice";
import { ThreadCard } from "./threads/ThreadCard";

type CustomText = { text: string };

type DynamicKeyElement = {
  type: "dynamic_key";
  key: string;
  children: CustomText[];
};
export type ParagraphElement = {
  type: "paragraph";
  children: (CustomText | DynamicKeyElement)[];
};

type CustomElement = DynamicKeyElement | ParagraphElement;

type CustomEditor = BaseEditor & ReactEditor;

declare module "slate" {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const dynamicKeys: {
  key: string;
  name: string;
  example?: string;
  tooltip?: string;
  subDynamicKeys?: { key: string; name: string; example: string }[];
}[] = [
  {
    name: "Client name",
    key: "client_name",
    subDynamicKeys: [
      {
        name: "Client first name",
        key: "first_name",
        example: "Steve",
      },
      {
        name: "Client full name",
        key: "full_name",
        example: "Steve Jobs",
      },
    ],
  },
  {
    name: "Next workout",
    key: "next_workout",
    subDynamicKeys: [
      {
        name: "Next workout day",
        key: "next_workout_day",
        example: "Wednesday",
      },
      {
        name: "Next workout date",
        key: "next_workout_date",
        example: "7th",
      },
      {
        name: "Next workout name",
        key: "next_workout_name",
        example: "Full Body A",
      },
      {
        name: "Next workout duration",
        key: "next_workout_duration",
        example: "33 minutes",
      },
    ],
  },
  {
    name: "Last missed workout",
    key: "last_missed_workout",
    subDynamicKeys: [
      {
        name: "Last missed workout day",
        key: "last_missed_workout_day",
        example: "Wednesday",
      },
      {
        name: "Last missed workout date",
        key: "last_missed_workout_date",
        example: "7th",
      },
      {
        name: "Last missed workout name",
        key: "last_missed_workout_name",
        example: "Full Body A",
      },
      {
        name: "Last missed workout duration",
        key: "last_missed_workout_duration",
        example: "33 minutes",
      },
    ],
  },
  {
    name: "Missed workout streak length",
    key: "missed_workout_streak_length",
    example: "2",
  },
  {
    name: "Upcoming workout days",
    key: "upcoming_workout_days",
    tooltip: "Excludes today if the client did a workout today",
    subDynamicKeys: [
      {
        name: "This week's upcoming workout days",
        key: "upcoming_workout_days_this_week",
        example: "Monday, Wednesday, Friday",
      },
      {
        name: "Next week's upcoming workout days",
        key: "upcoming_workout_days_next_week",
        example: "Monday, Wednesday, Friday",
      },
    ],
  },
  {
    name: "Links",
    key: "links",
    subDynamicKeys: [
      {
        name: "Change trainer",
        key: "url_change_coach",
        example: "https://account.trainwell.net",
      },
      {
        name: "Schedule call",
        key: "url_schedule_call",
        example: "https://account.trainwell.net",
      },
      {
        name: "Billing portal",
        key: "url_billing_portal",
        example: "https://account.trainwell.net",
      },
    ],
  },
];

const dynamicKeyLeaves: { key: string; name: string; example?: string }[] =
  dynamicKeys
    .map((dynamicKey) =>
      dynamicKey.subDynamicKeys ? dynamicKey.subDynamicKeys : dynamicKey,
    )
    .flat();

const ChipListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function MassMessageView() {
  const dispatch = useAppDispatch();
  const [target, setTarget] = useState<Range | null>(null);
  const [searchedDynamicKeyIndex, setSearchedDynamicKeys] = useState(0);
  const [search, setSearch] = useState("");
  const [emojiPickerAnchorEl, setEmojiPickerAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const isEmojiPickerOpen = Boolean(emojiPickerAnchorEl);
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const editorRef = useRef<CustomEditor>();
  if (!editorRef.current)
    editorRef.current = withDynamicKeys(withReact(createEditor()));
  const editor = editorRef.current;
  const [anchorEl, setAnchorEl] = useState<PopperProps["anchorEl"]>(null);
  const [dynamicKeyMenuData, setDynamicKeyMenuData] = useState<null | {
    key: string;
    anchorEl: HTMLElement;
  }>(null);
  const [height, setHeight] = useState(0);
  // const [messagePreview, setMessagePreview] = useState("");
  const [sending, setSending] = useState(false);
  const ref = useRef(null);
  const dynamicKeyMenuOpen = Boolean(dynamicKeyMenuData);
  const massMessagesToSendCount = useAppSelector(
    (state) => state.chat.chatIdsToMassMessage.length,
  );
  const rawMassMessage = useAppSelector((state) => state.chat.rawMassMessage);
  const previewMassMessages = useAppSelector(
    (state) => state.chat.previewMassMessages,
  );
  const { enqueueSnackbar } = useSnackbar();

  const messagePreview = serializeWithExamples(rawMassMessage as any);

  const searchedDynamicKeys = dynamicKeyLeaves
    .filter((dynamicKey) =>
      dynamicKey.name.toLowerCase().includes(search.toLowerCase()),
    )
    .slice(0, 10);

  const addEmoji = (emoji: any) => {
    if ("native" in emoji) {
      Transforms.insertText(editor, emoji.native);
      Transforms.move(editor);

      setEmojiPickerAnchorEl(null);
    }
  };

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (target) {
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault();
            // eslint-disable-next-line no-case-declarations
            const prevIndex =
              searchedDynamicKeyIndex >= searchedDynamicKeys.length - 1
                ? 0
                : searchedDynamicKeyIndex + 1;
            setSearchedDynamicKeys(prevIndex);
            break;
          case "ArrowUp":
            event.preventDefault();
            // eslint-disable-next-line no-case-declarations
            const nextIndex =
              searchedDynamicKeyIndex <= 0
                ? searchedDynamicKeys.length - 1
                : searchedDynamicKeyIndex - 1;
            setSearchedDynamicKeys(nextIndex);
            break;
          case "Tab":
          case "Enter":
            event.preventDefault();
            Transforms.select(editor, target);
            insertDynamicKey(
              editor,
              searchedDynamicKeys[searchedDynamicKeyIndex].key,
            );
            setTarget(null);
            setAnchorEl(null);
            break;
          case "Escape":
            event.preventDefault();
            setTarget(null);
            setAnchorEl(null);
            break;
        }
      }
    },
    [searchedDynamicKeyIndex, search, target],
  );

  useEffect(() => {
    // @ts-expect-error
    setHeight(ref.current?.clientHeight);
  }, [messagePreview]);

  useEffect(() => {
    if (target && searchedDynamicKeys.length > 0) {
      const domRange = ReactEditor.toDOMRange(editor, target);
      const getBoundingClientRect = () => domRange.getBoundingClientRect();

      setAnchorEl({
        getBoundingClientRect,
      });
    }
  }, [
    searchedDynamicKeys.length,
    editor,
    searchedDynamicKeyIndex,
    search,
    target,
  ]);

  function handleCloseDialog() {
    console.log("Resetting slate");
    dispatch(setChatView("default"));
    setTarget(null);
    setAnchorEl(null);
    setSearchedDynamicKeys(0);
    setSearch("");
    setTarget(null);
    editorRef.current = undefined;

    dispatch(resetMassMessaging());
  }

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
        p: 1,
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ flex: 1 }}>
        {previewMassMessages ? (
          <MessagePreviewPage slateData={editor.children} />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                listStyle: "none",
                mb: 1,
              }}
            >
              {dynamicKeys.map((dynamicKey) => (
                <ChipListItem key={dynamicKey.key}>
                  <Tooltip
                    title={
                      dynamicKey.tooltip ??
                      (dynamicKey.example
                        ? `Example: ${dynamicKey.example}`
                        : undefined)
                    }
                    disableInteractive
                  >
                    <Chip
                      label={dynamicKey.name}
                      onClick={(event) => {
                        if (dynamicKey.subDynamicKeys) {
                          setDynamicKeyMenuData({
                            anchorEl: event.currentTarget,
                            key: dynamicKey.key,
                          });
                        } else {
                          insertDynamicKey(editor, dynamicKey.key);
                        }
                      }}
                      size="small"
                    />
                  </Tooltip>
                </ChipListItem>
              ))}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
              <IconButton
                onClick={(event) => {
                  setEmojiPickerAnchorEl(event.currentTarget);
                }}
                size="small"
                sx={{ mr: 2 }}
              >
                <EmojiEmotionsRoundedIcon fontSize="small" />
              </IconButton>
              <Box sx={{ flex: 1 }}>
                <Box
                  sx={{
                    backgroundColor: (theme) => theme.palette.background.paper,
                    border: 1,
                    borderColor: "divider",
                    borderRadius: 1,
                    p: 2,
                  }}
                >
                  <Slate
                    key={previewMassMessages + "make-me-a-string"}
                    editor={editor}
                    initialValue={rawMassMessage}
                    onChange={(value) => {
                      dispatch(setRawMassMessage(value));

                      const { selection } = editor;

                      if (selection && Range.isCollapsed(selection)) {
                        const [start] = Range.edges(selection);
                        const wordBefore = Editor.before(editor, start, {
                          unit: "word",
                        });
                        const before =
                          wordBefore && Editor.before(editor, wordBefore);
                        const beforeRange =
                          before && Editor.range(editor, before, start);
                        const beforeText =
                          beforeRange && Editor.string(editor, beforeRange);
                        const beforeMatch =
                          beforeText && beforeText.match(/^@(\w+)$/);
                        const after = Editor.after(editor, start);
                        const afterRange = Editor.range(editor, start, after);
                        const afterText = Editor.string(editor, afterRange);
                        const afterMatch = afterText.match(/^(\s|$)/);

                        if (beforeMatch && afterMatch) {
                          setTarget(beforeRange);
                          setSearch(beforeMatch[1]);
                          setSearchedDynamicKeys(0);
                          return;
                        }
                      }

                      setTarget(null);

                      // const isAstChange = editor.operations.some(
                      //   (op) => "set_selection" !== op.type
                      // );
                      // if (isAstChange) {
                      //   // Serialize the value and save the string value to Local Storage.
                      //   setMessagePreview(serializeWithExamples(value as any));
                      // }
                    }}
                  >
                    <Editable
                      renderElement={renderElement}
                      onKeyDown={onKeyDown}
                      placeholder="Message"
                    />
                    <Popper
                      open={Boolean(
                        target && searchedDynamicKeys.length && anchorEl,
                      )}
                      anchorEl={anchorEl}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      sx={{ zIndex: 9999 }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom-start"
                                ? "left top"
                                : "left bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener
                              onClickAway={() => {
                                setAnchorEl(null);
                              }}
                            >
                              <MenuList
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                autoFocus={false}
                              >
                                {searchedDynamicKeys.map((dynamicKey, i) => (
                                  <MenuItem
                                    autoFocus={false}
                                    key={dynamicKey.key}
                                    selected={i === searchedDynamicKeyIndex}
                                    onClick={(event) => {
                                      if (!target) {
                                        return;
                                      }

                                      event.preventDefault();
                                      Transforms.select(editor, target);
                                      insertDynamicKey(editor, dynamicKey.key);
                                      setTarget(null);
                                      setAnchorEl(null);
                                    }}
                                  >
                                    {dynamicKey.name}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </Slate>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    mt: 0.5,
                  }}
                >
                  Type @ to quickly search for a dynamic key.
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="body2"
              sx={{ color: (theme) => theme.palette.text.secondary, mb: 0.25 }}
            >
              Live preview
            </Typography>
            <Typography
              ref={ref}
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.contrastText,
                borderRadius: `${height <= 35 ? "50px" : "10px"} ${
                  height <= 35 ? "50px" : "10px"
                } ${"0px"} ${height <= 35 ? "50px" : "10px"}`,
                px: 1,
                py: 0.5,
                whiteSpace: "pre-line",
                wordWrap: "break-word",
                maxWidth: "300px",
              }}
            >
              {messagePreview || "Message"}
            </Typography>
          </>
        )}
      </Box>
      <DialogActions>
        {previewMassMessages ? (
          <>
            <Button
              variant="text"
              onClick={() => {
                dispatch(setPreviewMassMessages(false));
              }}
              disabled={sending}
            >
              Back
            </Button>
            <LoadingButton
              variant="contained"
              startIcon={<SendRoundedIcon />}
              onClick={() => {
                setSending(true);

                const keys = editor.children
                  .map((child) =>
                    // @ts-expect-error
                    child.children.map((item) =>
                      item.type === "dynamic_key" ? item.key : undefined,
                    ),
                  )
                  .flat()
                  .flat()
                  .filter(Boolean);

                dispatch(sendMassMessages({ dynamicKeys: keys }))
                  .unwrap()
                  .then(() => {
                    handleCloseDialog();
                    enqueueSnackbar("Sent messages", {
                      variant: "success",
                    });
                    setSending(false);
                  })
                  .catch(() => {
                    enqueueSnackbar("Something went wrong sending messages", {
                      variant: "error",
                    });
                    setSending(false);
                  });
              }}
              disabled={massMessagesToSendCount === 0}
              loading={sending}
            >
              Send {massMessagesToSendCount} message
              {massMessagesToSendCount !== 1 ? "s" : ""}
            </LoadingButton>
          </>
        ) : (
          <>
            <Button
              variant="text"
              onClick={() => {
                handleCloseDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              startIcon={<PreviewRoundedIcon />}
              onClick={() => {
                dispatch(stageMassMessages(editor.children));
                dispatch(setPreviewMassMessages(true));
              }}
            >
              Preview
            </Button>
          </>
        )}
      </DialogActions>
      <Menu
        anchorEl={dynamicKeyMenuData?.anchorEl}
        open={dynamicKeyMenuOpen}
        onClose={() => {
          setDynamicKeyMenuData(null);
        }}
        MenuListProps={{ dense: true }}
      >
        {dynamicKeys
          .find((dynamicKey) => dynamicKey.key === dynamicKeyMenuData?.key)
          ?.subDynamicKeys?.map((subDynamicKey) => (
            <Tooltip
              title={
                subDynamicKey.example
                  ? `Example: ${subDynamicKey.example}`
                  : undefined
              }
              disableInteractive
              key={subDynamicKey.key}
            >
              <MenuItem
                onClick={() => {
                  insertDynamicKey(editor, subDynamicKey.key);
                  setDynamicKeyMenuData(null);
                }}
              >
                <Typography>{subDynamicKey.name}</Typography>
              </MenuItem>
            </Tooltip>
          ))}
      </Menu>
      <Popover
        open={isEmojiPickerOpen}
        anchorEl={emojiPickerAnchorEl}
        onClose={() => {
          setEmojiPickerAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 1260 }}
      >
        <Picker data={data} onEmojiSelect={addEmoji} />
      </Popover>
    </Box>
  );
}

// Define a serializing function that takes a value and returns a string.
function serializeWithExamples(paragraphs: ParagraphElement[]) {
  let message = "";

  for (const paragraph of paragraphs) {
    if (message !== "") {
      message += "\n";
    }

    for (const child of paragraph.children) {
      if ("type" in child) {
        if (child.type === "dynamic_key") {
          message += dynamicKeyLeaves.find(
            (dynamicKey) => dynamicKey.key === child.key,
          )?.example;
        }
      } else {
        message += child.text;
      }
    }
  }

  return message;
}

const withDynamicKeys = (editor: CustomEditor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "dynamic_key" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "dynamic_key" ? true : isVoid(element);
  };

  return editor;
};

const insertDynamicKey = (editor: CustomEditor, key: string) => {
  const dynamicKey: DynamicKeyElement = {
    type: "dynamic_key",
    key: key,
    children: [{ text: "" }],
  };
  Transforms.insertNodes(editor, dynamicKey);
  Transforms.move(editor);
};

const Element = (props: any) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case "dynamic_key":
      return <DynamicKey {...props} />;
    default:
      return <Typography {...attributes}>{children}</Typography>;
  }
};

const DynamicKey = ({ attributes, children, element }: any) => {
  const theme = useTheme();
  const selected = useSelected();
  const focused = useFocused();
  return (
    <span
      {...attributes}
      contentEditable={false}
      style={{
        padding: "1px 3px",
        margin: "2px 2px",
        verticalAlign: "baseline",
        display: "inline-block",
        borderRadius: "4px",
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
        fontSize: "0.9rem",
        fontWeight: "bold",
        boxShadow: selected && focused ? theme.shadows[1] : "none",
        border: "1px",
        borderColor:
          selected && focused ? theme.palette.primary.main : "rgba(0,0,0,0)",
        borderStyle: "solid",
      }}
    >
      {children}@
      {dynamicKeyLeaves.find((leaf) => leaf.key === element.key)?.name}
    </span>
  );
};

type MessagePreviewPageProps = {
  slateData: Descendant[];
};

function MessagePreviewPage({ slateData }: MessagePreviewPageProps) {
  const dispatch = useAppDispatch();
  /** used to perserve the order of mass message chats */
  const displayedChatIds = useRef<string[]>([]);

  const massMessageChats = useAppSelector((state) => {
    const messages = selectMassMessages(state, slateData as any);

    if (displayedChatIds.current.length === 0) {
      return messages;
    } else {
      // return messages in the order they were displayed
      messages.sort((a, b) => {
        return (
          displayedChatIds.current.indexOf(a.chat.id) -
          displayedChatIds.current.indexOf(b.chat.id)
        );
      });

      return messages;
    }
  });

  const chatIdsToMassMessage = useAppSelector(
    (state) => state.chat.chatIdsToMassMessage,
  );

  useEffect(() => {
    displayedChatIds.current = massMessageChats
      .sort((a, b) => {
        if (a.error && !b.error) {
          return 1;
        } else if (!a.error && b.error) {
          return -1;
        }

        if (
          a.chat.oldestUnreadMessageFromClient &&
          !b.chat.oldestUnreadMessageFromClient
        ) {
          return -1;
        } else if (
          !a.chat.oldestUnreadMessageFromClient &&
          b.chat.oldestUnreadMessageFromClient
        ) {
          return 1;
        }

        const aClientDate = getDateWithTimezoneOffset(
          today,
          a.chat.clientTimezoneOffset ?? 0,
        );
        const bClientDate = getDateWithTimezoneOffset(
          today,
          b.chat.clientTimezoneOffset ?? 0,
        );

        const aDayIndex = aClientDate.getDay();
        const bDayIndex = bClientDate.getDay();

        if (aDayIndex === todaysDayIndex && bDayIndex !== todaysDayIndex) {
          return 1;
        } else if (
          aDayIndex !== todaysDayIndex &&
          bDayIndex === todaysDayIndex
        ) {
          return -1;
        }

        return 0;
      })
      .map((chat) => chat.chat.id);
  }, []);

  const today = new Date();
  const todaysDayIndex = today.getDay();

  const errorCount = massMessageChats.filter((message) => message.error).length;

  return (
    <>
      <Typography sx={{ mb: 2 }}>
        Use the checkbox for messages you want to send. All unread messages are
        included in each preview, plus the latest message.
      </Typography>
      {errorCount > 0 && (
        <Chip
          label={`${errorCount} chats with errors`}
          color="error"
          sx={{ mb: 2 }}
        />
      )}
      <Box sx={{ display: "flex", maxWidth: "100%" }}>
        <Box sx={{ pr: 2, pl: 1 }}>
          <Tooltip
            title="Hover your mouse in this column to make scrolling easier"
            disableInteractive
          >
            <MouseIcon />
          </Tooltip>
        </Box>
        <Stack
          direction="column"
          spacing={4}
          flex={1}
          maxWidth={"100%"}
          overflow={"hidden"}
        >
          {massMessageChats.map((chat) => {
            const clientDate = getDateWithTimezoneOffset(
              today,
              chat.chat.clientTimezoneOffset ?? 0,
            );

            const clientDayIndex = clientDate.getDay();

            return (
              <Box key={chat.chat.id}>
                <ThreadCard
                  chatId={chat.chat.id}
                  color={chat.error === true ? "error" : "default"}
                  onMouseOverLinger={() => {
                    dispatch(setFocusedUserId(chat.chat.id));
                    dispatch(readChat(chat.chat.id));
                  }}
                />
                <Box sx={{ mx: 1, mt: 0.5 }}>
                  {chat.chat.oldestUnreadMessageFromClient && (
                    <Typography variant="overline">
                      ⚠️ Unread messages
                    </Typography>
                  )}
                  {clientDayIndex !== todaysDayIndex && (
                    <Typography variant="overline">
                      ⚠️ Client timezone is different day
                    </Typography>
                  )}
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={chatIdsToMassMessage.includes(chat.chat.id)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              dispatch(addMassMessage(chat.chat.id));
                            } else {
                              dispatch(removeMassMessage(chat.chat.id));
                            }
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                          disabled={chat.error}
                          size="small"
                        />
                      }
                      componentsProps={{
                        typography: { variant: "body2" },
                      }}
                      label="^ Looks good"
                    />
                  </FormGroup>
                </Box>
              </Box>
            );
          })}
        </Stack>
      </Box>
    </>
  );
}
