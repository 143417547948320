import type { SxProps, Theme } from "@mui/material";
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { useState } from "react";

type Props = {
  label: string;
  value: string;
  possibleValues: { value: string; label: string }[];
  onSave: (newValue: string) => void;
  sx?: SxProps<Theme>;
};

export default function SmartEditSelect({
  label,
  value,
  possibleValues,
  onSave,
  sx = [],
}: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  function save(newValue: string) {
    onSave(newValue);
    setIsEditing(false);
  }

  return (
    <Box
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      sx={Array.isArray(sx) ? sx : [sx]}
    >
      <Box sx={{ textAlign: "left" }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
          <Typography variant="h6">{label}</Typography>
          <Box
            sx={{
              opacity: isHovering || isEditing ? undefined : 0,
              pointerEvents: isHovering || isEditing ? undefined : "none",
            }}
          >
            {isEditing ? (
              <Button
                variant="text"
                size="small"
                sx={{
                  ml: 1,
                  lineHeight: 1.25,
                  px: 1,
                  py: 0.5,
                  minWidth: "0px",
                }}
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
            ) : (
              <Button
                variant="text"
                size="small"
                sx={{
                  ml: 1,
                  lineHeight: 1.25,
                  px: 1,
                  py: 0.5,
                  minWidth: "0px",
                }}
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                Edit
              </Button>
            )}
          </Box>
        </Box>
        {isEditing ? (
          <Select
            size="small"
            value={value}
            onChange={(event) => {
              save(event.target.value as string);
            }}
            MenuProps={{
              PaperProps: {
                style: { maxHeight: "350px" },
              },
            }}
          >
            {possibleValues.map((possibleValue) => (
              <MenuItem key={possibleValue.value} value={possibleValue.value}>
                {possibleValue.label}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography>
            {possibleValues.find((item) => item.value === value)?.label ??
              "Error"}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
