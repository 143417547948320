import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import {
  Box,
  debounce,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import RestrictAccess from "src/components/misc/RestrictAccess";
import SearchField from "src/components/misc/SearchField";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  resetAllClients,
  searchCompanyClients,
} from "src/slices/allClientsSlice";
import { resetClientEdit } from "src/slices/clientEditSlice";
import ClientCell from "./ClientCell";

type ClientFilter =
  | "active"
  | "paused"
  | "cancelled"
  | "all"
  | "past_due"
  | "lead";

const drawerWidth = 240;

export function ClientLayoutAdmin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [clientFilter, setClientFilter] = useState<ClientFilter>("all");
  const clients = useAppSelector((state) => state.allClients.clients);
  const clientsStatus = useAppSelector((state) => state.allClients.status);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [isVip, setIsVip] = useState(false);
  const open = Boolean(anchorEl);

  function fetchData(
    search: string,
    clientFilter: ClientFilter,
    isVip?: boolean,
  ) {
    dispatch(resetAllClients());

    if (search.length >= 3) {
      dispatch(resetAllClients());

      dispatch(
        searchCompanyClients({
          query: search,
          state: clientFilter === "all" ? undefined : clientFilter,
          isVip: isVip ?? undefined,
        }),
      );
    }
  }

  const debouncedSearch = useCallback(
    debounce((search: string, clientFilter: ClientFilter, isVip?: boolean) => {
      fetchData(search, clientFilter, isVip);
    }, 500),
    [],
  );

  useEffect(() => {
    debouncedSearch(search, clientFilter, isVip);
  }, [search]);

  useEffect(() => {
    fetchData(search, clientFilter, isVip);
  }, [clientFilter, isVip]);

  type MemoProps = {
    userId: string;
    isSelected: boolean;
    selectClient: (coachID: string) => void;
  };

  const InnerItem = React.memo<MemoProps>(function InnerItem({
    userId,
    isSelected,
    selectClient,
  }: MemoProps) {
    return (
      <ClientCell
        key={userId}
        userId={userId}
        isSelected={isSelected}
        selectClient={selectClient}
      />
    );
  });

  return (
    <RestrictAccess location={"client_list"} showNoAccessMessage>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Paper
          square
          elevation={0}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            maxHeight: "100%",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column",
            borderRightWidth: "1px",
            borderRightColor: (theme) => theme.palette.divider,
            borderRightStyle: "solid",
          }}
        >
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
              mx: 2,
              mt: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="h1">Clients</Typography>
              <Typography variant="overline">
                {(clients ?? []).length} clients
              </Typography>
            </Box>
            <IconButton
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <FilterListRoundedIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              p: 1,
              backgroundColor: (theme) => theme.palette.background.paper,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <SearchField
              value={search}
              onChange={(value) => {
                setSearch(value);
              }}
              onClear={() => {
                setSearch("");
              }}
            />
          </Box>
          <Box sx={{ overflowY: "hidden", flex: 1 }}>
            {clientsStatus === "loading" ? (
              <LoadingPage message="Getting clients" />
            ) : clientsStatus === "idle" ? (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 1,
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                >
                  Search for a client by name or email
                </Typography>
              </Box>
            ) : (
              <Virtuoso
                style={{ height: "100%" }}
                data={clients}
                itemContent={(index, client) => (
                  <InnerItem
                    userId={client.user_id}
                    isSelected={client.user_id === userId}
                    key={client.user_id}
                    selectClient={(selectedID) => {
                      navigate("/admin/company/clients/" + selectedID);
                      dispatch(resetClientEdit());
                    }}
                  />
                )}
              />
            )}
          </Box>
        </Paper>
        <Box sx={{ flexGrow: 1, overflowX: "hidden" }}>
          <Outlet />
        </Box>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          slotProps={{
            paper: {
              sx: { width: 400, p: 2 },
            },
          }}
        >
          <Typography variant="h2" sx={{ mb: 2 }}>
            Filter
          </Typography>
          <FormControl size="small" fullWidth sx={{ mb: 2 }}>
            <InputLabel>State</InputLabel>
            <Select
              value={clientFilter}
              label="State"
              onChange={(event) => {
                setClientFilter(event.target.value as ClientFilter);
              }}
              autoFocus
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"active"}>Active</MenuItem>
              <MenuItem value={"paused"}>Paused</MenuItem>
              <MenuItem value={"past_due"}>Past due</MenuItem>
              <MenuItem value={"cancelled"}>Cancelled</MenuItem>
              <MenuItem value={"lead"}>Lead</MenuItem>
            </Select>
          </FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isVip}
                  onChange={(event) => {
                    setIsVip(event.target.checked);
                  }}
                />
              }
              label="Only vips"
            />
          </FormGroup>
        </Popover>
      </Box>
    </RestrictAccess>
  );
}
