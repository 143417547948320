import AddIcon from "@mui/icons-material/Add";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  Popover,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { deepOrange, deepPurple, lightGreen, teal } from "@mui/material/colors";
import { DatePicker } from "@mui/x-date-pickers";
import type { TestVariant } from "@trainwell/types";
import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";
import { useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import ComparisonDropdown from "./ComparisonDropdown";
import type { Comparison } from "./useComparison";
import { DateRange, defaultComparison } from "./useComparison";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  comparisons: Comparison[];
  setComparisons: (any: any) => void;
  setDidDatesChange: (any: any) => void;
  getPossibles: (
    comparisons: Comparison[],
    comparisonIndex: number,
    startDate: string,
    endDate: string,
  ) => Promise<void>;
  dateRange: DateRange;
  gettingPossibles: boolean;
};

export default function Comparisons({
  comparisons,
  setComparisons,
  setDidDatesChange,
  getPossibles,
  dateRange,
  gettingPossibles,
}: Props) {
  const theme = useTheme();
  const [openedIndex, setOpenedIndex] = useState<number | null>(null);
  const [comparisonIteration, setComparisonIteration] = useState(1);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const tests = useAppSelector((state) => state.tests.tests);

  const testVariants: TestVariant[] = [];
  for (const test of tests) {
    if (test.variants.length === 1) {
      testVariants.push({ ...test.variants[0], name: test.name });
    } else {
      for (const variant of test.variants) {
        testVariants.push({
          ...variant,
          name: `${test.name} ${variant.name}`,
        });
      }
    }
  }

  console.log("Variants:", testVariants);

  const colors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    lightGreen[700],
    deepPurple[700],
    deepOrange[700],
    teal[700],
  ];

  return (
    <Grid
      container
      spacing={1}
      // sx={{ flexWrap: "wrap", listStyle: "none" }}
    >
      {comparisons.map((comparison, index) => {
        return (
          <Grid item key={index}>
            <Chip
              label={comparison.name}
              variant="outlined"
              sx={{
                color: colors[index],
                borderColor: colors[index],
              }}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setOpenedIndex(index);
              }}
              onDelete={
                comparisons.length <= 1
                  ? undefined
                  : () => {
                      const newComparisons = JSON.parse(
                        JSON.stringify(comparisons),
                      ) as Comparison[];
                      newComparisons.splice(index, 1);
                      setComparisons(newComparisons);
                    }
              }
            />
            <Popover
              open={open && openedIndex === index}
              anchorEl={anchorEl}
              onClose={() => {
                setAnchorEl(null);
                setOpenedIndex(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Stack
                direction="column"
                spacing={2}
                sx={{ p: 2, width: "350px" }}
              >
                <TextField
                  label="Name"
                  fullWidth
                  variant="filled"
                  defaultValue={comparison.name}
                  onBlur={(event) => {
                    const newComparisons = JSON.parse(
                      JSON.stringify(comparisons),
                    ) as Comparison[];

                    newComparisons[index].name = event.target.value;

                    setComparisons(newComparisons);
                  }}
                />
                <Typography variant="h3">Timeframe</Typography>
                <DatePicker
                  label="Start date"
                  value={comparison.startDate}
                  maxDate={new Date(comparison.endDate)}
                  onChange={(newValue) => {
                    if (isBefore(newValue!, new Date(comparison.endDate))) {
                      const newComparisons = JSON.parse(
                        JSON.stringify(comparisons),
                      ) as Comparison[];
                      newComparisons[index].startDate = startOfDay(
                        newValue ?? new Date(),
                      );
                      newComparisons[index].endDate = new Date(
                        newComparisons[index].endDate,
                      );
                      setComparisons(newComparisons);
                      setDidDatesChange(true);

                      getPossibles(
                        newComparisons,
                        index,
                        newComparisons[index].startDate.toString(),
                        newComparisons[index].endDate.toString(),
                      );
                    }
                  }}
                  disabled={dateRange !== DateRange.Custom}
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
                <DatePicker
                  label="End date"
                  value={comparison.endDate}
                  minDate={new Date(comparison.startDate)}
                  maxDate={new Date()}
                  onChange={(newValue) => {
                    if (isAfter(newValue!, new Date(comparison.startDate))) {
                      const newComparisons = JSON.parse(
                        JSON.stringify(comparisons),
                      ) as Comparison[];
                      newComparisons[index].endDate = endOfDay(
                        newValue ?? new Date(),
                      );
                      newComparisons[index].startDate = new Date(
                        newComparisons[index].startDate,
                      );
                      setComparisons(newComparisons);
                      setDidDatesChange(true);

                      getPossibles(
                        newComparisons,
                        index,
                        newComparisons[index].startDate.toString(),
                        newComparisons[index].endDate.toString(),
                      );
                    }
                  }}
                  disabled={dateRange !== DateRange.Custom}
                />
                <Typography variant="h3">Tests</Typography>
                <Autocomplete
                  multiple
                  size="small"
                  fullWidth
                  options={testVariants}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  value={testVariants.filter((testVariant) =>
                    comparison.testsInclude.includes(testVariant.id),
                  )}
                  onChange={(event: any, newValue) => {
                    const newComparisons = JSON.parse(
                      JSON.stringify(comparisons),
                    ) as Comparison[];
                    newComparisons[index].testsInclude = newValue.map(
                      (test) => test.id,
                    );
                    setComparisons(newComparisons);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Include" />
                  )}
                />
                <Autocomplete
                  multiple
                  size="small"
                  fullWidth
                  options={testVariants}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  value={testVariants.filter((testVariant) =>
                    comparison.testsExclude.includes(testVariant.id),
                  )}
                  onChange={(event: any, newValue) => {
                    const newComparisons = JSON.parse(
                      JSON.stringify(comparisons),
                    ) as Comparison[];
                    newComparisons[index].testsExclude = newValue.map(
                      (test) => test.id,
                    );
                    setComparisons(newComparisons);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Exclude" />
                  )}
                />
                {gettingPossibles ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Typography variant="h3">Source</Typography>
                    <ComparisonDropdown
                      label="source_id"
                      value={comparison.sourceID}
                      options={comparison.possibles.possibleSourceIDs}
                      onChange={(_, newValue: any) => {
                        const newComparisons = JSON.parse(
                          JSON.stringify(comparisons),
                        ) as Comparison[];
                        newComparisons[index].sourceID = newValue ?? [];
                        setComparisons(newComparisons);
                      }}
                    />
                    <ComparisonDropdown
                      label="UTM source"
                      value={comparison.utmSource}
                      options={comparison.possibles.possibleUtmSources}
                      onChange={(_, newValue: any) => {
                        const newComparisons = JSON.parse(
                          JSON.stringify(comparisons),
                        ) as Comparison[];
                        newComparisons[index].utmSource = newValue ?? [];
                        setComparisons(newComparisons);
                      }}
                    />
                    <ComparisonDropdown
                      label="UTM medium"
                      value={comparison.utmMedium}
                      options={comparison.possibles.possibleUtmMediums}
                      onChange={(_, newValue: any) => {
                        const newComparisons = JSON.parse(
                          JSON.stringify(comparisons),
                        ) as Comparison[];
                        newComparisons[index].utmMedium = newValue ?? [];
                        setComparisons(newComparisons);
                      }}
                    />
                    <ComparisonDropdown
                      label="UTM campaign"
                      value={comparison.utmCampaign}
                      options={comparison.possibles.possibleUtmCampaigns}
                      onChange={(_, newValue: any) => {
                        const newComparisons = JSON.parse(
                          JSON.stringify(comparisons),
                        ) as Comparison[];
                        newComparisons[index].utmCampaign = newValue ?? [];
                        setComparisons(newComparisons);
                      }}
                    />
                    <ComparisonDropdown
                      label="UTM content"
                      value={comparison.utmContent}
                      options={comparison.possibles.possibleUtmContents}
                      onChange={(_, newValue: any) => {
                        const newComparisons = JSON.parse(
                          JSON.stringify(comparisons),
                        ) as Comparison[];
                        newComparisons[index].utmContent = newValue ?? [];
                        setComparisons(newComparisons);
                      }}
                    />
                    <Typography variant="h3">Client info</Typography>
                    <ComparisonDropdown
                      label="OS name"
                      value={comparison.osName}
                      options={comparison.possibles.possibleOSNames}
                      onChange={(_, newValue: any) => {
                        const newComparisons = JSON.parse(
                          JSON.stringify(comparisons),
                        ) as Comparison[];
                        newComparisons[index].osName = newValue ?? [];
                        setComparisons(newComparisons);
                      }}
                    />
                    <ComparisonDropdown
                      label="Browser name"
                      value={comparison.browserName}
                      options={comparison.possibles.possibleBrowserNames}
                      onChange={(_, newValue: any) => {
                        const newComparisons = JSON.parse(
                          JSON.stringify(comparisons),
                        ) as Comparison[];
                        newComparisons[index].browserName = newValue ?? [];
                        setComparisons(newComparisons);
                      }}
                    />
                    <ComparisonDropdown
                      label="Device type"
                      value={comparison.deviceType}
                      options={comparison.possibles.possibleDeviceTypes}
                      onChange={(_, newValue: any) => {
                        const newComparisons = JSON.parse(
                          JSON.stringify(comparisons),
                        ) as Comparison[];
                        newComparisons[index].deviceType = newValue ?? [];
                        setComparisons(newComparisons);
                      }}
                    />
                  </>
                )}
              </Stack>
            </Popover>
          </Grid>
        );
      })}
      {comparisons.length < 6 && (
        <Grid item>
          <Chip
            icon={<AddIcon />}
            label="Add comparison"
            variant="outlined"
            onClick={() => {
              const newComparisons = JSON.parse(
                JSON.stringify(comparisons),
              ) as Comparison[];
              const newComparison = {
                ...defaultComparison,
                id: crypto.randomUUID(),
                name: `Comparison ${comparisonIteration + 1}`,
              };
              setComparisonIteration(comparisonIteration + 1);
              newComparisons.push(newComparison);
              setComparisons(newComparisons);

              getPossibles(
                newComparisons,
                newComparisons.length - 1,
                newComparison.startDate.toString(),
                newComparison.endDate.toString(),
              );

              // const newPossibles = JSON.parse(
              //   JSON.stringify(com)
              // ) as Possibles[];
              // newPossibles.push(defaultPossible);
              // setPossibles(newPossibles);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}
