import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import { isBlank } from "@trainwell/features";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { api } from "src/lib/trainwellApi";

type Props = {
  open: boolean;
  onClose: () => void;
  trainerId: string;
};

export default function AssignRealClientDialog({
  open,
  onClose,
  trainerId,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [userId, setUserId] = useState("");

  function resetAndClose() {
    onClose();

    setUserId("");
  }

  const isValid = !isBlank(userId);

  return (
    <Dialog onClose={resetAndClose} open={open} fullWidth maxWidth="sm">
      <DialogTitleWithClose onClose={resetAndClose}>
        Assign real client
      </DialogTitleWithClose>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          This resets the client (notes, plans, etc) and sets them up to be
          re-onboarded by this trainer.
        </DialogContentText>
        <TextField
          label="User id"
          fullWidth
          value={userId}
          onChange={(event) => {
            setUserId(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={resetAndClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          onClick={() => {
            api.trainers.assignRealClient(trainerId, userId).then((resp) => {
              if (resp.status !== "error") {
                enqueueSnackbar("Assigned real client", {
                  variant: "success",
                });
              } else {
                enqueueSnackbar("Error assigning real client", {
                  variant: "error",
                });
              }

              resetAndClose();
            });
          }}
        >
          Assign real client
        </Button>
      </DialogActions>
    </Dialog>
  );
}
