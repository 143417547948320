import { Draggable, Droppable } from "@hello-pangea/dnd";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { isBlank } from "@trainwell/features";
import {
  addDays,
  addWeeks,
  differenceInCalendarWeeks,
  format,
  isBefore,
  isSameWeek,
  startOfWeek,
} from "date-fns";
import { memo, useState } from "react";
import TextFieldWithLimit from "src/components/misc/TextFieldWithLimit";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  getDateFromDatabase,
  getDateWithTimezoneOffset,
  getLocalDate,
  getZeroedOutUtcDate,
} from "src/lib/date";
import {
  isHabitDayInPast,
  isHabitWeekPlanCurrentWeek,
  isHabitWeekPlanInPast,
} from "src/lib/habits";
import {
  addHabitWeekToNextWeek,
  clearHabitWeekPlan,
  duplicateHabitWeekPlan,
  removeHabitWeekPlan,
  selectHabitPlanById,
  setDayNotes,
  togglePlanPublished,
} from "src/slices/clientSlice";
import { FirstWeekDialog } from "./FirstWeekDialog";
import HabitWeek from "./HabitWeek";
import { PhaseExplanationDialog } from "./PhaseExplanationDialog";

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

type Props = {
  weekPlanId: string;
  isMostRecent: boolean;
};

const WeekPlan = memo(function WeekPlan({ weekPlanId, isMostRecent }: Props) {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [notesDayIndex, setNotesDayIndex] = useState<null | number>(null);
  const open = Boolean(anchorEl) && notesDayIndex !== null;
  const [copyMenuAnchorEl, setCopyMenyAnchorEl] =
    useState<null | HTMLButtonElement>(null);
  const copyMenuOpen = Boolean(copyMenuAnchorEl);
  const addWeekStatus = useAppSelector((state) => state.client.addWeekStatus);
  const weekPlan = useAppSelector((state) =>
    selectHabitPlanById(state, weekPlanId),
  );
  const clientTimezoneOffset = useAppSelector(
    (state) => state.client.client?.default_timezone_offset ?? 0,
  );
  const [firstWeekDialogOpen, setFirstWeekDialogOpen] = useState(false);
  const [phaseExplanationDialogOpen, setPhaseExplanationDialogOpen] =
    useState(false);
  const isInPhaseExplanationTest = useAppSelector((state) =>
    state.client.client?.tests?.includes("phase_explanation_treatment"),
  );
  const isWeekAfterOnboarding = useAppSelector((state) => {
    const client = state.client.client;

    if (!client || !client.account.dashboard.date_onboarded) {
      return false;
    }

    const onboardingWeekDate = getZeroedOutUtcDate(
      startOfWeek(client.account.dashboard.date_onboarded),
    );
    const nextWeekDate = getZeroedOutUtcDate(addWeeks(onboardingWeekDate, 1));

    return weekPlan.date === nextWeekDate;
  });

  if (!weekPlan) {
    return <Typography>Week plan not found</Typography>;
  }

  const clientsToday = getDateWithTimezoneOffset(
    new Date(),
    clientTimezoneOffset,
  );
  const clientsDay = clientsToday.getDay();
  const isThisWeek = isSameWeek(
    clientsToday,
    getDateFromDatabase(weekPlan.date),
  );

  const nonDraggableHabitWeeks = weekPlan.habit_weeks.filter(
    (w) => w.type === "progress_metric_measurement",
  );
  const draggableHabitWeeks = weekPlan.habit_weeks.filter(
    (w) => w.type !== "progress_metric_measurement",
  );

  return (
    <div>
      <Box
        id={isThisWeek ? "today" : undefined}
        sx={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            size="small"
            onClick={(event) => {
              if (isMostRecent) {
                dispatch(duplicateHabitWeekPlan({ weekPlanId }));
              } else {
                setCopyMenyAnchorEl(event.currentTarget);
              }
            }}
            disabled={addWeekStatus === "loading" || addWeekStatus === "failed"}
          >
            <ContentCopyRoundedIcon fontSize="inherit" />
          </IconButton>
          <Typography variant="h2" sx={{ ml: 1 }}>
            Week of {format(getLocalDate(weekPlan.date), "MMM d, yyyy")}
          </Typography>
        </Box>
        <Box sx={{ px: 1, display: "flex", alignItems: "center" }}>
          {isBefore(new Date(), new Date(weekPlan.date)) && isMostRecent && (
            <Button
              variant="text"
              size="small"
              color="error"
              onClick={() => {
                if (isMostRecent) {
                  dispatch(removeHabitWeekPlan(weekPlanId));
                } else {
                  dispatch(clearHabitWeekPlan(weekPlanId));
                }
              }}
              startIcon={<DeleteRoundedIcon />}
              sx={{ mr: 2 }}
            >
              Delete week
            </Button>
          )}
          <FormGroup>
            <FormControlLabel
              componentsProps={{ typography: { variant: "body2" } }}
              disabled={
                weekPlan.habit_weeks.length <= 0 ||
                differenceInCalendarWeeks(
                  new Date(),
                  getLocalDate(new Date(weekPlan.date)),
                ) >= 1
              }
              control={
                <Checkbox
                  size="small"
                  checked={weekPlan.published}
                  onChange={() => {
                    if (
                      isInPhaseExplanationTest &&
                      isWeekAfterOnboarding &&
                      !weekPlan.published
                    ) {
                      setPhaseExplanationDialogOpen(true);

                      return;
                    } else if (
                      !isInPhaseExplanationTest &&
                      isThisWeek &&
                      !weekPlan.published
                    ) {
                      setFirstWeekDialogOpen(true);
                    }

                    dispatch(togglePlanPublished(weekPlanId));
                  }}
                />
              }
              label="Published"
            />
          </FormGroup>
        </Box>
      </Box>
      {isThisWeek && (
        <Grid container columns={7}>
          {days.map((_, dayIndex) => {
            const isThisToday = isThisWeek && clientsDay === dayIndex;

            return (
              <Grid
                item
                xs={1}
                key={dayIndex}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isThisToday && (
                  <Tooltip
                    title={`Client local time: ${format(
                      clientsToday,
                      "hh:mm aaa",
                    )}`}
                    disableInteractive
                    placement="top"
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TodayRoundedIcon
                        fontSize="inherit"
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                          mr: 0.5,
                        }}
                      />
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                          color: (theme) => theme.palette.primary.main,
                        }}
                      >
                        Today
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
              </Grid>
            );
          })}
        </Grid>
      )}
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: 1,
          border: 1,
          borderColor: "divider",
          overflow: "hidden",
        }}
      >
        <Grid
          container
          columns={7}
          sx={{
            backgroundColor: (theme) =>
              weekPlan.published
                ? theme.palette.successSurface.main
                : theme.palette.errorSurface.main,
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          {days.map((day, dayIndex) => {
            const dayLabel = `${days[dayIndex]}, ${addDays(
              new Date(weekPlan.date),
              dayIndex,
            ).getUTCDate()}`;

            const isInPast = isHabitDayInPast(weekPlan.date, dayIndex);

            const isThisToday = isThisWeek && clientsDay === dayIndex;

            return (
              <Grid
                item
                xs={1}
                key={dayIndex}
                sx={{
                  px: 1,
                  py: 0.5,
                  borderLeft: dayIndex === 0 ? undefined : 1,
                  borderColor: "divider",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: (theme) =>
                    isThisToday ? theme.palette.primary.main : undefined,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: isThisToday ? "bold" : undefined,
                    color: (theme) =>
                      isThisToday
                        ? theme.palette.primary.contrastText
                        : undefined,
                  }}
                >
                  {dayLabel}
                </Typography>
                {(!isInPast ||
                  weekPlan.notes_coach_days[dayIndex] !== null) && (
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      setNotesDayIndex(dayIndex);
                    }}
                    disabled={isInPast}
                    sx={{
                      color: (theme) =>
                        isThisToday
                          ? theme.palette.primary.contrastText
                          : undefined,
                    }}
                  >
                    {weekPlan.notes_coach_days[dayIndex] === null ? (
                      <ChatBubbleOutlineRoundedIcon sx={{ fontSize: "14px" }} />
                    ) : (
                      <ChatBubbleRoundedIcon sx={{ fontSize: "14px" }} />
                    )}
                  </IconButton>
                )}
              </Grid>
            );
          })}
        </Grid>
        {nonDraggableHabitWeeks.map((habitWeek) => (
          <HabitWeek
            isDragging={false}
            key={habitWeek.id}
            habitWeekId={habitWeek.id}
            weekPlanId={weekPlanId}
          />
        ))}
        <Droppable
          droppableId={"habitWeekPlan." + weekPlanId}
          isDropDisabled={isHabitWeekPlanInPast(weekPlan.date)}
        >
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {draggableHabitWeeks.length >= 1 ? (
                <>
                  {draggableHabitWeeks.map((habitWeek, habitWeekIndex) => {
                    const realHabitWeekIndex =
                      habitWeekIndex + nonDraggableHabitWeeks.length;

                    return (
                      <Draggable
                        draggableId={
                          weekPlanId +
                          "." +
                          habitWeek.id +
                          "." +
                          realHabitWeekIndex
                        }
                        index={realHabitWeekIndex}
                        key={habitWeek.id}
                        isDragDisabled={
                          isHabitWeekPlanCurrentWeek(weekPlan.date) ||
                          isHabitWeekPlanInPast(weekPlan.date)
                        }
                      >
                        {(provided, snapshot) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <HabitWeek
                              isDragging={snapshot.isDragging}
                              key={habitWeek.id}
                              habitWeekId={habitWeek.id}
                              weekPlanId={weekPlanId}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    p: 2,
                    backgroundColor: (theme) =>
                      snapshot.isDraggingOver
                        ? alpha(theme.palette.primary.main, 0.2)
                        : undefined,
                    transition: "background-color 0.2s ease",
                  }}
                >
                  <AddRoundedIcon
                    sx={{
                      color: (theme) =>
                        snapshot.isDraggingOver
                          ? theme.palette.primary.main
                          : theme.palette.text.secondary,
                      mb: 1,
                      transition: "color 0.2s ease",
                    }}
                  />
                  <Typography
                    sx={{
                      color: (theme) =>
                        snapshot.isDraggingOver
                          ? theme.palette.primary.main
                          : theme.palette.text.secondary,
                      transition: "color 0.2s ease",
                    }}
                  >
                    Drag in habits
                  </Typography>
                </Box>
              )}
            </div>
          )}
        </Droppable>
      </Box>
      <Popover
        open={open}
        onClose={() => {
          setAnchorEl(null);
          setNotesDayIndex(null);
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        <Box sx={{ p: 2 }}>
          <TextFieldWithLimit
            multiline
            fullWidth
            autoFocus
            helperText={
              "These notes will be shown to the client on the home screen"
            }
            name="weekPlan.dayNotes"
            onSave={(newValue) => {
              if (notesDayIndex === null) {
                return;
              }

              if (isBlank(newValue)) {
                dispatch(
                  setDayNotes({
                    planId: weekPlanId,
                    dayIndex: notesDayIndex,
                    newNotes: null,
                  }),
                );
              } else {
                dispatch(
                  setDayNotes({
                    planId: weekPlanId,
                    dayIndex: notesDayIndex,
                    newNotes: newValue,
                  }),
                );
              }
            }}
            onKeyDownEnter={() => {
              setAnchorEl(null);
              setNotesDayIndex(null);
            }}
            defaultValue={weekPlan.notes_coach_days[notesDayIndex ?? 0]}
            characterLimit={93}
          />
        </Box>
      </Popover>
      <Menu
        anchorEl={copyMenuAnchorEl}
        open={copyMenuOpen}
        onClose={() => {
          setCopyMenyAnchorEl(null);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setCopyMenyAnchorEl(null);

            dispatch(addHabitWeekToNextWeek(weekPlanId));
          }}
        >
          Copy to next week
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCopyMenyAnchorEl(null);

            dispatch(duplicateHabitWeekPlan({ weekPlanId }));
          }}
        >
          Copy to brand new week
        </MenuItem>
      </Menu>
      {isThisWeek && firstWeekDialogOpen && (
        <FirstWeekDialog
          userId={weekPlan.user_id}
          open={firstWeekDialogOpen}
          onClose={() => {
            setFirstWeekDialogOpen(false);
          }}
        />
      )}
      <PhaseExplanationDialog
        userId={weekPlan.user_id}
        open={phaseExplanationDialogOpen}
        onClose={(sent) => {
          setPhaseExplanationDialogOpen(false);

          if (sent) {
            dispatch(togglePlanPublished(weekPlanId));
          }
        }}
      />
    </div>
  );
});

export default WeekPlan;
