import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PodcastsRoundedIcon from "@mui/icons-material/PodcastsRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  campaignRatings,
  campaignSponsorTypes,
  campaignTypes,
  useDeleteCampaign,
  type Campaign,
  type CampaignRating,
} from "@trainwell/features";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { getDateFromDatabase } from "src/lib/date";
import { CampaignClientCell } from "./CampaignClientCell";
import { CampaignClientEditDialog } from "./CampaignClientEditDialog";
import { CampaignEditDialog } from "./CampaignEditDialog";
import { CampaignShortUrlCell } from "./CampaignShortUrlCell";

type Props = {
  campaign: Campaign;
};

export function CampaignCell({ campaign }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const deleteCampaignMutation = useDeleteCampaign({
    mutationConfig: {
      onSuccess: () => {
        enqueueSnackbar("Deleted", {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar("Error deleting campaign", {
          variant: "error",
        });
      },
    },
  });
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const [campaignUserDialogOpen, setCampaignUserDialogOpen] = useState(false);

  return (
    <Card
      variant="outlined"
      sx={{
        p: 2,
        // backgroundColor: (theme) => alpha("#000", 0.025),
        borderWidth: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {campaign.type === "youtube" ? (
            <YouTubeIcon />
          ) : campaign.type === "podcast" ? (
            <PodcastsRoundedIcon />
          ) : campaign.type === "email" ? (
            <EmailRoundedIcon />
          ) : campaign.type === "instagram" ? (
            <InstagramIcon />
          ) : campaign.type === "tik_tok" ? (
            <PhoneAndroidIcon />
          ) : (
            <QuestionMarkRoundedIcon />
          )}
          <Box sx={{ ml: 2 }}>
            <Typography variant="h3">
              {campaignTypes[campaign.type]}{" "}
              <Typography component={"span"} sx={{ fontWeight: "normal" }}>
                (released{" "}
                {format(
                  getDateFromDatabase(campaign.date_content_released),
                  "MMM d, yyyy",
                )}
              </Typography>
              )
            </Typography>
            <Typography>
              {campaign.sponsor_type
                ? campaignSponsorTypes[campaign.sponsor_type]
                : "--"}
              , ${campaign.cost?.toLocaleString()}
            </Typography>
          </Box>
        </Box>
        <IconButton
          size="small"
          onClick={(event) => {
            setMoreAnchorEl(event.currentTarget);
          }}
        >
          <MoreVertRoundedIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {campaign.is_new_partner && <Chip label="New partner" size="small" />}
          {campaign.is_fitness_themed && (
            <Chip label="Fitness themed" size="small" />
          )}
          {campaign.is_health_themed && (
            <Chip label="Health themed" size="small" />
          )}
          {campaign.is_faceless && <Chip label="Faceless" size="small" />}
          {campaign.is_shown_working_out && (
            <Chip label="Shown working out" size="small" />
          )}
          {campaign.genuineness && (
            <Chip
              label={`Genuineness: ${campaignRatings[campaign.genuineness].toLocaleLowerCase()}`}
              size="small"
              color={getRatingColor(campaign.genuineness)}
            />
          )}
          {campaign.integration_smoothness && (
            <Chip
              label={`Integration smoothness: ${campaignRatings[campaign.integration_smoothness].toLocaleLowerCase()}`}
              size="small"
              color={getRatingColor(campaign.integration_smoothness)}
            />
          )}
          {campaign.passion && (
            <Chip
              label={`Passion: ${campaignRatings[campaign.passion].toLocaleLowerCase()}`}
              size="small"
              color={getRatingColor(campaign.passion)}
            />
          )}
          {campaign.detail_of_experience && (
            <Chip
              label={`Detail of experience: ${campaignRatings[campaign.detail_of_experience].toLocaleLowerCase()}`}
              size="small"
              color={getRatingColor(campaign.detail_of_experience)}
            />
          )}
          {campaign.trainer_relationship && (
            <Chip
              label={`Trainer relationship: ${campaignRatings[campaign.trainer_relationship].toLocaleLowerCase()}`}
              size="small"
              color={getRatingColor(campaign.trainer_relationship)}
            />
          )}
          {campaign.progress_shared && (
            <Chip
              label={`Progress shared: ${campaignRatings[campaign.progress_shared].toLocaleLowerCase()}`}
              size="small"
              color={getRatingColor(campaign.progress_shared)}
            />
          )}
        </Box>
        <Box>
          <Typography sx={{ fontWeight: "bold" }}>Expected views</Typography>
          <Typography>
            {campaign.expected_views
              ? campaign.expected_views.toLocaleString()
              : "--"}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: "bold" }}>Content url</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link
              href={campaign.url_content}
              target="_blank"
              sx={{
                overflowWrap: "break-word",
                wordBreak: "break-all",
              }}
            >
              {campaign.url_content}
            </Link>
            <Tooltip
              title="Copy to clipboard"
              placement="right"
              disableInteractive
            >
              <IconButton
                size="small"
                color="default"
                sx={{ ml: 0.5 }}
                onClick={() => {
                  navigator.clipboard
                    .writeText(campaign.url_content)
                    .then(() => {
                      enqueueSnackbar("Copied to clipboard", {
                        variant: "success",
                      });
                    });
                }}
              >
                <ContentPasteRoundedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <CampaignShortUrlCell shortUrlId={campaign.short_url_id} />
        <Card variant="outlined" sx={{ p: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PersonRoundedIcon fontSize="inherit" />
            <Typography sx={{ fontWeight: "bold", ml: 1 }}>
              Campaign users
            </Typography>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                setCampaignUserDialogOpen(true);
              }}
              sx={{ ml: 1 }}
            >
              Add campaign user
            </Button>
          </Box>
          <Stack spacing={1}>
            {campaign.users?.map((user) => (
              <CampaignClientCell
                influencerId={campaign.influencer_id}
                key={user.user_id}
                campaignUser={user}
                campaign={campaign}
              />
            ))}
          </Stack>
        </Card>
      </Stack>
      <CampaignEditDialog
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
        }}
        influencerId={campaign.influencer_id}
        defaultCampaign={campaign}
      />
      <CampaignClientEditDialog
        open={campaignUserDialogOpen}
        onClose={() => {
          setCampaignUserDialogOpen(false);
        }}
        campaign={campaign}
        influencerId={campaign.influencer_id}
      />
      <Menu
        open={Boolean(moreAnchorEl)}
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setMoreAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            setEditDialogOpen(true);

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <EditRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <Box>
          <Divider sx={{ mb: 1, mt: 2 }} />
        </Box>
        <MenuItem
          onClick={async () => {
            deleteCampaignMutation.mutate({ campaignId: campaign._id });

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <DeleteRoundedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            sx={{ color: (theme) => theme.palette.error.main }}
          />
        </MenuItem>
      </Menu>
    </Card>
  );
}

function getRatingColor(rating: CampaignRating) {
  switch (rating) {
    case "bad":
      return "errorSurface";
    case "medium":
      return "warningSurface";
    case "good":
      return "default";
    case "spectacular":
      return "successSurface";
    default:
      return "default";
  }
}
