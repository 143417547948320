import { Box, Card, Typography, type SxProps, type Theme } from "@mui/material";
import { useInfluencer } from "@trainwell/features";
import LoadingComponent from "src/components/miscPages/LoadingComponent";

type Props = {
  influencerId: string;
  sx: SxProps<Theme>;
};

export function InfluencerSummaryCard({ influencerId, sx = [] }: Props) {
  const { data: influencer, isLoading: isLoadingInfluencer } = useInfluencer({
    influencerId: influencerId ?? "",
  });

  if (isLoadingInfluencer) {
    return <LoadingComponent message="Loading influencer" />;
  }

  if (!influencer) {
    return <Typography>Error finding influencer</Typography>;
  }

  return (
    <Card
      variant="outlined"
      sx={{ p: 2, display: "flex", alignItems: "center", ...sx }}
    >
      {influencer.assets.splash_asset && (
        <img
          height={100}
          src={influencer.assets.splash_asset}
          style={{
            objectFit: "contain",
          }}
        />
      )}
      <Box
        sx={{
          ml: influencer.assets.splash_asset ? 2 : undefined,
        }}
      >
        <Typography variant="h1">{influencer.display_name}</Typography>
        <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>
          Influencer id: {influencer.id}
        </Typography>
      </Box>
    </Card>
  );
}
