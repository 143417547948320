import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { DialogTitleWithClose } from "./DialogTitleWithClose";

type Props = {
  open: boolean;
  onClose: (didConfirm: boolean) => void;
  title: string;
  message: string;
  variant?: "default" | "error";
  confirmButtonText?: string;
};

export default function ConfirmationDialog({
  open,
  onClose,
  title,
  message,
  variant = "default",
  confirmButtonText = "Continue",
}: Props) {
  return (
    <Dialog
      onClose={() => {
        onClose(false);
      }}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleWithClose
        onClose={() => {
          onClose(false);
        }}
      >
        {title}
      </DialogTitleWithClose>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color={variant === "error" ? "error" : "primary"}
          onClick={() => {
            onClose(true);
          }}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
