import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { deepOrange, deepPurple, lightGreen, teal } from "@mui/material/colors";
import { startOfToday, subDays } from "date-fns";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { roundedDecimal } from "src/lib/miscUtility";
import { api } from "src/lib/trainwellApi";
import { fetchAllTests } from "src/slices/testsSlice";
import Comparisons from "./Comparisons";
import { DateRange, useComparison } from "./useComparison";

const dataPoints = [
  { key: "membershipStarted", label: "Membership started" },
  { key: "scheduledOnboardingCall", label: "Scheduled onboarding call" },
  { key: "openedApp", label: "Opened app" },
  { key: "watchedWelcomeVideo", label: "Watched welcome video" },
  { key: "hadOnboardingCall", label: "Had onboarding call" },
  { key: "hasWorkedOut", label: "Has worked out" },
  { key: "paid", label: "Paid" },
];

export default function FunnelClientPage() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<Record<string, any>>([]);
  const abTestsStatus = useAppSelector((state) => state.tests.status);
  const [
    comparisons,
    gettingPossibles,
    dateRange,
    loading,
    setLoading,
    setDidDatesChange,
    setComparisons,
    getPossibles,
    handleChangeDateRange,
  ] = useComparison();

  useEffect(() => {
    if (abTestsStatus === "idle") {
      dispatch(fetchAllTests());
    }
  }, [abTestsStatus, dispatch]);

  useEffect(() => {
    handleRefresh();
  }, []);

  async function handleRefresh() {
    setLoading(true);

    console.log("Analytics: refreshing events");

    const newData: Record<string, any> = [];

    await Promise.all(
      comparisons.map(async (comparison) => {
        console.log("Comparison: ", comparison);

        let startDate = new Date(
          comparison.startDate ?? new Date(),
        ).toISOString();
        let endDate = new Date(comparison.endDate ?? new Date()).toISOString();

        if (dateRange === DateRange.Last24Hours) {
          startDate = subDays(new Date(), 1).toISOString();
          endDate = new Date().toISOString();
        } else if (dateRange === DateRange.Today) {
          startDate = startOfToday().toISOString();
          endDate = new Date().toISOString();
        } else if (dateRange === DateRange.LastThreeDays) {
          startDate = subDays(new Date(), 3).toISOString();
          endDate = new Date().toISOString();
        } else if (dateRange === DateRange.LastWeek) {
          startDate = subDays(new Date(), 7).toISOString();
          endDate = new Date().toISOString();
        } else if (dateRange === DateRange.LastMonth) {
          startDate = subDays(new Date(), 30).toISOString();
          endDate = new Date().toISOString();
        }

        const result = await api.analytics.getClientFunnelData({
          startDate: startDate,
          endDate: endDate,
          sourceID: comparison.sourceID,
          utmSource: comparison.utmSource,
          utmCampaign: comparison.utmCampaign,
          utmMedium: comparison.utmMedium,
          utmContent: comparison.utmContent,
          osName: comparison.osName,
          browserName: comparison.browserName,
          deviceType: comparison.deviceType,
          testsInclude: comparison.testsInclude,
          testsExclude: comparison.testsExclude,
        });
        console.log("Result: ", result);

        newData[comparison.id] = result;
      }),
    );

    console.log("Data: ", data);
    setData(newData);

    setLoading(false);
  }

  const colors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    lightGreen[700],
    deepPurple[700],
    deepOrange[700],
    teal[700],
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h1">Client funnel</Typography>
      <Box sx={{ my: 4, height: "450px" }}>
        <Box
          sx={{
            mb: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Comparisons
            comparisons={comparisons}
            setComparisons={setComparisons}
            setDidDatesChange={setDidDatesChange}
            getPossibles={getPossibles}
            dateRange={dateRange}
            gettingPossibles={gettingPossibles}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControl size="small">
              <InputLabel>Date range</InputLabel>
              <Select
                value={dateRange}
                label="Date range"
                onChange={handleChangeDateRange}
              >
                <MenuItem value={DateRange.Today}>Today</MenuItem>
                <MenuItem value={DateRange.Last24Hours}>Last 24 hours</MenuItem>
                <MenuItem value={DateRange.LastThreeDays}>Last 3 days</MenuItem>
                <MenuItem value={DateRange.LastWeek}>Last 7 days</MenuItem>
                <MenuItem value={DateRange.LastMonth}>Last 30 days</MenuItem>
                <MenuItem value={DateRange.Custom}>Custom</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <LoadingButton
          sx={{ mb: 2 }}
          onClick={handleRefresh}
          loading={loading}
          disabled={loading}
        >
          Refresh
        </LoadingButton>
        <Grid container spacing={2}>
          {dataPoints.map((dataPoint) => (
            <Grid item xs={3} key={dataPoint.key}>
              <Card variant="outlined" sx={{ p: 2 }}>
                <Typography variant="h2" sx={{ pb: 2 }}>
                  {dataPoint.label}
                </Typography>
                {comparisons.map((comparison, i) => (
                  <Box key={comparison.id}>
                    <Typography
                      sx={{
                        fontSize: 24,
                        fontWeight: "bold",
                        color: colors[i],
                        display: "inline",
                      }}
                    >
                      {data[comparison.id]?.[dataPoint.key]}{" "}
                    </Typography>
                    {dataPoint.key !== "membershipStarted" && (
                      <Typography
                        sx={{
                          color: colors[i],
                          display: "inline",
                        }}
                      >
                        {dataPoint.key !== "membershipStarted" &&
                          roundedDecimal(
                            (data[comparison.id]?.[dataPoint.key] /
                              data[comparison.id]?.["membershipStarted"]) *
                              100,
                          )}
                        %{i !== comparisons.length - 1 ? ", " : ""}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
