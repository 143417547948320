import { useDraggable } from "@dnd-kit/core";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {
  Avatar,
  Box,
  Button,
  CardActionArea,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";
import { getSmartTagDetails } from "src/lib/phaseTags";
import { api } from "src/lib/trainwellApi";
import {
  deletePhaseTemplate,
  downloadPhaseTemplate,
  duplicatePhaseTemplate,
  savePhaseTemplateToTrainerLibrary,
  selectCanEditTrainwellLibrary,
  selectPhaseTemplateById,
  selectUsedPhaseTemplateTags,
  sharePhaseTemplateToCoach,
  updatePhaseTemplate,
} from "src/slices/phaseTemplatesSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { openWorkoutTemplate } from "src/slices/workoutBuilderSlice";
import { ShareToCoachPopover } from "../ClientPage/SelectorColumn/ShareToCoachPopover";
import WorkoutPreviewPopover from "../ClientPage/WorkoutPreviewPopover";
import { TagList } from "../misc/TagList";

type Props = {
  phaseTemplateId: string;
};

export default function WorkoutTemplateCell({ phaseTemplateId }: Props) {
  const dispatch = useAppDispatch();
  const phaseTemplate = useAppSelector((state) =>
    selectPhaseTemplateById(state, phaseTemplateId!),
  );
  const openForUserId = useAppSelector(
    (state) => state.phaseTemplates.openForUserId,
  );
  const isDialog = Boolean(openForUserId);
  const canEdit = useAppSelector(selectCanEditTrainwellLibrary)
    ? true
    : phaseTemplate?.trainer_id !== "copilot";
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: phaseTemplate?.draggable_id!,
    disabled: !canEdit,
    data: {
      type: "workout_template",
      workoutTemplateId: phaseTemplate?.workout_template_ids[0][0],
      phaseTemplateId: phaseTemplate?._id,
    },
  });
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const [previewAnchorEl, setPreviewAnchorEl] =
    useState<null | HTMLButtonElement>(null);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const realCoach = useAppSelector((state) => state.trainer.trainer);
  const { enqueueSnackbar } = useSnackbar();
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);
  const [shareToCoachAnchorEl, setShareToCoachAnchorEl] =
    useState<null | HTMLButtonElement>(null);
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)!.trainer_id,
  );
  const possibleTags = useAppSelector((state) =>
    selectUsedPhaseTemplateTags(
      state,
      state.phaseTemplates.currentTab === "trainer" ? trainerId : "copilot",
    ),
  );
  const [saved, setSaved] = useState(false);

  function handleCloseContextMenu() {
    setContextMenu(null);
    setMoreAnchorEl(null);
  }

  if (!phaseTemplate) {
    return null;
  }

  return (
    <>
      <CardActionArea
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        onClick={() => {
          dispatch(
            openWorkoutTemplate(phaseTemplate.workout_template_ids[0][0]),
          );
        }}
        onContextMenu={(event) => {
          event.preventDefault();
          event.stopPropagation();

          setPreviewAnchorEl(event.currentTarget);
        }}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          py: 0.5,
          pr: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexShrink: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {phaseTemplate.is_pinned ? (
            <PushPinRoundedIcon
              sx={{
                fontSize: 12,
                width: "20px",
                color: (theme) => theme.palette.text.secondary,
              }}
            />
          ) : (
            <Box sx={{ width: "20px" }} />
          )}
          <Avatar
            sx={{
              backgroundColor: (theme) => theme.palette.blue.main,
              width: 20,
              height: 20,
              borderRadius: "6px",
            }}
          >
            <FitnessCenterRoundedIcon
              sx={{
                fontSize: 16,
              }}
            />
          </Avatar>
          <Box
            sx={{
              px: 2,
            }}
          >
            <Typography variant="h6">{phaseTemplate.name}</Typography>
            <Typography variant="overline">
              Updated{" "}
              {formatDistanceToNow(
                new Date(
                  phaseTemplate.date_updated ?? phaseTemplate.date_created,
                ),
              )}{" "}
              ago
            </Typography>
            <Typography>{phaseTemplate.description}</Typography>
            {phaseTemplate.shared_by_trainer_id && (
              <Chip
                sx={{ mt: 0.5 }}
                avatar={
                  <Avatar
                    alt="Trainer"
                    src={
                      trainerNames.find(
                        (c) =>
                          c.trainer_id === phaseTemplate.shared_by_trainer_id,
                      )?.headshot_url ?? ""
                    }
                  />
                }
                label={`Shared by ${trainerNames.find((c) => c.trainer_id === phaseTemplate.shared_by_trainer_id)?.full_name ?? "Unknown"}`}
                size="small"
                color="blueSurface"
              />
            )}
            <TagList
              value={(phaseTemplate.tags ?? []).map((t) => {
                const tagDetails = getSmartTagDetails(t);

                return {
                  id: t,
                  icon: tagDetails.icon,
                  label: tagDetails.label ?? t,
                };
              })}
              size="small"
              options={possibleTags.map((t) => {
                const tagDetails = getSmartTagDetails(t);

                return {
                  id: t,
                  icon: tagDetails.icon,
                  label: tagDetails.label ?? t,
                };
              })}
              onAdd={(tagId) => {
                dispatch(
                  updatePhaseTemplate({
                    id: phaseTemplateId,
                    tags: [...(phaseTemplate?.tags ?? []), tagId],
                  }),
                );
              }}
              onRemove={(tagId) => {
                const newTags = [
                  ...(phaseTemplate.tags ?? []).filter((t) => t !== tagId),
                ];

                dispatch(
                  updatePhaseTemplate({
                    id: phaseTemplateId,
                    tags: newTags,
                  }),
                );
              }}
              sx={{ mt: 1 }}
            />
            {isDialog && (
              <Button
                size="small"
                variant="contained"
                // fullWidth
                startIcon={<PersonRoundedIcon />}
                sx={{ mt: 1 }}
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(
                    downloadPhaseTemplate({
                      phaseTemplateId: phaseTemplate._id,
                      userId: openForUserId!,
                    }),
                  );
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              >
                Copy to client
              </Button>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            flex: "1 0 auto",
            justifyContent: "flex-end",
            alignItems: "center",
            display: "flex",
          }}
        >
          {phaseTemplate.trainer_id === "copilot" && (
            <Tooltip title="Save to your library" disableInteractive>
              <Button
                variant="text"
                size="small"
                startIcon={
                  saved ? <CheckRoundedIcon /> : <DownloadRoundedIcon />
                }
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(
                    savePhaseTemplateToTrainerLibrary({
                      phaseTemplateId: phaseTemplateId,
                      trainerId: trainerId,
                    }),
                  ).then(() => {
                    setSaved(true);
                  });
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                color={saved ? "success" : "primary"}
              >
                {saved ? "Saved" : "Save"}
              </Button>
            </Tooltip>
          )}
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();

              setMoreAnchorEl(event.currentTarget);
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
            disabled={!canEdit}
          >
            <MoreVertRoundedIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </CardActionArea>
      <Menu
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(moreAnchorEl) || Boolean(contextMenu)}
        onClose={() => {
          handleCloseContextMenu();
        }}
      >
        <MenuItem
          onClick={() => {
            dispatch(
              updatePhaseTemplate({
                id: phaseTemplateId,
                isPinned: !phaseTemplate.is_pinned,
              }),
            )
              .unwrap()
              .catch(() => {
                enqueueSnackbar("Error pinning / unpinning", {
                  variant: "error",
                });
              });

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            {phaseTemplate.is_pinned ? (
              <RemoveCircleRoundedIcon fontSize="small" />
            ) : (
              <PushPinRoundedIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText primary={phaseTemplate.is_pinned ? "Unpin" : "Pin"} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(
              duplicatePhaseTemplate({
                phaseTemplateId: phaseTemplateId,
              }),
            );
            handleCloseContextMenu();
          }}
        >
          <ListItemIcon>
            <ContentCopyRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Make a copy" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShareToCoachAnchorEl(moreAnchorEl);

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <IosShareRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Share to trainer" />
        </MenuItem>
        {trainerHasAccess(
          realCoach?.access_roles,
          "trainwell_template_library",
        ) && (
          <MenuItem
            onClick={() => {
              setMoreAnchorEl(null);

              dispatch(
                sharePhaseTemplateToCoach({
                  phaseTemplateId,
                  trainerId: "copilot",
                }),
              )
                .unwrap()
                .then(() => {
                  enqueueSnackbar("Shared", {
                    variant: "success",
                  });
                })
                .catch(() => {
                  enqueueSnackbar("Error sharing", {
                    variant: "error",
                  });
                });
            }}
          >
            <ListItemIcon>
              <IosShareRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Share to trainwell" />
          </MenuItem>
        )}
        <Box>
          <Divider sx={{ mb: 1, mt: 2 }} />
        </Box>
        <MenuItem
          onClick={() => {
            dispatch(deletePhaseTemplate({ phaseTemplateId: phaseTemplateId }));
            handleCloseContextMenu();
          }}
        >
          <ListItemIcon>
            <DeleteRoundedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            sx={{ color: (theme) => theme.palette.error.main }}
          />
        </MenuItem>
      </Menu>
      <WorkoutPreviewPopover
        templateId={phaseTemplate.workout_template_ids[0][0]}
        anchorEl={previewAnchorEl}
        onClose={() => {
          setPreviewAnchorEl(null);
        }}
      />
      <ShareToCoachPopover
        onShare={(targetTrainerId) => {
          return api.phaseTemplates.shareToCoach({
            phaseTemplateId,
            trainerId: targetTrainerId,
          });
        }}
        anchorEl={shareToCoachAnchorEl}
        onClose={() => {
          setShareToCoachAnchorEl(null);
        }}
      />
    </>
  );
}
