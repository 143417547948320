import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { createAudit, fetchAudits } from "src/slices/clientSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import AuditCell from "./AuditCell";

type Props = {
  userId: string;
  freeFloating?: boolean;
};

export default function AuditPanel({ userId, freeFloating = false }: Props) {
  const dispatch = useAppDispatch();
  const auditsStatus = useAppSelector((state) => state.client.auditsStatus);
  const audits = useAppSelector((state) => state.client.audits);
  const nonAuditCoach = useAppSelector((state) => state.trainer.trainer);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [isExpanded, setIsExpanded] = useState(false);

  const displayedAudits = nonAuditCoach?.is_admin
    ? audits
    : audits.filter(
        (audit) => audit.auditor_trainer_id === trainer?.trainer_id,
      );

  useEffect(() => {
    if (auditsStatus === "idle" && isExpanded) {
      dispatch(fetchAudits(userId));
    }
  }, [auditsStatus, userId, dispatch, isExpanded]);

  return (
    <Accordion
      disableGutters
      elevation={freeFloating ? undefined : 0}
      square={!freeFloating}
      onChange={() => {
        setIsExpanded(!isExpanded);
      }}
      expanded={isExpanded}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">🔍 Audits</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {auditsStatus === "loading" ? (
            <LoadingComponent />
          ) : (
            <>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                <Button
                  fullWidth
                  startIcon={<AddRoundedIcon />}
                  onClick={() => {
                    dispatch(createAudit(userId));
                  }}
                >
                  Start new audit
                </Button>
              </Box>
              <Stack spacing={2} direction="column">
                {displayedAudits.map((audit) => {
                  return <AuditCell audit={audit} key={audit.id} />;
                })}
              </Stack>
            </>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
