import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import type { Client } from "@trainwell/types";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Label from "src/components/misc/Label";
import { SmartEditRichTextField } from "src/components/misc/SmartEditRichTextField";
import SmartEditSelect from "src/components/misc/SmartEditSelect";
import SmartEditTextField from "src/components/misc/SmartEditTextField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import { api } from "src/lib/trainwellApi";
import { updateAllClients } from "src/slices/allClientsSlice";
import { openChat } from "src/slices/chatSlice";
import {
  setClientEdit,
  updateClientEditLocal,
} from "src/slices/clientEditSlice";
import {
  updateClient,
  updateClientEmail,
  updateClientPhoneNumber,
} from "src/slices/clientSlice";
import { fetchAuditeeTrainer } from "src/slices/trainerSlice";
import { ClientDetailSidebar } from "./ClientDetailSidebar";

type Props = {
  client: Client;
};

export default function TabInfo({ client }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);
  const [customClaims, setCustomClaims] =
    useState<Awaited<ReturnType<(typeof api)["clients"]["getCustomClaims"]>>>();
  const [changeCoachAnchorEl, setChangeCoachAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const changeCoachOpen = Boolean(changeCoachAnchorEl);
  const [hasTempLog, setHasTempLog] = useState(false);

  useEffect(() => {
    if (client.user_id) {
      api.clients.getCustomClaims(client.user_id).then((claims) => {
        setCustomClaims(claims);
      });

      api.clients.getTempLog(client.user_id).then((log) => {
        setHasTempLog(Boolean(log));
      });
    }
  }, [client.user_id]);

  const trainer = trainerNames.find(
    (trainer) => trainer.trainer_id === client.trainer_id,
  );

  const possibleValues = trainerNames
    .filter((c) => (c.is_employee || c.is_staff) && !c.was_employee)
    .map((trainer) => {
      return {
        value: trainer.trainer_id,
        label: trainer.full_name,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const switchLog = [...(client.coach_switch_log ?? [])].reverse();

  return (
    <>
      {client.account.membership.date_requested_deletion &&
        !client.account.membership.date_deleted && (
          <Card
            sx={{
              px: 2,
              py: 0.5,
              mb: 2,
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.2),
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            variant="outlined"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <WarningRoundedIcon fontSize="inherit" />
              <Typography sx={{ ml: 1 }}>
                Deletion requested on{" "}
                {format(
                  new Date(client.account.membership.date_requested_deletion),
                  "MMMM do, yyyy 'at' h:mm a",
                )}
              </Typography>
            </Box>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                api.clients.revokeDeletion(client.user_id).then(() => {
                  dispatch(
                    updateClientEditLocal({
                      user_id: client.user_id,
                      // @ts-expect-error
                      "account.membership.date_requested_deletion": null,
                    }),
                  );
                });
              }}
            >
              Don&apos;t delete
            </Button>
          </Card>
        )}
      {client.account.membership.suspension &&
        !client.account.membership.date_deleted && (
          <Card
            sx={{
              px: 2,
              py: 0.5,
              mb: 2,
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.2),
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            variant="outlined"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <WarningRoundedIcon fontSize="inherit" />
              <Typography sx={{ ml: 1 }}>
                Suspension scheduled for{" "}
                {format(
                  new Date(client.account.membership.suspension.date_start),
                  "MMMM do, yyyy 'at' h:mm a",
                )}{" "}
                to{" "}
                {format(
                  new Date(client.account.membership.suspension.date_end),
                  "MMMM do, yyyy 'at' h:mm a",
                )}
              </Typography>
            </Box>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                dispatch(
                  updateClient({
                    user_id: client.user_id,
                    // @ts-expect-error
                    "account.membership.suspension": null,
                  }),
                );
              }}
            >
              Remove suspension
            </Button>
          </Card>
        )}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ClientDetailSidebar userId={client.user_id} />
        </Grid>
        <Grid item xs={8}>
          <Stack direction="column" spacing={2}>
            <Card variant="outlined">
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Typography variant="h2">Trainer</Typography>
                  <Button
                    size="small"
                    startIcon={<SwapHorizRoundedIcon />}
                    onClick={(event) => {
                      setChangeCoachAnchorEl(event.currentTarget);
                    }}
                    disabled={Boolean(client.account.membership.date_deleted)}
                  >
                    Swap trainer
                  </Button>
                  <Popover
                    open={changeCoachOpen}
                    anchorEl={changeCoachAnchorEl}
                    onClose={() => {
                      setChangeCoachAnchorEl(null);
                    }}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{ sx: { p: 1, minWidth: "300px" } }}
                    disableRestoreFocus
                  >
                    <Autocomplete
                      size="small"
                      options={possibleValues}
                      renderInput={(params) => <TextField {...params} />}
                      getOptionLabel={(option) => option.label}
                      value={possibleValues.find(
                        (option) => option.value === client.trainer_id,
                      )}
                      onChange={(event: any, newValue) => {
                        if (newValue) {
                          const value = newValue.value;

                          api.clients.changeCoach({
                            userId: client.user_id,
                            trainerId: value,
                            reason: "manual_staff",
                          });
                          const newClient = JSON.parse(
                            JSON.stringify(client),
                          ) as Client;
                          newClient.trainer_id = value;
                          dispatch(updateAllClients(newClient));
                          dispatch(setClientEdit(newClient));

                          setChangeCoachAnchorEl(null);
                        }
                      }}
                    />
                  </Popover>
                </Box>
                {!trainer ? (
                  <Typography>No trainer found</Typography>
                ) : (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <img
                        style={{
                          borderRadius: "25px",
                          display: "block",
                        }}
                        src={trainer.headshot_url || "/assets/profile.png"}
                        alt={trainer.full_name}
                        width={50}
                        height={50}
                      />
                      <Box sx={{ ml: 2 }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {trainer.full_name}
                        </Typography>
                        <Typography
                          sx={{
                            color: (theme) => theme.palette.text.secondary,
                          }}
                        >
                          Trainer id: {trainer.trainer_id}
                        </Typography>
                      </Box>
                    </Box>
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="text"
                        size="small"
                        href={`/admin/company/team/${trainer.trainer_id}`}
                        endIcon={<OpenInNewRoundedIcon />}
                      >
                        Admin dash
                      </Button>
                      <Button
                        variant="text"
                        size="small"
                        endIcon={<LoginRoundedIcon />}
                        onClick={(event) => {
                          event.stopPropagation();

                          dispatch(fetchAuditeeTrainer(trainer.trainer_id));

                          navigate("/");
                        }}
                      >
                        Ghost
                      </Button>
                    </Stack>
                  </>
                )}
              </Box>
              <Accordion
                disableGutters
                elevation={0}
                square
                disabled={
                  !client.coach_switch_log ||
                  client.coach_switch_log.length === 0
                }
              >
                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Swap history ({switchLog.length})
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Stack direction="column">
                    {switchLog.map((log, i) => (
                      <Box
                        key={i}
                        sx={{
                          py: 1,
                          px: 2,
                          borderBottom: 1,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Typography>
                            {getTrainerName(log.trainer_id_old, trainerNames)}{" "}
                            -&gt;{" "}
                            <b>
                              {getTrainerName(log.trainer_id_new, trainerNames)}
                            </b>
                          </Typography>
                          {log.reason && (
                            <Typography>Reason: {log.reason}</Typography>
                          )}
                          <Typography variant="overline">
                            Automatic: {log.automatic ? "true" : "false"}
                          </Typography>
                          <Typography variant="overline">
                            {format(
                              new Date(log.date),
                              "MMMM do, yyyy h:mm aaa",
                            )}
                          </Typography>
                        </Box>
                        <Tooltip
                          title={`Old chat with ${getTrainerName(
                            log.trainer_id_old,
                            trainerNames,
                          )}`}
                          disableInteractive
                        >
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              dispatch(
                                openChat({
                                  chatId: client.user_id,
                                  forceTrainerId: log.trainer_id_old,
                                }),
                              );
                            }}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            sx={{ mx: 1 }}
                          >
                            <ChatRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Card>
            <Card sx={{ p: 2 }} variant="outlined">
              <Typography variant="h2" sx={{ mb: 2 }}>
                General
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <SmartEditTextField
                    label="First name"
                    value={client.first_name}
                    onSave={(value) => {
                      dispatch(
                        updateClient({
                          user_id: client.user_id,
                          first_name: value as string,
                          full_name: `${value} ${client.last_name}`,
                        }),
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SmartEditTextField
                    label="Last name"
                    value={client.last_name}
                    onSave={(value) => {
                      dispatch(
                        updateClient({
                          user_id: client.user_id,
                          last_name: value as string,
                          full_name: `${client.first_name} ${value}`,
                        }),
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SmartEditTextField
                    label="Email"
                    value={client.email}
                    onSave={(value) => {
                      dispatch(
                        updateClientEmail({
                          userId: client.user_id,
                          email: value as string,
                        }),
                      )
                        .unwrap()
                        .catch(() => {
                          enqueueSnackbar("Error updating email", {
                            variant: "error",
                          });
                        });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SmartEditTextField
                    label="Phone number"
                    value={client.phone_number}
                    onSave={(value) => {
                      dispatch(
                        updateClientPhoneNumber({
                          userId: client.user_id,
                          phoneNumber: value as string,
                        }),
                      )
                        .unwrap()
                        .catch(() => {
                          enqueueSnackbar("Error updating phone number", {
                            variant: "error",
                          });
                        });
                    }}
                    validationFunction={(phoneNumber) => {
                      const regEx = /^\+[1-9]\d{10,14}$/;

                      return regEx.test(phoneNumber);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SmartEditTextField
                    label="Age"
                    value={client.age}
                    inputType="number"
                    onSave={(value) => {
                      dispatch(
                        updateClient({
                          user_id: client.user_id,
                          age: value as number,
                        }),
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SmartEditTextField
                    label="Height"
                    value={client.height}
                    inputType="number"
                    onSave={(value) => {
                      dispatch(
                        updateClient({
                          user_id: client.user_id,
                          height: value as number,
                        }),
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SmartEditTextField
                    label="Starting weight"
                    value={client.starting_weight}
                    inputType="number"
                    onSave={(value) => {
                      dispatch(
                        updateClient({
                          user_id: client.user_id,
                          starting_weight: value as number,
                        }),
                      );
                    }}
                  />
                </Grid>
                {!client.account.dashboard.date_onboarded ? (
                  <Grid item xs={6}>
                    <SmartEditTextField
                      label="Upcoming trial length"
                      value={client.account.plan.original_trial_length ?? 0}
                      inputType="number"
                      onSave={(value) => {
                        dispatch(
                          updateClient({
                            user_id: client.user_id,
                            // @ts-expect-error
                            "account.plan.original_trial_length":
                              value as number,
                          }),
                        );
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <Label
                      content={(
                        client.account.plan.original_trial_length ?? 0
                      ).toString()}
                      label={"Original trial length"}
                    />
                  </Grid>
                )}
              </Grid>
            </Card>
            <Card sx={{ p: 2 }} variant="outlined">
              <Typography variant="h2" sx={{ mb: 2 }}>
                Support
              </Typography>
              <SmartEditRichTextField
                label="Support notes"
                value={client.support_notes}
                onSave={(value) => {
                  dispatch(
                    updateClient({
                      user_id: client.user_id,
                      support_notes: value as string,
                    }),
                  );
                }}
              />
            </Card>
            <Card sx={{ p: 2 }} variant="outlined">
              <Typography variant="h2" sx={{ mb: 2 }}>
                Technical
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SmartEditTextField
                    label="Headshot url"
                    value={client.headshot_url}
                    onSave={(value) => {
                      dispatch(
                        updateClient({
                          user_id: client.user_id,
                          headshot_url: value as string,
                        }),
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SmartEditTextField
                    label="Timezone offset"
                    inputType="number"
                    value={client.default_timezone_offset}
                    onSave={(value) => {
                      dispatch(
                        updateClient({
                          user_id: client.user_id,
                          default_timezone_offset: value as number,
                        }),
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SmartEditTextField
                    label="Stripe customer id"
                    value={client.account.membership.stripe_customer_id ?? ""}
                    helperText="DO NOT CHANGE THIS unless you know what you're doing"
                    onSave={(value) => {
                      dispatch(
                        updateClient({
                          user_id: client.user_id,
                          // @ts-expect-error
                          "account.membership.stripe_customer_id": value,
                        }),
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SmartEditSelect
                    label="Workout state"
                    value={client.state}
                    possibleValues={[
                      {
                        value: "none",
                        label: "None",
                      },
                      {
                        value: "in_workout",
                        label: "In a workout",
                      },
                      {
                        value: "starting",
                        label: "Starting a workout",
                      },
                      {
                        value: "waiting_to_submit",
                        label: "Waiting to submit a workout",
                      },
                    ]}
                    onSave={(value) => {
                      dispatch(
                        updateClient({
                          user_id: client.user_id,
                          state: value as Client["state"],
                        }),
                      );
                    }}
                  />
                  <Card
                    variant="outlined"
                    sx={{ p: 1, display: "flex", alignItems: "center", mt: 2 }}
                  >
                    {hasTempLog ? (
                      <CheckRoundedIcon fontSize="inherit" />
                    ) : (
                      <ClearRoundedIcon fontSize="inherit" />
                    )}
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {hasTempLog ? "Has temp log" : "No temp log"}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Card>
            <Card sx={{ p: 2 }} variant="outlined">
              <Typography variant="h2" sx={{ mb: 2 }}>
                Authentication
              </Typography>
              <Stack direction="column" spacing={2}>
                {client.auth.firebase_uid_email && (
                  <Card variant="outlined" sx={{ p: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <EmailRoundedIcon fontSize="inherit" />
                      <Typography variant="h3" sx={{ ml: 1 }}>
                        Email
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        mb: 2,
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      Firebase uid: {client.auth.firebase_uid_email}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      First login
                    </Typography>
                    <Typography>
                      {customClaims?.email_token?.metadata?.creationTime
                        ? format(
                            new Date(
                              customClaims.email_token.metadata.creationTime,
                            ),
                            "MMM d, yyyy h:mm aaa",
                          )
                        : "None"}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Last login
                    </Typography>
                    <Typography sx={{ mb: 2 }}>
                      {customClaims?.email_token?.metadata?.lastSignInTime
                        ? format(
                            new Date(
                              customClaims.email_token.metadata.lastSignInTime,
                            ),
                            "MMM d, yyyy h:mm aaa",
                          )
                        : "None"}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Custom claims
                    </Typography>
                    <Typography component={"span"}>
                      <pre>
                        <code>
                          {customClaims?.email_token
                            ? JSON.stringify(
                                customClaims.email_token.claims,
                                null,
                                2,
                              )
                            : "None"}
                        </code>
                      </pre>
                    </Typography>
                  </Card>
                )}
                {client.auth.firebase_uid_phone && (
                  <Card variant="outlined" sx={{ p: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PhoneRoundedIcon fontSize="inherit" />
                      <Typography variant="h3" sx={{ ml: 1 }}>
                        Phone
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        mb: 2,
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      Firebase uid: {client.auth.firebase_uid_phone}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      First login
                    </Typography>
                    <Typography>
                      {customClaims?.phone_token?.metadata?.creationTime
                        ? format(
                            new Date(
                              customClaims.phone_token.metadata.creationTime,
                            ),
                            "MMM d, yyyy h:mm aaa",
                          )
                        : "None"}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Last login
                    </Typography>
                    <Typography sx={{ mb: 2 }}>
                      {customClaims?.phone_token?.metadata?.lastSignInTime
                        ? format(
                            new Date(
                              customClaims.phone_token.metadata.lastSignInTime,
                            ),
                            "MMM d, yyyy h:mm aaa",
                          )
                        : "None"}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Custom claims
                    </Typography>
                    <Typography component={"span"}>
                      <pre>
                        <code>
                          {customClaims?.phone_token
                            ? JSON.stringify(
                                customClaims.phone_token.claims,
                                null,
                                2,
                              )
                            : "None"}
                        </code>
                      </pre>
                    </Typography>
                  </Card>
                )}
              </Stack>
            </Card>
            <Card variant="outlined">
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                  <Typography variant="h2">Raw data</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <pre
                    style={{
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    {JSON.stringify(client, null, 2)}
                  </pre>
                </AccordionDetails>
              </Accordion>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
