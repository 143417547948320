import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { memo, useState } from "react";
import { useAppDispatch } from "src/hooks/stateHooks";
import { logClick } from "src/slices/analyticsSlice";
import HabitPanel from "./HabitPanel";
import PhaseWorkoutPanel from "./PhaseWorkoutPanel";

export const SelectorColumn = memo(function SelectorColumn() {
  const dispatch = useAppDispatch();
  const [currentTab, setCurrentTab] = useState("phases_workouts");

  return (
    <TabContext value={currentTab}>
      <Box
        sx={{
          height: "calc(100vh - 38px)",
          borderRight: 1,
          borderColor: "divider",
          backgroundColor: (theme) => theme.palette.background.paper,
          display: "flex",
          flexDirection: "column",
          width: "200px",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          <TabList
            onChange={(_, newValue) => {
              setCurrentTab(newValue);
            }}
            aria-label="lab API tabs example"
            sx={{ minHeight: "32px" }}
          >
            <Tab
              label={"Phases"}
              value="phases_workouts"
              onClick={() => {
                dispatch(
                  logClick({
                    elementName: "client.phases_tab",
                  }),
                );
              }}
              sx={{ py: 1, minHeight: "32px" }}
            />
            <Tab
              label={"Habits"}
              value="habits"
              sx={{ py: 1, minHeight: "32px" }}
              onClick={() => {
                dispatch(
                  logClick({
                    elementName: "client.habits_tab",
                  }),
                );
              }}
            />
          </TabList>
        </Box>
        <TabPanel
          value="phases_workouts"
          sx={{
            p: 0,
            flexGrow: 1,
            overflowY: "auto",
          }}
        >
          <PhaseWorkoutPanel />
        </TabPanel>
        <TabPanel value="habits" sx={{ p: 0, flex: 1, overflowY: "auto" }}>
          <HabitPanel />
        </TabPanel>
      </Box>
    </TabContext>
  );
});
