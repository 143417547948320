import { useDroppable } from "@dnd-kit/core";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import { useMemo, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { useAppSelector } from "src/hooks/stateHooks";
import { getSmartTagDetails } from "src/lib/phaseTags";
import { selectUsedPhaseTemplateTags } from "src/slices/phaseTemplatesSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import SearchField from "../misc/SearchField";
import FolderCell from "./FolderCell";
import PhaseRequestDialog from "./PhaseRequestDialog";
import PhaseTemplateCell from "./PhaseTemplateCell";
import WorkoutTemplateCell from "./WorkoutTemplateCell";

const icon = <CheckBoxOutlineBlankRoundedIcon fontSize="small" />;
const checkedIcon = <CheckBoxRoundedIcon fontSize="small" />;

export default function TemplateLibraryHomePage() {
  const [search, setSearch] = useState("");
  const [filterTags, setFilterTags] = useState<string[]>([]);
  const phaseTemplateFolders = useAppSelector(
    (state) => state.phaseTemplates.templateLibraryFolders,
  );
  const currentTab = useAppSelector((state) => state.phaseTemplates.currentTab);
  const phaseTemplates = useAppSelector(
    (state) => state.phaseTemplates.phaseTemplates,
  );
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)!.trainer_id,
  );
  const { setNodeRef, isOver } = useDroppable({
    id: "home",
    data: {
      type: "page",
    },
  });

  const { filteredPhaseTemplates, filteredWorkoutTemplates } = useMemo(() => {
    let newPhaseTemplates = [...phaseTemplates].filter(
      (phaseTemplate) => !phaseTemplate.deleted,
    );

    if (currentTab === "trainwell") {
      newPhaseTemplates = newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.trainer_id === "copilot";
      });
    } else {
      newPhaseTemplates = newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.trainer_id === trainerId;
      });
    }

    if (search || filterTags.length > 0) {
      if (search) {
        newPhaseTemplates = newPhaseTemplates.filter((phaseTemplate) => {
          return phaseTemplate.name
            .toLowerCase()
            .includes(search.toLowerCase());
        });
      }

      if (filterTags.length > 0) {
        newPhaseTemplates = newPhaseTemplates.filter((phaseTemplate) => {
          for (const tag of filterTags) {
            if (!phaseTemplate.tags || !phaseTemplate.tags.includes(tag)) {
              return false;
            }
          }

          return true;
        });
      }
    } else {
      newPhaseTemplates = newPhaseTemplates.filter((phaseTemplate) => {
        return !phaseTemplate.parent_folder_id;
      });
    }

    newPhaseTemplates.sort((a, b) => {
      // Sort pinned to the top
      if (a.is_pinned && !b.is_pinned) {
        return -1;
      } else if (!a.is_pinned && b.is_pinned) {
        return 1;
      }

      // Sort by date updated
      return ((b.date_updated ?? b.date_created) as string).localeCompare(
        (a.date_updated ?? a.date_created) as string,
      );
    });

    return {
      filteredPhaseTemplates: newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.type === "multiple";
      }),
      filteredWorkoutTemplates: newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.type === "single";
      }),
    };
  }, [phaseTemplates, search, filterTags, currentTab, trainerId]);

  const filteredPhaseTemplateFolders = useMemo(() => {
    let newPhaseTemplateFolders = [...phaseTemplateFolders];

    if (filterTags.length > 0) {
      return [];
    }

    if (currentTab === "trainwell") {
      newPhaseTemplateFolders = newPhaseTemplateFolders.filter((folder) => {
        return folder.trainer_id === "copilot";
      });
    } else {
      newPhaseTemplateFolders = newPhaseTemplateFolders.filter((folder) => {
        return folder.trainer_id === trainerId;
      });
    }

    if (search) {
      newPhaseTemplateFolders = newPhaseTemplateFolders.filter((folder) => {
        return folder.name.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      newPhaseTemplateFolders = newPhaseTemplateFolders.filter((folder) => {
        return !folder.parent_folder_id;
      });
    }

    newPhaseTemplateFolders.sort((a, b) => {
      // Sort pinned to the top
      if (a.is_pinned && !b.is_pinned) {
        return -1;
      } else if (!a.is_pinned && b.is_pinned) {
        return 1;
      }

      // Sort by name alphabetically
      return a.name.localeCompare(b.name);
    });

    return newPhaseTemplateFolders.map((folder) => {
      return { ...folder, is_folder: true };
    });
  }, [phaseTemplateFolders, search, filterTags, currentTab, trainerId]);

  return (
    <>
      <Box
        ref={setNodeRef}
        sx={{
          px: 3,
          height: "100%",
          overflowY: "auto",
          backgroundColor: (theme) =>
            isOver ? alpha(theme.palette.primary.main, 0.1) : undefined,
        }}
      >
        <Virtuoso
          data={[
            ...filteredPhaseTemplateFolders,
            ...filteredPhaseTemplates,
            ...filteredWorkoutTemplates,
          ]}
          context={{ search, setSearch, filterTags, setFilterTags }}
          components={{
            Header: Header as any,
          }}
          style={{ height: "100%" }}
          itemContent={(index, item) => {
            if ("is_folder" in item) {
              return <FolderCell phaseTemplateFolderId={item._id} />;
            } else if (item.type === "multiple") {
              return <PhaseTemplateCell phaseTemplateId={item._id} />;
            } else {
              return <WorkoutTemplateCell phaseTemplateId={item._id} />;
            }
          }}
        />
      </Box>
    </>
  );
}

type HeaderProps = {
  context: {
    search: string;
    setSearch: (value: string) => void;
    filterTags: string[];
    setFilterTags: (value: string[]) => void;
  };
};

function Header({
  context: { search, setSearch, filterTags, setFilterTags },
}: HeaderProps) {
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)!.trainer_id,
  );
  const currentTab = useAppSelector((state) => state.phaseTemplates.currentTab);
  const possibleTags = useAppSelector((state) =>
    selectUsedPhaseTemplateTags(
      state,
      currentTab === "trainer" ? trainerId : "copilot",
    ),
  );
  const [requestDialogOpen, setRequestDialogOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pt: 2,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ExtensionRoundedIcon />
          <Typography variant="h1" sx={{ ml: 1 }}>
            {currentTab === "trainer" ? "My" : "trainwell"} template library
          </Typography>
        </Box>
        {currentTab === "trainwell" && (
          <Button
            size="small"
            variant="text"
            startIcon={<CampaignRoundedIcon />}
            onClick={() => {
              setRequestDialogOpen(true);
            }}
          >
            Request
          </Button>
        )}
      </Box>
      <Stack direction={"row"} spacing={1} sx={{ mb: 2, alignItems: "center" }}>
        <SearchField
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
          onClear={() => {
            setSearch("");
          }}
          sx={{ maxWidth: "250px" }}
          placeholder="Search everything"
        />
        <Autocomplete
          multiple
          size="small"
          options={possibleTags}
          disableCloseOnSelect
          getOptionLabel={(tag) => {
            const tagDetails = getSmartTagDetails(tag);

            return tagDetails.label;
          }}
          value={filterTags}
          onChange={(event, newValues) => {
            setFilterTags(newValues);
          }}
          renderOption={(props, tag, { selected }) => {
            const tagDetails = getSmartTagDetails(tag);

            return (
              <li
                {...props}
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {tagDetails.icon && (
                  <tagDetails.icon.type
                    {...tagDetails.icon.props}
                    sx={{ fontSize: "inherit", mr: 1 }}
                  />
                )}
                {tagDetails.label}
              </li>
            );
          }}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Tags" />}
        />
      </Stack>
      <PhaseRequestDialog
        open={requestDialogOpen}
        onClose={() => {
          setRequestDialogOpen(false);
        }}
      />
    </>
  );
}
