import { Close } from "@mui/icons-material";
import {
  Box,
  Chip,
  CircularProgress,
  Fab,
  Popover,
  Typography,
} from "@mui/material";
import { type MotionMetadata } from "@trainwell/types";
import { Fragment, useEffect, useState } from "react";
import ExerciseMedia from "src/components/misc/ExerciseMedia";
import { getExerciseIconURL } from "src/lib/mediaUtility";
import { api } from "src/lib/trainwellApi";
import { AxisGraph } from "./AxisGraph";
import {
  applySetRange,
  clearReps,
  clearSets,
  graphsFromData,
  prepareFile,
  toggleRep,
} from "./lib/fileUtility";

type Props = {
  skipHandler: any;
  backHandler: any;
  saveHandler: any;
  testHandler: any;
  uncleanHandler: any;
  quitHandler: any;
  totalFileCount: any;
  fileIndex: any;
  fileId: any;
  useVelocity: boolean;
};

export function FileEditor({
  skipHandler,
  backHandler,
  saveHandler,
  testHandler,
  uncleanHandler,
  quitHandler,
  totalFileCount,
  fileIndex,
  fileId,
  useVelocity,
}: Props) {
  const [wristConfig, setWristConfig] = useState<string>();
  const [crownConfig, setCrownConfig] = useState<string>();
  const [file, setFile] = useState<MotionMetadata>();
  const [isLoading, setIsLoading] = useState(false);
  const [animationAnchor, setAnimationAnchor] =
    useState<HTMLImageElement | null>(null);
  const animationOpen = Boolean(animationAnchor);
  const [popupExercise, setPopupExercise] = useState<string>();
  const [numCleaned, setNumCleaned] = useState(0);

  async function loadFile() {
    console.log("loading file!");

    setIsLoading(true);
    try {
      const resp = await api.motionMetadatas.loadFullFile(fileId, useVelocity);

      setFile(prepareFile(resp.obj));
      setWristConfig(resp.obj.attributes.wrist_config);
      setCrownConfig(resp.obj.attributes.crown_config);

      setIsLoading(false);
    } catch (err) {
      console.log("Error loading motion file", file?.id, err);
      alert(
        "Failed to load file.\n\nFile ID: " +
          fileId +
          "\n\nAutomatically skipping to the next file...",
      );
      skipHandler();
    }
  }

  useEffect(() => {
    loadFile();
  }, [fileId]);

  useEffect(() => {
    function keyDownHandler(event) {
      if (event.key === "w") {
        const newWristConfig =
          file?.attributes.wrist_config === "right" ? "left" : "right";
        if (file !== undefined) {
          setFile({
            ...file,
            attributes: { ...file.attributes, wrist_config: newWristConfig },
          });
        }
        setWristConfig(newWristConfig);
      } else if (event.key === "c") {
        const newCrownConfig =
          file?.attributes.crown_config === "right" ? "left" : "right";
        if (file !== undefined) {
          setFile({
            ...file,
            attributes: { ...file.attributes, crown_config: newCrownConfig },
          });
        }
        setCrownConfig(newCrownConfig);
      } else if (event.key === "x") {
        setFile({ ...clearReps(file) });
      } else if (event.key === "z") {
        setFile({ ...clearSets(file) });
      } else if (saveHandler && event.key === " ") {
        saveHandler(file);
        skipHandler();
        setNumCleaned(numCleaned + 1);
      } else if (testHandler && event.key === "t") {
        testHandler(file);
        skipHandler();
      } else if (uncleanHandler && event.key === "u") {
        uncleanHandler(file);
        skipHandler();
      } else if (skipHandler && (event.key === "a" || event.key === "k")) {
        skipHandler();
      } else if (backHandler && event.key === "b") {
        backHandler();
      } else if (event.key === "q") {
        quitHandler();
      }
    }

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [saveHandler, skipHandler, backHandler, quitHandler, file]);

  const graphs = graphsFromData(file?.data);

  return (
    <Fragment>
      {file && file !== null && !isLoading ? (
        <Fragment>
          {quitHandler && (
            <Fab
              onClick={(e) => quitHandler()}
              style={{ position: "relative", left: 30, top: 10 }}
            >
              <Close />
            </Fab>
          )}
          <Popover
            style={{ pointerEvents: "none", padding: "1em", cursor: "none" }}
            open={animationOpen}
            anchorEl={animationAnchor}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
          >
            <Box sx={{ p: 1, maxWidth: 600 }}>
              <Typography variant="h2" sx={{ textAlign: "center", mb: 2 }}>
                {file.attributes.exercise}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <ExerciseMedia
                  exerciseMasterId={popupExercise!}
                  width={300}
                  height={300}
                />
              </Box>
            </Box>
          </Popover>
          <div
            style={{
              width: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={
                getExerciseIconURL(file.attributes.exercise) ||
                "/assets/profile.png"
              }
              alt={file.attributes.exercise}
              width={46}
              height={46}
              style={{
                borderRadius: "8px",
              }}
              onMouseEnter={(e) => {
                document.documentElement.style.cursor = "none";
                setPopupExercise(file.attributes.exercise);
                setAnimationAnchor(e.currentTarget);
              }}
              onMouseLeave={() => {
                document.documentElement.style.cursor = "auto";
                setAnimationAnchor(null);
              }}
            />
            <Typography variant="h4">
              {file.attributes.exercise +
                " [" +
                (fileIndex + 1) +
                "/" +
                totalFileCount +
                "] " +
                numCleaned +
                " cleaned"}
            </Typography>
          </div>
          <div
            style={{
              width: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <Chip
              label={
                file.attributes.overhand_config === "top"
                  ? "Top of Wrist"
                  : "Bottom of Wrist"
              }
            />
            <Chip label={"ID: " + file.id} />
            {file.collection.weight && file.collection.weight !== 0 && (
              <Chip label={Math.round(file.collection.weight) + "lbs"} />
            )}
            <Chip
              label={
                file.collection.n_reps +
                " reps (" +
                file.collection.reps_expected +
                " expected)"
              }
            />
            {file.preprocessing.cleaners.length > 0 &&
              file.preprocessing.cleaned && (
                <Chip
                  label={
                    "Cleaned by " +
                    (file.preprocessing.cleaners.length === 1
                      ? file.preprocessing.cleaners[0]
                      : file.preprocessing.cleaners)
                  }
                />
              )}
            {file.collection.false_neg && <Chip label="False Negative" />}
            {file.collection.false_pos && <Chip label="False Positive" />}
          </div>
          <div
            style={{
              width: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              marginTop: 5,
            }}
          >
            <Chip
              variant="outlined"
              label={
                wristConfig === "right" ? "Right Wrist (w)" : "Left Wrist (w)"
              }
              onClick={(e) => {
                const newWristConfig =
                  file?.attributes.wrist_config === "right" ? "left" : "right";
                setFile({
                  ...file,
                  attributes: {
                    ...file.attributes,
                    wrist_config: newWristConfig,
                  },
                });
                setWristConfig(newWristConfig);
              }}
            />
            <Chip
              variant="outlined"
              label={
                crownConfig === "right" ? "Right Crown (c)" : "Left Crown (c)"
              }
              onClick={(e) => {
                const newCrownConfig =
                  file.attributes.crown_config === "right" ? "left" : "right";
                setFile({
                  ...file,
                  attributes: {
                    ...file.attributes,
                    crown_config: newCrownConfig,
                  },
                });
                setCrownConfig(newCrownConfig);
              }}
            />
            <Chip
              variant="outlined"
              label="Clear Reps (x)"
              onClick={(e) => {
                setFile({ ...clearReps(file) });
              }}
            />
            <Chip
              variant="outlined"
              label="Clear Sets (z)"
              onClick={(e) => {
                setFile({ ...clearSets(file) });
              }}
            />
            {backHandler && (
              <Chip
                variant="outlined"
                label="Back (b)"
                onClick={(e) => {
                  backHandler();
                }}
              />
            )}
            {skipHandler && (
              <Chip
                variant="outlined"
                label="Skip File (a)"
                onClick={(e) => {
                  skipHandler();
                }}
              />
            )}
            {saveHandler && (
              <Chip
                variant="outlined"
                label="Save File (space)"
                onClick={(e) => {
                  saveHandler(file);
                  skipHandler();
                  setNumCleaned(numCleaned + 1);
                }}
              />
            )}
            {testHandler && (
              <Chip
                variant="outlined"
                label="Test-Only File (t)"
                onClick={(e) => {
                  testHandler(file);
                  skipHandler();
                  setNumCleaned(numCleaned + 1);
                }}
              />
            )}
            {uncleanHandler && (
              <Chip
                variant="outlined"
                label="Unclean File (u)"
                onClick={(e) => {
                  uncleanHandler(file);
                  skipHandler();
                  setNumCleaned(numCleaned + 1);
                }}
              />
            )}
          </div>
          {graphs !== null && graphs !== undefined && (
            <Fragment>
              {graphs.map((graph, i) => (
                <AxisGraph
                  key={graph.title}
                  graph={graph}
                  selectSetRange={(range) => {
                    console.log("selected range:", range);
                    setFile({ ...applySetRange(range, file, true) });
                  }}
                  deselectSetRange={(range) => {
                    console.log("deselected range:", range);
                    setFile({ ...applySetRange(range, file, false) });
                  }}
                  toggleRep={(xValue) => {
                    console.log("toggle rep:", xValue);
                    setFile({ ...toggleRep(xValue, file) });
                  }}
                  showXAxis={i === graphs.length - 1} //Show X axis if it's the last graph
                />
              ))}
            </Fragment>
          )}
        </Fragment>
      ) : (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography>Only you can prevent file cleaning fires...</Typography>
        </div>
      )}
    </Fragment>
  );
}
