import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import DataUsageRoundedIcon from "@mui/icons-material/DataUsageRounded";
import EmojiPeopleRoundedIcon from "@mui/icons-material/EmojiPeopleRounded";
import NorthEastRoundedIcon from "@mui/icons-material/NorthEastRounded";
import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";
import SouthEastRoundedIcon from "@mui/icons-material/SouthEastRounded";
import SpeedRoundedIcon from "@mui/icons-material/SpeedRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import TimerRoundedIcon from "@mui/icons-material/TimerRounded";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import type { LogSet } from "@trainwell/types";
import { getConvertedWeight } from "@trainwell/workout-lib";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import { useSnackbar } from "notistack";
import { memo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "src/hooks/stateHooks";
import { getExerciseById } from "src/lib/exercises";
import { getFormattedTime } from "src/lib/misc";
import { getWeightUnit } from "src/lib/miscUtility";
import { api } from "src/lib/trainwellApi";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import {
  logApi,
  useGetLogQuery,
  useUpdateWorkoutLogAlertMutation,
} from "src/slices/api/logApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { SetLogDifficultyChip } from "./SetLogDifficultyChip";

const emptyArray = [];

const emptyObject: {
  badStuff: string[];
  notableStuff: string[];
  noteAlertIds: string[] | null;
} = {
  badStuff: emptyArray,
  notableStuff: emptyArray,
  noteAlertIds: null,
};

type Props = {
  setLog: LogSet;
  exerciseMasterId: string;
};

export const SetLogCell = memo(function SetCell({
  setLog,
  exerciseMasterId,
}: Props) {
  const { logId } = useParams<{ logId: string | undefined }>();
  const [showAll, setShowAll] = useState(false);
  const exerciseSource = getExerciseById(exerciseMasterId);
  const weightSystem = useAppSelector(
    (state) => state.client.client?.preferred_weight_system,
  );
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)!.trainer_id,
  );
  const theme = useTheme();
  const { data: log } = useGetLogQuery(logId ?? "");

  const [updateWorkoutLogAlert] = useUpdateWorkoutLogAlertMutation();

  const { badStuff, notableStuff, noteAlertIds } = useAppSelector((state) => {
    if (!logId) {
      return emptyObject;
    }

    if (!log) {
      return emptyObject;
    }

    const updatesSelector = logApi.endpoints.getWorkoutLogAlerts.select(logId);
    const updates = updatesSelector(state).data?.workout_log_alerts;

    if (!updates) {
      return emptyObject;
    }

    const highlights = updates
      .map((update) => {
        return update.trigger.triggered_sets.map((triggeredSet) => {
          if (setLog.set_id === triggeredSet.set_id) {
            return triggeredSet.set_details_to_highlight;
          }
        });
      })
      .flat(2);

    const badHighlights = updates
      .filter((u) => !u.date_addressed)
      .map((update) => {
        return update.trigger.triggered_sets.map((triggeredSet) => {
          if (setLog.set_id === triggeredSet.set_id) {
            return triggeredSet.set_details_to_highlight;
          }
        });
      })
      .flat(2);

    const noteAlertIds = updates
      .filter(
        (u) => !u.date_addressed && u.trigger.type === "exercise_client_notes",
      )
      .map((update) => update._id)
      .flat();

    const notableStuff = [...new Set(highlights)].filter(
      (x) => x !== undefined,
    );
    const badStuff = [...new Set(badHighlights)].filter((x) => x !== undefined);

    return {
      badStuff: badStuff,
      notableStuff: notableStuff,
      noteAlertIds: noteAlertIds,
    };
  });

  const { enqueueSnackbar } = useSnackbar();
  const [kineticGraphsAnchor, setKineticGraphsAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [romGraphsAnchor, setRomGraphsAnchor] =
    useState<HTMLButtonElement | null>(null);
  const summaryGraphAnchor = useRef<HTMLButtonElement | null>(null);
  const [feedbackAnchor, setFeedbackAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [paceGraphAnchor, setPaceGraphAnchor] =
    useState<HTMLButtonElement | null>(null);

  const kineticGraphsOpen = Boolean(kineticGraphsAnchor);
  const romGraphsOpen = Boolean(romGraphsAnchor);
  const [summaryGraphOpen, setSummaryGraphOpen] = useState(false);
  const feedbackOpen = Boolean(feedbackAnchor);
  const paceGraphOpen = Boolean(paceGraphAnchor);

  const [rawImageError, setRawImageError] = useState(false);

  const timeSeconds = Math.round(
    (new Date(setLog.set_end_date!).getTime() -
      new Date(setLog.set_start_date!).getTime()) /
      1000,
  );

  if (!exerciseSource) {
    return <Typography>Loading...</Typography>;
  }

  const values = workoutLib.exercises.getValuesForExercise(exerciseSource);

  const basicallySkipped =
    values.time &&
    timeSeconds < 5 &&
    !setLog.notes_client &&
    !setLog.rir &&
    !setLog.is_pr_new &&
    !setLog.user_feedback?.notes &&
    (setLog.user_feedback?.chips?.length ?? 0) === 0 &&
    (setLog.user_feedback?.difficulty ?? "just_right") === "just_right";

  const skipped = setLog.performance_index === null;
  const clientNotes = setLog.user_feedback?.notes || setLog.notes_client;

  if (skipped || basicallySkipped) {
    return (
      <>
        <Box
          sx={{
            pl: "25px",
            display: "flex",
            alignItems: "center",
            minHeight: 24,
          }}
        >
          <SkipNextRoundedIcon
            color={badStuff.includes("completion") ? "error" : undefined}
            sx={{ fontSize: 14 }}
          />
          <Typography
            sx={{
              lineHeight: 1,
              ml: 0.5,
            }}
            color={badStuff.includes("completion") ? "error" : undefined}
          >
            Skipped
          </Typography>
        </Box>
        {clientNotes && (
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.background.default,
                borderRadius: `2px 8px 8px 8px`,
                border: 2,
                borderColor: (theme) =>
                  badStuff.includes("client_notes")
                    ? theme.palette.error.main
                    : "divider",
                mt: 0.5,
                px: 1,
                py: 0.5,
                display: "flex",
                alignItems: "center",
                ml: "25px",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;",
              }}
            >
              <EmojiPeopleRoundedIcon
                fontSize="inherit"
                color={badStuff.includes("client_notes") ? "error" : undefined}
                sx={{ mr: 1 }}
              />
              <Typography>{clientNotes}</Typography>
              {(noteAlertIds ?? []).length > 0 && (
                <Tooltip
                  title="No action needed"
                  disableInteractive
                  placement="top"
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (noteAlertIds) {
                        updateWorkoutLogAlert({
                          workoutLogAlertId: noteAlertIds[0],
                          addressType: "dismissed",
                          logId: logId ?? "",
                          trainerId: trainerId,
                        });
                      }
                    }}
                    sx={{ ml: 1 }}
                  >
                    <ClearRoundedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        )}
      </>
    );
  }

  const isFalseNegative =
    exerciseSource.is_currently_tracked === true &&
    (!("repcognition_response" in setLog) ||
      setLog.repcognition_response === null) &&
    log?.tech_summary?.watch_used &&
    setLog.performance_index !== null &&
    log.tech_summary.device_platform === "ios";

  let hasROM = false;
  let goodRom = true;
  let romGraphURL: any = undefined;

  let pace: any = undefined;
  let paceIdeal: any = undefined;
  let isPaceBad = false;

  let speedMeans: any = undefined;

  let feedback: any = undefined;
  let isFeedbackBad = false;

  let summaryGraphURL: any = undefined;

  let lengths: any = undefined;

  let dataID: any = undefined;

  if (
    "repcognition_response" in setLog &&
    setLog.repcognition_response !== null
  ) {
    if (
      "rep_analysis" in (setLog as any).repcognition_response &&
      (setLog as any).repcognition_response.rep_analysis !== null
    ) {
      if (
        "reps_estimated" in
          (setLog as any).repcognition_response.rep_analysis &&
        (setLog as any).repcognition_response.rep_analysis.reps_estimated !==
          null
      ) {
        (setLog as any).reps_performed = (
          setLog as any
        ).repcognition_response.rep_analysis.reps_estimated;
      }
      if (
        "good_rom" in (setLog as any).repcognition_response.rep_analysis &&
        (setLog as any).repcognition_response.rep_analysis.good_rom !== null
      ) {
        hasROM = true;
        goodRom = (setLog as any).repcognition_response.rep_analysis.good_rom;
      }
      if (
        "pace" in (setLog as any).repcognition_response.rep_analysis &&
        (setLog as any).repcognition_response.rep_analysis.pace !== null
      ) {
        pace = (setLog as any).repcognition_response.rep_analysis.pace;
      }

      //First try getting the newer lower bound pace - jk this is confusing to trainers
      // if ("pace_lower_bound" in setLog.repcognition_response.rep_analysis && setLog.repcognition_response.rep_analysis.pace_lower_bound !== null) {
      //     paceIdeal = setLog.repcognition_response.rep_analysis.pace_lower_bound / (info.notes_reps_per_side ? 2 : 1)
      // }

      if (
        "pace_ideal" in (setLog as any).repcognition_response.rep_analysis &&
        (setLog as any).repcognition_response.rep_analysis.pace_ideal !== null
      ) {
        paceIdeal = (setLog as any).repcognition_response.rep_analysis
          .pace_ideal;
      }

      if (
        "pace_too_fast" in (setLog as any).repcognition_response.rep_analysis &&
        (setLog as any).repcognition_response.rep_analysis.pace_too_fast !==
          null
      ) {
        isPaceBad = (setLog as any).repcognition_response.rep_analysis
          .pace_too_fast;
      }
      if (
        "speed_means" in (setLog as any).repcognition_response.rep_analysis &&
        (setLog as any).repcognition_response.rep_analysis.speed_means !==
          null &&
        (setLog as any).repcognition_response.rep_analysis.speed_means.length >
          0
      ) {
        speedMeans = (setLog as any).repcognition_response.rep_analysis
          .speed_means;
      }
      if (
        "lengths" in (setLog as any).repcognition_response.rep_analysis &&
        (setLog as any).repcognition_response.rep_analysis.lengths !== null &&
        (setLog as any).repcognition_response.rep_analysis.lengths.length > 0
      ) {
        lengths = (setLog as any).repcognition_response.rep_analysis.lengths;
      }
    }

    if (
      "feedback_description" in (setLog as any).repcognition_response &&
      (setLog as any).repcognition_response.feedback_description !== null
    ) {
      feedback = (setLog as any).repcognition_response.feedback_description
        .text;
      isFeedbackBad =
        (setLog as any).repcognition_response.feedback_description.is_warning ||
        (setLog as any).repcognition_response.feedback_description.key ===
          "fixed";
    }

    // if (
    //   "response_data_id" in setLog.repcognition_response &&
    //   setLog.repcognition_response.response_data_id !== null
    // ) {
    //   if (setLog.set_log_id !== null) {
    //     dataID = setLog.set_log_id;
    //     summaryGraphURL =
    //       "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
    //       dataID +
    //       "_summary.png";
    //     romGraphURL =
    //       "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
    //       dataID +
    //       "_rom.png";
    //   }
    // }

    if (log?.metadata?.from_auto_conversion) {
      if (
        "response_data_id" in (setLog as any).repcognition_response &&
        (setLog as any).repcognition_response.response_data_id !== null
      ) {
        dataID = (setLog as any).repcognition_response.response_data_id;
        summaryGraphURL =
          "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
          dataID +
          "_summary.png";
        romGraphURL =
          "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
          dataID +
          "_rom.png";
      }
    } else {
      if (setLog.set_log_id !== null) {
        dataID = setLog.set_log_id;
        summaryGraphURL =
          "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
          dataID +
          "_summary.png";
        romGraphURL =
          "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
          dataID +
          "_rom.png";
      }
    }
  }

  if (!log?.metadata?.from_auto_conversion) {
    if (log?.tech_summary?.watch_used) {
      if (setLog.set_log_id !== null) {
        dataID = setLog.set_log_id;
        summaryGraphURL =
          "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
          dataID +
          "_summary.png";
        romGraphURL =
          "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
          dataID +
          "_rom.png";
      }
    }
  }

  const chartOptionsPacing: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    colors: [isPaceBad ? theme.palette.error.main : theme.palette.primary.main],
    tooltip: {
      headerFormat: undefined,
    },
    yAxis: {
      title: {
        text: "Rep Length (s)",
        style: { color: theme.palette.text.secondary },
      },
      crosshair: {
        color: "#cccccc",
      },
      min: 0.0,
      plotLines: [
        {
          color: theme.palette.success.main,
          width: 2,
          dashStyle: "ShortDash",
          value: paceIdeal,
        },
      ],
      labels: {
        style: { color: theme.palette.text.secondary },
      },
    },
    xAxis: {
      labels: {
        formatter: function () {
          return "Rep " + Math.round((this.value as number) + 1);
        },
        style: { color: theme.palette.text.secondary },
      },
      crosshair: {
        color: "#cccccc",
      },
    },
    chart: {
      backgroundColor: "transparent",
      type: "spline",
      height: 300,
      width: 300,
    },
    title: {
      text: undefined,
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
    series: [
      {
        name: "Rep Length (s)",
        data: (setLog as any).repcognition_response?.rep_analysis?.lengths,
        type: "spline",
      },
    ],
    legend: {
      itemStyle: { color: theme.palette.text.secondary },
    },
    accessibility: {
      enabled: false,
    },
  };

  const chartOptionsKinetics: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    colors: [theme.palette.primary.main],
    tooltip: {
      headerFormat: undefined,
    },
    yAxis: {
      title: {
        text: "Velocity (m/s)",
        style: { color: theme.palette.text.secondary },
      },
      crosshair: {
        color: "#cccccc",
      },
      min: 0.0,
      labels: {
        style: { color: theme.palette.text.secondary },
      },
    },
    xAxis: {
      labels: {
        formatter: function () {
          return "Rep " + Math.round((this.value as number) + 1);
        },
        style: { color: theme.palette.text.secondary },
      },
      crosshair: {
        color: "#cccccc",
      },
    },
    chart: {
      backgroundColor: "transparent",
      type: "spline",
      height: 300,
      width: 300,
    },
    title: {
      text: "Rep Velocity",
      style: { color: theme.palette.text.primary },
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
    series: [
      {
        name: "Velocity (m/s)",
        data: (setLog as any).repcognition_response?.rep_analysis?.speed_means,
        type: "spline",
      },
    ],
    legend: {
      itemStyle: { color: theme.palette.text.secondary },
    },
    accessibility: {
      enabled: false,
    },
  };

  if (!exerciseSource || !weightSystem) {
    return <Typography>Loading</Typography>;
  }

  const hasAnythingNotable =
    setLog.is_pr_new ||
    setLog.notes_client ||
    badStuff.length !== 0 ||
    isFalseNegative ||
    // TODO: Add 0-2 RiR
    setLog.rir === "3+" ||
    setLog.rir === "not_finished" ||
    // New feedback
    setLog.user_feedback?.notes ||
    (setLog.user_feedback?.chips?.length ?? 0) > 0 ||
    setLog.user_feedback?.difficulty !== "just_right";

  if (!hasAnythingNotable && !showAll) {
    return (
      <Box>
        <IconButton
          size="small"
          onClick={() => {
            setShowAll(true);
          }}
          color="success"
          sx={{
            ml: "25px",
          }}
        >
          <CheckRoundedIcon sx={{ fontSize: 14 }} />
        </IconButton>
      </Box>
    );
  }

  if (setLog.user_feedback?.chips) {
    console.log(setLog.user_feedback?.chips);
  }

  return (
    <Box>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        sx={{
          ml: "25px",
          mr: 1,
          my: 0.25,
        }}
      >
        {values.time && (
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              width: "132px",
              pl: 1.75,
              lineHeight: 1,
              fontFamily: "'Azeret Mono', monospace;",
              fontWeight: 200,
              display: "flex",
            }}
          >
            {getFormattedTime(setLog.time_target!)}
            {setLog.time_performed !== setLog.time_target && (
              <Typography
                component={"span"}
                sx={{
                  color: (theme) =>
                    badStuff.includes("time")
                      ? theme.palette.error.main
                      : theme.palette.text.primary,
                  fontWeight: notableStuff.includes("time") ? "bold" : 300,
                  lineHeight: 1,
                  fontFamily: "'Azeret Mono', monospace;",
                  display: "flex",
                }}
              >
                {UpOrDownArrow({
                  performed: setLog.time_performed,
                  target: setLog.time_target,
                })}
                {getFormattedTime(setLog.time_performed!)}
              </Typography>
            )}
          </Typography>
        )}
        {values.reps && (
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              width: "132px",
              pl: 1.75,
              lineHeight: 1,
              fontFamily: "'Azeret Mono', monospace;",
              fontWeight: 200,
              display: "flex",
            }}
          >
            {setLog.reps_target}
            <Typography
              component={"span"}
              sx={{
                fontSize: (theme) => theme.typography.body2.fontSize,
                fontWeight: 300,
                ml: 0.5,
              }}
            >
              reps
            </Typography>
            {setLog.reps_performed !== setLog.reps_target && (
              <Typography
                component={"span"}
                sx={{
                  color: (theme) =>
                    badStuff.includes("reps")
                      ? theme.palette.error.main
                      : theme.palette.text.primary,
                  fontWeight: notableStuff.includes("reps") ? "bold" : 300,
                  lineHeight: 1,
                  fontFamily: "'Azeret Mono', monospace;",
                  display: "flex",
                }}
              >
                {UpOrDownArrow({
                  performed: setLog.reps_performed,
                  target: setLog.reps_target,
                })}
                {setLog.reps_performed}
              </Typography>
            )}
          </Typography>
        )}
        {values.weight && (
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              width: "132px",
              pl: 1.75,
              lineHeight: 1,
              fontFamily: "'Azeret Mono', monospace;",
              fontWeight: 200,
              display: "flex",
            }}
          >
            {getConvertedWeight({
              weight: setLog.weight_target!,
              fromSystem: "imperial",
              toSystem: weightSystem,
              round: true,
            })}
            <Typography
              component={"span"}
              sx={{
                fontSize: (theme) => theme.typography.body2.fontSize,
                fontWeight: 300,
                ml: 0.5,
              }}
            >
              {getWeightUnit(weightSystem)}
            </Typography>
            {setLog.weight_target !== setLog.weight_performed && (
              <Typography
                component={"span"}
                sx={{
                  color: (theme) =>
                    badStuff.includes("weight")
                      ? theme.palette.error.main
                      : theme.palette.text.primary,
                  fontWeight: notableStuff.includes("weight") ? "bold" : 300,
                  lineHeight: 1,
                  fontFamily: "'Azeret Mono', monospace;",
                  display: "flex",
                }}
              >
                {UpOrDownArrow({
                  performed: setLog.weight_performed,
                  target: setLog.weight_target,
                })}
                {getConvertedWeight({
                  weight: setLog.weight_performed!,
                  fromSystem: "imperial",
                  toSystem: weightSystem,
                  round: true,
                })}
              </Typography>
            )}
          </Typography>
        )}
        {setLog.rest_performed !== undefined &&
        setLog.rest_performed !== null &&
        setLog.rest_performed !== 0 ? (
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              width: "90px",
              pl: 1.75,
              lineHeight: 1,
              fontFamily: "'Azeret Mono', monospace;",
              fontWeight: 200,
              display: "flex",
            }}
          >
            {setLog.rest_target}
            <Typography
              component={"span"}
              sx={{
                fontSize: (theme) => theme.typography.body2.fontSize,
                fontWeight: 300,
                ml: 0.5,
              }}
            >
              s
            </Typography>
            {setLog.rest_performed !== setLog.rest_target && (
              <Typography
                component={"span"}
                sx={{
                  color: (theme) =>
                    badStuff.includes("rest")
                      ? theme.palette.error.main
                      : theme.palette.text.primary,
                  fontWeight: notableStuff.includes("rest") ? "bold" : 300,
                  lineHeight: 1,
                  fontFamily: "'Azeret Mono', monospace;",
                  display: "flex",
                }}
              >
                {UpOrDownArrow({
                  performed: setLog.rest_performed,
                  target: setLog.rest_target,
                })}
                {setLog.rest_performed}
              </Typography>
            )}
          </Typography>
        ) : (
          <Box sx={{ width: "90px" }} />
        )}
        <Stack
          spacing={1}
          direction={"row"}
          sx={{ alignItems: "center" }}
          flexWrap={"wrap"}
        >
          {setLog.is_pr_new && (
            <Chip
              color="success"
              icon={
                <SvgIcon fontSize="inherit" sx={{ color: "#9D7F10", mr: 0.5 }}>
                  <path d="M12 8L15 13.2L18 10.5L17.3 14H6.7L6 10.5L9 13.2L12 8M12 4L8.5 10L3 5L5 16H19L21 5L15.5 10L12 4M19 18H5V19C5 19.6 5.4 20 6 20H18C18.6 20 19 19.6 19 19V18Z" />
                </SvgIcon>
              }
              label={"PR"}
              size="small"
              sx={{
                backgroundColor: "#9D7F10",
                fontSize: 10,
                "& .MuiChip-iconSmall": {
                  fontSize: 14,
                },
              }}
            />
          )}
          <SetLogDifficultyChip setLog={setLog} badStuff={badStuff} />
          {setLog.user_feedback?.chips?.map((chip) => (
            <Chip
              key={chip.key}
              color={badStuff.includes("chips") ? "error" : "default"}
              label={chip.label}
              size="small"
              sx={{
                fontSize: 10,
                "& .MuiChip-iconSmall": {
                  fontSize: 14,
                },
              }}
            />
          ))}
          {isFalseNegative ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip
                title="Tracking did not work for this set"
                disableInteractive
                placement="left"
              >
                <Typography variant="body2" sx={{ lineHeight: 1 }}>
                  False negative
                </Typography>
              </Tooltip>
              <IconButton
                color={badStuff.includes("tracking") ? "error" : "primary"}
                disabled={dataID === undefined}
                ref={summaryGraphAnchor}
                onMouseEnter={() => {
                  setSummaryGraphOpen(true);
                }}
                onMouseLeave={() => {
                  setSummaryGraphOpen(false);
                }}
                onClick={() => {
                  if (dataID) {
                    navigator.clipboard.writeText(dataID).then(() => {
                      enqueueSnackbar("Copied to clipboard", {
                        variant: "success",
                      });
                    });
                  }
                }}
                size="small"
                sx={{ ml: 0.5 }}
              >
                <TimelineRoundedIcon sx={{ fontSize: 14 }} />
              </IconButton>
            </Box>
          ) : (
            <Stack spacing={0} direction={"row"} sx={{ alignItems: "center" }}>
              {lengths && (
                <IconButton
                  disabled={!lengths}
                  color={badStuff.includes("pacing") ? "error" : "primary"}
                  onMouseEnter={(event) => {
                    setPaceGraphAnchor(event.currentTarget);
                  }}
                  onMouseLeave={() => {
                    setPaceGraphAnchor(null);
                  }}
                  size="small"
                >
                  <TimerRoundedIcon sx={{ fontSize: 14 }} />
                </IconButton>
              )}
              {hasROM && (
                <IconButton
                  disabled={!hasROM}
                  onMouseEnter={(event) => {
                    setRomGraphsAnchor(event.currentTarget);
                  }}
                  onMouseLeave={() => {
                    setRomGraphsAnchor(null);
                  }}
                  color={badStuff.includes("rom") ? "error" : "primary"}
                  onClick={async () => {
                    const image = await fetch(romGraphURL);
                    const blob = await image.blob();
                    const clipboardItem = new ClipboardItem({
                      "image/png": blob,
                    });
                    await navigator.clipboard
                      .write([clipboardItem])
                      .then(() => {
                        enqueueSnackbar("Copied to clipboard", {
                          variant: "success",
                        });
                      });
                  }}
                  size="small"
                >
                  <DataUsageRoundedIcon sx={{ fontSize: 14 }} />
                </IconButton>
              )}
              {speedMeans && (
                <IconButton
                  disabled={!speedMeans}
                  onMouseEnter={(event) => {
                    setKineticGraphsAnchor(event.currentTarget);
                  }}
                  color={"primary"}
                  onMouseLeave={() => {
                    setKineticGraphsAnchor(null);
                  }}
                  size="small"
                >
                  <SpeedRoundedIcon sx={{ fontSize: 14 }} />
                </IconButton>
              )}
              {feedback && (
                <IconButton
                  disabled={!feedback}
                  color={isFeedbackBad ? "error" : "primary"}
                  onMouseEnter={(event) => {
                    setFeedbackAnchor(event.currentTarget);
                  }}
                  onMouseLeave={() => {
                    setFeedbackAnchor(null);
                  }}
                  size="small"
                >
                  <AssignmentRoundedIcon sx={{ fontSize: 14 }} />
                </IconButton>
              )}
              {dataID !== undefined && (
                <IconButton
                  disabled={dataID === undefined}
                  color={badStuff.includes("tracking") ? "error" : "primary"}
                  ref={summaryGraphAnchor}
                  onMouseEnter={() => {
                    setSummaryGraphOpen(true);
                  }}
                  onMouseLeave={() => {
                    setSummaryGraphOpen(false);
                  }}
                  onClick={() => {
                    if (dataID) {
                      navigator.clipboard.writeText(dataID).then(() => {
                        enqueueSnackbar("Copied to clipboard", {
                          variant: "success",
                        });
                      });
                    }
                  }}
                  size="small"
                >
                  <TimelineRoundedIcon sx={{ fontSize: 14 }} />
                </IconButton>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
      {clientNotes && (
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.default,
              borderRadius: `2px 8px 8px 8px`,
              display: "flex",
              alignItems: "center",
              border: 2,
              borderColor: (theme) =>
                badStuff.includes("client_notes")
                  ? theme.palette.error.main
                  : "divider",
              px: 1,
              py: 0.5,
              mt: 0.5,
              ml: "25px",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;",
            }}
          >
            <EmojiPeopleRoundedIcon
              fontSize="inherit"
              color={badStuff.includes("client_notes") ? "error" : undefined}
              sx={{ mr: 1 }}
            />
            <Typography>{clientNotes}</Typography>
            {(noteAlertIds ?? []).length > 0 && (
              <Tooltip
                title="No action needed"
                disableInteractive
                placement="top"
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    if (noteAlertIds) {
                      updateWorkoutLogAlert({
                        workoutLogAlertId: noteAlertIds[0],
                        addressType: "dismissed",
                        logId: logId ?? "",
                        trainerId: trainerId,
                      });
                    }
                  }}
                  sx={{ ml: 1 }}
                >
                  <ClearRoundedIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      )}

      <Popover
        style={{ pointerEvents: "none", borderRadius: 350 }}
        open={romGraphsOpen}
        anchorEl={romGraphsAnchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={() => {
          setRomGraphsAnchor(null);
        }}
        disableRestoreFocus
      >
        <Typography style={{ textAlign: "center" }} variant="h5">
          Range of Motion
        </Typography>
        <img
          crossOrigin={"anonymous"}
          src={romGraphURL ?? ""}
          width={500}
          height={500}
          alt="rom graph"
        />
      </Popover>

      <Popover
        sx={{ pointerEvents: "none" }}
        slotProps={{
          paper: {
            sx: { p: 2, pointerEvents: "auto" },
            onMouseEnter: () => {
              setSummaryGraphOpen(true);
            },
            onMouseLeave: () => {
              setSummaryGraphOpen(false);
            },
          },
        }}
        open={summaryGraphOpen}
        anchorEl={summaryGraphAnchor.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={() => {
          setSummaryGraphOpen(false);
        }}
        disableRestoreFocus
      >
        <Typography sx={{ textAlign: "center", mb: 2 }} variant="h2">
          Raw Output
        </Typography>
        {!rawImageError && (
          <img
            crossOrigin={"anonymous"}
            src={summaryGraphURL}
            onError={() => {
              setRawImageError(true);
            }}
            style={{ width: 750 }}
          />
        )}
        {rawImageError && (
          <Button
            size="small"
            variant="text"
            onClick={() => {
              if (!dataID) {
                console.log("no id");
                return;
              }

              api.motionMetadatas
                .generatePlot({
                  fileId: dataID,
                })
                .then(() => {
                  setRawImageError(false);
                });
            }}
          >
            Generate plot
          </Button>
        )}
      </Popover>

      <Popover
        sx={{ pointerEvents: "none" }}
        slotProps={{
          paper: { sx: { p: 2 } },
        }}
        open={feedbackOpen}
        anchorEl={feedbackAnchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={() => {
          setFeedbackAnchor(null);
        }}
        disableRestoreFocus
      >
        <Typography variant="overline">Feedback</Typography>
        <Typography>{feedback}</Typography>
      </Popover>

      {lengths && (
        <Popover
          sx={{ pointerEvents: "none" }}
          slotProps={{
            paper: { sx: { p: 2 } },
          }}
          open={paceGraphOpen}
          anchorEl={paceGraphAnchor}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => {
            setPaceGraphAnchor(null);
          }}
          disableRestoreFocus
        >
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            Pacing
          </Typography>
          {pace && paceIdeal && (
            <Typography sx={{ textAlign: "center", mb: 2 }}>
              {pace + "s / " + paceIdeal + "s"}
            </Typography>
          )}
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptionsPacing}
          />
        </Popover>
      )}

      {speedMeans && (
        <Popover
          sx={{ pointerEvents: "none" }}
          slotProps={{
            paper: { sx: { p: 2 } },
          }}
          open={kineticGraphsOpen}
          anchorEl={kineticGraphsAnchor}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => {
            setKineticGraphsAnchor(null);
          }}
          disableRestoreFocus
        >
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptionsKinetics}
          />
        </Popover>
      )}
    </Box>
  );
});

type UpOrDownArrowProps = {
  target: number | null;
  performed: number | null;
};

function UpOrDownArrow({ target, performed }: UpOrDownArrowProps) {
  if (performed === target || performed === null || target === null) {
    return null;
  }

  if (performed > target) {
    return (
      <NorthEastRoundedIcon
        fontSize="inherit"
        sx={{
          color: (theme) => theme.palette.text.secondary,
          mx: 0.25,
        }}
      />
    );
  }

  return (
    <SouthEastRoundedIcon
      fontSize="inherit"
      sx={{
        color: (theme) => theme.palette.text.secondary,
        mx: 0.25,
      }}
    />
  );
}
