import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useUpdateShortUrl, type ShortUrl } from "@trainwell/features";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { ShortUrlEditDialog } from "./ShortUrlEditDialog";

type Props = {
  shortUrl: ShortUrl;
};

export default function ShortUrlCell({ shortUrl }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const updateShortUrlMutation = useUpdateShortUrl();

  if (!shortUrl) {
    return <Typography>Error finding short url</Typography>;
  }

  return (
    <>
      <Box
        sx={{
          py: 1,
          px: 2,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          backgroundColor: (theme) => theme.palette.background.paper,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <IconButton
          onClick={(event) => {
            event.stopPropagation();

            updateShortUrlMutation.mutate({
              shortUrlId: shortUrl.id,
              data: {
                favorite: !shortUrl.favorite,
              },
            });
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        >
          {shortUrl.favorite ? <StarRoundedIcon /> : <StarBorderRoundedIcon />}
        </IconButton>
        <Box sx={{ flex: 1, mx: 1 }}>
          <Box sx={{ display: "flex", alignItems: "baseline" }}>
            <Typography>
              go.{shortUrl.domain}/{shortUrl.short_path}
            </Typography>
            {shortUrl.is_case_insensitive && (
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  ml: 1,
                }}
              >
                (case insensitive)
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              size="small"
              sx={{ mr: 0.25 }}
              onClick={() => {
                navigator.clipboard.writeText(shortUrl.id).then(() => {
                  enqueueSnackbar("Copied to clipboard", {
                    variant: "success",
                  });
                });
              }}
            >
              <ContentCopyRoundedIcon fontSize="inherit" />
            </IconButton>
            <Typography
              variant="body2"
              sx={{
                overflowWrap: "break-word",
                wordBreak: "break-all",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              id: {shortUrl.id}
            </Typography>
          </Box>
          <Typography>
            <b>{shortUrl.clicks.toLocaleString()}</b> clicks
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              size="small"
              sx={{ mr: 0.25 }}
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    `https://go.${shortUrl.domain}/${shortUrl.short_path}`,
                  )
                  .then(() => {
                    enqueueSnackbar("Copied to clipboard", {
                      variant: "success",
                    });
                  });
              }}
            >
              <ContentCopyRoundedIcon fontSize="inherit" />
            </IconButton>
            <Typography
              variant="body2"
              sx={{
                overflowWrap: "break-word",
                wordBreak: "break-all",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {shortUrl.original_url}
            </Typography>
          </Box>
        </Box>
        <Stack direction={"row"} spacing={1}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();

              setEditDialogOpen(true);
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          >
            <EditRoundedIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={(event) => {
              event.stopPropagation();

              updateShortUrlMutation.mutate({
                shortUrlId: shortUrl.id,
                data: {
                  date_archived: new Date().toISOString(),
                },
              });
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            disabled={Boolean(shortUrl.date_archived)}
          >
            <ArchiveRoundedIcon />
          </IconButton>
        </Stack>
      </Box>
      <ShortUrlEditDialog
        defaultShortUrl={shortUrl}
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
        }}
      />
    </>
  );
}
