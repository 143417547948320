import { Draggable } from "@hello-pangea/dnd";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import { Avatar, Box, CardActionArea, Chip, Typography } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useState } from "react";
import WorkoutPreviewPopover from "src/components/ClientPage/WorkoutPreviewPopover";
import { useAppSelector } from "src/hooks/stateHooks";
import { getSmartTagDetails } from "src/lib/phaseTags";
import { selectTemplateById } from "src/slices/templatesSlice";

type Props = {
  templateId: string;
  index: number;
};

export default function TemplateSelectCell({ templateId, index }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const template = useAppSelector((state) =>
    selectTemplateById(state, templateId),
  );

  if (!template) {
    return <Typography>Loading</Typography>;
  }

  function content(isDragging: boolean) {
    if (!template) {
      return <>Loading</>;
    }

    return (
      <>
        <CardActionArea
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          onContextMenu={(event) => {
            event.preventDefault();
            setAnchorEl(event.currentTarget);
          }}
        >
          <Box
            sx={{
              p: 1,
              borderBottom: 1,
              borderColor: (theme) =>
                isDragging ? theme.palette.primary.main : "divider",
              backgroundColor: (theme) => theme.palette.background.paper,
              borderWidth: isDragging ? "2px" : undefined,
              borderStyle: isDragging ? "solid" : "none",
              borderBottomStyle: "solid",
              borderRadius: isDragging ? 1 : undefined,
              boxShadow: isDragging ? 6 : undefined,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: (theme) => theme.palette.blue.main,
                  width: 15,
                  height: 15,
                  borderRadius: "4px",
                }}
              >
                <FitnessCenterRoundedIcon
                  sx={{
                    fontSize: 12,
                  }}
                />
              </Avatar>
              <Typography sx={{ ml: 0.5 }}>{template.name}</Typography>
            </Box>
            <Typography
              variant="overline"
              sx={{ pb: (template.tags ?? []).length > 0 ? 0.5 : 0 }}
            >
              Updated{" "}
              {formatDistanceToNow(
                new Date(
                  template.metadata.dates_updated[
                    template.metadata.dates_updated.length - 1
                  ] ?? template.metadata.date_created,
                ),
              )}{" "}
              ago
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0,
                m: 0,
              }}
              component="ul"
            >
              {(template.tags ?? []).map((tag, i) => {
                const tagDetails = getSmartTagDetails(tag);
                return (
                  <Chip
                    key={i}
                    label={tagDetails.label}
                    icon={tagDetails.icon}
                    sx={{ mb: 0.25, mr: 0.25, fontSize: 12 }}
                    size="small"
                  />
                );
              })}
            </Box>
          </Box>
        </CardActionArea>
        <WorkoutPreviewPopover
          anchorEl={anchorEl}
          templateId={templateId}
          onClose={() => {
            setAnchorEl(null);
          }}
        />
      </>
    );
  }

  return (
    <Draggable
      key={`template.${templateId}`}
      draggableId={`template.${templateId}`}
      index={index}
      disableInteractiveElementBlocking
    >
      {(provided, snapshot) => (
        <>
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={{
              ...provided.draggableProps.style,
              transform: snapshot.isDragging
                ? provided.draggableProps.style?.transform
                : "translate(0px, 0px)",
            }}
          >
            {content(snapshot.isDragging)}
          </div>
          {snapshot.isDragging && (
            <div style={{ transform: "none !important" }}>{content(false)}</div>
          )}
        </>
      )}
    </Draggable>
  );
}
