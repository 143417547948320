import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { isBlank } from "@trainwell/features";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { useAppDispatch } from "src/hooks/stateHooks";
import { createTrainerCandidate } from "src/slices/trainersSlice";

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function InviteCandidateDialog({ open, onClose }: Props) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  function resetAndClose() {
    onClose();

    setEmail("");
    setFirstName("");
    setLastName("");
  }

  const isValid = !isBlank(email) && !isBlank(firstName) && !isBlank(lastName);

  return (
    <Dialog onClose={resetAndClose} open={open} fullWidth maxWidth="sm">
      <DialogTitleWithClose onClose={resetAndClose}>
        Invite candidate
      </DialogTitleWithClose>
      <DialogContent>
        <Typography sx={{ mb: 2 }}>
          Once you&apos;re done, let this candidate know they can sign into the
          dash to finish setting up their account.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="First name"
              fullWidth
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last name"
              fullWidth
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              helperText="Must be a gmail"
              placeholder="example@gmail.com"
              fullWidth
              value={email}
              type="email"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={resetAndClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          onClick={() => {
            if (!firstName || !lastName || !email) {
              return;
            }

            dispatch(
              createTrainerCandidate({
                email: email,
                firstName: firstName,
                lastName: lastName,
              }),
            )
              .unwrap()
              .then(() => {
                enqueueSnackbar("Created candidate", {
                  variant: "success",
                });

                resetAndClose();
              })
              .catch(() => {
                enqueueSnackbar("Error creating candidate", {
                  variant: "error",
                });
              });
          }}
        >
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}
