import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import TextSnippetRoundedIcon from "@mui/icons-material/TextSnippetRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  isBlank,
  useVideoCallSummaries,
  type VideoCallSummary,
} from "@trainwell/features";
import { format } from "date-fns";
import { useState } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { updateClient } from "src/slices/clientSlice";
import { DialogTitleWithClose } from "../misc/DialogTitleWithClose";

type Props = {
  userId: string;
};

export default function OnboardingVideosPanel({ userId }: Props) {
  const dispatch = useAppDispatch();
  const [newVideoUrl, setNewVideoUrl] = useState<null | string>(null);
  const onboardingVideoUrls = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? state.client.client.onboarding_video_urls
        : undefined,
    shallowEqual,
  );

  const { data: callSummaries } = useVideoCallSummaries({
    userId: userId,
  });

  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">📹 Onboarding videos</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ height: "300px", overflowY: "auto" }}>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <Button
              startIcon={<AddRoundedIcon />}
              onClick={() => {
                setNewVideoUrl("");
              }}
            >
              Add link to onboarding video
            </Button>
          </Box>
          <Stack spacing={2} direction="column">
            {onboardingVideoUrls?.map((video, i) => {
              return (
                <Box key={i}>
                  <video
                    controls
                    width={300}
                    style={{
                      borderRadius: "8px",
                      display: "block",
                    }}
                  >
                    <source src={video.url} />
                  </video>
                  <Typography>
                    {format(new Date(video.date), "MMMM do, yyyy")}
                  </Typography>
                </Box>
              );
            })}
            {callSummaries?.map((callSummary) => (
              <FathomSummary key={callSummary._id} callSummary={callSummary} />
            ))}
          </Stack>
        </Box>
      </AccordionDetails>
      <Dialog
        onClose={() => {
          setNewVideoUrl(null);
        }}
        open={newVideoUrl !== null}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitleWithClose
          onClose={() => {
            setNewVideoUrl(null);
          }}
        >
          Add link to onboarding video
        </DialogTitleWithClose>
        <DialogContent>
          <TextField
            label="Onboarding video url"
            fullWidth
            value={newVideoUrl}
            onChange={(event) => {
              setNewVideoUrl(event.target.value);
            }}
            sx={{ my: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setNewVideoUrl(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={isBlank(newVideoUrl)}
            onClick={() => {
              if (!newVideoUrl) {
                return;
              }

              dispatch(
                updateClient({
                  user_id: userId,
                  onboarding_video_urls: [
                    ...(onboardingVideoUrls ?? []),
                    { url: newVideoUrl, date: new Date().toISOString() },
                  ],
                }),
              );

              setNewVideoUrl(null);
            }}
          >
            Add video
          </Button>
        </DialogActions>
      </Dialog>
    </Accordion>
  );
}

type FathomSummaryProps = {
  callSummary: VideoCallSummary;
};

function FathomSummary({ callSummary }: FathomSummaryProps) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Box key={callSummary._id}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Fathom summary</Typography>
          {callSummary.summary_plain && (
            <IconButton
              size="small"
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              <TextSnippetRoundedIcon fontSize="inherit" />
            </IconButton>
          )}
        </Box>
        <Typography variant="overline">
          {format(callSummary.date_created, "MMMM do, yyyy")}
        </Typography>
        <Link href={callSummary.recording_url} target="_blank">
          Recording
        </Link>
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogTitleWithClose
          onClose={() => {
            setDialogOpen(false);
          }}
        >
          Fathom summary
        </DialogTitleWithClose>
        <DialogContent>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              overflowWrap: "break-word",
            }}
          >
            {callSummary.summary_plain}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
