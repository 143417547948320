import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  testAnalyticsInfo,
  type IncludeExcludeTags,
  type Test,
  type TestAnalyticId,
} from "@trainwell/types";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { createTest, editTest } from "src/slices/testsSlice";

const icon = <CheckBoxOutlineBlankRoundedIcon fontSize="small" />;
const checkedIcon = <CheckBoxRoundedIcon fontSize="small" />;

const testDataDefault = {
  name: "",
  variants: [
    {
      id: "variant_a",
      name: "Variant A",
      include_percent: 0.5,
    },
    {
      id: "variant_b",
      name: "Variant B",
      include_percent: 0.5,
    },
  ],
  is_active: true,
  is_enrolling: false,
  trainer_dependent: false,
  include: {
    utm_content: [],
    utm_campaign: [],
    utm_medium: [],
    utm_source: [],
    source_id: [],
    goal: [],
  },
  exclude: {
    utm_content: [],
    utm_campaign: [],
    utm_medium: [],
    utm_source: [],
    source_id: [],
    goal: [],
  },
  enroll_all: true,
  safe_to_override: false,
};

const UTMParams = [
  "utm_content",
  "utm_campaign",
  "utm_medium",
  "utm_source",
  "source_id",
  "goal",
] as (keyof IncludeExcludeTags)[];

type Props = {
  open: boolean;
  onClose: () => void;
  defaultTest?: Test;
};

export function TestEditDialog({ open, onClose, defaultTest }: Props) {
  const dispatch = useAppDispatch();
  const [testData, setTestData] = useState<Omit<Test, "id">>(
    defaultTest ?? testDataDefault,
  );
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);

  useEffect(() => {
    if (!open) {
      setTestData(defaultTest ?? testDataDefault);
    }
  }, [open, defaultTest]);

  const handleChangeTestData = (data: Partial<Test>) => {
    setTestData({ ...testData, ...data });
  };

  const handleAddVariant = () => {
    handleChangeTestData({
      variants: [
        ...testData.variants,
        { id: "", name: "", include_percent: 0 },
      ],
    });
  };

  const handleDeleteVariant = (indexToDelete: number) => {
    const updatedVariants = testData.variants.filter(
      (_, index) => index !== indexToDelete,
    );
    handleChangeTestData({ variants: updatedVariants });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{defaultTest ? "Edit" : "Create"} test</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Name"
          value={testData.name}
          onChange={(event) => {
            handleChangeTestData({ name: event.target.value });
          }}
          fullWidth
          sx={{ mb: 1 }}
        />
        <TextField
          margin="dense"
          label="Description"
          helperText="Optional"
          value={testData.description}
          onChange={(event) => {
            handleChangeTestData({ description: event.target.value });
          }}
          fullWidth
          size="small"
          multiline
          minRows={2}
          sx={{ mb: 2 }}
        />
        <Typography sx={{ fontWeight: "bold", mb: 1 }}>Settings</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={testData.is_active}
                onChange={(event) =>
                  handleChangeTestData({ is_active: event.target.checked })
                }
              />
            }
            label="Active"
          />
          <FormControlLabel
            control={
              <Switch
                checked={testData.is_enrolling}
                onChange={(event) =>
                  handleChangeTestData({
                    is_enrolling: event.target.checked,
                  })
                }
              />
            }
            label="Enrolling"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={testData.calculate_metrics}
                onChange={(event) =>
                  handleChangeTestData({
                    calculate_metrics: event.target.checked,
                  })
                }
              />
            }
            label="Calculate metrics"
          />
        </FormGroup>
        {testData.calculate_metrics && (
          <FormControl fullWidth sx={{ mb: 2 }} size="small">
            <InputLabel>Visible metrics</InputLabel>
            <Select
              multiple
              value={testData.visible_analytic_ids ?? []}
              onChange={(event) => {
                handleChangeTestData({
                  visible_analytic_ids: event.target.value as TestAnalyticId[],
                });
              }}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Visible metrics"
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((metricId) => (
                    <Chip
                      key={metricId}
                      label={testAnalyticsInfo[metricId].name}
                      size="small"
                    />
                  ))}
                </Box>
              )}
              MenuProps={{
                slotProps: {
                  paper: {
                    sx: {
                      width: 250,
                      maxWidth: 250,
                    },
                  },
                },
              }}
            >
              {Object.keys(testAnalyticsInfo).map((metricId) => (
                <MenuItem key={metricId} value={metricId}>
                  <Checkbox
                    checked={
                      (testData.visible_analytic_ids ?? []).indexOf(
                        metricId as TestAnalyticId,
                      ) > -1
                    }
                  />
                  <ListItemText primary={testAnalyticsInfo[metricId].name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={testData.trainer_dependent}
                onChange={(event) =>
                  handleChangeTestData({
                    trainer_dependent: event.target.checked,
                  })
                }
              />
            }
            label="Trainer dependent"
          />
        </FormGroup>
        {testData.trainer_dependent && (
          <Autocomplete
            multiple
            options={trainerNames
              .filter((trainer) => trainer.is_employee || trainer.is_staff)
              .map((trainer) => {
                return {
                  id: trainer.trainer_id,
                  label: trainer.full_name,
                };
              })
              .sort((a, b) => a.label.localeCompare(b.label))}
            disableCloseOnSelect
            getOptionLabel={(trainer) => trainer.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={(testData.supported_trainer_ids ?? []).map((trainerId) => ({
              id: trainerId,
              label:
                trainerNames.find((trainer) => trainer.trainer_id === trainerId)
                  ?.full_name ?? "",
            }))}
            onChange={(event, newValues) => {
              handleChangeTestData({
                supported_trainer_ids: newValues.map((value) => value.id),
              });
            }}
            componentsProps={{
              paper: {
                sx: {
                  width: 300,
                },
              },
            }}
            size="small"
            renderOption={(props, callType, { selected }) => {
              return (
                <li
                  {...props}
                  style={{
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 0,
                    paddingTop: 0,
                  }}
                >
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {callType.label}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Supported trainers"
                helperText="Only clients that sign up with these trainers will be eligible for enrolling"
              />
            )}
            sx={{ mb: 2 }}
          />
        )}
        <Typography sx={{ fontWeight: "bold", mb: 1, mt: 2 }}>
          Variants
        </Typography>
        <Stack direction={"row"} spacing={2} sx={{ mb: 2 }}>
          {testData.variants.map((variant, index) => (
            <Card variant="outlined" key={index} sx={{ p: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 1,
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Variant {index + 1}
                </Typography>
                <IconButton
                  color="error"
                  onClick={() => handleDeleteVariant(index)}
                  size="small"
                >
                  <DeleteRoundedIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <TextField
                margin="dense"
                size="small"
                label="Id"
                value={variant.id}
                disabled={defaultTest !== undefined}
                onChange={(event) => {
                  const updatedVariants = structuredClone(testData.variants);
                  updatedVariants[index].id = event.target.value;
                  handleChangeTestData({ variants: updatedVariants });
                }}
                fullWidth
              />
              <TextField
                margin="dense"
                size="small"
                label="Name"
                value={variant.name}
                onChange={(event) => {
                  const updatedVariants = structuredClone(testData.variants);

                  updatedVariants[index].name = event.target.value;
                  handleChangeTestData({ variants: updatedVariants });
                }}
                fullWidth
              />
              <TextField
                margin="dense"
                size="small"
                label="Include Percent"
                value={variant.include_percent * 100}
                onChange={(event) => {
                  const value = event.target.value;
                  const updatedVariants = structuredClone(testData.variants);

                  if (value === "" || value === ".") {
                    updatedVariants[index].include_percent = 0;
                  } else if (!isNaN(parseFloat(value))) {
                    updatedVariants[index].include_percent =
                      parseFloat(value) / 100;
                  }

                  handleChangeTestData({ variants: updatedVariants });
                }}
                inputProps={{ min: 0, max: 100, step: 1 }}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Card>
          ))}
        </Stack>
        <Button
          variant="outlined"
          onClick={handleAddVariant}
          sx={{ mb: 1, mr: 2 }}
          startIcon={<AddRoundedIcon />}
        >
          Add variant
        </Button>
        <Button
          variant="text"
          onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
          sx={{ mb: 1 }}
        >
          Show advanced options
        </Button>
        {showAdvancedOptions && (
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={testData.enroll_all || false}
                  onChange={(event) =>
                    handleChangeTestData({ enroll_all: event.target.checked })
                  }
                />
              }
              label="Enroll All"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={testData.safe_to_override || false}
                  onChange={(event) =>
                    handleChangeTestData({
                      safe_to_override: event.target.checked,
                    })
                  }
                />
              }
              label="Safe to Override"
            />
            <Typography>Include Tags</Typography>
            {UTMParams.map((tag) => (
              <TextField
                key={tag}
                margin="dense"
                label={`Include ${tag}`}
                value={testData.include[tag]?.join(", ") ?? []}
                onChange={(event) => {
                  const updatedInclude = { ...testData.include };
                  updatedInclude[tag] = event.target.value.split(", ");
                  handleChangeTestData({ include: updatedInclude });
                }}
                fullWidth
              />
            ))}
            <Typography sx={{ mt: 1 }}>Exclude Tags</Typography>
            {UTMParams.map((tag) => (
              <TextField
                key={tag}
                margin="dense"
                label={`Exclude ${tag}`}
                value={testData.exclude[tag]?.join(", ") ?? []}
                onChange={(event) => {
                  const updatedExclude = { ...testData.exclude };
                  updatedExclude[tag] = event.target.value.split(", ");
                  handleChangeTestData({ exclude: updatedExclude });
                }}
                fullWidth
              />
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!(testData.name !== "") && !(testData.variants.length > 0)}
          onClick={() => {
            if (defaultTest) {
              dispatch(
                editTest({
                  testId: defaultTest.id,
                  data: {
                    ...testData,
                  },
                }),
              );
            } else {
              dispatch(
                createTest({
                  ...testData,
                }),
              );
            }
            onClose();
          }}
          startIcon={<SaveRoundedIcon />}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
