import type { ParagraphElement } from "src/components/chat/MassMessageView";

export function serializeWithKeys(paragraphs: ParagraphElement[]) {
  let message = "";

  for (const paragraph of paragraphs) {
    if (message !== "") {
      message += "\n";
    }

    for (const child of paragraph.children) {
      if ("type" in child) {
        if (child.type === "dynamic_key") {
          message += `{${child.key}}`;
        }
      } else {
        message += child.text;
      }
    }
  }

  return message;
}
