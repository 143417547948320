import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import {
  Box,
  Grid,
  Paper,
  Popover,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import type { CoachMetricTemplateSubmodule } from "@trainwell/types";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import Histogram from "highcharts/modules/histogram-bellcurve";
import React, { useState } from "react";
import { queryMetric } from "src/lib/metrics";
import { round } from "src/lib/misc";

if (typeof Highcharts === "object") {
  Histogram(Highcharts);
}

const hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

const styles: Record<string, React.CSSProperties> = {
  metricContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "4px",
  },
  metric: {
    fontSize: "20px",
    fontWeight: 500,
  },
  vLine: {
    height: "24px",
    borderRight: "1px solid rgb(0, 0, 0)",
    margin: "0 8px 0 8px",
    flexGrow: 0,
    flexBasis: 0,
  },
};

type Props = {
  metrics: any;
  companyMetrics: any;
  submodule: CoachMetricTemplateSubmodule;
  window: number;
  trainerName: string;
};

export default function Distribution({
  metrics,
  companyMetrics,
  submodule,
  window,
  trainerName,
}: Props) {
  const theme = useTheme();
  const [distributionAnchorEl, setDistributionAnchorEl] =
    useState<HTMLDivElement | null>(null);
  const openDistribution = Boolean(distributionAnchorEl);

  const handleOpenDistribution = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    setDistributionAnchorEl(e.currentTarget);
  };

  const handleCloseDistribution = () => {
    setDistributionAnchorEl(null);
  };

  const offsetHours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ];

  if (
    submodule.distribution_buckets &&
    submodule.distribution_buckets.length === 24
  ) {
    const timezoneOffset = new Date().getTimezoneOffset() / 60;

    for (let i = 0; i < timezoneOffset; i++) {
      const x = offsetHours.shift();
      offsetHours.push(x!);
    }
  }

  const metric = queryMetric(metrics, submodule.metric);
  const companyMetric = queryMetric(companyMetrics, submodule.metric);

  let trainerData = queryMetric(metrics, submodule.distribution_metric!);
  let companyData = queryMetric(companyMetrics, submodule.distribution_metric!);

  trainerData = trainerData.map((value: any) => {
    value = submodule.per_day ? value / window : value;
    return round(value, submodule.precision);
  });

  companyData = companyData.map((value: any) => {
    value = submodule.per_day ? value / window : value;
    return round(value, submodule.precision);
  });

  const binData = (data: any[], binWidth: number) => {
    const hData: number[][] = [];
    const size = data.length;
    let bins = round(Math.sqrt(size));

    bins = bins > 50 ? 50 : bins;
    const max = Math.max.apply(null, data);
    const min = 0;
    const range = max - min;
    let width = round(range / bins);
    let binBottom: number;
    let binTop: number;

    if (binWidth) {
      width = binWidth;
      bins = round(range / width);
    }

    for (let i = 0; i < bins; i++) {
      binBottom = min + i * width;
      binTop = binBottom + width;

      if (!hData[i]) {
        hData[i] = [];
        hData[i][0] = binBottom + width / 2;
      }

      for (let j = 0; j < size; j++) {
        const x = data[j];

        // eslint-disable-next-line no-self-assign
        i === 0 && j === 0 ? (binBottom -= 0) : (binBottom = binBottom);

        if (x >= binBottom && x < binTop) {
          !hData[i][1] ? (hData[i][1] = 1) : hData[i][1]++;
        }
      }

      if (hData[i][1] === undefined) {
        // @ts-expect-error
        hData[i][1] = null;
      }
    }

    return hData;
  };

  const setBinWidth = (data: any[]) => {
    const size = data.length;
    const bins = round(Math.sqrt(size));
    const max = Math.max.apply(null, data);
    const min = Math.min.apply(null, data);
    const range = max - min;
    const width = round(range / bins);

    return width;
  };

  const binWidth = round(
    (setBinWidth(trainerData) + setBinWidth(companyData)) / 2,
  );

  const chartOptions: Highcharts.Options = {
    colors: [theme.palette.primary.main],
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: "transparent",
      width: 500,
      height: 300,
    },
    legend: {
      enabled: true,
      itemStyle: { color: theme.palette.text.secondary },
    },
    title: undefined,
    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          submodule.metric_title +
          ":</b> " +
          ((this.x as number) - binWidth / 2) +
          "-" +
          ((this.x as number) + binWidth / 2) +
          "<br/><b>Frequency: </b>" +
          this.y
        );
      },
    },
    plotOptions: {
      column: {
        grouping: false,
      },
      series: {
        animation: false,
        pointStart: 0,
        borderWidth: 0.5,
        borderColor: "rgba(255,255,255,0.5)",
      },
    },
    xAxis: {
      title: {
        text: submodule.metric_title,
        style: {
          color: theme.palette.text.primary,
        },
      },
      tickInterval: binWidth,
      labels: {
        style: {
          color: theme.palette.text.secondary,
        },
      },
    },
    yAxis: [
      {
        title: {
          text: `${trainerName === "tw" ? "trainwell" : trainerName}'s Frequency`,
          style: {
            color: theme.palette.text.primary,
          },
        },
        labels: {
          style: {
            color: theme.palette.text.secondary,
          },
        },
      },
      {
        title: {
          text: "trainwell's Frequency",
          style: {
            color: theme.palette.text.primary,
          },
        },
        opposite: true,
        labels: {
          style: {
            color: theme.palette.text.secondary,
          },
        },
      },
    ],
    series: [
      {
        type: "column",
        data: binData(trainerData, binWidth),
        color: theme.palette.primary.main,
        name: trainerName === "tw" ? "trainwell" : trainerName,
      },
      {
        type: "column",
        data: binData(companyData, binWidth),
        name: "trainwell",
        color: alpha("#000000", 0.5),
        yAxis: 1,
      },
    ],
    accessibility: {
      enabled: false,
    },
  };

  const chartOptionsBuckets: Highcharts.Options = {
    colors: [theme.palette.primary.main],
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: "transparent",
      width: 500,
      height: 300,
    },
    legend: {
      enabled: true,
      itemStyle: { color: theme.palette.text.secondary },
    },
    title: undefined,
    tooltip: {
      formatter: function () {
        return "<b>Hour:</b> " + this.x + "<br/><b>Count: </b>" + this.y;
      },
    },
    plotOptions: {
      column: {
        grouping: false,
      },
      series: {
        animation: false,
        pointStart: 0,
        borderWidth: 0.5,
        borderColor: "rgba(255,255,255,0.5)",
      },
    },
    xAxis: {
      title: {
        text: "Hour",
        style: {
          color: theme.palette.text.primary,
        },
      },
      categories: submodule.distribution_buckets?.map((item) =>
        item.toString(),
      ),
    },
    yAxis: [
      {
        title: {
          text:
            `${trainerName === "tw" ? "trainwell" : trainerName}'s ` +
            submodule.metric_title,
          style: {
            color: theme.palette.text.primary,
          },
        },
        labels: {
          style: {
            color: theme.palette.text.secondary,
          },
        },
      },
      {
        title: {
          text: "trainwell's " + submodule.metric_title,
          style: {
            color: theme.palette.text.primary,
          },
        },
        opposite: true,
        labels: {
          style: {
            color: theme.palette.text.secondary,
          },
        },
      },
    ],
    series: [
      {
        type: "column",
        data: hours.map((h) => {
          return {
            x: h,
            y: trainerData[offsetHours[h]],
          };
        }),
        color: theme.palette.primary.main,
        name: trainerName === "tw" ? "trainwell" : trainerName,
      },
      {
        type: "column",
        data: hours.map((h) => {
          return {
            x: h,
            y: companyData[offsetHours[h]],
          };
        }),
        name: "trainwell",
        color: theme.palette.text.secondary,
        yAxis: 1,
      },
    ],
    accessibility: {
      enabled: false,
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
        flexGrow: 1,
        margin: "0 2px 0 2px",
        borderRadius: "4px",
      }}
      aria-owns={openDistribution ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onMouseEnter={handleOpenDistribution}
      onMouseLeave={handleCloseDistribution}
    >
      <BarChartRoundedIcon style={{ fontSize: "18px" }} />
      <Popover
        open={openDistribution}
        anchorEl={distributionAnchorEl}
        sx={{
          pointerEvents: "none",
        }}
        PaperProps={{
          sx: {
            pointerEvents: "auto",
          },
        }}
        onClose={handleCloseDistribution}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper
          sx={{
            padding: 3,
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" sx={{ mb: 1 }}>
            {submodule.metric_title}
          </Typography>
          <Grid item style={styles.metricContainer}>
            <div style={{ flexGrow: 1, flexBasis: 0, textAlign: "center" }}>
              <Typography style={styles.metric}>{metric}</Typography>
            </div>
            <div style={styles.vLine}></div>
            <div style={{ flexGrow: 1, flexBasis: 0, textAlign: "center" }}>
              <Typography style={styles.metric}>{companyMetric}</Typography>
            </div>
          </Grid>
          {submodule.distribution_buckets ? null : (
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          )}
          {submodule.distribution_buckets ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptionsBuckets}
            />
          ) : null}
        </Paper>
      </Popover>
    </Box>
  );
}
