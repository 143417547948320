import {
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RestrictAccess from "src/components/misc/RestrictAccess";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { fetchAllVacations } from "src/slices/vacationsSlice";
import SimpleVacationCell from "./SimpleVacationCell";

export default function AllVacationsPage() {
  const dispatch = useAppDispatch();
  const vacations = useAppSelector((state) => state.vacations.vacations);
  const vacationsStatus = useAppSelector((state) => state.vacations.status);
  const [onlyUpcoming, setOnlyUpcoming] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyUpcoming(event.target.checked);
  };

  useEffect(() => {
    dispatch(fetchAllVacations({ onlyFetchUpcoming: onlyUpcoming }));
  }, [dispatch, onlyUpcoming]);

  return (
    <RestrictAccess location={"vacations"} showNoAccessMessage>
      <Box sx={{ px: 3, pt: 3 }}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          Vacations
        </Typography>
        <FormGroup sx={{ mb: 2 }}>
          <FormControlLabel
            control={<Switch checked={onlyUpcoming} onChange={handleChange} />}
            label="Show only upcoming"
          />
        </FormGroup>
      </Box>
      {vacationsStatus === "loading" ? (
        <LoadingPage message="Getting vacations" />
      ) : (
        <Stack
          direction="column"
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            borderTop: 1,
            borderColor: "divider",
          }}
        >
          {vacations.map((vacation) => (
            <SimpleVacationCell key={vacation.id} vacationId={vacation.id} />
          ))}
        </Stack>
      )}
    </RestrictAccess>
  );
}
