import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import { DateTimePicker } from "@mui/x-date-pickers";
import { isBlank } from "@trainwell/features";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { createCustomActionItem } from "src/slices/actionItemSlice";
import { selectAllVisibleClients } from "src/slices/clientsSlice";

type Props = {
  open: boolean;
  onClose: () => void;
  defaultUserId?: string;
  defaultMessage?: string;
};

export default function CreateActionItemDialog({
  open,
  onClose,
  defaultUserId,
  defaultMessage,
}: Props) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const clients = useAppSelector(selectAllVisibleClients);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [message, setmessage] = useState("");
  const [withDate, setWithDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<Date | null>(new Date());

  useEffect(() => {
    if (!open) {
      setmessage(defaultMessage ?? "");
      setSelectedUserId(defaultUserId ?? null);
    }
  }, [open]);

  const userIds = clients.map((client) => client.user_id);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (loading) {
          return;
        }

        onClose();
      }}
    >
      <DialogTitle>Create a custom action item</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Custom action items never count against checkpoints or office hours.
          You can schedule them to show up in the future too!
        </DialogContentText>
        <Stack direction={"column"} spacing={2}>
          <Autocomplete
            value={selectedUserId}
            onChange={(event: any, newValue) => {
              setSelectedUserId(newValue);
            }}
            fullWidth
            getOptionLabel={(option) =>
              clients.find((client) => client.user_id === option)?.full_name ??
              ""
            }
            options={userIds}
            renderInput={(params) => <TextField {...params} label="Client" />}
          />
          <TextField
            label="Notes"
            multiline
            minRows={2}
            fullWidth
            value={message}
            onChange={(event) => {
              setmessage(event.target.value);
            }}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={withDate}
                  onChange={(event) => {
                    setWithDate(event.target.checked);
                  }}
                />
              }
              label="Send at a specific time"
            />
          </FormGroup>
          {withDate && (
            <DateTimePicker
              label="Send date"
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose();
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => {
            if (!selectedUserId) {
              return;
            }

            setLoading(true);

            dispatch(
              createCustomActionItem({
                userId: selectedUserId,
                message: message,
                sendDate: date?.toISOString(),
              }),
            )
              .unwrap()
              .then(() => {
                onClose();
                setLoading(false);
              })
              .catch(() => {
                enqueueSnackbar("Error creating custom action item", {
                  variant: "error",
                });

                setLoading(false);
              });
          }}
          disabled={isBlank(message) || !selectedUserId || (withDate && !date)}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
