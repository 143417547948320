import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import type {
  ActionItem,
  Client,
  HabitSummaryMessage,
  Message,
  Reaction,
} from "@trainwell/types";
import {
  addDays,
  differenceInCalendarDays,
  format,
  isPast,
  isSameWeek,
} from "date-fns";
import type { Descendant } from "slate";
import type { ChatSort } from "src/components/chat/ChatSortButton";
import type { ParagraphElement } from "src/components/chat/MassMessageView";
import { socket } from "src/hooks/useSocket";
import { trainerHasAccess } from "src/lib/accessRoles";
import {
  getChatFromTicket,
  getChatsMatchingFilter,
  sortChatsByActionItemsGrouped,
  sortChatsByNewest,
  sortChatsByOldestActionItem,
} from "src/lib/chat";
import { getClientDisplayName } from "src/lib/client";
import { getTrainerName } from "src/lib/coachUtility";
import { getDateWithTimezoneOffset } from "src/lib/date";
import { serializeWithKeys } from "src/lib/slateLib";
import { api } from "src/lib/trainwellApi";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import type { RootState } from "src/slices/store";
import {
  clearActionItemWithType,
  selectAllActionItems,
} from "./actionItemSlice";
import { selectClientById } from "./clientsSlice";
import { selectTicketById, upsertTickets } from "./ticketsSlice";
import { selectIsAuditing, selectPrimaryTrainer } from "./trainerSlice";
import { updateTrainer } from "./trainersSlice";

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export interface Chat {
  /** userId for client chats, chatId for group chats */
  id: string;

  forceTrainerId?: string;
  /** Used for interim clients */
  oldTrainerId?: string;

  pinned: boolean;
  clientName: string;
  messages: Message[];
  firstMessageFetchState: "idle" | "fetching" | "done";
  dateCreated: string;

  clientTimezoneOffset?: number;
  clientHeadshotURL: string;
  oldestMessageNeedingResponse?: string;
  oldestUnreadMessageFromClient?: string;
  oldestUnreadMessageIdFromClient?: string;
  oldestQuestionFromClient?: string;

  isGroupChat: boolean;
  isTrainwell?: boolean;
  ticketId?: string;
  memberIds?: string[];

  /** Used by Virtuoso for virtual scrolling */
  firstChatIndex: number;

  loadingState: "idle" | "loading" | "succeeded" | "failed" | "endReached";

  forceDisabled?: boolean;

  banner?: string;
}

export const fetchChats = createAsyncThunk(
  "chat/fetchChats",
  async (_, { getState }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    const actionItems = selectAllActionItems(state);

    if (!trainer) {
      throw new Error("No trainer");
    }

    const newClients = JSON.parse(
      JSON.stringify(state.clients.clients),
    ) as Client[];

    const newChats: Chat[] = [];

    newClients.forEach((client) => {
      let unreadDate: string | undefined = undefined;

      const questionAi = actionItems.find(
        (ai) =>
          ai.user_id === client.user_id &&
          ai.type === "respond" &&
          ai.respond_type === "question",
      );
      const respondAi = actionItems.find(
        (ai) =>
          ai.user_id === client.user_id &&
          ai.type === "respond" &&
          (ai.respond_type === "message" || ai.respond_type === "habit"),
      );
      const unreadAi = actionItems.find(
        (ai) => ai.user_id === client.user_id && ai.type === "read_chat",
      );

      if (unreadAi) {
        unreadDate =
          (unreadAi.date_to_send as string | null) ??
          (unreadAi.date_created as string);
      }

      if (trainer.force_unread_chat_ids.includes(client.user_id)) {
        unreadDate = new Date().toISOString();
      }

      const isClientBannerActive = Boolean(
        client.banner_coach?.active && client.banner_coach.text,
      );
      const isCoachBannerActive = Boolean(
        trainer.banner?.active && trainer.banner.text,
      );

      newChats.push({
        isGroupChat: false,
        id: client.user_id,
        clientTimezoneOffset: client.default_timezone_offset,
        messages: client.latest_message?.send_date
          ? [client.latest_message]
          : [],
        firstMessageFetchState: "idle",
        clientName: getClientDisplayName(client),
        clientHeadshotURL: client.headshot_url,
        loadingState: "idle",
        oldestUnreadMessageFromClient: unreadDate,
        oldestMessageNeedingResponse: !respondAi
          ? undefined
          : ((respondAi.date_to_send as string | null) ??
            (respondAi.date_created as string)),
        oldestQuestionFromClient: !questionAi
          ? undefined
          : ((questionAi.date_to_send as string | null) ??
            (questionAi.date_created as string)),
        dateCreated: client.account.membership
          .date_membership_started as string,
        pinned: trainer.pinned_chat_ids.includes(client.user_id),
        firstChatIndex: 1000000,
        forceDisabled: Boolean(
          client.trainer_id_interim && client.trainer_id === trainer.trainer_id,
        ),
        banner: isClientBannerActive
          ? client.banner_coach?.text
          : isCoachBannerActive
            ? trainer.banner?.text
            : undefined,
        oldTrainerId: client.trainer_id_interim ? client.trainer_id : undefined,
      });
    });

    return newChats;
  },
);

export const fetchTicketChats = createAsyncThunk(
  "chat/fetchTicketChats",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    const newChats: Chat[] = [];

    const ticketChats = await api.trainers.getTicketChats(trainer!.trainer_id);

    ticketChats.forEach((ticketChat) => {
      const chat = getChatFromTicket(
        ticketChat,
        getTrainerName(
          ticketChat.support_ticket.trainer_id,
          state.trainers.trainerNames,
        ),
        trainer!.trainer_id,
      );

      newChats.push(chat);
    });

    const tickets = ticketChats.map((chat) => chat.support_ticket);

    dispatch(upsertTickets({ tickets: tickets }));

    return newChats;
  },
);

export const fetchFirstMessages = createAsyncThunk(
  "chat/fetchFirstMessages",
  async (chatId: string, { getState }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    const chat = state.chat.chats[chatId];

    if (chat && trainer) {
      console.log(
        "fetch first messages for",
        chatId,
        chat.oldTrainerId ?? chat.forceTrainerId ?? trainer.trainer_id,
      );

      const messageRes = await api.messages.findMany({
        fromId: chat.isGroupChat
          ? undefined
          : (chat.oldTrainerId ?? chat.forceTrainerId ?? trainer.trainer_id),
        toId: state.chat.chats[chatId].id,
        beforeDate: new Date().toISOString(),
      });

      return { messages: messageRes.messages, trainer };
    }
  },
);

export const fetchOfficialChats = createAsyncThunk(
  "chat/fetchOfficialChats",
  async (_, { getState }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    const newChats: Chat[] = [];

    if (
      trainerHasAccess(trainer?.access_roles, "official_trainwell_group_chats")
    ) {
      const officialTrainwellChats =
        await api.messages.getOfficialTrainwellChats();

      officialTrainwellChats.forEach((chat) => {
        newChats.push({
          isGroupChat: true,
          id: chat.chat_id,
          messages: chat.latest_message ? [chat.latest_message as any] : [],
          firstMessageFetchState: "idle",
          clientName: chat.user_full_name ?? chat.name,
          clientHeadshotURL: chat.user_headshot_url ?? "",
          loadingState: "idle",
          oldestUnreadMessageFromClient:
            chat.latest_message &&
            (chat.latest_message as any).trainer_id !== trainer?.trainer_id &&
            !(chat.latest_message as any).read_date
              ? new Date().toISOString()
              : undefined,
          dateCreated: chat.date_created as string,
          isTrainwell: true,
          pinned: false,
          firstChatIndex: 1000000,
        });
      });
    }

    return newChats;
  },
);

export const openChat = createAsyncThunk(
  "chat/openChat",
  async (data: { chatId: string; forceTrainerId?: string }, { getState }) => {
    const { chatId, forceTrainerId } = data;

    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    if (!trainer) {
      throw new Error("No trainer");
    }

    if (chatId === state.chat.selectedChatId && !forceTrainerId) {
      return;
    }

    if (state.chat.view === "mass_message" || state.chat.view === "threads") {
      return;
    }

    if (state.chat.mediaUploadUi === "uploading") {
      return;
    }

    if (state.chat.chats[chatId] && !forceTrainerId) {
      return { messages: [], trainer: trainer };
    } else {
      console.log(`Chat: found a user_id but no chat, manually fetching info`);

      const client = await api.clients.getOne(chatId, true);

      console.log(
        `Chat: fetching chat between\ntrainer_id: ${
          forceTrainerId ?? client.trainer_id
        }\nuser_id: ${client.user_id}`,
      );

      const messagesRes = await api.messages.findMany({
        fromId: forceTrainerId ?? client.trainer_id,
        toId: client.user_id,
        beforeDate: new Date().toISOString(),
        excludeTypes: trainer.settings.hide_habit_day_summaries
          ? ["daily_habit_summary"]
          : undefined,
      });

      const newChat: Chat = {
        clientName: client.full_name,
        clientHeadshotURL: client.headshot_url,
        loadingState: "idle",
        messages: messagesRes.messages,
        firstMessageFetchState: "done",
        id: chatId,
        isGroupChat: false,
        dateCreated: new Date().toISOString(),
        pinned: false,
        forceTrainerId: forceTrainerId,
        firstChatIndex: 1000000,
      };

      return {
        messages: messagesRes.messages,
        chat: newChat,
        trainer: trainer,
      };
    }
  },
);

export const fetchMoreMessages = createAsyncThunk(
  "chat/fetchMoreMessages",
  async (
    data: { chatId: string; all?: boolean },
    { getState, rejectWithValue, dispatch },
  ) => {
    const { chatId, all } = data;

    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    if (!trainer) {
      throw new Error("No trainer");
    }

    const chat = state.chat.chats[chatId];

    if (!chat) {
      throw new Error("No chat");
    }

    if (chat.loadingState === "endReached") {
      console.log("Chat: Skip fetching messages, end reached");

      return rejectWithValue("endReached");
    }

    if (chat.loadingState === "loading") {
      console.log("Chat: Skip fetching messages, loading");

      return rejectWithValue(null);
    }

    console.log("Chat: Fetch more messages");

    await dispatch(updateChat({ id: chatId, loadingState: "loading" }));

    const earliestStartDate = chat.messages[0].send_date as string;

    const messageRes = await api.messages.findMany({
      fromId: chat.isGroupChat
        ? undefined
        : (chat.oldTrainerId ?? chat.forceTrainerId ?? trainer!.trainer_id),
      toId: chat.id,
      beforeDate: earliestStartDate,
      limit: all ? -1 : undefined,
      excludeTypes: trainer.settings.hide_habit_day_summaries
        ? ["daily_habit_summary"]
        : undefined,
    });

    return { messages: messageRes.messages, trainer };
  },
);

export const openTicketChat = createAsyncThunk(
  "chat/openTicketChat",
  async (ticketId: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    if (state.chat.mediaUploadUi !== "uploading") {
      const trainer = selectPrimaryTrainer(state);

      const ticketChats: Chat[] = [];

      for (const chatId in state.chat.chats) {
        if (state.chat.chats[chatId].ticketId) {
          ticketChats.push(state.chat.chats[chatId]);
        }
      }

      const chatIndex = ticketChats.findIndex(
        (chat) => chat.ticketId === ticketId,
      );

      if (chatIndex !== -1) {
        const messageRes = await api.messages.findMany({
          toId: ticketChats[chatIndex].id,
          beforeDate: new Date().toISOString(),
          excludeTypes: trainer?.settings.hide_habit_day_summaries
            ? ["daily_habit_summary"]
            : undefined,
        });

        return messageRes.messages;
      } else {
        const ticketChat = await api.tickets.getChat(ticketId);

        const chat = getChatFromTicket(
          ticketChat,
          getTrainerName(
            ticketChat.support_ticket.trainer_id,
            state.trainers.trainerNames,
          ),
          trainer!.trainer_id,
        );

        dispatch(addChat(chat));

        const messageRes = await api.messages.findMany({
          toId: chat.id,
          beforeDate: new Date().toISOString(),
          excludeTypes: trainer?.settings.hide_habit_day_summaries
            ? ["daily_habit_summary"]
            : undefined,
        });

        return messageRes.messages;
      }
    }
  },
);

export const markChatAsUnread = createAsyncThunk(
  "chat/markChatAsUnread",
  async (chatId: string | undefined, { getState, dispatch }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);
    if (!trainer) {
      throw new Error("No trainer");
    }

    const chatIds = chatId ? [chatId] : state.chat.selectedChatIds;

    const newUnreadChats = [
      ...new Set([...trainer.force_unread_chat_ids, ...chatIds]),
    ];

    dispatch(
      updateTrainer({
        trainer_id: trainer.trainer_id,
        force_unread_chat_ids: newUnreadChats,
      }),
    );

    return chatIds;
  },
);

export const toggleChatPinned = createAsyncThunk(
  "chat/toggleChatPinned",
  async (chatId: string | undefined, { getState, dispatch }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);
    if (!trainer) {
      throw new Error("No trainer");
    }

    const chatIds = chatId ?? state.chat.selectedChatIds;

    let newPinnedChats = [...trainer.pinned_chat_ids];

    if (chatId) {
      if (newPinnedChats.includes(chatId)) {
        newPinnedChats = newPinnedChats.filter((id) => id !== chatId);
      } else {
        newPinnedChats.push(chatId);
      }
    } else {
      newPinnedChats = [...new Set([...newPinnedChats, ...chatIds])];
    }

    dispatch(
      updateTrainer({
        trainer_id: trainer.trainer_id,
        pinned_chat_ids: newPinnedChats,
      }),
    );

    return newPinnedChats;
  },
);

export const sendMassMessages = createAsyncThunk(
  "chat/sendMassMessages",
  async (data: { dynamicKeys: string[] }, { getState, dispatch }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);
    if (!trainer) {
      throw new Error("No trainer");
    }

    const chatIdsToMassMessage = state.chat.chatIdsToMassMessage;

    if (chatIdsToMassMessage.length === 0) {
      throw new Error("No mass messages");
    }

    const messages: { message: string; userId: string }[] = [];

    await Promise.all(
      chatIdsToMassMessage.map(async (chatId) => {
        if (state.chat.pendingMessages[chatId]) {
          await dispatch(
            sendTextMessage({
              userId: chatId,
              text: state.chat.pendingMessages[chatId],
              sourceDetailed: "mass_message",
            }),
          );

          messages.push({
            userId: chatId,
            message: state.chat.pendingMessages[chatId],
          });
        }
      }),
    );

    const prettyMassMessage = serializeWithKeys(
      state.chat.rawMassMessage as any,
    );

    api.trainers.saveMassMessageLog({
      trainerId: trainer.trainer_id,
      messages: messages,
      dynamicKeys: data.dynamicKeys,
      selectedChatIds: state.chat.selectedChatIds,
      chatFilters: state.chat.selectedFilters,
      prettyMassMessage: prettyMassMessage,
    });
  },
);

export const deleteMessage = createAsyncThunk(
  "chat/deleteMessage",
  async (messageId: string) => {
    const response = await api.messages.removeOne(messageId);

    return response;
  },
);

export const sendMessageAsSms = createAsyncThunk(
  "chat/sendMessageAsSms",
  async (data: { messageId: string; chatId: string }) => {
    const response = await api.messages.sendAsSms(data.messageId);

    return response;
  },
);

export const sendMessageAsEmail = createAsyncThunk(
  "chat/sendMessageAsEmail",
  async (data: {
    messageId: string;
    chatId: string;
    body: string;
    subject?: string;
  }) => {
    const response = await api.messages.sendAsEmail(
      data.messageId,
      data.body,
      data.subject,
    );

    return response;
  },
);

export const reactMessage = createAsyncThunk(
  "chat/reactMessage",
  async (data: { messageId: string; reactions: Reaction[] }) => {
    const response = await api.messages.reactMessage(
      data.messageId,
      data.reactions,
    );

    return response;
  },
);

export const sendTextMessage = createAsyncThunk(
  "chat/sendTextMessage",
  async (
    data: {
      text: string;
      userId: string;
      toGroup?: boolean;
      asTrainwell?: boolean;
      sourceDetailed?: Message["source_detailed"];
    },
    { getState, dispatch },
  ) => {
    const {
      text,
      userId,
      toGroup = false,
      asTrainwell = false,
      sourceDetailed,
    } = data;

    console.log("Chat: send text message to: " + userId);

    const state = getState() as RootState;
    const trainer = selectPrimaryTrainer(state);
    const isGhosting = selectIsAuditing(state);
    const client = selectClientById(state, userId);

    if (!trainer) {
      throw new Error("Trainer not found");
    }

    dispatch(readChat(userId));

    const fromTrainerId = client?.trainer_id_interim
      ? client.trainer_id
      : trainer.trainer_id;

    const { message } = await api.messages.sendOne({
      to_id: userId,
      from_id: toGroup && asTrainwell ? "copilot" : fromTrainerId,
      type: "text",
      text: text,
      source: "dashboard",
      force_send: isGhosting,
      source_detailed: sourceDetailed,
    });

    dispatch(
      addMessage({
        clientID: userId,
        message: message,
        isFromCoach: true,
      }),
    );
  },
);

export const sendVideoMessage = createAsyncThunk(
  "chat/sendVideoMessage",
  async (
    data: {
      video_url: string;
      thumbnail_url: string;
      userId: string;
      toGroup?: boolean;
      asTrainwell?: boolean;
      width: number;
      height: number;
    },
    { getState, dispatch },
  ) => {
    const {
      video_url,
      thumbnail_url,
      userId,
      toGroup = false,
      asTrainwell = false,
      width,
      height,
    } = data;

    console.log("Chat: send video message to: " + userId);

    const state = getState() as RootState;
    const trainer = selectPrimaryTrainer(state);
    const isGhosting = selectIsAuditing(state);
    const client = selectClientById(state, userId);

    if (!trainer) {
      throw new Error("Trainer not found");
    }

    const fromTrainerId = client?.trainer_id_interim
      ? client.trainer_id
      : trainer.trainer_id;

    const { message } = await api.messages.sendOne({
      to_id: userId,
      from_id: toGroup && asTrainwell ? "copilot" : fromTrainerId,
      type: "video",
      media_url: video_url,
      thumbnail_url: thumbnail_url,
      source: "dashboard",
      force_send: isGhosting,
      width: width,
      height: height,
    });

    if (message) {
      dispatch(
        addMessage({
          clientID: userId,
          message: message,
          isFromCoach: true,
        }),
      );
    } else {
      throw new Error("Video failed to send");
    }
  },
);

export const sendImageMessage = createAsyncThunk(
  "chat/sendImageMessage",
  async (
    data: {
      image_url: string;
      userId: string;
      toGroup?: boolean;
      asTrainwell?: boolean;
      width: number;
      height: number;
    },
    { getState, dispatch },
  ) => {
    const {
      image_url,
      userId,
      toGroup = false,
      asTrainwell = false,
      width,
      height,
    } = data;

    console.log("Chat: send image message to: " + userId);

    const state = getState() as RootState;
    const trainer = selectPrimaryTrainer(state);
    const isGhosting = selectIsAuditing(state);
    const client = selectClientById(state, userId);

    if (!trainer) {
      throw new Error("Trainer not found");
    }

    const fromTrainerId = client?.trainer_id_interim
      ? client.trainer_id
      : trainer.trainer_id;

    const { message } = await api.messages.sendOne({
      to_id: userId,
      from_id: toGroup && asTrainwell ? "copilot" : fromTrainerId,
      type: "image",
      media_url: image_url,
      source: "dashboard",
      force_send: isGhosting,
      width: width,
      height: height,
    });

    if (message) {
      dispatch(
        addMessage({
          clientID: userId,
          message: message,
          isFromCoach: true,
        }),
      );
    } else {
      throw new Error("Image failed to send");
    }
  },
);

export const readChat = createAsyncThunk(
  "chat/readChat",
  async (chatId: string, { getState, rejectWithValue, dispatch }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);
    const isGhosting = selectIsAuditing(state);
    const client = selectClientById(state, chatId);

    const disableGhostingProtections = state.trainer.disableGhostingProtections;

    if (!trainer) {
      throw new Error("Trainer not found");
    }

    const fromTrainerId = client?.trainer_id_interim
      ? client.trainer_id
      : trainer.trainer_id;

    const chat = state.chat.chats[chatId];

    if (chat.forceTrainerId) {
      return rejectWithValue(null);
    }

    if (
      (!isGhosting || state.trainer.disableGhostingProtections) &&
      trainer.force_unread_chat_ids.includes(chatId)
    ) {
      const newChatIds = [...trainer.force_unread_chat_ids].filter(
        (id) => id !== chatId,
      );

      dispatch(
        updateTrainer({
          trainer_id: trainer.trainer_id,
          force_unread_chat_ids: newChatIds,
        }),
      );
    }

    let latestMessageToRead: Message | undefined;

    for (
      let messageIndex = chat.messages.length - 1;
      messageIndex >= 0;
      messageIndex--
    ) {
      const message = chat.messages[messageIndex];

      if (chat.isGroupChat) {
        if (
          message.from_id !== trainer.trainer_id &&
          (!message.read_statuses ||
            !message.read_statuses.hasOwnProperty(trainer.trainer_id) ||
            !message.read_statuses[trainer.trainer_id])
        ) {
          latestMessageToRead = message;

          break;
        }
      } else {
        if (message.from_id !== trainer.trainer_id && !message.read_date) {
          latestMessageToRead = message;

          break;
        }
      }
    }

    const actionItems = selectAllActionItems(state);

    const hasUnreadActionItems = actionItems.some(
      (ai) => ai.user_id === chat.id && ai.type === "read_chat",
    );

    if (!latestMessageToRead && !hasUnreadActionItems) {
      console.log(
        `Chat: Skip reading chat due to nothing to read (1): '${chat.id}'`,
      );

      return { isGhosting: isGhosting, disableGhostingProtections };
    }

    if (!isGhosting || state.trainer.disableGhostingProtections) {
      console.log(`Chat: Reading chat: '${chat.id}'`);

      if (chat.isGroupChat) {
        if (!latestMessageToRead) {
          console.log(
            `Chat: Skip reading chat due to nothing to read (2): '${chat.id}'`,
          );

          return { isGhosting: isGhosting, disableGhostingProtections };
        }

        socket.emit("readMessage", {
          from_id: latestMessageToRead.from_id,
          to_id: latestMessageToRead.to_id,
          message_id: latestMessageToRead.message_id,
          group_message: chat.isGroupChat,
          read_by: fromTrainerId,
        });
      } else {
        await api.messages
          .readClientMessages(fromTrainerId, chat.id)
          .catch((e) => {
            Sentry.captureException(e);
          });
      }
    } else {
      console.log(`Chat: Skip reading chat due to ghost mode: '${chat.id}'`);
    }

    return {
      isGhosting: isGhosting,
      trainerId: trainer.trainer_id,
      disableGhostingProtections,
    };
  },
);

export const fetchAllMessages = createAsyncThunk(
  "chat/fetchAllMessages",
  async (_, { getState }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    if (!trainer) {
      throw new Error("No trainer");
    }

    const res = await api.trainers.getAllMessages(trainer.trainer_id);

    return res;
  },
);

export const toggleChatFilter = createAsyncThunk(
  "chat/toggleChatFilter",
  async (newFilter: string, { getState }) => {
    const state = getState() as RootState;

    let newFilteredChatIds: string[] = [];

    const currentIndex = state.chat.selectedFilters.indexOf(newFilter);

    const clientChats: Chat[] = [];

    for (const chatId in state.chat.chats) {
      if (!state.chat.chats[chatId].isGroupChat) {
        clientChats.push(state.chat.chats[chatId]);
      }
    }

    if (currentIndex === -1) {
      const filteredChatIdsToAdd = getChatsMatchingFilter(
        newFilter,
        clientChats,
        state.clients.clients,
        state.clients.clientInfo,
        state.actionItems.actionItems,
      ).map((chat) => chat.id);

      newFilteredChatIds = [
        ...new Set([...state.chat.selectedChatIds, ...filteredChatIdsToAdd]),
      ];
    } else {
      for (const filter of state.chat.selectedFilters) {
        if (filter === newFilter) {
          continue;
        }

        const filteredChatIdsToAdd = getChatsMatchingFilter(
          filter,
          clientChats,
          state.clients.clients,
          state.clients.clientInfo,
          state.actionItems.actionItems,
        ).map((chat) => chat.id);

        newFilteredChatIds = [...newFilteredChatIds, ...filteredChatIdsToAdd];
      }

      newFilteredChatIds = [...new Set(newFilteredChatIds)];
    }

    return newFilteredChatIds;
  },
);

export const addMessage = createAsyncThunk(
  "chat/addMessage",
  async (
    data: {
      clientID: string;
      message: Message;
      isFromCoach: boolean;
    },
    { dispatch },
  ) => {
    console.log("Redux: add message");

    if (data.message.to_id === data.clientID) {
      //Trainer sent a message

      dispatch(
        clearActionItemWithType({ userId: data.clientID, type: "respond" }),
      );
    }

    return;
  },
);

export const stageMassMessages = createAsyncThunk(
  "chat/stageMassMessages",
  async (slateData: Descendant[], { getState }) => {
    console.log("Redux: stage mass messages");

    const state = getState() as RootState;

    const massMessages = selectMassMessages(state, slateData as any);

    return massMessages;
  },
);

// Define a type for the slice state
interface ChatState {
  chats: Record<string, Chat>;
  pendingMessages: Record<string, string>;
  selectedChatId: string | undefined;
  mediaUploadUi: "hide" | "show" | "uploading";
  chatMode:
    | "drag_minimized"
    | "drag_expanded"
    | "big_left"
    | "big_right"
    | "full";
  isSelectingChats: boolean;
  selectedChatIds: string[];
  selectedFilters: string[];
  flyoutOpen: boolean;
  currentTab: "clients" | "tickets" | "official_trainwell";
  ticketChatsStatus: "idle" | "loading" | "succeeded" | "failed";
  officialChatsStatus: "idle" | "loading" | "succeeded" | "failed";
  chatIdsToMassMessage: string[];
  rawMassMessage: Descendant[];
  previewMassMessages: boolean;
  focusedUserId: string | undefined;
  view: "default" | "threads" | "mass_message";
}

// Define the initial state using that type
const initialState: ChatState = {
  chats: {},
  pendingMessages: {},
  selectedChatId: undefined,
  mediaUploadUi: "hide",
  chatMode: "big_left",
  isSelectingChats: false,
  selectedChatIds: [],
  selectedFilters: [],
  flyoutOpen: false,
  currentTab: "clients",
  ticketChatsStatus: "idle",
  officialChatsStatus: "idle",
  chatIdsToMassMessage: [],
  rawMassMessage: [
    {
      type: "paragraph",
      children: [
        {
          text: "",
        },
      ],
    },
  ],
  previewMassMessages: false,
  focusedUserId: undefined,
  view: "default",
};

export const chatSclice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    resetChat: () => initialState,
    addChat: (state, action: PayloadAction<Chat>) => {
      const newChat = action.payload;

      state.chats[newChat.id] = newChat;
    },
    setCurrentTab: (state, action: PayloadAction<ChatState["currentTab"]>) => {
      state.currentTab = action.payload;
    },
    setFocusedUserId: (state, action: PayloadAction<string>) => {
      state.focusedUserId = action.payload;
    },
    setChatView: (state, action: PayloadAction<ChatState["view"]>) => {
      const view = action.payload;

      if (state.view === "mass_message" && view !== "mass_message") {
        chatSclice.caseReducers.resetMassMessaging(state);
      }

      state.view = view;
      state.focusedUserId = undefined;
    },
    closeChat: (state) => {
      if (state.mediaUploadUi === "uploading") {
        return;
      }

      state.selectedChatId = undefined;
      state.mediaUploadUi = "hide";
    },
    toggleSelectingChats: (state) => {
      state.isSelectingChats = !state.isSelectingChats;
      state.selectedChatIds = [];
      state.selectedFilters = [];
      state.rawMassMessage = [
        {
          type: "paragraph",
          children: [
            {
              text: "",
            },
          ],
        },
      ];
    },
    toggleSelectedChat: (state, action: PayloadAction<string>) => {
      const chatId = action.payload;

      if (state.selectedChatIds.includes(chatId)) {
        state.selectedChatIds = state.selectedChatIds.filter(
          (id) => id !== chatId,
        );
      } else {
        state.selectedChatIds.push(chatId);
      }
    },
    toggleFullChatMode: (state) => {
      if (state.chatMode === "full") {
        state.chatMode = "drag_expanded";
        console.log("toggle 2");
      } else {
        state.chatMode = "full";
        console.log("toggle 1");
      }
    },
    toggleExpanded: (state) => {
      if (state.chatMode === "drag_expanded") {
        state.chatMode = "drag_minimized";
      } else if (state.chatMode === "drag_minimized") {
        state.chatMode = "drag_expanded";
      }
    },
    setChatMode: (state, action: PayloadAction<ChatState["chatMode"]>) => {
      state.chatMode = action.payload;
    },
    toggleFullscreen: (state) => {
      if (state.chatMode === "big_left" || state.chatMode === "big_right") {
        state.chatMode = "drag_expanded";
      } else {
        state.chatMode = "big_right";
      }
    },
    toggleChatFlyout: (state) => {
      if (state.chatMode === "drag_expanded") {
        state.flyoutOpen = !state.flyoutOpen;
      }
    },
    setMediaUploadUi: (
      state,
      action: PayloadAction<ChatState["mediaUploadUi"]>,
    ) => {
      const mediaUploadUi = action.payload;

      state.mediaUploadUi = mediaUploadUi;
    },
    setMessage: (
      state,
      action: PayloadAction<{ message: string; chatId: string }>,
    ) => {
      const { message, chatId } = action.payload;

      if (state.chats[chatId]) {
        state.pendingMessages[chatId] = message;
      }
    },
    updateMessage: (state, action: PayloadAction<Message>) => {
      const newMessage = action.payload;

      let chatId = newMessage.from_id;

      if (!state.chats[chatId]) {
        chatId = newMessage.to_id;
      }

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === newMessage.message_id,
        );

        if (messageIndex !== -1) {
          state.chats[chatId].messages[messageIndex] = newMessage;
        }
      }
    },
    setRawMassMessage: (state, action: PayloadAction<Descendant[]>) => {
      const rawMassMessage = action.payload;

      state.rawMassMessage = rawMassMessage;
    },
    setPreviewMassMessages: (state, action: PayloadAction<boolean>) => {
      const previewMassMessages = action.payload;

      state.previewMassMessages = previewMassMessages;
    },
    setSentMessage: (state, action: PayloadAction<string>) => {
      const userId = action.payload;

      if (state.chats[userId]) {
        state.chats[userId].oldestMessageNeedingResponse = undefined;
      }
    },
    markMessageAsRead: (
      state,
      action: PayloadAction<{
        userId: string;
        message: Message;
        didCoachRead: boolean;
        readBy?: string;
      }>,
    ) => {
      const { userId, message, didCoachRead, readBy } = action.payload;

      if (state.chats[userId]) {
        const messageIndex = state.chats[userId].messages.findIndex(
          (m) => m.message_id === message.message_id,
        );

        if (messageIndex !== -1) {
          state.chats[userId].messages[messageIndex].read_date =
            message.read_date;
          if (readBy) {
            state.chats[userId].messages[messageIndex].read_statuses = {
              ...state.chats[userId].messages[messageIndex].read_statuses,
              [readBy]: message.read_date ?? new Date().toISOString(),
            };
          }
        }

        if (didCoachRead) {
          console.log("Chat: Trainer read a message");

          state.chats[userId].oldestUnreadMessageFromClient = undefined;

          for (const message of state.chats[userId].messages) {
            state.chats[userId].messages.map((m) => {
              if (!m.read_date && m.to_id === message.to_id) {
                m.read_date = new Date().toISOString();
              }
            });
          }
        }
      }
    },
    markMessageAsTicketed: (
      state,
      action: PayloadAction<{
        messageId: string;
        userId: string;
        ticketId: string;
      }>,
    ) => {
      const { messageId, ticketId, userId } = action.payload;

      if (state.chats[userId]) {
        const messageIndex = state.chats[userId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        if (messageIndex !== -1) {
          state.chats[userId].messages[messageIndex].issue_report_id = ticketId;
        }
      }
    },
    updateChat: (
      state,
      action: PayloadAction<Partial<Chat> & Pick<Chat, "id">>,
    ) => {
      const update = action.payload;

      if (state.chats[update.id]) {
        state.chats[update.id] = {
          ...state.chats[update.id],
          ...update,
        };
      }
    },
    addMassMessage: (state, action: PayloadAction<string>) => {
      const chatId = action.payload;

      if (!state.chatIdsToMassMessage.includes(chatId)) {
        state.chatIdsToMassMessage.push(chatId);
      }
    },
    removeMassMessage: (state, action: PayloadAction<string>) => {
      const userId = action.payload;

      const index = state.chatIdsToMassMessage.findIndex(
        (chatId) => chatId === userId,
      );

      if (index !== -1) {
        state.chatIdsToMassMessage.splice(index, 1);
      }
    },
    resetMassMessaging: (state) => {
      state.focusedUserId = undefined;
      state.previewMassMessages = false;
      state.chatIdsToMassMessage = [];
      state.rawMassMessage = [
        {
          type: "paragraph",
          children: [
            {
              text: "",
            },
          ],
        },
      ];
    },
    syncActionItemsToChat: (state, action: PayloadAction<ActionItem[]>) => {
      const actionItems = action.payload;

      for (const chatId in state.chats) {
        const chat = state.chats[chatId];

        const relevantActionItems = actionItems.filter(
          (ai) => ai.user_id === chatId,
        );

        const questionAi = relevantActionItems.find(
          (ai) => ai.type === "respond" && ai.respond_type === "question",
        );
        const respondAi = relevantActionItems.find(
          (ai) =>
            ai.type === "respond" &&
            (ai.respond_type === "message" || ai.respond_type === "habit"),
        );
        const unreadAi = relevantActionItems.find(
          (ai) => ai.type === "read_chat",
        );

        if (questionAi) {
          chat.oldestQuestionFromClient =
            (questionAi.date_to_send as string | null) ??
            (questionAi.date_created as string);
        }

        if (respondAi) {
          chat.oldestMessageNeedingResponse =
            (respondAi.date_to_send as string | null) ??
            (respondAi.date_created as string);
        }

        if (unreadAi) {
          chat.oldestUnreadMessageFromClient =
            (unreadAi.date_to_send as string | null) ??
            (unreadAi.date_created as string);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteMessage.fulfilled, (state, action) => {
      const clientId = state.selectedChatId;
      const messageId = action.meta.arg;

      if (clientId && state.chats[clientId]) {
        const messageIndex = state.chats[clientId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        state.chats[clientId].messages.splice(messageIndex, 1);
      }
    });
    builder.addCase(openChat.pending, (state, action) => {
      const { chatId: userId } = action.meta.arg;

      if (state.selectedChatId === userId) {
        return;
      }

      if (state.chats[userId]) {
        state.chats[userId].firstMessageFetchState = "idle";
      }
    });
    builder.addCase(openChat.fulfilled, (state, action) => {
      const { chatId: userId, forceTrainerId } = action.meta.arg;

      if (!action.payload) {
        return;
      }

      const { chat, trainer } = action.payload;
      let { messages } = action.payload;

      if (forceTrainerId) {
        delete state.chats[userId];
      }

      if (state.chats[userId]) {
        state.chats[userId].firstChatIndex = 1000000;
      }

      if (messages) {
        console.log("Redux: open chat for client with user_id: " + userId);

        messages.sort((a, b) =>
          (a.send_date as string).localeCompare(b.send_date as string),
        );

        if (trainer.settings.hide_habit_day_summaries) {
          messages = messages.filter(
            (message) => message.type !== "daily_habit_summary",
          );
        } else {
          messages = messages.filter(
            (message) =>
              message.type !== "daily_habit_summary" ||
              (message as HabitSummaryMessage).summary?.habit_tasks.filter(
                (task) => !task.date_completed,
              ).length > 0,
          );
        }

        if (chat) {
          // A new chat was supplied, add it to state

          chat.messages = messages;
          state.chats[chat.id] = chat;
        } else {
          // Find the existing chat to add messages to
          if (state.chats[userId]) {
            state.chats[userId].messages = messages;
            state.chats[userId].loadingState = "succeeded";
          }
        }

        state.selectedChatId = userId;
      }

      if (state.chatMode === "drag_minimized") {
        state.chatMode = "drag_expanded";
      }

      state.mediaUploadUi = "hide";
    });
    builder.addCase(openTicketChat.fulfilled, (state, action) => {
      const ticketId = action.meta.arg;
      const messages = action.payload;

      if (messages) {
        console.log("Redux: open chat for ticket with id: " + ticketId);

        messages.sort((a, b) => (a.send_date > b.send_date ? 1 : -1));

        const ticketChats: Chat[] = [];

        for (const chatId in state.chats) {
          if (state.chats[chatId].ticketId) {
            ticketChats.push(state.chats[chatId]);
          }
        }

        const chatIndex = ticketChats.findIndex(
          (chat) => chat.ticketId === ticketId,
        );

        if (chatIndex !== -1) {
          state.chats[ticketChats[chatIndex].id].messages = messages;

          state.selectedChatId = ticketChats[chatIndex].id;
        }
      }

      if (state.chatMode === "drag_minimized") {
        state.chatMode = "drag_expanded";
      }

      state.mediaUploadUi = "hide";
    });
    builder.addCase(addMessage.fulfilled, (state, action) => {
      const { clientID, message, isFromCoach } = action.meta.arg;

      if (state.chats[clientID]) {
        if (
          !isFromCoach &&
          !state.chats[clientID].oldestUnreadMessageFromClient
        ) {
          state.chats[clientID].oldestUnreadMessageFromClient =
            new Date().toISOString();
        }

        if (message.to_id === clientID) {
          state.chats[clientID].oldestMessageNeedingResponse = undefined;
          state.chats[clientID].oldestUnreadMessageIdFromClient = undefined;
        }

        const messageIndex = state.chats[clientID].messages.findIndex(
          (m) => m.message_id === message.message_id,
        );

        if (messageIndex === -1) {
          state.chats[clientID].messages.push(message);
        }
      }
    });
    builder.addCase(fetchAllMessages.fulfilled, (state, action) => {
      const unreadMessages = action.payload;

      for (const chatId in unreadMessages) {
        if (!state.chats[chatId]) {
          continue;
        }

        let messages = unreadMessages[chatId];

        messages.sort((a, b) =>
          (a.send_date as string).localeCompare(b.send_date as string),
        );

        messages = messages.filter(
          (message) =>
            message.type !== "daily_habit_summary" ||
            (message as HabitSummaryMessage).summary?.habit_tasks.filter(
              (task) => !task.date_completed,
            ).length > 0,
        );

        for (const message of messages) {
          const isFromClient = message.from_id === chatId;

          if (isFromClient && !message.read_date) {
            state.chats[chatId].oldestUnreadMessageIdFromClient =
              message.message_id;

            break;
          }
        }

        state.chats[chatId].messages = messages;
      }
    });
    builder.addCase(fetchChats.fulfilled, (state, action) => {
      const newChats = action.payload;

      for (const chat of newChats) {
        state.chats[chat.id] = chat;
      }
    });
    builder.addCase(fetchTicketChats.pending, (state) => {
      state.ticketChatsStatus = "loading";
    });
    builder.addCase(fetchTicketChats.fulfilled, (state, action) => {
      state.ticketChatsStatus = "succeeded";

      const newChats = action.payload;

      for (const chat of newChats) {
        state.chats[chat.id] = chat;
      }
    });
    builder.addCase(fetchTicketChats.rejected, (state) => {
      state.ticketChatsStatus = "failed";
    });
    builder.addCase(fetchOfficialChats.pending, (state) => {
      state.officialChatsStatus = "loading";
    });
    builder.addCase(fetchOfficialChats.fulfilled, (state, action) => {
      state.officialChatsStatus = "succeeded";
      const newChats = action.payload;

      for (const chat of newChats) {
        state.chats[chat.id] = chat;
      }
    });
    builder.addCase(fetchFirstMessages.pending, (state, action) => {
      const chatId = action.meta.arg;

      if (
        state.chats[chatId] &&
        state.chats[chatId].firstMessageFetchState === "idle"
      ) {
        state.chats[chatId].firstMessageFetchState = "fetching";
      }
    });
    builder.addCase(fetchFirstMessages.fulfilled, (state, action) => {
      console.log("Chat: Fetched first messages");

      const chatId = action.meta.arg;

      if (!action.payload) {
        return;
      }

      let { messages } = action.payload;
      const { trainer } = action.payload;

      if (state.chats[chatId]) {
        messages.sort((a, b) =>
          (a.send_date as string).localeCompare(b.send_date as string),
        );

        if (trainer.settings.hide_habit_day_summaries) {
          messages = messages.filter(
            (message) => message.type !== "daily_habit_summary",
          );
        } else {
          messages = messages.filter(
            (message) =>
              message.type !== "daily_habit_summary" ||
              (message as HabitSummaryMessage).summary?.habit_tasks.filter(
                (task) => !task.date_completed,
              ).length > 0,
          );
        }

        for (const message of messages) {
          const isFromClient = message.from_id === chatId;

          if (isFromClient && !message.read_date) {
            state.chats[chatId].oldestUnreadMessageIdFromClient =
              message.message_id;

            break;
          }
        }

        state.chats[chatId].messages = messages;
        state.chats[chatId].firstMessageFetchState = "done";
      }
    });
    builder.addCase(fetchOfficialChats.rejected, (state) => {
      state.officialChatsStatus = "failed";
    });
    builder.addCase(sendMessageAsSms.pending, (state, action) => {
      const { messageId, chatId } = action.meta.arg;

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        if (messageIndex !== -1) {
          // @ts-expect-error
          state.chats[chatId].messages[messageIndex].send_state = "sending_sms";
        }
      }
    });
    builder.addCase(sendMessageAsSms.fulfilled, (state, action) => {
      const { messageId, chatId } = action.meta.arg;

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        if (messageIndex !== -1) {
          state.chats[chatId].messages[messageIndex].date_sms_sent =
            new Date().toISOString();
          // @ts-expect-error
          delete state.chats[chatId].messages[messageIndex].send_state;
        }
      }
    });
    builder.addCase(sendMessageAsSms.rejected, (state, action) => {
      const { messageId, chatId } = action.meta.arg;

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        if (messageIndex !== -1) {
          // @ts-expect-error
          state.chats[chatId].messages[messageIndex].send_state = "error";
        }
      }
    });
    builder.addCase(sendMessageAsEmail.pending, (state, action) => {
      const { messageId, chatId } = action.meta.arg;

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        if (messageIndex !== -1) {
          // @ts-expect-error
          state.chats[chatId].messages[messageIndex].send_state =
            "sending_email";
        }
      }
    });
    builder.addCase(sendMessageAsEmail.fulfilled, (state, action) => {
      const { messageId, chatId } = action.meta.arg;

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        if (messageIndex !== -1) {
          state.chats[chatId].messages[messageIndex].date_email_sent =
            new Date().toISOString();
          // @ts-expect-error
          delete state.chats[chatId].messages[messageIndex].send_state;
        }
      }
    });
    builder.addCase(sendMessageAsEmail.rejected, (state, action) => {
      const { messageId, chatId } = action.meta.arg;

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        if (messageIndex !== -1) {
          // @ts-expect-error
          state.chats[chatId].messages[messageIndex].send_state = "error";
        }
      }
    });
    builder.addCase(reactMessage.fulfilled, (state, action) => {
      const newMessage = action.payload.message;

      let chatId = newMessage.from_id;

      if (!state.chats[chatId]) {
        chatId = newMessage.to_id;
      }

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === newMessage.message_id,
        );

        if (messageIndex !== -1) {
          state.chats[chatId].messages[messageIndex] = newMessage;
        }
      }
    });
    builder.addCase(markChatAsUnread.fulfilled, (state, action) => {
      const chatIds = action.payload;

      for (const chatId of chatIds) {
        if (state.chats[chatId]) {
          if (!state.chats[chatId].oldestUnreadMessageFromClient) {
            state.chats[chatId].oldestUnreadMessageFromClient =
              new Date().toISOString();
          }
        }
      }

      if (!action.meta.arg) {
        state.isSelectingChats = false;
        state.selectedChatIds = [];
      }
    });
    builder.addCase(sendMassMessages.fulfilled, (state) => {
      state.selectedChatIds = [];
      state.selectedFilters = [];
      state.isSelectingChats = false;
      state.pendingMessages = {};
      state.rawMassMessage = [
        {
          type: "paragraph",
          children: [
            {
              text: "",
            },
          ],
        },
      ];
    });
    builder.addCase(toggleChatPinned.fulfilled, (state, action) => {
      const chatIds = action.payload;

      for (const chatId in state.chats) {
        if (chatIds.includes(chatId)) {
          state.chats[chatId].pinned = true;
        } else {
          state.chats[chatId].pinned = false;
        }
      }

      if (!action.meta.arg) {
        state.isSelectingChats = false;
        state.selectedChatIds = [];
      }
    });
    builder.addCase(toggleChatFilter.fulfilled, (state, action) => {
      const newFilter = action.meta.arg;
      const newSelectedChatIds = action.payload;

      const currentIndex = state.selectedFilters.indexOf(newFilter);

      if (currentIndex === -1) {
        state.selectedFilters.push(newFilter);
      } else {
        state.selectedFilters.splice(currentIndex, 1);
      }

      state.selectedChatIds = newSelectedChatIds;
    });
    builder.addCase(stageMassMessages.fulfilled, (state, action) => {
      const massMessages = action.payload;

      for (const massMessage of massMessages) {
        if (state.chats[massMessage.chat.id]) {
          state.pendingMessages[massMessage.chat.id] = massMessage.message;
        }
      }
    });
    builder.addCase(readChat.fulfilled, (state, action) => {
      const { isGhosting, trainerId, disableGhostingProtections } =
        action.payload;
      const chatId = action.meta.arg;

      const chat = state.chats[chatId];

      if ((!isGhosting || disableGhostingProtections) && chat) {
        chat.oldestUnreadMessageFromClient = undefined;

        if (chat.isGroupChat) {
          if (trainerId) {
            chat.messages.forEach((message) => {
              if (
                !message.read_statuses ||
                (!message.read_statuses[trainerId] &&
                  message.from_id !== trainerId)
              ) {
                message.read_date = new Date().toISOString();

                if (message.read_statuses) {
                  message.read_statuses[trainerId] = new Date().toISOString();
                } else {
                  message.read_statuses = {
                    [trainerId]: new Date().toISOString(),
                  };
                }
              }
            });
          }
        } else {
          chat.messages.forEach((message) => {
            if (!message.read_date && message.from_id === chatId) {
              message.read_date = new Date().toISOString();
            }
          });
        }
      }
    });
    builder.addCase(fetchMoreMessages.fulfilled, (state, action) => {
      const { chatId, all } = action.meta.arg;
      let { messages } = action.payload;
      const { trainer } = action.payload;

      messages.sort((a, b) => (a.send_date > b.send_date ? 1 : -1));

      if (trainer.settings.hide_habit_day_summaries) {
        messages = messages.filter(
          (message) => message.type !== "daily_habit_summary",
        );
      } else {
        messages = messages.filter(
          (message) =>
            message.type !== "daily_habit_summary" ||
            (message as HabitSummaryMessage).summary?.habit_tasks.filter(
              (task) => !task.date_completed,
            ).length > 0,
        );
      }

      state.chats[chatId].messages.unshift(...messages);
      state.chats[chatId].firstChatIndex =
        state.chats[chatId].firstChatIndex - messages.length;
      state.chats[chatId].loadingState = "succeeded";

      if (all || messages.length === 0) {
        state.chats[chatId].loadingState = "endReached";
      }
    });
    builder.addCase(fetchMoreMessages.rejected, (state, action) => {
      const { chatId } = action.meta.arg;

      if (action.payload !== null && action.payload !== "endReached") {
        state.chats[chatId].loadingState = "failed";
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetChat,
  closeChat,
  toggleFullChatMode,
  setChatMode,
  toggleExpanded,
  toggleFullscreen,
  toggleChatFlyout,
  setFocusedUserId,
  setMediaUploadUi,
  markMessageAsRead,
  markMessageAsTicketed,
  addChat,
  setSentMessage,
  setCurrentTab,
  updateChat,
  setMessage,
  updateMessage,
  toggleSelectingChats,
  toggleSelectedChat,
  addMassMessage,
  removeMassMessage,
  resetMassMessaging,
  setPreviewMassMessages,
  setRawMassMessage,
  setChatView,
  syncActionItemsToChat,
} = chatSclice.actions;

export default chatSclice.reducer;

export const selectAllChats = (state: RootState) => state.chat.chats;

export const selectChatById = createSelector(
  [selectAllChats, (state, chatId: string) => chatId],
  (chats, chatId) => chats[chatId],
);

export const selectMessageByChatId = (state: RootState, chatId: string) =>
  state.chat.pendingMessages[chatId] ?? "";

export const selectSelectedChat = createSelector(
  [selectAllChats, (state: RootState) => state.chat.selectedChatId],
  (chats, selectedChatId) =>
    selectedChatId ? chats[selectedChatId] : undefined,
);

export const selectIsBigChatMode = createSelector(
  [(state: RootState) => state.chat.chatMode],
  (chatMode) => chatMode === "big_left" || chatMode === "big_right",
);

export const selectTicketChats = createSelector(
  [
    (state: RootState) => state.chat.chats,
    (state: RootState) => state.tickets.tickets,
  ],
  (chats, tickets) => {
    const ticketChats: Chat[] = [];

    for (const chatId in chats) {
      if (chats[chatId].ticketId && tickets.entities[chats[chatId].ticketId!]) {
        ticketChats.push(chats[chatId]);
      }
    }

    return ticketChats.sort((a, b) => {
      const aTicket = tickets.entities[a.ticketId!];
      const bTicket = tickets.entities[b.ticketId!];

      const dateA = (
        a.messages.length > 0
          ? a.messages[a.messages.length - 1].send_date
          : aTicket!.date_created
      ) as string;
      const dateB = (
        b.messages.length > 0
          ? b.messages[b.messages.length - 1].send_date
          : bTicket!.date_created
      ) as string;

      return dateB.localeCompare(dateA);
    });
  },
);

export const selectClientChats = createSelector(
  [(state: RootState) => state.chat.chats],
  (chats) => {
    const clientChats: Chat[] = [];

    for (const chatId in chats) {
      if (!chats[chatId].isGroupChat) {
        clientChats.push(chats[chatId]);
      }
    }

    return clientChats;
  },
);

export const selectOfficialTrainwellChats = createSelector(
  [(state: RootState) => state.chat.chats],
  (chats) => {
    const trainwellChats: Chat[] = [];

    for (const chatId in chats) {
      if (chats[chatId].isGroupChat && !chats[chatId].ticketId) {
        trainwellChats.push(chats[chatId]);
      }
    }

    return trainwellChats.sort((a, b) => {
      const dateA = (
        a.messages.length > 0
          ? a.messages[a.messages.length - 1].send_date
          : a.dateCreated
      ) as string;
      const dateB = (
        b.messages.length > 0
          ? b.messages[b.messages.length - 1].send_date
          : b.dateCreated
      ) as string;

      return dateB.localeCompare(dateA);
    });
  },
);

export const selectUnreadTicketCount = (state: RootState) => {
  const ticketChats = selectTicketChats(state);

  const notDoneTicketChats = ticketChats.filter(
    (chat) => selectTicketById(state, chat.ticketId!)?.state !== "done",
  );

  let count = 0;

  notDoneTicketChats.forEach((chat) => {
    if (chat.oldestUnreadMessageFromClient) {
      count++;
    }
  });

  return count;
};

const selectSentActionItems = createSelector(
  [(state: RootState) => state.actionItems.actionItems],
  (actionItems) =>
    actionItems.filter(
      (actionItem) =>
        !actionItem.date_to_send || isPast(new Date(actionItem.date_to_send)),
    ),
);

export const selectSortedChats = createSelector(
  [
    (state: RootState) => state.chat.chats,
    (_state: RootState, chatSort: ChatSort) => chatSort,
    (_state: RootState, _chatSort: ChatSort, search: string) => search,
    selectSentActionItems,
    (state: RootState) => state.chat.selectedChatIds,
  ],
  (chats, chatSort, search, actionItems, selectedChatIds) => {
    let newChats: Chat[] = [];

    for (const chatId in chats) {
      if (!chats[chatId].isGroupChat) {
        newChats.push(chats[chatId]);
      }
    }

    if (search) {
      newChats = newChats.filter((chat) =>
        chat.clientName.toLowerCase().includes(search.toLowerCase()),
      );
    } else {
      if (chatSort === "action_items") {
        newChats.sort((a, b) => {
          return sortChatsByActionItemsGrouped(a, b, actionItems);
        });
      } else if (chatSort === "action_items_oldest") {
        newChats.sort((a, b) => {
          return sortChatsByOldestActionItem(a, b, actionItems);
        });
      } else if (chatSort === "newest") {
        newChats.sort(sortChatsByNewest);
      }
    }

    // Apply additional filtering after the list has already been sorted
    // if (
    //   (chatSort === "action_items" || chatSort === "action_items_oldest") &&
    //   !search
    // ) {
    //   newChats = newChats.filter((chat) => {
    //     const chatActionItems = actionItems.filter(
    //       (item) => item.user_id === chat.id && item.type !== "custom",
    //     );

    //     const hasItems =
    //       chatActionItems.length > 0 ||
    //       chat.oldestMessageNeedingResponse ||
    //       chat.oldestUnreadMessageFromClient

    //     return hasItems || chat.pinned;
    //   });
    // }

    // If the coach has selected chat filters which includes chats exempt from the above filter
    // Append additional selected chats

    const chatIds = newChats.map((chat) => chat.id);

    for (const chatId of selectedChatIds) {
      if (!chatIds.includes(chatId)) {
        newChats.push(JSON.parse(JSON.stringify(chats[chatId])));
      }
    }

    return newChats;
  },
);

export const selectMassMessages = createSelector(
  [
    (state: RootState) => state.chat.selectedChatIds,
    (state: RootState) => state.chat.chats,
    (state: RootState) => state.clients.clients,
    (state: RootState) => state.clients.clientInfo,
    (_state: RootState, paragraphs: ParagraphElement[]) => paragraphs,
  ],
  (selectedChatIds, chats, clients, allClientInfo, paragraphs) => {
    const selectedChats: Chat[] = [];

    for (const chatId of selectedChatIds) {
      selectedChats.push(chats[chatId]);
    }

    const messages: { message: string; chat: Chat; error: boolean }[] = [];

    const today = new Date();

    for (const chat of selectedChats) {
      let message = "";
      let foundError = false;

      const client = clients.find((c) => c.user_id === chat.id);
      const clientInfo = allClientInfo[chat.id];

      if (!client || !clientInfo) {
        messages.push({
          message: "{error: no client info. Refresh the dash}",
          chat: chat,
          error: true,
        });

        continue;
      }

      const clientsToday = getDateWithTimezoneOffset(
        today,
        client.default_timezone_offset ?? 0,
      );

      for (const paragraph of paragraphs) {
        if (message !== "") {
          message += "\n";
        }

        for (const child of paragraph.children) {
          if ("type" in child) {
            if (child.type === "dynamic_key") {
              if (child.key === "first_name") {
                message += client.first_name;
              } else if (child.key === "full_name") {
                message += client.full_name;
              } else if (child.key === "url_change_coach") {
                message += `https://account.trainwell.net/change-coach?user_id=${client.user_id}`;
              } else if (child.key === "url_schedule_call") {
                message += `https://account.trainwell.net/schedule?user_id=${client.user_id}`;
              } else if (child.key === "url_billing_portal") {
                message += `https://account.trainwell.net`;
              } else if (child.key === "missed_workout_streak_length") {
                if (client.missed_workout_day_streak === undefined) {
                  message += `{error: never missed a workout}`;
                  foundError = true;
                  continue;
                }

                message += client.missed_workout_day_streak;
              } else if (child.key === "next_workout_name") {
                if (!clientInfo.upcomingWorkout) {
                  message += `{error: no upcoming workout}`;
                  foundError = true;
                  continue;
                }

                message += clientInfo.upcomingWorkout.name;
              } else if (child.key === "next_workout_duration") {
                if (!clientInfo.upcomingWorkout) {
                  message += `{error: no upcoming workout}`;
                  foundError = true;
                  continue;
                }

                message += `${Math.floor(
                  workoutLib.workouts.getWorkoutDuration(
                    clientInfo.upcomingWorkout,
                  ) / 60,
                )} minutes`;
              } else if (child.key === "next_workout_date") {
                if (!clientInfo.upcomingWorkout) {
                  message += `{error: no upcoming workout}`;
                  foundError = true;
                  continue;
                }

                const date = addDays(
                  new Date(),
                  clientInfo.daysUntilNextWorkout!,
                );

                message += format(date, "do");
              } else if (child.key === "next_workout_day") {
                if (!clientInfo.upcomingWorkout) {
                  message += `{error: no upcoming workout}`;
                  foundError = true;
                  continue;
                }

                const date = addDays(
                  new Date(),
                  clientInfo.daysUntilNextWorkout!,
                );

                const lastWord = message.split(" ").at(-2);

                if (
                  lastWord !== "on" &&
                  clientInfo.daysUntilNextWorkout === 1
                ) {
                  message += "tomorrow";
                } else if (
                  lastWord !== "on" &&
                  clientInfo.daysUntilNextWorkout === 0
                ) {
                  message += "today";
                } else {
                  message += format(date, "EEEE");
                }
              } else if (child.key === "last_missed_workout_name") {
                if (!clientInfo.latestMissedWorkout) {
                  message += `{error: no missed workout}`;
                  foundError = true;
                  continue;
                }

                message += clientInfo.latestMissedWorkout.name;
              } else if (child.key === "last_missed_workout_duration") {
                if (!clientInfo.latestMissedWorkout) {
                  message += `{error: no missed workout}`;
                  foundError = true;
                  continue;
                }

                message += `${Math.floor(
                  workoutLib.workouts.getWorkoutDuration(
                    clientInfo.latestMissedWorkout,
                  ) / 60,
                )} minutes`;
              } else if (child.key === "last_missed_workout_date") {
                if (!clientInfo.latestMissedWorkoutDate) {
                  message += `{error: no missed workout}`;
                  foundError = true;
                  continue;
                }

                const date = getDateWithTimezoneOffset(
                  new Date(clientInfo.latestMissedWorkoutDate),
                  client.default_timezone_offset ?? 0,
                );

                message += format(date, "do");
              } else if (child.key === "last_missed_workout_day") {
                if (!clientInfo.latestMissedWorkoutDate) {
                  message += `{error: no missed workout}`;
                  foundError = true;
                  continue;
                }

                const date = getDateWithTimezoneOffset(
                  new Date(clientInfo.latestMissedWorkoutDate),
                  client.default_timezone_offset ?? 0,
                );

                const daysAgo = differenceInCalendarDays(clientsToday, date);

                const lastWord = message.split(" ").at(-2);

                if (lastWord !== "on" && daysAgo === 1) {
                  message += "yesterday";
                } else {
                  message += format(date, "EEEE");
                }
              } else if (child.key === "upcoming_workout_days_this_week") {
                if (!clientInfo.upcomingWorkoutSchedules.at(0)) {
                  message += `{error: no workout schedule this week}`;
                  foundError = true;
                  continue;
                }

                if (!isSameWeek(today, clientsToday)) {
                  message += `{error: cleint timezone is different week}`;
                  foundError = true;
                  continue;
                }

                let todaysDayIndex = getDateWithTimezoneOffset(
                  today,
                  client.default_timezone_offset ?? 0,
                ).getDay();

                const lastWorkoutDate = client.last_workout_date
                  ? getDateWithTimezoneOffset(
                      new Date(client.last_workout_date),
                      client.default_timezone_offset ?? 0,
                    )
                  : undefined;

                if (lastWorkoutDate) {
                  const daysSinceWorkout = differenceInCalendarDays(
                    today,
                    lastWorkoutDate,
                  );

                  if (daysSinceWorkout) {
                    // Worked out today

                    if (todaysDayIndex === 6) {
                      message += `{error: no upcoming workouts this week (did a workout today)}`;
                      foundError = true;
                      continue;
                    } else {
                      todaysDayIndex = todaysDayIndex + 1;
                    }
                  }
                }

                const days: string[] = [];

                const lastWord = message.split(" ").at(-2);

                for (let dayIndex = todaysDayIndex; dayIndex <= 6; dayIndex++) {
                  if (clientInfo.upcomingWorkoutSchedules.at(0)?.at(dayIndex)) {
                    if (dayIndex === todaysDayIndex && lastWord !== "on") {
                      days.push("today");
                    } else if (
                      dayIndex === todaysDayIndex + 1 &&
                      lastWord !== "on"
                    ) {
                      days.push("tomorrow");
                    } else {
                      days.push(dayNames[dayIndex]);
                    }
                  }
                }

                if (days.length >= 2) {
                  days[days.length - 2] = `${days[days.length - 2]}${
                    days.length >= 3 ? "," : ""
                  } and ${days[days.length - 1]}`;

                  days.pop();
                }

                if (days.length > 0) {
                  message += days.join(", ");
                } else {
                  message += `{error: no upcoming workouts this week}`;
                  foundError = true;
                  continue;
                }
              } else if (child.key === "upcoming_workout_days_next_week") {
                if (!clientInfo.upcomingWorkoutSchedules.at(1)) {
                  message += `{error: no workout schedule next week}`;
                  foundError = true;
                  continue;
                }

                if (
                  !isSameWeek(
                    today,
                    getDateWithTimezoneOffset(
                      today,
                      client.default_timezone_offset ?? 0,
                    ),
                  )
                ) {
                  message += `{error: cleint timezone is different week}`;
                  foundError = true;
                  continue;
                }

                const days: string[] = [];

                for (let dayIndex = 0; dayIndex <= 6; dayIndex++) {
                  if (clientInfo.upcomingWorkoutSchedules.at(1)?.at(dayIndex)) {
                    days.push(dayNames[dayIndex]);
                  }
                }

                if (days.length >= 2) {
                  days[days.length - 2] = `${days[days.length - 2]}${
                    days.length >= 3 ? "," : ""
                  } and ${days[days.length - 1]}`;

                  days.pop();
                }

                if (days.length > 0) {
                  message += days.join(", ");
                } else {
                  message += `{error: no workouts next week}`;
                  foundError = true;
                  continue;
                }
              } else {
                message += `{error: missingKey ${child.key}}`;
                foundError = true;
              }
            }
          } else {
            message += child.text;
          }
        }
      }

      messages.push({
        message: message,
        chat: chat,
        error: foundError,
      });
    }

    return messages;
  },
);
