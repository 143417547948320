import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { isHabitDayInPast, isProgramHabit } from "src/lib/habits";
import {
  makeSelectHabitTasksForDay,
  selectHabitPlanById,
  selectPreferredWorkoutDays,
} from "src/slices/clientSlice";
import { AnimateChangeInHeight } from "./AnimateChangeInHeight";
import HabitTaskCell from "./HabitTaskCell";
import WorkoutTaskCell from "./WorkoutTaskCell";
import WorkoutTaskCellPast from "./WorkoutTaskCellPast";

type Props = {
  habitWeekId: string;
  weekPlanId: string;
  isSelected: boolean;
  dayIndex: number;
  isEditMode: boolean;
  forceTaskNames?: string[];
};

export default function HabitDayCell({
  habitWeekId,
  weekPlanId,
  isSelected,
  dayIndex,
  isEditMode,
  forceTaskNames,
}: Props) {
  const habitWeekPlanDate = useAppSelector(
    (state) => selectHabitPlanById(state, weekPlanId)?.date,
  );
  const selectHabitTasksForDay = useMemo(makeSelectHabitTasksForDay, []);
  const habitTasks = useAppSelector((state) =>
    selectHabitTasksForDay(state, {
      planId: weekPlanId,
      dayIndex: dayIndex,
      habitWeekId: habitWeekId,
    }),
  );
  const habitWeek = useAppSelector(
    (state) =>
      selectHabitPlanById(state, weekPlanId)?.habit_weeks.find(
        (w) => w.id === habitWeekId,
      )!,
  );

  const isProgramDay = isProgramHabit(habitWeek);

  const isInPast = isHabitDayInPast(habitWeekPlanDate!, dayIndex);

  return (
    <>
      {isSelected && !isProgramDay && habitTasks.length <= 0 && (
        <HabitTaskCell
          isEditMode={isEditMode}
          dayIndex={dayIndex}
          habitWeek={habitWeek}
          weekPlanId={weekPlanId}
          forceTaskNames={forceTaskNames}
        />
      )}
      {habitTasks.map((task, index) => {
        if (task.movement_type === "copilot_workout") {
          return (
            <WorkoutTaskCellPast
              key={task.id}
              habitTaskId={task.id}
              isLast={index === habitTasks.length - 1}
            />
          );
        }
        return (
          <HabitTaskCell
            key={task.id}
            habitTask={task}
            isEditMode={isEditMode}
            dayIndex={dayIndex}
            habitWeek={habitWeek}
            weekPlanId={weekPlanId}
            isLast={index === habitTasks.length - 1}
          />
        );
      })}
      {isProgramDay && !isInPast && (
        <HabitDayCellDroppable
          habitWeekId={habitWeekId}
          weekPlanId={weekPlanId}
          dayIndex={dayIndex}
        />
      )}
    </>
  );
}

type DroppableProps = {
  habitWeekId: string;
  weekPlanId: string;
  dayIndex: number;
};

function HabitDayCellDroppable({
  habitWeekId,
  weekPlanId,
  dayIndex,
}: DroppableProps) {
  const isDraggingPhase = useAppSelector(
    (state) => state.client.currentDraggingInfo?.originalType === "phase",
  );
  const isInPast = useAppSelector((state) =>
    isHabitDayInPast(selectHabitPlanById(state, weekPlanId)!.date, dayIndex),
  );
  const isProgramDay = useAppSelector((state) =>
    isProgramHabit(
      selectHabitPlanById(state, weekPlanId)?.habit_weeks.find(
        (w) => w.id === habitWeekId,
      )!,
    ),
  );
  const preferredWorkoutDays = useAppSelector(selectPreferredWorkoutDays);

  const isEligiblePhaseDay =
    isProgramDay && !isInPast && preferredWorkoutDays[dayIndex];

  const { setNodeRef, isOver, over } = useDroppable({
    id: `weekPlan.${weekPlanId}.${dayIndex}`,
    disabled: isInPast || (isDraggingPhase && !isEligiblePhaseDay),
    data: {
      type: "day",
      weekPlanId: weekPlanId,
      dayIndex: dayIndex,
    },
  });

  const isOverContainer =
    isOver ||
    (over?.data.current?.weekPlanId === weekPlanId &&
      over?.data.current?.habitWeekId === habitWeekId &&
      over?.data.current?.dayIndex === dayIndex);

  return (
    <Box
      ref={setNodeRef}
      sx={{
        height: "100%",
        backgroundColor: (theme) =>
          isOverContainer ? theme.palette.backgroundSecondary.main : undefined,
        transition: "background-color 0.2s ease",
      }}
    >
      <HabitDayCellDroppableContent
        habitWeekId={habitWeekId}
        weekPlanId={weekPlanId}
        dayIndex={dayIndex}
        isOver={isOverContainer}
      />
    </Box>
  );
}

type DroppableContentProps = {
  habitWeekId: string;
  weekPlanId: string;
  dayIndex: number;
  isOver?: boolean;
};

const empty = [];

const HabitDayCellDroppableContent = memo(
  function HabitDayCellDroppableContent({
    habitWeekId,
    weekPlanId,
    dayIndex,
    isOver,
  }: DroppableContentProps) {
    const isDraggingPhase = useAppSelector(
      (state) => state.client.currentDraggingInfo?.originalType === "phase",
    );
    const weekPlan = useAppSelector((state) =>
      selectHabitPlanById(state, weekPlanId),
    );
    const habitWeek = weekPlan?.habit_weeks.find((w) => w.id === habitWeekId)!;
    const anchoredWorkouts = habitWeek.anchored_workout_days
      ? habitWeek.anchored_workout_days[dayIndex]
      : null;
    const draggableIds = habitWeek.draggable_ids
      ? habitWeek.draggable_ids[dayIndex]
      : [];
    const preferredWorkoutDays = useAppSelector(selectPreferredWorkoutDays);
    const selectHabitTasksForDay = useMemo(makeSelectHabitTasksForDay, []);
    const habitTasks = useAppSelector((state) =>
      selectHabitTasksForDay(state, {
        planId: weekPlanId,
        dayIndex: dayIndex,
        habitWeekId: habitWeek.id,
      }),
    );

    const isProgramDay = isProgramHabit(habitWeek);

    const isInPast = isHabitDayInPast(weekPlan!.date, dayIndex);

    const newAnchoredWorkouts = anchoredWorkouts?.filter(
      (workoutId) => !habitTasks.some((task) => task.workout_id === workoutId),
    );

    const isEligiblePhaseDay =
      isProgramDay && !isInPast && preferredWorkoutDays[dayIndex];

    const isEmptyWorkoutDay =
      (!newAnchoredWorkouts || newAnchoredWorkouts?.length === 0) &&
      !isDraggingPhase;

    return (
      <SortableContext
        items={draggableIds ?? empty}
        strategy={verticalListSortingStrategy}
      >
        <AnimateChangeInHeight>
          {newAnchoredWorkouts && newAnchoredWorkouts.length >= 1 && (
            <Box>
              {newAnchoredWorkouts?.map((workoutId, workoutIdIndex) => (
                <WorkoutTaskCell
                  workoutId={workoutId}
                  habitWeekId={habitWeek.id}
                  key={workoutIdIndex}
                  index={workoutIdIndex}
                  weekPlanId={weekPlanId}
                  dayIndex={dayIndex}
                />
              ))}
            </Box>
          )}
          {(isEmptyWorkoutDay || (isEligiblePhaseDay && isDraggingPhase)) && (
            <Box
              sx={{
                p: 1,
                height: "100%",
              }}
            >
              <Box
                sx={{
                  borderStyle: "dashed",
                  borderWidth: "2px",
                  borderRadius: 1,
                  borderColor: (theme) =>
                    isOver ? theme.palette.primary.main : theme.palette.divider,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  transition: "border-color 0.2s ease",
                }}
              >
                <AddRoundedIcon
                  sx={{
                    color: (theme) =>
                      isOver
                        ? theme.palette.primary.main
                        : theme.palette.divider,
                    transition: "color 0.2s ease",
                  }}
                />
                {isDraggingPhase && (
                  <Typography
                    sx={{
                      fontSize: 10,
                      color: (theme) =>
                        isOver
                          ? theme.palette.primary.main
                          : theme.palette.text.secondary,
                      textAlign: "center",
                      transition: "color 0.2s ease",
                    }}
                  >
                    Start phase here
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </AnimateChangeInHeight>
      </SortableContext>
    );
  },
);
