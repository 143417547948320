import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { GoalTemplate } from "@trainwell/types";
import { api } from "src/lib/trainwellApi";

export const fetchGoalTemplates = createAsyncThunk(
  "goalTemplates/fetchGoalTemplates",
  async () => {
    const response = await api.goalTemplates.getAll();

    return response;
  },
);

// Define a type for the slice state
interface AppState {
  goalTemplates: GoalTemplate[];
  status: "idle" | "loading" | "succeeded" | "failed";
}

// Define the initial state using that type
const initialState: AppState = {
  goalTemplates: [],
  status: "idle",
};

export const goalTemplatesSlice = createSlice({
  name: "goalTemplates",
  initialState,
  reducers: {
    resetGoalTemplates: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGoalTemplates.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchGoalTemplates.fulfilled, (state, action) => {
      console.log("Redux: Got goal templates");
      state.status = "succeeded";

      const { goal_templates } = action.payload;

      state.goalTemplates = goal_templates;
    });
    builder.addCase(fetchGoalTemplates.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const { resetGoalTemplates } = goalTemplatesSlice.actions;

export default goalTemplatesSlice.reducer;
