import { Box, Card, Grid, Typography, useTheme } from "@mui/material";
import type { CoachMetricTemplate } from "@trainwell/types";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import type Metric from "src/interfaces/Metric";
import MetricSubmodule from "./MetricSubmodule";

const hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

type Props = {
  metricSnapshot: any;
  coachMetrics: Metric[];
  template: CoachMetricTemplate;
  companyMetrics: any;
  allCompanyMetrics: Metric[];
  window: number;
  sortedSubmoduleMetrics: any;
  trainerName: string;
  admin: boolean;
};

export default function MetricModule({
  metricSnapshot,
  coachMetrics,
  template,
  companyMetrics,
  allCompanyMetrics,
  window,
  sortedSubmoduleMetrics,
  trainerName,
  admin,
}: Props) {
  const theme = useTheme();

  const offsetHours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ];

  const timezoneOffset = new Date().getTimezoneOffset() / 60;

  for (let i = 0; i < timezoneOffset; i++) {
    const x = offsetHours.shift();
    offsetHours.push(x!);
  }

  const messagesData = hours.map((h) => {
    return {
      x: h,
      y: metricSnapshot.messages.count_sent_by_hour[offsetHours[h]],
    };
  });

  const workoutsData = hours.map((h) => {
    return {
      x: h,
      y: metricSnapshot.workouts.count_internal_by_hour[offsetHours[h]],
    };
  });

  let graphSeries: Highcharts.SeriesOptionsType | undefined = undefined;

  if (template.module_title === "Messaging") {
    graphSeries = {
      name: "Messages Sent",
      data: messagesData,
      type: "column",
      borderWidth: 0,
    };
  } else if (template.module_title === "Workouts") {
    graphSeries = {
      name: "Workouts",
      data: workoutsData,
      type: "column",
      borderWidth: 0,
    };
  }

  const chartOptions: Highcharts.Options = {
    colors: [theme.palette.primary.main],
    credits: {
      enabled: false,
    },
    tooltip: {
      headerFormat: undefined,
      pointFormat:
        '<span style="color:{point.color}"><Favorite /></span> {point.x}:00 <b>{point.y}</b><br/>',
    },
    plotOptions: {
      area: {
        fillColor: theme.palette.primary.main,
        fillOpacity: 0.3,
      },
    },
    yAxis: {
      title: undefined,
      crosshair: {
        color: "#cccccc",
      },
      labels: {
        style: { color: theme.palette.text.secondary },
      },
    },
    xAxis: {
      labels: {
        formatter: function () {
          return this.value + ":00";
        },
        style: { color: theme.palette.text.secondary },
      },
      crosshair: {
        color: "#cccccc",
      },
    },
    chart: {
      backgroundColor: "transparent",
      type: "column",
      width: null,
      height: null,
    },
    title: {
      text: template.module_title,
      style: { fontSize: "11px", color: theme.palette.text.primary },
    },
    series: graphSeries ? [graphSeries] : undefined,
    legend: {
      itemStyle: { fontSize: "11px", color: theme.palette.text.secondary },
    },
    accessibility: {
      enabled: false,
    },
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h2" sx={{ mb: 1 }}>
        {template.module_title}
      </Typography>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          p: 2,
        }}
      >
        <Grid container spacing={2}>
          {template.submodules.map((submoduleTemplate, i) => (
            <Grid item xs={4} key={i}>
              <MetricSubmodule
                metricSnapshot={metricSnapshot}
                coachMetrics={coachMetrics}
                submodule={submoduleTemplate}
                companyMetrics={companyMetrics}
                allCompanyMetrics={allCompanyMetrics}
                window={window}
                sortedSubmoduleMetrics={sortedSubmoduleMetrics}
                trainerName={trainerName}
                admin={admin}
              />
            </Grid>
          ))}
        </Grid>
        {template.module_title === "Messaging" ||
        template.module_title === "Workouts" ? (
          <Box sx={{ width: "250px", height: "200px" }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </Box>
        ) : undefined}
      </Card>
    </Box>
  );
}
