import {
  Box,
  Card,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import EventCell from "src/components/AdminPage/company/clients/EventCell";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { changeEventsOrder, fetchEvents } from "src/slices/clientEditSlice";

type Props = {
  userId: string;
};

export default function TabEvents({ userId }: Props) {
  const dispatch = useAppDispatch();
  let events = useAppSelector((state) => state.clientEdit.events);
  const eventsStatus = useAppSelector((state) => state.clientEdit.eventsStatus);
  const eventsOrder = useAppSelector((state) => state.clientEdit.eventsOrder);
  const [filter, setFilter] = useState<"all" | "account" | "communication">(
    "all",
  );

  useEffect(() => {
    if (eventsStatus === "idle" && userId) {
      console.log("Fetching all events");
      dispatch(fetchEvents(userId));
    }
  }, [eventsStatus, dispatch, userId]);

  if (!events) {
    return <LoadingPage message="Loading events" />;
  }

  if (filter === "account") {
    events = events
      .map((eventGroup) => {
        return eventGroup.filter(
          (event) =>
            event.type === "membership_started" ||
            event.type === "pause_ended" ||
            event.type === "pause_extended" ||
            event.type === "pause_occurred" ||
            event.type === "pause_request_revoked" ||
            event.type === "pause_requested" ||
            event.type === "cancel_ended" ||
            event.type === "cancel_occurred" ||
            event.type === "cancel_request_revoked" ||
            event.type === "cancel_requested" ||
            event.type === "payment_succeeded" ||
            event.type === "payment_failed" ||
            event.type === "card_added" ||
            event.type === "card_removed" ||
            event.type === "coach_switched",
        );
      })
      .filter((eventGroup) => eventGroup.length > 0);
  } else if (filter === "communication") {
    events = events
      .map((eventGroup) => {
        return eventGroup.filter(
          (event) => event.type === "sms_send" || event.type === "email_send",
        );
      })
      .filter((eventGroup) => eventGroup.length > 0);
  }

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <FormControl>
          <InputLabel>Filter</InputLabel>
          <Select
            value={filter}
            label="Filter"
            onChange={(event) => {
              setFilter(event.target.value as any);
            }}
            size={"small"}
          >
            <MenuItem value={"all" as typeof filter}>All</MenuItem>
            <MenuItem value={"account" as typeof filter}>Account</MenuItem>
            <MenuItem value={"communication" as typeof filter}>
              Communications
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel>Order</InputLabel>
          <Select
            value={eventsOrder}
            label="Order"
            onChange={() => {
              dispatch(changeEventsOrder());
            }}
          >
            <MenuItem value={"oldestFirst"}>Oldest first</MenuItem>
            <MenuItem value={"newestFirst"}>Newest first</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Stack direction="column" spacing={2}>
        {events.map((eventGroup, groupIndex) => {
          return (
            <Card key={groupIndex} variant="outlined">
              {eventGroup.map((event, index) => {
                return (
                  <Fragment key={index}>
                    <EventCell event={event} />
                    <Divider />
                  </Fragment>
                );
              })}
            </Card>
          );
        })}
      </Stack>
    </Box>
  );
}
