import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import VerticalAlignTopRoundedIcon from "@mui/icons-material/VerticalAlignTopRounded";
import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Typography } from "@mui/material";
import type { Message } from "@trainwell/types";
import { format } from "date-fns";
import { useEffect, useRef } from "react";
import type { VirtuosoHandle } from "react-virtuoso";
import { Virtuoso } from "react-virtuoso";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getDateWithTimezoneOffset } from "src/lib/date";
import { selectHasPreCallSurveyActionItem } from "src/slices/actionItemSlice";
import {
  fetchFirstMessages,
  fetchMoreMessages,
  readChat,
  selectSelectedChat,
  updateChat,
} from "src/slices/chatSlice";
import {
  selectClientById,
  selectUpcomingCallForUserId,
} from "src/slices/clientsSlice";
import { selectTicketById } from "src/slices/ticketsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import ChatMediaUpload from "./ChatMediaUpload";
import ChatMessageBar from "./ChatMessageBar";
import ChatMessagesLoading from "./ChatMessagesLoading";
import SendPrecallSurveyButton from "./SendPrecallSurveyButton";
import MessageBubble from "./messages/MessageBubble";
import TicketMessageCard from "./messages/TicketMessageCard";

type Props = {
  condensed?: boolean;
};

export default function ClientChat({ condensed }: Props) {
  const dispatch = useAppDispatch();
  const selectedChat = useAppSelector(selectSelectedChat);
  const forceSmsDisabled = useAppSelector(
    (state) =>
      selectClientById(state, selectedChat?.id ?? "")?.settings.disable_sms ??
      false,
  );
  const chatRef = useRef(selectedChat);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const virtuoso = useRef<VirtuosoHandle>(null);
  const shouldScroll = useRef(true);
  const ticketForChat = useAppSelector((state) =>
    selectedChat?.ticketId
      ? selectTicketById(state, selectedChat.ticketId)
      : undefined,
  );
  const chatVisualState = useAppSelector((state) => state.chat.chatMode);
  const mediaUploadUi = useAppSelector((state) => state.chat.mediaUploadUi);

  function scrollToTop() {
    console.log("Chat: Scroll to top");

    if (virtuoso.current) {
      virtuoso.current.scrollToIndex({
        index: 0,
        align: "start",
        behavior: "auto",
      });
    }
  }

  function scrollToPosition(position: number | "bottom") {
    // Run code with a delay to ensure that the chat has been rendered
    setTimeout(() => {
      if (virtuoso.current && selectedChat) {
        if (position === "bottom") {
          console.log("Chat: scroll to bottom");

          virtuoso.current.scrollToIndex({
            index: selectedChat.firstChatIndex + selectedChat.messages.length,
            align: "start",
            behavior: "auto",
          });
        } else {
          // const adjustedIndex = position + selectedChat.firstChatIndex;
          const adjustedIndex = position;

          console.log("Chat: scroll to index", adjustedIndex);

          virtuoso.current.scrollToIndex({
            index: adjustedIndex,
            align: "start",
            behavior: "auto",
          });
        }
      }
    }, 50);
  }

  useEffect(() => {
    if (selectedChat?.firstMessageFetchState === "idle") {
      dispatch(fetchFirstMessages(selectedChat.id));
    }
  }, [selectedChat, dispatch]);

  useEffect(() => {
    if (!virtuoso.current || !shouldScroll.current) {
      shouldScroll.current = true;
      return;
    }

    let position: number | "bottom" = "bottom";

    if (
      selectedChat?.oldestUnreadMessageIdFromClient &&
      selectedChat.messages?.length
    ) {
      position = selectedChat.messages.findIndex(
        (m) => m.message_id === selectedChat.oldestUnreadMessageIdFromClient,
      );
    }

    scrollToPosition(position);
  }, [
    selectedChat?.messages.length,
    selectedChat?.id,
    selectedChat?.firstMessageFetchState,
  ]);

  useEffect(() => {
    if (selectedChat && selectedChat.messages.length > 0) {
      dispatch(readChat(selectedChat.id));
    }
  }, [dispatch, selectedChat, trainer?.trainer_id]);

  useEffect(() => {
    return () => {
      if (selectedChat?.id) {
        dispatch(
          updateChat({
            id: selectedChat.id,
            oldestUnreadMessageIdFromClient: undefined,
          }),
        );
      }
    };
  }, [selectedChat?.id, dispatch]);

  useEffect(() => {
    chatRef.current = selectedChat;
  }, [selectedChat]);

  function handleGoToTop() {
    if (selectedChat?.loadingState === "endReached") {
      scrollToTop();
    } else {
      shouldScroll.current = false;

      dispatch(
        fetchMoreMessages({
          chatId: selectedChat!.id,
          all: true,
        }),
      ).then(() => {
        scrollToTop();
      });
    }
  }

  if (chatVisualState === "drag_minimized") {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
      }}
    >
      {mediaUploadUi === "show" || mediaUploadUi === "uploading" ? (
        <ChatMediaUpload />
      ) : (
        <>
          <div
            style={{
              flex: "1 1 auto",
              overflowY: "auto",
              minHeight: "0px",
            }}
          >
            <Box
              sx={{
                height: "100%",
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
                overflowY: "hidden",
              }}
            >
              {selectedChat?.banner && (
                <Box
                  sx={{
                    px: 1,
                    py: 0.5,
                    backgroundColor: (theme) =>
                      theme.palette.warningSurface.main,
                    display: "flex",
                    alignItems: "center",
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.main,
                      width: 19,
                      height: 19,
                      mr: 1,
                    }}
                  >
                    <CalendarMonthRoundedIcon sx={{ fontSize: 13 }} />
                  </Avatar>
                  <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
                    {selectedChat.banner}
                  </Typography>
                </Box>
              )}
              {selectedChat?.firstMessageFetchState === "done" ? (
                <Virtuoso
                  style={{
                    flex: 1,
                  }}
                  ref={virtuoso}
                  data={selectedChat.messages}
                  firstItemIndex={selectedChat.firstChatIndex}
                  overscan={100}
                  totalCount={selectedChat.messages.length}
                  initialTopMostItemIndex={selectedChat.messages.length - 1}
                  skipAnimationFrameInResizeObserver
                  rangeChanged={(listRange) => {
                    const buffer = 6;

                    if (
                      listRange.startIndex <
                      selectedChat.firstChatIndex + buffer
                    ) {
                      shouldScroll.current = false;

                      dispatch(fetchMoreMessages({ chatId: selectedChat.id }));
                    }
                  }}
                  components={{
                    Header: () => {
                      if (ticketForChat) {
                        return (
                          <Box sx={{ pt: 1 }}>
                            <TicketMessageCard chat={selectedChat} />
                          </Box>
                        );
                      } else {
                        return null;
                      }
                    },
                    Footer: Footer,
                  }}
                  context={{
                    onGoToTop: handleGoToTop,
                  }}
                  itemContent={(index, message) => {
                    let showName =
                      selectedChat.isGroupChat &&
                      message.from_id !== trainer?.trainer_id;
                    const convertedIndex = index - selectedChat.firstChatIndex;

                    const nextMessage = selectedChat?.messages.at(
                      convertedIndex + 1,
                    );
                    const previousMessage =
                      convertedIndex === 0
                        ? undefined
                        : selectedChat?.messages.at(convertedIndex - 1);

                    let previousTrainerMessage: Message | undefined = undefined;

                    for (let i = convertedIndex - 1; i >= 0; i--) {
                      const message = selectedChat?.messages.at(i);
                      if (message && message.from_id === trainer?.trainer_id) {
                        previousTrainerMessage = message;
                        break;
                      }
                    }

                    const combineBottom = Boolean(
                      nextMessage &&
                        nextMessage.from_id === message.from_id &&
                        nextMessage.type !== "notification",
                    );

                    if (
                      showName &&
                      previousMessage &&
                      previousMessage.from_id === message.from_id
                    ) {
                      showName = false;
                    }

                    const fromMe = selectedChat.isTrainwell
                      ? message.from_id === "copilot"
                      : message.from_id === trainer?.trainer_id ||
                        message.from_id === selectedChat.oldTrainerId ||
                        (message.trainer_id_interim === trainer?.trainer_id &&
                          message.from_id !== selectedChat.id);

                    const actuallyFromThisCoach =
                      !message.trainer_id_interim &&
                      message.from_id === trainer?.trainer_id;

                    return (
                      <>
                        {fromMe &&
                          !message.read_date &&
                          previousTrainerMessage?.read_date &&
                          !selectedChat.isGroupChat && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                width: "100%",
                                px: 2,
                                py: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  flex: 1,
                                  height: "1px",
                                  backgroundColor: (theme) =>
                                    theme.palette.divider,
                                  mr: 1,
                                }}
                              />
                              <Typography
                                variant="overline"
                                sx={{
                                  color: (theme) =>
                                    theme.palette.text.secondary,
                                }}
                              >
                                {selectedChat.clientName} read to here
                              </Typography>
                            </Box>
                          )}
                        {selectedChat.oldestUnreadMessageIdFromClient ===
                          message.message_id &&
                          !selectedChat.isGroupChat && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                width: "100%",
                                px: 2,
                                py: 2,
                              }}
                            >
                              <Typography
                                variant="overline"
                                sx={{
                                  color: (theme) => theme.palette.primary.main,
                                  fontWeight: "bold",
                                }}
                              >
                                New
                              </Typography>
                              <Box
                                sx={{
                                  flex: 1,
                                  height: "1px",
                                  backgroundColor: (theme) =>
                                    theme.palette.primary.main,
                                  ml: 1,
                                }}
                              />
                            </Box>
                          )}
                        <MessageBubble
                          message={message}
                          isFromMe={fromMe}
                          isFromOtherCoach={Boolean(
                            fromMe &&
                              !actuallyFromThisCoach &&
                              (message.trainer_id_interim ===
                                selectedChat.oldTrainerId ||
                                message.trainer_id_interim !==
                                  trainer?.trainer_id),
                          )}
                          combineBottom={combineBottom}
                          forceSmsDisabled={forceSmsDisabled}
                          showName={showName}
                          condensed={condensed}
                        />
                      </>
                    );
                  }}
                />
              ) : selectedChat ? (
                <ChatMessagesLoading />
              ) : null}
            </Box>
          </div>
          {selectedChat && <ChatMessageBar />}
        </>
      )}
    </Box>
  );
}

type FooterProps = {
  context?: {
    onGoToTop: () => void;
  };
};

function Footer({ context }: FooterProps) {
  const { onGoToTop } = context ?? {};

  const selectedChat = useAppSelector(selectSelectedChat);
  const upcomingCall = useAppSelector((state) =>
    selectUpcomingCallForUserId(state, selectedChat?.id ?? ""),
  );
  const hasClientLoggedIn = useAppSelector((state) =>
    Boolean(selectClientById(state, selectedChat?.id ?? "")?.last_login_date),
  );
  const hasPreCallSurveyActionItem = useAppSelector((state) =>
    selectHasPreCallSurveyActionItem(state, selectedChat?.id ?? ""),
  );

  return (
    <Box>
      {hasPreCallSurveyActionItem && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SendPrecallSurveyButton />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          pb: 1,
          mx: 1,
        }}
      >
        <LoadingButton
          variant="text"
          size="small"
          startIcon={<VerticalAlignTopRoundedIcon />}
          onClick={onGoToTop}
          loading={selectedChat?.loadingState === "loading"}
        >
          Top
        </LoadingButton>
        <Box>
          {selectedChat?.clientTimezoneOffset !== undefined && (
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                textAlign: "right",
              }}
            >
              Client local time:{" "}
              {format(
                getDateWithTimezoneOffset(
                  new Date(),
                  selectedChat?.clientTimezoneOffset ?? 0,
                ),
                "hh:mm aaa",
              )}
            </Typography>
          )}
          {upcomingCall && (
            <Typography
              sx={{
                color: "text.secondary",
                textAlign: "right",
              }}
            >
              {upcomingCall.content.call_type === "check_in"
                ? "Check in"
                : upcomingCall.content.call_type === "onboarding"
                  ? "Onboarding"
                  : "Call"}{" "}
              (their time):{" "}
              {format(
                getDateWithTimezoneOffset(
                  new Date(upcomingCall.content.date_of_call),
                  selectedChat?.clientTimezoneOffset ?? 0,
                ),
                "MMM d, hh:mm aaa",
              )}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
