import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {
  Card,
  CardActionArea,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectClientsWorkoutValidity } from "src/slices/clientSlice";
import { selectWorkoutById, updateWorkout } from "src/slices/phasesSlice";
import WorkoutPreviewPopover from "../WorkoutPreviewPopover";

type Props = {
  workoutId: string;
};

export default function WorkoutExtraCell({ workoutId }: Props) {
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const workout = useAppSelector((state) =>
    selectWorkoutById(state, workoutId),
  );
  const workoutValidity = useAppSelector((state) =>
    selectClientsWorkoutValidity(state, workout?.workout_id ?? ""),
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  if (!workout) {
    return <>Loading</>;
  }

  return (
    <>
      <Tooltip disableInteractive title={workoutValidity?.message}>
        <Card
          variant="outlined"
          sx={{
            backgroundColor: (theme) =>
              workoutValidity?.status === "error"
                ? theme.palette.errorSurface.main
                : undefined,
          }}
        >
          <CardActionArea
            href={`/clients/${userId}/workouts/${workout.workout_id}`}
            onContextMenu={(event) => {
              event.preventDefault();

              setAnchorEl(event.currentTarget);
            }}
            sx={{
              px: 1,
              py: 0.25,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">{workout?.name}</Typography>
            <IconButton
              sx={{ ml: 1 }}
              color="error"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();

                dispatch(
                  updateWorkout({
                    workout_id: workout.workout_id,
                    is_extra: false,
                  }),
                );
              }}
              onMouseDown={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
              size="small"
            >
              <RemoveCircleRoundedIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </CardActionArea>
        </Card>
      </Tooltip>
      {anchorEl && (
        <WorkoutPreviewPopover
          anchorEl={anchorEl}
          workoutId={workout.workout_id}
          onClose={() => {
            setAnchorEl(null);
          }}
        />
      )}
    </>
  );
}
