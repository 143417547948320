import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { isBlank } from "@trainwell/features";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useParams } from "react-router-dom";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import {
  addSection,
  renameWorkout,
  shareWorkoutToCoach,
  toggleExercisesOpen,
  toggleIsInsertingTemplate,
} from "src/slices/workoutSlice";
import { DialogTitleWithClose } from "../misc/DialogTitleWithClose";
import NotesPopup from "./NotesPopup";
import SaveAsTemplateButton from "./SaveAsTemplateButton";
import { WorkoutAutoSave } from "./WorkoutAutoSave";
import WorkoutDuration from "./WorkoutDuration";

export function WorkoutHeader() {
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isSelectingTemplate = useAppSelector(
    (state) => state.workout.isSelectingTemplate,
  );
  const isTemplate = useAppSelector((state) => state.workout.isTemplate);
  const workoutStatus = useAppSelector((state) => state.workout.status);
  const workout = useAppSelector(
    (state) => state.workout.workoutNormalized?.result,
  );
  const exercisesOpen = useAppSelector((state) => state.workout.exercisesOpen);
  const [workoutNotesAnchor, setWorkoutNotesAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [newWorkoutName, setNewWorkoutName] = useState<string>();
  const [shareMenuAnchorEl, setShareMenuAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const shareMenuOpen = Boolean(shareMenuAnchorEl);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);
  const [shareCoachId, setShareCoachId] = useState<string>();

  if (!workout || workoutStatus !== "succeeded") {
    return <LoadingPage message="Getting your workout ready 💪" />;
  }

  return (
    <Box
      sx={{
        px: 2,
        pb: 1,
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      {userId && (
        <Button
          variant="text"
          size="small"
          startIcon={<ChevronLeftRoundedIcon />}
          href={`/clients/${userId}`}
          sx={{ mt: 0.5 }}
        >
          Calendar
        </Button>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              size="small"
              onClick={() => {
                dispatch(toggleExercisesOpen());
              }}
            >
              {exercisesOpen ? <ChevronLeftRoundedIcon /> : <MenuRoundedIcon />}
            </IconButton>
            {newWorkoutName === undefined ? (
              <Tooltip title="Click to edit">
                <Button
                  variant="text"
                  onClick={() => {
                    setNewWorkoutName(workout.name);
                  }}
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                    fontSize: 18,
                    mr: 2,
                  }}
                >
                  {workout.name}
                </Button>
              </Tooltip>
            ) : (
              <TextField
                value={newWorkoutName}
                onChange={(event) => {
                  setNewWorkoutName(event.target.value.substring(0, 44));
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    // @ts-expect-error
                    event.target.blur();
                  }
                }}
                onBlur={() => {
                  dispatch(renameWorkout(newWorkoutName ?? ""));
                  setNewWorkoutName(undefined);
                }}
                helperText={`${newWorkoutName.length} / 44`}
                autoFocus={true}
                variant="outlined"
                size="small"
                label="Workout name"
                sx={{
                  mr: 2,
                  mt: 1,
                  minWidth: "400px",
                }}
              />
            )}
          </Box>
          <Box sx={{ mb: 1.25 }}>
            <WorkoutAutoSave />
          </Box>
        </Box>
        <WorkoutDuration userId={!isTemplate ? workout.user_id : undefined} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              dispatch(addSection());
            }}
            sx={{ mr: 1 }}
            startIcon={<AddRoundedIcon />}
          >
            Add section
          </Button>
          <Tooltip
            title={
              workout.notes_trainer && workout.notes_trainer !== ""
                ? workout.notes_trainer
                : ""
            }
            enterDelay={0}
          >
            <Badge
              color="primary"
              variant="dot"
              invisible={isBlank(workout.notes_trainer)}
            >
              <Button
                size="small"
                variant="text"
                onClick={(event) => {
                  setWorkoutNotesAnchor(event.currentTarget);
                }}
                startIcon={
                  isBlank(workout.notes_trainer) ? (
                    <ChatBubbleOutlineRoundedIcon />
                  ) : (
                    <ChatBubbleRoundedIcon />
                  )
                }
              >
                Notes
              </Button>
            </Badge>
          </Tooltip>
          <NotesPopup
            defaultNotes={workout.notes_trainer ?? ""}
            anchorEl={workoutNotesAnchor}
            onClose={() => {
              setWorkoutNotesAnchor(null);
            }}
          />
        </Box>
        <div>
          {isTemplate && (
            <>
              <Button
                variant="text"
                onClick={(event) => {
                  setShareMenuAnchorEl(event.currentTarget);
                }}
                size="small"
              >
                Share
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={shareMenuAnchorEl}
                open={shareMenuOpen}
                onClose={() => {
                  setShareMenuAnchorEl(null);
                }}
              >
                <MenuItem
                  onClick={() => {
                    setShareMenuAnchorEl(null);
                    setShareDialogOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <PersonRoundedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Share to trainer" />
                </MenuItem>
                <MenuItem
                  disabled
                  onClick={() => {
                    setShareMenuAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <MenuBookRoundedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Share to library" />
                </MenuItem>
              </Menu>
              <Dialog
                onClose={() => {
                  setShareDialogOpen(false);
                }}
                aria-labelledby="customized-dialog-title"
                open={shareDialogOpen}
              >
                <DialogTitleWithClose
                  onClose={() => {
                    setShareDialogOpen(false);
                  }}
                >
                  Share to trainer
                </DialogTitleWithClose>
                <DialogContent>
                  <Typography sx={{ mb: 2 }}>
                    Share <b>{workout.name}</b> to a trainer of your choice.
                  </Typography>
                  <Autocomplete
                    fullWidth
                    options={trainerNames.filter(
                      (trainer) => trainer.is_employee || trainer.is_staff,
                    )}
                    onChange={(event, value) => {
                      setShareCoachId(value?.trainer_id);
                    }}
                    getOptionLabel={(option) => option.full_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Trainer"
                        placeholder="Select a trainer"
                      />
                    )}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="text"
                    onClick={() => {
                      setShareDialogOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!shareCoachId}
                    onClick={() => {
                      setShareDialogOpen(false);

                      dispatch(shareWorkoutToCoach(shareCoachId!));

                      enqueueSnackbar("Shared template", {
                        variant: "success",
                      });
                    }}
                  >
                    {shareCoachId
                      ? `Share with ${getTrainerName(shareCoachId, trainerNames)}`
                      : "Share"}
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
          {!isTemplate && !isSelectingTemplate && (
            <Button
              variant="text"
              onClick={() => {
                dispatch(toggleIsInsertingTemplate());
              }}
              size="small"
            >
              Add template
            </Button>
          )}
          {!isTemplate && <SaveAsTemplateButton />}
        </div>
      </Box>
    </Box>
  );
}
