import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ClientSchedule from "src/components/misc/ClientSchedule";

type Props = {
  defaultExpanded?: boolean;
  freeFloating?: boolean;
};

export default function SchedulePanel({
  defaultExpanded = false,
  freeFloating = false,
}: Props) {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      disableGutters
      elevation={freeFloating ? undefined : 0}
      square={!freeFloating}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">📅 Default schedule</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ClientSchedule />
      </AccordionDetails>
    </Accordion>
  );
}
