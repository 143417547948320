import type {
  ActionItem,
  Client,
  HabitTaskMessage,
  Message,
  NotificationMessage,
  SupportTicketChat,
  Trainer,
} from "@trainwell/types";
import { differenceInCalendarDays, isFuture, isPast } from "date-fns";
import type { Chat } from "src/slices/chatSlice";
import type { ClientInfo } from "src/slices/clientsSlice";
import { getDateWithTimezoneOffset } from "./date";
import { getNotificationTitle } from "./message";
import {
  getActionItemStatus,
  getNeedsResponseStatus,
  getUnreadStatus,
} from "./officeHours";

export function messageAsText(message: Message) {
  switch (message.type) {
    case "text":
      return message.text;
    case "notification":
      return getNotificationTitle(
        (message as NotificationMessage).notification_type ?? "",
      );
    case "video":
      return "[Video]";
    case "workout":
      return "[Workout completed]";
    case "image":
      return "[Image]";
    case "habit_task_log":
      if ((message as HabitTaskMessage).habit_task.workout_id) {
        return `Workout: ${(message as HabitTaskMessage).habit_task.name}`;
      } else {
        return `Habit: ${(message as HabitTaskMessage).habit_task.name}`;
      }
    case "daily_habit_summary":
      return "[Habit summary]";
  }
}

export function getChatFromTicket(
  ticketChat: SupportTicketChat,
  coachName: string,
  trainerId: string,
) {
  let ticketName = `#${ticketChat.support_ticket.number}, ${coachName}`;

  if (coachName !== ticketChat.support_ticket.client_name) {
    ticketName += ` & ${ticketChat.support_ticket.client_name}`;
  }

  const chat: Chat = {
    isGroupChat: true,
    id: ticketChat.chat_id,
    messages: ticketChat.latest_message
      ? [ticketChat.latest_message as any]
      : [],
    firstMessageFetchState: "idle",
    clientName: ticketName,
    clientHeadshotURL: ticketChat.thumbnail_url ?? "",
    loadingState: "idle",
    oldestUnreadMessageFromClient:
      ticketChat.latest_message &&
      (ticketChat.latest_message as any).trainer_id !== trainerId &&
      !((ticketChat.latest_message as any).read_statuses ?? {})[trainerId]
        ? new Date().toISOString()
        : undefined,
    ticketId: ticketChat.support_ticket_id,
    dateCreated: ticketChat.date_created as string,
    memberIds: ticketChat.member_ids,
    pinned: false,
    firstChatIndex: 1000000,
  };

  return chat;
}

export function sortChatsByUnread(a: Chat, b: Chat) {
  if (b.messages.length > 0 && a.messages.length > 0) {
    const aUnread = Boolean(a.oldestUnreadMessageFromClient);
    const bUnread = Boolean(b.oldestUnreadMessageFromClient);

    if (aUnread && !bUnread) {
      return -1;
    } else if (!aUnread && bUnread) {
      return 1;
    }

    return (
      b.messages[b.messages.length - 1].send_date as string
    ).localeCompare(a.messages[a.messages.length - 1].send_date as string);
  } else if (a.messages.length === 0 && b.messages.length > 0) {
    return 1;
  } else if (b.messages.length === 0 && a.messages.length > 0) {
    return -1;
  } else {
    return 0;
  }
}

export function sortChatsByNewest(a: Chat, b: Chat) {
  if (a.pinned && !b.pinned) {
    return -1;
  } else if (!a.pinned && b.pinned) {
    return 1;
  }

  if (
    b.messages.length > 0 &&
    a.messages.length > 0 &&
    b.messages[b.messages.length - 1].send_date &&
    a.messages[a.messages.length - 1].send_date
  ) {
    if (
      a.messages[a.messages.length - 1] &&
      b.messages[b.messages.length - 1]
    ) {
      return (
        b.messages[b.messages.length - 1].send_date as string
      ).localeCompare(a.messages[a.messages.length - 1].send_date as string);
    } else if (
      a.messages[a.messages.length - 1] &&
      !b.messages[b.messages.length - 1]
    ) {
      return 1;
    } else if (
      !a.messages[a.messages.length - 1] &&
      b.messages[b.messages.length - 1]
    ) {
      return -1;
    } else {
      return 0;
    }
  } else if (a.messages.length === 0 && b.messages.length > 0) {
    return 1;
  } else if (b.messages.length === 0 && a.messages.length > 0) {
    return -1;
  } else {
    return 0;
  }
}

export function sortChatsByUnanswered(a: Chat, b: Chat, trainerId: string) {
  if (b.messages.length > 0 && a.messages.length > 0) {
    if (
      a.messages[a.messages.length - 1] &&
      b.messages[b.messages.length - 1] &&
      b.messages[b.messages.length - 1].send_date &&
      a.messages[a.messages.length - 1].send_date
    ) {
      if (
        a.messages[a.messages.length - 1].from_id === trainerId &&
        b.messages[b.messages.length - 1].from_id !== trainerId
      ) {
        return 1;
      } else if (
        a.messages[a.messages.length - 1].from_id !== trainerId &&
        b.messages[b.messages.length - 1].from_id === trainerId
      ) {
        return -1;
      }

      return (
        b.messages[b.messages.length - 1].send_date as string
      ).localeCompare(a.messages[a.messages.length - 1].send_date as string);
    } else if (
      a.messages[a.messages.length - 1] &&
      !b.messages[b.messages.length - 1]
    ) {
      return 1;
    } else if (
      !a.messages[a.messages.length - 1] &&
      b.messages[b.messages.length - 1]
    ) {
      return -1;
    } else {
      return 0;
    }
  } else if (a.messages.length === 0 && b.messages.length > 0) {
    return 1;
  } else if (b.messages.length === 0 && a.messages.length > 0) {
    return -1;
  } else {
    return 0;
  }
}

export function sortChatsByOldestActionItem(
  a: Chat,
  b: Chat,
  actionItems: ActionItem[],
) {
  if (a.pinned && !b.pinned) {
    return -1;
  } else if (!a.pinned && b.pinned) {
    return 1;
  }

  const aActionItems = actionItems.filter(
    (item) => item.user_id === a.id && item.type !== "custom",
  );
  const bActionItems = actionItems.filter(
    (item) => item.user_id === b.id && item.type !== "custom",
  );

  const aDates = aActionItems.map(
    (actionItem) => actionItem.date_to_send || actionItem.date_created,
  ) as string[];
  const bDates = bActionItems.map(
    (actionItem) => actionItem.date_to_send || actionItem.date_created,
  ) as string[];

  if (a.oldestUnreadMessageFromClient) {
    aDates.push(a.oldestUnreadMessageFromClient);
  }
  if (b.oldestUnreadMessageFromClient) {
    bDates.push(b.oldestUnreadMessageFromClient);
  }

  if (aDates.length === 0 && bDates.length === 0) {
    return 0;
  } else if (aDates.length === 0) {
    return 1;
  } else if (bDates.length === 0) {
    return -1;
  }

  const aOldestDate = aDates.sort((a, b) => a.localeCompare(b))[0];
  const bOldestDate = bDates.sort((a, b) => a.localeCompare(b))[0];

  return aOldestDate.localeCompare(bOldestDate);
}

export function sortChatsByOfficeHours(
  a: Chat,
  b: Chat,
  coachOfficeHours: Trainer["office_hours"],
  actionItems: ActionItem[],
) {
  const aActionItems = actionItems.filter((item) => item.user_id === a.id);
  const bActionItems = actionItems.filter((item) => item.user_id === b.id);

  const aVal =
    aActionItems.some(
      (item) => getActionItemStatus(item, coachOfficeHours) === "error",
    ) ||
    getNeedsResponseStatus(a.oldestMessageNeedingResponse, coachOfficeHours) ===
      "error"
      ? 6
      : getUnreadStatus(a.oldestUnreadMessageFromClient, coachOfficeHours) ===
          "error"
        ? 5
        : aActionItems.some(
              (item) => getActionItemStatus(item, coachOfficeHours) === "warn",
            ) ||
            getNeedsResponseStatus(
              a.oldestMessageNeedingResponse,
              coachOfficeHours,
            ) === "warn"
          ? 4
          : getUnreadStatus(
                a.oldestUnreadMessageFromClient,
                coachOfficeHours,
              ) === "warn"
            ? 3
            : a.oldestUnreadMessageFromClient
              ? 2
              : aActionItems.length > 0
                ? 1
                : 0;

  const bVal =
    bActionItems.some(
      (item) => getActionItemStatus(item, coachOfficeHours) === "error",
    ) ||
    getNeedsResponseStatus(b.oldestMessageNeedingResponse, coachOfficeHours) ===
      "error"
      ? 6
      : getUnreadStatus(b.oldestUnreadMessageFromClient, coachOfficeHours) ===
          "error"
        ? 5
        : bActionItems.some(
              (item) => getActionItemStatus(item, coachOfficeHours) === "warn",
            ) ||
            getNeedsResponseStatus(
              b.oldestMessageNeedingResponse,
              coachOfficeHours,
            ) === "warn"
          ? 4
          : getUnreadStatus(
                b.oldestUnreadMessageFromClient,
                coachOfficeHours,
              ) === "warn"
            ? 3
            : b.oldestUnreadMessageFromClient
              ? 2
              : bActionItems.length > 0
                ? 1
                : 0;

  if (aVal > bVal) {
    return -1;
  } else if (aVal < bVal) {
    return 1;
  } else {
    if (b.messages.length >= 1 && a.messages.length > 0) {
      const aUnread = Boolean(a.oldestUnreadMessageFromClient);
      const bUnread = Boolean(b.oldestUnreadMessageFromClient);

      if (aUnread && !bUnread) {
        return -1;
      } else if (!aUnread && bUnread) {
        return 1;
      }

      return (
        b.messages[b.messages.length - 1].send_date as string
      ).localeCompare(a.messages[a.messages.length - 1].send_date as string);
    } else if (a.messages.length === 0 && b.messages.length > 0) {
      return 1;
    } else if (b.messages.length === 0 && a.messages.length > 0) {
      return -1;
    } else {
      return 0;
    }
  }
}

function getActionItemSortPriority(actionItems: ActionItem[], chat: Chat) {
  if (chat.pinned) {
    return -1;
  }

  if (
    actionItems.some(
      (item) => item.type === "respond" && item.respond_type === "question",
    )
  ) {
    return 0;
  } else if (actionItems.some((item) => item.type === "new_client")) {
    return 1;
  } else if (
    actionItems.some((item) => item.type === "switched_coach_to_you")
  ) {
    return 2;
  } else if (actionItems.some((item) => item.type === "workout_review")) {
    return 3;
  } else if (
    actionItems.some((item) => item.type === "user_requested_cancelation")
  ) {
    return 4;
  } else if (actionItems.some((item) => item.type === "respond")) {
    return 5;
  }

  return 100;
}

export function sortChatsByActionItemsGrouped(
  a: Chat,
  b: Chat,
  actionItems: ActionItem[],
) {
  const aActionItems = actionItems.filter((item) => item.user_id === a.id);
  const bActionItems = actionItems.filter((item) => item.user_id === b.id);

  const aPriority = getActionItemSortPriority(aActionItems, a);
  const bPriority = getActionItemSortPriority(bActionItems, b);

  return aPriority - bPriority;
}

export function getUserIdsMatchingFilter(
  filter: string,
  clients: Client[],
  clientInfo: ClientInfo,
  actionItems: ActionItem[],
): string[] {
  const today = new Date();

  const dayIndex = today.getDay();
  const daysLeftInWeek = 6 - dayIndex;

  if (filter.startsWith("ai:")) {
    const actionItemType = filter.split(":")[1];

    return clients
      .filter((client) =>
        actionItems.some(
          (actionItem) =>
            actionItem.user_id === client.user_id &&
            actionItem.type === actionItemType,
        ),
      )
      .map((client) => client.user_id);
  } else if (filter.startsWith("client_tag:")) {
    const tag = filter.split(":")[1];

    return clients
      .filter((client) => client.tags?.includes(tag) ?? false)
      .map((client) => client.user_id);
  } else if (filter === "has_action_items") {
    return clients
      .filter((client) =>
        actionItems.some((ai) => ai.user_id === client.user_id),
      )
      .map((client) => client.user_id);
  } else if (filter === "workout_today") {
    return clients
      .filter(
        (client) => clientInfo[client.user_id]?.daysUntilNextWorkout === 0,
      )
      .map((client) => client.user_id);
  } else if (filter === "workout_tomorrow") {
    return clients
      .filter(
        (client) => clientInfo[client.user_id]?.daysUntilNextWorkout === 1,
      )
      .map((client) => client.user_id);
  } else if (filter === "any_workout_week") {
    return clients
      .filter(
        (client) =>
          (clientInfo[client.user_id]?.daysUntilNextWorkout ?? 999) <=
          daysLeftInWeek,
      )
      .map((client) => client.user_id);
  } else if (filter === "missed_streak_0") {
    return clients
      .filter((client) => client.missed_workout_day_streak === 0)
      .map((client) => client.user_id);
  } else if (filter === "missed_streak_1") {
    return clients
      .filter((client) => client.missed_workout_day_streak === 1)
      .map((client) => client.user_id);
  } else if (filter === "missed_streak_2_or_more") {
    return clients
      .filter((client) => (client.missed_workout_day_streak ?? 0) >= 2)
      .map((client) => client.user_id);
  } else if (filter === "visible") {
    return clients
      .filter((client) => !client.account.dashboard.is_hidden)
      .map((client) => client.user_id);
  } else if (filter === "hidden") {
    return clients
      .filter((client) => client.account.dashboard.is_hidden === true)
      .map((client) => client.user_id);
  } else if (filter === "pre_trial") {
    return clients
      .filter((client) => {
        return !client.account.dashboard.date_onboarded;
      })
      .map((client) => client.user_id);
  } else if (filter === "in_trial") {
    return clients
      .filter((client) => {
        return (
          client.account.dashboard.date_onboarded &&
          client.account.plan.date_trial_end &&
          isFuture(new Date(client.account.plan.date_trial_end))
        );
      })
      .map((client) => client.user_id);
  } else if (filter === "post_trial") {
    return clients
      .filter((client) => {
        return (
          client.account.dashboard.date_onboarded &&
          client.account.plan.date_trial_end &&
          isPast(new Date(client.account.plan.date_trial_end))
        );
      })
      .map((client) => client.user_id);
  } else if (filter === "unmessaged_3_or_more") {
    return clients
      .filter((client) => {
        const lastMessageDate = client.last_trainer_message_date
          ? new Date(client.last_trainer_message_date)
          : undefined;

        if (!lastMessageDate) {
          return false;
        }

        const daysSinceMessage = differenceInCalendarDays(
          today,
          lastMessageDate,
        );

        return daysSinceMessage >= 3;
      })
      .map((client) => client.user_id);
  } else if (filter === "unmessaged_7_or_more") {
    return clients
      .filter((client) => {
        const lastMessageDate = client.last_trainer_message_date
          ? new Date(client.last_trainer_message_date)
          : undefined;

        if (!lastMessageDate) {
          return false;
        }

        const daysSinceMessage = differenceInCalendarDays(
          today,
          lastMessageDate,
        );

        return daysSinceMessage >= 7;
      })
      .map((client) => client.user_id);
  } else if (filter === "active") {
    return clients
      .filter((client) => {
        return !client.account.dashboard.is_inactive;
      })
      .map((client) => client.user_id);
  } else if (filter === "inactive") {
    return clients
      .filter((client) => {
        return client.account.dashboard.is_inactive === true;
      })
      .map((client) => client.user_id);
  } else if (filter === "worked_out_today") {
    return clients
      .filter((client) => {
        const lastWorkoutDate = client.last_workout_date
          ? getDateWithTimezoneOffset(
              new Date(client.last_workout_date),
              client.default_timezone_offset ?? 0,
            )
          : undefined;

        if (!lastWorkoutDate) {
          return false;
        }

        const daysSinceWorkout = differenceInCalendarDays(
          today,
          lastWorkoutDate,
        );

        return daysSinceWorkout === 0;
      })
      .map((client) => client.user_id);
  } else if (filter === "worked_out_week") {
    return clients
      .filter((client) => {
        const lastWorkoutDate = client.last_workout_date
          ? getDateWithTimezoneOffset(
              new Date(client.last_workout_date),
              client.default_timezone_offset ?? 0,
            )
          : undefined;

        if (!lastWorkoutDate) {
          return false;
        }

        const daysSinceWorkout = differenceInCalendarDays(
          today,
          lastWorkoutDate,
        );

        return daysSinceWorkout >= dayIndex;
      })
      .map((client) => client.user_id);
  }

  return [];
}

export function getChatsMatchingFilter(
  filter: string,
  chats: Chat[],
  clients: Client[],
  clientInfo: ClientInfo,
  actionItems: ActionItem[],
) {
  const userIds = getUserIdsMatchingFilter(
    filter,
    clients,
    clientInfo,
    actionItems,
  );

  return chats.filter((chat) => userIds.includes(chat.id));
}
