import { useTheme } from "@mui/material";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import ScreenCapture from "@uppy/screen-capture";
import Transloadit from "@uppy/transloadit";
import Webcam from "@uppy/webcam";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { TRANSLOADIT_AUTH_KEY } from "src/config/config";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  selectSelectedChat,
  sendImageMessage,
  sendVideoMessage,
  setMediaUploadUi,
} from "src/slices/chatSlice";

export default function ChatMediaUpload() {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const selectedChat = useAppSelector(selectSelectedChat);
  const chatRef = useRef(selectedChat);
  const theme = useTheme();
  const [uppy] = useState(() =>
    new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*", "video/*"],
      },
      onBeforeFileAdded: () => {
        dispatch(setMediaUploadUi("uploading"));
        return true;
      },
    })
      .use(Webcam, {
        modes: ["video-audio", "picture"],
        videoConstraints: {
          facingMode: "user",
          width: { min: 480, max: 720, ideal: 480 },
          height: { min: 480, max: 720, ideal: 480 },
        },
        showRecordingLength: true,
        mobileNativeCamera: true,
        preferredImageMimeType: "image/jpeg",
      })
      .use(ScreenCapture)
      .use(Transloadit, {
        assemblyOptions: {
          params: {
            auth: { key: TRANSLOADIT_AUTH_KEY },
            template_id: "chat-media",
          },
        },
        waitForEncoding: true,
      })
      .on("complete", () => {
        uppy.cancelAll();
        closeMediaUpload();
        dispatch(setMediaUploadUi("hide"));
      })
      .on("transloadit:complete", (assembly) => {
        console.log("Uppy: upload success", assembly);

        if (
          "videos" in assembly.results &&
          assembly.results.videos.length &&
          assembly.results.videos[0].ssl_url &&
          "thumbnails" in assembly.results &&
          assembly.results.thumbnails.length &&
          assembly.results.thumbnails[0].ssl_url
        ) {
          const videoResult = assembly.results.videos[0];
          const thumbnailResult = assembly.results.thumbnails[0];

          dispatch(
            sendVideoMessage({
              video_url: videoResult.ssl_url,
              thumbnail_url: thumbnailResult.ssl_url,
              userId: chatRef.current!.id,
              toGroup: chatRef.current!.isGroupChat,
              asTrainwell: chatRef.current!.isTrainwell ?? false,
              width: videoResult.meta.width,
              height: videoResult.meta.height,
            }),
          )
            .unwrap()
            .catch(() => {
              enqueueSnackbar("Video failed to send", {
                variant: "error",
              });
            });
        } else if (
          "images" in assembly.results &&
          assembly.results.images.length &&
          assembly.results.images[0].ssl_url
        ) {
          const imageResult = assembly.results.images[0];

          dispatch(
            sendImageMessage({
              image_url: imageResult.ssl_url,
              userId: chatRef.current!.id,
              toGroup: chatRef.current!.isGroupChat,
              asTrainwell: chatRef.current!.isTrainwell ?? false,
              width: imageResult.meta.width,
              height: imageResult.meta.height,
            }),
          )
            .unwrap()
            .catch(() => {
              enqueueSnackbar("Image failed to send", {
                variant: "error",
              });
            });
        }
      }),
  );

  function closeMediaUpload() {
    dispatch(setMediaUploadUi("hide"));
  }

  return (
    <Dashboard
      uppy={uppy}
      plugins={["Webcam", "ScreenCapture"]}
      proudlyDisplayPoweredByUppy={false}
      showLinkToFileUploadResult={false}
      height={"100%"}
      doneButtonHandler={() => {
        closeMediaUpload();
      }}
      theme={theme.palette.mode}
    />
  );
}
