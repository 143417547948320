import { configureStore } from "@reduxjs/toolkit";
import actionItemsReducer from "src/slices/actionItemSlice";
import allClientsReducer from "src/slices/allClientsSlice";
import appReducer from "src/slices/appSlice";
import chatReducer from "src/slices/chatSlice";
import clientEditReducer from "src/slices/clientEditSlice";
import clientReducer from "src/slices/clientSlice";
import clientsReducer from "src/slices/clientsSlice";
import companyReducer from "src/slices/companySlice";
import employeesReducer from "src/slices/employeesSlice";
import goalTemplatesReducer from "src/slices/goalTemplates";
import phaseTemplatesReducer from "src/slices/phaseTemplatesSlice";
import phasesReducer from "src/slices/phasesSlice";
import progressMetricsReducer from "src/slices/progressMetricsSlice";
import templatesReducer from "src/slices/templatesSlice";
import testsReducer from "src/slices/testsSlice";
import ticketsReducer from "src/slices/ticketsSlice";
import trainerEditReducer from "src/slices/trainerEditSlice";
import trainerReducer from "src/slices/trainerSlice";
import trainersReducer from "src/slices/trainersSlice";
import vacationsReducer from "src/slices/vacationsSlice";
import workoutBuilderReducer from "src/slices/workoutBuilderSlice";
import workoutReducer from "src/slices/workoutSlice";

import { clientApi } from "./api/clientApi";
import { coachNotificationApi } from "./api/coachNotificationApi";
import { exercisesApi } from "./api/exercisesApi";
import { googleFormsApi } from "./api/googleFormsApi";
import { logApi } from "./api/logApi";
import { progressMetricLogsApi } from "./api/progressMetricLogsApi";
import { setHistoryApi } from "./api/setHistoryApi";
import { trainerMediaApi } from "./api/trainerMediaApi";

export const store = configureStore({
  reducer: {
    app: appReducer,
    trainer: trainerReducer,
    clients: clientsReducer,
    client: clientReducer,
    chat: chatReducer,
    workout: workoutReducer,
    actionItems: actionItemsReducer,
    templates: templatesReducer,
    trainers: trainersReducer,
    tests: testsReducer,
    trainerEdit: trainerEditReducer,
    employees: employeesReducer,
    clientEdit: clientEditReducer,
    allClients: allClientsReducer,
    tickets: ticketsReducer,
    vacations: vacationsReducer,
    company: companyReducer,
    goalTemplates: goalTemplatesReducer,
    progressMetrics: progressMetricsReducer,
    phaseTemplates: phaseTemplatesReducer,
    workoutBuilder: workoutBuilderReducer,
    phases: phasesReducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [logApi.reducerPath]: logApi.reducer,
    [progressMetricLogsApi.reducerPath]: progressMetricLogsApi.reducer,
    [setHistoryApi.reducerPath]: setHistoryApi.reducer,
    [trainerMediaApi.reducerPath]: trainerMediaApi.reducer,
    [googleFormsApi.reducerPath]: googleFormsApi.reducer,
    [coachNotificationApi.reducerPath]: coachNotificationApi.reducer,
    [exercisesApi.reducerPath]: exercisesApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      clientApi.middleware,
      logApi.middleware,
      progressMetricLogsApi.middleware,
      setHistoryApi.middleware,
      trainerMediaApi.middleware,
      googleFormsApi.middleware,
      coachNotificationApi.middleware,
      exercisesApi.middleware,
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
