import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import type { Client } from "@trainwell/types";
import {
  trainerAccessRoles,
  trainerCertification,
  trainerEducation,
} from "@trainwell/types";
import { startOfDay } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import CustomLink from "src/components/misc/CustomLink";
import RestrictAccess from "src/components/misc/RestrictAccess";
import SmartEditAutoComplete from "src/components/misc/SmartEditAutoComplete";
import SmartEditChips from "src/components/misc/SmartEditChips";
import SmartEditDateField from "src/components/misc/SmartEditDateField";
import SmartEditList from "src/components/misc/SmartEditList";
import SmartEditSelect from "src/components/misc/SmartEditSelect";
import SmartEditSwitch from "src/components/misc/SmartEditSwitch";
import SmartEditTextField from "src/components/misc/SmartEditTextField";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";
import {
  getDateForDatabase,
  getDateFromDatabase,
  getZeroedOutUtcDate,
} from "src/lib/date";
import { api } from "src/lib/trainwellApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { updateTrainer, updateTrainerEmail } from "src/slices/trainersSlice";
import VoiceSettings from "./VoiceSettings";

export default function TabInfo() {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const trainerEdit = useAppSelector((state) => state.trainerEdit.trainer);
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);
  const [customClaims, setCustomClaims] = useState<Awaited<
    ReturnType<(typeof api)["trainers"]["getCustomClaims"]>
  > | null>();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [client, setClient] = useState<null | Client | undefined | "error">();

  useEffect(() => {
    if (trainerEdit?.trainer_id) {
      api.trainers
        .getCustomClaims(trainerEdit.trainer_id)
        .then((claims) => {
          setCustomClaims(claims);
        })
        .catch(() => {
          setCustomClaims(null);
        });
    }
  }, [trainerEdit?.trainer_id]);

  useEffect(() => {
    if (trainerEdit?.user_id) {
      api.clients
        .getOne(trainerEdit.user_id)
        .then((data) => {
          setClient(data);
        })
        .catch(() => {
          setClient("error");
        });
    } else {
      setClient(null);
    }
  }, [trainerEdit?.user_id]);

  if (!trainerEdit) {
    return <LoadingPage message="Getting your trainer" />;
  }

  return (
    <Box>
      <Card sx={{ mb: 4, p: 2 }} variant="outlined">
        <Typography variant="h2" sx={{ mb: 2 }}>
          Quick controls
        </Typography>
        <SmartEditSwitch
          label="Active"
          value={trainerEdit.is_active}
          onSave={(value) => {
            dispatch(
              updateTrainer({
                trainer_id: trainerEdit.trainer_id,
                is_active: value,
              }),
            );
          }}
        />
        <SmartEditSwitch
          label="Auto active"
          value={trainerEdit.automatically_toggle_active ?? false}
          onSave={(value) => {
            dispatch(
              updateTrainer({
                trainer_id: trainerEdit.trainer_id,
                automatically_toggle_active: value,
              }),
            );

            if (value) {
              api.analytics.trackEvent({
                trainerId: trainerEdit.trainer_id,
                eventType: "trainer_manually_toggled_on",
                platform: "coach_dash",
                sessionId: "",
                eventContent: {
                  by_trainer_id: trainer?.trainer_id,
                },
              });
            } else {
              api.analytics.trackEvent({
                trainerId: trainerEdit.trainer_id,
                eventType: "trainer_manually_toggled_off",
                platform: "coach_dash",
                sessionId: "",
                eventContent: {
                  by_trainer_id: trainer?.trainer_id,
                },
              });
            }
          }}
        />
        <SmartEditSwitch
          label="Lead Trainer"
          value={trainerEdit.is_lead_trainer ?? false}
          disabled={
            !trainerHasAccess(trainer?.access_roles, "staff_management")
          }
          onSave={(value) => {
            dispatch(
              updateTrainer({
                trainer_id: trainerEdit.trainer_id,
                is_lead_trainer: value,
              }),
            );
          }}
        />
        <SmartEditSwitch
          label="Manager"
          value={trainerEdit.is_manager ?? false}
          disabled={
            !trainerHasAccess(trainer?.access_roles, "staff_management")
          }
          onSave={(value) => {
            dispatch(
              updateTrainer({
                trainer_id: trainerEdit.trainer_id,
                is_manager: value,
              }),
            );
          }}
        />
        <SmartEditSwitch
          label="Director"
          value={trainerEdit.is_director ?? false}
          disabled={
            !trainerHasAccess(trainer?.access_roles, "staff_management")
          }
          onSave={(value) => {
            dispatch(
              updateTrainer({
                trainer_id: trainerEdit.trainer_id,
                is_director: value,
              }),
            );
          }}
        />
        <SmartEditSwitch
          label="Disable access"
          value={trainerEdit.disable_access ?? false}
          disabled={
            !trainerHasAccess(trainer?.access_roles, "staff_management")
          }
          onSave={(value) => {
            dispatch(
              updateTrainer({
                trainer_id: trainerEdit.trainer_id,
                disable_access: value,
              }),
            );
          }}
        />
      </Card>
      <Stack direction="column" spacing={4} sx={{ mb: 8 }}>
        <Card sx={{ p: 2 }} variant="outlined">
          <Typography variant="h2" sx={{ mb: 2 }}>
            General
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SmartEditTextField
                label="First name"
                value={trainerEdit.first_name}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      first_name: value as string,
                      full_name: `${value} ${trainerEdit.last_name}`,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditTextField
                label="Last name"
                value={trainerEdit.last_name}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      last_name: value as string,
                      full_name: `${trainerEdit.first_name} ${value}`,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditTextField
                label="Email"
                value={trainerEdit.email}
                inputType="email"
                onSave={(value) => {
                  dispatch(
                    updateTrainerEmail({
                      trainerId: trainerEdit.trainer_id,
                      email: value as string,
                    }),
                  )
                    .unwrap()
                    .catch((error) => {
                      console.log(error);
                      enqueueSnackbar(error.message || "Error updating email", {
                        variant: "error",
                      });
                    });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditTextField
                label="Phone number"
                value={trainerEdit.phone_number}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      phone_number: value as string,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditDateField
                label="Birthday"
                value={
                  trainerEdit.birthday
                    ? getDateFromDatabase(trainerEdit.birthday as string)
                    : new Date()
                }
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      birthday: getZeroedOutUtcDate(
                        getDateForDatabase(startOfDay(value!).toISOString()),
                      ),
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditSelect
                label="Tier"
                value={trainerEdit.tier?.toString()}
                possibleValues={[
                  {
                    value: "1",
                    label: "Tier 1",
                  },
                  {
                    value: "2",
                    label: "Tier 2",
                  },
                  {
                    value: "3",
                    label: "Tier 3",
                  },
                  {
                    value: "4",
                    label: "Tier 4",
                  },
                ]}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      tier: Number(value),
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditDateField
                label="Employment started"
                value={
                  trainerEdit.employment_start_date
                    ? getDateFromDatabase(
                        trainerEdit.employment_start_date as string,
                      )
                    : new Date()
                }
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      employment_start_date: getZeroedOutUtcDate(
                        getDateForDatabase(startOfDay(value!).toISOString()),
                      ),
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditAutoComplete
                label="Manager"
                value={trainerEdit.manager_id}
                possibleValues={trainerNames
                  .map((trainer) => {
                    return {
                      value: trainer.trainer_id,
                      label: trainer.full_name,
                    };
                  })
                  .sort((a, b) => a.label.localeCompare(b.label))}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      manager_id: value,
                    }),
                  );
                }}
              />
            </Grid>
            <RestrictAccess location={"access_roles"}>
              {trainerEdit.is_staff && (
                <Grid item xs={6}>
                  <SmartEditChips
                    label="Access roles"
                    values={trainerEdit.access_roles}
                    possibleValues={trainerAccessRoles}
                    onSave={(values) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainerEdit.trainer_id,
                          access_roles: values as any,
                        }),
                      );
                    }}
                  />
                </Grid>
              )}
            </RestrictAccess>
            <Grid item xs={6}>
              <SmartEditTextField
                label="Referral url (this trainer)"
                value={trainerEdit.referral_url_specific_coach}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      referral_url_specific_coach: value as string,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditTextField
                label="Referral url (any trainer)"
                value={trainerEdit.referral_url_any_coach}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      referral_url_any_coach: value as string,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditTextField
                label="Years experience"
                value={(trainerEdit.years_experience ?? 0).toString()}
                inputType="number"
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      years_experience: value as number,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditTextField
                label="Previous price"
                value={(trainerEdit.price_previous ?? 0).toString()}
                inputType="number"
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      price_previous: value as number,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditChips
                label="Education"
                values={trainerEdit.education}
                possibleValues={trainerEducation}
                onSave={(values) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      education: values,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditChips
                label="Certifications"
                values={trainerEdit.certifications_short}
                possibleValues={trainerCertification}
                onSave={(values) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      certifications_short: values,
                    }),
                  );
                }}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ p: 2 }} variant="outlined">
          <Typography variant="h2" sx={{ mb: 2 }}>
            Client load
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <SmartEditTextField
                label="Client cap"
                value={(trainerEdit.client_capacity ?? 0).toString()}
                inputType="number"
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      client_capacity: value as number,
                    }),
                  );
                }}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ p: 2 }} variant="outlined">
          <Typography variant="h2" sx={{ mb: 2 }}>
            Bio
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SmartEditTextField
                label="About"
                multiline
                value={trainerEdit.short_about}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      short_about: value as string,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditList
                label="Experience"
                values={trainerEdit.experience}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      experience: value,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditList
                label="Specialties"
                values={trainerEdit.specialties}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      specialties: value,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditList
                label="Loves"
                values={trainerEdit.loves}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      loves: value,
                    }),
                  );
                }}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ p: 2 }} variant="outlined">
          <Typography variant="h2" sx={{ mb: 2 }}>
            Client account
          </Typography>
          {client === "error" ? (
            <Typography>Error finding client</Typography>
          ) : client === null ? (
            <Typography>
              Not setup. The trainer will be prompted to finish setting up their
              account next time they log into the dashboard.
            </Typography>
          ) : client === undefined ? (
            <CircularProgress />
          ) : (
            <>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <img
                  style={{
                    borderRadius: "25px",
                    display: "block",
                  }}
                  src={client.headshot_url || "/assets/profile.png"}
                  alt={client.full_name}
                  width={50}
                  height={50}
                />
                <Box sx={{ ml: 2 }}>
                  <CustomLink
                    text={client.full_name}
                    href={`/admin/company/clients/${client.user_id}`}
                  />
                  <Typography
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                  >
                    User id: {client.user_id}
                  </Typography>
                </Box>
              </Box>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="text"
                  size="small"
                  href={`/admin/company/clients/${client.user_id}`}
                  endIcon={<OpenInNewRoundedIcon />}
                >
                  Admin dash
                </Button>
                <Button
                  variant="text"
                  size="small"
                  href={`/clients/${client.user_id}`}
                  endIcon={<OpenInNewRoundedIcon />}
                >
                  Client dash
                </Button>
              </Stack>
            </>
          )}
        </Card>
        <Card sx={{ p: 2 }} variant="outlined">
          <VoiceSettings />
        </Card>
        <Card sx={{ p: 2 }} variant="outlined">
          <Typography variant="h2" sx={{ mb: 2 }}>
            Technical
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <SmartEditTextField
                label="Slack id"
                value={trainerEdit.slack_id}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      slack_id: value as string,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditTextField
                label="Manager slack channel id"
                value={trainerEdit.manager_slack_channel}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      manager_slack_channel: value as string,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditTextField
                label="Cancellations slack channel id"
                value={trainerEdit.cancellations_slack_channel_id}
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      cancellations_slack_channel_id: value as string,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditTextField
                label="Timezone"
                value={trainerEdit.timezone}
                disabled
                onSave={(value) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      timezone: value as string,
                    }),
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartEditChips
                label="Device platforms"
                values={trainerEdit.device_platforms}
                possibleValues={{ ios: "iOS", android: "Android" }}
                onSave={(values) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainerEdit.trainer_id,
                      device_platforms: values as any,
                    }),
                  );
                }}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ p: 2 }} variant="outlined">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box>
              <Typography variant="h2">Authentication</Typography>
              <Typography
                sx={{ mb: 2, color: (theme) => theme.palette.text.secondary }}
              >
                Firebase uid: {trainerEdit.auth.firebase_uid_email}
              </Typography>
            </Box>
            <SmartEditSwitch
              label="Admin authentication"
              value={trainerEdit.auth.is_admin ?? false}
              onSave={(value) => {
                dispatch(
                  updateTrainer({
                    trainer_id: trainerEdit.trainer_id,
                    // @ts-expect-error
                    "auth.is_admin": value,
                  }),
                );
              }}
            />
          </Box>
          <Typography sx={{ fontWeight: "bold" }}>JWT token:</Typography>
          <Typography>
            <pre>
              <code>
                {customClaims ? JSON.stringify(customClaims, null, 2) : "None"}
              </code>
            </pre>
          </Typography>
        </Card>
      </Stack>
    </Box>
  );
}
