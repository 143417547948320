import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import { Box, Stack, Toolbar, Typography } from "@mui/material";
import type { ActionItem, ActionItemType } from "@trainwell/types";
import { useMemo, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectSentActionItems } from "src/slices/actionItemSlice";
import {
  selectIsAuditing,
  selectPrimaryTrainer,
} from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";
import ActionItemCell from "./ActionItemCell";
import ActionItemClientCell from "./ActionItemClientCell";
import ActionItemFilterButton from "./ActionItemFilterButton";
import ActionItemSortButton, {
  type ActionItemSort,
} from "./ActionItemSortButton";

export default function ActionItemSelector() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const actionItems = useAppSelector(selectSentActionItems);
  const [filterTypes, setFilterTypes] = useState<ActionItemType[]>(
    JSON.parse(sessionStorage.getItem("action_item_filter") ?? "[]"),
  );
  const [sortMode, setSortMode] = useState<ActionItemSort>(
    (trainer?.settings?.action_item_sort as any) ?? "client_grouped",
  );
  const isAuditing = useAppSelector(selectIsAuditing);
  const displayedActionItems = useMemo(() => {
    let newItems = JSON.parse(JSON.stringify(actionItems)) as ActionItem[];

    if (filterTypes.length) {
      newItems = newItems.filter((i) => {
        return filterTypes.includes(i.type);
      });
    }

    if (sortMode === "oldest_first") {
      newItems.sort((a, b) =>
        a.date_created > b.date_created
          ? 1
          : b.date_created > a.date_created
            ? -1
            : 0,
      );
    } else if (sortMode === "newest_first") {
      newItems.sort((a, b) =>
        a.date_created < b.date_created
          ? 1
          : b.date_created < a.date_created
            ? -1
            : 0,
      );
    } else if (sortMode === "client_grouped") {
      newItems.sort((a, b) =>
        a.user_id < b.user_id
          ? 1
          : b.user_id < a.user_id
            ? -1
            : a.date_created < b.date_created
              ? 1
              : b.date_created < a.date_created
                ? -1
                : 0,
      );
    }

    return newItems;
  }, [filterTypes, sortMode, actionItems]);

  const groupedClients = [
    ...new Set(displayedActionItems.map((item) => item.user_id)),
  ];

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Toolbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
          py: 1,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box>
          <Typography variant="h1">Action items</Typography>
          {filterTypes.length > 0 && (
            <Typography variant="overline">
              Showing {displayedActionItems.length} / {actionItems.length}
            </Typography>
          )}
        </Box>
        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
          <ActionItemSortButton
            value={sortMode}
            onChange={(newSortMode) => {
              setSortMode(newSortMode);

              if (!isAuditing) {
                dispatch(
                  updateTrainer({
                    trainer_id: trainer!.trainer_id,
                    settings: {
                      ...trainer!.settings,
                      action_item_sort: newSortMode,
                    },
                  }),
                );
              }
            }}
          />
          <ActionItemFilterButton
            value={filterTypes}
            onChange={(newActionItems) => {
              setFilterTypes(newActionItems);

              sessionStorage.setItem(
                "action_item_filter",
                JSON.stringify(newActionItems),
              );
            }}
          />
        </Stack>
      </Box>
      {displayedActionItems && displayedActionItems.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              mb: 2,
            }}
          >
            You&apos;re all caught up
          </Typography>
          <InventoryRoundedIcon
            fontSize="large"
            sx={{ color: (theme) => theme.palette.text.secondary }}
          />
        </Box>
      ) : (
        <>
          {sortMode !== "client_grouped" ? (
            <>
              <Box sx={{ flex: 1, overflowY: "auto" }}>
                <Virtuoso
                  data={displayedActionItems}
                  itemContent={(index, actionItem) => (
                    <ActionItemCell
                      key={actionItem.id}
                      actionItemId={actionItem.id}
                    />
                  )}
                />
              </Box>
            </>
          ) : (
            <Box sx={{ flex: 1, overflowY: "auto" }}>
              {groupedClients.map((userId) => (
                <ActionItemClientCell key={userId} userId={userId} />
              ))}
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
