import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DataArrayRoundedIcon from "@mui/icons-material/DataArrayRounded";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { useShortUrls } from "@trainwell/features";
import { useMemo, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import RestrictAccess from "src/components/misc/RestrictAccess";
import SearchField from "src/components/misc/SearchField";
import ShortUrlCell from "./ShortUrlCell";
import { ShortUrlEditDialog } from "./ShortUrlEditDialog";
import ShortUrlSortButton, { type ShortUrlSort } from "./ShortUrlSortButton";

export default function ShortUrlPage() {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("all");
  const [sortMode, setSortMode] = useState<ShortUrlSort>("newest");
  const [search, setSearch] = useState("");
  const { data: shortUrls, isLoading: isLoadingShortUrls } = useShortUrls();

  const displayedShortUrls = useMemo(() => {
    if (isLoadingShortUrls || !shortUrls) {
      return [];
    }

    let newShortUrls = [...shortUrls];

    if (currentTab === "favorites") {
      newShortUrls = newShortUrls.filter(
        (shortUrl) => shortUrl.favorite && !shortUrl.date_archived,
      );
    } else if (currentTab === "all") {
      newShortUrls = newShortUrls.filter((shortUrl) => !shortUrl.date_archived);
    } else if (currentTab === "archived") {
      newShortUrls = newShortUrls.filter((shortUrl) => shortUrl.date_archived);
    }

    if (sortMode === "clicks") {
      newShortUrls.sort((a, b) => b.clicks - a.clicks);
    } else if (sortMode === "newest") {
      newShortUrls.sort((a, b) =>
        (b.date_created as string).localeCompare(a.date_created as string),
      );
    } else if (sortMode === "oldest") {
      newShortUrls.sort((a, b) =>
        (a.date_created as string).localeCompare(b.date_created as string),
      );
    }

    if (search !== "") {
      newShortUrls = newShortUrls.filter(
        (shortUrl) =>
          shortUrl.short_path.toLowerCase().includes(search.toLowerCase()) ||
          shortUrl.original_url.toLowerCase().includes(search.toLowerCase()),
      );
    }

    return newShortUrls;
  }, [currentTab, shortUrls, sortMode, search]);

  return (
    <RestrictAccess location={"campaigns"} showNoAccessMessage>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mx: 4,
            mt: 2,
          }}
        >
          <Typography variant="h1">Short urls</Typography>
          <Button
            variant="contained"
            onClick={() => {
              setEditDialogOpen(true);
            }}
            startIcon={<AddRoundedIcon />}
          >
            New short url
          </Button>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}>
          <Tabs
            value={currentTab}
            onChange={(_, newValue) => {
              setCurrentTab(newValue);
            }}
            aria-label="url tabs"
            sx={{
              mx: 4,
              minHeight: "32px",
            }}
          >
            <Tab
              label="Favorites"
              value="favorites"
              sx={{ minHeight: "32px", py: 1 }}
            />
            <Tab label="All" value="all" sx={{ minHeight: "32px", py: 1 }} />
            <Tab
              label="Archived"
              value="archived"
              sx={{ minHeight: "32px", py: 1 }}
            />
          </Tabs>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mx: 4, mb: 1 }}>
          <SearchField
            value={search}
            onChange={(value) => {
              setSearch(value as string);
            }}
            onClear={() => {
              setSearch("");
            }}
            sx={{ mr: 2, maxWidth: "300px" }}
          />
          <ShortUrlSortButton
            value={sortMode}
            onChange={(newSortMode) => {
              setSortMode(newSortMode);
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            mx: 4,
            border: 1,
            borderBottom: 0,
            borderColor: "divider",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            overflow: "hidden",
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          {displayedShortUrls.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                p: 1,
                height: "100%",
              }}
            >
              <DataArrayRoundedIcon
                sx={{ color: (theme) => theme.palette.text.secondary }}
              />
              <Typography
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                No short urls found
              </Typography>
            </Box>
          ) : (
            <Virtuoso
              data={displayedShortUrls}
              style={{ height: "100%" }}
              itemContent={(index, shortUrl) => (
                <ShortUrlCell key={shortUrl.id} shortUrl={shortUrl} />
              )}
              components={{
                Footer: () => <Box sx={{ height: "64px" }} />,
              }}
            />
          )}
        </Box>
      </Box>
      <ShortUrlEditDialog
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
        }}
      />
    </RestrictAccess>
  );
}
