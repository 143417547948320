import type { SxProps, Theme } from "@mui/material";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "@tiptap/extension-link";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import StarterKit from "@tiptap/starter-kit";
import {
  LinkBubbleMenu,
  LinkBubbleMenuHandler,
  MenuButtonBlockquote,
  MenuButtonBulletedList,
  MenuButtonEditLink,
  MenuButtonHorizontalRule,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonTaskList,
  MenuButtonUndo,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  RichTextEditor,
  RichTextReadOnly,
} from "mui-tiptap";
import { useState } from "react";

type Props = {
  label: string;
  value: string | undefined;
  onSave: (newValue: string) => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  helperText?: string;
};

export function SmartEditRichTextField({
  label,
  value,
  onSave,
  sx = [],
  disabled,
  helperText,
}: Props) {
  const [isHovering, setIsHovering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(value ?? "");

  function save() {
    if (!isEditing) {
      return;
    }

    setIsEditing(false);

    onSave(newValue);
  }

  const CustomLinkExtension = Link.extend({
    inclusive: false,
  });

  return (
    <Box
      onMouseEnter={() => {
        if (disabled) {
          return;
        }

        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      sx={Array.isArray(sx) ? sx : [sx]}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
        <Typography variant="h6">{label}</Typography>
        <Box
          sx={{
            opacity: isHovering || isEditing ? undefined : 0,
            pointerEvents: isHovering || isEditing ? undefined : "none",
          }}
        >
          <Button
            variant={isEditing ? "contained" : "text"}
            size="small"
            sx={{
              ml: 1,
              lineHeight: 1.25,
              px: 1,
              py: 0.5,
              minWidth: "0px",
            }}
            onClick={() => {
              if (isEditing) {
                save();
              } else {
                setIsEditing(true);
              }
            }}
          >
            {isEditing ? "Save" : "Edit"}
          </Button>
          {isEditing && (
            <Button
              variant="text"
              size="small"
              sx={{
                ml: 1,
                lineHeight: 1.25,
                px: 1,
                py: 0.5,
                minWidth: "0px",
              }}
              onClick={() => {
                setIsEditing(false);
                setNewValue(value ?? "");
              }}
            >
              Cancel
            </Button>
          )}
        </Box>
      </Box>
      {isEditing ? (
        <>
          <RichTextEditor
            extensions={[
              StarterKit,
              TaskList,
              TaskItem.configure({
                nested: true,
              }),
              // CustomLinkExtension.configure({
              //   // autolink is generally useful for changing text into links if they
              //   // appear to be URLs (like someone types in literally "example.com"),
              //   // though it comes with the caveat that if you then *remove* the link
              //   // from the text, and then add a space or newline directly after the
              //   // text, autolink will turn the text back into a link again. Not ideal,
              //   // but probably still overall worth having autolink enabled, and that's
              //   // how a lot of other tools behave as well.
              //   autolink: true,
              //   linkOnPaste: true,
              //   openOnClick: false,
              // }),
              CustomLinkExtension.configure({
                // autolink is generally useful for changing text into links if they
                // appear to be URLs (like someone types in literally "example.com"),
                // though it comes with the caveat that if you then *remove* the link
                // from the text, and then add a space or newline directly after the
                // text, autolink will turn the text back into a link again. Not ideal,
                // but probably still overall worth having autolink enabled, and that's
                // how a lot of other tools behave as well.
                autolink: true,
                linkOnPaste: true,
                openOnClick: false,
              }),
              LinkBubbleMenuHandler,
            ]}
            content={
              newValue?.includes("<p>")
                ? newValue
                : `<p>${newValue?.split("\n").join("</p><p>")}</p>`
            }
            renderControls={() => (
              <MenuControlsContainer>
                <MenuSelectHeading />
                <MenuDivider />
                <MenuButtonOrderedList />
                <MenuButtonBulletedList />
                <MenuButtonTaskList />
                <MenuDivider />
                <MenuButtonHorizontalRule />
                <MenuDivider />
                <MenuButtonBlockquote />
                <MenuDivider />
                <MenuButtonUndo />
                <MenuButtonRedo />
                <MenuDivider />
                <MenuButtonEditLink />
              </MenuControlsContainer>
            )}
            onUpdate={({ editor }) => {
              setNewValue(editor.getHTML());
            }}
          >
            {() => (
              <>
                <LinkBubbleMenu />
              </>
            )}
          </RichTextEditor>
          {helperText && (
            <Typography variant="overline">{helperText}</Typography>
          )}
        </>
      ) : (
        // <TextField
        //   size="small"
        //   fullWidth
        //   multiline={multiline}
        //   value={newValue}
        //   type={inputType}
        //   onChange={(event) => {
        //     setNewValue(event.target.value);
        //   }}
        //   onKeyDown={(event) => {
        //     if (!multiline && event.key === "Enter") {
        //       save();
        //     }
        //   }}
        //   error={hasError}
        //   helperText={hasError ? "Invalid" : helperText}
        // />
        <RichTextReadOnly
          content={value}
          extensions={[
            StarterKit,
            TaskList,
            TaskItem.configure({
              nested: true,
            }),
            CustomLinkExtension.configure({
              // autolink is generally useful for changing text into links if they
              // appear to be URLs (like someone types in literally "example.com"),
              // though it comes with the caveat that if you then *remove* the link
              // from the text, and then add a space or newline directly after the
              // text, autolink will turn the text back into a link again. Not ideal,
              // but probably still overall worth having autolink enabled, and that's
              // how a lot of other tools behave as well.
              autolink: true,
              linkOnPaste: true,
              openOnClick: false,
            }),
            LinkBubbleMenuHandler,
          ]}
        />
      )}
    </Box>
  );
}
