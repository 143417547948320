import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ProgressMetric } from "@trainwell/types";
import { api } from "src/lib/trainwellApi";

export const fetchProgressMetrics = createAsyncThunk(
  "progressMetrics/fetchProgressMetrics",
  async () => {
    const response = await api.progressMetrics.getAll();

    return response;
  },
);

// Define a type for the slice state
interface AppState {
  progressMetrics: ProgressMetric[];
  status: "idle" | "loading" | "succeeded" | "failed";
}

// Define the initial state using that type
const initialState: AppState = {
  progressMetrics: [],
  status: "idle",
};

export const progressMetricsSlice = createSlice({
  name: "progressMetrics",
  initialState,
  reducers: {
    resetProgressMetrics: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProgressMetrics.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchProgressMetrics.fulfilled, (state, action) => {
      console.log("Redux: Got progress metrics");
      state.status = "succeeded";

      const { progress_metrics } = action.payload;

      state.progressMetrics = progress_metrics.sort((a, b) =>
        a.id.localeCompare(b.id),
      );
    });
    builder.addCase(fetchProgressMetrics.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const { resetProgressMetrics } = progressMetricsSlice.actions;

export default progressMetricsSlice.reducer;
