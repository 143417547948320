import { Box, Typography } from "@mui/material";
import { useCampaign } from "@trainwell/features";
import { useParams } from "react-router-dom";
import RestrictAccess from "src/components/misc/RestrictAccess";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { InfluencerSummaryCard } from "../influencers/InfluencerSummaryCard";
import { CampaignCell } from "./CampaignCell";

export function CampaignPage() {
  const { campaignId } = useParams();
  const { data: campaign, isLoading: isLoadingCampaign } = useCampaign({
    campaignId: campaignId ?? "",
  });

  if (isLoadingCampaign) {
    return <LoadingPage message="Loading campaign" />;
  }

  if (!campaign) {
    return <Typography>Error finding campaign</Typography>;
  }

  return (
    <RestrictAccess location={"campaigns"} showNoAccessMessage>
      <Box sx={{ pt: 2, mb: 6, mx: 4 }}>
        <InfluencerSummaryCard
          influencerId={campaign.influencer_id}
          sx={{ mb: 4 }}
        />
        <CampaignCell campaign={campaign} />
      </Box>
    </RestrictAccess>
  );
}
