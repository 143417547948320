import AcUnitRoundedIcon from "@mui/icons-material/AcUnitRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Divider,
  Grid,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import type { Client } from "@trainwell/types";
import { formatDistanceToNow } from "date-fns";
import { useState } from "react";
import { useAppDispatch } from "src/hooks/stateHooks";
import { updateClient } from "src/slices/clientSlice";

type Props = {
  client: Client;
};

export default function ClientStreak({ client }: Props) {
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [streak, setStreak] = useState(client.lifetime_stats.streak ?? 0);
  const [streakFreezes, setStreakFreezes] = useState(
    client.lifetime_stats.streak_freezes ?? 0,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  function cancelEditing() {
    setIsEditing(false);

    setStreak(client.lifetime_stats.streak ?? 0);
    setStreakFreezes(client.lifetime_stats.streak_freezes ?? 0);
  }

  return (
    <Box>
      <Card variant="outlined">
        <CardActionArea
          sx={{
            py: 0.25,
            px: 0.75,
            display: "flex",
            alignItems: "center",
          }}
          onClick={(event) => {
            event.stopPropagation();

            setAnchorEl(event.currentTarget);
          }}
        >
          <Typography>
            🔥 {client.lifetime_stats.streak ?? 0} day
            {client.lifetime_stats.streak === 0 ||
            client.lifetime_stats.streak > 1
              ? "s"
              : ""}{" "}
            |
          </Typography>
          {[0, 1].map((index) => (
            <Avatar
              key={index}
              sx={{
                width: 16,
                height: 16,
                ml: 0.75,
                backgroundColor: (theme) =>
                  client.lifetime_stats.streak_freezes >= index + 1
                    ? theme.palette.blue.main
                    : theme.palette.text.disabled,
              }}
            >
              <AcUnitRoundedIcon sx={{ fontSize: 14 }} />
            </Avatar>
          ))}
        </CardActionArea>
      </Card>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);

          cancelEditing();
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: { p: 1, width: 325 },
          },
        }}
        onBackdropClick={(event) => {
          event.stopPropagation();
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
        onFocus={(event) => {
          event.stopPropagation();
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4.5}>
            {isEditing ? (
              <TextField
                size="small"
                label="Streak"
                value={streak}
                onChange={(event) => {
                  setStreak(Number(event.target.value));
                }}
              />
            ) : (
              <>
                <Typography variant="overline">Current streak</Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  🔥 {client.lifetime_stats.streak ?? 0} day
                  {client.lifetime_stats.streak === 0 ||
                  client.lifetime_stats.streak > 1
                    ? "s"
                    : ""}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={4.5}>
            {isEditing ? (
              <TextField
                size="small"
                label="Streak freezes"
                value={streakFreezes}
                onChange={(event) => {
                  setStreakFreezes(
                    Math.max(Math.min(Number(event.target.value), 2), 0),
                  );
                }}
              />
            ) : (
              <>
                <Typography variant="overline">Streak freezes</Typography>
                <Box sx={{ display: "flex" }}>
                  {[0, 1].map((index) => (
                    <Avatar
                      key={index}
                      sx={{
                        width: 20,
                        height: 20,
                        mr: 0.75,
                        backgroundColor: (theme) =>
                          client.lifetime_stats.streak_freezes >= index + 1
                            ? theme.palette.blue.main
                            : theme.palette.text.disabled,
                      }}
                    >
                      <AcUnitRoundedIcon sx={{ fontSize: 16 }} />
                    </Avatar>
                  ))}
                </Box>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            {isEditing ? (
              <Button
                size="small"
                variant="text"
                startIcon={<SaveRoundedIcon />}
                onClick={() => {
                  dispatch(
                    updateClient({
                      user_id: client.user_id,
                      // @ts-expect-error
                      "lifetime_stats.streak": streak,
                      "lifetime_stats.streak_freezes": streakFreezes,
                    }),
                  );

                  setIsEditing(false);
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                size="small"
                variant="text"
                startIcon={<EditRoundedIcon />}
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                Edit
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            {(client.lifetime_stats.streak_previous ?? 0) > 0 && (
              <>
                <Typography variant="overline">Previous streak</Typography>
                <Typography>
                  <b>
                    {client.lifetime_stats.streak_previous ?? 0} day
                    {client.lifetime_stats.streak_previous === 0 ||
                    client.lifetime_stats.streak_previous > 1
                      ? "s"
                      : ""}
                  </b>
                  {client.lifetime_stats.streak_previous_date ? (
                    <Typography
                      component={"span"}
                      variant="body2"
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      , reset{" "}
                      {formatDistanceToNow(
                        client.lifetime_stats.streak_previous_date,
                      )}{" "}
                      ago
                    </Typography>
                  ) : (
                    ""
                  )}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h2">How streaks work</Typography>
        <Typography>
          Your streak is the number of days you&apos;ve gone without missing a
          workout day a day with a scheduled trainwell workout).
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{
              width: 20,
              height: 20,
              mr: 0.75,
              backgroundColor: (theme) => theme.palette.success.main,
            }}
          >
            <FitnessCenterRoundedIcon sx={{ fontSize: 16 }} />
          </Avatar>
          <Typography fontWeight={"bold"}>Completed</Typography>
          <Avatar
            sx={{
              ml: 2,
              width: 20,
              height: 20,
              mr: 0.75,
              backgroundColor: (theme) => theme.palette.background.paper,
              border: 2,
              borderColor: (theme) => theme.palette.text.disabled,
            }}
          >
            <FitnessCenterRoundedIcon
              sx={{
                fontSize: 16,
                color: (theme) => theme.palette.text.disabled,
              }}
            />
          </Avatar>
          <Typography fontWeight={"bold"}>Upcoming</Typography>
        </Box>
        <Typography>
          On workout days, you can complete any trainwell workout (including
          extras) to keep your streak alive.
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{
              width: 20,
              height: 20,
              mr: 0.75,
              background: (theme) => `linear-gradient(to top left,
                rgba(0,0,0,0) 0%,
                rgba(0,0,0,0) calc(50% - 2px),
                ${theme.palette.background.paper} 50%,
                rgba(0,0,0,0) calc(50% + 2px),
                rgba(0,0,0,0) 100%)`,
              backgroundColor: (theme) => theme.palette.warning.main,
            }}
          >
            <FitnessCenterRoundedIcon sx={{ fontSize: 16 }} />
          </Avatar>
          <Typography fontWeight={"bold"}>Streak in danger</Typography>
        </Box>
        <Typography>
          If a workout day is ending and you haven&apos;t done any trainwell
          workout, you&apos;ll receive a reminder to save your streak.
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{
              width: 20,
              height: 20,
              mr: 0.75,
              backgroundColor: (theme) => theme.palette.blue.main,
            }}
          >
            <AcUnitRoundedIcon sx={{ fontSize: 16 }} />
          </Avatar>
          <Typography fontWeight={"bold"}>Streak freezes</Typography>
        </Box>
        <Typography>
          If you don&apos;t do any trainwell workout on a workout day one of
          your streak freezes will be used to keep your streak.
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{
              width: 20,
              height: 20,
              mr: 0.75,
              background: (theme) => `linear-gradient(to top left,
                rgba(0,0,0,0) 0%,
                rgba(0,0,0,0) calc(50% - 2px),
                ${theme.palette.background.paper} 50%,
                rgba(0,0,0,0) calc(50% + 2px),
                rgba(0,0,0,0) 100%)`,
              backgroundColor: (theme) => theme.palette.error.main,
            }}
          >
            <FitnessCenterRoundedIcon sx={{ fontSize: 16 }} />
          </Avatar>
          <Typography fontWeight={"bold"}>Streak broken</Typography>
        </Box>
        <Typography>
          If you don&apos;t do any trainwell workout on a workout day and you
          have no more streak freezes, you&apos;ll break your streak.
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{
              width: 20,
              height: 20,
              mr: 0.75,
              backgroundColor: (theme) => theme.palette.successSurface.main,
            }}
          >
            <FitnessCenterRoundedIcon
              sx={{
                fontSize: 16,
                color: (theme) => theme.palette.successSurface.main,
              }}
            />
          </Avatar>
          <Typography fontWeight={"bold"}>Completed</Typography>
          <Avatar
            sx={{
              ml: 2,
              width: 20,
              height: 20,
              mr: 0.75,
              backgroundColor: (theme) => theme.palette.background.paper,
              border: 2,
              borderColor: (theme) => theme.palette.text.disabled,
            }}
          >
            <FitnessCenterRoundedIcon
              sx={{
                fontSize: 16,
                color: (theme) => theme.palette.background.paper,
              }}
            />
          </Avatar>
          <Typography fontWeight={"bold"}>Upcoming</Typography>
        </Box>
        <Typography>Enjoy your rest days in between workout days.</Typography>
      </Popover>
    </Box>
  );
}
