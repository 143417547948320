import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import CalendarViewWeekRoundedIcon from "@mui/icons-material/CalendarViewWeekRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {
  Avatar,
  Box,
  CardActionArea,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { memo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { isHabitDayInPast } from "src/lib/habits";
import {
  removeWorkoutFromWeekPlanDay,
  selectClientsWorkoutValidity,
  selectHabitPlanById,
} from "src/slices/clientSlice";
import { selectPhaseById, selectWorkoutById } from "src/slices/phasesSlice";
import WorkoutPreviewPopover from "../WorkoutPreviewPopover";

function animateLayoutChanges(args) {
  const { isSorting, wasSorting } = args;

  if (isSorting || wasSorting) {
    return defaultAnimateLayoutChanges(args);
  }

  return true;
}

type DraggableProps = {
  workoutId: string;
  habitWeekId: string;
  index: number;
  weekPlanId: string;
  dayIndex: number;
};

export default function WorkoutTaskCellDraggable({
  workoutId,
  habitWeekId,
  index,
  weekPlanId,
  dayIndex,
}: DraggableProps) {
  const isInPast = useAppSelector((state) =>
    isHabitDayInPast(selectHabitPlanById(state, weekPlanId)!.date, dayIndex),
  );
  const phaseType = useAppSelector(
    (state) =>
      selectPhaseById(
        state,
        selectWorkoutById(state, workoutId ?? "")?.phase_id ?? "",
      )?.type,
  );

  const draggableId = useAppSelector((state) => {
    return selectHabitPlanById(state, weekPlanId)!.habit_weeks.find(
      (w) => w.id === habitWeekId,
    )!.draggable_ids![dayIndex]![index];
  });

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: draggableId,
    disabled: isInPast,
    data: {
      type: "workout_task",
      workoutId: workoutId,
      dayIndex: dayIndex,
      weekPlanId: weekPlanId,
      isSource: false,
      habitWeekId: habitWeekId,
      index: index,
      phaseType: phaseType,
    },
    animateLayoutChanges,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      sx={{
        opacity: isDragging ? 0.5 : undefined,
      }}
    >
      <WorkoutTaskCell
        workoutId={workoutId}
        weekPlanId={weekPlanId}
        dayIndex={dayIndex}
      />
    </Box>
  );
}

type Props = {
  workoutId: string;
  weekPlanId: string;
  dayIndex: number;
};

const WorkoutTaskCell = memo(function WorkoutTaskCell({
  workoutId,
  weekPlanId,
  dayIndex,
}: Props) {
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const weekPlanDate = useAppSelector(
    (state) => selectHabitPlanById(state, weekPlanId)?.date,
  );
  const workout = useAppSelector((state) =>
    selectWorkoutById(state, workoutId ?? ""),
  );
  const phase = useAppSelector((state) =>
    selectPhaseById(state, workout?.phase_id ?? ""),
  );
  const workoutValidityStatus = useAppSelector(
    (state) => selectClientsWorkoutValidity(state, workoutId ?? "")?.status,
  );

  if (!weekPlanDate) {
    return <Typography>Error habit week plan</Typography>;
  }

  const isInPast = isHabitDayInPast(weekPlanDate, dayIndex);

  if (!workout) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", p: 0.5 }}>
        <CircularProgress size={14} />
        {!isInPast && (
          <IconButton
            size="small"
            color="error"
            onClick={(event) => {
              event.stopPropagation();

              dispatch(
                removeWorkoutFromWeekPlanDay({
                  weekPlanId: weekPlanId!,
                  dayIndex: dayIndex!,
                  workoutId: workoutId,
                }),
              );
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          >
            <RemoveCircleRoundedIcon sx={{ fontSize: "12px" }} />
          </IconButton>
        )}
      </Box>
    );
  }

  return (
    <>
      <CardActionArea
        onClick={() => {
          navigate(`/clients/${userId}/workouts/${workoutId}`);
        }}
        onContextMenu={(event) => {
          event.preventDefault();

          setAnchorEl(event.currentTarget);
        }}
        sx={{
          backgroundColor: (theme) =>
            !workout.metadata.date_last_opened ||
            workoutValidityStatus === "error"
              ? theme.palette.errorSurface.main
              : undefined,
          p: 0.5,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ flex: 1 }}>
          {phase?.type === "multiple" && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                sx={{
                  backgroundColor: (theme) => theme.palette.success.main,
                  mr: 0.25,
                  width: 8,
                  height: 8,
                  borderRadius: "2px",
                  opacity: 0.75,
                }}
              >
                <CalendarViewWeekRoundedIcon
                  sx={{
                    fontSize: 6,
                  }}
                />
              </Avatar>
              <Typography
                sx={{
                  fontSize: 9,
                  lineHeight: 1,
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {phase.name}
              </Typography>
            </Box>
          )}
          <Typography variant="body2">{workout.name}</Typography>
        </Box>
        {!isInPast && (
          <IconButton
            size="small"
            color="error"
            onClick={(event) => {
              event.stopPropagation();

              dispatch(
                removeWorkoutFromWeekPlanDay({
                  weekPlanId: weekPlanId!,
                  dayIndex: dayIndex!,
                  workoutId: workoutId,
                }),
              );
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            sx={{
              ml: 0.25,
            }}
          >
            <RemoveCircleRoundedIcon sx={{ fontSize: "12px" }} />
          </IconButton>
        )}
      </CardActionArea>
      {anchorEl && (
        <WorkoutPreviewPopover
          anchorEl={anchorEl}
          workoutId={workoutId}
          onClose={() => {
            setAnchorEl(null);
          }}
        />
      )}
    </>
  );
});
