import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import SupportRoundedIcon from "@mui/icons-material/SupportRounded";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import type { Message } from "@trainwell/types";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getChatFromTicket } from "src/lib/chat";
import { getTrainerName } from "src/lib/coachUtility";
import { api } from "src/lib/trainwellApi";
import { addChat, markMessageAsTicketed } from "src/slices/chatSlice";
import { selectAllVisibleClients } from "src/slices/clientsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { DialogTitleWithClose } from "./DialogTitleWithClose";

type Props = {
  open: boolean;
  onClose: () => void;
  userId?: string;
  logId?: string;
  attachedMessage?: Message;
};

export default function SubmitTicketDialog({
  open,
  onClose,
  userId,
  logId,
  attachedMessage,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const selectedTicketType = "customer_support";
  const [ticketText, setTicketText] = useState("");
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>();
  const clients = useAppSelector(selectAllVisibleClients);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);

  function handleClose() {
    onClose();
    setTicketText("");
    setSelectedUserId(undefined);
  }

  const clientNames = clients.map((client) => {
    return {
      user_id: client.user_id,
      name: client.full_name,
    };
  });

  clientNames.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  return (
    <Dialog
      onClose={() => {
        if (loading) {
          return;
        }

        handleClose();
      }}
      open={open}
    >
      <DialogTitleWithClose
        onClose={() => {
          if (loading) {
            return;
          }

          handleClose();
        }}
      >
        Send support ticket
      </DialogTitleWithClose>
      <DialogContent sx={{ minWidth: "400px" }}>
        {!selectedTicketType ? (
          <>
            <Typography sx={{ mb: 2 }}>
              Send a ticket directly to the support and the tech team.
            </Typography>
            <List sx={{ pt: 0 }}>
              <ListItem
                button
                onClick={() => {
                  // setSelectedTicketType("tech_issue");
                }}
                sx={{ borderRadius: 1 }}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      bgcolor: (theme) => theme.palette.backgroundTertiary.main,
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <BugReportRoundedIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Tech issue" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  // setSelectedTicketType("customer_support");
                }}
                sx={{ borderRadius: 1 }}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      bgcolor: (theme) => theme.palette.backgroundTertiary.main,
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <SupportAgentRoundedIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Customer support" />
              </ListItem>
            </List>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="text"
                autoFocus
                onClick={() => {
                  if (loading) {
                    return;
                  }

                  onClose();
                }}
              >
                Cancel
              </Button>
            </Box>
          </>
        ) : (
          <>
            <TextField
              fullWidth
              label={!attachedMessage ? "Message" : "Message (optional)"}
              value={ticketText}
              multiline
              onChange={(event) => {
                setTicketText(event.target.value);
              }}
              sx={{ mb: 2, mt: 1 }}
            />
            {!userId && (
              <Autocomplete
                fullWidth
                options={clientNames}
                onChange={(event, value) => {
                  setSelectedUserId(value?.user_id);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client (optional)"
                    placeholder="Select a client"
                  />
                )}
                sx={{ mb: 3 }}
              />
            )}
            {attachedMessage ? (
              <Card variant="outlined" sx={{ p: 1, mb: 3 }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Message attached
                </Typography>
                {attachedMessage?.text ? (
                  <Typography>{attachedMessage.text}</Typography>
                ) : (
                  <img
                    width={200}
                    height={200}
                    src={attachedMessage?.media_url || ""}
                    alt=""
                    style={{ borderRadius: "8px", objectFit: "cover" }}
                  />
                )}
              </Card>
            ) : logId ? (
              <Typography sx={{ fontWeight: "bold" }}>
                Workout log attached
              </Typography>
            ) : (
              <></>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="text"
                onClick={() => {
                  if (loading) {
                    return;
                  }

                  handleClose();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                autoFocus
                startIcon={<SupportRoundedIcon />}
                onClick={() => {
                  setLoading(true);

                  api.tickets
                    .create({
                      type: selectedTicketType,
                      userId: userId ?? selectedUserId,
                      messageCoach: ticketText,
                      trainerId: trainer!.trainer_id,
                      messageId: attachedMessage?.message_id,
                      logId: logId,
                    })
                    .then((ticketChat) => {
                      setLoading(false);

                      if (attachedMessage) {
                        dispatch(
                          markMessageAsTicketed({
                            messageId: attachedMessage.message_id,
                            userId: attachedMessage.from_id,
                            ticketId: ticketChat.support_ticket_id!,
                          }),
                        );
                      }

                      const newChat = getChatFromTicket(
                        ticketChat,
                        getTrainerName(
                          ticketChat.support_ticket.trainer_id,
                          trainerNames,
                        ),
                        trainer!.trainer_id,
                      );

                      dispatch(addChat(newChat));

                      handleClose();
                    })
                    .catch(() => {
                      setLoading(false);

                      enqueueSnackbar("Error sending ticket", {
                        variant: "error",
                      });
                    });
                }}
                disabled={
                  !attachedMessage &&
                  (!ticketText || !ticketText.replace(/\s/g, "").length)
                }
                sx={{ ml: 2 }}
              >
                Send ticket
              </LoadingButton>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
