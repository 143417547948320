import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Company } from "@trainwell/types";
import { api } from "src/lib/trainwellApi";

export const fetchCompany = createAsyncThunk(
  "company/fetchCompany",
  async () => {
    const companyPromise = api.company.getOne();
    const compStructurePromise = api.company.getCompStructure();

    const [company, compStructure] = await Promise.all([
      companyPromise,
      compStructurePromise,
    ]);

    return {
      company,
      compStructure,
    };
  },
);

export const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async (data: Partial<Company>) => {
    const response = await api.company.updateOne(data);

    return response;
  },
);

// Define a type for the slice state
interface TicketsState {
  company: Company | null;
  compStructure: Awaited<
    ReturnType<(typeof api)["company"]["getCompStructure"]>
  > | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
}

// Define the initial state using that type
const initialState: TicketsState = {
  company: null,
  compStructure: null,
  status: "idle",
  error: undefined,
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    resetCompany: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompany.pending, (state) => {
      state.status = "loading";
      state.company = null;
    });
    builder.addCase(fetchCompany.fulfilled, (state, action) => {
      console.log("Redux: Got company");
      state.status = "succeeded";

      const { company, compStructure } = action.payload;

      state.company = company;
      state.compStructure = compStructure;
    });
    builder.addCase(fetchCompany.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      const newCompany = action.payload;

      state.company = newCompany;
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetCompany } = companySlice.actions;

export default companySlice.reducer;
