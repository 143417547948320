import DirectionsRunRoundedIcon from "@mui/icons-material/DirectionsRunRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { Avatar, Box, Tooltip } from "@mui/material";
import type React from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectClientById } from "src/slices/clientsSlice";

type Props = {
  userId: string;
  dimension: number;
} & Omit<
  React.HTMLProps<HTMLImageElement>,
  "src" | "alt" | "width" | "height" | "style"
>;

export default function ClientProfilePicture({
  userId,
  dimension,
  ...props
}: Props) {
  const client = useAppSelector((state) => selectClientById(state, userId));
  const clientInfo = useAppSelector((state) => state.clients.clientInfo);
  let state: "none" | "in_workout" | "upcoming_workout" = "none";

  if (client?.current_workout?.workout_id) {
    state = "in_workout";
  } else if (client && clientInfo[client.user_id]?.daysUntilNextWorkout === 0) {
    state = "upcoming_workout";
  }

  const iconScale = 0.5;

  return (
    <Box sx={{ position: "relative" }}>
      <img
        style={{
          borderRadius: `${Math.round(dimension / 2)}px`,
          overflow: "hidden",
          display: "block",
        }}
        width={dimension}
        height={dimension}
        src={client?.headshot_url || "/assets/profile.png"}
        alt="client profile picture"
        {...props}
      />
      {state === "in_workout" ? (
        <Tooltip disableInteractive placement="right" title="In a workout">
          <Avatar
            sx={{
              position: "absolute",
              top: "51%",
              left: "51%",
              margin: `-${Math.round(dimension * iconScale * 0.5)}px`,
              width: `${Math.round(dimension * iconScale)}px`,
              height: `${Math.round(dimension * iconScale)}px`,
              borderRadius: `${Math.round(dimension * iconScale * 0.5)}px`,
              transform: `rotate(45deg) translate(${Math.round(
                dimension / 2,
              )}px) rotate(-45deg)`,
              backgroundColor: "#a3e635",
              border: 1,
              borderColor: (theme) => theme.palette.background.paper,
            }}
          >
            <DirectionsRunRoundedIcon
              sx={{
                fontSize: 12,
                color: (theme) => theme.palette.text.primary,
              }}
            />
          </Avatar>
        </Tooltip>
      ) : state === "upcoming_workout" ? (
        <Tooltip
          disableInteractive
          placement="right"
          title="Upcoming workout today"
        >
          <Avatar
            sx={{
              position: "absolute",
              top: "51%",
              left: "51%",
              margin: `-${Math.round(dimension * iconScale * 0.5)}px`,
              width: `${Math.round(dimension * iconScale)}px`,
              height: `${Math.round(dimension * iconScale)}px`,
              borderRadius: `${Math.round(dimension * iconScale * 0.5)}px`,
              transform: `rotate(45deg) translate(${Math.round(
                dimension / 2,
              )}px) rotate(-45deg)`,
              backgroundColor: (theme) => theme.palette.text.disabled,
              border: dimension * 0.0625,
              borderColor: (theme) => theme.palette.background.paper,
            }}
          >
            <MoreHorizRoundedIcon sx={{ fontSize: dimension / 3 }} />
          </Avatar>
        </Tooltip>
      ) : (
        <></>
      )}
    </Box>
  );
}
