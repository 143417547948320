import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";
import { Box, Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import type { Ticket } from "@trainwell/types";
import { useEffect, useState } from "react";
import RestrictAccess from "src/components/misc/RestrictAccess";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  fetchMoreTickets,
  fetchTicketCategories,
  fetchTickets,
} from "src/slices/ticketsSlice";
import TicketCell from "./TicketCell";

type Props = {
  type: Ticket["type"];
};

export default function TicketsPage({ type }: Props) {
  const dispatch = useAppDispatch();
  const ticketIds = useAppSelector((state) => state.tickets.tickets.ids);
  const [currentTab, setCurrentTab] = useState<Ticket["state"]>("none");
  const ticketCategoriesStatus = useAppSelector(
    (state) => state.tickets.categoriesStatus,
  );

  useEffect(() => {
    if (ticketCategoriesStatus === "idle") {
      dispatch(fetchTicketCategories());
    }
  }, [ticketCategoriesStatus, dispatch]);

  useEffect(() => {
    dispatch(fetchTickets({ type: type, state: currentTab }));
  }, [currentTab, type]);

  return (
    <RestrictAccess location={"tickets"} showNoAccessMessage>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            mb: 2,
            pt: 3,
          }}
        >
          <Typography variant="h1">Tickets</Typography>
        </Box>
        <Tabs
          value={currentTab}
          onChange={(_, newValue) => {
            setCurrentTab(newValue);
          }}
          aria-label="client tabs"
        >
          <Tab label="New" value="none" />
          <Tab label="In progress" value="in_progress" />
          <Tab label="Done" value="done" />
        </Tabs>
      </Box>
      {ticketIds.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            my: 4,
          }}
        >
          <Typography sx={{ mb: 2 }}>No tickets</Typography>
          <CelebrationRoundedIcon />
        </Box>
      ) : (
        <>
          <Stack
            direction="column"
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          >
            {ticketIds.map((ticketId) => (
              <TicketCell key={ticketId} ticketId={ticketId} />
            ))}
          </Stack>
          <Box sx={{ my: 4, display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => {
                dispatch(fetchMoreTickets({ type: type, state: currentTab }));
              }}
            >
              Load more
            </Button>
          </Box>
        </>
      )}
    </RestrictAccess>
  );
}
