import { lazy, useEffect, useState } from "react";
import RestrictAccess from "src/components/misc/RestrictAccess";
import { api } from "src/lib/trainwellApi";
import "swagger-ui-react/swagger-ui.css";
const SwaggerUI = lazy(() => import("swagger-ui-react"));

export default function DocsPythonPage() {
  const [apiSpec, setApiSpec] = useState<any>();

  useEffect(() => {
    async function run() {
      const specs = await api.docs.getPython();

      setApiSpec(specs);
    }

    run();
  }, []);

  return (
    <RestrictAccess location={"docs"} showNoAccessMessage>
      <SwaggerUI spec={apiSpec} />
    </RestrictAccess>
  );
}
