import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Tab, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { fetchTrainerEdit } from "src/slices/trainerEditSlice";
import { CoachMoreButton } from "./CoachMoreButton";
import { CoachProfilePictureEdit } from "./CoachProfilePictureEdit";
import TabAudits from "./TabAudits";
import TabInfo from "./TabInfo";
import { TabMetrics } from "./TabMetrics";
import TabVacations from "./TabVacations";

export function CoachPage() {
  const { trainerId } = useParams();
  const dispatch = useAppDispatch();
  const trainerEditStatus = useAppSelector((state) => state.trainerEdit.status);
  const trainerEdit = useAppSelector((state) => state.trainerEdit.trainer);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get("tab") ?? "info";

  useEffect(() => {
    if (
      trainerId &&
      ((trainerEditStatus === "idle" && trainerId) ||
        (trainerEdit?.trainer_id && trainerEdit.trainer_id !== trainerId))
    ) {
      dispatch(fetchTrainerEdit(trainerId));
    }
  }, [trainerEditStatus, dispatch, trainerId, trainerEdit?.trainer_id]);

  if (!trainerEdit) {
    return <LoadingPage message="Getting your trainer" />;
  }

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          height: "100%",
          overflow: "auto",
          pt: 2,
          mb: 6,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 1,
            mx: 4,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CoachProfilePictureEdit />
            <Box sx={{ ml: 2 }}>
              <Typography variant="h1">{trainerEdit.full_name}</Typography>
              <Typography
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                Trainer id: {trainerEdit.trainer_id}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="text"
              endIcon={<LoginRoundedIcon />}
              href={`/?trainer_id=${trainerEdit.trainer_id}`}
              target="_blank"
              LinkComponent={"a"}
            >
              Ghost
            </Button>
            <CoachMoreButton />
          </Box>
        </Box>
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
            <TabList
              onChange={(event, newValue) => {
                searchParams.set("tab", newValue);
                setSearchParams(searchParams);
              }}
              aria-label="trainer tabs"
              sx={{
                mx: 4,
                minHeight: 32,
              }}
            >
              <Tab label="Info" value="info" sx={{ py: 1, minHeight: 32 }} />
              <Tab
                label="Vacations"
                value="vacations"
                sx={{ py: 1, minHeight: 32 }}
              />
              <Tab
                label="Audits"
                value="audits"
                sx={{ py: 1, minHeight: 32 }}
              />
              <Tab
                label="Metrics"
                value="metrics"
                sx={{ py: 1, minHeight: 32 }}
              />
            </TabList>
          </Box>
          <TabPanel value="info" sx={{ p: 0, mx: 4 }}>
            <TabInfo />
          </TabPanel>
          <TabPanel value="vacations" sx={{ p: 0, mx: 4 }}>
            <TabVacations />
          </TabPanel>
          <TabPanel value="audits" sx={{ p: 0, mx: 4 }}>
            <TabAudits />
          </TabPanel>
          <TabPanel value="metrics" sx={{ p: 0 }}>
            <TabMetrics />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}
