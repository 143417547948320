import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import EmojiEmotionsRoundedIcon from "@mui/icons-material/EmojiEmotionsRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import VideoCallRoundedIcon from "@mui/icons-material/VideoCallRounded";
import VideoLibraryRoundedIcon from "@mui/icons-material/VideoLibraryRounded";
import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Popover,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import type { KeyboardEvent } from "react";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  selectMessageByChatId,
  selectSelectedChat,
  sendTextMessage,
  sendVideoMessage,
  setMediaUploadUi,
  setMessage,
} from "src/slices/chatSlice";
import CoachMediaDialog from "./CoachMediaDialog";

export default function ChatMessageBar() {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isPwa = useAppSelector((state) => state.app.pwa);
  const selectedChat = useAppSelector(selectSelectedChat);
  const chatRef = useRef(selectedChat);
  const message = useAppSelector((state) =>
    selectedChat ? selectMessageByChatId(state, selectedChat.id) : "",
  );
  const messageRef = useRef(message);
  const textfieldRef = useRef<HTMLInputElement>();
  const [emojiPickerAnchorEl, setEmojiPickerAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const isEmojiPickerOpen = Boolean(emojiPickerAnchorEl);
  const chatVisualState = useAppSelector((state) => state.chat.chatMode);
  const socketConnected = useAppSelector((state) => state.app.socketConnected);
  const isFullscreen =
    chatVisualState === "big_left" || chatVisualState === "big_right";
  const [sending, setSending] = useState(false);
  const [coachMediaDialogOpen, setCoachMediaDialogOpen] = useState(false);

  function closeEmojiPicker() {
    setEmojiPickerAnchorEl(null);
  }

  const addEmoji = (emoji: any) => {
    if ("native" in emoji) {
      const position = textfieldRef.current?.selectionStart;

      let newMessage = message;

      if (position !== null && position !== undefined) {
        newMessage =
          newMessage.substring(0, position) +
          emoji.native +
          newMessage.substring(position);
      } else {
        newMessage = newMessage + emoji.native;
      }

      dispatch(
        setMessage({ message: newMessage, chatId: chatRef.current!.id }),
      );
      closeEmojiPicker();
    }
  };

  useEffect(() => {
    messageRef.current = message;
  }, [message]);

  useEffect(() => {
    chatRef.current = selectedChat;
  }, [selectedChat]);

  useEffect(() => {
    if (isPwa) {
      return;
    }

    textfieldRef.current?.focus();
  }, [sending]);

  function sendMessage() {
    setSending(true);

    dispatch(
      sendTextMessage({
        userId: chatRef.current!.id,
        text: messageRef.current,
        toGroup: chatRef.current!.isGroupChat,
        asTrainwell: chatRef.current!.isTrainwell ?? false,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(setMessage({ chatId: chatRef.current!.id, message: "" }));
        setSending(false);
      })
      .catch(() => {
        enqueueSnackbar("Message failed to send", {
          variant: "error",
        });
        setSending(false);
      });
  }

  function onKeyDown(
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const key = event.key;

    if (
      key === "Enter" &&
      !(event.shiftKey || event.ctrlKey || event.altKey || event.metaKey) &&
      chatVisualState !== "full"
    ) {
      event.preventDefault();

      if (messageRef.current.length > 0) {
        sendMessage();
      }
    }
  }

  const chatDisabled =
    !socketConnected ||
    Boolean(selectedChat?.forceTrainerId) ||
    sending ||
    selectedChat?.forceDisabled;

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        borderTop: 1,
        borderColor: "divider",
      }}
    >
      {!socketConnected && (
        <Typography
          variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
            textAlign: "center",
          }}
        >
          Chat server connection lost. Trying to reconnect
        </Typography>
      )}

      <Grid
        container
        sx={{
          alignItems: "center",
          p: isFullscreen ? 1 : 0.5,
          pb: chatVisualState === "full" ? 6 : undefined,
        }}
        spacing={chatVisualState === "full" || isFullscreen ? 1 : 0}
      >
        {isFullscreen ? (
          <>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  dispatch(setMediaUploadUi("show"));
                }}
                size="small"
                disabled={chatDisabled}
              >
                <VideoCallRoundedIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                disabled={chatDisabled}
                onClick={() => {
                  setCoachMediaDialogOpen(true);
                }}
                sx={{ ml: 1 }}
              >
                <VideoLibraryRoundedIcon fontSize="small" />
              </IconButton>
              <IconButton
                onClick={(event) => {
                  setEmojiPickerAnchorEl(event.currentTarget);
                }}
                size="small"
                disabled={chatDisabled}
                sx={{ ml: 1 }}
              >
                <EmojiEmotionsRoundedIcon fontSize="small" />
              </IconButton>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  dispatch(setMediaUploadUi("show"));
                }}
                size="small"
                disabled={chatDisabled}
              >
                <VideoCallRoundedIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                disabled={chatDisabled}
                onClick={() => {
                  setCoachMediaDialogOpen(true);
                }}
              >
                <VideoLibraryRoundedIcon fontSize="small" />
              </IconButton>
            </Grid>
            {chatVisualState !== "full" && (
              <Grid item>
                <IconButton
                  onClick={(event) => {
                    setEmojiPickerAnchorEl(event.currentTarget);
                  }}
                  size="small"
                  disabled={chatDisabled}
                >
                  <EmojiEmotionsRoundedIcon fontSize="small" />
                </IconButton>
              </Grid>
            )}
          </>
        )}
        <Grid
          item
          xs={isFullscreen ? 12 : true}
          order={isFullscreen ? 4 : undefined}
        >
          <Box
            sx={{
              overflow: "auto",
              backgroundColor: (theme) => theme.palette.background.default,
              borderRadius: 1,
              maxHeight:
                chatVisualState === "big_left" ||
                chatVisualState === "big_right"
                  ? "500px"
                  : "300px",
              border: 1,
              borderColor: "divider",
            }}
          >
            <InputBase
              inputRef={textfieldRef}
              value={message}
              autoFocus={isPwa ? false : true}
              sx={{ p: 0.5 }}
              fullWidth
              placeholder={"Message"}
              multiline={true}
              minRows={2}
              onChange={(e) => {
                if (selectedChat) {
                  dispatch(
                    setMessage({
                      message: e.target.value,
                      chatId: selectedChat.id,
                    }),
                  );
                }
              }}
              onKeyDown={(event) => {
                onKeyDown(event);
              }}
              disabled={chatDisabled}
              name="chat_input"
            />
          </Box>
        </Grid>
        {chatVisualState === "full" && (
          <Grid item>
            <IconButton
              onClick={() => {
                sendMessage();
              }}
              size="small"
              disabled={chatDisabled}
            >
              <SendRoundedIcon fontSize="small" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Popover
        open={isEmojiPickerOpen}
        anchorEl={emojiPickerAnchorEl}
        onClose={closeEmojiPicker}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{ zIndex: 1260 }}
      >
        <Box>
          <Picker data={data} onEmojiSelect={addEmoji} />
        </Box>
      </Popover>
      <CoachMediaDialog
        open={coachMediaDialogOpen}
        onClose={(coachMedia) => {
          if (coachMedia) {
            dispatch(
              sendVideoMessage({
                video_url: coachMedia.video_url,
                thumbnail_url: coachMedia.thumbnail_url,
                userId: chatRef.current!.id,
                toGroup: chatRef.current!.isGroupChat,
                asTrainwell: chatRef.current!.isTrainwell ?? false,
                width: coachMedia.width,
                height: coachMedia.height,
              }),
            )
              .unwrap()
              .catch(() => {
                enqueueSnackbar("Video failed to send", {
                  variant: "error",
                });
              });
          }

          setCoachMediaDialogOpen(false);
        }}
      />
    </Box>
  );
}
