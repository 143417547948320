import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, IconButton, Tab, Typography } from "@mui/material";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import RestrictAccess from "src/components/misc/RestrictAccess";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { archiveTest, selectTestById } from "src/slices/testsSlice";
import { TestEditDialog } from "./TestEditDialog";
import { TestEvents } from "./TestEvents";
import { TestInfo } from "./TestInfo";

export function TestPage() {
  const { testId } = useParams();
  const dispatch = useAppDispatch();
  const test = useAppSelector((state) => selectTestById(state, testId ?? ""));
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get("tab") ?? "info";

  if (!test) {
    return <Typography>Error finding test</Typography>;
  }

  return (
    <RestrictAccess location={"tests"} showNoAccessMessage>
      <Box sx={{ pt: 2, mb: 6 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 1,
            mx: 4,
          }}
        >
          <Box>
            <Typography variant="h1">{test.name}</Typography>
            <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>
              Test id: {test.id}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", ml: 4 }}>
            <Button
              size="small"
              startIcon={<EditRoundedIcon />}
              onClick={() => {
                setEditDialogOpen(true);
              }}
              disabled={Boolean(test.date_archived)}
            >
              Edit
            </Button>
            <IconButton
              color="error"
              onClick={(event) => {
                event.stopPropagation();
                dispatch(archiveTest(testId!));
              }}
              sx={{ ml: 2 }}
              disabled={Boolean(test.date_archived)}
            >
              <ArchiveRoundedIcon sx={{ fontSize: "inherit" }} />
            </IconButton>
          </Box>
        </Box>
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
            <TabList
              onChange={(event, newValue) => {
                searchParams.set("tab", newValue);
                setSearchParams(searchParams);
              }}
              aria-label="trainer tabs"
              sx={{
                mx: 4,
                minHeight: 32,
              }}
            >
              <Tab label="Info" value="info" sx={{ py: 1, minHeight: 32 }} />
              <Tab
                label="Events"
                value="events"
                sx={{ py: 1, minHeight: 32 }}
              />
            </TabList>
          </Box>
          <TabPanel value="info" sx={{ p: 0, mx: 4 }}>
            <TestInfo />
          </TabPanel>
          <TabPanel value="events" sx={{ p: 0, mx: 4 }}>
            <TestEvents />
          </TabPanel>
        </TabContext>
      </Box>
      <TestEditDialog
        open={editDialogOpen}
        defaultTest={test}
        onClose={() => {
          setEditDialogOpen(false);
        }}
      />
    </RestrictAccess>
  );
}
