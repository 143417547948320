import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";
import { Box, Stack, Typography } from "@mui/material";
import type { MessagePhaseExplanation } from "@trainwell/types";
import { format } from "date-fns";

type Props = {
  message: MessagePhaseExplanation;
  combineBottom: boolean;
};

export default function PhaseExplanationMessage({
  message,
  combineBottom,
}: Props) {
  return (
    <>
      <Box
        sx={{
          maxWidth: "300px",
          borderRadius: `10px 10px ${!combineBottom ? "0px" : "10px"} 10px`,
          overflow: "hidden",
          backgroundColor: (theme) => theme.palette.background.default,
          p: 1,
          display: "flex",
          alignItems: "center",
          border: 1,
          borderColor: "divider",
        }}
      >
        <Stack direction={"column"} spacing={1}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CelebrationRoundedIcon />
            <Box sx={{ ml: 2 }}>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                Phase explanation
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {format(
                  new Date(message.send_date as string),
                  "MMM do, yyyy h:mm a",
                )}
              </Typography>
            </Box>
          </Box>
          <video
            controls
            height={150}
            style={{
              width: "100%",
              borderRadius: "10px",
              display: "block",
            }}
          >
            <source src={message.phase_explanation.video_url} />
          </video>
        </Stack>
      </Box>
    </>
  );
}
