import {
  DndContext,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  closestCenter,
  pointerWithin,
  useSensor,
  useSensors,
  type CollisionDetection,
  type UniqueIdentifier,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import ControlBar from "src/components/misc/ControlBar";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  createWorkoutTemplate,
  setCurrentDraggingInfo,
  updateLocalPhaseTemplate,
  updatePhaseTemplate,
  updatePhaseTemplateEditing,
  updateTemplateLibraryFolder,
  type DndTypeTemplateLibrary,
} from "src/slices/phaseTemplatesSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import FolderPage from "./FolderPage";
import LibraryDragOverlay from "./LibraryDragOverlay";
import PhaseTemplateBuilder from "./PhaseTemplateBuilder/PhaseTemplateBuilder";
import { Sidebar } from "./Sidebar/Sidebar";
import TagPage from "./TagPage";
import TemplateLibraryHomePage from "./TemplateLibraryHomePage";

const leftDrawerWidth = 260;
const rightDrawerWidth = 320;

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "openLeft" && prop !== "openRight",
})<{
  openLeft?: boolean;
  openRight?: boolean;
}>(({ theme, openLeft, openRight }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${leftDrawerWidth}px`,
  marginRight: `-${rightDrawerWidth}px`,
  ...(openLeft && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  ...(openRight && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
  /**
   * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
   * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
   * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
   * proper interaction with the underlying content.
   */
  position: "relative",
}));

export default function TemplateLibraryLayout() {
  const dispatch = useAppDispatch();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const allPhaseTemplates = useAppSelector(
    (state) => state.phaseTemplates.phaseTemplates,
  );
  const openFolderId = useAppSelector(
    (state) => state.phaseTemplates.openFolderId,
  );
  const openTagId = useAppSelector((state) => state.phaseTemplates.openTagId);
  const openForUserId = useAppSelector(
    (state) => state.phaseTemplates.openForUserId,
  );
  const isDialog = Boolean(openForUserId);
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)!.trainer_id,
  );
  const phaseTemplateEditing = useAppSelector(
    (state) => state.phaseTemplates.phaseTemplateEditing,
  );
  const activeId = useAppSelector(
    (state) => state.phaseTemplates.currentDraggingInfo?.id,
  );

  // dnd kit stuff
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor),
  );

  const items = useMemo(() => {
    return (
      phaseTemplateEditing?.days_draggable.reduce(
        (acc, day) => {
          acc[day.draggable_id] = day.templates;
          return acc;
        },
        {} as Record<string, { draggable_id: string; template_id: string }[]>,
      ) ?? {}
    );
  }, [phaseTemplateEditing?.days_draggable]);

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback((args) => {
    const activeType = args.active.data.current?.type as DndTypeTemplateLibrary;

    let validDroppableContainers = args.droppableContainers;

    if (activeType === "phase_template") {
      validDroppableContainers = validDroppableContainers.filter(
        (c) =>
          (c.data.current?.type as DndTypeTemplateLibrary) ===
            "folder_sidebar" ||
          (c.data.current?.type as DndTypeTemplateLibrary) ===
            "folder_droppable",
      );
    } else if (activeType === "workout_template") {
      validDroppableContainers = validDroppableContainers.filter(
        (c) =>
          (c.data.current?.type as DndTypeTemplateLibrary) ===
            "folder_sidebar" ||
          (c.data.current?.type as DndTypeTemplateLibrary) ===
            "folder_droppable" ||
          (c.data.current?.type as DndTypeTemplateLibrary) ===
            "phase_template_workout_template" ||
          (c.data.current?.type as DndTypeTemplateLibrary) ===
            "phase_template_day",
      );
    } else if (activeType === "folder_draggable") {
      validDroppableContainers = validDroppableContainers.filter(
        (c) =>
          ((c.data.current?.type as DndTypeTemplateLibrary) ===
            "folder_sidebar" ||
            (c.data.current?.type as DndTypeTemplateLibrary) ===
              "folder_droppable") &&
          c.data.current?.folderId !== args.active.data.current?.folderId,
      );
    } else if (activeType === "phase_template_workout_template") {
      validDroppableContainers = validDroppableContainers.filter(
        (c) =>
          (c.data.current?.type as DndTypeTemplateLibrary) ===
            "phase_template_workout_template" ||
          (c.data.current?.type as DndTypeTemplateLibrary) ===
            "phase_template_day" ||
          (c.data.current?.type as DndTypeTemplateLibrary) === "page",
      );
    } else if (activeType === "phase_template_day") {
      validDroppableContainers = validDroppableContainers.filter(
        (c) =>
          (c.data.current?.type as DndTypeTemplateLibrary) ===
            "phase_template_day" && c.id !== "new_day",
      );
    }

    // Phase template days
    if (activeType === "phase_template_day") {
      return closestCenter({
        ...args,
        droppableContainers: validDroppableContainers,
      });
    }

    return pointerWithin({
      ...args,
      droppableContainers: validDroppableContainers,
    });
  }, []);

  const findContainer = (id: UniqueIdentifier) => {
    if (!items) {
      return id;
    }

    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) =>
      items[key].map((i) => i.draggable_id).includes(id.toString()),
    );
  };

  return (
    <DndContext
      collisionDetection={collisionDetectionStrategy}
      sensors={sensors}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        dispatch(
          setCurrentDraggingInfo({
            id: active.id.toString(),
            originalType: active.data.current?.type,
            phaseTemplateId: active.data.current?.phaseTemplateId,
            workoutTemplateId: active.data.current?.workoutTemplateId,
            folderId: active.data.current?.folderId,
          }),
        );
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id;

        if (!items) {
          return;
        }

        if (over?.data.current?.type === "folder_sidebar") {
          return;
        }

        // const itemId = active.id.toString().startsWith("workout_template_card.")
        //   ? active.id.toString().split(".")[1]
        //   : active.id.toString();
        const itemId = active.id.toString();

        if (overId == null || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(itemId);

        // if (overContainer && items[overContainer.toString()].includes(itemId)) {
        //   return;
        // }

        if (overContainer && !activeContainer) {
          // Dragging workout from left into phase
          const newItems = {
            ...items,
          };

          const overItems = newItems[overContainer];
          const overIndex = overItems
            .map((i) => i.draggable_id)
            .indexOf(overId.toString());

          let newIndex: number;

          if (overId in items) {
            newIndex = overItems.length + 1;
          } else {
            const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top >
                over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;

            newIndex =
              overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
          }

          newItems[overContainer.toString()] = newItems[
            overContainer.toString()
          ].filter((item) => item.draggable_id !== itemId);

          newItems[overContainer.toString()] = [
            ...newItems[overContainer].slice(0, newIndex),
            {
              draggable_id: itemId,
              template_id: active.data.current?.workoutTemplateId,
            },
            ...newItems[overContainer].slice(
              newIndex,
              newItems[overContainer].length,
            ),
          ];

          const newDays = Object.keys(newItems).map((key) => ({
            draggable_id: key,
            templates: newItems[key],
          }));

          dispatch(
            updatePhaseTemplateEditing({
              days_draggable: newDays,
            }),
          );

          return;
        }

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          // Dragging worout between phase days

          const newItems = {
            ...items,
          };

          const activeItems = newItems[activeContainer];
          const overItems = newItems[overContainer];
          const overIndex = overItems
            .map((i) => i.draggable_id)
            .indexOf(overId.toString());
          const activeIndex = activeItems
            .map((i) => i.draggable_id)
            .indexOf(itemId);

          let newIndex: number;

          if (overId in items) {
            newIndex = overItems.length + 1;
          } else {
            const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top >
                over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;

            newIndex =
              overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
          }

          newItems[activeContainer.toString()] = items[activeContainer].filter(
            (item) => item.draggable_id !== itemId,
          );

          newItems[overContainer.toString()] = [
            ...items[overContainer].slice(0, newIndex),
            items[activeContainer][activeIndex],
            ...items[overContainer].slice(
              newIndex,
              items[overContainer].length,
            ),
          ];

          const newDays = Object.keys(newItems).map((key) => ({
            draggable_id: key,
            templates: newItems[key],
          }));

          dispatch(
            updatePhaseTemplateEditing({
              days_draggable: newDays,
            }),
          );
        }
      }}
      onDragEnd={({ active, over }) => {
        const activeType = active.data.current?.type as DndTypeTemplateLibrary;

        const overId = over?.id.toString();
        const overType = over?.data.current?.type as DndTypeTemplateLibrary;

        const sourceWorkoutTemplateId = active.data.current
          ?.workoutTemplateId as string;

        if (
          overType === "page" &&
          activeType === "phase_template_workout_template"
        ) {
          if (
            allPhaseTemplates.some(
              (phase) =>
                phase.type === "single" &&
                phase.workout_template_ids
                  .flat()
                  .includes(sourceWorkoutTemplateId),
            )
          ) {
            console.log("DND: Local edit");

            dispatch(
              updateLocalPhaseTemplate({
                workoutTemplateId: sourceWorkoutTemplateId,
                phaseTemplate: {
                  deleted: false,
                },
              }),
            );
          } else {
            console.log("DND: Server edit");

            dispatch(
              createWorkoutTemplate({
                trainerId: trainerId,
                parentFolderId: over?.data.current?.folderId ?? undefined,
                workoutTemplateId: sourceWorkoutTemplateId,
              }),
            );
          }

          const newItems = { ...items };
          const newDays = Object.keys(newItems).map((key) => ({
            draggable_id: key,
            templates: newItems[key],
          }));

          const dayIndex = active.data.current?.dayIndex as number;

          const day = newDays[dayIndex];
          const newTemplates = day.templates.filter(
            (template) => template.template_id !== sourceWorkoutTemplateId,
          );
          newDays[dayIndex] = {
            ...day,
            templates: newTemplates,
          };

          dispatch(
            updatePhaseTemplateEditing({
              days_draggable: newDays,
            }),
          );

          dispatch(setCurrentDraggingInfo(null));
          return;
        }

        if (activeType === "phase_template_day" && overId) {
          const containers = Object.keys(items);
          const activeIndex = containers.indexOf(active.id.toString());
          const overIndex = containers.indexOf(overId);

          const newDays = [...phaseTemplateEditing!.days_draggable];

          newDays.splice(overIndex, 0, newDays.splice(activeIndex, 1)[0]);

          dispatch(
            updatePhaseTemplateEditing({
              days_draggable: newDays,
            }),
          );

          return;
        }

        const activeContainer = findContainer(active.id);

        if (overId === "new_day") {
          const newItems = { ...items };

          if (activeContainer) {
            newItems[activeContainer.toString()] = items[
              activeContainer
            ].filter((id) => id.draggable_id !== activeId);
          }

          const newDays = Object.keys(newItems).map((key) => ({
            draggable_id: key,
            templates: newItems[key],
          }));

          newDays.push({
            draggable_id: crypto.randomUUID(),
            templates: [
              {
                template_id: active.data.current?.workoutTemplateId,
                draggable_id: active.id.toString(),
              },
            ],
          });

          dispatch(
            updatePhaseTemplateEditing({
              days_draggable: newDays,
            }),
          );

          dispatch(
            updateLocalPhaseTemplate({
              workoutTemplateId: active.data.current?.workoutTemplateId,
              phaseTemplate: {
                deleted: true,
              },
            }),
          );

          dispatch(setCurrentDraggingInfo(null));
          return;
        }

        // Dropped something over a folder
        if (overType === "folder_sidebar" || overType === "folder_droppable") {
          if (
            activeType === "workout_template" ||
            activeType === "phase_template"
          ) {
            // Move a template

            const overFolderId = over?.data.current?.folderId as string;

            const phaseId = active.data.current!.phaseTemplateId as string;

            console.log(`Move ${phaseId} to ${overFolderId}`);

            dispatch(
              updatePhaseTemplate({
                id: phaseId,
                parentFolderId: overFolderId,
              }),
            );

            dispatch(setCurrentDraggingInfo(null));
            return;
          } else if (activeType === "folder_draggable") {
            // Move a folder

            const overFolderId = over?.data.current?.folderId as string;

            const activeFolderId = active.data.current!.folderId as string;

            console.log(`Move ${activeFolderId} to ${overFolderId}`);

            dispatch(
              updateTemplateLibraryFolder({
                id: activeFolderId,
                parentFolderId: overFolderId,
              }),
            );

            dispatch(setCurrentDraggingInfo(null));
            return;
          }
        }

        if (!activeContainer) {
          console.log("activeContainer null");
          dispatch(setCurrentDraggingInfo(null));
          return;
        }

        if (overId == null) {
          console.log("overId null");
          dispatch(setCurrentDraggingInfo(null));
          return;
        }

        // if (overId === TRASH_ID) {
        //   setItems((items) => ({
        //     ...items,
        //     [activeContainer]: items[activeContainer].filter(
        //       (id) => id !== activeId
        //     ),
        //   }));
        //   setActiveId(null);
        //   return;
        // }

        const overContainer = findContainer(overId);

        if (overContainer) {
          const activeIndex = items[activeContainer]
            .map((i) => i.draggable_id)
            .indexOf(active.id.toString());
          const overIndex = items[overContainer]
            .map((i) => i.draggable_id)
            .indexOf(overId.toString());

          if (active.data.current?.workoutTemplateId) {
            dispatch(
              updateLocalPhaseTemplate({
                workoutTemplateId: active.data.current?.workoutTemplateId,
                phaseTemplate: {
                  deleted: true,
                },
              }),
            );
          }

          if (activeIndex !== overIndex) {
            const newItems = { ...items };

            newItems[overContainer.toString()] = arrayMove(
              items[overContainer],
              activeIndex,
              overIndex,
            );

            const newDays = Object.keys(newItems).map((key) => ({
              draggable_id: key,
              templates: newItems[key],
            }));

            dispatch(
              updatePhaseTemplateEditing({
                days_draggable: newDays,
              }),
            );
          }
        }

        dispatch(setCurrentDraggingInfo(null));
      }}
      onDragCancel={() => {
        dispatch(setCurrentDraggingInfo(null));
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: isDialog ? undefined : "100vh",
        }}
      >
        {!isDialog && (
          <ControlBar
            leftContent={
              <IconButton
                aria-label="toggle drawer"
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
                size="small"
              >
                {drawerOpen ? <ChevronLeftRoundedIcon /> : <MenuRoundedIcon />}
              </IconButton>
            }
          />
        )}
        {drawerOpen && (
          <Box
            sx={{
              width: leftDrawerWidth,
              flexShrink: 0,
              borderRight: 1,
              borderColor: "divider",
              mt: isDialog ? "35px" : "38px",
              overflowY: "auto",
              height: "calc(100vh - 38px)",
              // [`& .MuiDrawer-paper`]: {
              //   width: leftDrawerWidth,
              //   boxSizing: "border-box",
              // },
              // "& .MuiDrawer-root": {
              //   position: "absolute",
              // },
              // "& .MuiPaper-root": {
              //   position: "absolute",
              // },
            }}
          >
            <Sidebar />
          </Box>
        )}
        <Box
          component={"main"}
          // openLeft={drawerOpen}
          // openRight={phaseTemplateEditing !== null}
          sx={{
            flex: 1,
            overflow: "hidden",
            height: "100%",
            paddingTop: !isDialog ? "38px" : undefined,
          }}
        >
          {/* {!isDialog && <Toolbar />} */}
          <Box
            sx={{
              // maxHeight: "calc(100vh - 38px)",
              height: "100%",
              overflowX: "hidden",
              overflowY: "hidden",
              backgroundColor: (theme) => theme.palette.background.default,
              width: "100%",
            }}
          >
            {!isDialog ? (
              <Outlet />
            ) : openFolderId ? (
              <FolderPage />
            ) : openTagId ? (
              <TagPage />
            ) : (
              <TemplateLibraryHomePage />
            )}
          </Box>
        </Box>
        {phaseTemplateEditing !== null && (
          <Box
            sx={{
              width: rightDrawerWidth,
              flexShrink: 0,
              borderLeft: 1,
              borderColor: "divider",
              mt: isDialog ? "35px" : "38px",
              overflowY: "auto",
              height: "calc(100vh - 38px)",
            }}
          >
            <PhaseTemplateBuilder onClose={() => undefined} />
          </Box>
        )}
      </Box>
      <LibraryDragOverlay />
    </DndContext>
  );
}
