import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { shallowEqual } from "react-redux";
import GoalEditDialog from "src/components/goals/GoalEditDialog";
import { useAppSelector } from "src/hooks/stateHooks";
import GoalCard from "../goals/GoalCard";
import ClientIconGrid from "./ClientIconGrid";
import ClientStreak from "./ClientStreak";

type Props = {
  userId: string;
  defaultExpanded?: boolean;
  freeFloating?: boolean;
  condensed?: boolean;
};

export default function AboutPanel({
  userId,
  defaultExpanded = false,
  freeFloating = false,
  condensed,
}: Props) {
  const client = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId ? state.client.client : undefined,
    shallowEqual,
  );
  const [goalEditDialogOpen, setGoalEditDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState(defaultExpanded);

  if (!client) {
    return null;
  }

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      disableGutters
      elevation={freeFloating ? undefined : 0}
      square={!freeFloating}
      sx={{ overflow: "hidden" }}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      expanded={condensed ? false : expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h6">🙂{!condensed ? " About" : ""}</Typography>
          {!client.settings.consistency_mode_disabled && !condensed && (
            <ClientStreak client={client} />
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <>
          <ClientIconGrid client={client} />
          {!client.goal && !client.account.dashboard.date_onboarded && (
            <Box sx={{ px: 1 }}>
              <Button
                fullWidth
                sx={{ my: 1 }}
                startIcon={<EditCalendarRoundedIcon />}
                onClick={() => {
                  setGoalEditDialogOpen(true);
                }}
              >
                Set goal schedule
              </Button>
              <GoalEditDialog
                client={client}
                open={goalEditDialogOpen}
                onClose={() => {
                  setGoalEditDialogOpen(false);
                }}
              />
            </Box>
          )}
          <GoalCard client={client} sx={{ my: 1, mx: 1 }} />
        </>
      </AccordionDetails>
    </Accordion>
  );
}
