import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import {
  Box,
  CardActionArea,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import { selectClientById } from "src/slices/allClientsSlice";

type Props = {
  userId: string;
  isSelected: boolean;
  selectClient: (coachID: string) => void;
};

export default function ClientCell({
  userId,
  isSelected,
  selectClient,
}: Props) {
  const navigate = useNavigate();
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);
  const client = useAppSelector((state) => selectClientById(state, userId));

  // const handleChange = (event: SelectChangeEvent) => {
  //   setCoachID(event.target.value as string);
  //   changeCoach(client.user_id, event.target.value as string);
  // };

  if (!client) {
    return <Typography>Error finding client</Typography>;
  }

  return (
    <>
      <CardActionArea
        sx={{
          p: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: (theme) =>
            isSelected ? alpha(theme.palette.primary.main, 0.2) : undefined,
        }}
        onClick={() => {
          selectClient(userId);
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            width={30}
            height={30}
            style={{
              borderRadius: "15px",
              display: "block",
            }}
            src={
              client.headshot_url ? client.headshot_url : "/assets/profile.png"
            }
            alt="client headshot"
          />
          <Box sx={{ ml: 1 }}>
            <Typography>{client.full_name}</Typography>
            <Typography variant="overline">
              {getTrainerName(client.trainer_id, trainerNames)}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Tooltip title="Ghost">
            <IconButton
              href={`/clients/${userId}`}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
            >
              <LoginRoundedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActionArea>
      <Divider />
    </>
  );
}
