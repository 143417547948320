import { DndContext, useDraggable } from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { CSS, type Coordinates } from "@dnd-kit/utilities";
import { Paper } from "@mui/material";
import { useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import ChatBar from "./ChatBar";

const defaultCoordinates = {
  x: 0,
  y: 0,
};

export function ChatDraggable() {
  const [{ x, y }, setCoordinates] = useState<Coordinates>(defaultCoordinates);

  return (
    <DndContext
      modifiers={[restrictToWindowEdges]}
      onDragEnd={({ delta }) => {
        setCoordinates(({ x, y }) => {
          return {
            x: x - delta.x,
            y: y - delta.y,
          };
        });
      }}
    >
      <ChatDraggableContent bottom={y} right={x} />
    </DndContext>
  );
}

type ContentProps = {
  bottom: number;
  right: number;
};

export function ChatDraggableContent({ bottom, right }: ContentProps) {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useDraggable({
      id: "draggable_chat",
    });
  const chatVisualState = useAppSelector((state) => state.chat.chatMode);
  const expanded =
    chatVisualState === "drag_expanded" ||
    chatVisualState === "big_left" ||
    chatVisualState === "big_right" ||
    chatVisualState === "full";

  return (
    <Paper
      ref={setNodeRef}
      sx={{
        marginTop: expanded ? "-450px" : "0px",
        backgroundColor: (theme) => theme.palette.background.default,
        borderRadius: 1,
        border: 1,
        borderColor: "divider",
        overflow: "hidden",
        zIndex: 1260,
        boxShadow: isDragging ? 12 : 6,
        transform: CSS.Translate.toString(transform),
        position: "absolute",
        bottom: bottom,
        right: right,
      }}
    >
      <ChatBar dragAttributes={attributes} dragListeners={listeners} />
    </Paper>
  );
}
