import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { shallowEqual } from "react-redux";
import AutosaveTextField from "src/components/misc/AutosaveTextField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { updateClient } from "src/slices/clientSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { AutosaveRichTextField } from "../misc/AutosaveRichTextField";

type Props = {
  userId: string;
  defaultExpanded?: boolean;
  condensed?: boolean;
};

export default function NotesPanel({
  userId,
  defaultExpanded = false,
  condensed,
}: Props) {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const client = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? {
            quick_notes: state.client.client.quick_notes,
            personal_notes: state.client.client.personal_notes,
            outcome_notes: state.client.client.outcome_notes,
            process_notes: state.client.client.process_notes,
            injuries_notes: state.client.client.injuries_notes,
            source_notes: state.client.client.source_notes,
            programming_notes: state.client.client.programming_notes,
            notes: state.client.client.notes,
          }
        : undefined,
    shallowEqual,
  );
  const [expanded, setExpanded] = useState(defaultExpanded);

  useHotkeys("n", () => {
    setExpanded(true);
    setTimeout(() => {
      document
        .getElementById("panel_notes")
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 50);
  });

  if (!trainer || !trainer.note_templates || !client) {
    return null;
  }

  return (
    <Accordion
      id={"panel_notes"}
      disableGutters
      elevation={0}
      square
      expanded={condensed ? false : expanded}
      onChange={(event, expanded) => {
        setExpanded(expanded);
      }}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      sx={{
        "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
        "& .MuiAccordionDetails-root": { display: expanded ? "block" : "none" },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">🗒️{condensed ? "" : " Notes"}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0.5 }}>
        <div>
          <AutosaveTextField
            label="Quick notes"
            defaultValue={client.quick_notes ?? trainer?.note_templates.quick}
            onSave={(newValue) => {
              dispatch(
                updateClient({
                  user_id: userId,
                  quick_notes: newValue,
                }),
              );
            }}
            name="notesPanel.quickNotes"
            size="large"
          />
          <AutosaveTextField
            label="Outcome goals"
            defaultValue={
              client.outcome_notes ?? trainer?.note_templates.goal_outcome
            }
            onSave={(newValue) => {
              dispatch(
                updateClient({
                  user_id: userId,
                  outcome_notes: newValue,
                }),
              );
            }}
            name="notesPanel.outcomeGoalsNotes"
            size="large"
          />
          <AutosaveTextField
            label="Process goals"
            defaultValue={
              client.process_notes ?? trainer?.note_templates.goal_process
            }
            onSave={(newValue) => {
              dispatch(
                updateClient({
                  user_id: userId,
                  process_notes: newValue,
                }),
              );
            }}
            name="notesPanel.processGoalsNotes"
            size="large"
          />
          <AutosaveTextField
            label="Exercise history"
            defaultValue={
              client.injuries_notes ?? trainer?.note_templates.exercise_history
            }
            onSave={(newValue) => {
              dispatch(
                updateClient({
                  user_id: userId,
                  injuries_notes: newValue,
                }),
              );
            }}
            name="notesPanel.exerciseHistoryNotes"
            size="large"
          />
          <AutosaveTextField
            label="Source"
            defaultValue={client.source_notes ?? trainer?.note_templates.source}
            onSave={(newValue) => {
              dispatch(
                updateClient({
                  user_id: userId,
                  source_notes: newValue,
                }),
              );
            }}
            name="notesPanel.sourceNotes"
            size="large"
          />
          <AutosaveTextField
            label="Personal notes"
            defaultValue={
              client.personal_notes ?? trainer?.note_templates.personal
            }
            onSave={(newValue) => {
              dispatch(
                updateClient({
                  user_id: userId,
                  personal_notes: newValue,
                }),
              );
            }}
            name="notesPanel.personalNotes"
            size="large"
          />
          <AutosaveTextField
            label="Programming"
            defaultValue={
              client.programming_notes ?? trainer?.note_templates.programming
            }
            onSave={(newValue) => {
              dispatch(
                updateClient({
                  user_id: userId,
                  programming_notes: newValue,
                }),
              );
            }}
            name="notesPanel.programmingNotes"
            size="large"
          />
          <AutosaveRichTextField
            label="Detailed notes"
            defaultValue={client.notes ?? trainer?.note_templates.detailed}
            onSave={(newValue) => {
              dispatch(
                updateClient({
                  user_id: userId,
                  notes: newValue,
                }),
              );
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
