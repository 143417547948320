import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import RestrictAccess from "src/components/misc/RestrictAccess";
import { useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { FileEditor } from "./FileEditor";
import {
  isNumeric,
  markFileAsClean,
  markFileAsTestOnly,
  markFileAsUnclean,
} from "./lib/fileUtility";

export function DataCleaningPage() {
  const cleanerName =
    useAppSelector(selectPrimaryTrainer)?.first_name.toLowerCase();
  const [totalFiles, setTotalFiles] = useState<string>();
  const [exerciseId, setExerciseId] = useState<string>();
  const [fileIndex, setFileIndex] = useState<number>();
  const [files, setFiles] = useState<string[]>();
  const [isCleaning, setIsCleaning] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [useVelocity, setUseVelocity] = useState(false);

  async function loadFileIds() {
    setIsLoading(true);

    if (!exerciseId || !totalFiles) {
      setIsLoading(false);
      return;
    }

    try {
      const files = await api.motionMetadatas.getIDs(
        exerciseId,
        Number(totalFiles),
      );

      setIsLoading(false);
      setIsCleaning(true);
      setFiles(files.motion_file_ids);
      setFileIndex(0);
    } catch (err) {
      console.log("Error fetching list of motion files", err);
      setIsLoading(false);
      alert("Failed to load files.\n\n" + err);
    }
  }

  function updateFile(file) {
    // run this without awaiting
    api.motionMetadatas.updateMotionFile(file);
  }

  const enableButton =
    cleanerName !== undefined &&
    cleanerName.length > 0 &&
    totalFiles !== undefined &&
    isNumeric(totalFiles) &&
    exerciseId !== undefined &&
    exerciseId.length > 0;

  console.log("cleanerName", cleanerName);

  return (
    <RestrictAccess location={"data_cleaner"} showNoAccessMessage>
      <Box sx={{ mx: 4, my: 2 }}>
        <Typography variant="h1">Data Cleaner</Typography>
        <Typography>Now with batteries included!</Typography>
      </Box>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <CircularProgress />
          <Typography>Only you can prevent data cleaning fires...</Typography>
        </div>
      ) : (
        <Fragment>
          {isCleaning && files && fileIndex !== undefined ? (
            <FileEditor
              fileIndex={fileIndex}
              totalFileCount={files.length}
              fileId={files[fileIndex]}
              useVelocity={useVelocity}
              skipHandler={() => {
                //If there are files left, go to the next one
                if (fileIndex < files.length - 1) {
                  setFileIndex(fileIndex + 1);
                } else {
                  //No more files to view, go back
                  setIsCleaning(false);
                  setIsLoading(false);
                  setFiles(undefined);
                  setFileIndex(undefined);
                }
              }}
              backHandler={() => {
                //If there are files before, go to the last one
                if (fileIndex > 0) {
                  setFileIndex(fileIndex - 1);
                } else {
                  //No more files to view, go back
                  setIsCleaning(false);
                  setIsLoading(false);
                  setFiles(undefined);
                  setFileIndex(undefined);
                }
              }}
              saveHandler={(file) => {
                markFileAsClean(file, cleanerName);

                //Update file async - TODO: report back if save fails somehow
                updateFile(file);
              }}
              testHandler={(file) => {
                markFileAsTestOnly(file, cleanerName);
                updateFile(file);
              }}
              uncleanHandler={(file) => {
                markFileAsUnclean(file, cleanerName);
                updateFile(file);
              }}
              quitHandler={() => {
                setIsCleaning(false);
                setIsLoading(false);
                setFiles(undefined);
                setFileIndex(undefined);
              }}
            />
          ) : (
            <div
              style={{
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 7,
                  marginTop: 50,
                }}
              >
                <FormGroup>
                  <TextField
                    error={!totalFiles || !isNumeric(totalFiles)}
                    helperText={
                      totalFiles && !isNumeric(totalFiles)
                        ? "Must be a number"
                        : undefined
                    }
                    value={totalFiles}
                    variant="outlined"
                    label="Number of Files"
                    onChange={(e) => {
                      setTotalFiles(e.target.value);
                    }}
                    margin="normal"
                  />
                  <TextField
                    error={!exerciseId || exerciseId.length === 0}
                    value={exerciseId}
                    variant="outlined"
                    label="Exercise Name"
                    onChange={(e) => {
                      setExerciseId(e.target.value);
                    }}
                    margin="normal"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(e) => {
                          setUseVelocity(e.target.checked);
                        }}
                      />
                    }
                    label="Convert acceleration to velocity"
                  />
                </FormGroup>
              </div>
              {enableButton && (
                <Button
                  style={{ marginTop: 40 }}
                  variant="contained"
                  onClick={() => loadFileIds()}
                >
                  Clean
                </Button>
              )}
            </div>
          )}
        </Fragment>
      )}
    </RestrictAccess>
  );
}
