import type { ActionItem, Client, Message } from "@trainwell/types";
import { useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import type { Socket } from "socket.io-client";
import { io } from "socket.io-client";
import { TRAINWELL_CHAT_API_DOMAIN } from "src/config/config";
import { addActionItem, clearActionItems } from "src/slices/actionItemSlice";
import { setSocketConnected } from "src/slices/appSlice";
import {
  addMessage,
  markMessageAsRead,
  updateMessage,
} from "src/slices/chatSlice";
import { updateClientLocal } from "src/slices/clientSlice";
import {
  selectAllVisibleClients,
  updateClientInListLocal,
} from "src/slices/clientsSlice";
import {
  selectIsAuditing,
  selectPrimaryTrainer,
} from "src/slices/trainerSlice";
import { useAppDispatch, useAppSelector } from "./stateHooks";

export let socket: Socket;

export default function useSocket() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const coachRef = useRef(trainer);
  const isSetup = useRef(false);
  const isGhosting = useAppSelector(selectIsAuditing);
  const isGhostingRef = useRef(isGhosting);
  const { enqueueSnackbar } = useSnackbar();
  const clients = useAppSelector(selectAllVisibleClients);
  const clientsRef = useRef(clients);

  useEffect(() => {
    coachRef.current = trainer;
  }, [trainer]);

  useEffect(() => {
    clientsRef.current = clients;
  }, [clients]);

  useEffect(() => {
    isGhostingRef.current = isGhosting;
  }, [isGhosting]);

  useEffect(() => {
    if (!coachRef.current) {
      return;
    }

    socket = io(TRAINWELL_CHAT_API_DOMAIN);

    isSetup.current = true;

    console.log("Socket: connecting");

    socket.open();

    socket.on("connect", () => {
      console.log(
        "Socket: connected with trainer_id: " + coachRef.current!.trainer_id,
      );

      dispatch(setSocketConnected(true));

      if (!isGhostingRef.current) {
        socket.emit("registerCoach", {
          trainer_id: coachRef.current!.trainer_id,
        });

        console.log("Socket: trainer registered");
      } else {
        console.log("Socket: skipping trainer registration while ghosting");
      }
    });

    socket.on("newMessage", (message: Message) => {
      console.log("Socket: Got new message!");

      let user_id = message.from_id;
      if (user_id === coachRef.current!.trainer_id) {
        user_id = message.to_id;
      }

      const fromClient = message.from_id !== coachRef.current!.trainer_id;

      if (fromClient && coachRef.current?.settings.play_chat_sound) {
        const audio = new Audio(
          coachRef.current.settings.chat_sound === "got-mail"
            ? "/sounds/got-mail.mp3"
            : coachRef.current.settings.chat_sound === "click"
              ? "/sounds/click.mp3"
              : "/sounds/ding.mp3",
        );
        audio.play();
      }

      dispatch(
        addMessage({
          clientID: user_id,
          message: message,
          isFromCoach: message.from_id === coachRef.current!.trainer_id,
        }),
      );
    });

    socket.on("newChatMessage", (message) => {
      console.log("Socket: Got new chat message!");

      if (
        message.from_id !== coachRef.current!.trainer_id &&
        coachRef.current?.settings.play_chat_sound
      ) {
        const audio = new Audio(
          coachRef.current.settings.chat_sound === "got-mail"
            ? "/sounds/got-mail.mp3"
            : coachRef.current.settings.chat_sound === "click"
              ? "/sounds/click.mp3"
              : "/sounds/ding.mp3",
        );
        audio.play();
      }

      dispatch(
        addMessage({
          clientID: message.to_id,
          message: message,
          isFromCoach: message.from_id === coachRef.current!.trainer_id,
        }),
      );
    });

    socket.on(
      "updateClient",
      (update: Partial<Client> & Pick<Client, "user_id">) => {
        console.log("Socket: update client");

        dispatch(updateClientInListLocal(update));
        dispatch(updateClientLocal(update));
      },
    );

    socket.on("updatedMessage", (message: Message) => {
      console.log("Socket: update message");

      dispatch(updateMessage(message));
    });

    socket.on(
      "messageRead",
      (message: Message & { group_message?: boolean; read_by?: string }) => {
        let user_id = message.from_id;
        if (user_id === coachRef.current!.trainer_id) {
          user_id = message.to_id;
        }

        dispatch(
          markMessageAsRead({
            userId: message.group_message ? message.to_id : user_id,
            message: message,
            didCoachRead:
              message.from_id !== coachRef.current!.trainer_id ||
              message.read_by === coachRef.current!.trainer_id,
            readBy: message.read_by,
          }),
        );
      },
    );

    socket.on("newActionItem", (data: { actionItem: ActionItem }) => {
      dispatch(addActionItem(data.actionItem));
    });

    socket.on("clearActionItems", (data: { actionItemIds: string[] }) => {
      dispatch(clearActionItems(data.actionItemIds));
    });

    socket.on("disconnect", () => {
      console.log("Socket: disconnected");

      dispatch(setSocketConnected(false));
    });

    return () => {
      console.log("Socket: disconnecting due to coach switch");

      socket.disconnect();
    };
  }, [trainer?.trainer_id]);

  useEffect(() => {
    return () => {
      console.log("Socket: closing");

      if (socket) {
        socket.off("connect");
        socket.off("newMessage");
        socket.off("newChatMessage");
        socket.off("messageRead");
        socket.off("newActionItem");
        socket.off("clearActionItems");
        socket.close();
      }
    };
  }, []);

  return;
}
