import { Card, Paper, Typography, useTheme } from "@mui/material";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { localPoint } from "@visx/event";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { BarGroup } from "@visx/shape";
import { defaultStyles, useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { roundedDecimal } from "src/lib/miscUtility";
import type { Comparison } from "./useComparison";

type TooltipData = {
  title: string;
  value: number;
  percent: number;
  percentPeak: number;
};

let tooltipTimeout: number;

const defaultMargin = { top: 40, right: 0, bottom: 60, left: 20 };

// export type PageViewData = {
//   path: string;
//   first: number;
//   second: number;
//   third: number;
//   firstActual: number;
//   secondActual: number;
//   thirdActual: number;
//   firstRelative: number;
//   secondRelative: number;
//   thirdRelative: number;
// }[];

type Props = {
  width: number;
  height: number;
  relative: boolean;
  comparisons: Comparison[];
  pageViewData: any[];
  colors: string[];
  pages: string[];
};

export default function ChartFunnel({
  width,
  height,
  relative,
  comparisons,
  pageViewData,
  colors,
  pages,
}: Props) {
  const theme = useTheme();
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  const keys = comparisons.map((comparison) => comparison.id);

  // scales
  const pageScale = scaleBand<string>({
    domain: pageViewData.map((pageView: any) => pageView.path),
    padding: 0.2,
  });
  const filterScale = scaleBand<string>({
    domain: keys,
    padding: 0.1,
  });
  const eventCountScale = scaleLinear<number>({
    domain: [
      0,
      Math.max(
        ...pageViewData.map((d: any) =>
          Math.max(...keys.map((key) => Number(d[key] ?? 0))),
        ),
      ),
    ],
    // domain: [0, Math.max(...pageViewData.map((d) => d.first))],
  });
  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: colors,
  });

  // bounds
  const xMax = width - defaultMargin.left - defaultMargin.right;
  const yMax = height - defaultMargin.top - defaultMargin.bottom;

  // update scale output dimensions
  pageScale.rangeRound([0, xMax]);
  filterScale.rangeRound([0, pageScale.bandwidth()]);
  eventCountScale.range([yMax, 0]);

  return width < 10 ? null : (
    <div style={{ position: "relative" }}>
      <Card variant="outlined" sx={{ width: width, height: height, mb: 2 }}>
        <svg ref={containerRef} width={width} height={height}>
          <Group top={defaultMargin.top} left={defaultMargin.left}>
            <GridRows
              scale={eventCountScale}
              width={xMax}
              height={yMax}
              stroke={theme.palette.divider}
              numTicks={5}
              left={defaultMargin.left}
            />
            <BarGroup
              data={pageViewData}
              keys={keys}
              height={yMax}
              x0={(event) => event.path}
              x0Scale={pageScale}
              x1Scale={filterScale}
              yScale={eventCountScale}
              color={colorScale}
            >
              {(barGroups) =>
                barGroups.map((barGroup) => (
                  <Group
                    key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                    left={barGroup.x0}
                  >
                    {barGroup.bars.map((bar) => (
                      <rect
                        key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                        x={bar.x}
                        y={bar.y}
                        width={bar.width}
                        height={bar.height}
                        fill={bar.color}
                        rx={4}
                        onMouseLeave={() => {
                          tooltipTimeout = window.setTimeout(() => {
                            hideTooltip();
                          }, 300);
                        }}
                        onMouseMove={(event) => {
                          if (tooltipTimeout) clearTimeout(tooltipTimeout);

                          const eventSvgCoords = localPoint(event);
                          const left =
                            (bar.x ?? 0) + barGroup.x0 + bar.width / 2;

                          showTooltip({
                            tooltipData: {
                              title: pages[barGroup.index],
                              value:
                                pageViewData[barGroup.index][
                                  "actual-" + keys[bar.index]
                                ],
                              percent:
                                barGroup.index === 0
                                  ? 1
                                  : pageViewData[barGroup.index][
                                      "actual-" + keys[bar.index]
                                    ] /
                                    pageViewData[barGroup.index - 1][
                                      "actual-" + keys[bar.index]
                                    ],
                              percentPeak:
                                pageViewData[barGroup.index][
                                  "relative-" + keys[bar.index]
                                ],
                            },
                            tooltipTop: eventSvgCoords?.y,
                            tooltipLeft: left,
                          });
                        }}
                      />
                    ))}
                  </Group>
                ))
              }
            </BarGroup>
          </Group>
          <AxisLeft
            scale={eventCountScale.nice()}
            numTicks={5}
            top={defaultMargin.top}
            left={defaultMargin.left + 20}
            stroke={theme.palette.text.primary}
            hideTicks
            hideZero
            hideAxisLine
            tickLabelProps={() => ({
              fill: theme.palette.text.primary,
              fontSize: 12,
              textAnchor: "end",
              dy: "0.33em",
              // x: -12,
              // y: (yScale(e) ?? 0) + 3,
            })}
            tickFormat={(text) => {
              if (relative) {
                return (Number(text) * 100).toPrecision(2) + "%";
              } else {
                return text.toString();
              }
            }}
          />
          <AxisBottom
            top={yMax + defaultMargin.top}
            left={defaultMargin.left}
            scale={pageScale}
            stroke={theme.palette.text.primary}
            tickStroke={theme.palette.text.primary}
            hideAxisLine
            numTicks={pages.length}
            tickLabelProps={() => ({
              fill: theme.palette.text.primary,
              fontSize: 12,
              textAnchor: "start",
              angle: 15,
            })}
            tickFormat={(text) => {
              if (text === "create_account") {
                return "create account";
              }
              if (text === "sales_site") {
                return "sales site";
              }
              const newText = text.substring(1);
              if (newText) {
                return newText;
              } else {
                return "splash";
              }
            }}
          />
        </svg>
      </Card>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          offsetTop={-110}
          style={{
            ...defaultStyles,
            minWidth: 60,
            backgroundColor: "rgba(0,0,0,0)",
            padding: 0,
          }}
        >
          <Paper elevation={3} sx={{ width: "100%", height: "100%", p: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {tooltipData.title}
            </Typography>
            <Typography>
              Views: <b>{tooltipData.value}</b>
            </Typography>
            <Typography>
              From last step:{" "}
              <b>{roundedDecimal(tooltipData.percent * 100)}%</b>{" "}
              <Typography
                component="span"
                sx={{
                  fontSize: 12,
                  color: (theme) => theme.palette.error.main,
                }}
              >
                {roundedDecimal(100 - tooltipData.percent * 100)}%
              </Typography>
            </Typography>
            <Typography>
              From peak: <b>{roundedDecimal(tooltipData.percentPeak * 100)}%</b>{" "}
              <Typography
                component="span"
                sx={{
                  fontSize: 12,
                  color: (theme) => theme.palette.error.main,
                }}
              >
                {roundedDecimal(100 - tooltipData.percentPeak * 100)}%
              </Typography>
            </Typography>
          </Paper>
        </TooltipInPortal>
      )}
    </div>
  );
}
