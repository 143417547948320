import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import AuditCell from "src/components/ClientOverview/AuditCell";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  createCoachAudit,
  fetchTrainerAudits,
} from "src/slices/trainerEditSlice";

export default function TabAudits() {
  const dispatch = useAppDispatch();
  const trainerEdit = useAppSelector((state) => state.trainerEdit.trainer);
  const audits = useAppSelector((state) => state.trainerEdit.audits);
  const auditsStatus = useAppSelector(
    (state) => state.trainerEdit.auditsStatus,
  );
  const [includeClientAudits, setIncludeClientAudits] = useState(false);

  useEffect(() => {
    if (auditsStatus === "idle" && trainerEdit?.trainer_id) {
      dispatch(fetchTrainerAudits(trainerEdit.trainer_id));
    }
  }, [auditsStatus, dispatch, trainerEdit?.trainer_id]);

  if (!trainerEdit) {
    return <LoadingPage message="Getting your trainer" />;
  }

  let displayAudits = [...audits];

  if (!includeClientAudits) {
    displayAudits = displayAudits.filter((audit) => audit.subject_trainer_id);
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          justifyContent: "space-between",
        }}
      >
        <Button
          startIcon={<AddRoundedIcon />}
          onClick={() => {
            dispatch(createCoachAudit(trainerEdit.trainer_id));
          }}
          sx={{ mr: 2 }}
        >
          Start trainer audit
        </Button>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={includeClientAudits}
                onChange={(event) => {
                  setIncludeClientAudits(event.target.checked);
                }}
              />
            }
            label="Include client audits"
          />
        </FormGroup>
      </Box>
      <Stack spacing={2} direction="column">
        {displayAudits.map((audit) => {
          return <AuditCell audit={audit} key={audit.id} showClientName />;
        })}
      </Stack>
    </Box>
  );
}
