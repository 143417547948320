import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useInfluencer,
  useShortUrl,
  useUpdateInfluencer,
  type InfluencerClient,
} from "@trainwell/features";
import type { Client } from "@trainwell/types";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { api } from "src/lib/trainwellApi";
import { InfluencerClientEditDialog } from "./InfluencerClientEditDialog";

type Props = {
  influencerId: string;
  influencerUser: InfluencerClient;
};

export function InfluencerClientCell({ influencerId, influencerUser }: Props) {
  const [client, setClient] = useState<null | Client | undefined | "error">();
  const { data: shortUrl } = useShortUrl({
    shortUrlId: influencerUser.signup_short_url_id ?? "",
    queryConfig: {
      enabled: !!influencerUser.signup_short_url_id,
    },
  });
  const { data: influencer } = useInfluencer({
    influencerId: influencerId,
  });
  const updateInfluencerMutation = useUpdateInfluencer();
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (influencerUser.user_id) {
      api.clients
        .getOne(influencerUser.user_id)
        .then((data) => {
          setClient(data);
        })
        .catch(() => {
          setClient("error");
        });
    } else {
      setClient(null);
    }
  }, [influencerUser.user_id]);

  function deleteInfluencerClient() {
    const users = [...(influencer?.users ?? [])].filter(
      (u) => u.id !== influencerUser.id,
    );

    updateInfluencerMutation.mutate({
      influencerId: influencerId,
      data: {
        users,
      },
    });
  }

  if (client === "error") {
    return (
      <Box>
        <Typography>Error finding client</Typography>
        <Button
          size="small"
          variant="text"
          startIcon={<DeleteRoundedIcon />}
          onClick={deleteInfluencerClient}
        >
          Remove error client
        </Button>
      </Box>
    );
  } else if (client === undefined) {
    return <CircularProgress />;
  }

  let name = client?.full_name;

  if (!name) {
    if (influencerUser.temporary_name) {
      name = influencerUser.temporary_name;
    } else {
      name = "Not signed up";
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {client?.headshot_url ? (
            <Box sx={{ mr: 2 }}>
              <img
                src={client.headshot_url}
                alt={client.full_name}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  objectFit: "cover",
                  display: "block",
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                mr: 2,
                width: 40,
                height: 40,
                borderRadius: "50%",
                borderWidth: 3,
                borderStyle: "dashed",
                borderColor: "divider",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: (theme) => theme.palette.background.default,
              }}
            >
              <QuestionMarkRoundedIcon
                sx={{ color: (theme) => theme.palette.divider }}
              />
            </Box>
          )}
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>{name}</Typography>
            {!client && shortUrl && (
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>
                  Signup url{" "}
                  <Typography component={"span"} sx={{ fontWeight: "normal" }}>
                    (not signed up yet)
                  </Typography>
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Link
                    href={`https://go.${shortUrl.domain}/${shortUrl.short_path}`}
                    target="_blank"
                    sx={{
                      overflowWrap: "break-word",
                      wordBreak: "break-all",
                    }}
                  >
                    https://go.{shortUrl.domain}/{shortUrl.short_path}
                  </Link>
                  <Tooltip
                    title="Copy to clipboard"
                    placement="right"
                    disableInteractive
                  >
                    <IconButton
                      size="small"
                      color="default"
                      sx={{ ml: 0.5 }}
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            `https://go.${shortUrl.domain}/${shortUrl.short_path}`,
                          )
                          .then(() => {
                            enqueueSnackbar("Copied to clipboard", {
                              variant: "success",
                            });
                          });
                      }}
                    >
                      <ContentPasteRoundedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            )}
            {influencerUser.user_id && (
              <Typography>id: {influencerUser.user_id}</Typography>
            )}
          </Box>
        </Box>
        {client && (
          <Stack spacing={1} direction={"row"} sx={{ mt: 1 }}>
            <Button
              size="small"
              variant="text"
              href={`/clients/${client.user_id}`}
            >
              Trainer dash
            </Button>
            <Button
              size="small"
              variant="text"
              href={`/admin/company/clients/${client.user_id}`}
            >
              Admin dash
            </Button>
          </Stack>
        )}
      </Box>
      <IconButton
        size="small"
        onClick={(event) => {
          setMoreAnchorEl(event.currentTarget);
        }}
      >
        <MoreVertRoundedIcon fontSize="inherit" />
      </IconButton>
      <InfluencerClientEditDialog
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
        }}
        influencerId={influencerId}
        defaultInfluencerUser={influencerUser}
      />
      <Menu
        open={Boolean(moreAnchorEl)}
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setMoreAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            setEditDialogOpen(true);

            setMoreAnchorEl(null);
          }}
          disabled
        >
          <ListItemIcon>
            <EditRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <Box>
          <Divider sx={{ mb: 1, mt: 2 }} />
        </Box>
        <MenuItem
          onClick={() => {
            deleteInfluencerClient();

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <DeleteRoundedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            sx={{ color: (theme) => theme.palette.error.main }}
          />
        </MenuItem>
      </Menu>
    </Box>
  );
}
