import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { shallowEqual } from "react-redux";
import ClientName from "src/components/misc/ClientName";
import ClientProfilePicture from "src/components/misc/ClientProfilePicture";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getClientSummary } from "src/lib/client";
import { openChat } from "src/slices/chatSlice";
import AboutPanel from "./AboutPanel";
import AuditPanel from "./AuditPanel";
import ClientTags from "./ClientTags";
import EquipmentPanel from "./EquipmentPanel";
import ExerciseProgressPanel from "./ExerciseProgressPanel";
import LevelPanel from "./LevelPanel";
import MorePanel from "./MorePanel";
import NotesPanel from "./NotesPanel";
import OnboardingVideosPanel from "./OnboardingVideosPanel";
import ProgressPicturesPanel from "./ProgressPicturesPanel";
import ReferralsPanel from "./ReferralsPanel";
import SettingsPanel from "./SettingsPanel";
import SurveysPanel from "./SurveysPanel";
import SwapPanel from "./SwapPanel";
import TagsPanel from "./TagsPanel";
import TicketsPanel from "./TicketsPanel";

type Props = {
  userId: string;
  open: boolean;
  onToggle: () => void;
};

export default function ClientOverview({ userId, open, onToggle }: Props) {
  const dispatch = useAppDispatch();
  const account = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? state.client.client.account
        : undefined,
    shallowEqual,
  );
  const name = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? state.client.client.full_name
        : undefined,
    shallowEqual,
  );
  const niches = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? state.client.client.niches
        : undefined,
    shallowEqual,
  );

  console.log("Render: client overview");

  if (!account) {
    return <CircularProgress />;
  }

  return (
    <Box
      sx={{
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: open ? "row" : "column",
          backgroundColor: (theme) => theme.palette.background.paper,
          px: 2,
          pt: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: open ? undefined : 2,
          }}
        >
          <Tooltip title={open ? "" : name} disableInteractive placement="top">
            <span>
              <ClientProfilePicture dimension={40} userId={userId} />
            </span>
          </Tooltip>
          {open && (
            <Box sx={{ ml: 2 }}>
              <ClientName variant="h1" userId={userId} />
              <Typography variant="overline">
                {getClientSummary(account).text}
              </Typography>
              {account.plan.brand !== "copilot" && (
                <Typography variant="overline">
                  {account.plan.brand === "af"
                    ? "Anytime Fitness"
                    : account.plan.brand === "echelon"
                      ? "Echelon"
                      : account.plan.brand === "gf"
                        ? "GlobalFit / Husk"
                        : "Unknown brand"}
                </Typography>
              )}
              {niches?.includes("postpartum") && (
                <Typography>Postpartum client</Typography>
              )}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IconButton
            aria-label="toggle drawer"
            onClick={() => {
              onToggle();
            }}
            size="small"
          >
            {open ? <ChevronLeftRoundedIcon /> : <MenuRoundedIcon />}
          </IconButton>
          <IconButton
            onClick={() => {
              dispatch(openChat({ chatId: userId }));
            }}
            size="small"
          >
            <ChatRoundedIcon />
          </IconButton>
        </Box>
      </Box>
      {open && (
        <Box sx={{ p: 1 }}>
          <ClientTags userId={userId} />
        </Box>
      )}

      {open && (
        <>
          <AboutPanel userId={userId} defaultExpanded condensed={!open} />

          <NotesPanel userId={userId} condensed={!open} />

          <EquipmentPanel userId={userId} condensed={!open} />

          <ReferralsPanel userId={userId} condensed={!open} />

          <ExerciseProgressPanel userId={userId} condensed={!open} />

          <ProgressPicturesPanel userId={userId} />

          <SettingsPanel userId={userId} />

          <SurveysPanel userId={userId} />

          <OnboardingVideosPanel userId={userId} />

          <TagsPanel userId={userId} />

          <LevelPanel userId={userId} />

          <AuditPanel userId={userId} />

          <TicketsPanel userId={userId} />

          <SwapPanel userId={userId} />

          <MorePanel userId={userId} />
        </>
      )}
    </Box>
  );
}
