import { Box, Typography } from "@mui/material";
import { getFormattedTimeFromMinutes } from "src/lib/misc";

type Props = {
  metric?: string;
  type: string;
  subMetric?: string;
};

export default function TrainerMetric({ metric, type, subMetric }: Props) {
  let metricString = "--";

  if (metric && type) {
    if (type === "percent") {
      metricString = metric + "%";
    } else if (type === "duration") {
      metricString = getFormattedTimeFromMinutes(Math.round(Number(metric)));
    } else {
      metricString = metric;
    }
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ fontWeight: "bold", fontSize: 19 }}>
        {metricString}
      </Typography>
      {subMetric !== undefined && subMetric !== null && (
        <Typography sx={{ fontWeight: "bold" }}>({subMetric})</Typography>
      )}
    </Box>
  );
}
