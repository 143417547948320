import CalendarViewWeekRoundedIcon from "@mui/icons-material/CalendarViewWeekRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import { Avatar, Box, Typography } from "@mui/material";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPhaseById, selectWorkoutById } from "src/slices/phasesSlice";

type Props = {
  phaseId?: string;
  workoutId?: string;
};

export function PhaseDragOverlay({ phaseId, workoutId }: Props) {
  const phase = useAppSelector((state) =>
    selectPhaseById(state, phaseId ?? ""),
  );
  const workout = useAppSelector((state) =>
    selectWorkoutById(state, workoutId ?? ""),
  );

  if ((phaseId && !phase) || (workoutId && !workout)) {
    return <Typography>Error</Typography>;
  }

  let icon = (
    <Avatar
      sx={{
        backgroundColor: (theme) => theme.palette.blue.main,
        mr: 1,
        width: 20,
        height: 20,
        borderRadius: "6px",
      }}
    >
      <FitnessCenterRoundedIcon
        sx={{
          fontSize: 16,
        }}
      />
    </Avatar>
  );

  if (phase?.type === "multiple") {
    icon = (
      <Avatar
        sx={{
          backgroundColor: (theme) => theme.palette.success.main,
          mr: 1,
          width: 20,
          height: 20,
          borderRadius: "6px",
        }}
      >
        <CalendarViewWeekRoundedIcon
          sx={{
            fontSize: 16,
          }}
        />
      </Avatar>
    );
  }

  return (
    <Box
      sx={{
        border: 1,
        borderColor: "divider",
        borderRadius: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
        // maxWidth: "300px",
        boxShadow: 7,
        display: "inline-block",
        p: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {icon}
        <Typography sx={{ whiteSpace: "nowrap" }}>
          {workout ? workout.name : phase?.name}
        </Typography>
      </Box>
    </Box>
  );
}
