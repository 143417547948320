import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { useAppDispatch } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { updateClientEditLocal } from "src/slices/clientEditSlice";

type Props = {
  userId: string;
  defaultIsTestUser?: boolean;
  open: boolean;
  onClose: () => void;
};

export default function DeleteNowDialog({
  userId,
  defaultIsTestUser,
  open,
  onClose,
}: Props) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isTestUser, setIsTestUser] = useState<null | boolean>(
    defaultIsTestUser || null,
  );

  return (
    <Dialog
      onClose={() => {
        onClose();
      }}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleWithClose
        onClose={() => {
          onClose();
        }}
      >
        {isTestUser === null ? "Is this a test user?" : "Delete Now"}
      </DialogTitleWithClose>
      <DialogContent>
        <Typography>
          {isTestUser === null
            ? `If this client was a test user the system will mark them as such so the trainer isn't penalized.`
            : `This will immediately cancel the client and remove their access to the app. Their data will be irrecoverably deleted immediately in compliance with the GDPR.`}
        </Typography>
      </DialogContent>
      {isTestUser === null ? (
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setIsTestUser(true);
            }}
          >
            Yes, they&apos;re a test user
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setIsTestUser(false);
            }}
          >
            No they&apos;re not
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              onClose();
            }}
          >
            Nevermind
          </Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              api.clients
                .deleteNow(userId, isTestUser)
                .then((newClient) => {
                  enqueueSnackbar("Deleted", {
                    variant: "success",
                  });

                  dispatch(updateClientEditLocal(newClient));
                })
                .catch(() => {
                  enqueueSnackbar("Error deleting", {
                    variant: "error",
                  });
                });

              onClose();
            }}
          >
            Delete now
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
