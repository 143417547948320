import { Box, Button, Typography } from "@mui/material";
import ClientIconGrid from "src/components/ClientOverview/ClientIconGrid";
import GoalCard from "src/components/goals/GoalCard";
import ClientProfilePicture from "src/components/misc/ClientProfilePicture";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppSelector } from "src/hooks/stateHooks";
import { useGetClientQuery } from "src/slices/api/clientApi";
import { WeekPreview } from "./WeekPreview";

type Props = {
  userId: string;
};

export function ChatFlyout({ userId }: Props) {
  const { data: client } = useGetClientQuery(userId);
  const chatVisualState = useAppSelector((state) => state.chat.chatMode);

  if (!client) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Box sx={{ borderBottom: 0.5, borderColor: "divider", p: 1 }}>
        <Button
          variant="text"
          size="small"
          href={chatVisualState === "full" ? undefined : `/clients/${userId}`}
          onTouchStart={(event) => {
            event.stopPropagation();
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        >
          <ClientProfilePicture dimension={32} userId={userId} />
          <Box sx={{ ml: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {client.full_name}
            </Typography>
          </Box>
        </Button>
      </Box>
      <ClientIconGrid size="small" client={client} />
      <WeekPreview userId={userId} />
      <GoalCard client={client} disableEditing sx={{ m: 1 }} />
      <Box sx={{ m: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>Quick notes</Typography>
        <Typography
          sx={{ mb: 2, whiteSpace: "pre-line", wordWrap: "break-word" }}
        >
          {client?.quick_notes || "None"}
        </Typography>
      </Box>
    </>
  );
}
