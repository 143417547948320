import { Box, Paper, Stack, Typography } from "@mui/material";
import { useCampaigns } from "@trainwell/features";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import RestrictAccess from "src/components/misc/RestrictAccess";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { CampaignSidebarCell } from "./CampaignSidebarCell";

const drawerWidth = 240;

export function CampaignLayout() {
  const { data: campaigns, isLoading: isLoadingCampaigns } = useCampaigns();

  const displayedCampaigns = useMemo(() => {
    if (isLoadingCampaigns) {
      return [];
    }

    const newInfluencers = [...(campaigns ?? [])];

    newInfluencers.sort((a, b) =>
      (b.date_content_released as string).localeCompare(
        a.date_content_released as string,
      ),
    );

    return newInfluencers;
  }, [campaigns]);

  return (
    <RestrictAccess location={"campaigns"} showNoAccessMessage>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Paper
          square
          elevation={0}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            maxHeight: "100%",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column",
            borderRight: 1,
            borderColor: "divider",
          }}
        >
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
              px: 2,
              py: 1,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Typography variant="h1">Campaigns</Typography>
            <Typography variant="overline">
              Showing {(displayedCampaigns ?? []).length} campaigns
            </Typography>
          </Box>
          <Box sx={{ overflowY: "auto", flex: 1 }}>
            {isLoadingCampaigns ? (
              <LoadingComponent message="Loading campaigns" />
            ) : (
              <Stack direction="column">
                {displayedCampaigns.map((campaign) => (
                  <CampaignSidebarCell key={campaign._id} campaign={campaign} />
                ))}
              </Stack>
            )}
          </Box>
        </Paper>
        <Box sx={{ flexGrow: 1, overflowX: "hidden" }}>
          <Outlet />
        </Box>
      </Box>
    </RestrictAccess>
  );
}
