import { Dialog, DialogContent } from "@mui/material";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";

type Props = {
  width?: number | null;
  height?: number | null;
  url: string;
  combineBottom: boolean;
  isFromMe: boolean;
};

export default function ImageMessage({
  width,
  height,
  url,
  combineBottom,
  isFromMe,
}: Props) {
  const [imageDialogOpen, setImageDialogOpen] = useState(false);

  return (
    <>
      {width && height ? (
        <img
          width={250}
          height={(250 / width) * height}
          src={url}
          style={{
            borderRadius: isFromMe
              ? `10px 10px ${!combineBottom ? "2px" : "10px"} 10px`
              : `10px 10px 10px ${!combineBottom ? "2px" : "10px"}`,
            display: "block",
          }}
          alt="chat media"
          onClick={() => {
            setImageDialogOpen(true);
          }}
        />
      ) : (
        <img
          src={url}
          height={150}
          style={{
            width: "100%",
            borderRadius: isFromMe
              ? `10px 10px ${!combineBottom ? "2px" : "10px"} 10px`
              : `10px 10px 10px ${!combineBottom ? "2px" : "10px"}`,
            cursor: "pointer",
            display: "block",
          }}
          alt="chat media"
          onClick={() => {
            setImageDialogOpen(true);
          }}
        />
      )}
      <Dialog
        onClose={() => {
          setImageDialogOpen(false);
        }}
        aria-labelledby="client-name"
        open={imageDialogOpen}
      >
        <DialogTitleWithClose
          onClose={() => {
            setImageDialogOpen(false);
          }}
        >
          Image
        </DialogTitleWithClose>
        <DialogContent dividers>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              width={600}
              height={600}
              src={url}
              style={{
                objectFit: "contain",
              }}
              alt="chat media"
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
