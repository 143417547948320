import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import { Box, Chip, Popover, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import useActionItem from "src/hooks/useActionItem";
import { getActionItemDetails } from "src/lib/actionItem";
import { getActionItemStatus } from "src/lib/officeHours";
import {
  makeSelectActionItemsForClient,
  selectActionItemById,
} from "src/slices/actionItemSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import CheckInDialog from "../misc/CheckInDialog";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

type Props = {
  userId: string;
  showChatBackground?: boolean;
  border?: boolean;
  background?: boolean;
};

export default function ActionItemRow({
  userId,
  showChatBackground,
  border,
  background,
}: Props) {
  const selectActionItemsForClient = useMemo(
    makeSelectActionItemsForClient,
    [],
  );
  const actionItems = useAppSelector((state) =>
    selectActionItemsForClient(state, userId),
  );

  if (actionItems.length <= 0) {
    return null;
  }

  const collapseIntoScrollable = actionItems.length >= 5;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: collapseIntoScrollable ? "nowrap" : "wrap",
        overflowX: collapseIntoScrollable ? "auto" : undefined,
        listStyle: "none",
        px: showChatBackground ? 0.5 : undefined,
        py: showChatBackground ? 0.25 : undefined,
        borderBottom: showChatBackground || border ? 1 : undefined,
        borderTop: border ? 1 : undefined,
        borderColor: "divider",
        backgroundColor: (theme) =>
          showChatBackground || background
            ? theme.palette.background.paper
            : undefined,
      }}
    >
      {actionItems.map((actionItem) => (
        <ActionItemChip key={actionItem.id} actionItemId={actionItem.id} />
      ))}
    </Box>
  );
}

type PropsChip = {
  actionItemId: string;
};

function ActionItemChip({ actionItemId }: PropsChip) {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const { handleActionItemClick, handleActionItemClearButton } =
    useActionItem();
  const actionItem = useAppSelector((state) =>
    selectActionItemById(state, actionItemId),
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const chatVisualState = useAppSelector((state) => state.chat.chatMode);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const open = Boolean(anchorEl);

  if (!actionItem) {
    return null;
  }

  const status = getActionItemStatus(actionItem, trainer!.office_hours);
  const details = getActionItemDetails(actionItem);

  const color: "default" | "errorSurface" | "warningSurface" =
    status === "ok"
      ? "default"
      : status === "warn"
        ? "warningSurface"
        : "errorSurface";

  return (
    <>
      <ListItem>
        <Chip
          color={color}
          label={details.name}
          onClick={(event) => {
            event.stopPropagation();

            setAnchorEl(null);

            if (actionItem.type === "check_in_survey_send") {
              setIsDialogOpen(true);

              return;
            }

            if (chatVisualState === "full") {
              return;
            }

            handleActionItemClick(actionItem);
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
          onMouseEnter={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          onMouseLeave={() => {
            setAnchorEl(null);
          }}
          deleteIcon={
            details.clearTypes?.includes("clear_button") ? (
              <TaskAltRoundedIcon />
            ) : undefined
          }
          onDelete={
            details.clearTypes?.includes("clear_button")
              ? (event) => {
                  event.stopPropagation();

                  setAnchorEl(null);

                  handleActionItemClearButton(actionItem);
                }
              : undefined
          }
        />
      </ListItem>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        disableRestoreFocus
      >
        <Box sx={{ p: 1, maxWidth: "400px" }}>
          <Stack spacing={0.5} direction="column">
            {details.notes && <Typography>{details.notes}</Typography>}
            <Typography variant="overline">
              Sent{" "}
              {format(
                new Date(actionItem.date_created),
                "iiii, MMM d, yyyy 'at' h:mm a",
              )}
            </Typography>
          </Stack>
        </Box>
      </Popover>
      {actionItem.type === "check_in_survey_send" && (
        <CheckInDialog
          userId={actionItem.user_id}
          actionItemId={actionItemId}
          open={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
          }}
        />
      )}
    </>
  );
}
