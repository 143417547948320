import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import PersonOffRoundedIcon from "@mui/icons-material/PersonOffRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  Card,
  Chip,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import {
  type TestAnalyticId,
  type TestAnalyticsSnapshot,
} from "@trainwell/types";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { selectTestById } from "src/slices/testsSlice";
import { SnapshotMetricCell } from "./SnapshotMetricCell";

export function TestInfo() {
  const { testId } = useParams();
  const test = useAppSelector((state) => selectTestById(state, testId ?? ""));
  const [snapshots, setSnapshots] = useState<TestAnalyticsSnapshot[]>([]);
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);

  useEffect(() => {
    if (!testId) {
      return;
    }

    setSnapshots([]);

    api.tests.getAnalyticsSnapshots(testId).then((data) => {
      // Sort earlier to latest
      setSnapshots(
        data.snapshots.sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        }),
      );
    });
  }, [testId]);

  if (!test) {
    return <Typography>Error finding test</Typography>;
  }

  const snapshotMetrics = Object.keys(
    snapshots.at(0)?.variants.at(0)?.analytics ?? {},
  ).filter((metricId) =>
    test.visible_analytic_ids?.includes(metricId as TestAnalyticId),
  );

  return (
    <>
      {test.description && (
        <Typography sx={{ mb: 1 }}>{test.description}</Typography>
      )}
      {test.date_archived && (
        <Card
          sx={{
            px: 2,
            py: 0.5,
            mb: 2,
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
            display: "flex",
            alignItems: "center",
          }}
          variant="outlined"
        >
          <WarningRoundedIcon fontSize="inherit" />
          <Typography sx={{ ml: 1 }}>
            Archived on{" "}
            {format(test.date_archived, "MMMM do, yyyy 'at' h:mm a")}
          </Typography>
        </Card>
      )}
      <Stack direction="row" spacing={2} mb={4}>
        <Chip
          label={test.is_active ? "Active" : "Inactive"}
          icon={
            test.is_active ? <PlayArrowRoundedIcon /> : <PauseRoundedIcon />
          }
          color={test.is_active ? "successSurface" : "default"}
        />
        <Chip
          label={test.is_enrolling ? "Enrolling" : "Not enrolling"}
          icon={
            test.is_enrolling ? (
              <PersonAddRoundedIcon />
            ) : (
              <PersonOffRoundedIcon />
            )
          }
          color={test.is_enrolling ? "successSurface" : "default"}
        />
        {test.trainer_dependent && (
          <Chip
            label={"Trainer dependent"}
            icon={<FitnessCenterRoundedIcon />}
          />
        )}
      </Stack>
      {test.trainer_dependent && (
        <Card variant="outlined" sx={{ p: 2, mb: 4 }}>
          <Typography sx={{ fontWeight: "bold" }}>
            Trainer dependent test
          </Typography>
          <Typography sx={{ mb: 1 }}>
            This test will only enroll clients with the following trainers:
          </Typography>
          {test.supported_trainer_ids?.map((trainerId) => (
            <Typography key={trainerId}>
              {trainerNames.find((t) => t.trainer_id === trainerId)
                ?.full_name ?? "Unknown trainer"}
            </Typography>
          ))}
        </Card>
      )}
      <Card
        variant="outlined"
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        <Stack direction="column" divider={<Divider />}>
          <Grid
            container
            columns={test.variants.length + 1}
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          >
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Metric
              </Typography>
            </Grid>
            {test.variants.map((variant) => {
              const snapshot = snapshots
                .at(-1)
                ?.variants.find((v) => v.variant_id === variant.id);

              return (
                <Grid
                  item
                  xs={1}
                  key={variant.id}
                  sx={{ pt: 1, border: 0.5, borderColor: "divider" }}
                >
                  <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                    {variant.name}
                  </Typography>
                  <Typography variant="overline" sx={{ textAlign: "center" }}>
                    {variant.id}
                  </Typography>
                  <Typography
                    variant="overline"
                    sx={{ textAlign: "center", mb: 1 }}
                  >
                    Enrolling {Math.round(variant.include_percent * 100)}%
                  </Typography>
                  <Grid
                    container
                    columns={3}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.background.paper,
                    }}
                  >
                    <Grid
                      item
                      xs={
                        snapshots[0]?.variants.find(
                          (v) => v.variant_id === variant.id,
                        )?.is_control
                          ? 3
                          : 1
                      }
                      sx={{
                        border: 0.5,
                        borderTop: 1,
                        borderBottom: 0,
                        borderColor: "divider",
                      }}
                    >
                      <Typography
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Value
                      </Typography>
                      {snapshot && (
                        <Tooltip title={"Sample size"} disableInteractive>
                          <Typography
                            variant="overline"
                            sx={{ textAlign: "center" }}
                          >
                            n=
                            {snapshot.sample_n.toLocaleString()}
                          </Typography>
                        </Tooltip>
                      )}
                    </Grid>
                    {!snapshots[0]?.variants.find(
                      (v) => v.variant_id === variant.id,
                    )?.is_control && (
                      <>
                        <Grid
                          item
                          xs={1}
                          sx={{
                            border: 0.5,
                            borderTop: 1,
                            borderBottom: 0,
                            borderColor: "divider",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            Effect size
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sx={{
                            border: 0.5,
                            borderTop: 1,
                            borderBottom: 0,
                            borderColor: "divider",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            Effect over time
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          {snapshotMetrics.map((metricId) => (
            <SnapshotMetricCell
              key={metricId}
              test={test}
              metricId={metricId}
              snapshots={snapshots}
            />
          ))}
        </Stack>
      </Card>
    </>
  );
}
