import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import BiotechRoundedIcon from "@mui/icons-material/BiotechRounded";
import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import CameraEnhanceRoundedIcon from "@mui/icons-material/CameraEnhanceRounded";
import CasinoRoundedIcon from "@mui/icons-material/CasinoRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import CodeRoundedIcon from "@mui/icons-material/CodeRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import HouseboatRoundedIcon from "@mui/icons-material/HouseboatRounded";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import LocalLaundryServiceRoundedIcon from "@mui/icons-material/LocalLaundryServiceRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";
import RocketLaunchRoundedIcon from "@mui/icons-material/RocketLaunchRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import SmartphoneRoundedIcon from "@mui/icons-material/SmartphoneRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import SupportRoundedIcon from "@mui/icons-material/SupportRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ControlBar from "src/components/misc/ControlBar";
import RestrictAccess from "src/components/misc/RestrictAccess";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { setChatMode } from "src/slices/chatSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

const drawerWidth = 240;

export default function AdminLayout() {
  const dispatch = useAppDispatch();
  const [analyticsOpen, setAnalyticsOpen] = useState(false);
  const [employeesOpen, setEmployeesOpen] = useState(false);
  const [ticketsOpen, setTicketsOpen] = useState(false);
  const [marketingOpen, setMarketingOpen] = useState(false);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [drawerOpen, setDrawerOpen] = useState(true);

  useEffect(() => {
    dispatch(setChatMode("drag_minimized"));
  }, []);

  if (!trainer?.is_admin) {
    return <Typography>Error</Typography>;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <ControlBar
        leftContent={
          <IconButton
            aria-label="toggle drawer"
            onClick={() => {
              setDrawerOpen(!drawerOpen);
            }}
            size="small"
          >
            {drawerOpen ? <ChevronLeftRoundedIcon /> : <MenuRoundedIcon />}
          </IconButton>
        }
      />
      {drawerOpen && (
        <Box
          sx={{
            minWidth: drawerWidth,
            maxWidth: drawerWidth,
            width: drawerWidth,
            backgroundColor: (theme) => theme.palette.background.paper,
            borderRight: 1,
            borderColor: "divider",
            height: "100vh",
            pt: "38px",
            overflowY: "auto",
          }}
        >
          <List>
            <RestrictAccess location={"analytics"}>
              <ListItemButton
                onClick={() => {
                  setAnalyticsOpen(!analyticsOpen);
                }}
              >
                <ListItemIcon>
                  <TimelineRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Analytics"}
                  primaryTypographyProps={{ sx: { fontWeight: "bold" } }}
                />
                {analyticsOpen ? (
                  <ExpandLessRoundedIcon />
                ) : (
                  <ExpandMoreRoundedIcon />
                )}
              </ListItemButton>
              <Collapse in={analyticsOpen} timeout="auto" unmountOnExit>
                <RestrictAccess location={"tests"}>
                  <NestedListItem
                    name="Tests"
                    path="/admin/analytics/tests"
                    icon={<BiotechRoundedIcon />}
                  />
                </RestrictAccess>
                <RestrictAccess location={"product_metrics"}>
                  <NestedListItem
                    name="Product metrics"
                    path="/admin/analytics/product-metrics"
                    icon={<SmartphoneRoundedIcon />}
                  />
                </RestrictAccess>
                <NestedListItem
                  name="Lead funnel"
                  path="/admin/analytics/lead-funnel"
                  icon={<BarChartRoundedIcon />}
                />
                <NestedListItem
                  name="Client funnel"
                  path="/admin/analytics/client-funnel"
                  icon={<BarChartRoundedIcon />}
                />
              </Collapse>
            </RestrictAccess>
            <ListItemButton
              onClick={() => {
                setEmployeesOpen(!employeesOpen);
              }}
            >
              <ListItemIcon>
                <BusinessRoundedIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Company"}
                primaryTypographyProps={{ sx: { fontWeight: "bold" } }}
              />
              {employeesOpen ? (
                <ExpandLessRoundedIcon />
              ) : (
                <ExpandMoreRoundedIcon />
              )}
            </ListItemButton>
            <Collapse in={employeesOpen} timeout="auto" unmountOnExit>
              <RestrictAccess location={"client_list"}>
                <NestedListItem
                  name="Clients"
                  path="/admin/company/clients"
                  icon={<GroupsRoundedIcon />}
                />
              </RestrictAccess>
              <RestrictAccess location={"coach_list"}>
                <NestedListItem
                  name="Team"
                  path="/admin/company/team"
                  icon={<PeopleRoundedIcon />}
                />
              </RestrictAccess>
              <RestrictAccess location={"candidate_list"}>
                <NestedListItem
                  name="Candidates"
                  path="/admin/company/candidates"
                  icon={<PersonSearchRoundedIcon />}
                />
              </RestrictAccess>
              <RestrictAccess location={"vacations"}>
                <NestedListItem
                  name="Vacations"
                  path="/admin/company/vacations"
                  icon={<HouseboatRoundedIcon />}
                />
              </RestrictAccess>
              <RestrictAccess location={"coach_metrics"}>
                <NestedListItem
                  name="Metrics"
                  path="/admin/company/metrics"
                  icon={<AssessmentRoundedIcon />}
                />
              </RestrictAccess>
              <RestrictAccess location={"company_page"}>
                <NestedListItem
                  name="Settings"
                  path="/admin/company/settings"
                  icon={<SettingsRoundedIcon />}
                />
              </RestrictAccess>
            </Collapse>
            <RestrictAccess location={"tickets"}>
              <ListItemButton
                onClick={() => {
                  setTicketsOpen(!ticketsOpen);
                }}
              >
                <ListItemIcon>
                  <SupportRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Tickets"}
                  primaryTypographyProps={{ sx: { fontWeight: "bold" } }}
                />
                {ticketsOpen ? (
                  <ExpandLessRoundedIcon />
                ) : (
                  <ExpandMoreRoundedIcon />
                )}
              </ListItemButton>
              <Collapse in={ticketsOpen} timeout="auto" unmountOnExit>
                <NestedListItem
                  name="Tech issues"
                  path="/admin/tickets/tech_issue"
                  icon={<BugReportRoundedIcon />}
                />
                <NestedListItem
                  name="Customer support"
                  path="/admin/tickets/customer_support"
                  icon={<SupportAgentRoundedIcon />}
                />
              </Collapse>
            </RestrictAccess>
            <RestrictAccess location={"campaigns"}>
              <ListItemButton
                onClick={() => {
                  setMarketingOpen(!marketingOpen);
                }}
              >
                <ListItemIcon>
                  <RocketLaunchRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Growth"}
                  primaryTypographyProps={{ sx: { fontWeight: "bold" } }}
                />
                {marketingOpen ? (
                  <ExpandLessRoundedIcon />
                ) : (
                  <ExpandMoreRoundedIcon />
                )}
              </ListItemButton>
              <Collapse in={marketingOpen} timeout="auto" unmountOnExit>
                <NestedListItem
                  name="Influencers"
                  path="/admin/influencers"
                  icon={<CameraEnhanceRoundedIcon />}
                />
                <NestedListItem
                  name="Campaigns"
                  path="/admin/campaigns"
                  icon={<YouTubeIcon />}
                />
                <NestedListItem
                  name="Short Urls"
                  path="/admin/short-urls"
                  icon={<LinkRoundedIcon />}
                />
              </Collapse>
            </RestrictAccess>
            <RestrictAccess location={"random_client"}>
              <ListItem
                path={"/admin/random-clients"}
                label={"Random clients"}
                icon={<CasinoRoundedIcon />}
              />
            </RestrictAccess>
            <ListItem
              path={"/admin/trainer-availability"}
              label={"Trainer availability"}
              icon={<CalendarMonthRoundedIcon />}
            />
            <RestrictAccess location={"data_cleaner"}>
              <ListItem
                path={"/admin/data-cleaner"}
                label={"Data cleaner"}
                icon={<LocalLaundryServiceRoundedIcon />}
              />
            </RestrictAccess>
            <RestrictAccess location={"docs"}>
              <ListItem
                path={"/admin/api-docs-nodejs"}
                label={"Api docs (nodejs)"}
                icon={<CodeRoundedIcon />}
              />
              <ListItem
                path={"/admin/api-docs-python"}
                label={"Api docs (python)"}
                icon={<CodeRoundedIcon />}
              />
            </RestrictAccess>
          </List>
        </Box>
      )}
      <Box
        component={"main"}
        sx={{
          marginTop: "38px",
          flex: 1,
          height: "calc(100vh - 38px)",
          overflowY: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

type ListItemProps = {
  path: string;
  label: string;
  icon: React.ReactNode;
};

function ListItem({ icon, label, path }: ListItemProps) {
  const location = useLocation();
  const selectred = location.pathname === path;

  return (
    <ListItemButton href={path} selected={selectred}>
      <ListItemIcon
        sx={{
          color: (theme) =>
            selectred ? theme.palette.primary.main : undefined,
          minWidth: "48px",
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          sx: {
            fontWeight: selectred ? "bold" : undefined,
            color: (theme) =>
              selectred ? theme.palette.primary.main : undefined,
          },
        }}
      />
    </ListItemButton>
  );
}

type NestedListItemProps = {
  name: string;
  path: string;
  icon: React.ReactNode;
  disabled?: boolean;
};

function NestedListItem({
  name,
  path,
  icon,
  disabled = false,
}: NestedListItemProps) {
  const location = useLocation();
  const selectred = location.pathname.includes(path);

  return (
    <ListItemButton
      href={path}
      sx={{ pl: 4, mx: 1, borderRadius: 1 }}
      selected={selectred}
      disabled={disabled}
    >
      <ListItemIcon
        sx={{
          color: (theme) =>
            selectred ? theme.palette.primary.main : undefined,
          minWidth: "48px",
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={name}
        primaryTypographyProps={{
          sx: {
            fontWeight: selectred ? "bold" : undefined,
            color: (theme) =>
              selectred ? theme.palette.primary.main : undefined,
          },
        }}
      />
    </ListItemButton>
  );
}
