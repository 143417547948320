import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PodcastsRoundedIcon from "@mui/icons-material/PodcastsRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { alpha, Box, CardActionArea, Stack, Typography } from "@mui/material";
import { type Campaign } from "@trainwell/features";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { getDateFromDatabase } from "src/lib/date";

type Props = {
  campaign: Campaign;
};

export function CampaignSidebarCell({ campaign }: Props) {
  const { campaignId: queryCampaignId } = useParams();

  const selected = queryCampaignId === campaign._id;

  return (
    <CardActionArea
      href={`/admin/campaigns/${campaign._id}`}
      sx={{
        p: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: 1,
        borderColor: "divider",
        backgroundColor: (theme) =>
          selected
            ? alpha(theme.palette.primary.main, 0.1)
            : theme.palette.background.paper,
      }}
    >
      <Stack alignItems={"center"} spacing={1} direction={"row"}>
        {campaign.type === "youtube" ? (
          <YouTubeIcon />
        ) : campaign.type === "podcast" ? (
          <PodcastsRoundedIcon />
        ) : campaign.type === "email" ? (
          <EmailRoundedIcon />
        ) : campaign.type === "instagram" ? (
          <InstagramIcon />
        ) : campaign.type === "tik_tok" ? (
          <PhoneAndroidIcon />
        ) : (
          <QuestionMarkRoundedIcon />
        )}
        <Box>
          <Typography>
            {format(
              getDateFromDatabase(campaign.date_content_released),
              "MMM d, yyyy",
            )}
          </Typography>
        </Box>
      </Stack>
    </CardActionArea>
  );
}
