import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Popover,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import type { ActionItem, ActionItemType } from "@trainwell/types";
import { useEffect, useRef, useState } from "react";
import { getActionItemDetails } from "src/lib/actionItem";

const icon = <CheckBoxOutlineBlankRoundedIcon fontSize="small" />;
const checkedIcon = <CheckBoxRoundedIcon fontSize="small" />;

const actionItemTypes: ActionItemType[] = [
  "custom",
  "1_day_unmessaged_weekday",
  "2_day_unmessaged",
  "3_day_unmessaged",
  "7_day_unmessaged",
  "follow_up_on_welcome_video",
  "upcoming_workout",
  "workout_review",
  "progress_review",
  "missed_habit",
  "missed_workout_streak",
  "user_completed_first_habit",
  "respond",
  "read_chat",
  "new_client",
  "check_in_survey_send",
  "check_in_survey_reminder",
  "check_in_survey_completed",
  "send_pre_check_in_survey",
  "switched_coach_to_you",
  "user_requested_cancelation",
  "client_returned_from_cancel",
  "client_returned_from_pause",
  "client_unhidden",
  "inactive_user",
];

const actionItemOptions: {
  type: ActionItemType;
  name: string;
  icon: JSX.Element;
}[] = actionItemTypes.map((t) => {
  return {
    type: t,
    name: getActionItemDetails({ type: t } as ActionItem).name,
    icon: getActionItemDetails({ type: t } as ActionItem).icon,
  };
});

type Props = {
  value: ActionItemType[];
  onChange: (actionItemTypes: ActionItemType[]) => void;
  variant?: "button" | "chip";
};

export default function ActionItemFilterButton({
  value,
  onChange,
  variant = "button",
}: Props) {
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  const open = Boolean(anchorEl);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 150);
    }
  }, [open]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {variant === "chip" ? (
          <Chip
            label={"Action items"}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
            size="small"
            variant={value.length === 0 ? "outlined" : "filled"}
            color={value.length === 0 ? "default" : "blueSurface"}
            sx={{
              borderStyle: value.length === 0 ? "dashed" : undefined,
            }}
            avatar={value.length ? <Avatar>{value.length}</Avatar> : undefined}
          />
        ) : (
          <Tooltip title="Filter" enterDelay={1000}>
            <Button
              size="small"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
              variant="text"
              sx={{
                backgroundColor: (theme) =>
                  value.length > 0
                    ? alpha(theme.palette.primary.main, 0.075)
                    : undefined,
                minWidth: "20px",
              }}
            >
              <FilterListRoundedIcon />
              {value.length > 0 && (
                <Button
                  size="small"
                  variant="text"
                  onMouseOver={(event) => {
                    event.stopPropagation();
                  }}
                  onMouseEnter={(event) => {
                    event.stopPropagation();
                  }}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  onClick={(event) => {
                    event.stopPropagation();

                    onChange([]);
                  }}
                  sx={{
                    py: 0.25,
                    px: 0.5,
                    minWidth: "20px",
                    "& .MuiButton-endIcon": {
                      ml: 0.5,
                    },
                    lineHeight: 1.5,
                  }}
                  endIcon={<ClearRoundedIcon />}
                >
                  {value.length}
                </Button>
              )}
            </Button>
          </Tooltip>
        )}
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: { p: 2 },
          },
        }}
      >
        <Typography variant="h2" sx={{ mb: 2 }}>
          Filter
        </Typography>
        <Autocomplete
          multiple
          size="small"
          options={actionItemOptions.map((o) => o.type)}
          disableCloseOnSelect
          openOnFocus
          getOptionLabel={(type) =>
            actionItemOptions.find((o) => o.type === type)?.name ?? ""
          }
          value={value}
          onChange={(event, newValues) => {
            onChange(newValues as any);
          }}
          renderOption={(props, type, { selected }) => {
            const option = actionItemOptions.find((o) => o.type === type)!;
            return (
              <li
                {...props}
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <option.icon.type
                  {...option.icon.props}
                  sx={{ fontSize: "inherit", mr: 1 }}
                />
                {option.name}
              </li>
            );
          }}
          style={{ width: 400 }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={inputRef}
              label="Type"
              placeholder="Filter by..."
            />
          )}
        />
      </Popover>
    </>
  );
}
