import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import EngineeringRoundedIcon from "@mui/icons-material/EngineeringRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import type { Ticket } from "@trainwell/types";

export function getTypeIcon(type: Ticket["type"]) {
  if (type === "tech_issue") {
    return <BugReportRoundedIcon />;
  } else if (type === "customer_support") {
    return <SupportAgentRoundedIcon />;
  } else if (type === "request") {
    return <EngineeringRoundedIcon />;
  } else {
    return null;
  }
}

export function getTypeName(type: Ticket["type"]) {
  if (type === "tech_issue") {
    return "Tech issue";
  } else if (type === "customer_support") {
    return "Customer support";
  } else if (type === "request") {
    return "Request";
  }
}
