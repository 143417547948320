import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import {
  Badge,
  Box,
  CardActionArea,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { trainerAccessRoles } from "@trainwell/types";
import RestrictAccess from "src/components/misc/RestrictAccess";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectCoachById } from "src/slices/trainersSlice";

type Props = {
  trainerId: string;
  isSelected: boolean;
  type: "employees" | "candidates";
};

export default function CoachCell({ trainerId, isSelected, type }: Props) {
  const trainer = useAppSelector((state) => selectCoachById(state, trainerId));

  if (!trainer) {
    return <Typography>Error finding trainer</Typography>;
  }

  return (
    <CardActionArea
      sx={{
        p: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: 1,
        borderColor: "divider",
        backgroundColor: (theme) =>
          isSelected ? alpha(theme.palette.primary.main, 0.2) : undefined,
      }}
      href={
        trainer.is_candidate
          ? `/admin/company/candidates/${trainerId}`
          : `/admin/company/team/${trainerId}`
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Badge
          variant="dot"
          color="success"
          invisible={trainer.is_employee !== true || trainer.is_active !== true}
        >
          <img
            style={{
              borderRadius: "15px",
              display: "block",
            }}
            width={30}
            height={30}
            src={
              trainer.headshot_url
                ? trainer.headshot_url
                : "/assets/profile.png"
            }
            alt="trainer headshot"
          />
        </Badge>
        <Box sx={{ ml: 1 }}>
          <Typography
            sx={{
              color: (theme) =>
                trainer.is_employee ||
                trainer.is_candidate ||
                (trainer.is_staff && !trainer.was_employee)
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
            }}
          >
            {trainer.full_name}
          </Typography>
          {type === "employees" &&
            (trainer.is_employee || trainer.was_employee) && (
              <>
                {trainer.is_employee === false && (
                  <Typography variant="overline">Not employed</Typography>
                )}
                {trainer.disable_access !== true && (
                  <Typography variant="overline">
                    {trainer.automatically_toggle_active
                      ? "Auto active"
                      : "No auto active"}
                  </Typography>
                )}
                {trainer.disable_access === true && (
                  <Typography variant="overline">Access disabled</Typography>
                )}
              </>
            )}
          <RestrictAccess location={"access_roles"}>
            {trainer.is_staff && (
              <Grid container spacing={0.5}>
                {trainer.access_roles?.map((role, index) => {
                  return (
                    <Grid item key={index}>
                      <Chip
                        label={trainerAccessRoles[role]}
                        variant="filled"
                        size="small"
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </RestrictAccess>
        </Box>
      </Box>
      <Box>
        <Tooltip title="Ghost" disableInteractive>
          <IconButton
            href={`/?trainer_id=${trainer.trainer_id}`}
            onClick={(event) => {
              event.stopPropagation();
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          >
            <LoginRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </CardActionArea>
  );
}
