import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import DataArrayRoundedIcon from "@mui/icons-material/DataArrayRounded";
import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import {
  Box,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import type { ExerciseGroup } from "@trainwell/types";
import { formatDistanceToNow } from "date-fns";
import { useMemo, useState } from "react";
import { shallowEqual } from "react-redux";
import { Virtuoso } from "react-virtuoso";
import ExerciseIcon from "src/components/misc/ExerciseIcon";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  exerciseGroups,
  exerciseGroupsFuse,
  exerciseMap,
  getExerciseById,
} from "src/lib/exercises";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import { useGetSwapSuggestionsQuery } from "src/slices/api/exercisesApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { selectExerciseById, swapExercise } from "src/slices/workoutSlice";
import SearchField from "../misc/SearchField";

type Props = {
  position: {
    mouseX: number;
    mouseY: number;
  } | null;
  onClose: () => void;
  exerciseId: string;
};

export function ExerciseSwapPopover({ position, onClose, exerciseId }: Props) {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const exercise = useAppSelector((state) =>
    selectExerciseById(state, exerciseId),
  );
  const isTemplate = useAppSelector((state) => state.workout.isTemplate);
  const [search, setSearch] = useState("");
  const trainer = useAppSelector(selectPrimaryTrainer);
  const latestSets = useAppSelector(
    (state) => state.client.client?.latest_sets,
    shallowEqual,
  );

  const filteredExerciseGroups = useMemo(() => {
    if (!position) {
      return [];
    }

    let list = JSON.parse(JSON.stringify(exerciseGroups)) as ExerciseGroup[];

    if (search !== "") {
      let refinedSearch = search;

      // Expand exercise shortcuts

      refinedSearch = refinedSearch
        .toLowerCase()
        .split(",")
        .map((t) => t.trim())
        .map((t) => {
          if (t === "mb") {
            return "medicine ball";
          } else if (t === "kb") {
            return "kettlebell";
          } else if (t === "db") {
            return "dumbbell";
          } else if (t === "bb") {
            return "barbell";
          }

          return t;
        })
        .join(",")
        .split(" ")
        .map((t) => {
          if (t === "mb") {
            return "medicine ball";
          } else if (t === "kb") {
            return "kettlebell";
          } else if (t === "db") {
            return "dumbbell";
          } else if (t === "bb") {
            return "barbell";
          }

          return t;
        })
        .join(" ");

      refinedSearch = refinedSearch.replaceAll("mb,", "medicine ball,");
      refinedSearch = refinedSearch.replaceAll("kb,", "kettlebell,");
      refinedSearch = refinedSearch.replaceAll("db,", "dumbbell,");
      refinedSearch = refinedSearch.replaceAll("bb,", "barbell,");

      // Handle AND and OR

      const andTerms = refinedSearch
        .split(",")
        .map((t) => t.trim())
        .filter((t) => !t.includes(" or "));

      const orTerms = refinedSearch
        .split(",")
        .map((t) => t.trim())
        .filter((t) => t.includes(" or "))
        .join(" ")
        .split(" or ")
        .map((t) => t.trim());

      const searchExpression: any = {
        $and: [
          ...andTerms.map((t) => {
            return { tags: t };
          }),
        ],
      };

      if (orTerms.length >= 2) {
        searchExpression.$and.push({
          $or: [
            ...orTerms.map((t) => {
              return { tags: t };
            }),
          ],
        });
      }

      // Useful logging to debug search
      // console.log(`Search: ${search}`);
      // console.log(`Search (refined): ${refinedSearch}`);
      // console.log(
      //   `Search expression: ${JSON.stringify(searchExpression, null, 1)}`,
      // );

      let fusedItems = exerciseGroupsFuse.search(searchExpression);

      const ids = list.map((item) => item.picker_group_id);

      fusedItems = fusedItems.filter((fusedItem) =>
        ids.includes(fusedItem.item.picker_group_id),
      );

      // console.log(fusedItems);

      list = fusedItems.map((fusedItem) => fusedItem.item);

      const favoriteExercises = trainer?.favorite_exercise_ids ?? [];

      list.sort((a, b) => {
        const isAFavorite = favoriteExercises.includes(a.picker_group_id);
        const isBFavorite = favoriteExercises.includes(b.picker_group_id);
        if (isAFavorite && !isBFavorite) {
          return -1;
        } else if (!isAFavorite && isBFavorite) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      const favoriteExercises = trainer?.favorite_exercise_ids ?? [];

      list.sort((a, b) => {
        const isAFavorite = favoriteExercises.includes(a.picker_group_id);
        const isBFavorite = favoriteExercises.includes(b.picker_group_id);
        if (isAFavorite && !isBFavorite) {
          return -1;
        } else if (!isAFavorite && isBFavorite) {
          return 1;
        }

        const lastUsedDateA = latestSets
          ? workoutLib.exercises.getLastUsedDate({
              client: { latest_sets: latestSets },
              exerciseSourceId: a.exercises[0].id,
            })
          : undefined;
        const lastUsedDateB = latestSets
          ? workoutLib.exercises.getLastUsedDate({
              client: { latest_sets: latestSets },
              exerciseSourceId: b.exercises[0].id,
            })
          : undefined;

        if (!lastUsedDateA && lastUsedDateB) {
          return 1;
        } else if (lastUsedDateA && !lastUsedDateB) {
          return -1;
        } else if (!lastUsedDateA && !lastUsedDateB) {
          return 0;
        } else {
          return lastUsedDateB!.getTime() - lastUsedDateA!.getTime();
        }
      });
    }

    return list;
  }, [search, latestSets, trainer?.favorite_exercise_ids, position]);

  const exerciseSource = getExerciseById(exercise?.exercise_master_id ?? "");

  const { data: allSwapSuggestions } = useGetSwapSuggestionsQuery({
    trainerId: trainer?.trainer_id ?? "",
  });

  console.log("suggestions", allSwapSuggestions);

  /** Exericise group ids */
  const swapExerciseGroupIds =
    (allSwapSuggestions ?? {})[exercise?.exercise_master_id ?? ""]
      ?.swap_suggestions ?? [];

  const swapExerciseIds = swapExerciseGroupIds.map((groupId) => {
    return workoutLib.exerciseGroups.getExerciseFromGroupId(
      exerciseGroups.find((group) => group.picker_group_id === groupId)!,
      isTemplate
        ? undefined
        : {
            equipment_detailed: client!.equipment_detailed,
            latest_sets: latestSets,
          },
      undefined,
    );
  });

  function handleClose() {
    onClose();
    setSearch("");
  }

  if (!exercise) {
    return <Typography>Finding exercise</Typography>;
  }

  if (!exerciseSource) {
    return (
      <Typography>
        Error: Could not find exercise in library. id=
        {exercise.exercise_master_id}
      </Typography>
    );
  }

  return (
    <Popover
      open={Boolean(position)}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        position !== null
          ? { top: position.mouseY, left: position.mouseX }
          : undefined
      }
    >
      <Box
        sx={{
          pb: 1,
          mt: 1,
          px: 2,
          flexShrink: 0,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <SwapHorizRoundedIcon fontSize="small" />
          <Typography variant="h2" sx={{ ml: 1 }}>
            Swap
          </Typography>
        </Box>
        <SearchField
          autoFocus
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
          onClear={() => {
            setSearch("");
          }}
          placeholder="Search all exercises"
        />
      </Box>
      {(search === "" && swapExerciseIds.length === 0) ||
      (search !== "" && filteredExerciseGroups.length === 0) ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            p: 1,
            height: "100%",
          }}
        >
          <DataArrayRoundedIcon
            sx={{ color: (theme) => theme.palette.text.secondary }}
          />
          <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>
            No exercises found
          </Typography>
        </Box>
      ) : search === "" ? (
        <Box sx={{ height: "400px", overflowY: "auto" }}>
          <Box sx={{ display: "flex", alignItems: "center", ml: 2, my: 1 }}>
            <AutoAwesomeRoundedIcon
              sx={{
                fontSize: "inherit",
                color: "#eab308",
              }}
            />
            <Typography variant="overline" sx={{ ml: 1 }}>
              Recommended
            </Typography>
          </Box>
          {swapExerciseIds.map((exerciseMasterId) => {
            const lastUsedDate = client
              ? workoutLib.exercises.getLastUsedDate({
                  client: client,
                  exerciseSourceId: exerciseMasterId,
                })
              : undefined;

            const exercise = exerciseMap[exerciseMasterId];

            return (
              <MenuItem
                key={exerciseMasterId}
                onClick={() => {
                  dispatch(
                    swapExercise({
                      forceSelectedExerciseIds: [exerciseId],
                      newExerciseMasterId: exerciseMasterId,
                    }),
                  );

                  handleClose();
                }}
                sx={{ py: 0.5 }}
              >
                <ListItemAvatar sx={{ display: "flex", alignItems: "center" }}>
                  <ExerciseIcon
                    exerciseMasterId={exerciseMasterId}
                    placement="left"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={exercise!.picker_group_id}
                  secondary={
                    lastUsedDate
                      ? `Used ${formatDistanceToNow(lastUsedDate)} ago`
                      : undefined
                  }
                />
              </MenuItem>
            );
          })}
        </Box>
      ) : (
        <Virtuoso
          style={{ height: "400px" }}
          data={filteredExerciseGroups}
          itemContent={(index, exerciseGroup) => {
            const exerciseMasterId =
              workoutLib.exerciseGroups.getExerciseFromGroupId(
                exerciseGroup,
                isTemplate
                  ? undefined
                  : {
                      equipment_detailed: client!.equipment_detailed,
                      latest_sets: latestSets,
                    },
                undefined,
              );

            const lastUsedDate = client
              ? workoutLib.exercises.getLastUsedDate({
                  client: client,
                  exerciseSourceId: exerciseMasterId,
                })
              : undefined;

            return (
              <MenuItem
                key={exerciseMasterId}
                onClick={() => {
                  dispatch(
                    swapExercise({
                      forceSelectedExerciseIds: [exerciseId],
                      newExerciseMasterId: exerciseMasterId,
                    }),
                  );

                  handleClose();
                }}
                sx={{ py: 0.5 }}
              >
                <ListItemAvatar sx={{ display: "flex", alignItems: "center" }}>
                  <ExerciseIcon
                    exerciseMasterId={exerciseMasterId}
                    placement="left"
                  />
                </ListItemAvatar>
                <ListItemText
                  sx={{ textWrap: "wrap" }}
                  primary={exerciseGroup.picker_group_id}
                  secondary={
                    lastUsedDate
                      ? `Used ${formatDistanceToNow(lastUsedDate)} ago`
                      : undefined
                  }
                />
              </MenuItem>
            );
          }}
        />
      )}
    </Popover>
  );
}
