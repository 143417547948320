import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  addMinutes,
  differenceInHours,
  format,
  parse,
  startOfToday,
} from "date-fns";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";
import { range } from "src/lib/misc";
import {
  addOfficeHour,
  changeOfficeHour,
  removeOfficeHour,
  selectPrimaryTrainer,
} from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";

const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export default function CheckpointSettings() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const changed = useRef(false);

  const onlyOneOfficeHour = true;

  useEffect(() => {
    if (hasError() || !trainer || !changed.current) {
      return;
    }

    changed.current = false;

    console.log("Save OH");

    dispatch(
      updateTrainer({
        trainer_id: trainer?.trainer_id,
        office_hours: trainer?.office_hours,
      }),
    );
  }, [dispatch, trainer?.office_hours]);

  function hasError() {
    let foundError = false;

    if (trainer?.office_hours) {
      trainer.office_hours.forEach((day, dayIndex) => {
        if (
          dayIndex > 0 &&
          dayIndex < 6 &&
          day.length < 2 &&
          !onlyOneOfficeHour
        ) {
          foundError = true;
        } else if (
          dayIndex === 6 &&
          day.length < 1 &&
          !trainer.date_stopped_saturday_checkpoints
        ) {
          foundError = true;
        }

        if (trainer.office_hours[dayIndex].length === 2) {
          const date0 = parse(
            trainer.office_hours[dayIndex][0].time_start,
            "HH:mm",
            new Date(),
          );

          const date1 = parse(
            trainer.office_hours[dayIndex][1].time_start,
            "HH:mm",
            new Date(),
          );

          if (Math.abs(differenceInHours(date0, date1)) < 6) {
            foundError = true;
          }
        }
      });
    }

    return foundError;
  }

  if (!trainer) {
    return null;
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="h2">Checkpoints</Typography>
        <Tooltip
          title="Try to clear all action items before your checkpoint. Items will
            turn orange to let you know if they're past due"
          placement="top"
        >
          <IconButton size="small">
            <HelpOutlineRoundedIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
      <Grid container columns={7}>
        {days.map((day, dayIndex) => {
          const maxOH = 1;

          const canAddTime =
            trainer.office_hours[dayIndex].length < maxOH &&
            !(trainer.date_stopped_saturday_checkpoints && dayIndex === 6);

          let canRemoveCheckpoint = trainer.office_hours[dayIndex].length > 1;

          if (trainer.date_stopped_saturday_checkpoints && dayIndex === 6) {
            canRemoveCheckpoint = true;
          }

          const dayError =
            dayIndex > 0 &&
            trainer.office_hours &&
            ((dayIndex === 6 &&
              !trainer.date_stopped_saturday_checkpoints &&
              trainer.office_hours[dayIndex].length < 1) ||
              (dayIndex !== 6 &&
                trainer.office_hours[dayIndex].length < 2 &&
                !onlyOneOfficeHour));

          return (
            <Grid
              item
              xs={1}
              key={day}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  mb: 1,
                  color: (theme) =>
                    dayError ? theme.palette.error.main : undefined,
                }}
              >
                {day}
              </Typography>
              {dayIndex === 0 ? (
                <Typography>Day off</Typography>
              ) : (
                <>
                  {trainer.office_hours && (
                    <>
                      {trainer.office_hours[dayIndex].map((time, timeIndex) => {
                        let hasError = false;
                        if (trainer.office_hours[dayIndex].length === 2) {
                          const date0 = parse(
                            trainer.office_hours[dayIndex][0].time_start,
                            "HH:mm",
                            new Date(),
                          );

                          const date1 = parse(
                            trainer.office_hours[dayIndex][1].time_start,
                            "HH:mm",
                            new Date(),
                          );

                          if (Math.abs(differenceInHours(date0, date1)) < 6) {
                            hasError = true;
                          }
                        }
                        return (
                          <Box
                            key={timeIndex}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 2,
                            }}
                          >
                            <FormControl size="small" error={hasError}>
                              <InputLabel>Time</InputLabel>
                              <Select
                                label="Time"
                                MenuProps={{ style: { maxHeight: "300px" } }}
                                value={format(
                                  parse(time.time_start, "HH:mm", new Date()),
                                  "h:mm aaa",
                                )}
                                onChange={(event) => {
                                  changed.current = true;
                                  const value = event.target.value as string;

                                  const time = format(
                                    parse(value, "h:mm aaa", new Date()),
                                    "HH:mm",
                                  );

                                  dispatch(
                                    changeOfficeHour({
                                      dayIndex: dayIndex,
                                      timeIndex: timeIndex,
                                      time: time,
                                    }),
                                  );
                                }}
                              >
                                {(dayIndex === 6
                                  ? range(10, 46)
                                  : range(
                                      trainerHasAccess(
                                        trainer.access_roles,
                                        "early_office_hour",
                                      )
                                        ? 10
                                        : trainer.allow_early_checkpoint
                                          ? 16
                                          : 26,
                                      46,
                                    )
                                ).map((i) => {
                                  const dateString = format(
                                    addMinutes(startOfToday(), i * 30),
                                    "h:mm aaa",
                                  );
                                  return (
                                    <MenuItem value={dateString} key={i}>
                                      {dateString}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {hasError && (
                                <FormHelperText>
                                  Must be six hours apart
                                </FormHelperText>
                              )}
                            </FormControl>
                            {canRemoveCheckpoint && (
                              <IconButton
                                color="error"
                                onClick={() => {
                                  changed.current = true;
                                  dispatch(
                                    removeOfficeHour({
                                      dayIndex: dayIndex,
                                      timeIndex: timeIndex,
                                    }),
                                  );
                                }}
                              >
                                <DeleteRoundedIcon />
                              </IconButton>
                            )}
                          </Box>
                        );
                      })}
                      {canAddTime && (
                        <Button
                          size="small"
                          onClick={() => {
                            changed.current = true;
                            dispatch(
                              addOfficeHour({
                                dayIndex: dayIndex,
                                time: "12:00",
                              }),
                            );
                          }}
                        >
                          Add time
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
