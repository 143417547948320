import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import RestrictAccess from "src/components/misc/RestrictAccess";
import { api } from "src/lib/trainwellApi";
import RandomClientCell from "./RandomClientCell";

export default function RandomClientsPage() {
  const [count, setCount] = useState(1);
  const [potentialClients, setPotentialClients] = useState<number>();
  const [clients, setClients] = useState<
    Awaited<ReturnType<(typeof api)["analytics"]["getRandomClients"]>>
  >([]);

  useEffect(() => {
    api.analytics.getRecentActiveClientCount().then((fetchedCount) => {
      setPotentialClients(fetchedCount);
    });
  }, []);

  return (
    <RestrictAccess location={"random_client"} showNoAccessMessage>
      <Box sx={{ p: 3 }}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          Random clients
        </Typography>
        <Typography sx={{ mb: 4 }}>
          Gets a number of random active clients who used the app in the last
          two weeks. Sample size: {potentialClients}
        </Typography>
        <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
          <TextField
            label="Number of clients to find"
            value={count}
            onChange={(event) => {
              setCount(Number(event.target.value));
            }}
            sx={{ mr: 2 }}
          />
          <Button
            variant="contained"
            onClick={() => {
              api.analytics.getRandomClients(count).then((fetchedClients) => {
                setClients(fetchedClients);
              });
            }}
          >
            Find random clients
          </Button>
        </Box>
        <Card
          variant="outlined"
          sx={{
            mb: 8,
          }}
        >
          <Stack direction="column">
            {clients.map((client) => (
              <RandomClientCell key={client.user_id} client={client} />
            ))}
          </Stack>
        </Card>
      </Box>
    </RestrictAccess>
  );
}
