import { Box, Slider, Typography } from "@mui/material";
import { useState } from "react";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

type Props = {
  latestClientLoad?: number;
  latestRetention?: number;
  latestFloor?: number;
};

export default function CompensationCalculator({
  latestClientLoad,
  latestRetention,
  latestFloor,
}: Props) {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const companyCompStructure = useAppSelector(
    (state) => state.company.compStructure,
  );
  const [clientLoad, setClientLoad] = useState(latestClientLoad ?? 130);
  const [retention, setRetention] = useState(
    Math.round(Math.max(latestRetention ?? 80, 70)),
  );

  if (!companyCompStructure) {
    return <LoadingComponent message="Getting comp structure details" />;
  }

  let estimatedSalary = Math.max(
    12 * companyCompStructure.floor +
      12 * clientLoad * (retention - (100 - companyCompStructure.bonus_base)),
    12 * (latestFloor ?? companyCompStructure.floor),
  );

  if (trainer?.is_lead_trainer) {
    estimatedSalary += 5000;
  }

  return (
    <Box sx={{ mr: 4 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Box sx={{ minWidth: "220px", mr: 2 }}>
          <Typography>Clients who could pay ({clientLoad}):</Typography>
          {latestClientLoad && (
            <Typography variant="overline">
              Latest: {latestClientLoad}
            </Typography>
          )}
        </Box>
        <Slider
          valueLabelDisplay="auto"
          value={clientLoad}
          step={1}
          min={0}
          max={300}
          onChange={(_, newValue) => {
            setClientLoad(newValue as number);
          }}
          marks={[
            {
              value: 0,
              label: "0",
            },
            {
              value: 300,
              label: "300",
            },
          ]}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Box sx={{ minWidth: "220px", mr: 2 }}>
          <Typography>Percent paid ({retention}%):</Typography>
          {latestRetention && (
            <Typography variant="overline">
              Latest: {latestRetention}%
            </Typography>
          )}
        </Box>
        <Slider
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value}%`}
          value={retention}
          step={1}
          min={70}
          max={100}
          onChange={(_, newValue) => {
            setRetention(newValue as number);
          }}
          marks={[
            {
              value: 70,
              label: "70%",
            },
            {
              value: 100,
              label: "100%",
            },
          ]}
        />
      </Box>
      <Typography>
        Estimated salary:{" "}
        <b>
          {estimatedSalary.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </b>
      </Typography>
    </Box>
  );
}
