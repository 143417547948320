import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import {
  useCreateShortUrl,
  useUpdateShortUrl,
  type ShortUrl,
} from "@trainwell/features";
import { useEffect, useState } from "react";

type Props = {
  open: boolean;
  onClose: (newShortUrl?: ShortUrl) => void;
  defaultShortUrl?: ShortUrl;
};

export function ShortUrlEditDialog({ open, onClose, defaultShortUrl }: Props) {
  const [originalUrl, setOriginalUrl] = useState<string | null>(
    defaultShortUrl?.original_url ?? null,
  );
  const [useCustomShortPath, setUseCustomShortPath] = useState(false);
  const [isCaseInsensitive, setIsCaseInsensitive] = useState(false);
  const [shortPath, setShortPath] = useState<string | null>(null);
  const updateShortUrlMutation = useUpdateShortUrl();
  const createShortUrlMutation = useCreateShortUrl();

  useEffect(() => {
    if (!open) {
      setOriginalUrl(defaultShortUrl?.original_url ?? null);
      setShortPath(null);
      setIsCaseInsensitive(defaultShortUrl?.is_case_insensitive ?? false);
    }
  }, [open, defaultShortUrl]);

  useEffect(() => {
    if (shortPath) {
      setShortPath(shortPath.toLocaleLowerCase());
    }
  }, [open, isCaseInsensitive]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{defaultShortUrl ? "Edit" : "Create"} short url</DialogTitle>
      <DialogContent>
        <Stack direction={"column"} spacing={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isCaseInsensitive}
                  onChange={(event) => {
                    setIsCaseInsensitive(event.target.checked);
                  }}
                />
              }
              label="Case insensitive"
            />
            {!defaultShortUrl && (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={useCustomShortPath}
                      onChange={(event) => {
                        setUseCustomShortPath(event.target.checked);
                      }}
                    />
                  }
                  label="Use custom short path"
                />
                {useCustomShortPath && (
                  <TextField
                    label="Short path"
                    placeholder="cool-short-path"
                    helperText="cool-short-path becomes https://go.trainwell.net/cool-short-path"
                    value={shortPath}
                    onChange={(event) => {
                      let urlSafeString = event.target.value.replace(
                        /[^0-9a-zA-Z-]/gi,
                        "",
                      );

                      if (isCaseInsensitive) {
                        urlSafeString = urlSafeString.toLocaleLowerCase();
                      }

                      setShortPath(urlSafeString);
                    }}
                    fullWidth
                  />
                )}
              </>
            )}
          </FormGroup>
          <TextField
            label="Redirect to"
            placeholder="https://exampleofalooooongurl.com"
            multiline
            value={originalUrl}
            onChange={(event) => {
              setOriginalUrl(event.target.value);
            }}
            fullWidth
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!originalUrl || (useCustomShortPath && !shortPath)}
          onClick={async () => {
            if (!originalUrl) {
              return;
            }

            let newShortUrl: undefined | ShortUrl = undefined;

            if (defaultShortUrl) {
              newShortUrl = await updateShortUrlMutation.mutateAsync({
                shortUrlId: defaultShortUrl.id,
                data: {
                  original_url: originalUrl,
                  is_case_insensitive: isCaseInsensitive,
                },
              });
            } else {
              newShortUrl = await createShortUrlMutation.mutateAsync({
                data: {
                  long_url: originalUrl,
                  short_path: useCustomShortPath ? shortPath! : undefined,
                  is_case_insensitive: isCaseInsensitive,
                  show_in_dash: true,
                },
              });
            }

            onClose(newShortUrl);
          }}
        >
          {defaultShortUrl ? "Edit" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
