import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { isBefore } from "date-fns";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";

export function CheckForTrainerSurvey(props: any) {
  const dispatch = useAppDispatch();
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.trainer_id,
  );
  const lastDateTookSurvey = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.last_survey_date,
  );
  const isEmployee = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.is_employee,
  );
  const lastDateRequiredSurvey = useAppSelector(
    (state) => state.company.company?.last_trainer_survey_date,
  );
  const [temporarilyHideSurvey, setTemporarilyHideSurvey] = useState(false);

  const showSurvey =
    isEmployee === true &&
    lastDateRequiredSurvey !== undefined &&
    lastDateRequiredSurvey !== null &&
    !temporarilyHideSurvey &&
    (!lastDateTookSurvey ||
      isBefore(new Date(lastDateTookSurvey), new Date(lastDateRequiredSurvey)));

  return (
    <>
      {props.children}
      <Dialog
        open={showSurvey}
        onClose={() => {
          setTemporarilyHideSurvey(true);
        }}
      >
        <DialogTitle>Take the trainer survey</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Feedback helps us build the best trainer platform possible. Please
            take a moment to fill out our survey.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setTemporarilyHideSurvey(true);
            }}
          >
            Not now
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              dispatch(
                updateTrainer({
                  trainer_id: trainerId!,
                  last_survey_date: new Date().toISOString(),
                }),
              );
            }}
            href="https://docs.google.com/forms/d/e/1FAIpQLSfwBw_JAekJxwImW8KtFFt-jNHDZmqivzx78WkGh58Lxkqf1w/viewform"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<OpenInNewRoundedIcon />}
          >
            Take survey
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
