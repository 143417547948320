import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { Box, IconButton, Typography } from "@mui/material";
import type { CoachMetricTemplateSubmodule } from "@trainwell/types";
import React from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import type Metric from "src/interfaces/Metric";
import { queryMetric } from "src/lib/metrics";
import { round } from "src/lib/misc";
import { api } from "src/lib/trainwellApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import Distribution from "./HoverTargets/Distribution";
import Info from "./HoverTargets/Info";
import List from "./HoverTargets/List";
import Ranking from "./HoverTargets/Ranking";
import Trend from "./HoverTargets/Trend";

const styles: Record<string, React.CSSProperties> = {
  title: {
    display: "flex",
    alignItems: "center",
    fontSize: "11px",
    marginBottom: "4px",
    height: "40px",
    lineHeight: 1.25,
  },
  metricContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "4px",
  },
  metric: {
    fontSize: "20px",
    fontWeight: 500,
  },
  hoverTargetContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  hoverTarget: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(44, 44, 49, 0.2)",
    flexGrow: 1,
    margin: "0 2px 0 2px",
    borderRadius: "4px",
  },
};

type Props = {
  metricSnapshot: any;
  coachMetrics: Metric[];
  submodule: CoachMetricTemplateSubmodule;
  companyMetrics: any;
  allCompanyMetrics: Metric[];
  window: number;
  sortedSubmoduleMetrics: any;
  trainerName: string;
  admin: boolean;
};

export default function MetricSubmodule({
  metricSnapshot,
  coachMetrics,
  submodule,
  companyMetrics,
  allCompanyMetrics,
  window,
  sortedSubmoduleMetrics,
  trainerName,
  admin,
}: Props) {
  const trainer = useAppSelector(selectPrimaryTrainer);

  const displayRanking = () => {
    if (sortedSubmoduleMetrics === undefined) {
      return;
    }
    return (
      <Ranking
        sortedSubmoduleMetrics={sortedSubmoduleMetrics}
        metrics={metricSnapshot}
        submodule={submodule}
      />
    );
  };

  const displayDistribution = () => {
    if (submodule.distribution_metric != null) {
      return (
        <Distribution
          metrics={metricSnapshot}
          companyMetrics={companyMetrics}
          submodule={submodule}
          window={window}
          trainerName={trainerName}
        />
      );
    }
    return;
  };

  const displayList = () => {
    if (submodule.list_metric != null) {
      return <List metrics={metricSnapshot} submodule={submodule} />;
    }
    return;
  };

  const displayTrend = () => {
    return (
      <Trend
        allMetrics={coachMetrics}
        allCompanyMetrics={allCompanyMetrics}
        submodule={submodule}
        window={window}
        trainerName={trainerName}
      />
    );
  };

  let metric = queryMetric(metricSnapshot, submodule.metric);
  let companyMetric = queryMetric(companyMetrics, submodule.metric);

  if (submodule.per_day) {
    metric /= window;
    companyMetric /= window;
  }

  metric = round(metric, submodule.precision);
  companyMetric = round(companyMetric, submodule.precision);

  if (submodule.is_percent) {
    metric += "%";
    companyMetric += "%";
  }

  return (
    <>
      <Box>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: 12,
            height: "45px",
            mb: 1,
          }}
        >
          {submodule.metric_title}
          <Info template={submodule} />
          {admin && (
            <IconButton
              onClick={() => {
                api.coachMetrics.sendMetricSummary(
                  trainer!.trainer_id,
                  submodule.metric,
                );
              }}
            >
              <SendRoundedIcon sx={{ fontSize: 18 }} />
            </IconButton>
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          mb: 0.5,
        }}
      >
        <Typography
          variant="caption"
          textAlign={"center"}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {trainerName}
        </Typography>
        <Typography
          variant="caption"
          textAlign={"center"}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          tw
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          mb: 0.5,
        }}
      >
        <div style={{ flexGrow: 1, flexBasis: 0, textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold" }}>{metric}</Typography>
        </div>
        <Box
          sx={{
            height: "24px",
            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            mx: 1,
          }}
        />
        <div style={{ flexGrow: 1, flexBasis: 0, textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold" }}>{companyMetric}</Typography>
        </div>
      </Box>
      <Box style={styles.hoverTargetContainer}>
        {displayRanking()}
        {displayDistribution()}
        {displayTrend()}
        {displayList()}
      </Box>
    </>
  );
}
