import { updateApiOptions } from "@trainwell/features";
import {
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  signOut,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TRAINWELL_API_DOMAIN, TRAINWELL_API_KEY } from "src/config/config";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import {
  fetchAuditeeTrainer,
  fetchTrainer,
  selectPrimaryTrainer,
  setAuthenticated,
} from "src/slices/trainerSlice";

export default function AuthHelper() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const navigate = useNavigate();
  const [isSetup, setIsSetup] = useState(false);
  const trainerStatus = useAppSelector((state) => state.trainer.status);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribeIdTokenChanged = onIdTokenChanged(auth, async (user) => {
      if (!user) {
        return;
      }

      const token = await user.getIdToken();

      updateApiOptions({
        prefixUrl: TRAINWELL_API_DOMAIN,
        headers: {
          Authorization: `Bearer ${token}`,
          "api-key": TRAINWELL_API_KEY,
        },
      });
    });

    const unsubscribeAuthChange = onAuthStateChanged(auth, (user) => {
      if (!user) {
        console.log("Auth: Sign out");

        navigate("/login", { replace: true });

        return;
      }

      console.log(`Auth: Sign in with uid: '${user.uid}'`);

      user
        .getIdTokenResult()
        .then((idTokenResult) => {
          dispatch(setAuthenticated(true));

          const trainerId = idTokenResult.claims.trainerId as
            | string
            | undefined;

          if (trainerId) {
            console.log(`Auth: Token's trainer_id: '${trainerId}'`);

            loginHandler(trainerId);
            if (location.pathname.includes("login")) {
              console.log("Auth: Redirecting to home page");

              navigate("/");
            }
          } else {
            console.log("Auth: Token does not have a trainer_id");

            if (user.email) {
              console.log(
                `Auth: Telling server to fix things for email: '${user.email}'`,
              );

              api.trainers
                .attemptFirebaseSetup(user.email)
                .then((trainerId) => {
                  loginHandler(trainerId);
                  navigate("/");
                })
                .catch(() => {
                  signOut(auth);
                });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });

    return () => {
      unsubscribeIdTokenChanged();
      unsubscribeAuthChange();
    };
  }, []);

  useEffect(() => {
    const auditTrainerId = searchParams.get("trainer_id");

    if (
      trainer?.trainer_id &&
      auditTrainerId &&
      auditTrainerId !== trainer?.trainer_id
    ) {
      dispatch(fetchAuditeeTrainer(auditTrainerId));
    }
  }, [searchParams, trainer?.trainer_id]);

  useEffect(() => {
    if (
      trainer &&
      !trainer?.user_id &&
      !location.pathname.includes("setup-account")
    ) {
      navigate("/setup-account");
    }
  }, [trainer]);

  useEffect(() => {
    console.log("UseEffect: trainer_id");

    if (trainer?.trainer_id && isSetup === false) {
      setIsSetup(true);
    }
  }, [trainer]);

  function loginHandler(trainer_id: string) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const auditTrainerID = urlParams.get("trainer_id");

    if (trainerStatus === "idle" && trainer_id) {
      dispatch(
        fetchTrainer({
          trainerId: trainer_id,
          auditeeTrainerId: auditTrainerID,
        }),
      )
        .unwrap()
        .catch((err) => {
          console.log(err);
          if (err.name === "UnauthorizedError") {
            navigate("/access-denied");
          }
        });
    }
  }

  return null;
}
