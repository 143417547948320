import type { DraggableAttributes } from "@dnd-kit/core";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import {
  SortableContext,
  defaultAnimateLayoutChanges,
  useSortable,
  verticalListSortingStrategy,
  type AnimateLayoutChanges,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {
  Box,
  Card,
  IconButton,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { memo, useMemo } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import type { PhaseDayDraggable } from "src/slices/phasesSlice";
import PhaseWorkoutCell from "./PhaseWorkoutCell";

type Props = {
  dayIndex: number;
  day: PhaseDayDraggable;
  newDay?: boolean;
  onDeleteWorkout: (workoutId: string) => void;
  onCopyWorkout: (workoutId: string, type: "linked" | "not_linked") => void;
  onDelete: () => void;
};

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

export default function PhaseDayDraggable({
  dayIndex,
  day,
  newDay,
  onDeleteWorkout,
  onCopyWorkout,
  onDelete,
}: Props) {
  const {
    attributes,
    isDragging,
    listeners,
    isOver,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id: day.draggable_id,
    data: {
      type: "phase_day",
    },
    animateLayoutChanges,
  });

  return (
    <div
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
    >
      <PhaseDay
        droppableRef={setNodeRef}
        dayIndex={dayIndex}
        day={day}
        newDay={newDay}
        onDelete={onDelete}
        onDeleteWorkout={onDeleteWorkout}
        onCopyWorkout={onCopyWorkout}
        isOver={isOver}
        attributes={attributes}
        listeners={listeners}
      />
    </div>
  );
}

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const PhaseDay = memo(function PhaseDay({
  dayIndex,
  day,
  newDay,
  onDeleteWorkout,
  onCopyWorkout,
  onDelete,
  isOver,
  attributes,
  listeners,
  droppableRef,
}: Props & {
  isOver: boolean;
  listeners: SyntheticListenerMap | undefined;
  attributes: DraggableAttributes;
  droppableRef: (node: HTMLElement | null) => void;
}) {
  const itemIds = useMemo(
    () => day.workouts.map((workout) => workout.draggable_id),
    [day.workouts],
  );
  const selectedWorkoutScheduleDayIndexes = useAppSelector((state) =>
    (
      (state.client.client!.workout_times ?? [])
        .map((day, index) => (day.has_time ? index : null))
        .filter((day) => day !== null) as number[]
    ).sort(),
  );

  const possibleDays = selectedWorkoutScheduleDayIndexes.map(
    (dayIndex) => days[dayIndex],
  );

  let dayLabel = possibleDays[dayIndex % possibleDays.length];

  if (possibleDays.length === 0) {
    dayLabel = "No workout schedule";
  } else if (dayIndex >= possibleDays.length) {
    dayLabel += ` (${Math.floor(dayIndex / possibleDays.length) + 1})`;
  }

  return (
    <>
      {newDay ? (
        <Card
          ref={droppableRef}
          variant="outlined"
          sx={{
            flex: 1,
            borderStyle: "dashed",
            borderWidth: "2px",
            borderColor: (theme) =>
              isOver ? theme.palette.primary.main : undefined,
            backgroundColor: (theme) =>
              isOver
                ? alpha(theme.palette.primary.main, 0.2)
                : theme.palette.background.paper,
            transition: "background-color 0.2s ease, border-color 0.2s ease",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              p: 1,
            }}
          >
            <AddRoundedIcon
              sx={{
                color: (theme) =>
                  isOver
                    ? theme.palette.primary.main
                    : theme.palette.text.secondary,
                transition: "color 0.2s ease",
              }}
            />
            <Typography variant="overline">Drop to add new day</Typography>
          </Box>
        </Card>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              mb: 0.5,
            }}
          >
            <Typography variant="body2">{dayLabel}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip
                title={day.workouts.length > 0 ? "Day must be empty" : ""}
              >
                <span>
                  <IconButton
                    size="small"
                    onClick={() => {
                      onDelete();
                    }}
                    color="error"
                    disabled={day.workouts.length > 0}
                  >
                    <RemoveCircleRoundedIcon fontSize="inherit" />
                  </IconButton>
                </span>
              </Tooltip>
              <IconButton size="small" {...attributes} {...listeners}>
                <DragIndicatorRoundedIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Card
            variant="outlined"
            ref={droppableRef}
            sx={{
              flex: 1,
              borderStyle: day.workouts.length > 0 ? "solid" : "dashed",
              borderWidth: day.workouts.length > 0 ? "1px" : "2px",
              // borderRadius: 1,
              borderColor: (theme) =>
                isOver ? theme.palette.primary.main : undefined,
              backgroundColor: (theme) =>
                isOver
                  ? alpha(theme.palette.primary.main, 0.2)
                  : theme.palette.background.paper,
              transition: "background-color 0.2s ease, border-color 0.2s ease",
              width: "100%",
            }}
          >
            <SortableContext
              items={itemIds}
              strategy={verticalListSortingStrategy}
            >
              {day.workouts.map((workout, workoutTemplateIndex) => (
                <PhaseWorkoutCell
                  key={workout.draggable_id}
                  workoutId={workout.workout_id}
                  draggableId={workout.draggable_id}
                  dayIndex={dayIndex}
                  isLast={workoutTemplateIndex === day.workouts.length - 1}
                  onDelete={() => {
                    onDeleteWorkout(workout.workout_id);
                  }}
                  onCopy={(type) => {
                    onCopyWorkout(workout.workout_id, type);
                  }}
                />
              ))}
              {day.workouts.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    p: 1,
                  }}
                >
                  <AddRoundedIcon
                    sx={{
                      color: (theme) =>
                        isOver
                          ? theme.palette.primary.main
                          : theme.palette.text.secondary,
                      transition: "color 0.2s ease",
                    }}
                  />
                  <Typography variant="overline">Drag workouts here</Typography>
                </Box>
              )}
            </SortableContext>
          </Card>
        </>
      )}
    </>
  );
});
