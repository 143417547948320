import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, Button, Divider, Typography } from "@mui/material";
import type { Client } from "@trainwell/types";
import { useAppSelector } from "src/hooks/stateHooks";
import { getClientSummary } from "src/lib/client";
import { getTrainerName } from "src/lib/coachUtility";

type Props = {
  client: Pick<
    Client,
    | "trainer_id"
    | "full_name"
    | "email"
    | "phone_number"
    | "account"
    | "user_id"
  >;
};

export default function RandomClientCell({ client }: Props) {
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);

  return (
    <>
      <Box
        sx={{
          py: 1,
          px: 2,
        }}
      >
        <Box sx={{ display: "flex", mb: 1, justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {client.full_name}
          </Typography>
          <Button
            variant="text"
            target="_blank"
            href={`https://trainer.trainwell.net/clients/${client.user_id}`}
            LinkComponent={"a"}
            endIcon={<OpenInNewRoundedIcon />}
            size="small"
          >
            trainwell dash
          </Button>
        </Box>
        <Typography>
          Account status: {getClientSummary(client.account).text}
        </Typography>
        <Typography>
          Trainer: {getTrainerName(client.trainer_id, trainerNames)}
        </Typography>
        {client.email && <Typography>Email: {client.email}</Typography>}
        {client.phone_number && (
          <Typography>Phone number: {client.phone_number}</Typography>
        )}
      </Box>
      <Divider />
    </>
  );
}
