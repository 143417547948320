import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import type { Ticket } from "@trainwell/types";
import { format } from "date-fns";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import { getTypeIcon, getTypeName } from "src/lib/tickets";
import { openTicketChat } from "src/slices/chatSlice";
import {
  selectTicketById,
  selectTicketCategories,
  updateTicket,
} from "src/slices/ticketsSlice";
import TicketQuickDetails from "./TicketQuickDetails";

type Props = {
  ticketId: string;
  ticket?: Ticket;
};

export default function TicketCell({ ticketId, ticket: forceTicket }: Props) {
  const dispatch = useAppDispatch();
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const ticketCategories = useAppSelector(selectTicketCategories);
  const open = Boolean(anchorEl);
  const ticket =
    useAppSelector((state) => selectTicketById(state, ticketId)) ?? forceTicket;
  const [dashAnchorEl, setDashAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const dashMenuOpen = Boolean(dashAnchorEl);

  if (!ticket || !ticketCategories) {
    return <Typography>Loading</Typography>;
  }

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs="auto">
          <IconButton
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            {getTypeIcon(ticket.type)}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={open}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            {["tech_issue", "customer_support", "request"].map((type) => (
              <MenuItem
                key={type}
                selected={ticket.type === type}
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(updateTicket({ id: ticketId, type: type as any }));
                }}
              >
                <ListItemIcon>
                  {getTypeIcon(type as Ticket["type"])}
                </ListItemIcon>
                <ListItemText primary={getTypeName(type as Ticket["type"])} />
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid item xs={2}>
          <Typography>
            <b>#{ticket.number}</b> by{" "}
            {getTrainerName(ticket.trainer_id, trainerNames)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Stack direction={"row"} spacing={1} alignItems="center">
            <IconButton
              onClick={() => {
                dispatch(openTicketChat(ticket.id));
              }}
            >
              <CommentRoundedIcon />
            </IconButton>
            {ticket.user_id && (
              <>
                <Button
                  variant="text"
                  size="small"
                  onClick={(event) => {
                    setDashAnchorEl(event.currentTarget);
                  }}
                >
                  Client{ticket.client_name ? ` - ${ticket.client_name}` : ""}
                </Button>
                <Menu
                  anchorEl={dashAnchorEl}
                  open={dashMenuOpen}
                  onClose={() => {
                    setDashAnchorEl(null);
                  }}
                >
                  <Link
                    href={`/clients/${ticket.user_id}`}
                    sx={{
                      textDecoration: "none",
                      color: (theme) => theme.palette.text.primary,
                    }}
                  >
                    <MenuItem>Trainer dash</MenuItem>
                  </Link>
                  <Link
                    href={`/admin/company/clients/${ticket.user_id}`}
                    sx={{
                      textDecoration: "none",
                      color: (theme) => theme.palette.text.primary,
                    }}
                  >
                    <MenuItem>Admin dash</MenuItem>
                  </Link>
                </Menu>
              </>
            )}
            {ticket.log_id && (
              <Button
                variant="text"
                size="small"
                href={`/clients/${ticket.user_id}/logs/${ticket.log_id}`}
                startIcon={<FitnessCenterRoundedIcon />}
              >
                Log
              </Button>
            )}
          </Stack>
        </Grid>
        <Grid item xs="auto" display="flex" justifyContent="flex-end">
          <TicketQuickDetails ticketId={ticketId} />
        </Grid>
        <Grid item xs={1.5}>
          <Typography textAlign={"right"}>
            {format(new Date(ticket.date_created), "MMM d, yyyy")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {ticket.message_client && (
            <Typography>
              <b>Client message:</b> {ticket.message_client}
            </Typography>
          )}
          {ticket.message_coach && (
            <Typography>
              <b>Trainer message:</b> {ticket.message_coach}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
