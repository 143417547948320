import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import AuditCell from "src/components/ClientOverview/AuditCell";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { fetchAudits } from "src/slices/clientEditSlice";

type Props = {
  userId: string;
};

export default function TabAudits({ userId }: Props) {
  const dispatch = useAppDispatch();
  const auditsStatus = useAppSelector((state) => state.clientEdit.auditsStatus);
  const audits = useAppSelector((state) => state.clientEdit.audits);

  useEffect(() => {
    if (auditsStatus === "idle") {
      dispatch(fetchAudits(userId));
    }
  }, [auditsStatus, userId, dispatch]);

  if (audits.length === 0) {
    return <Typography sx={{ textAlign: "center" }}>No audits</Typography>;
  }

  return (
    <Stack spacing={2} direction="column">
      {audits.map((audit) => {
        return <AuditCell audit={audit} key={audit.id} />;
      })}
    </Stack>
  );
}
