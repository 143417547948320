import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Box, Card, IconButton, Typography } from "@mui/material";
import type { Audit } from "@trainwell/types";
import { format } from "date-fns";
import { useState } from "react";
import AutosaveTextField from "src/components/misc/AutosaveTextField";
import ConfirmationDialog from "src/components/misc/ConfirmationDialog";
import CustomLink from "src/components/misc/CustomLink";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import { deleteAudit, updateAudit } from "src/slices/clientSlice";
import { toggleAuditTag } from "src/slices/trainerSlice";
import { TagList } from "../misc/TagList";

type Props = {
  audit: Audit & { client_full_name?: string };
  showClientName?: boolean;
};

export default function AuditCell({ audit, showClientName }: Props) {
  const dispatch = useAppDispatch();
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);
  const auditTags = useAppSelector((state) => state.trainer.auditTags);
  const [editing, setEditing] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  return (
    <Card variant="outlined" sx={{ p: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>
            {!showClientName
              ? "Audit"
              : audit.subject_trainer_id
                ? "Trainer audit"
                : "Client audit"}{" "}
            by {getTrainerName(audit.auditor_trainer_id, trainerNames)}
          </Typography>
          {showClientName && audit.client_full_name && (
            <Typography>
              For{" "}
              <CustomLink
                href={`/clients/${audit.subject_user_id}`}
                text={audit.client_full_name}
              />
            </Typography>
          )}
          <Typography variant="overline">
            Created {format(new Date(audit.date_created), "MMMM do, yyyy")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => {
              setEditing(!editing);
            }}
            size="small"
          >
            {editing ? (
              <DoneRoundedIcon fontSize="small" />
            ) : (
              <EditRoundedIcon fontSize="small" />
            )}
          </IconButton>
          <IconButton
            onClick={() => {
              setConfirmDeleteOpen(true);
            }}
            size="small"
            color="error"
          >
            <DeleteRoundedIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <TagList
        value={audit.tags.map((t) => ({ id: t, label: t }))}
        options={auditTags.map((t) => ({ id: t, label: t }))}
        onAdd={(tag) => {
          const newTags = [...audit.tags, tag];

          dispatch(
            updateAudit({
              id: audit.id,
              tags: newTags,
            }),
          );
          dispatch(toggleAuditTag(tag));
        }}
        onRemove={(tag) => {
          const newTags = [...audit.tags].filter((t) => t !== tag);

          dispatch(
            updateAudit({
              id: audit.id,
              tags: newTags,
            }),
          );
          dispatch(toggleAuditTag(tag));
        }}
        sx={{ mb: 1 }}
      />
      {editing ? (
        <AutosaveTextField
          label="Audit"
          defaultValue={audit.text}
          name="audit.text"
          disableGutter
          onSave={(text, blured) => {
            const data: Partial<Audit> & Pick<Audit, "id"> = {
              id: audit.id,
              text: text,
            };

            if (blured) {
              data.notified_slack = true;
            }

            dispatch(updateAudit(data));
          }}
        />
      ) : (
        <Typography
          sx={{
            whiteSpace: "pre-line",
            wordWrap: "break-word",
          }}
        >
          {audit.text}
        </Typography>
      )}
      <ConfirmationDialog
        open={confirmDeleteOpen}
        title="Delete audit"
        message="Delete this audit?"
        confirmButtonText="Delete audit"
        onClose={(confirmed) => {
          setConfirmDeleteOpen(false);

          if (confirmed) {
            dispatch(deleteAudit(audit.id));
          }
        }}
      />
    </Card>
  );
}
