import { Box, Card, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import {
  type TestAnalyticsInfoItem,
  type TestVariantAnalyticsItem,
} from "@trainwell/types";
import { format } from "date-fns";

type Props = {
  metrics: { date: string; data: TestVariantAnalyticsItem }[];
  details: TestAnalyticsInfoItem;
  isControl: boolean;
};

export function SnapshotMetricDetails({ metrics, details, isControl }: Props) {
  const theme = useTheme();
  const mostRecentMetric = metrics.at(-1);

  if (!mostRecentMetric?.data) {
    return null;
  }

  const value = mostRecentMetric.data.value;
  const effectPercent = mostRecentMetric.data.effect_size?.percent;
  const effectAbsolute = mostRecentMetric.data.effect_size?.absolute;
  const pValue = mostRecentMetric.data.p_value;

  let status: "good" | "bad" | "neutral" = "neutral";

  if (mostRecentMetric.data.is_significant) {
    if (details.better_direction === "increase") {
      if ((mostRecentMetric.data.effect_size?.absolute ?? 0) > 0) {
        status = "good";
      } else if ((mostRecentMetric.data.effect_size?.absolute ?? 0) < 0) {
        status = "bad";
      }
    } else if (details.better_direction === "decrease") {
      if ((mostRecentMetric.data.effect_size?.absolute ?? 0) < 0) {
        status = "good";
      } else if ((mostRecentMetric.data.effect_size?.absolute ?? 0) > 0) {
        status = "bad";
      }
    }
  }

  const chartData = metrics.map((metric) => ({
    x: new Date(metric.date).getTime(),
    y: Math.round((metric.data.effect_size?.percent as number) * 10000) / 100,
  }));

  return (
    <Grid container columns={3} sx={{ height: "100%", alignItems: "stretch" }}>
      <Grid
        item
        xs={1}
        sx={{
          p: 1,
          borderLeft: 1,
          borderColor: "divider",
          height: "100%",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: (theme) =>
              status === "good"
                ? theme.palette.success.main
                : status === "bad"
                  ? theme.palette.error.main
                  : undefined,
          }}
        >
          {details.type === "percent"
            ? `${Math.round(value * 10000) / 100}%`
            : Math.round(value * 100) / 100}
        </Typography>
        {mostRecentMetric.data.sample_n && (
          <Tooltip title={"Sample size"} disableInteractive>
            <Typography variant="overline">
              n=
              {mostRecentMetric.data.sample_n.toLocaleString()}
            </Typography>
          </Tooltip>
        )}
      </Grid>
      {!isControl && (
        <Grid item xs={1} sx={{ p: 1, borderLeft: 1, borderColor: "divider" }}>
          <Tooltip title="Effect percent" disableInteractive placement="top">
            <Typography>
              {!effectPercent
                ? "--"
                : `${effectPercent > 0 ? "+" : ""}${(Math.round(effectPercent * 10000) / 100).toFixed(2)}`}
              %
            </Typography>
          </Tooltip>
          <Tooltip title="Effect absolute" disableInteractive placement="top">
            <Typography variant="overline">
              {!effectAbsolute
                ? "--"
                : details.type === "percent"
                  ? `${effectAbsolute > 0 ? "+" : ""}${(Math.round(effectAbsolute * 10000) / 100).toFixed(2)}%`
                  : `${effectAbsolute > 0 ? "+" : ""}${(Math.round(effectAbsolute * 100) / 100).toFixed(2)}`}
            </Typography>
          </Tooltip>
          <Typography variant="overline">
            p-value:{" "}
            {!pValue ? "--" : (Math.round(pValue * 100) / 100).toFixed(2)}
          </Typography>
        </Grid>
      )}
      {!isControl && (
        <Grid item xs={1} sx={{ p: 1, borderLeft: 1, borderColor: "divider" }}>
          <LineChart
            dataset={chartData}
            xAxis={[
              {
                dataKey: "x",
                valueFormatter: (value) =>
                  format(new Date(value as number), "MMM d, yyyy"),
              },
            ]}
            yAxis={[
              {
                min: Math.min(...chartData.map((d) => d.y)),
                max: Math.max(...chartData.map((d) => d.y)),
              },
            ]}
            series={[
              {
                dataKey: "y",
                showMark: false,
                color:
                  status === "good"
                    ? theme.palette.success.main
                    : status === "bad"
                      ? theme.palette.error.main
                      : theme.palette.text.primary,
                label: details.name,
              },
            ]}
            leftAxis={null}
            bottomAxis={null}
            height={35}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
            margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
            skipAnimation
            slots={{
              axisContent: ({ dataIndex }) => {
                const point = chartData[dataIndex as number];

                return (
                  <Card variant="outlined" sx={{ py: 1, ml: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center", px: 2 }}>
                      <Box
                        sx={{
                          width: theme.spacing(1),
                          height: theme.spacing(1),
                          borderRadius: "50%",
                          backgroundColor: theme.palette.primary.main,
                          borderColor: theme.palette.background.paper,
                          border: `solid ${theme.palette.background.paper} ${theme.spacing(0.25)}`,
                          boxSizing: "content-box",
                        }}
                      />
                      <Box sx={{ ml: 1 }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Effect size: {point.y.toFixed(2)}%
                        </Typography>
                        <Typography variant="overline">
                          {format(point.x as number, "MMM d, yyy")}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                );
              },
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}
