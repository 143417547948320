import { Card, Stack, Typography } from "@mui/material";
import type { Ticket } from "@trainwell/types";
import { useEffect, useState } from "react";
import TicketCell from "src/components/AdminPage/tickets/TicketCell";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { fetchTicketCategories } from "src/slices/ticketsSlice";

type Props = {
  userId: string;
};

export default function TabTickets({ userId }: Props) {
  const dispatch = useAppDispatch();
  const [tickets, setTickets] = useState<Ticket[]>();
  const ticketCategoriesStatus = useAppSelector(
    (state) => state.tickets.categoriesStatus,
  );

  useEffect(() => {
    if (ticketCategoriesStatus === "idle") {
      dispatch(fetchTicketCategories());
    }
  }, [ticketCategoriesStatus, dispatch]);

  useEffect(() => {
    setTickets(undefined);

    api.tickets.getMany({ userId }).then((fetchedTickets) => {
      fetchedTickets.sort((a, b) => (a.date_created > b.date_created ? 1 : -1));

      setTickets(fetchedTickets);
    });
  }, [userId]);

  if (!tickets) {
    return <LoadingPage message="Loading tickets" />;
  }

  if (tickets.length === 0) {
    return <Typography sx={{ textAlign: "center" }}>No tickets</Typography>;
  }

  return (
    <Card variant="outlined">
      <Stack direction="column" spacing={2}>
        {tickets.map((ticket) => (
          <TicketCell ticketId={ticket.id} ticket={ticket} key={ticket.id} />
        ))}
      </Stack>
    </Card>
  );
}
