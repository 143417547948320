import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Box, Stack, Typography, alpha, useTheme } from "@mui/material";
import { useMemo } from "react";
import { useAppDispatch } from "src/hooks/stateHooks";
import {
  duplicateWorkout,
  updatePhaseEditing,
  type PhaseDayDraggable,
} from "src/slices/phasesSlice";
import PhaseDay from "./PhaseDay";

type Props = {
  days: PhaseDayDraggable[];
};

export default function PhaseDaysCard({ days }: Props) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const items = useMemo(() => {
    if (days.length <= 14) {
      return [...days.map((day) => day.draggable_id), "new_day"];
    }

    return days.map((day) => day.draggable_id);
  }, [days]);

  return (
    <Box sx={{ pb: 4 }}>
      <Typography variant="h2" sx={{ mb: 1 }}>
        Workouts
      </Typography>
      <Box
        sx={{
          flex: 1,
          p: 1,
          borderRadius: 1,
          border: 1,
          borderColor: (theme) => theme.palette.divider,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        <Stack direction="column" spacing={1}>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {days.map((day, dayIndex) => {
              return (
                <PhaseDay
                  key={day.draggable_id}
                  day={day}
                  dayIndex={dayIndex}
                  onDeleteWorkout={(workoutId) => {
                    const newDays = [...days];
                    const day = newDays[dayIndex];
                    const newWorkouts = day.workouts.filter(
                      (workout) => workout.workout_id !== workoutId,
                    );
                    newDays[dayIndex] = {
                      ...day,
                      workouts: newWorkouts,
                    };

                    dispatch(
                      updatePhaseEditing({
                        days_draggable: newDays,
                      }),
                    );
                  }}
                  onCopyWorkout={async (workoutId, type) => {
                    const draggableId = crypto.randomUUID();

                    if (type === "not_linked") {
                      const { workout } = await dispatch(
                        duplicateWorkout(workoutId),
                      ).unwrap();

                      const newDays = JSON.parse(
                        JSON.stringify(days),
                      ) as PhaseDayDraggable[];
                      const day = newDays[dayIndex];

                      const originalWorkoutIndex = day.workouts.findIndex(
                        (w) => w.workout_id === workoutId,
                      );

                      if (originalWorkoutIndex === -1) {
                        return;
                      }

                      const newWorkouts = [...day.workouts];

                      newWorkouts.splice(originalWorkoutIndex + 1, 0, {
                        draggable_id: draggableId,
                        workout_id: workout.workout_id,
                      });

                      newDays[dayIndex] = {
                        ...day,
                        workouts: newWorkouts,
                      };

                      dispatch(
                        updatePhaseEditing({
                          days_draggable: newDays,
                        }),
                      );
                    } else {
                      const newDays = JSON.parse(
                        JSON.stringify(days),
                      ) as PhaseDayDraggable[];

                      let placed = false;

                      for (let i = dayIndex; i < newDays.length; i++) {
                        // Try to add the linked workout to the next available day
                        const day = newDays[i];

                        const workoutExists =
                          day.workouts.findIndex(
                            (w) => w.workout_id === workoutId,
                          ) !== -1;

                        if (workoutExists) {
                          continue;
                        }

                        placed = true;

                        const newWorkouts = [...day.workouts];

                        newWorkouts.splice(0, 0, {
                          draggable_id: draggableId,
                          workout_id: workoutId,
                        });

                        newDays[i] = {
                          ...day,
                          workouts: newWorkouts,
                        };

                        dispatch(
                          updatePhaseEditing({
                            days_draggable: newDays,
                          }),
                        );
                      }

                      if (!placed) {
                        // Add the linked workout to a new day
                        newDays.push({
                          draggable_id: crypto.randomUUID(),
                          workouts: [
                            {
                              draggable_id: draggableId,
                              workout_id: workoutId,
                            },
                          ],
                        });

                        dispatch(
                          updatePhaseEditing({
                            days_draggable: newDays,
                          }),
                        );
                      }
                    }

                    // Flash day
                    setTimeout(() => {
                      const element = document.getElementById(draggableId);

                      if (element) {
                        element.style.backgroundColor = alpha(
                          theme.palette.primary.main,
                          0.2,
                        );
                        // change the background color back
                        setTimeout(() => {
                          element.style.backgroundColor =
                            theme.palette.background.paper;
                        }, 800);
                      }
                    }, 100);
                  }}
                  onDelete={() => {
                    const newDays = [...days];
                    newDays.splice(dayIndex, 1);
                    dispatch(
                      updatePhaseEditing({
                        days_draggable: newDays,
                      }),
                    );
                  }}
                />
              );
            })}
            {days.length <= 14 && (
              <PhaseDay
                key={"new_day"}
                newDay
                day={{
                  draggable_id: "new_day",
                  workouts: [],
                }}
                dayIndex={-1}
                onDeleteWorkout={() => {}}
                onDelete={() => {}}
                onCopyWorkout={() => {}}
              />
            )}
          </SortableContext>
        </Stack>
      </Box>
    </Box>
  );
}
