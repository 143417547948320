import * as Sentry from "@sentry/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AdminLayout from "src/components/AdminPage/AdminLayout";
import AdminPage from "src/components/AdminPage/AdminPage";
import DocsPage from "src/components/AdminPage/DocsPage";
import DocsPythonPage from "src/components/AdminPage/DocsPythonPage";
import RandomClientsPage from "src/components/AdminPage/RandomClientsPage";
import { TrainerAvailabilityPage } from "src/components/AdminPage/TrainerAvailabilityPage";
import FunnelClientPage from "src/components/AdminPage/analytics/FunnelClientPage";
import FunnelLeadPage from "src/components/AdminPage/analytics/FunnelLeadPage";
import { CampaignLayout } from "src/components/AdminPage/campaigns/CampaignLayout";
import { CampaignPage } from "src/components/AdminPage/campaigns/CampaignPage";
import AllVacationsPage from "src/components/AdminPage/company/AllVacationsPage";
import CompanySettingsPage from "src/components/AdminPage/company/CompanySettingsPage";
import { ClientLayoutAdmin } from "src/components/AdminPage/company/clients/ClientLayoutAdmin";
import { ClientPageAdmin } from "src/components/AdminPage/company/clients/ClientPageAdmin";
import { CoachLayout } from "src/components/AdminPage/company/team/CoachLayout";
import { CoachPage } from "src/components/AdminPage/company/team/CoachPage";
import { DataCleaningPage } from "src/components/AdminPage/data-cleaner/DataCleaningPage";
import { InfluencerLayout } from "src/components/AdminPage/influencers/InfluencerLayout";
import { InfluencerPage } from "src/components/AdminPage/influencers/InfluencerPage";
import { ProductMetricsLayout } from "src/components/AdminPage/product-metrics/ProductMetricsLayout";
import { ProductMetricsPage } from "src/components/AdminPage/product-metrics/ProductMetricsPage";
import ShortUrlPage from "src/components/AdminPage/short-urls/ShortUrlPage";
import { TestLayout } from "src/components/AdminPage/tests/TestLayout";
import { TestPage } from "src/components/AdminPage/tests/TestPage";
import TicketPage from "src/components/AdminPage/tickets/TicketPage";
import TicketsPage from "src/components/AdminPage/tickets/TicketsPage";
import MetricsPage from "src/components/AdminPage/trainer-metrics/MetricsPage";
import SelfAuditsPage from "src/components/AuditsPage/SelfAuditsPage";
import ClientLayout from "src/components/ClientPage/ClientLayout";
import ClientPlanPage from "src/components/ClientPage/ClientPlanPage";
import HomePage from "src/components/HomePage/HomePage";
import CalendarPage from "src/components/ProfilePage/CalendarPage";
import ClientLoadPage from "src/components/ProfilePage/ClientLoadPage";
import CoachMetricsPage from "src/components/ProfilePage/CoachMetricsPage";
import CoachVacationsPage from "src/components/ProfilePage/CoachVacationsPage";
import CompensationPage from "src/components/ProfilePage/CompensationPage";
import MessageTemplatesPage from "src/components/ProfilePage/MessageTemplatesPage";
import NoteTemplatesPage from "src/components/ProfilePage/NoteTemplatesPage";
import ProfileLayout from "src/components/ProfilePage/ProfileLayout";
import ProfilePage from "src/components/ProfilePage/ProfilePage";
import SettingsPage from "src/components/ProfilePage/SettingsPage";
import FolderPage from "src/components/TemplateLibrary/FolderPage";
import TagPage from "src/components/TemplateLibrary/TagPage";
import TemplateLibraryHomePage from "src/components/TemplateLibrary/TemplateLibraryHomePage";
import TemplateLibraryLayout from "src/components/TemplateLibrary/TemplateLibraryLayout";
import WorkoutBuilderPage from "src/components/WorkoutBuilderPage/WorkoutBuilderPage";
import ChatBar from "src/components/chat/ChatBar";
import RestrictAccess from "src/components/misc/RestrictAccess";
import AccessDeniedPage from "src/components/miscPages/AccessDeniedPage";
import LoginPage from "src/components/miscPages/LoginPage";
import LogoutPage from "src/components/miscPages/LogoutPage";
import SetupAccountPage from "src/components/miscPages/SetupAccountPage";
import { AppRoot } from "./AppRoot";
import { AuthRequired } from "./AuthRequired";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <AppRoot />,
    children: [
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "logout",
        element: <LogoutPage />,
      },
      {
        path: "access-denied",
        element: <AccessDeniedPage />,
      },
      {
        element: <AuthRequired />,
        children: [
          {
            index: true,
            element: <HomePage />,
          },
          {
            path: "chat",
            element: <ChatBar />,
          },
          {
            path: "setup-account",
            element: <SetupAccountPage />,
          },
          {
            path: "audits",
            element: <SelfAuditsPage />,
          },
          {
            path: "audits/:auditId",
            element: <SelfAuditsPage />,
          },
          {
            path: "clients/:userId",
            element: <ClientLayout />,
            children: [
              {
                index: true,
                element: <ClientPlanPage />,
              },
              {
                path: "workouts/:workoutId",
                element: <WorkoutBuilderPage />,
              },
              {
                path: "logs/:logId",
                element: <WorkoutBuilderPage />,
              },
            ],
          },
          {
            path: "template-library",
            element: <TemplateLibraryLayout />,
            children: [
              {
                index: true,
                element: <TemplateLibraryHomePage />,
              },
              {
                path: "folders/:phaseTemplateFolderId",
                element: <FolderPage />,
              },
              {
                path: "tags/:tagId",
                element: <TagPage />,
              },
            ],
          },
          {
            path: "profile",
            element: <ProfileLayout />,
            children: [
              {
                index: true,
                element: <ProfilePage />,
              },
              {
                path: "compensation",
                element: <CompensationPage />,
              },
              {
                path: "note-templates",
                element: <NoteTemplatesPage />,
              },
              {
                path: "client-load",
                element: <ClientLoadPage />,
              },
              {
                path: "vacations",
                element: <CoachVacationsPage />,
              },
              {
                path: "calendar",
                element: <CalendarPage />,
              },
              {
                path: "metrics",
                element: <CoachMetricsPage />,
              },
              {
                path: "message-templates",
                element: <MessageTemplatesPage />,
              },
              {
                path: "settings",
                element: <SettingsPage />,
              },
            ],
          },
          {
            path: "admin",
            element: <AdminLayout />,
            children: [
              { index: true, element: <AdminPage /> },
              {
                path: "company/settings",
                element: <CompanySettingsPage />,
              },
              {
                path: "company/team",
                element: <CoachLayout type="employees" />,
                children: [
                  {
                    path: ":trainerId",
                    element: <CoachPage />,
                  },
                ],
              },
              {
                path: "company/candidates",
                element: <CoachLayout type="candidates" />,
                children: [
                  {
                    path: ":trainerId",
                    element: <CoachPage />,
                  },
                ],
              },
              {
                path: "company/clients",
                element: <ClientLayoutAdmin />,
                children: [
                  {
                    path: ":userId",
                    element: <ClientPageAdmin />,
                  },
                ],
              },
              {
                path: "company/vacations",
                element: <AllVacationsPage />,
              },
              {
                path: "company/metrics",
                element: (
                  <RestrictAccess location={"coach_metrics"}>
                    <MetricsPage
                      trainerId="admin"
                      showAllTrainers
                      showAdminMetrics
                    />
                  </RestrictAccess>
                ),
              },
              {
                path: "random-clients",
                element: <RandomClientsPage />,
              },
              {
                path: "trainer-availability",
                element: <TrainerAvailabilityPage />,
              },
              {
                path: "tickets/tech_issue",
                element: <TicketsPage type="tech_issue" />,
              },
              {
                path: "tickets/customer_support",
                element: <TicketsPage type="customer_support" />,
              },
              {
                path: "tickets/all/:ticketId",
                element: <TicketPage />,
              },
              {
                path: "short-urls",
                element: <ShortUrlPage />,
              },
              {
                path: "influencers",
                element: <InfluencerLayout />,
                children: [
                  {
                    path: ":influencerId",
                    element: <InfluencerPage />,
                  },
                ],
              },
              {
                path: "campaigns",
                element: <CampaignLayout />,
                children: [
                  {
                    path: ":campaignId",
                    element: <CampaignPage />,
                  },
                ],
              },
              {
                path: "analytics/tests",
                element: <TestLayout />,
                children: [
                  {
                    path: ":testId",
                    element: <TestPage />,
                  },
                ],
              },
              {
                path: "analytics/product-metrics",
                element: <ProductMetricsLayout />,
                children: [
                  {
                    path: ":productMetricId",
                    element: <ProductMetricsPage />,
                  },
                ],
              },
              {
                path: "analytics/lead-funnel",
                element: <FunnelLeadPage />,
              },
              {
                path: "analytics/client-funnel",
                element: <FunnelClientPage />,
              },
              {
                path: "data-cleaner",
                element: <DataCleaningPage />,
              },
              {
                path: "api-docs-nodejs",
                element: <DocsPage />,
              },
              {
                path: "api-docs-python",
                element: <DocsPythonPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export function AppRoutes() {
  return <RouterProvider router={router} />;
}
