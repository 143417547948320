import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DnsRoundedIcon from "@mui/icons-material/DnsRounded";
import ModelTrainingRoundedIcon from "@mui/icons-material/ModelTrainingRounded";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import ConfirmationDialog from "src/components/misc/ConfirmationDialog";
import Label from "src/components/misc/Label";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { TRAINWELL_API_DOMAIN, TRAINWELL_API_KEY } from "src/config/config";
import { useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";
import { api } from "src/lib/trainwellApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

let key: string | undefined = undefined;
let bucket: string | undefined = undefined;

export default function VoiceSettings() {
  const { enqueueSnackbar } = useSnackbar();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [uploadingStatus, setUploadingStatus] = useState<
    "idle" | "pending" | "uploading" | "done"
  >("idle");
  const trainerEdit = useAppSelector((state) => state.trainerEdit.trainer);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const theme = useTheme();
  const [uppy] = useState(() =>
    new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
      },
      // @ts-expect-error
    }).use(AwsS3, {
      // Use multipart only for files larger than 100MiB.
      shouldUseMultipart() {
        // Use multipart only for files larger than 100MiB.
        return false;
      },
      getUploadParameters(file) {
        console.log("fetching upload parameters for file:", file); // here you can have a look at the file object - get rid of this for production!
        // here we prepare our request to the server for the upload URL
        return fetch(`${TRAINWELL_API_DOMAIN}/upload/createUrl`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": TRAINWELL_API_KEY,
            authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            content_type: file.type,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log(">>>", data); // here you can have a look at the data the server sent back - get rid of this for production!
            key = data.key;
            bucket = data.bucket;
            return {
              method: "PUT", // here we send method, url, fields and headers to the AWS S3 bucket
              url: data.url,
              fields: {},
              headers: { "content-type": file.type },
            };
          });
      },
    }),
  );

  useEffect(() => {
    uppy.cancelAll();

    uppy.once("upload", () => {
      setUploadingStatus("uploading");
    });

    uppy.once("complete", (result) => {
      if (result.successful) {
        console.log(
          "Upload complete! We've uploaded these files:",
          result.successful,
          key,
          bucket,
        ); // if upload succeeds, let's see what we uploaded

        if (bucket && key && trainerEdit?.trainer_id) {
          api.trainers
            .trainVoice({
              bucket: bucket,
              key: key,
              trainerId: trainerEdit.trainer_id,
            })
            .then(() => {
              enqueueSnackbar("Upload complete, training will finish soon", {
                variant: "success",
              });

              uppy.clear();
              setUploadingStatus("done");
            })
            .catch(() => {
              enqueueSnackbar("Training failed", {
                variant: "error",
              });
            });
        } else {
          enqueueSnackbar("Upload failed", {
            variant: "error",
          });
        }
      } else {
        console.log("Upload error: ", result.failed); // if upload failed, let's see what went wrong
      }
    });
  }, [trainerEdit?.trainer_id]);

  if (!trainerEdit) {
    return <LoadingPage message="Getting your trainer" />;
  }

  return (
    <>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Voice
      </Typography>
      <Label
        content={trainerEdit.voice_id ?? "None"}
        label="Descript voice id"
      />
      <Label
        content={trainerEdit.voice_id_eleven_labs ?? "None"}
        label="ElevenLabs voice id"
      />
      <Stack direction={"row"} spacing={2}>
        {uploadingStatus === "idle" ? (
          <Button
            startIcon={<ModelTrainingRoundedIcon />}
            size="small"
            variant="contained"
            onClick={() => {
              setUploadingStatus("pending");
            }}
            disabled={
              !trainerHasAccess(trainer?.access_roles, "voice_training")
            }
          >
            Train new voice
          </Button>
        ) : uploadingStatus === "pending" ? (
          <Button
            startIcon={<CloseRoundedIcon />}
            size="small"
            variant="contained"
            onClick={() => {
              setUploadingStatus("idle");
              uppy.cancelAll();
            }}
          >
            Cancel
          </Button>
        ) : null}
        <Button
          startIcon={<DnsRoundedIcon />}
          size="small"
          variant="contained"
          color="error"
          onClick={() => {
            setConfirmDialogOpen(true);
          }}
          disabled={!trainerHasAccess(trainer?.access_roles, "voice_training")}
        >
          Regenerate voice prompts
        </Button>
      </Stack>
      {uploadingStatus === "done" && (
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <CheckCircleRoundedIcon color="success" />
          <Typography sx={{ ml: 1 }}>
            Upload complete. Training should finish soon!
          </Typography>
        </Box>
      )}
      {(uploadingStatus === "pending" || uploadingStatus === "uploading") && (
        <>
          <Dashboard
            uppy={uppy}
            proudlyDisplayPoweredByUppy={false}
            showLinkToFileUploadResult={false}
            height={"300px"}
            theme={theme.palette.mode}
          />
        </>
      )}
      <ConfirmationDialog
        open={confirmDialogOpen}
        message="This will regenerate A LOT of voice prompts for this trainer which can take time, resources, and money. Make sure you're sure."
        title="Regenerate voice prompts"
        confirmButtonText="Regenerate voices"
        variant="error"
        onClose={(didConfirm) => {
          setConfirmDialogOpen(false);

          if (didConfirm) {
            api.voice
              .pregenerateVoicePrompts(trainerEdit.trainer_id)
              .then((response) => {
                enqueueSnackbar(
                  `Generating ${response.new_prompts_count} voice prompts`,
                  {
                    variant: "success",
                  },
                );
              })
              .catch(() => {
                enqueueSnackbar("Regeneration failed", {
                  variant: "error",
                });
              });
          }
        }}
      />
    </>
  );
}
