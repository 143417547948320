import { Box, Paper, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import RestrictAccess from "src/components/misc/RestrictAccess";
import SearchField from "src/components/misc/SearchField";
import { ProductMertricCell } from "./ProductMertricCell";

const drawerWidth = 240;

const metricIds = [
  "copilot_workouts_per_week_mean",
  "workout_completion_mean",
  "workouts_with_disconnection_percent",
  "workouts_with_unspoken_prompt_percent",
  "unspoken_prompts_percent_mean",
  "disconnections_per_workout_mean",
];

export function ProductMetricsLayout() {
  const [search, setSearch] = useState("");

  const displayedMetricIds = useMemo(() => {
    let newMetrics = [...metricIds];

    if (search !== "") {
      newMetrics = newMetrics.filter((test) =>
        test.toLowerCase().includes(search.toLowerCase()),
      );
    }

    newMetrics.sort((a, b) => {
      return a.localeCompare(b);
    });

    return newMetrics;
  }, [search, metricIds]);

  return (
    <RestrictAccess location={"product_metrics"} showNoAccessMessage>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Paper
          square
          elevation={0}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            maxHeight: "100%",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column",
            borderRight: 1,
            borderColor: "divider",
          }}
        >
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
              px: 2,
              py: 1,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Typography variant="h1">Product metrics</Typography>
            <Typography variant="overline">
              Showing {(displayedMetricIds ?? []).length} metrics
            </Typography>
          </Box>
          <Box sx={{ overflowY: "auto", flex: 1 }}>
            <Box
              sx={{
                p: 1,
                backgroundColor: (theme) => theme.palette.background.paper,
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <SearchField
                value={search}
                onChange={(value) => {
                  setSearch(value);
                }}
                onClear={() => {
                  setSearch("");
                }}
              />
            </Box>
            <Stack direction="column">
              {displayedMetricIds.map((productMetricId) => (
                <ProductMertricCell
                  key={productMetricId}
                  productMetricId={productMetricId}
                />
              ))}
            </Stack>
          </Box>
        </Paper>
        <Box sx={{ flexGrow: 1, overflowX: "hidden" }}>
          <Outlet />
        </Box>
      </Box>
    </RestrictAccess>
  );
}
