import { Droppable } from "@hello-pangea/dnd";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useHotkeys } from "react-hotkeys-hook";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  setEquipmentWarningDialog,
  toggleEquipment,
  toggleIsInsertingTemplate,
} from "src/slices/workoutSlice";
import SectionCell from "./SectionCell";
import WorkoutBuilderHelper from "./WorkoutBuilderHelper";
import { WorkoutHeader } from "./WorkoutHeader";
import WorkoutLogCard from "./WorkoutLogCard";

export default function WorkoutBuilder() {
  const dispatch = useAppDispatch();
  const isTemplate = useAppSelector((state) => state.workout.isTemplate);
  const workoutStatus = useAppSelector((state) => state.workout.status);
  const workout = useAppSelector(
    (state) => state.workout.workoutNormalized?.result,
  );
  const equipmentWarningOpen = useAppSelector(
    (state) => state.workout.equipmentWarningOpen,
  );

  useHotkeys("t", () => {
    if (!isTemplate) {
      dispatch(toggleIsInsertingTemplate());
    }
  });

  if (!workout || workoutStatus !== "succeeded") {
    return <LoadingPage message="Getting your workout ready 💪" />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
        overflowY: "hidden",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          overflowY: "hidden",
        }}
      >
        <Box>
          <WorkoutHeader />
          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <EquipmentPreview userId={workout.user_id} />
          </Box> */}
        </Box>
        <Box sx={{ overflowY: "auto", flex: 1, maxHeight: "100%" }}>
          <WorkoutLogCard />
          <Droppable droppableId="sections" type="section">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {workout.sections.map((sectionId, index) => (
                  <SectionCell
                    key={sectionId}
                    sectionId={sectionId}
                    sectionIndex={index}
                    isLastSection={index === workout.sections.length - 1}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Box>
      </Box>
      <Dialog
        open={equipmentWarningOpen !== null}
        onClose={() => {
          dispatch(setEquipmentWarningDialog(null));
        }}
      >
        <DialogTitle>Check set notes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Some set notes reference equipment. Make sure they are accurate when
            swapping.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(setEquipmentWarningDialog(null));
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              dispatch(
                toggleEquipment({
                  exerciseId: equipmentWarningOpen!.exerciseId,
                  equipmentPickerId: equipmentWarningOpen!.equipmentPickerId,
                }),
              );
            }}
          >
            Swap anyways
          </Button>
        </DialogActions>
      </Dialog>
      <WorkoutBuilderHelper />
    </Box>
  );
}
