import CalendarViewWeekRoundedIcon from "@mui/icons-material/CalendarViewWeekRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import { Avatar, Box, Typography } from "@mui/material";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPhaseTemplateById } from "src/slices/phaseTemplatesSlice";
import { selectTemplateById } from "src/slices/templatesSlice";

type Props = {
  phaseTemplateId?: string;
  workoutTemplateId?: string;
};

export function PhaseTemplateDragOverlay({
  phaseTemplateId,
  workoutTemplateId,
}: Props) {
  const phaseTemplate = useAppSelector((state) =>
    selectPhaseTemplateById(state, phaseTemplateId ?? ""),
  );
  const workoutTemplate = useAppSelector((state) =>
    selectTemplateById(state, workoutTemplateId ?? ""),
  );

  if (
    (phaseTemplateId && !phaseTemplate) ||
    (workoutTemplateId && !workoutTemplate)
  ) {
    return <Typography>Error</Typography>;
  }

  let icon = (
    <Avatar
      sx={{
        backgroundColor: (theme) => theme.palette.blue.main,
        mr: 1,
        width: 20,
        height: 20,
        borderRadius: "6px",
      }}
    >
      <FitnessCenterRoundedIcon
        sx={{
          fontSize: 16,
        }}
      />
    </Avatar>
  );

  if (phaseTemplate?.type === "multiple") {
    icon = (
      <Avatar
        sx={{
          backgroundColor: (theme) => theme.palette.success.main,
          mr: 1,
          width: 20,
          height: 20,
          borderRadius: "6px",
        }}
      >
        <CalendarViewWeekRoundedIcon
          sx={{
            fontSize: 16,
          }}
        />
      </Avatar>
    );
  }

  return (
    <Box
      sx={{
        border: 1,
        borderColor: "divider",
        borderRadius: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
        maxWidth: "300px",
        boxShadow: 7,
        display: "inline-block",
        p: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {icon}
        <Typography sx={{ whiteSpace: "nowrap" }}>
          {workoutTemplate ? workoutTemplate.name : phaseTemplate?.name}
        </Typography>
      </Box>
    </Box>
  );
}
