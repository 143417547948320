import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {
  Avatar,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import Transloadit from "@uppy/transloadit";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { TRANSLOADIT_AUTH_KEY } from "src/config/config";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { updateTrainer } from "src/slices/trainersSlice";

export function CoachProfilePictureEdit() {
  const dispatch = useAppDispatch();
  const trainerEdit = useAppSelector((state) => state.trainerEdit.trainer);
  const [isHovering, setIsHovering] = useState(false);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [uppy] = useState(() =>
    new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*"],
      },
    })
      .use(Transloadit, {
        assemblyOptions: {
          params: {
            auth: { key: TRANSLOADIT_AUTH_KEY },
            template_id: "7f08b1991ab846ed89c8eab9b4a9fddd",
          },
        },
        waitForEncoding: true,
      })
      .on("transloadit:complete", (assembly) => {
        if (
          "original" in assembly.results &&
          assembly.results["original"].length &&
          assembly.results["original"][0].ssl_url &&
          "high-res" in assembly.results &&
          "low-res" in assembly.results &&
          assembly.results["low-res"].length &&
          assembly.results["low-res"][0].ssl_url &&
          "high-res" in assembly.results &&
          assembly.results["high-res"].length &&
          assembly.results["high-res"][0].ssl_url
        ) {
          const originalResult = assembly.results["original"][0];
          const lowResResult = assembly.results["low-res"][0];
          const highResResult = assembly.results["high-res"][0];

          dispatch(
            updateTrainer({
              trainer_id: trainerEdit!.trainer_id,
              headshot_url: lowResResult.ssl_url,
              headshot_url_hd: highResResult.ssl_url,
              headshot_url_original: originalResult.ssl_url,
            }),
          )
            .unwrap()
            .catch(() => {
              enqueueSnackbar("Failed to upload", {
                variant: "error",
              });
            });
        }
      })
      .on("complete", () => {
        uppy.cancelAll();
        setOpen(false);

        enqueueSnackbar("Changed profile picture", {
          variant: "success",
        });
      }),
  );
  const theme = useTheme();

  return (
    <>
      <ButtonBase
        sx={{ position: "relative", borderRadius: "25px" }}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <img
          style={{
            borderRadius: "25px",
            display: "block",
            opacity: isHovering ? 0.5 : 1,
            transition: "opacity 0.2s ease",
          }}
          src={trainerEdit?.headshot_url || "/assets/profile.png"}
          alt={trainerEdit?.full_name}
          width={50}
          height={50}
        />
        <Avatar
          sx={{
            position: "absolute",
            top: "51%",
            left: "51%",
            margin: `-10px`,
            width: 20,
            height: 20,
            borderRadius: `10px`,
            transform: `rotate(45deg) translate(25px) rotate(-45deg)`,
            backgroundColor: (theme) => theme.palette.primary.main,
            border: 2,
            borderColor: (theme) => theme.palette.background.default,
          }}
        >
          <EditRoundedIcon sx={{ fontSize: "12px" }} />
        </Avatar>
      </ButtonBase>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Change profile picture</DialogTitle>
        <DialogContent>
          <Dashboard
            uppy={uppy}
            proudlyDisplayPoweredByUppy={false}
            showLinkToFileUploadResult={false}
            height={"300px"}
            theme={theme.palette.mode}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
