import { Box, CardActionArea, Typography } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import useActionItem from "src/hooks/useActionItem";
import { getActionItemDetails } from "src/lib/actionItem";
import { getActionItemStatus } from "src/lib/officeHours";
import { selectActionItemById } from "src/slices/actionItemSlice";
import { selectClientById } from "src/slices/clientsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import CheckInDialog from "../misc/CheckInDialog";

type Props = {
  actionItemId: string;
};

export default function ActionItemCell({ actionItemId }: Props) {
  const actionItem = useAppSelector((state) =>
    selectActionItemById(state, actionItemId),
  );
  const trainer = useAppSelector(selectPrimaryTrainer);
  const { handleActionItemClick } = useActionItem();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const clientName = useAppSelector(
    (state) =>
      selectClientById(state, actionItem?.user_id ?? "")?.full_name ??
      "Client not found",
  );

  if (!actionItem) {
    return <Typography>Loading</Typography>;
  }

  const details = getActionItemDetails(actionItem);

  const status = getActionItemStatus(actionItem, trainer!.office_hours);

  return (
    <>
      <CardActionArea
        sx={{
          backgroundColor: (theme) =>
            status === "ok"
              ? theme.palette.background.paper
              : status === "warn"
                ? theme.palette.warningSurface.main
                : theme.palette.errorSurface.main,
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          p: 1,
          pl: 2,
        }}
        onClick={() => {
          if (actionItem.type === "check_in_survey_send") {
            setIsDialogOpen(true);
          }

          handleActionItemClick(actionItem);
        }}
      >
        {details.icon}
        <Box sx={{ ml: 2, flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Typography>{clientName}</Typography>
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.text.secondary,
                textAlign: "end",
              }}
            >
              {format(
                new Date(actionItem.date_to_send ?? actionItem.date_created),
                "MMM do, h:mmaaa",
              )}
            </Typography>
          </Box>
          <Typography variant="h6">{details.name}</Typography>
          {details.notes && (
            <Typography variant="body2">{details.notes}</Typography>
          )}
        </Box>
      </CardActionArea>
      {actionItem.type === "check_in_survey_send" && (
        <CheckInDialog
          userId={actionItem.user_id}
          actionItemId={actionItemId}
          open={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
          }}
        />
      )}
    </>
  );
}
