export function getAverage(numbers: number[]) {
  const total = numbers.reduce((acc, c) => acc + c, 0);
  return total / numbers.length;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomNumber(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function wrapNumber(num: number, min: number, max: number) {
  return min + ((num - min) % (max - min));
}

export function toTitleCase(string: string) {
  return string.replace(/\w\S*/g, function (text) {
    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
  });
}

export function toSentenceCase(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatPercent(value: number) {
  if (value < 0.1) {
    return String(round(value * 100, 1)) + "%";
  } else {
    return String(round(value * 100)) + "%";
  }
}

export function round(metric: number, precision?: number) {
  if (precision === undefined) {
    return Math.round(metric);
  }
  return Math.round(metric * 10 ** precision) / 10 ** precision;
}

export function range(min: number, max: number) {
  const len = max - min + 1;
  const arr = new Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = min + i;
  }
  return arr;
}

export function closeToNumber(
  value: number,
  target: number,
  plusMinus: number,
) {
  const lowerBound = target - plusMinus;
  const upperBound = target + plusMinus;

  return value >= lowerBound && value <= upperBound;
}

export function getFormattedTime(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds - minutes * 60;

  let formattedTime = "";

  if (minutes && remainingSeconds) {
    formattedTime = `${minutes}m ${remainingSeconds}s`;
  } else if (minutes) {
    formattedTime = `${minutes}m`;
  } else if (remainingSeconds) {
    formattedTime = `${remainingSeconds}s`;
  }

  return formattedTime;
}

export function getFormattedTimeFromMinutes(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes - hours * 60;

  const performedString = hours
    ? `${hours}h ${remainingMinutes}m`
    : `${remainingMinutes}m`;

  return performedString;
}

export function formatCentsToDollars(cents: number) {
  const dollarNumber = cents / 100;
  const dollars = dollarNumber.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  return dollars;
}

export function randomPastel() {
  return (
    "hsl(" +
    360 * Math.random() +
    "," +
    (25 + 70 * Math.random()) +
    "%," +
    (85 + 10 * Math.random()) +
    "%)"
  );
}

export function randomDark() {
  return (
    "hsl(" +
    Math.floor(Math.random() * 360) +
    ", " +
    Math.floor(Math.random() * 100) +
    "%, " +
    Math.floor(Math.random() * 50) +
    "%)"
  );
}
