import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { useInfluencers } from "@trainwell/features";
import { useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import RestrictAccess from "src/components/misc/RestrictAccess";
import SearchField from "src/components/misc/SearchField";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { InfluencerCell } from "./InfluencerCell";
import { InfluencerEditDialog } from "./InfluencerEditDialog";

const drawerWidth = 240;

export function InfluencerLayout() {
  const [search, setSearch] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const { data: influencers, isLoading: isLoadingInfluencers } =
    useInfluencers();

  const displayedInfluencers = useMemo(() => {
    if (isLoadingInfluencers) {
      return [];
    }

    let newInfluencers = [...(influencers ?? [])];

    if (search !== "") {
      newInfluencers = newInfluencers.filter((influencer) =>
        influencer.display_name.toLowerCase().includes(search.toLowerCase()),
      );
    }

    newInfluencers.sort((a, b) =>
      (b.date_added as string).localeCompare(a.date_added as string),
    );

    return newInfluencers;
  }, [search, influencers]);

  return (
    <RestrictAccess location={"campaigns"} showNoAccessMessage>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Paper
          square
          elevation={0}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            maxHeight: "100%",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column",
            borderRight: 1,
            borderColor: "divider",
          }}
        >
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
              px: 2,
              py: 1,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Typography variant="h1">Influencers</Typography>
            <Typography variant="overline">
              Showing {(displayedInfluencers ?? []).length} influencers
            </Typography>
          </Box>
          <Box sx={{ overflowY: "auto", flex: 1 }}>
            <Box
              sx={{
                p: 1,
                backgroundColor: (theme) => theme.palette.background.paper,
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <SearchField
                value={search}
                onChange={(value) => {
                  setSearch(value);
                }}
                onClear={() => {
                  setSearch("");
                }}
              />
              <Button
                startIcon={<AddRoundedIcon />}
                size="small"
                fullWidth
                onClick={() => {
                  setEditDialogOpen(true);
                }}
                sx={{ mt: 1 }}
                disabled={isLoadingInfluencers}
              >
                New
              </Button>
            </Box>
            {isLoadingInfluencers ? (
              <LoadingComponent message="Loading influencers" />
            ) : (
              <Stack direction="column">
                {displayedInfluencers.map((influencer) => (
                  <InfluencerCell key={influencer.id} influencer={influencer} />
                ))}
              </Stack>
            )}
          </Box>
        </Paper>
        <Box sx={{ flexGrow: 1, overflowX: "hidden" }}>
          <Outlet />
        </Box>
      </Box>
      <InfluencerEditDialog
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
        }}
      />
    </RestrictAccess>
  );
}
