import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { useAppDispatch } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { setClientEdit } from "src/slices/clientEditSlice";

type Props = {
  open: boolean;
  onClose: () => void;
  userId: string;
};

export default function ExtendPauseDialog({ open, onClose, userId }: Props) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [extraMonths, setExtraMonths] = useState<number | string>("");
  const [loading, setLoading] = useState(false);

  function resetAndClose() {
    onClose();

    setLoading(false);
    setExtraMonths("");
  }

  const isValid = Boolean(extraMonths);

  return (
    <Dialog onClose={resetAndClose} open={open} fullWidth maxWidth="sm">
      <DialogTitleWithClose
        onClose={() => {
          if (loading) {
            return;
          }

          resetAndClose();
        }}
      >
        Extend pause
      </DialogTitleWithClose>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Extend client&apos;s pause by a specified amount of months
        </DialogContentText>
        <TextField
          label="Extra months"
          fullWidth
          value={extraMonths}
          onChange={(event) => {
            setExtraMonths(
              Number(event.target.value) ? Number(event.target.value) : "",
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={resetAndClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          disabled={!isValid}
          onClick={() => {
            if (!extraMonths) {
              return;
            }

            setLoading(true);

            api.clients
              .extendPause(userId, Number(extraMonths))
              .then((client) => {
                enqueueSnackbar("Extended trial", {
                  variant: "success",
                });

                dispatch(setClientEdit(client));

                resetAndClose();
              })
              .catch(() => {
                enqueueSnackbar("Error extending trial", {
                  variant: "error",
                });

                setLoading(false);
              });
          }}
        >
          Extend pause
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
