import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import { useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";

const icon = <CheckBoxOutlineBlankRoundedIcon fontSize="small" />;
const checkedIcon = <CheckBoxRoundedIcon fontSize="small" />;

interface Time {
  start: string;
  end: string;
}

interface CallType {
  id: "onboarding" | "check_in";
  label: string;
}

const callTypeOptions: CallType[] = [
  {
    id: "onboarding",
    label: "Onboarding",
  },
  {
    id: "check_in",
    label: "Check in",
  },
];

export function TrainerAvailabilityPage() {
  const [times, setTimes] = useState<Time[]>([]);
  const [coaches, setCoaches] = useState<Awaited<
    ReturnType<(typeof api)["trainers"]["getAvailableDuringDateRanges"]>
  > | null>(null);
  const [coachTimezones, setCoachTimezones] = useState<string[]>([]);
  const [selectedCallTypes, setSelectedCallTypes] = useState<CallType[]>([
    callTypeOptions[0],
  ]);
  const [searchTimezone, setSearchTimezone] = useState<string | null>(null);
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);

  const timezones = [
    ...new Set(
      trainerNames
        .filter((c) => c.is_employee)
        .map((trainer) => trainer.timezone)
        .filter(Boolean),
    ),
  ] as string[];

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h1" sx={{ mb: 2 }}>
        Search trainer availability
      </Typography>
      <Autocomplete
        multiple
        options={callTypeOptions}
        disableCloseOnSelect
        getOptionLabel={(callType) => callType.label}
        value={selectedCallTypes}
        onChange={(event, newValues) => {
          setSelectedCallTypes(newValues as any);
        }}
        size="small"
        renderOption={(props, callType, { selected }) => {
          return (
            <li
              {...props}
              style={{
                paddingLeft: 8,
                paddingRight: 8,
                paddingBottom: 0,
                paddingTop: 0,
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {callType.label}
            </li>
          );
        }}
        style={{ width: 400 }}
        renderInput={(params) => <TextField {...params} label="Call types" />}
        sx={{ mb: 2 }}
      />
      <Autocomplete
        multiple
        options={timezones}
        disableCloseOnSelect
        getOptionLabel={(timezone) => timezone}
        value={coachTimezones}
        onChange={(event, newValues) => {
          setCoachTimezones(newValues);
        }}
        size="small"
        renderOption={(props, timezone, { selected }) => {
          return (
            <li
              {...props}
              style={{
                paddingLeft: 8,
                paddingRight: 8,
                paddingBottom: 0,
                paddingTop: 0,
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {timezone}
            </li>
          );
        }}
        style={{ width: 400 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Only trainers in these timezones"
            helperText="Only include trainers in these timezones"
          />
        )}
        sx={{ mb: 2 }}
      />
      <Autocomplete
        options={timezones}
        getOptionLabel={(timezone) => timezone}
        value={searchTimezone}
        onChange={(event, newValue) => {
          setSearchTimezone(newValue);
        }}
        style={{ width: 400 }}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search timezone"
            helperText="Set the timezone that you're searching in (defaults to your timezone)"
          />
        )}
        sx={{ mb: 2 }}
      />
      <Stack direction="column" sx={{ my: 4, maxWidth: "500px" }} spacing={2}>
        {times.map((time, i) => (
          <Stack key={i} direction="row" spacing={2}>
            <DateTimePicker
              label="Start date"
              value={new Date(time.start)}
              onChange={(newValue) => {
                const newTimes = JSON.parse(JSON.stringify(times)) as Time[];

                newTimes[i].start = (newValue ?? new Date()).toISOString();

                setTimes(newTimes);
              }}
              minDate={new Date()}
            />
            <DateTimePicker
              label="End date"
              value={new Date(time.end)}
              onChange={(newValue) => {
                const newTimes = JSON.parse(JSON.stringify(times)) as Time[];

                newTimes[i].end = (newValue ?? new Date()).toISOString();

                setTimes(newTimes);
              }}
              minDate={new Date()}
            />
          </Stack>
        ))}
      </Stack>
      <Button
        onClick={() => {
          const newTimes = JSON.parse(JSON.stringify(times)) as Time[];

          newTimes.push({
            start: new Date().toISOString(),
            end: new Date().toISOString(),
          });

          setTimes(newTimes);
        }}
        startIcon={<AddRoundedIcon />}
        variant="outlined"
      >
        Add time
      </Button>
      <Button
        onClick={() => {
          setCoaches(null);

          const newTimes = JSON.parse(JSON.stringify(times)) as Time[];

          if (searchTimezone) {
            newTimes.forEach((time) => {
              time.start = fromZonedTime(
                time.start,
                searchTimezone,
              ).toISOString();
              time.end = fromZonedTime(time.end, searchTimezone).toISOString();
            });
          }

          api.trainers
            .getAvailableDuringDateRanges({
              dateRanges: newTimes,
              timezones: coachTimezones,
              callTypes: selectedCallTypes.map((callType) => callType.id),
            })
            .then((fetchedCoaches) => {
              fetchedCoaches.sort((a, b) => {
                return a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1;
              });
              console.log(fetchedCoaches);
              setCoaches(fetchedCoaches);
            });
        }}
        disabled={times.length === 0}
        startIcon={<SearchRoundedIcon />}
        sx={{ ml: 2 }}
      >
        Search
      </Button>
      {coaches !== null && (
        <>
          <Typography variant="h2" sx={{ mb: 2, mt: 4 }}>
            Available trainers
          </Typography>
          <Stack direction={"column"} spacing={1}>
            {coaches.map((trainer) => {
              const groupedAvailabilities =
                trainer.availabilities_matching.reduce(
                  (groups, availability) => {
                    const day = format(new Date(availability), "MMM do");

                    const group = groups[day] || [];
                    group.push(availability);
                    groups[day] = group;
                    return groups;
                  },
                  {} as Record<string, string[]>,
                );

              const availabilities: { day: string; dates: string[] }[] = [];

              for (const day in groupedAvailabilities) {
                availabilities.push({
                  day: day,
                  dates: groupedAvailabilities[day],
                });
              }

              return (
                <Box key={trainer.trainer_id}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {trainer.full_name}
                    </Typography>
                    {trainer.is_active ? (
                      <Chip
                        label="Active"
                        size="small"
                        color="success"
                        sx={{ ml: 2 }}
                      />
                    ) : (
                      <Chip
                        label="Inactive"
                        size="small"
                        color="default"
                        sx={{ ml: 2 }}
                      />
                    )}
                  </Box>
                  {availabilities.map((availability) => (
                    <Typography key={availability.day} variant="body2">
                      <b>{availability.day}:</b>{" "}
                      {availability.dates
                        ?.map((available) =>
                          format(new Date(available), "h:mm a"),
                        )
                        .join(", ")}
                    </Typography>
                  ))}
                </Box>
              );
            })}
          </Stack>
        </>
      )}
    </Box>
  );
}
