import CameraEnhanceRoundedIcon from "@mui/icons-material/CameraEnhanceRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import { Box, Card, CardActionArea, Grid, Typography } from "@mui/material";
import SmallMomentsMatter from "src/assets/habits/small-moments-matter.svg";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import RestrictAccess from "../misc/RestrictAccess";

export default function AdminPage() {
  const trainer = useAppSelector(selectPrimaryTrainer);

  if (!trainer?.is_admin) {
    return <Typography>Error</Typography>;
  }

  return (
    <Box
      sx={{ p: 3, display: "flex", flexDirection: "column", height: "100%" }}
    >
      <Box>
        <Typography variant="h1">Admin dash</Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          For all of your admin dash needs :)
        </Typography>
        <Grid container spacing={3}>
          <RestrictAccess location={"client_list"}>
            <Grid item xs={4}>
              <Card variant="outlined">
                <CardActionArea
                  href="/admin/company/clients"
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <GroupsRoundedIcon
                    color="primary"
                    fontSize="medium"
                    sx={{ mr: 2 }}
                  />
                  <Box>
                    <Typography variant="h2">Clients</Typography>
                    <Typography variant="overline">
                      Search and maintain clients
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          </RestrictAccess>
          <RestrictAccess location={"coach_list"}>
            <Grid item xs={4}>
              <Card variant="outlined">
                <CardActionArea
                  href="/admin/company/team"
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <PeopleRoundedIcon
                    color="primary"
                    fontSize="medium"
                    sx={{ mr: 2 }}
                  />
                  <Box>
                    <Typography variant="h2">Team</Typography>
                    <Typography variant="overline">
                      Our all-star trainwell team
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          </RestrictAccess>
          <RestrictAccess location={"campaigns"}>
            <Grid item xs={4}>
              <Card variant="outlined">
                <CardActionArea
                  href="/admin/influencers"
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <CameraEnhanceRoundedIcon
                    color="primary"
                    fontSize="medium"
                    sx={{ mr: 2 }}
                  />
                  <Box>
                    <Typography variant="h2">Influencers</Typography>
                    <Typography variant="overline">✨fab✨</Typography>
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          </RestrictAccess>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <img src={SmallMomentsMatter} width={300} />
      </Box>
    </Box>
  );
}
