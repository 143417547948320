import { Box } from "@mui/material";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppSelector } from "src/hooks/stateHooks";
import MetricsPage from "../../trainer-metrics/MetricsPage";

export function TabMetrics() {
  const trainerEdit = useAppSelector((state) => state.trainerEdit.trainer);

  if (!trainerEdit) {
    return <LoadingPage message="Getting your trainer" />;
  }

  return (
    <Box>
      <MetricsPage trainerId={trainerEdit.trainer_id} showAdminMetrics />
    </Box>
  );
}
