import AddRoundedIcon from "@mui/icons-material/AddRounded";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import ErrorDialog from "src/components/misc/ErrorDialog";
import RestrictAccess from "src/components/misc/RestrictAccess";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { employTrainer, terminateCoach } from "src/slices/trainersSlice";
import AssignRealClientDialog from "./AssignRealClientDialog";
import AssignTestClientsDialog from "./AssignTestClientsDialog";
import ConfirmTerminateDialog from "./ConfirmTerminateDialog";

export function CoachMoreButton() {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const trainerEdit = useAppSelector((state) => state.trainerEdit.trainer);
  const [messageToSend, setMessageToSend] = useState<string | null>(null);
  const [realClientDialogOpen, setRealClientDialogOpen] = useState(false);
  const [testClientDialogOpen, setTestClientDialogOpen] = useState(false);
  const [terminateDialogOpen, setTerminateDialogOpen] = useState(false);
  const [errorMessageEmploy, setErrorMessageEmploy] = useState<string | null>(
    null,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  if (!trainerEdit) {
    return <LoadingPage message="Getting your trainer" />;
  }

  return (
    <Box>
      <IconButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <MoreVertRoundedIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            api.trainers.addTestClient(trainerEdit.trainer_id).then(() => {
              enqueueSnackbar("Added Darth Vader client", {
                variant: "success",
              });
            });
          }}
        >
          <ListItemIcon>
            <AddRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={"Add Darth Vader client"} />
        </MenuItem>
        {trainerEdit.is_candidate && (
          <>
            <MenuItem
              onClick={() => {
                setTestClientDialogOpen(true);
              }}
            >
              <ListItemIcon>
                <PeopleRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={"Assign test clients"} />
            </MenuItem>
            <MenuItem
              onClick={() => {
                setRealClientDialogOpen(true);
              }}
            >
              <ListItemIcon>
                <PersonRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={"Assign real client"} />
            </MenuItem>
          </>
        )}
        <MenuItem
          onClick={() => {
            api.trainers
              .removeTestClients(trainerEdit.trainer_id)
              .then((resp: any) => {
                if (resp.status !== "error") {
                  enqueueSnackbar("Removed test clients", {
                    variant: "success",
                  });
                } else {
                  enqueueSnackbar("Error removing test clients", {
                    variant: "error",
                  });
                }
              });
          }}
        >
          <ListItemIcon>
            <RemoveCircleRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={"Remove test clients"} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMessageToSend("");
          }}
        >
          <ListItemIcon>
            <SendRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={"Message clients"} />
        </MenuItem>
        {trainerEdit.is_candidate &&
          !trainerEdit.is_employee &&
          !trainerEdit.disable_access && (
            <MenuItem
              onClick={() => {
                dispatch(employTrainer(trainerEdit.trainer_id))
                  .unwrap()
                  .then(() => {
                    enqueueSnackbar("Employed trainer", {
                      variant: "success",
                    });
                  })
                  .catch((errors) => {
                    setErrorMessageEmploy(errors.message);
                  });
              }}
            >
              <ListItemIcon color="success">
                <BusinessCenterRoundedIcon color="success" fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={"Employ"}
                sx={{
                  color: (theme) => theme.palette.success.main,
                }}
              />
            </MenuItem>
          )}
        <RestrictAccess location="staff_management">
          <Box>
            <Divider sx={{ mb: 1, mt: 2 }} />
          </Box>
          <MenuItem
            onClick={() => {
              setTerminateDialogOpen(true);
            }}
            disabled={trainerEdit.disable_access}
          >
            <ListItemIcon>
              <DeleteRoundedIcon fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText
              primary={trainerEdit.disable_access ? "Terminated" : "Terminate"}
              sx={{ color: (theme) => theme.palette.error.main }}
            />
          </MenuItem>
        </RestrictAccess>
      </Menu>
      <Dialog
        onClose={() => {
          setMessageToSend(null);
        }}
        open={messageToSend !== null}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Send mass message</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            This will send a mass message to all of{" "}
            <b>{trainerEdit.full_name}&apos;s</b> clients
          </DialogContentText>
          <TextField
            multiline
            fullWidth
            placeholder="Message"
            helperText={`Message will automatically start with "[Automated Message]"`}
            value={messageToSend}
            onChange={(event) => {
              setMessageToSend(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setMessageToSend(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            startIcon={<SendRoundedIcon />}
            onClick={() => {
              if (!messageToSend) {
                return;
              }

              api.trainers
                .sendMessageToClients(trainerEdit.trainer_id, messageToSend)
                .then(() => {
                  enqueueSnackbar("Sent message", {
                    variant: "success",
                  });
                });

              setMessageToSend(null);
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <AssignTestClientsDialog
        trainerId={trainerEdit.trainer_id}
        open={testClientDialogOpen}
        onClose={() => {
          setTestClientDialogOpen(false);
        }}
      />
      <AssignRealClientDialog
        trainerId={trainerEdit.trainer_id}
        open={realClientDialogOpen}
        onClose={() => {
          setRealClientDialogOpen(false);
        }}
      />
      <ConfirmTerminateDialog
        open={terminateDialogOpen}
        onClose={(confirmed) => {
          if (confirmed) {
            dispatch(terminateCoach(trainerEdit.trainer_id))
              .unwrap()
              .then(() => {
                enqueueSnackbar("Terminated trainer", {
                  variant: "success",
                });
              })
              .catch(() => {
                enqueueSnackbar("Error terminating trainer", {
                  variant: "error",
                });
              });
          }

          setTerminateDialogOpen(false);
        }}
      />
      <ErrorDialog
        open={Boolean(errorMessageEmploy)}
        onClose={() => {
          setErrorMessageEmploy(null);
        }}
        title="Error employing trainer"
        message={
          errorMessageEmploy ?? "There was an error employing this trainer"
        }
      />
    </Box>
  );
}
