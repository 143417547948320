import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { alpha, Box, CardActionArea, Stack, Typography } from "@mui/material";
import { type Influencer } from "@trainwell/features";
import { useParams } from "react-router-dom";

type Props = {
  influencer: Influencer;
};

export function InfluencerCell({ influencer }: Props) {
  const { influencerId: queryInfluencerId } = useParams();

  const selected = queryInfluencerId === influencer.id;

  return (
    <CardActionArea
      href={`/admin/influencers/${influencer.id}`}
      sx={{
        p: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: 1,
        borderColor: "divider",
        backgroundColor: (theme) =>
          selected
            ? alpha(theme.palette.primary.main, 0.1)
            : theme.palette.background.paper,
      }}
    >
      <Stack alignItems={"center"} spacing={1} direction={"row"}>
        {influencer.status === "active" ? (
          <PlayArrowRoundedIcon fontSize="small" color="success" />
        ) : (
          <PauseRoundedIcon fontSize="small" />
        )}
        <Box>
          <Typography>{influencer.display_name}</Typography>
          <Typography variant="overline" sx={{ lineHeight: 1 }}>
            {influencer.type}
          </Typography>
        </Box>
      </Stack>
    </CardActionArea>
  );
}
