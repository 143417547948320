import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import type { Ticket } from "@trainwell/types";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RestrictAccess from "src/components/misc/RestrictAccess";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import { api } from "src/lib/trainwellApi";
import { openTicketChat } from "src/slices/chatSlice";
import { fetchTicketCategories } from "src/slices/ticketsSlice";
import TicketQuickDetails from "./TicketQuickDetails";

export default function TicketPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ticketId } = useParams();
  const [ticket, setTicket] = useState<Ticket>();
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);
  const ticketCategories = useAppSelector((state) => state.tickets.categories);
  const ticketCategoriesStatus = useAppSelector(
    (state) => state.tickets.categoriesStatus,
  );

  useEffect(() => {
    if (ticketCategoriesStatus === "idle") {
      dispatch(fetchTicketCategories());
    }
  }, [ticketCategoriesStatus, dispatch]);

  useEffect(() => {
    if (ticketId) {
      api.tickets.getOne(ticketId).then((fetchedTicket) => {
        setTicket(fetchedTicket);
      });
    }
  }, [ticketId]);

  if (!ticket || !ticketId || !ticketCategories) {
    return <LoadingPage />;
  }

  return (
    <RestrictAccess location={"tickets"} showNoAccessMessage>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 3,
            backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              size="small"
              startIcon={<ArrowBackRoundedIcon />}
              variant="contained"
              onClick={() => {
                navigate(`/admin/tickets/${ticket.type}`);
              }}
              sx={{ mr: 2 }}
            >
              All
            </Button>
            <IconButton
              onClick={() => {
                dispatch(openTicketChat(ticket.id));
              }}
              sx={{ mr: 2 }}
            >
              <CommentRoundedIcon />
            </IconButton>
            <Typography variant="h1">
              Ticket by {getTrainerName(ticket.trainer_id, trainerNames)}
            </Typography>
          </Box>
          <Stack direction={"row"} spacing={1}>
            {ticket.user_id && (
              <Button
                variant="contained"
                size="small"
                href={`/clients/${ticket.user_id}`}
              >
                Client dash
              </Button>
            )}
            {ticket.log_id && (
              <Button
                variant="contained"
                size="small"
                href={`/clients/${ticket.user_id}/logs/${ticket.log_id}`}
              >
                Log
              </Button>
            )}
          </Stack>
        </Box>
        <Box
          sx={{
            p: 3,
            flex: 1,
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          <TicketQuickDetails ticketId={ticketId} />
          <Typography sx={{ mt: 2 }}>
            <b>Submitted:</b>{" "}
            {format(new Date(ticket.date_created), "MMMM do, yyyy")}
          </Typography>
          {ticket.message_client && (
            <Typography sx={{ mb: 2 }}>
              <b>Client message:</b> {ticket.message_client}
            </Typography>
          )}
          {ticket.message_coach && (
            <Typography sx={{ mb: 2 }}>
              <b>Trainer message:</b> {ticket.message_coach}
            </Typography>
          )}
        </Box>
      </Box>
    </RestrictAccess>
  );
}
