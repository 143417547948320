import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import type {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import React from "react";

const icon = <CheckBoxOutlineBlankRoundedIcon fontSize="small" />;
const checkedIcon = <CheckBoxRoundedIcon fontSize="small" />;

type Props = {
  onChange:
    | ((
        event: React.SyntheticEvent<Element, Event>,
        value: readonly string[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<string> | undefined,
      ) => void)
    | undefined;
  value: string[];
  options: string[];
  label: string;
};

export default function ComparisonDropdown({
  onChange,
  value,
  options,
  label,
}: Props) {
  return (
    <Autocomplete
      size="small"
      options={options}
      fullWidth
      value={value}
      onChange={onChange}
      disableCloseOnSelect
      renderInput={(params) => <TextField {...params} label={label} />}
      multiple
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
    />
  );
}
