import { Box, CardActionArea, Stack, Typography } from "@mui/material";
import type {
  HabitSummaryMessage as HabitSummaryMessageType,
  HabitTask,
} from "@trainwell/types";
import { format } from "date-fns";
import { useState } from "react";
import HabitDot from "src/components/misc/HabitDot";
import HabitTaskPopover from "src/components/misc/HabitTaskPopover";
import { getLocalDate } from "src/lib/date";

type Props = {
  message: HabitSummaryMessageType;
};

export default function HabitsMissedMessage({ message }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [previewTask, setPreviewTask] = useState<HabitTask | null>(null);

  if (!message.summary) {
    return <Typography>Error loading habit summary</Typography>;
  }

  const tasks = message.summary.habit_tasks.filter(
    (task) => !task.date_completed,
  );

  if (tasks.length <= 0) {
    return <Typography>No tasks</Typography>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ my: 2, width: "300px" }}>
        <Typography textAlign={"center"}>
          Missed habits{" "}
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              display: "inline",
            }}
          >
            {format(getLocalDate(message.summary.date), "MMMM do, yyyy")}
          </Typography>
        </Typography>
        <Stack direction={"column"}>
          {tasks.map((task) => (
            <CardActionArea
              key={task.id}
              sx={{ borderRadius: 1, p: 0.5 }}
              onClick={(event) => {
                setPreviewTask(task as HabitTask);
                setAnchorEl(event.currentTarget);
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <HabitDot type={task.type} sx={{ mr: 0.5 }} />
                <Typography sx={{ ml: 1 }}>{task.name}</Typography>
                {task.is_new && (
                  <Typography
                    sx={{
                      backgroundColor: (theme) => theme.palette.text.primary,
                      color: (theme) => theme.palette.background.paper,
                      borderRadius: "5px",
                      p: 0.5,
                      lineHeight: 1,
                      ml: 1,
                      mr: 1,
                    }}
                  >
                    New
                  </Typography>
                )}
              </Box>
            </CardActionArea>
          ))}
        </Stack>
      </Box>
      {previewTask && (
        <HabitTaskPopover
          habitTask={previewTask}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
            setPreviewTask(null);
          }}
        />
      )}
    </Box>
  );
}
