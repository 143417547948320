import {
  DragOverlay,
  defaultDropAnimationSideEffects,
  type DropAnimation,
} from "@dnd-kit/core";
import { createPortal } from "react-dom";
import { useAppSelector } from "src/hooks/stateHooks";
import { snapCornerToCursor } from "../TemplateLibrary/dnd/snapCornerToCursor";
import { FolderDragOverlay } from "./FolderDragOverlay";
import { PhaseTemplateDragOverlay } from "./PhaseTemplateDragOverlay";

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

export default function LibraryDragOverlay() {
  const activeData = useAppSelector(
    (state) => state.phaseTemplates.currentDraggingInfo,
  );

  return (
    <>
      {createPortal(
        <DragOverlay
          dropAnimation={dropAnimation}
          zIndex={9999}
          modifiers={[snapCornerToCursor]}
        >
          {activeData?.originalType === "phase_template" ? (
            <PhaseTemplateDragOverlay
              phaseTemplateId={activeData.phaseTemplateId}
            />
          ) : activeData?.originalType === "workout_template" ||
            activeData?.originalType === "phase_template_workout_template" ? (
            <PhaseTemplateDragOverlay
              workoutTemplateId={activeData.workoutTemplateId}
            />
          ) : activeData?.originalType === "folder_draggable" ? (
            <FolderDragOverlay folderId={activeData.folderId!} />
          ) : null}
        </DragOverlay>,
        document.body,
      )}
    </>
  );
}
