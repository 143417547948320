import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { createContext, useContext, useState } from "react";

type DialogData = {
  title: string;
  message: string;
  buttonText?: string;
};

export interface DialogContextType {
  showDialog: (data: DialogData) => void;
  showErrorDialog: (data: DialogData) => void;
}

export const DialogContext = createContext<DialogContextType>({
  showDialog: () => undefined,
  showErrorDialog: () => undefined,
});

type Props = {
  children: React.ReactNode;
};

export default function DialogProvider({ children }: Props) {
  const [dialogData, setDialogData] = useState<{
    open: boolean;
    data?: DialogData;
  }>({ open: false });

  function showDialog(data: DialogData) {
    setDialogData({ open: true, data: data });
  }

  return (
    <>
      <DialogContext.Provider
        value={{
          showDialog: showDialog,
          showErrorDialog: (data) => {
            showDialog({
              title: data.title ?? "TrainwellError",
              message: data.message ?? "Something went wrong",
              buttonText: data.buttonText,
            });
          },
        }}
      >
        {children}
      </DialogContext.Provider>
      <Dialog
        open={dialogData.open}
        onClose={() => {
          setDialogData((d) => {
            return { ...d, open: false };
          });
        }}
        sx={{ zIndex: 9999 }}
      >
        <DialogTitle>{dialogData.data?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogData.data?.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setDialogData((d) => {
                return { ...d, open: false };
              });
            }}
          >
            {dialogData.data?.buttonText ?? "Ok"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const useDialog = (): DialogContextType => useContext(DialogContext);
