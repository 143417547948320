import AndroidRoundedIcon from "@mui/icons-material/AndroidRounded";
import AppleIcon from "@mui/icons-material/Apple";
import CakeRoundedIcon from "@mui/icons-material/CakeRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import DoNotDisturbRoundedIcon from "@mui/icons-material/DoNotDisturbRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import HeightRoundedIcon from "@mui/icons-material/HeightRounded";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import PhoneIphoneRoundedIcon from "@mui/icons-material/PhoneIphoneRounded";
import ScaleRoundedIcon from "@mui/icons-material/ScaleRounded";
import SentimentSatisfiedRoundedIcon from "@mui/icons-material/SentimentSatisfiedRounded";
import SportsScoreRoundedIcon from "@mui/icons-material/SportsScoreRounded";
import SquareFootRoundedIcon from "@mui/icons-material/SquareFootRounded";
import TimerRoundedIcon from "@mui/icons-material/TimerRounded";
import WatchRoundedIcon from "@mui/icons-material/WatchRounded";
import {
  ButtonBase,
  Grid,
  MenuItem,
  Popover,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import type { Client } from "@trainwell/types";
import { getConvertedHeight, getConvertedWeight } from "@trainwell/workout-lib";
import { differenceInYears, format, startOfDay, subYears } from "date-fns";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  getDateForDatabase,
  getDateFromDatabase,
  getZeroedOutUtcDate,
} from "src/lib/date";
import { updateClient } from "src/slices/clientSlice";
import AutosaveTextField from "../misc/AutosaveTextField";
import SmartEditDateField from "../misc/SmartEditDateField";

const workoutDurations: Record<string, string> = {
  "10m": "0:10",
  "15m": "0:15",
  "20m": "0:20",
  "30m": "0:30",
  "45m": "0:45",
  "60m": "1:00",
  "75m": "1:15",
  "90m": "1:30",
  "120m": "2:00",
};

const weightSystem: Record<string, string> = {
  imperial: "Imperial",
  metric: "Metric",
};

const watch: Record<string, string> = {
  "1": "Watch",
  "0": "Phone",
};

const workoutFrequency: Record<
  NonNullable<Client["pre_copilot_workout_frequency"]>,
  string
> = {
  "2_to_3_week": "2-3/w",
  "4_plus_week": "4+/w",
  few_month: "Few/mon",
  not_at_all: "None",
  once_week: "1/w",
};

type Props = {
  client: Client;
  size?: "small" | "medium";
};

export default function ClientIconGrid({ client, size }: Props) {
  const dispatch = useAppDispatch();
  const company = useAppSelector((state) => state.company.company);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [weightSystemAnchorEl, setWeightSystemAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const weightSystemOpen = Boolean(weightSystemAnchorEl);
  const [watchAnchorEl, setWatchAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const watchOpen = Boolean(watchAnchorEl);

  const [weightAnchorEl, setWeightAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const weightOpen = Boolean(weightAnchorEl);

  const [identityAnchorEl, setIdentityAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const identityOpen = Boolean(identityAnchorEl);

  const [heightAnchorEl, setHeightAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const heightOpen = Boolean(heightAnchorEl);

  const [ageMode, setAgeMode] = useState<"years" | "birthday">(
    client.is_birthday_real ? "birthday" : "years",
  );
  const [ageAnchorEl, setAgeAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const ageOpen = Boolean(ageAnchorEl);

  const theme = useTheme();

  const sanitizedGender = [
    "Man",
    "Woman",
    "Non-binary",
    "Prefer not to say",
    "Other",
  ].includes(client.gender ?? "--")
    ? client.gender
    : "Other";

  const fontSize =
    size === "small" ? theme.typography.body2.fontSize : undefined;
  const fontSizeIcon = size === "small" ? 14 : 16;

  return (
    <>
      <Grid
        container
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          alignItems: "stretch",
          justifyItems: "stretch",
          ".MuiGrid-item": {
            border: 0.5,
            borderColor: "divider",
            padding: size === "small" ? 0.25 : 0.5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          },
          ".MuiGrid-item .MuiTypography-root": {
            mt: 0.5,
          },
        }}
      >
        <Tooltip
          title={`Age${client.is_birthday_real && client.birthday ? ` (birthday: ${format(getDateFromDatabase(client.birthday), "MMMM do, yyyy")})` : " (might be a little wrong)"}`}
          disableInteractive
        >
          <ButtonBase
            onClick={(event) => {
              setAgeAnchorEl(event.currentTarget);
            }}
            sx={{ justifyContent: "stretch" }}
          >
            <Grid item sm>
              <CakeRoundedIcon
                color="primary"
                sx={{ fontSize: fontSizeIcon }}
              />
              <Typography sx={{ fontSize: fontSize }}>
                {client.is_birthday_real && client.birthday ? "" : "~"}
                {client.birthday
                  ? `${differenceInYears(new Date(), client.birthday)} y`
                  : client.age
                    ? `${client.age?.toString()} y`
                    : "--"}
              </Typography>
            </Grid>
          </ButtonBase>
        </Tooltip>
        <Tooltip title="Height" disableInteractive>
          <ButtonBase
            onClick={(event) => {
              setHeightAnchorEl(event.currentTarget);
            }}
            sx={{ justifyContent: "stretch" }}
          >
            <Grid item sm>
              <HeightRoundedIcon
                color="primary"
                sx={{ fontSize: fontSizeIcon }}
              />
              <Typography sx={{ fontSize: fontSize }}>
                {client.height === undefined
                  ? "--"
                  : client.preferred_weight_system === "metric"
                    ? `${getConvertedHeight({
                        height: client.height ?? 0,
                        fromSystem: "imperial",
                        toSystem: client.preferred_weight_system,
                        round: true,
                      })}cm`
                    : client.height
                      ? Math.floor(client.height / 12) +
                        "' " +
                        Math.round(client.height % 12) +
                        '"'
                      : "--"}
              </Typography>
            </Grid>
          </ButtonBase>
        </Tooltip>
        <Tooltip
          title={
            client.date_last_manual_bodyweight_log
              ? "You can't edit weight after the client manually submits a weight log"
              : "Weight"
          }
          disableInteractive
        >
          <ButtonBase
            onClick={(event) => {
              if (client.date_last_manual_bodyweight_log) {
                return;
              }

              setWeightAnchorEl(event.currentTarget);
            }}
            sx={{ justifyContent: "stretch" }}
          >
            <Grid item sm>
              <ScaleRoundedIcon
                color={
                  client.date_last_manual_bodyweight_log ? undefined : "primary"
                }
                sx={{ fontSize: fontSizeIcon }}
              />
              <Typography sx={{ fontSize: fontSize }}>
                {Math.round(
                  getConvertedWeight({
                    weight: client.weight,
                    fromSystem: "imperial",
                    toSystem: client.preferred_weight_system,
                    round: true,
                  }),
                ) +
                  " " +
                  (client.preferred_weight_system === "imperial"
                    ? "lbs"
                    : "kg")}
              </Typography>
            </Grid>
          </ButtonBase>
        </Tooltip>
        <Tooltip title="Gender identity" disableInteractive>
          <ButtonBase
            onClick={(event) => {
              setIdentityAnchorEl(event.currentTarget);
            }}
            sx={{ justifyContent: "stretch" }}
          >
            <Grid item sm sx={{ width: "100%" }}>
              <SentimentSatisfiedRoundedIcon
                color="primary"
                sx={{ fontSize: fontSizeIcon }}
              />
              <Typography sx={{ fontSize: fontSize }}>
                {client.gender}
              </Typography>
            </Grid>
          </ButtonBase>
        </Tooltip>
        <Tooltip
          title={`Current: ${client.app_version || "unknown"}. Latest: ${
            client.device_platform === "android"
              ? company?.latest_version_android
              : company?.latest_version_ios
          }`}
          disableInteractive
        >
          <Grid item sm>
            {client.device_platform === "android" ? (
              <AndroidRoundedIcon sx={{ fontSize: fontSizeIcon }} />
            ) : (
              <AppleIcon sx={{ fontSize: fontSizeIcon }} />
            )}
            <Typography sx={{ fontSize: fontSize }}>
              {client.device_platform === "android" ? "Android" : "iOS"}
            </Typography>
          </Grid>
        </Tooltip>
        <Tooltip title="Smart watch" disableInteractive>
          <ButtonBase
            onClick={(event) => {
              setWatchAnchorEl(event.currentTarget);
            }}
            sx={{ justifyContent: "stretch" }}
          >
            <Grid item sm sx={{ width: "100%" }}>
              {client.owns_smart_watch ? (
                <WatchRoundedIcon
                  color="primary"
                  sx={{ fontSize: fontSizeIcon }}
                />
              ) : (
                <PhoneIphoneRoundedIcon
                  color="primary"
                  sx={{ fontSize: fontSizeIcon }}
                />
              )}
              <Typography sx={{ fontSize: fontSize }}>
                {watch[Number(client.owns_smart_watch ?? false).toString()]}
              </Typography>
            </Grid>
          </ButtonBase>
        </Tooltip>
        <Tooltip title="Measuring system" disableInteractive>
          <ButtonBase
            onClick={(event) => {
              setWeightSystemAnchorEl(event.currentTarget);
            }}
            sx={{ justifyContent: "stretch" }}
          >
            <Grid item sm sx={{ width: "100%" }}>
              <SquareFootRoundedIcon
                color="primary"
                sx={{ fontSize: fontSizeIcon }}
              />
              <Typography sx={{ fontSize: fontSize }}>
                {weightSystem[client.preferred_weight_system]}
              </Typography>
            </Grid>
          </ButtonBase>
        </Tooltip>
        <Tooltip title="Habit days completed" disableInteractive>
          <Grid item sm>
            <CalendarMonthRoundedIcon sx={{ fontSize: fontSizeIcon }} />
            <Typography sx={{ fontSize: fontSize }}>--</Typography>
          </Grid>
        </Tooltip>
        <Tooltip title="Preferred workout duration" disableInteractive>
          <ButtonBase
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
            sx={{ justifyContent: "stretch" }}
          >
            <Grid item sm sx={{ width: "100%" }}>
              <TimerRoundedIcon
                color="primary"
                sx={{ fontSize: fontSizeIcon }}
              />
              <Typography sx={{ fontSize: fontSize }}>
                {client.workout_duration
                  ? `${workoutDurations[client.workout_duration]} hrs`
                  : "--"}
              </Typography>
            </Grid>
          </ButtonBase>
        </Tooltip>
        <Tooltip title="Last login" disableInteractive>
          <Grid item sm>
            <LoginRoundedIcon sx={{ fontSize: fontSizeIcon }} />
            <Typography sx={{ fontSize: fontSize }}>
              {client.last_login_date
                ? format(new Date(client.last_login_date), "MMM d")
                : "❌ Never"}
            </Typography>
          </Grid>
        </Tooltip>
        <Tooltip title="Workout completion (week)" disableInteractive>
          <Grid item sm>
            <SportsScoreRoundedIcon sx={{ fontSize: fontSizeIcon }} />
            <Typography sx={{ fontSize: fontSize }}>
              {client.metrics?.workout_completion_week === undefined ||
              client.metrics?.workout_completion_week === null
                ? "--"
                : `${Math.round(
                    client.metrics?.workout_completion_week * 100,
                  )}%`}
            </Typography>
          </Grid>
        </Tooltip>
        <Tooltip
          title="Missed streak. Streak of missed days that had something assigned. Doing a CoPilot workout resets this to 0."
          disableInteractive
        >
          <Grid item sm>
            <DoNotDisturbRoundedIcon
              sx={{ fontSize: fontSizeIcon }}
              color={
                (client.missed_workout_day_streak ?? 0) > 0 &&
                (client.missed_workout_day_streak ?? 0) < 4
                  ? "error"
                  : undefined
              }
            />
            <Typography
              sx={{
                fontSize: fontSize,
                fontWeight:
                  (client.missed_workout_day_streak ?? 0) > 0 &&
                  (client.missed_workout_day_streak ?? 0) < 4
                    ? "bold"
                    : undefined,
                color: (theme) =>
                  (client.missed_workout_day_streak ?? 0) > 0 &&
                  (client.missed_workout_day_streak ?? 0) < 4
                    ? theme.palette.error.main
                    : undefined,
              }}
            >
              {!client.missed_workout_day_streak
                ? "0"
                : `${client.missed_workout_day_streak} day${
                    client.missed_workout_day_streak > 1 ? "s" : ""
                  }`}
            </Typography>
          </Grid>
        </Tooltip>
        <Tooltip
          title="Workout frequency before joining trainwell"
          disableInteractive
        >
          <Grid item sm>
            <FitnessCenterRoundedIcon sx={{ fontSize: fontSizeIcon }} />
            <Typography
              sx={{
                fontSize: fontSize,
              }}
            >
              {!client.pre_copilot_workout_frequency
                ? "--"
                : workoutFrequency[client.pre_copilot_workout_frequency]}
            </Typography>
          </Grid>
        </Tooltip>
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 1,
            },
          },
        }}
      >
        <Select
          size="small"
          value={client.workout_duration}
          onChange={(event) => {
            dispatch(
              updateClient({
                user_id: client.user_id,
                workout_duration: event.target.value as string,
              }),
            );

            setAnchorEl(null);
          }}
        >
          {[
            { value: "10m", label: "0:10" },
            { value: "15m", label: "0:15" },
            { value: "20m", label: "0:20" },
            { value: "30m", label: "0:30" },
            { value: "45m", label: "0:45" },
            { value: "60m", label: "1:00" },
            { value: "75m", label: "1:15" },
            { value: "90m", label: "1:30" },
            { value: "120m", label: "2:00" },
          ].map((possibleValue) => (
            <MenuItem key={possibleValue.value} value={possibleValue.value}>
              {possibleValue.label}
            </MenuItem>
          ))}
        </Select>
      </Popover>
      <Popover
        open={weightSystemOpen}
        anchorEl={weightSystemAnchorEl}
        onClose={() => {
          setWeightSystemAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 1,
            },
          },
        }}
      >
        <Select
          size="small"
          value={client.preferred_weight_system}
          onChange={(event) => {
            dispatch(
              updateClient({
                user_id: client.user_id,
                preferred_weight_system: event.target.value as any,
              }),
            );

            setWeightSystemAnchorEl(null);
          }}
        >
          {[
            { value: "imperial", label: "Imperial" },
            { value: "metric", label: "Metric" },
          ].map((possibleValue) => (
            <MenuItem key={possibleValue.value} value={possibleValue.value}>
              {possibleValue.label}
            </MenuItem>
          ))}
        </Select>
      </Popover>
      <Popover
        open={identityOpen}
        anchorEl={identityAnchorEl}
        onClose={() => {
          setIdentityAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 1,
            },
          },
        }}
      >
        <Select
          size="small"
          value={sanitizedGender}
          onChange={(event) => {
            dispatch(
              updateClient({
                user_id: client.user_id,
                gender: event.target.value as any,
              }),
            );

            setIdentityAnchorEl(null);
          }}
        >
          {[
            { value: "Woman", label: "Woman" },
            { value: "Man", label: "Man" },
            { value: "Non-binary", label: "Non-binary" },
            { value: "Prefer not to say", label: "Prefer not to say" },
            { value: "Other", label: "Other" },
          ].map((possibleValue) => (
            <MenuItem key={possibleValue.value} value={possibleValue.value}>
              {possibleValue.label}
            </MenuItem>
          ))}
        </Select>
      </Popover>
      <Popover
        open={watchOpen}
        anchorEl={watchAnchorEl}
        onClose={() => {
          setWatchAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 1,
            },
          },
        }}
      >
        <Select
          size="small"
          value={Number(client.owns_smart_watch ?? false).toString()}
          onChange={(event) => {
            dispatch(
              updateClient({
                user_id: client.user_id,
                owns_smart_watch: Boolean(Number(event.target.value as string)),
              }),
            );

            setWatchAnchorEl(null);
          }}
        >
          {[
            { value: "1", label: "Owns watch" },
            { value: "0", label: "Phone only" },
          ].map((possibleValue) => (
            <MenuItem key={possibleValue.value} value={possibleValue.value}>
              {possibleValue.label}
            </MenuItem>
          ))}
        </Select>
      </Popover>
      <Popover
        open={heightOpen}
        anchorEl={heightAnchorEl}
        onClose={() => {
          setHeightAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 1,
            },
          },
        }}
      >
        <AutosaveTextField
          label="Height"
          defaultValue={getConvertedHeight({
            height: client.height ?? 0,
            fromSystem: "imperial",
            toSystem: client.preferred_weight_system,
            round: true,
          }).toString()}
          disableGutter
          onlySaveOnBlur
          blurOnEnter
          helperText={
            client.preferred_weight_system === "metric" ? "cm" : "inches"
          }
          onSave={(value) => {
            const convertedHeight = getConvertedHeight({
              height: Number(value),
              fromSystem: client.preferred_weight_system,
              toSystem: "imperial",
            });

            dispatch(
              updateClient({
                user_id: client.user_id,
                height: convertedHeight,
              }),
            );

            setHeightAnchorEl(null);
          }}
        />
      </Popover>
      <Popover
        open={weightOpen}
        anchorEl={weightAnchorEl}
        onClose={() => {
          setWeightAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 1,
            },
          },
        }}
      >
        <AutosaveTextField
          label="Weight"
          defaultValue={getConvertedWeight({
            weight: client.starting_weight ?? 0,
            fromSystem: "imperial",
            toSystem: client.preferred_weight_system,
            round: true,
          }).toString()}
          disableGutter
          onlySaveOnBlur
          blurOnEnter
          helperText={
            client.preferred_weight_system === "metric" ? "kg" : "lbs"
          }
          onSave={(value) => {
            const convertedWeight = getConvertedWeight({
              weight: Number(value),
              fromSystem: client.preferred_weight_system,
              toSystem: "imperial",
            });

            dispatch(
              updateClient({
                user_id: client.user_id,
                starting_weight: convertedWeight,
              }),
            );

            setWeightAnchorEl(null);
          }}
        />
      </Popover>
      <Popover
        open={ageOpen}
        anchorEl={ageAnchorEl}
        onClose={() => {
          setAgeAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 1,
            },
          },
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={ageMode}
          exclusive
          sx={{ mb: 2 }}
          size="small"
          onChange={(event, newValue) => {
            if (newValue === null) {
              return;
            }

            setAgeMode(newValue);
          }}
        >
          <ToggleButton value="years" sx={{ py: 0.25 }}>
            Years old
          </ToggleButton>
          <ToggleButton value="birthday" sx={{ py: 0.25 }}>
            Birthday (preferred)
          </ToggleButton>
        </ToggleButtonGroup>
        {ageMode === "years" ? (
          <AutosaveTextField
            label="Age"
            defaultValue={client.age?.toString() ?? "0"}
            disableGutter
            onlySaveOnBlur
            blurOnEnter
            helperText={"years"}
            onSave={(value) => {
              dispatch(
                updateClient({
                  user_id: client.user_id,
                  age: Number(value),
                  birthday: getZeroedOutUtcDate(
                    getDateForDatabase(
                      startOfDay(
                        subYears(new Date(), Number(value)),
                      ).toISOString(),
                    ),
                  ),
                  is_birthday_real: false,
                }),
              );

              setAgeAnchorEl(null);
            }}
          />
        ) : (
          <SmartEditDateField
            label="Birthday"
            value={
              client.birthday
                ? getDateFromDatabase(client.birthday as string)
                : new Date()
            }
            onSave={(value) => {
              dispatch(
                updateClient({
                  user_id: client.user_id,
                  birthday: getZeroedOutUtcDate(
                    getDateForDatabase(startOfDay(value!).toISOString()),
                  ),
                  age: differenceInYears(new Date(), value!),
                  is_birthday_real: true,
                }),
              );
            }}
          />
        )}
      </Popover>
    </>
  );
}
