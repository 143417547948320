import { Box, Grid, Popover, Typography } from "@mui/material";
import { format, fromUnixTime, getUnixTime } from "date-fns";
import React, { useState } from "react";

type Props = {
  text: string;
  date: Date;
  coachTimeZoneOffset: number;
};

export default function TimezoneTypography({
  text,
  date,
  coachTimeZoneOffset,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const myTzOffset = new Date().getTimezoneOffset() * -60;
  const theirTzOffset = coachTimeZoneOffset ?? 0;
  const difference = myTzOffset - theirTzOffset;

  const adjustedDate = fromUnixTime(getUnixTime(new Date(date)) - difference);

  return (
    <>
      <Typography
        sx={{ display: "inline" }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {text}
      </Typography>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "left",
        // }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 1, width: "350px" }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "bold" }}>Your timezone</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">
                {format(date, "MMM d, yyyy h:mm a")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "bold" }}>
                Trainer&apos;s timezone
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">
                {format(adjustedDate, "MMM d, yyyy h:mm a")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
}
