import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import SickRoundedIcon from "@mui/icons-material/SickRounded";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import {
  Avatar,
  Box,
  Button,
  Card,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import type { CoachVacation } from "@trainwell/types";
import { differenceInHours, format } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import TimezoneTypography from "src/components/misc/TimezoneTypography";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { deleteVacation, editVacation } from "src/slices/vacationsSlice";
import VacationEditDialog from "./VacationEditDialog";

type Props = {
  vacation: CoachVacation;
};

export default function CoachVacationCell({ vacation }: Props) {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isVacationDialogOpen, setIsVacationDialogOpen] = useState(false);
  const trainerEdit = useAppSelector((state) => state.trainerEdit.trainer);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();

  let icon = <MoreHorizRoundedIcon />;

  if (vacation.type === "pto") {
    icon = <AttachMoneyRoundedIcon />;
  } else if (vacation.type === "sick") {
    icon = <SickRoundedIcon />;
  } else if (vacation.type === "wto") {
    icon = <WorkRoundedIcon />;
  }

  const requiresMessageAndDate =
    vacation.type === "sick" ||
    (vacation.type === "pto" &&
      differenceInHours(
        new Date(vacation.date_end),
        new Date(vacation.date_start),
      ) > 48);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          backgroundColor: (theme) =>
            requiresMessageAndDate && !vacation.message_to_clients
              ? theme.palette.errorSurface.main
              : undefined,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Avatar
            sx={{
              bgcolor: (theme) => theme.palette.backgroundTertiary.main,
              mr: 2,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {icon}
          </Avatar>
          <Box>
            <Typography variant="overline">
              {vacation.type === "pto"
                ? "Paid time off"
                : vacation.type === "sick"
                  ? "Sick time"
                  : vacation.type === "wto"
                    ? "Work time off"
                    : "Type: other"}
            </Typography>
            <Typography>
              <b>Start:</b>{" "}
              <TimezoneTypography
                text={format(
                  new Date(vacation.date_start),
                  "MMMM do, yyyy h:mm aaa",
                )}
                date={new Date(vacation.date_start)}
                coachTimeZoneOffset={trainerEdit?.default_timezone_offset ?? 0}
              />
            </Typography>
            <Typography>
              <b>End:</b>{" "}
              <TimezoneTypography
                text={format(
                  new Date(vacation.date_end),
                  "MMMM do, yyyy h:mm aaa",
                )}
                date={new Date(vacation.date_end)}
                coachTimeZoneOffset={trainerEdit?.default_timezone_offset ?? 0}
              />
            </Typography>
            {(vacation.type === "pto" || vacation.type === "sick") && (
              <>
                {vacation.date_message_sent_to_clients ? (
                  <Typography>
                    <b>Message sent on:</b>{" "}
                    {format(
                      new Date(vacation.date_message_sent_to_clients),
                      "MMMM do, yyyy h:mm aaa",
                    )}
                  </Typography>
                ) : (
                  <Typography>
                    <b>Message will be sent on:</b>{" "}
                    {vacation.date_to_send_message_to_clients ? (
                      <TimezoneTypography
                        text={format(
                          new Date(vacation.date_to_send_message_to_clients),
                          "MMMM do, yyyy h:mm aaa",
                        )}
                        date={
                          new Date(vacation.date_to_send_message_to_clients)
                        }
                        coachTimeZoneOffset={
                          trainerEdit?.default_timezone_offset ?? 0
                        }
                      />
                    ) : (
                      "not found"
                    )}
                  </Typography>
                )}
                {vacation.message_to_clients && (
                  <>
                    <Typography variant="overline">Message</Typography>
                    <Typography
                      sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.primary.contrastText,
                        borderRadius: `10px 10px 2px 10px`,
                        px: 1,
                        py: 0.5,
                        whiteSpace: "pre-line",
                        wordWrap: "break-word",
                      }}
                    >
                      {vacation.message_to_clients}
                    </Typography>
                  </>
                )}
              </>
            )}
            {vacation.requires_interim_trainers &&
            !vacation.date_interim_trainers_assigned ? (
              <Button
                onClick={() => {
                  api.vacations
                    .startInterimPeriod({
                      vacationId: vacation.id,
                    })
                    .then(() => {
                      enqueueSnackbar("Interim period started", {
                        variant: "success",
                      });
                    })
                    .catch(() => {
                      enqueueSnackbar("Failed to start interim period", {
                        variant: "error",
                      });
                    });
                }}
                sx={{ mt: 2 }}
                color="error"
                size="small"
              >
                Start interim period
              </Button>
            ) : vacation.requires_interim_trainers &&
              vacation.date_interim_trainers_assigned ? (
              <Button
                onClick={() => {
                  api.vacations
                    .endInterimPeriod({ vacationId: vacation.id })
                    .then(() => {
                      enqueueSnackbar("Interim period ended", {
                        variant: "success",
                      });
                    })
                    .catch(() => {
                      enqueueSnackbar("Failed to end interim period", {
                        variant: "error",
                      });
                    });
                }}
                sx={{ mt: 2 }}
                color="error"
                size="small"
              >
                End interim period
              </Button>
            ) : null}
          </Box>
        </Box>
        {!(
          (vacation.type === "pto" || vacation.type === "sick") &&
          vacation.date_message_sent_to_clients
        ) && (
          <IconButton
            aria-label="more"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreHorizRoundedIcon />
          </IconButton>
        )}
      </Card>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setIsVacationDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <EditRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            dispatch(deleteVacation(vacation.id));
          }}
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          <ListItemIcon color="error">
            <DeleteRoundedIcon
              fontSize="small"
              sx={{ color: (theme) => theme.palette.error.main }}
            />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
      <VacationEditDialog
        open={isVacationDialogOpen}
        defaultVacation={vacation}
        coachDefaultTimezoneOffset={trainerEdit?.default_timezone_offset ?? 0}
        saveText="Save"
        titleText="Edit vacation time"
        onClose={() => {
          setIsVacationDialogOpen(false);
        }}
        onSave={({
          startTime,
          endTime,
          type,
          clientMessage,
          localDateToSend,
        }) => {
          setIsVacationDialogOpen(false);

          dispatch(
            editVacation({
              vacationId: vacation.id,
              startDate: startTime,
              endDate: endTime,
              type: type,
              clientMessage: clientMessage,
              localDateToSend: localDateToSend,
            }),
          );
        }}
      />
    </>
  );
}
