import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import {
  Box,
  IconButton,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
  type TooltipProps,
} from "@mui/material";
import { useMealLogReport } from "@trainwell/features";
import { foodItemCategoryNames, type Message } from "@trainwell/types";
import { format } from "date-fns";
import { useState } from "react";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[8],
    fontSize: 16,
    maxWidth: 800,
    p: 0,
  },
}));

type Props = {
  message: Message;
  combineBottom: boolean;
};

export function MealLogReportMessage({ message, combineBottom }: Props) {
  const { data } = useMealLogReport({
    mealLogReportId: message.meal_log_report_id ?? "",
  });
  const [detailsOpen, setDetailsOpen] = useState(false);

  const mealLogReport = data?.meal_log_report;

  if (!message.meal_log_report_id || !mealLogReport) {
    return <Typography>Meal report not found</Typography>;
  }

  // turn the categories object into an array of objects
  const categories = Object.entries(mealLogReport.categories).map(
    ([key, value]) => ({
      key,
      value,
    }),
  );

  return (
    <>
      <Box
        sx={{
          maxWidth: "300px",
          borderRadius: `10px 10px ${!combineBottom ? "0px" : "10px"} 10px`,
          overflow: "hidden",
          backgroundColor: (theme) => theme.palette.background.default,
          p: 1,
          border: 1,
          borderColor: "divider",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <RestaurantRoundedIcon />
          <Box sx={{ ml: 2 }}>
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            >
              Meal log report
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {format(
                new Date(message.send_date as string),
                "MMM do, yyyy h:mm a",
              )}
            </Typography>
          </Box>
          <IconButton
            onClick={() => {
              setDetailsOpen(!detailsOpen);
            }}
            sx={{ ml: 1 }}
          >
            {detailsOpen ? (
              <ExpandLessRoundedIcon />
            ) : (
              <ExpandMoreRoundedIcon />
            )}
          </IconButton>
        </Box>
        {detailsOpen && (
          <Stack sx={{ mt: 1 }} spacing={4}>
            {categories.map((category) => (
              <Box key={category.key}>
                <Typography variant="h2" sx={{ mb: 2 }}>
                  {foodItemCategoryNames[category.key]} ({category.value.length}
                  )
                </Typography>
                <Stack spacing={1} sx={{ ml: 2 }}>
                  {category.value.map((foodItem) => (
                    <Box
                      key={foodItem.food_item._id}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <LightTooltip
                        placement={"right"}
                        disableInteractive
                        title={
                          <Box sx={{ p: 1, maxWidth: 600 }}>
                            <img
                              src={foodItem.image_url}
                              width={500}
                              height={500}
                              style={{
                                borderRadius: "12px",
                                objectFit: "contain",
                              }}
                            />
                          </Box>
                        }
                      >
                        <img
                          src={foodItem.image_url}
                          width={45}
                          height={45}
                          style={{
                            borderRadius: "2px",
                            objectFit: "contain",
                          }}
                        />
                      </LightTooltip>
                      <Box>
                        <Typography>{foodItem.food_item.name}</Typography>
                        <Typography variant="overline">
                          {Math.round(foodItem.food_item.n_servings * 100) /
                            100}{" "}
                          servings
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </Box>
            ))}
          </Stack>
        )}
      </Box>
    </>
  );
}
