import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { LineChart } from "@mui/x-charts";
import type { Client } from "@trainwell/types";
import { format } from "date-fns";
import { useState } from "react";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppSelector } from "src/hooks/stateHooks";
import {
  useDeleteProgressMetricLogMutation,
  useGetProgressMetricLogsQuery,
} from "src/slices/api/progressMetricLogsApi";

type Props = {
  progressMetricId: string;
  userId: string;
  preferredWeightSystem: Client["preferred_weight_system"];
};

export default function GoalCardMetric({
  progressMetricId,
  userId,
  preferredWeightSystem,
}: Props) {
  const theme = useTheme();
  const progressMetrics = useAppSelector(
    (state) => state.progressMetrics.progressMetrics,
  );
  const { data: progressMetricLogs, isLoading } = useGetProgressMetricLogsQuery(
    {
      userId,
      progressMetricId: progressMetricId,
    },
  );
  const [deleteProgressMetricLog] = useDeleteProgressMetricLogMutation();

  const progressMetric = progressMetrics.find((p) => p.id === progressMetricId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (!progressMetric) {
    return <LoadingComponent message={`Progressing progress`} />;
  }

  if (isLoading || !progressMetricLogs) {
    return (
      <LoadingComponent message={`Getting logs for ${progressMetric.name}`} />
    );
  }

  function round(number: number) {
    return Math.round(number * 10) / 10;
  }

  const units =
    preferredWeightSystem === "imperial"
      ? progressMetric.unit_imperial
      : progressMetric.unit_metric;

  const dataPoints = progressMetricLogs
    .map((log) => ({
      x: new Date(log.date).getTime(),
      y: round(
        (log.measurement ?? 0) *
          (preferredWeightSystem === "imperial"
            ? 1
            : (progressMetric.ratio_metric ?? 1)),
      ),
    }))
    .sort((a, b) => a.x - b.x);

  return (
    <Box key={progressMetricId}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          {progressMetric.name}
        </Typography>
        <IconButton
          size="small"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <FormatListBulletedRoundedIcon fontSize="small" />
        </IconButton>
      </Box>
      {dataPoints.length > 0 ? (
        <LineChart
          colors={[theme.palette.primary.main]}
          xAxis={[
            {
              dataKey: "x",
              valueFormatter: (value) => format(value as number, "MMM d, yyy"),
              min: Math.min(...dataPoints.map((d) => d.x)),
              // max: Math.max(...dataPoints.map((d) => d.x)),
            },
          ]}
          yAxis={[
            {
              min: Math.min(...dataPoints.map((d) => d.y)),
            },
          ]}
          series={[
            {
              dataKey: "y",
              label: units ?? "",
              showMark: false,
            },
          ]}
          dataset={dataPoints}
          height={200}
          margin={{ top: 10, right: 0, left: 40 }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
          slots={{
            axisContent: ({ dataIndex }) => {
              const point = dataPoints[dataIndex as number];

              return (
                <Card variant="outlined" sx={{ py: 1, ml: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", px: 2 }}>
                    <Box
                      sx={{
                        width: theme.spacing(1),
                        height: theme.spacing(1),
                        borderRadius: "50%",
                        backgroundColor: theme.palette.primary.main,
                        borderColor: theme.palette.background.paper,
                        border: `solid ${theme.palette.background.paper} ${theme.spacing(0.25)}`,
                        boxSizing: "content-box",
                      }}
                    />
                    <Box sx={{ ml: 1 }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {point.y} {units}
                      </Typography>
                      <Typography variant="overline">
                        {format(point.x as number, "MMM d, yyy")}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              );
            },
          }}
          skipAnimation
        />
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          No data
        </Typography>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              width: 250,
              maxHeight: 600,
            },
          },
        }}
      >
        <Typography variant="h2" sx={{ mb: 2 }}>
          {progressMetric.name} logs
        </Typography>
        <Stack spacing={1} divider={<Divider />}>
          {progressMetricLogs.map((log) => (
            <Box
              key={log.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Typography>
                {format(new Date(log.date), "MMM d, yyyy")}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {round(
                    (log.measurement ?? 0) *
                      (preferredWeightSystem === "imperial"
                        ? 1
                        : (progressMetric.ratio_metric ?? 1)),
                  )}{" "}
                  {units}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    deleteProgressMetricLog({
                      progressMetricLogId: log.id,
                    });
                  }}
                  sx={{ ml: 1 }}
                  color="error"
                >
                  <DeleteRoundedIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Stack>
      </Popover>
    </Box>
  );
}
