import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import SupportRoundedIcon from "@mui/icons-material/SupportRounded";
import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
import VolumeDownRoundedIcon from "@mui/icons-material/VolumeDownRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
} from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { resetActionItems } from "src/slices/actionItemSlice";
import { resetAllClients } from "src/slices/allClientsSlice";
import { resetApp, setDashMode } from "src/slices/appSlice";
import { resetClientEdit } from "src/slices/clientEditSlice";
import { resetClient } from "src/slices/clientSlice";
import { resetClients } from "src/slices/clientsSlice";
import { resetEmployees } from "src/slices/employeesSlice";
import { resetPhaseTemplates } from "src/slices/phaseTemplatesSlice";
import { resetTemplates } from "src/slices/templatesSlice";
import { resetTests } from "src/slices/testsSlice";
import { resetTrainerEdit } from "src/slices/trainerEditSlice";
import {
  exitAuditMode,
  resetCoach,
  selectIsAuditing,
  selectPrimaryTrainer,
  setDisableGhostingProtections,
} from "src/slices/trainerSlice";
import { resetCoaches } from "src/slices/trainersSlice";
import { resetWorkout } from "src/slices/workoutSlice";
import type { ThemeMode } from "src/theme/themeModes";
import CoachNotificationBell from "./CoachNotificationBell";
import CreateActionItemDialog from "./CreateActionItemDialog";
import SubmitTicketDialog from "./SubmitTicketDialog";

type Props = {
  leftContent?: React.ReactNode;
};

export default function ControlBar({ leftContent }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const realCoachIsAdmin = useAppSelector(
    (state) => state.trainer.trainer?.is_admin,
  );
  const disableGhostingProtections = useAppSelector(
    (state) => state.trainer.disableGhostingProtections,
  );
  const dashThemeMode = useAppSelector(
    (state) => state.trainer.trainer?.settings?.theme_dash ?? "light",
  ) as ThemeMode;
  const isAuditing = useAppSelector(selectIsAuditing);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [createAnchorEl, setCreateAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const createOpen = Boolean(createAnchorEl);
  const [actionItemOpen, setActionItemOpen] = useState(false);
  const [submitTicketDialogOpen, setSubmitTicketDialogOpen] = useState(false);
  const dashMode = useAppSelector((state) => state.app.dashMode);
  const [notificationsAnchorEl, setNotificationsAnchorEl] =
    useState<HTMLElement | null>(null);
  const notificationsOpen = Boolean(notificationsAnchorEl);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleLogout() {
    navigate("/login");

    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("Sign out: success");
      })
      .catch((error) => {
        console.log("Sign out: fail");
      });

    localStorage.clear();
    sessionStorage.clear();

    dispatch(resetTemplates());
    dispatch(resetPhaseTemplates());
    dispatch(resetActionItems());
    dispatch(resetClient());
    dispatch(resetClients());
    dispatch(resetCoach());
    dispatch(resetApp());
    dispatch(resetWorkout());
    dispatch(resetAllClients());
    dispatch(resetClientEdit());
    dispatch(resetTests());
    dispatch(resetEmployees());
    dispatch(resetCoaches());
    dispatch(resetTrainerEdit());
  }

  const useLightLogo =
    dashThemeMode === "light" || dashThemeMode === "light_soft";

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: 1250,
        backgroundColor: (theme) => theme.palette.background.paper,
        boxShadow: 0,
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          minHeight: "38px",
        }}
      >
        <Grid
          container
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs sx={{ display: "flex", alignItems: "center" }}>
            {leftContent}
          </Grid>
          <Grid
            item
            xs="auto"
            sx={{ justifyContent: "center", display: "flex" }}
          >
            <ButtonBase href="/">
              <img
                src={
                  useLightLogo
                    ? "/assets/trainwell-wordmark.svg"
                    : "/assets/trainwell-wordmark-white.svg"
                }
                alt="Logo"
                height={30}
                width={100}
              />
            </ButtonBase>
          </Grid>
          <Grid
            item
            xs
            sx={{
              justifyContent: "flex-end",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              size="small"
              variant="contained"
              startIcon={<AccountCircleRoundedIcon />}
              onClick={handleMenu}
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color={isAuditing ? "secondary" : undefined}
            >
              {(isAuditing ? "👻 mode: " : "") + trainer?.full_name}
            </Button>
            <CoachNotificationBell />
            <IconButton
              onClick={(event) => {
                setCreateAnchorEl(event.currentTarget);
              }}
              size="small"
            >
              <AddRoundedIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={open}
              onClose={handleClose}
            >
              <Link
                href={"/profile"}
                sx={{
                  textDecoration: "none",
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <AccountCircleRoundedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Profile & settings" />
                </MenuItem>
              </Link>
              {trainer?.is_admin && (
                <Link
                  href={"/admin"}
                  sx={{
                    textDecoration: "none",
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  <MenuItem>
                    <ListItemIcon>
                      <AdminPanelSettingsRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Admin" />
                  </MenuItem>
                </Link>
              )}
              <Link
                href={"/template-library"}
                sx={{
                  textDecoration: "none",
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <ExtensionRoundedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Template library" />
                </MenuItem>
              </Link>
              <Link
                href={"/audits"}
                sx={{
                  textDecoration: "none",
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <VerifiedUserRoundedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Self audits" />
                </MenuItem>
              </Link>
              <Link
                href="https://help.trainwell.net"
                component="a"
                target="_blank"
                sx={{
                  textDecoration: "none",
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <MenuBookRoundedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Knowledge base" />
                </MenuItem>
              </Link>
              <Link
                href="https://docs.google.com/spreadsheets/d/1gP4zGRqfmHZz7BWmuDu0SRXZhh67pIj6LMl2VtPLsrM/edit?usp=sharing"
                component="a"
                target="_blank"
                sx={{
                  textDecoration: "none",
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <YouTubeIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Exercise videos" />
                </MenuItem>
              </Link>
              <MenuItem
                onClick={() => {
                  dispatch(
                    setDashMode(
                      dashMode === "default" ? "programming" : "default",
                    ),
                  );
                }}
              >
                <ListItemIcon>
                  <VolumeDownRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Program mode" />
                <Switch
                  size="small"
                  edge="end"
                  onChange={(event) => {
                    dispatch(
                      setDashMode(
                        event.target.checked ? "programming" : "default",
                      ),
                    );
                  }}
                  checked={dashMode === "programming"}
                />
              </MenuItem>
              {isAuditing && (
                <MenuItem
                  onClick={() => {
                    dispatch(
                      setDisableGhostingProtections(
                        !disableGhostingProtections,
                      ),
                    );
                  }}
                >
                  <ListItemIcon>
                    <LockRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Enable audit protections" />
                  <Switch
                    size="small"
                    edge="end"
                    onChange={(event) => {
                      dispatch(
                        setDisableGhostingProtections(!event.target.checked),
                      );
                    }}
                    checked={!disableGhostingProtections}
                  />
                </MenuItem>
              )}
              <Box>
                <Divider sx={{ mb: 1, mt: 2 }} />
              </Box>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleLogout();
                }}
              >
                <ListItemIcon>
                  <LogoutRoundedIcon fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText
                  primary="Logout"
                  sx={{ color: (theme) => theme.palette.error.main }}
                />
              </MenuItem>
              {isAuditing && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    dispatch(exitAuditMode());

                    if (realCoachIsAdmin) {
                      navigate("/admin/company/team");
                    }
                  }}
                >
                  <ListItemIcon>
                    <ArrowBackRoundedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Exit ghost mode" />
                </MenuItem>
              )}
            </Menu>
            <Menu
              anchorEl={createAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={createOpen}
              onClose={() => {
                setCreateAnchorEl(null);
              }}
            >
              <MenuItem
                onClick={() => {
                  setCreateAnchorEl(null);
                  setActionItemOpen(true);
                }}
              >
                <ListItemIcon>
                  <AssignmentRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={"Action item"} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setCreateAnchorEl(null);
                  setSubmitTicketDialogOpen(true);
                }}
              >
                <ListItemIcon>
                  <SupportRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={"Support ticket"} />
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
      <CreateActionItemDialog
        open={actionItemOpen}
        onClose={() => {
          setActionItemOpen(false);
        }}
      />
      <SubmitTicketDialog
        open={submitTicketDialogOpen}
        onClose={() => {
          setSubmitTicketDialogOpen(false);
        }}
      />
    </AppBar>
  );
}
