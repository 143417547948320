import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { deepOrange, deepPurple, lightGreen, teal } from "@mui/material/colors";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { startOfToday, subDays } from "date-fns";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { fetchAllTests } from "src/slices/testsSlice";
import ChartFunnel from "./ChartFunnel";
import Comparisons from "./Comparisons";
import { DateRange, useComparison } from "./useComparison";

const allPages = [
  "sales_site",
  "/",
  "/phone",
  "/tags1",
  "/tags2",
  "/snippet1",
  "/tags3",
  "/tags4",
  "/transition",
  "/weight-system",
  "/height-weight",
  "/gender",
  "/finding-coach",
  "/select-coach",
  "create_account",
  "/plan",
  "/pay",
  "/schedule-call",
  "/source",
  "/done",
];

export default function FunnelLeadPage() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [pageViewData, setPageViewData] = useState<any[]>([]);
  const [relative, setRelative] = useState(false);
  const [pages, setPages] = useState<string[]>([]);
  const [isCondensed, setIsCondensed] = useState(false);
  // const [sessionCounts, setSessionCounts] = useState([0, 0, 0]);
  const abTestsStatus = useAppSelector((state) => state.tests.status);
  const [
    comparisons,
    gettingPossibles,
    dateRange,
    loading,
    setLoading,
    setDidDatesChange,
    setComparisons,
    getPossibles,
    handleChangeDateRange,
  ] = useComparison();

  useEffect(() => {
    if (abTestsStatus === "idle") {
      dispatch(fetchAllTests());
    }
  }, [abTestsStatus, dispatch]);

  useEffect(() => {
    handleRefresh();
  }, []);

  useEffect(() => {
    if (isCondensed) {
      setPages([
        "sales_site",
        "/",
        "/finding-coach",
        "/select-coach",
        "create_account",
        "/pay",
        "/schedule-call",
        "/source",
        "/done",
      ]);
    } else {
      setPages(allPages);
    }
  }, [isCondensed]);

  useEffect(() => {
    const newPageViewData = JSON.parse(JSON.stringify(pageViewData)) as any[];

    comparisons.forEach((comparison) => {
      if (relative) {
        const max = Math.max(...newPageViewData.map((d) => d[comparison.id]));
        newPageViewData.map((d) => {
          const point = d;
          d[comparison.id] = d[comparison.id] / max;
          return point;
        });
      } else {
        newPageViewData.map((d) => {
          const point = d;
          d[comparison.id] = d["actual-" + comparison.id];
          return point;
        });
      }
    });

    setPageViewData(newPageViewData);
  }, [relative]);

  async function handleRefresh() {
    setLoading(true);

    console.log("Analytics: refreshing events");

    const newPageViewData: any[] = [];

    allPages.forEach((page) => {
      newPageViewData.push({
        path: page,
      });
    });

    // let newSessionCounts = [...sessionCounts];

    // let newComparisons = JSON.parse(
    //   JSON.stringify(comparisons)
    // ) as Comparison[];

    await Promise.all(
      comparisons.map(async (comparison) => {
        console.log("Comparison: ", comparison);

        let startDate = new Date(
          comparison.startDate ?? new Date(),
        ).toISOString();
        let endDate = new Date(comparison.endDate ?? new Date()).toISOString();

        if (dateRange === DateRange.Last24Hours) {
          startDate = subDays(new Date(), 1).toISOString();
          endDate = new Date().toISOString();
        } else if (dateRange === DateRange.Today) {
          startDate = startOfToday().toISOString();
          endDate = new Date().toISOString();
        } else if (dateRange === DateRange.LastThreeDays) {
          startDate = subDays(new Date(), 3).toISOString();
          endDate = new Date().toISOString();
        } else if (dateRange === DateRange.LastWeek) {
          startDate = subDays(new Date(), 7).toISOString();
          endDate = new Date().toISOString();
        } else if (dateRange === DateRange.LastMonth) {
          startDate = subDays(new Date(), 30).toISOString();
          endDate = new Date().toISOString();
        }

        const result = await api.analytics.getLeadFunnelData({
          startDate: startDate,
          endDate: endDate,
          sourceID: comparison.sourceID,
          utmSource: comparison.utmSource,
          utmCampaign: comparison.utmCampaign,
          utmMedium: comparison.utmMedium,
          utmContent: comparison.utmContent,
          osName: comparison.osName,
          browserName: comparison.browserName,
          deviceType: comparison.deviceType,
          testsInclude: comparison.testsInclude,
          testsExclude: comparison.testsExclude,
        });
        console.log("Result: ", result.data);

        allPages.forEach((page, pageIndex) => {
          newPageViewData[pageIndex][comparison.id] = result[page] ?? 0;
        });

        // newSessionCounts[index] = result.sessionCount;
      }),
    );

    // if (dateRange === DateRange.Custom) {
    //   setComparisons(newComparisons);
    // }

    const keys = comparisons.map((comparison) => comparison.id);

    newPageViewData.map((d) => {
      const point = d;

      keys.forEach((key) => {
        d["actual-" + key] = d[key] ?? 0;
      });

      return point;
    });

    keys.forEach((key) => {
      const max = Math.max(...newPageViewData.map((d) => d[key])) ?? 0;

      newPageViewData.map((d) => {
        const point = d;

        d["relative-" + key] = (d[key] ?? 0) / max;

        return point;
      });
    });

    console.log("Page view data: ", pageViewData);
    setPageViewData(newPageViewData);

    // setSessionCounts(newSessionCounts);

    setLoading(false);
  }

  const colors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    lightGreen[700],
    deepPurple[700],
    deepOrange[700],
    teal[700],
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h1">Lead funnel</Typography>
      <Box sx={{ my: 4, height: "450px" }}>
        <Box
          sx={{
            mb: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Comparisons
            comparisons={comparisons}
            setComparisons={setComparisons}
            setDidDatesChange={setDidDatesChange}
            getPossibles={getPossibles}
            dateRange={dateRange}
            gettingPossibles={gettingPossibles}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormGroup sx={{ mr: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isCondensed}
                    onChange={(event) => {
                      setIsCondensed(event.target.checked);
                    }}
                  />
                }
                label="Condensed"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={relative}
                    onChange={(event) => {
                      setRelative(event.target.checked);
                    }}
                  />
                }
                label="Relative"
              />
            </FormGroup>
            <FormControl size="small">
              <InputLabel>Date range</InputLabel>
              <Select
                value={dateRange}
                label="Date range"
                onChange={handleChangeDateRange}
              >
                <MenuItem value={DateRange.Today}>Today</MenuItem>
                <MenuItem value={DateRange.Last24Hours}>Last 24 hours</MenuItem>
                <MenuItem value={DateRange.LastThreeDays}>Last 3 days</MenuItem>
                <MenuItem value={DateRange.LastWeek}>Last 7 days</MenuItem>
                <MenuItem value={DateRange.LastMonth}>Last 30 days</MenuItem>
                <MenuItem value={DateRange.Custom}>Custom</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <LoadingButton
          sx={{ mb: 2 }}
          onClick={handleRefresh}
          loading={loading}
          disabled={loading}
        >
          Refresh
        </LoadingButton>
        <ParentSize>
          {({ width, height }) => (
            <ChartFunnel
              width={width}
              height={height}
              relative={relative}
              comparisons={comparisons}
              pageViewData={pageViewData.filter((data) =>
                pages.includes(data.path),
              )}
              colors={colors}
              pages={pages}
            />
          )}
        </ParentSize>
        {/* <Stack direction="row" spacing={4} sx={{ mt: 2 }}>
          {comparisons.map((comparison, index) => (
            <Box key={index}>
              <Typography sx={{ fontWeight: "bold", color: colors[index] }}>
                {comparison.name}
              </Typography>
              <Typography>Unique sessions: {sessionCounts[index]}</Typography>
            </Box>
          ))}
        </Stack> */}
      </Box>
    </Box>
  );
}
