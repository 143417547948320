import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ShowChartRoundedIcon from "@mui/icons-material/ShowChartRounded";
import {
  Box,
  Button,
  Paper,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import type { CoachMetricTemplateSubmodule } from "@trainwell/types";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import React, { useState } from "react";
import type Metric from "src/interfaces/Metric";
import {
  calculateNetChange,
  formatMetrics,
  frames,
  queryMetric,
} from "src/lib/metrics";
import { round } from "src/lib/misc";

const styles: Record<string, React.CSSProperties> = {
  hoverTarget: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(44, 44, 49, 0.2)",
    flexGrow: 1,
    margin: "0 2px 0 2px",
    borderRadius: "4px",
  },
  heading: {
    textAlign: "center",
    marginTop: "8px",
  },
  upArrow: {
    width: 0,
    height: 0,
    borderLeft: "6px solid transparent",
    borderRight: "6px solid transparent",
    borderBottom: "9px solid",
  },
  downArrow: {
    width: 0,
    height: 0,
    borderLeft: "6px solid transparent",
    borderRight: "6px solid transparent",
    borderTop: "9px solid",
  },
  dash: {
    width: "12px",
    height: 0,
    borderTop: "3px solid rgba(0, 0, 0, 1)",
  },
};

type Props = {
  allMetrics: Metric[];
  allCompanyMetrics: Metric[];
  submodule: CoachMetricTemplateSubmodule;
  window: number;
  trainerName: string;
};

export default function Trend({
  allMetrics,
  allCompanyMetrics,
  submodule,
  window,
  trainerName,
}: Props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [frame, setFrame] = useState(frames[0]);

  const open = Boolean(anchorEl);

  const trendMetrics = allMetrics.map((obj: any) => {
    let metric = queryMetric(obj, submodule.metric, true);
    metric = submodule.per_day ? metric / window : metric;
    metric = round(metric, submodule.precision);
    return {
      date: obj.date,
      metric: metric,
    };
  });

  const companyTrendMetrics = allCompanyMetrics.map((obj: any) => {
    let metric = queryMetric(obj, submodule.metric, true);
    metric = submodule.per_day ? metric / window : metric;
    metric = round(metric, submodule.precision);
    return {
      date: obj.date,
      metric: metric,
    };
  });

  const filteredTrendMetrics = formatMetrics(trendMetrics, frame);
  const filteredCompanyTrendMetrics = formatMetrics(companyTrendMetrics, frame);

  const trendMetricsMin = Math.min(
    ...filteredTrendMetrics.map((m) => m.metric),
  );
  const companyTrendMetricsMin = Math.min(
    ...filteredCompanyTrendMetrics.map((m) => m.metric),
  );

  const { difference, differencePercent } =
    calculateNetChange(filteredTrendMetrics);

  const displayMetric = (metrics: Metric[]) => {
    let metric;
    if (metrics.length === 0) {
      metric = 0;
    } else {
      metric = round(metrics[metrics.length - 1].metric, 1);
    }

    return (
      <React.Fragment>
        {submodule.is_percent ? (
          <Typography sx={{ fontWeight: "bold", fontSize: 18, mb: 1 }}>
            {metric}%
          </Typography>
        ) : (
          <Typography sx={{ fontWeight: "bold", fontSize: 18, mb: 1 }}>
            {metric}
          </Typography>
        )}
      </React.Fragment>
    );
  };

  let trend: "neutral" | "bad" | "good" = "neutral";
  let chartColor = theme.palette.text.secondary;

  if (submodule.rank_low_to_high === null || difference === 0) {
    chartColor = theme.palette.text.secondary;
    trend = "neutral";
  } else if (
    (difference > 0 && submodule.rank_low_to_high) ||
    (difference < 0 && !submodule.rank_low_to_high)
  ) {
    chartColor = theme.palette.success.main;
    trend = "good";
  } else if (
    (difference < 0 && submodule.rank_low_to_high) ||
    (difference > 0 && !submodule.rank_low_to_high)
  ) {
    chartColor = theme.palette.error.main;
    trend = "bad";
  }

  const chartOptions: Highcharts.Options = {
    colors: [chartColor],
    credits: {
      enabled: false,
    },
    legend: {
      itemStyle: { color: theme.palette.text.secondary },
    },
    tooltip: {
      formatter: function () {
        const date = new Date(this.x ?? "");
        date.setDate(date.getDate() + 6);
        const newDate = Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
        );
        if (submodule.is_percent) {
          return (
            "<b>" +
            this.series.name +
            "</b><br/>" +
            Highcharts.dateFormat("%b %e", newDate) +
            "<br/>" +
            this.y +
            "%"
          );
        } else {
          return (
            "<b>" +
            this.series.name +
            "</b><br/>" +
            Highcharts.dateFormat("%b %e", newDate) +
            "<br/>" +
            this.y
          );
        }
      },
    },
    plotOptions: {
      series: {
        animation: false,
        marker: {
          enabled: false,
        },
      },
    },
    chart: {
      backgroundColor: "transparent",
      height: 300,
      width: 500,
      animation: false,
    },
    title: {
      text: undefined,
    },
    yAxis: {
      title: {
        text: undefined,
      },
      labels: {
        formatter: function () {
          if (submodule.is_percent) {
            return this.value + "%";
          } else {
            return this.value.toString();
          }
        },
        style: {
          color: theme.palette.text.secondary,
        },
      },
      min:
        trendMetricsMin > companyTrendMetricsMin
          ? companyTrendMetricsMin
          : trendMetricsMin,
    },
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function () {
          const date = new Date(this.value);
          date.setDate(date.getDate() + 7);
          const newDate = Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
          );
          return Highcharts.dateFormat("%b %e", newDate);
        },
        style: {
          color: theme.palette.text.secondary,
        },
      },
    },
    series: [
      {
        type: "area",
        data: filteredTrendMetrics.map((obj: any) => [
          Date.parse(obj.date),
          obj.metric,
        ]),
        name: trainerName === "tw" ? "trainwell" : trainerName,
      },
      {
        type: "line",
        data: filteredCompanyTrendMetrics.map((obj: any) => [
          Date.parse(obj.date),
          obj.metric,
        ]),
        name: "trainwell",
        color: theme.palette.text.secondary,
        dashStyle: "ShortDash",
      },
    ],
    accessibility: {
      enabled: false,
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        margin: "0 2px 0 2px",
        borderRadius: "4px",
        color: (theme) =>
          trend === "bad"
            ? theme.palette.error.contrastText
            : trend === "good"
              ? theme.palette.success.contrastText
              : undefined,
        backgroundColor: (theme) =>
          trend === "bad"
            ? theme.palette.error.light
            : trend === "good"
              ? theme.palette.success.light
              : theme.palette.backgroundSecondary.main,
      }}
      aria-haspopup="true"
      onMouseEnter={(event) => {
        setFrame("Past Month");
        setAnchorEl(event.currentTarget);
      }}
      onMouseLeave={() => {
        setAnchorEl(null);
      }}
    >
      <ShowChartRoundedIcon style={{ fontSize: "18px" }} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        sx={{
          pointerEvents: "none",
        }}
        PaperProps={{
          sx: {
            pointerEvents: "auto",
          },
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper sx={{ maxHeight: "500px", padding: 1 }}>
          <div style={styles.heading}>
            <Typography variant="h3" sx={{ mb: 1 }}>
              {submodule.metric_title}
            </Typography>
            {displayMetric(filteredTrendMetrics)}
            <div
              style={{
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography style={{ fontSize: "18px" }}>
                <Typography
                  sx={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    justifyContent: "center",
                    alignItems: "center",
                    color: (theme) =>
                      trend === "bad"
                        ? theme.palette.error.main
                        : trend === "good"
                          ? theme.palette.success.main
                          : undefined,
                    mr: 2,
                  }}
                >
                  {difference > 0 ? (
                    <ArrowDropUpRoundedIcon />
                  ) : difference < 0 ? (
                    <ArrowDropDownRoundedIcon />
                  ) : (
                    "-"
                  )}
                  &nbsp;{" "}
                  {submodule.is_percent
                    ? Math.abs(difference) + "%"
                    : Math.abs(difference) + " (+" + differencePercent + "%)"}
                </Typography>
              </Typography>
              <Button
                onClick={() => {
                  if (frame === frames[0]) {
                    setFrame(frames[1]);
                  } else if (frame === frames[1]) {
                    setFrame(frames[2]);
                  } else if (frame === frames[2]) {
                    setFrame(frames[0]);
                  }
                }}
                style={{ textTransform: "none" }}
              >
                {frame}
              </Button>
            </div>
          </div>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </Paper>
      </Popover>
    </Box>
  );
}
