import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  getInfluencerAssetPresignedUrl,
  influencerGenres,
  influencerManagers,
  isBlank,
  useCreateInfluencer,
  useUpdateInfluencer,
  type Influencer,
} from "@trainwell/features";
import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import { useEffect, useState } from "react";

const icon = <CheckBoxOutlineBlankRoundedIcon fontSize="small" />;
const checkedIcon = <CheckBoxRoundedIcon fontSize="small" />;

type InfluencerData = Pick<
  Influencer,
  | "name"
  | "display_name"
  | "source_ids"
  | "status"
  | "type"
  | "genres"
  | "manager"
  | "percent_under_25"
>;

const blankInfluencerData: InfluencerData = {
  name: "",
  display_name: "",
  status: "active",
  source_ids: [],
  type: "youtube",
};

type Props = {
  open: boolean;
  onClose: () => void;
  defaultInfluencer?: Influencer;
};

export function InfluencerEditDialog({
  open,
  onClose,
  defaultInfluencer,
}: Props) {
  const [uppy] = useState(() =>
    createUppy((fileLocation) => setImageUrl(fileLocation)),
  );
  const [imageUrl, setImageUrl] = useState(
    defaultInfluencer?.assets.splash_asset ?? "",
  );
  const [influencerData, setInfluencerData] = useState<InfluencerData>(
    defaultInfluencer ?? blankInfluencerData,
  );
  const updateInfluencerMutation = useUpdateInfluencer();
  const createInfluencerMutation = useCreateInfluencer();

  useEffect(() => {
    if (!open) {
      setInfluencerData(defaultInfluencer ?? blankInfluencerData);
      setImageUrl(defaultInfluencer?.assets.splash_asset ?? "");
    }
  }, [open, defaultInfluencer]);

  const handleChangeInfluencerData = (data: Partial<InfluencerData>) => {
    setInfluencerData({ ...influencerData, ...data });
  };

  const isDataValid =
    !isBlank(influencerData.name) &&
    !isBlank(influencerData.display_name) &&
    influencerData.source_ids.length > 0;

  const displayedGenreOptions = Object.keys(influencerGenres);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {defaultInfluencer ? "Edit" : "Create"} influencer
      </DialogTitle>
      <DialogContent>
        <Stack direction={"column"} spacing={2} sx={{ mt: 2, mb: 2 }}>
          <TextField
            label="Name"
            value={influencerData.name}
            onChange={(event) => {
              handleChangeInfluencerData({ name: event.target.value });
            }}
            fullWidth
          />
          <TextField
            label="Display name"
            value={influencerData.display_name}
            onChange={(event) => {
              handleChangeInfluencerData({ display_name: event.target.value });
            }}
            fullWidth
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={influencerData.status === "active"}
                  onChange={(event) =>
                    handleChangeInfluencerData({
                      status: event.target.checked ? "active" : "inactive",
                    })
                  }
                />
              }
              label="Status"
            />
          </FormGroup>
          <TextField
            label="Source id"
            value={influencerData.source_ids.join(", ")}
            onChange={(event) => {
              handleChangeInfluencerData({
                source_ids: event.target.value.split(", "),
              });
            }}
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel>Manager</InputLabel>
            <Select
              value={influencerData.manager}
              label="Manager"
              onChange={(event) => {
                handleChangeInfluencerData({
                  manager: (event.target.value ||
                    null) as Influencer["manager"],
                });
              }}
            >
              {Object.keys(influencerManagers).map((manager) => (
                <MenuItem key={manager} value={manager}>
                  {influencerManagers[manager]}
                </MenuItem>
              ))}
              <MenuItem value={""}>None</MenuItem>
            </Select>
          </FormControl>
          <Autocomplete
            multiple
            options={displayedGenreOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => influencerGenres[option]}
            value={influencerData.genres ?? []}
            onChange={(event, newValues) => {
              handleChangeInfluencerData({
                genres: newValues as Influencer["genres"],
              });
            }}
            renderOption={(props, option, { selected }) => {
              return (
                <li
                  {...props}
                  style={{
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 0,
                    paddingTop: 0,
                  }}
                >
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {influencerGenres[option]}
                </li>
              );
            }}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Genres" />}
            sx={{ mb: 1 }}
          />
          <TextField
            margin="dense"
            label="Percent under 25"
            placeholder="83"
            value={influencerData.percent_under_25 ?? ""}
            onChange={(event) => {
              handleChangeInfluencerData({
                percent_under_25:
                  event.target.value === null ||
                  event.target.value === "" ||
                  isNaN(Number(event.target.value))
                    ? null
                    : Math.max(Math.min(Number(event.target.value), 100), 0),
              });
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            fullWidth
            helperText="0-100"
          />
        </Stack>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {defaultInfluencer?.assets.splash_asset ? (
            <Box>
              <Typography>Current Picture</Typography>
              <img
                alt=""
                height={50}
                width={50}
                src={defaultInfluencer.assets.splash_asset}
              />{" "}
            </Box>
          ) : (
            <Typography sx={{ color: (theme) => theme.palette.error.dark }}>
              No splash asset
            </Typography>
          )}
          {imageUrl !== "" &&
            imageUrl !== defaultInfluencer?.assets.splash_asset && (
              <Box sx={{ ml: 3 }}>
                <Typography>New Picture</Typography>
                <img alt="" height={50} width={50} src={imageUrl} />
              </Box>
            )}
        </Box>
        <Dashboard
          uppy={uppy}
          height={200}
          width={400}
          proudlyDisplayPoweredByUppy={false}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!isDataValid}
          onClick={() => {
            if (defaultInfluencer) {
              updateInfluencerMutation.mutate({
                influencerId: defaultInfluencer.id,
                data: {
                  name: influencerData.name,
                  display_name: influencerData.display_name,
                  status: influencerData.status,
                  source_ids: influencerData.source_ids,
                  type: influencerData.type,
                  assets: {
                    splash_asset: imageUrl,
                  },
                  genres: influencerData.genres,
                  manager: influencerData.manager,
                  percent_under_25: influencerData.percent_under_25,
                },
              });
            } else {
              createInfluencerMutation.mutate({
                data: {
                  name: influencerData.name,
                  display_name: influencerData.display_name,
                  status: influencerData.status,
                  source_ids: influencerData.source_ids,
                  type: influencerData.type,
                  assets: {
                    splash_asset: imageUrl,
                  },
                  genres: influencerData.genres,
                  manager: influencerData.manager,
                  percent_under_25: influencerData.percent_under_25,
                },
              });
            }
            uppy.cancelAll();
            onClose();
          }}
        >
          {defaultInfluencer ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const createUppy = (onUpload: (fileLocation: string) => void) => {
  return new Uppy({
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*"],
    },
    autoProceed: true,
    onBeforeUpload: () => {
      return true;
    },
  })
    .use(AwsS3, {
      // @ts-expect-error
      getUploadParameters(file) {
        // here we prepare our request to the server for the upload URL
        return getInfluencerAssetPresignedUrl(file.name || "").then((data) => {
          return {
            method: "put", // here we send method, url, fields and headers to the AWS S3 bucket
            url: data.signed_url,
            fields: {},
          };
        });
      },
    })
    .on("upload-success", (file, response) => {
      onUpload(response.body?.location ?? "");
      response;
    });
};
