import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import PersonOffRoundedIcon from "@mui/icons-material/PersonOffRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { CardActionArea, Stack, Typography, alpha } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectTestById } from "src/slices/testsSlice";

type Props = {
  testId: string;
};

export function TestCell({ testId }: Props) {
  const { testId: queryTestId } = useParams();
  const test = useAppSelector((state) => selectTestById(state, testId));

  if (!test) {
    return <Typography>Error finding test</Typography>;
  }

  const selected = queryTestId === testId;

  return (
    <CardActionArea
      href={`/admin/analytics/tests/${testId}`}
      sx={{
        p: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: 1,
        borderColor: "divider",
        backgroundColor: (theme) =>
          selected
            ? alpha(theme.palette.primary.main, 0.1)
            : theme.palette.background.paper,
      }}
    >
      <Stack alignItems={"center"} spacing={1} direction={"row"}>
        {test.is_active ? (
          <PlayArrowRoundedIcon fontSize="small" color="success" />
        ) : (
          <PauseRoundedIcon
            fontSize="small"
            sx={{
              color: (theme) =>
                test.date_archived ? theme.palette.text.disabled : undefined,
            }}
          />
        )}
        {test.is_enrolling ? (
          <PersonAddRoundedIcon fontSize="small" color="success" />
        ) : (
          <PersonOffRoundedIcon
            fontSize="small"
            sx={{
              color: (theme) =>
                test.date_archived ? theme.palette.text.disabled : undefined,
            }}
          />
        )}
        <Typography
          sx={{
            color: (theme) =>
              selected
                ? theme.palette.primary.main
                : test.date_archived
                  ? theme.palette.text.disabled
                  : undefined,
            fontWeight: selected ? "bold" : undefined,
          }}
        >
          {test.name}
        </Typography>
      </Stack>
    </CardActionArea>
  );
}
