import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Accordion, Box, Button, Grid } from "@mui/material";
import type {
  CoachMetricTemplate,
  CoachMetricTemplateSubmodule,
  Trainer,
} from "@trainwell/types";
import React, { useEffect, useMemo, useState } from "react";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppSelector } from "src/hooks/stateHooks";
import {
  queryMetric,
  sortMetricsByName,
  sortMetricsByOneMonthRetention,
  sortMetricsByPaidClients,
  sortMetricsByRankSum,
  sortMetricsByResponseTime,
  sortMetricsBySixMonthRetention,
  sortMetricsByThreeMonthRetention,
  sortMetricsByTrialConversion,
  sortMetricsByWorkoutCompletion,
} from "src/lib/metrics";
import { round } from "src/lib/misc";
import { api } from "src/lib/trainwellApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { TrainerMetricsCell } from "./TrainerMetricsCell";

const styles: Record<string, React.CSSProperties> = {
  tile: {
    padding: "0.25em",
    minHeight: "30px",
    display: "block",
    borderRadius: "0px",
    justifyContent: "center",
  },
  categoryGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
    gap: "1em",
  },
};

type Column =
  | "Name"
  | "Trial conversion"
  | "Response time"
  | "Workout completion"
  | "1 month retention"
  | "3 month retention"
  | "6 month retention"
  | "Paid clients"
  | "Rank sum";

const adminModeCategories: Column[] = [
  "Name",
  "Trial conversion",
  "Response time",
  "Workout completion",
  "1 month retention",
  "3 month retention",
  "6 month retention",
  "Paid clients",
  "Rank sum",
];
const trainerModeCategories: Column[] = [
  "Name",
  "Trial conversion",
  "Response time",
  "Workout completion",
  "1 month retention",
  "3 month retention",
  "6 month retention",
  "Paid clients",
];
const windows = { day: 1, week: 7, month: 31 };

export interface CoachMetricDetails {
  trainer: Pick<
    Trainer,
    | "trainer_id"
    | "first_name"
    | "last_name"
    | "full_name"
    | "headshot_url"
    | "lead_trainer_id"
    | "is_manager"
  >;
  metricsSnapshot: any;
  metrics: any[];
  failedToLoad: boolean;
  rankSum: number;
  isTest?: boolean;
  isCohort?: boolean;
}

type Props = {
  trainerId: string;
  showAdminMetrics?: boolean;
  showAllTrainers?: boolean;
};

export default function MetricsPage({
  trainerId,
  showAdminMetrics,
  showAllTrainers,
}: Props) {
  const [coachMetricDetails, setCoachMetricsDetails] =
    useState<CoachMetricDetails[]>();
  const [companyObject, setCompanyObject] = useState<any>();
  const [templates, setTemplates] = useState<CoachMetricTemplate[]>();
  const [window, setWindow] = useState<"day" | "week" | "month">("week");
  const [sortedSubmoduleMetrics, setSortedSubmoduleMetrics] = useState<any[]>();
  const [detailedMetricsKeys, setDetailedMetricsKeys] = useState<string[]>([]);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [sort, setSort] = useState<{
    field: Column;
    direction: "asc" | "desc";
  }>({
    field: "Name",
    direction: "asc",
  });

  useEffect(() => {
    async function runAsync() {
      let coaches: Pick<
        Trainer,
        | "trainer_id"
        | "first_name"
        | "last_name"
        | "full_name"
        | "headshot_url"
        | "lead_trainer_id"
        | "is_manager"
      >[];

      if (showAllTrainers) {
        coaches = await api.coachMetrics.getCoaches();
      } else {
        coaches = [await api.trainers.getOne(trainerId)];
      }

      coaches = coaches.concat({
        trainer_id: "deltatrainer",
        full_name: "trainwell",
        first_name: "",
        last_name: "",
        headshot_url: "/assets/favicon.png",
        is_manager: false,
      });

      const coachMetricTemplates = await api.coachMetrics.getTemplates();

      coachMetricTemplates.map((template) => {
        template.submodules = template.submodules.filter(Boolean);
      });

      if (!showAllTrainers) {
        coaches = coaches.filter((t) => {
          return t.trainer_id === trainerId || t.trainer_id === "deltatrainer";
          // if (trainer?.is_lead_trainer) {
          //   return (
          //     t.lead_trainer_id === trainerId ||
          //     t.trainer_id === trainerId ||
          //     t.trainer_id === "deltatrainer"
          //   );
          // } else {
          //   return (
          //     t.trainer_id === trainerId || t.trainer_id === "deltatrainer"
          //   );
          // }
        });

        if (trainer?.is_lead_trainer) {
          // make the lead coach the first in the list after "deltatrainer"
          const leadCoachIndex = coaches.findIndex(
            (t) => t.trainer_id == trainerId,
          );

          if (leadCoachIndex !== 1) {
            coaches.splice(1, 0, coaches.splice(leadCoachIndex, 1)[0]);
          }
        }

        let countOverOneWorkDay: CoachMetricTemplateSubmodule;

        coachMetricTemplates.forEach((metricTemplate) => {
          metricTemplate.submodules.forEach((submodule) => {
            if (submodule.metric_title === "Count Over 1 Work Day") {
              countOverOneWorkDay = submodule;
            }
          });
        });

        // coachMetricTemplates = coachMetricTemplates.filter(
        //   (metricTemplate) =>
        //     metricTemplate.module_title === "Users" ||
        //     metricTemplate.module_title === "Workouts"
        // );

        coachMetricTemplates.forEach((metricTemplate) => {
          if (
            metricTemplate.module_title === "Workouts" &&
            countOverOneWorkDay
          ) {
            metricTemplate.submodules.push(countOverOneWorkDay);
          }
        });
      }

      const topLevelMetricsKeys: string[] = [];
      const fetchedDetailedMetricsKeys: string[] = [];

      coachMetricTemplates.forEach((template) => {
        template.submodules.forEach((submodule) => {
          if (submodule) {
            fetchedDetailedMetricsKeys.push(submodule.metric);
            topLevelMetricsKeys.push(submodule.metric);
            if (submodule.list_metric) {
              topLevelMetricsKeys.push(submodule.list_metric);
            }
            if (submodule.distribution_metric) {
              topLevelMetricsKeys.push(submodule.distribution_metric);
            }
          }
        });
      });

      const trainerIds = coaches.map((trainer) => trainer.trainer_id);

      const managers = coaches.filter((trainer) => trainer.is_manager);
      const managerIds = managers.map((trainer) => trainer.trainer_id);

      const {
        coachMetrics: fetchedTopLevelMetrics,
        cohortMetrics,
        testMetrics,
      } = await api.coachMetrics.getTopLevelMetrics(
        trainerIds,
        managerIds,
        topLevelMetricsKeys.concat([
          "workout_satisfactions.percent_100",
          "messages.count_sent_by_hour",
          "workouts.count_internal_by_hour",
          "progresses.percent_users_measuring_month",
          "training.is_active",
        ]),
      );

      setDetailedMetricsKeys(fetchedDetailedMetricsKeys);

      const fetchedDetailedMetrics = await api.coachMetrics.getDetailedMetrics(
        "deltatrainer",
        false,
        false,
        fetchedDetailedMetricsKeys,
      );

      const companyObject = {
        metrics: fetchedTopLevelMetrics.filter(
          (metric: any) => metric.trainer_id === "deltatrainer",
        )[0],
        allMetrics: fetchedDetailedMetrics,
      };

      const newCoachMetricDetails: CoachMetricDetails[] = [];

      coaches.forEach((trainer) => {
        const topLevelMetrics = fetchedTopLevelMetrics.filter(
          (metric: any) => metric.trainer_id === trainer.trainer_id,
        );

        newCoachMetricDetails.push({
          trainer: trainer,
          metricsSnapshot: topLevelMetrics[0],
          metrics: [],
          failedToLoad: false,
          rankSum: 0,
        });
      });

      managers.forEach((trainer) => {
        const topLevelMetrics = cohortMetrics.filter(
          (metric: any) => metric.manager_id === trainer.trainer_id,
        );

        newCoachMetricDetails.unshift({
          trainer: { ...trainer, full_name: `${trainer.full_name}'s cohort` },
          metricsSnapshot: topLevelMetrics[0],
          metrics: [],
          failedToLoad: false,
          rankSum: 0,
          isCohort: true,
        });
      });

      if (trainerId === "admin") {
        testMetrics.forEach((metricSnapshot) => {
          newCoachMetricDetails.unshift({
            trainer: {
              trainer_id: metricSnapshot.test_label,
              full_name: `Test: ${metricSnapshot.test_label}`,
              first_name: "Test",
              last_name: metricSnapshot.test_label,
              headshot_url: "/assets/favicon.png",
              is_manager: false,
            },
            metricsSnapshot: metricSnapshot,
            metrics: [],
            failedToLoad: false,
            rankSum: 0,
            isTest: true,
          });
        });
      }

      newCoachMetricDetails.sort((a, b) => {
        if (a.trainer.trainer_id === "deltatrainer") {
          return -1;
        } else {
          return 0;
        }
      });

      const sortedCategories: any[] = [];

      const rankSumCoachData = [...newCoachMetricDetails].filter(
        (c) =>
          !c.isTest && !c.isCohort && c.trainer.trainer_id !== "deltatrainer",
      );

      sortedCategories.push.apply(sortedCategories, [
        [...rankSumCoachData].sort((a, b) => {
          return sortMetricsByTrialConversion(a, b, "asc");
        }),
        [...rankSumCoachData].sort((a, b) => {
          return sortMetricsByResponseTime(a, b, "asc");
        }),
        [...rankSumCoachData].sort((a, b) => {
          return sortMetricsByWorkoutCompletion(a, b, "asc");
        }),
        [...rankSumCoachData].sort((a, b) => {
          return sortMetricsByOneMonthRetention(a, b, "asc");
        }),
        [...rankSumCoachData].sort((a, b) => {
          return sortMetricsByThreeMonthRetention(a, b, "asc");
        }),
        [...rankSumCoachData].sort((a, b) => {
          return sortMetricsBySixMonthRetention(a, b, "asc");
        }),
        [...rankSumCoachData].sort((a, b) => {
          return sortMetricsByPaidClients(a, b, "asc");
        }),
      ]);
      sortedCategories.forEach((category) => {
        newCoachMetricDetails.map((trainerObject) => {
          trainerObject.rankSum += category.indexOf(trainerObject);
          return trainerObject;
        });
      });

      const window = companyObject.metrics.window || "week";

      let sortedSubmoduleMetrics;
      if (trainerId === "admin") {
        sortedSubmoduleMetrics = sortSubmoduleMetrics(
          newCoachMetricDetails,
          coachMetricTemplates,
          window,
        );
      }

      setCoachMetricsDetails(newCoachMetricDetails);
      setCompanyObject(companyObject);
      setTemplates(coachMetricTemplates);
      setWindow(window);
      setSortedSubmoduleMetrics(sortedSubmoduleMetrics);
    }

    if (!coachMetricDetails) {
      runAsync();
    }
  }, [trainerId]);

  const sortedCoachMetricsDetailed = useMemo(() => {
    let newCoachMetricsDetailed = JSON.parse(
      JSON.stringify(coachMetricDetails ?? []),
    ) as CoachMetricDetails[];

    if (sort.field === "Name") {
      newCoachMetricsDetailed = newCoachMetricsDetailed.sort((a, b) => {
        return sortMetricsByName(a, b, sort.direction);
      });
    } else if (sort.field === "Trial conversion") {
      newCoachMetricsDetailed = newCoachMetricsDetailed.sort((a, b) => {
        return sortMetricsByTrialConversion(a, b, sort.direction);
      });
    } else if (sort.field === "Response time") {
      newCoachMetricsDetailed = newCoachMetricsDetailed.sort((a, b) => {
        return sortMetricsByResponseTime(a, b, sort.direction);
      });
    } else if (sort.field === "Workout completion") {
      newCoachMetricsDetailed = newCoachMetricsDetailed.sort((a, b) => {
        return sortMetricsByWorkoutCompletion(a, b, sort.direction);
      });
    } else if (sort.field === "1 month retention") {
      newCoachMetricsDetailed = newCoachMetricsDetailed.sort((a, b) => {
        return sortMetricsByOneMonthRetention(a, b, sort.direction);
      });
    } else if (sort.field === "3 month retention") {
      newCoachMetricsDetailed = newCoachMetricsDetailed.sort((a, b) => {
        return sortMetricsByThreeMonthRetention(a, b, sort.direction);
      });
    } else if (sort.field === "6 month retention") {
      newCoachMetricsDetailed = newCoachMetricsDetailed.sort((a, b) => {
        return sortMetricsBySixMonthRetention(a, b, sort.direction);
      });
    } else if (sort.field === "Paid clients") {
      newCoachMetricsDetailed = newCoachMetricsDetailed.sort((a, b) => {
        return sortMetricsByPaidClients(a, b, sort.direction);
      });
    } else if (sort.field === "Rank sum") {
      newCoachMetricsDetailed = newCoachMetricsDetailed.sort((a, b) => {
        return sortMetricsByRankSum(a, b, sort.direction);
      });
    }

    return newCoachMetricsDetailed;
  }, [sort.direction, sort.field, coachMetricDetails]);

  function sortSubmoduleMetrics(
    trainerObjects: CoachMetricDetails[],
    templates: CoachMetricTemplate[],
    window: any,
  ) {
    const metricsArray = trainerObjects
      .filter((obj) => obj.trainer.trainer_id !== "deltatrainer")
      .map((obj) => {
        return { trainer: obj.trainer, metrics: obj.metricsSnapshot };
      });

    const sortedSubmoduleMetrics: any[] = [];

    let submoduleTemplates: any[] = [];

    templates.forEach((template) => {
      submoduleTemplates = submoduleTemplates.concat(template.submodules);
    });

    submoduleTemplates.forEach((template) => {
      const filteredMetricsArray = metricsArray.map((obj) => {
        let metric = queryMetric(obj.metrics, template.metric);
        if (template.per_day) {
          metric /= windows[window];
        }
        metric = round(metric, template.precision);
        return { trainer: obj.trainer, metric: metric };
      });

      const sortedMetricsArray = [...filteredMetricsArray].sort((a, b) => {
        if (template.rank_low_to_high) {
          return b.metric - a.metric;
        } else {
          return a.metric - b.metric;
        }
      });

      sortedSubmoduleMetrics.push({
        title: template.metric_title,
        metrics: sortedMetricsArray,
      });
    });

    return sortedSubmoduleMetrics;
  }

  function toggleSort(field: (typeof sort)["field"]) {
    if (sort.field === field) {
      setSort({
        field: sort.field,
        direction: sort.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setSort({
        field: field,
        direction: "desc",
      });
    }
  }

  const categories = showAdminMetrics
    ? adminModeCategories
    : trainerModeCategories;

  if (!coachMetricDetails || !companyObject || !templates) {
    return (
      <LoadingPage message="Doing a metric tonne of metric math (haha, get it? 🙃)" />
    );
  }

  return (
    <Box>
      <Accordion style={styles.tile}>
        <div
          style={{
            outline: "none",
            display: "flex",
            padding: "0px 16px",
          }}
        >
          <div style={{ display: "flex", flexGrow: 1, margin: "12px 0" }}>
            <Grid
              container
              style={
                showAdminMetrics
                  ? styles.categoryGrid
                  : {
                      ...styles.categoryGrid,
                      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                    }
              }
            >
              <Grid
                item
                sx={{
                  width: "200px",
                  mr: "1em",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  size="small"
                  variant="text"
                  onClick={() => {
                    toggleSort("Name");
                  }}
                  endIcon={
                    sort.field !== "Name" ? undefined : sort.direction ===
                      "desc" ? (
                      <ExpandMoreRoundedIcon />
                    ) : (
                      <ExpandLessRoundedIcon />
                    )
                  }
                >
                  Name
                </Button>
              </Grid>
              {[...categories].splice(1).map((category, i) => (
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  key={i}
                >
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      toggleSort(category);
                    }}
                    endIcon={
                      sort.field !== category ? undefined : sort.direction ===
                        "desc" ? (
                        <ExpandMoreRoundedIcon />
                      ) : (
                        <ExpandLessRoundedIcon />
                      )
                    }
                  >
                    {category}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </Accordion>
      {companyObject && templates && (
        <>
          {sortedCoachMetricsDetailed.map((details, i) => (
            <TrainerMetricsCell
              key={details.trainer.trainer_id + i}
              coachMetricDetails={details}
              detailedMetricsKeys={detailedMetricsKeys}
              templates={templates}
              companyMetrics={companyObject.metrics}
              allCompanyMetrics={companyObject.allMetrics}
              window={windows[window]}
              sortedSubmoduleMetrics={sortedSubmoduleMetrics}
              showAdminMetrics={showAdminMetrics ?? false}
              admin={trainerId === "admin"}
            />
          ))}
        </>
      )}
    </Box>
  );
}
