import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  debounce,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { EquipmentType, type EquipmentObject } from "@trainwell/types";
import { getConvertedWeight } from "@trainwell/workout-lib";
import cloneDeep from "lodash-es/cloneDeep";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { shallowEqual } from "react-redux";
import SetTextField from "src/components/WorkoutBuilderPage/SetTextField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getWeightUnit } from "src/lib/miscUtility";
import { api } from "src/lib/trainwellApi";
import { updateClientLocal } from "src/slices/clientSlice";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.25),
}));

type Props = {
  userId: string;
  defaultExpanded?: boolean;
  freeFloating?: boolean;
  condensed?: boolean;
};

export default function EquipmentPanel({
  userId,
  defaultExpanded = false,
  freeFloating = false,
  condensed,
}: Props) {
  const dispatch = useAppDispatch();
  const equipment = useAppSelector(
    (state) =>
      (state.client.client?.user_id === userId
        ? state.client.client.equipment_detailed
        : undefined) ?? {},
    shallowEqual,
  );
  const preferredWeightSystem = useAppSelector((state) =>
    state.client.client?.user_id === userId
      ? state.client.client.preferred_weight_system
      : undefined,
  );
  const [expanded, setExpanded] = useState(defaultExpanded);
  const { enqueueSnackbar } = useSnackbar();

  useHotkeys("e", () => {
    setExpanded(true);
    setTimeout(() => {
      document
        .getElementById("panel_equipment")
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 50);
  });

  const handleSaveEquipment = useCallback(
    (userId: string, equipment: EquipmentObject) => {
      console.log(`Autosave: saving equipment`, userId);

      const newEquipmentCopy = JSON.parse(
        JSON.stringify(equipment),
      ) as EquipmentObject;

      const equipmentWithWeights: (keyof EquipmentObject)[] = [
        "dumbbell",
        "barbell",
        "kettlebell",
        "medicine_ball",
      ];

      for (const equipmentKey of equipmentWithWeights) {
        newEquipmentCopy[equipmentKey]?.values?.map((value, i) => {
          if (value === null) {
            newEquipmentCopy[equipmentKey]?.values!.splice(i, 1);
            newEquipmentCopy[equipmentKey]?.is_adjustable!.splice(i, 1);
          }
        });
      }

      api.clients
        .updateOne({
          user_id: userId,
          equipment_detailed: newEquipmentCopy,
        })
        .then(() => {
          console.log(`Autosave: saved equipment`, userId);
        })
        .catch((error) => {
          enqueueSnackbar("Error saving equipment", {
            variant: "error",
          });

          Sentry.captureException(new Error("Error saving equipment"));

          throw error;
        });
    },
    [dispatch, enqueueSnackbar],
  );

  const debouncedSaveEquipment = useMemo(() => {
    return debounce(handleSaveEquipment, 3000);
  }, [handleSaveEquipment]);

  const didMount = useRef(false);
  useEffect(() => {
    didMount.current = false;
    debouncedSaveEquipment.clear();
  }, [userId, debouncedSaveEquipment]);

  useEffect(() => {
    if (!equipment) {
      return;
    }

    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    const newEquipmentCopy = JSON.parse(
      JSON.stringify(equipment),
    ) as EquipmentObject;

    debouncedSaveEquipment(userId, newEquipmentCopy);
  }, [dispatch, JSON.stringify(equipment), debouncedSaveEquipment]);

  const sortedEquipmentKeys = (
    Object.keys(EquipmentType) as (keyof typeof EquipmentType)[]
  ).sort((a, b) => {
    const hasEquipmentA = equipment.hasOwnProperty(a) && equipment[a];
    const hasEquipmentB = equipment.hasOwnProperty(b) && equipment[b];

    if (hasEquipmentA && !hasEquipmentB) {
      return -1;
    } else if (!hasEquipmentA && hasEquipmentB) {
      return 1;
    }

    return a < b ? -1 : 1;
  });

  const selectedEquipmentKeys = sortedEquipmentKeys.filter(
    (item) => equipment.hasOwnProperty(item) && equipment[item],
  );

  function toggleEquipment(key: keyof typeof EquipmentType) {
    const newSelectedEquipment = cloneDeep(equipment);

    if (newSelectedEquipment[key]) {
      newSelectedEquipment[key] = null;
    } else {
      newSelectedEquipment[key] = {
        notes: null,
        photo_url: null,
      };

      if (
        key === "kettlebell" ||
        key === "dumbbell" ||
        key === "barbell" ||
        key === "medicine_ball"
      ) {
        newSelectedEquipment[key]!.is_adjustable = [];
        newSelectedEquipment[key]!.values = [];
      }
    }

    dispatch(
      updateClientLocal({
        user_id: userId,
        equipment_detailed: newSelectedEquipment,
      }),
    );
  }

  return (
    <Accordion
      id="panel_equipment"
      disableGutters
      elevation={freeFloating ? undefined : 0}
      square={!freeFloating}
      expanded={condensed ? false : expanded}
      onChange={(event, expanded) => {
        setExpanded(expanded);
      }}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      sx={{
        "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
        "& .MuiAccordionDetails-root": { display: expanded ? "block" : "none" },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">🏋️{condensed ? "" : " Equipment"}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            listStyle: "none",
            px: 1,
          }}
        >
          {sortedEquipmentKeys.map((key) => {
            return (
              <ListItem key={key}>
                <Chip
                  label={EquipmentType[key]}
                  onClick={() => {
                    toggleEquipment(key);
                  }}
                  size="small"
                  variant={!equipment[key] ? "outlined" : "filled"}
                  color={!equipment[key] ? "default" : "blueSurface"}
                />
              </ListItem>
            );
          })}
        </Box>
        {selectedEquipmentKeys.map((key) => {
          return (
            <Box
              sx={{ px: 2, py: 2, borderBottom: 1, borderColor: "divider" }}
              key={key}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                {EquipmentType[key]}
              </Typography>
              {(key === "dumbbell" ||
                key === "barbell" ||
                key === "kettlebell" ||
                key === "medicine_ball") && (
                <>
                  {equipment[key]?.values?.map((value, i) => {
                    return (
                      <Grid
                        container
                        spacing={1}
                        sx={{ mb: 1 }}
                        alignItems="center"
                        key={i + "-" + preferredWeightSystem}
                      >
                        <Grid item xs>
                          <SetTextField
                            status={"valid"}
                            label={
                              (equipment[key]!.is_adjustable![i]
                                ? "Max weight"
                                : "Weight") +
                              " (" +
                              getWeightUnit(preferredWeightSystem!) +
                              ")"
                            }
                            value={
                              value === null
                                ? ""
                                : getConvertedWeight({
                                    weight: value,
                                    fromSystem: "imperial",
                                    toSystem: preferredWeightSystem!,
                                    round: true,
                                  })
                            }
                            onChange={(value) => {
                              const newSelectedEquipment = cloneDeep(equipment);

                              newSelectedEquipment[key]!.values![i] =
                                value === ""
                                  ? null
                                  : getConvertedWeight({
                                      weight: Number(
                                        value.replace(/[^\d.]/g, ""),
                                      ),
                                      fromSystem: preferredWeightSystem!,
                                      toSystem: "imperial",
                                    });

                              dispatch(
                                updateClientLocal({
                                  user_id: userId,
                                  equipment_detailed: newSelectedEquipment,
                                }),
                              );
                            }}
                            allowDecimal
                          />
                        </Grid>
                        <Grid item xs="auto">
                          {key !== "medicine_ball" && (
                            <FormGroup>
                              <FormControlLabel
                                sx={{ mr: 1 }}
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={equipment[key]?.is_adjustable![i]}
                                    onChange={() => {
                                      const newSelectedEquipment =
                                        cloneDeep(equipment);

                                      newSelectedEquipment[key]!.is_adjustable![
                                        i
                                      ] =
                                        !newSelectedEquipment[key]!
                                          .is_adjustable![i];

                                      dispatch(
                                        updateClientLocal({
                                          user_id: userId,
                                          equipment_detailed:
                                            newSelectedEquipment,
                                        }),
                                      );
                                    }}
                                  />
                                }
                                label="Adjustable"
                              />
                            </FormGroup>
                          )}
                        </Grid>
                        <Grid item xs="auto">
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              const newSelectedEquipment = cloneDeep(equipment);

                              newSelectedEquipment[key]!.is_adjustable!.splice(
                                i,
                                1,
                              );

                              newSelectedEquipment[key]!.values!.splice(i, 1);

                              dispatch(
                                updateClientLocal({
                                  user_id: userId,
                                  equipment_detailed: newSelectedEquipment,
                                }),
                              );
                            }}
                            size="small"
                            color="error"
                          >
                            <DeleteRoundedIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<AddRoundedIcon />}
                    onClick={() => {
                      const newSelectedEquipment = cloneDeep(equipment);

                      if (newSelectedEquipment[key]!.is_adjustable) {
                        newSelectedEquipment[key]!.is_adjustable!.push(false);
                      } else {
                        newSelectedEquipment[key]!.is_adjustable = [false];
                      }

                      if (newSelectedEquipment[key]!.values) {
                        newSelectedEquipment[key]!.values!.push(0);
                      } else {
                        newSelectedEquipment[key]!.values = [0];
                      }

                      dispatch(
                        updateClientLocal({
                          user_id: userId,
                          equipment_detailed: newSelectedEquipment,
                        }),
                      );
                    }}
                    sx={{ mb: 1 }}
                  >
                    Add a weight
                  </Button>
                </>
              )}
              <TextField
                label="Notes"
                variant="outlined"
                size="small"
                fullWidth
                multiline={true}
                defaultValue={equipment[key]?.notes}
                key={equipment[key]?.notes}
                onBlur={(event) => {
                  const newSelectedEquipment = cloneDeep(equipment);

                  if (!event.target.value.replace(/\s/g, "").length) {
                    newSelectedEquipment[key]!.notes = null;
                  } else {
                    newSelectedEquipment[key]!.notes = event.target.value;
                  }

                  dispatch(
                    updateClientLocal({
                      user_id: userId,
                      equipment_detailed: newSelectedEquipment,
                    }),
                  );
                }}
              />
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}
