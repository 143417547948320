import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import type { Test } from "@trainwell/types";
import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import RestrictAccess from "src/components/misc/RestrictAccess";
import SearchField from "src/components/misc/SearchField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { fetchAllTests } from "src/slices/testsSlice";
import { TestCell } from "./TestCell";
import { TestEditDialog } from "./TestEditDialog";

const drawerWidth = 240;

export function TestLayout() {
  const dispatch = useAppDispatch();
  const tests = useAppSelector((state) => state.tests.tests);
  const testsStatus = useAppSelector((state) => state.tests.status);
  const [search, setSearch] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const displayedTests = useMemo(() => {
    let newTests = JSON.parse(JSON.stringify(tests)) as Test[];

    if (search !== "") {
      newTests = newTests.filter((test) =>
        test.name.toLowerCase().includes(search.toLowerCase()),
      );
    }

    newTests.sort((a, b) => {
      if (a.date_archived && !b.date_archived) {
        return 1;
      } else if (b.date_archived && !a.date_archived) {
        return -1;
      }

      if (a.is_active && !b.is_active) {
        return -1;
      } else if (b.is_active && !a.is_active) {
        return 1;
      }

      if (a.is_enrolling && !b.is_enrolling) {
        return -1;
      } else if (b.is_enrolling && !a.is_enrolling) {
        return 1;
      }

      return a.name.localeCompare(b.name);
    });

    return newTests;
  }, [search, tests]);

  useEffect(() => {
    if (testsStatus === "idle") {
      dispatch(fetchAllTests());
    }
  }, [testsStatus, dispatch]);

  return (
    <RestrictAccess location={"tests"} showNoAccessMessage>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Paper
          square
          elevation={0}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            maxHeight: "100%",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column",
            borderRight: 1,
            borderColor: "divider",
          }}
        >
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
              px: 2,
              py: 1,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Typography variant="h1">Tests</Typography>
            <Typography variant="overline">
              Showing {(displayedTests ?? []).length} tests
            </Typography>
          </Box>
          <Box sx={{ overflowY: "auto", flex: 1 }}>
            <Box
              sx={{
                p: 1,
                backgroundColor: (theme) => theme.palette.background.paper,
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <SearchField
                value={search}
                onChange={(value) => {
                  setSearch(value);
                }}
                onClear={() => {
                  setSearch("");
                }}
              />
              <Button
                startIcon={<AddRoundedIcon />}
                size="small"
                fullWidth
                onClick={() => {
                  setEditDialogOpen(true);
                }}
                sx={{ mt: 1 }}
              >
                New
              </Button>
            </Box>
            <Stack direction="column">
              {displayedTests.map((test) => (
                <TestCell key={test.id} testId={test.id} />
              ))}
            </Stack>
          </Box>
        </Paper>
        <Box sx={{ flexGrow: 1, overflowX: "hidden" }}>
          <Outlet />
        </Box>
      </Box>
      <TestEditDialog
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
        }}
      />
    </RestrictAccess>
  );
}
