import { Box, Typography, useTheme } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import {
  testAnalyticsInfo,
  type ProductMetricSnapshot,
  type TestAnalyticsInfoItem,
} from "@trainwell/types";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RestrictAccess from "src/components/misc/RestrictAccess";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { getDateFromDatabase } from "src/lib/date";
import { api } from "src/lib/trainwellApi";

export function ProductMetricsPage() {
  const { productMetricId } = useParams();
  const theme = useTheme();
  const [snapshots, setSnapshots] = useState<ProductMetricSnapshot[]>([]);

  useEffect(() => {
    api.analytics.getProductMetricSnapshots().then((data) => {
      setSnapshots(
        data.snapshots.sort((a, b) =>
          (a.date as string).localeCompare(b.date as string),
        ),
      );
    });
  }, []);

  const details = testAnalyticsInfo[productMetricId!] as TestAnalyticsInfoItem;

  if (!snapshots) {
    return <LoadingPage />;
  }

  const androidSnapshots = snapshots.filter(
    (snapshot) => snapshot.platform === "android",
  );

  const iosSnapshots = snapshots.filter(
    (snapshot) => snapshot.platform === "ios",
  );

  const data = androidSnapshots.map((snapshot, i) => {
    let androidValue = androidSnapshots[i][productMetricId!] as number;
    let iosValue = iosSnapshots[i][productMetricId!] as number;

    if (details.type === "percent") {
      androidValue = Math.round(androidValue * 10000) / 100;
      iosValue = Math.round(iosValue * 10000) / 100;
    } else {
      androidValue = Math.round(androidValue * 100) / 100;
      iosValue = Math.round(iosValue * 100) / 100;
    }

    return {
      date: new Date(snapshot.date).getTime(),
      android: androidValue,
      ios: iosValue,
    };
  });

  return (
    <RestrictAccess location={"product_metrics"} showNoAccessMessage>
      <Box sx={{ mb: 6 }}>
        <Box
          sx={{
            mb: 2,
            mt: 2,
            mx: 4,
          }}
        >
          <Typography variant="h1" sx={{ mb: 1 }}>
            {details.name}
          </Typography>
          <Typography>{details.help_text}</Typography>
        </Box>
        <Box
          sx={{
            mx: 4,
          }}
        >
          <LineChart
            dataset={data}
            xAxis={[
              {
                dataKey: "date",
                valueFormatter: (value) =>
                  format(
                    getDateFromDatabase(new Date(value as number)),
                    "EEE, MMM d, yyyy",
                  ),
              },
            ]}
            yAxis={[
              {
                valueFormatter: (value) =>
                  `${value}${details.type === "percent" ? "%" : ""}`,
              },
            ]}
            series={[
              {
                dataKey: "ios",
                showMark: false,
                label: "iOS",
                color: theme.palette.blue.main,
                valueFormatter: (value) =>
                  `${value}${details.type === "percent" ? "%" : ""}`,
              },
              {
                dataKey: "android",
                showMark: false,
                label: "Android",
                color: theme.palette.success.main,
                valueFormatter: (value) =>
                  `${value}${details.type === "percent" ? "%" : ""}`,
              },
            ]}
            height={400}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
            skipAnimation
          />
        </Box>
      </Box>
    </RestrictAccess>
  );
}
