import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { useAppDispatch } from "src/hooks/stateHooks";
import { updateClient } from "src/slices/clientSlice";

type Props = {
  userId: string;
  open: boolean;
  onClose: () => void;
};

export default function SuspendDialog({ userId, open, onClose }: Props) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [dateStart, setDateStart] = useState<null | Date>(null);
  const [dateEnd, setDateEnd] = useState<null | Date>(null);

  return (
    <Dialog
      onClose={() => {
        onClose();
      }}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleWithClose
        onClose={() => {
          onClose();
        }}
      >
        Suspend
      </DialogTitleWithClose>
      <DialogContent>
        <Typography sx={{ mb: 2 }}>
          The client will be hidden from their trainer&apos;s dash and
          won&apos;t be able to log into the app during their suspension.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DatePicker
              label="Start date"
              value={dateStart}
              onChange={(newValue) => {
                setDateStart(newValue ?? new Date());
              }}
              minDate={new Date()}
              maxDate={dateEnd ?? undefined}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label="End date"
              value={dateEnd}
              onChange={(newValue) => {
                setDateEnd(newValue ?? new Date());
              }}
              minDate={dateStart ?? new Date()}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            dispatch(
              updateClient({
                user_id: userId,
                // @ts-expect-error
                "account.membership.suspension": {
                  date_start: dateStart,
                  date_end: dateEnd,
                },
              }),
            );

            onClose();
          }}
        >
          Suspend
        </Button>
      </DialogActions>
    </Dialog>
  );
}
