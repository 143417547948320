import { Grid, Typography } from "@mui/material";
import {
  testAnalyticsInfo,
  type Test,
  type TestAnalyticsInfoItem,
  type TestAnalyticsSnapshot,
  type TestVariantAnalyticsItem,
} from "@trainwell/types";
import { SnapshotMetricDetails } from "./SnapshotMetricDetails";

type Props = {
  snapshots: TestAnalyticsSnapshot[];
  metricId: string;
  test: Test;
};

export function SnapshotMetricCell({ test, snapshots, metricId }: Props) {
  const metricDetails = testAnalyticsInfo[metricId] as TestAnalyticsInfoItem;

  return (
    <Grid container columns={test.variants.length + 1}>
      <Grid item xs={1} sx={{ p: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>
          {metricDetails.name}
        </Typography>
        <Typography variant="overline">{metricDetails.help_text}</Typography>
      </Grid>
      {test.variants.map((variant) => {
        const metrics = snapshots
          .map((snapshot) => {
            const data = snapshot.variants.find(
              (v) => v.variant_id === variant.id,
            )?.analytics[metricId] as TestVariantAnalyticsItem;

            return {
              date: snapshot.date as string,
              data: data,
            };
          })
          .sort((a, b) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
          });

        return (
          <Grid key={variant.id} item xs={1}>
            <SnapshotMetricDetails
              isControl={
                snapshots[0].variants.find((v) => v.variant_id === variant.id)
                  ?.is_control ?? false
              }
              metrics={metrics}
              details={metricDetails}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
