import type { WorkoutSection } from "@trainwell/types";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getAverage } from "src/lib/misc";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import { selectClientMaxes } from "src/slices/clientSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import {
  selectDenormalizedWorkout,
  updateSectionNotes,
} from "src/slices/workoutSlice";

export default function WorkoutBuilderHelper() {
  const dispatch = useAppDispatch();
  const workout = useAppSelector(selectDenormalizedWorkout);
  const clientGoal = useAppSelector((state) => state.client.client?.goal);
  const enableAutoSectionNotes = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.settings.enable_auto_section_notes,
  );
  const clientMaxes = useAppSelector(selectClientMaxes);

  useEffect(() => {
    const disable = true;

    if (disable) {
      return;
    }

    if (
      !workout ||
      !clientGoal ||
      clientGoal.goal_template_id !== "lose_weight" ||
      !clientGoal.progress_metric_ids_selected.includes("bodyweight")
    ) {
      return;
    }

    if (!enableAutoSectionNotes) {
      return;
    }

    const sections = workout.sections.filter(
      (section) => !section.is_placeholder,
    );

    let addedNote = false;

    const validSections: WorkoutSection[] = [];

    let sectionIndex = -1;
    for (const section of sections) {
      sectionIndex++;

      if (sectionIndex === 0) {
        validSections.push(section);
      } else {
        const mostRecentExercise =
          sections[sectionIndex - 1].cycles[0].exercises[
            sections[sectionIndex - 1].cycles[0].exercises.length - 1
          ];
        const mostRecentSet =
          mostRecentExercise.sets[mostRecentExercise.sets.length - 1];

        const firstSetThisSection = section.cycles
          .at(0)
          ?.exercises.at(0)
          ?.sets.at(0);
        const setbuffer = firstSetThisSection?.notes_trainer ? 30 : 15;

        if (mostRecentSet.rest_target >= setbuffer) {
          validSections.push(section);
        }
      }
    }

    validSections.sort((a, b) => {
      const scoreA = workoutLib.workouts.getWorkoutSectionDuration(a, {
        excludeRest: true,
      });
      const scoreB = workoutLib.workouts.getWorkoutSectionDuration(b, {
        excludeRest: true,
      });

      return scoreB - scoreA;
    });

    for (const section of validSections) {
      const cycle = section.cycles.at(0);

      if (!cycle) {
        continue;
      }

      const sets = cycle.exercises.map((exercise) => exercise.sets).flat();

      const averageRest = getAverage(sets.map((set) => set.rest_target));
      const averageIntensity = getAverage(
        cycle.exercises
          .map((exercise) =>
            exercise.sets.map((set) =>
              workoutLib.templates.getIntensityOfSet(
                exercise.exercise_master_id,
                set,
                clientMaxes,
              ),
            ),
          )
          .flat()
          .filter((v) => v !== undefined) as number[],
      );
      const cardioDuration = workoutLib.workouts.getWorkoutSectionDuration(
        section,
        {
          excludeRest: true,
          cardioOnly: true,
        },
      );

      console.log("cardio", cardioDuration);

      if (
        cycle.exercises.length >= 3 &&
        averageRest <= 20 &&
        averageIntensity >= 0.9
      ) {
        // HIIT

        const newNotes = `This ${section.section_name} section has short rests, high intensity, and lots of exercise variety, which is perfect for maximizing calorie burn!`;

        if (section.notes === newNotes) {
          addedNote = true;
        } else {
          dispatch(
            updateSectionNotes({
              sectionId: section.section_id,
              notes: newNotes,
            }),
          );

          addedNote = true;
        }

        break;
      } else if (cardioDuration >= 60 * 15) {
        // Long cardio

        const newNotes = `This ${section.section_name} section has ${Math.floor(
          cardioDuration / 60,
        )} minutes of cardio, which is the most efficient way to burn lots of calories!`;

        if (section.notes === newNotes) {
          addedNote = true;
        } else {
          dispatch(
            updateSectionNotes({
              sectionId: section.section_id,
              notes: newNotes,
            }),
          );

          addedNote = true;
        }

        break;
      }
    }

    if (!addedNote) {
      for (const section of sections) {
        if (section.notes) {
          dispatch(
            updateSectionNotes({
              sectionId: section.section_id,
              notes: null,
            }),
          );
        }
      }
    }
  }, [workout, dispatch]);

  return <></>;
}
