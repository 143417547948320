import { Typography } from "@mui/material";
import { Axis, LineSeries, Tooltip, XYChart } from "@visx/xychart";
import { useEffect, useState } from "react";

const accessors = {
  xAccessor: (d) => d.x,
  yAccessor: (d) => d.y,
};

let controlPressed = false;
let hoveredX = undefined;

export type Graph = {
  title: string;
  series: { inSet: boolean; dataPoints: { x: any; y: any }[] }[];
  minY: number;
  maxY: number;
  maxX: any;
  repXLocations: never[];
};

type Props = {
  graph: Graph;
  selectSetRange: (range: [number, number]) => void;
  deselectSetRange: (range: [number, number]) => void;
  toggleRep: (x: number) => void;
  showXAxis: boolean;
};

export function AxisGraph({
  graph,
  selectSetRange,
  deselectSetRange,
  toggleRep,
  showXAxis,
}: Props) {
  const [selectedX, setSelectedX] = useState(undefined);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (!hoveredX) {
        return;
      }

      if (event.key === "Meta" || event.key === "Control") {
        event.preventDefault();

        controlPressed = true;
      } else if (event.key === "r") {
        toggleRep(hoveredX);
      }
    };

    const keyUpHandler = (event) => {
      if (!hoveredX) {
        return;
      }

      if (event.key === "Meta" || event.key === "Control") {
        event.preventDefault();

        controlPressed = false;
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    document.addEventListener("keyup", keyUpHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
      document.removeEventListener("keyup", keyUpHandler);
    };
  }, [toggleRep]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 20,
        height: showXAxis ? "calc(8vh + 40px)" : "8vh",
        width: "90vw",
      }}
    >
      <Typography style={{ width: "5vw", textAlign: "center" }}>
        {graph.title}
      </Typography>
      <XYChart
        margin={{ top: 5, bottom: showXAxis ? 40 : 5, left: 30, right: 10 }}
        xScale={{ type: "band" }}
        yScale={{
          type: "linear",
          domain: [Math.min(graph.minY, -1), Math.max(graph.maxY, 1)],
        }}
        onPointerDown={(e: any) => {
          if (selectedX) {
            if (controlPressed) {
              deselectSetRange([selectedX, e.datum.x]);
            } else {
              selectSetRange([selectedX, e.datum.x]);
            }

            setSelectedX(undefined);
          } else {
            setSelectedX(e.datum.x);
          }
        }}
        onPointerMove={(e: any) => {
          hoveredX = e.datum.x;
        }}
        onPointerOut={() => {
          hoveredX = undefined;
        }}
      >
        <Axis
          orientation="bottom"
          tickValues={
            showXAxis
              ? Array.from(Array(Math.round(graph.maxX)).keys()).filter(
                  (x) => x % 2 === 0,
                )
              : []
          }
        />
        <Axis
          orientation="left"
          tickValues={[Math.min(graph.minY, -1), 0, Math.max(graph.maxY, 1)]}
          numTicks={3}
        />
        {selectedX && (
          <LineSeries
            data={[
              { x: selectedX, y: graph.minY },
              { x: selectedX, y: graph.maxY },
            ]}
            colorAccessor={() => "blue"}
            {...accessors}
            dataKey="x-line"
          />
        )}
        {graph.series.map((series, i) => (
          <LineSeries
            key={i}
            dataKey={i.toString()}
            colorAccessor={(d) => (Number(d) % 2 === 0 ? "black" : "green")}
            data={series.dataPoints}
            {...accessors}
          />
        ))}
        {graph.repXLocations.map((x, i) => (
          <LineSeries
            opacity={0.4}
            dataKey={"r" + i}
            key={"r" + i}
            data={[
              { x: x, y: graph.minY },
              { x: x, y: graph.maxY },
            ]}
            colorAccessor={() => "red"}
            {...accessors}
          />
        ))}
        <Tooltip
          showVerticalCrosshair
          renderTooltip={({ tooltipData }) => (
            <div>
              {Math.round(
                accessors.yAccessor(tooltipData?.nearestDatum?.datum) * 100,
              ) / 100.0}
              <div />
              {Math.round(
                accessors.xAccessor(tooltipData?.nearestDatum?.datum) * 10,
              ) / 10.0}
              s
            </div>
          )}
        />
      </XYChart>
    </div>
  );
}
