import type { DraggableAttributes } from "@dnd-kit/core";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import {
  Box,
  ButtonBase,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import debounce from "lodash-es/debounce";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  fetchAllMessages,
  fetchChats,
  selectSelectedChat,
  toggleChatFlyout,
} from "src/slices/chatSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import getTheme from "src/theme/theme";
import type { ThemeMode } from "src/theme/themeModes";
import ChatList from "./ChatList";
import { ChatListHeader } from "./ChatListHeader";
import ClientChat from "./ClientChat";
import { ClientChatHeader } from "./ClientChatHeader";
import MassMessageView from "./MassMessageView";
import { ChatFlyout } from "./flyout/ChatFlyout";
import { ThreadsList } from "./threads/ThreadsList";

type Props = {
  dragAttributes?: DraggableAttributes;
  dragListeners?: SyntheticListenerMap;
};

export default function ChatBar({ dragAttributes, dragListeners }: Props) {
  const dispatch = useAppDispatch();
  const clientsStatus = useAppSelector((state) => state.clients.status);
  const chatThemeMode = useAppSelector(
    (state) => state.trainer.trainer?.settings?.theme_chat ?? "light",
  ) as ThemeMode;
  const chatTheme = useMemo(() => getTheme(chatThemeMode), [chatThemeMode]);
  const selectedChatId = useAppSelector((state) => state.chat.selectedChatId);
  const focusedUserId = useAppSelector((state) => state.chat.focusedUserId);
  const isSelectingChats = useAppSelector(
    (state) => state.chat.isSelectingChats,
  );
  const chatView = useAppSelector((state) => state.chat.view);
  const isGroupChat = useAppSelector(
    (state) => selectSelectedChat(state)?.isGroupChat,
  );
  let flyoutOpen = useAppSelector((state) => state.chat.flyoutOpen);
  const chatVisualState = useAppSelector((state) => state.chat.chatMode);
  const expanded =
    chatVisualState === "drag_expanded" ||
    chatVisualState === "big_left" ||
    chatVisualState === "big_right" ||
    chatVisualState === "full";
  const scrollRef = useRef<HTMLDivElement>();
  const coachUserId = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.user_id,
  );
  const dashMode = useAppSelector((state) => state.app.dashMode);

  flyoutOpen = chatVisualState !== "drag_expanded" ? false : flyoutOpen;

  useEffect(() => {
    if (clientsStatus === "succeeded") {
      dispatch(fetchChats());
      dispatch(fetchAllMessages());
    }
  }, [dispatch, clientsStatus]);

  useEffect(() => {
    if (!selectedChatId && scrollRef.current) {
      const scrollIndex =
        "current_client_index" in sessionStorage
          ? JSON.parse(sessionStorage.getItem("current_chat_scroll")!)
          : 0;

      scrollRef.current.scrollTop = scrollIndex;
    }
  }, [selectedChatId]);

  function changeHandler() {
    if (selectedChatId) {
      return;
    }

    sessionStorage.setItem(
      "current_chat_scroll",
      JSON.stringify(scrollRef.current?.scrollTop ?? 0),
    );
  }

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 200), [
    selectedChatId,
  ]);

  const fullChatHeight = isSelectingChats ? "650px" : "600px";

  if (!coachUserId || dashMode === "programming") {
    return null;
  }

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height:
          chatVisualState === "full"
            ? "100vh"
            : chatVisualState === "drag_expanded"
              ? fullChatHeight
              : chatVisualState === "drag_minimized"
                ? undefined
                : "calc(100vh - 38px)",
        maxHeight:
          chatVisualState === "full" ? "-webkit-fill-available" : undefined,
        transitionProperty: "height width",
        transitionDuration: "0.1s",
        transitionTimingFunction: "ease-in-out",
      }}
    >
      {selectedChatId ? (
        <ClientChatHeader
          dragAttributes={dragAttributes}
          dragListeners={dragListeners}
        />
      ) : (
        <ChatListHeader
          dragAttributes={dragAttributes}
          dragListeners={dragListeners}
        />
      )}
      <Box
        sx={{
          overflowY: "auto",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          backgroundColor: (theme) =>
            !selectedChatId
              ? theme.palette.background.paper
              : theme.palette.background.default,
        }}
        ref={scrollRef}
        onScrollCapture={() => {
          debouncedChangeHandler();
        }}
      >
        {expanded && (
          <>
            {chatView === "default" ? (
              <>
                {!selectedChatId ? (
                  <ChatList />
                ) : (
                  <ClientChat key={selectedChatId} />
                )}
              </>
            ) : chatView === "threads" ? (
              <ThreadsList />
            ) : (
              <MassMessageView />
            )}
          </>
        )}
      </Box>
    </Box>
  );

  if (chatVisualState === "big_left" || chatVisualState === "big_right") {
    return (
      <ThemeProvider theme={chatTheme}>
        <Paper
          sx={{
            height: "calc(100vh - 38px)",
            // marginTop: expanded ? "-450px" : "0px",
            marginTop: "38px",
            background: (theme) => theme.palette.background.default,
            borderLeft: (theme) => "1px solid " + theme.palette.divider,
            // borderRadius: "10px 10px 10px 10px",
            overflow: "hidden",
            zIndex: 1260,
            borderRadius: 0,
          }}
          elevation={0}
        >
          {content}
        </Paper>
      </ThemeProvider>
    );
  } else if (chatVisualState === "full") {
    return (
      <ThemeProvider theme={chatTheme}>
        <Paper
          sx={{
            width: "100%",
            height: "100vh",
            maxHeight: "-webkit-fill-available;",
            background: (theme) => theme.palette.background.default,
            overflow: "hidden",
            zIndex: 1260,
          }}
          elevation={0}
        >
          {content}
        </Paper>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={chatTheme}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              borderRight: flyoutOpen ? 1 : 0,
              borderRightColor: "divider",
            }}
          >
            <ButtonBase
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                alignItems: "center",
                display: "flex",
                borderRight: 1,
                borderRightColor: "divider",
              }}
              onClick={() => {
                dispatch(toggleChatFlyout());
              }}
            >
              {flyoutOpen ? (
                <KeyboardDoubleArrowRightRoundedIcon fontSize="small" />
              ) : (
                <KeyboardDoubleArrowLeftRoundedIcon fontSize="small" />
              )}
            </ButtonBase>
            <Box
              sx={{
                transitionProperty: "width",
                transitionDuration: "0.1s",
                transitionTimingFunction: "ease-in-out",
                width: flyoutOpen ? "300px" : "0px",
                height: flyoutOpen
                  ? chatVisualState === "drag_expanded"
                    ? fullChatHeight
                    : chatVisualState === "drag_minimized"
                      ? "0px"
                      : "calc(100vh - 38px)"
                  : "0px",
                overflowY: "auto",
                backgroundColor: (theme) => theme.palette.background.paper,
              }}
            >
              {flyoutOpen && (
                <>
                  {!(selectedChatId || focusedUserId) || isGroupChat ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Typography sx={{ textAlign: "center" }}>
                        No client selected
                      </Typography>
                    </Box>
                  ) : (
                    <ChatFlyout
                      userId={selectedChatId || focusedUserId || ""}
                    />
                  )}
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              transitionProperty: "width",
              transitionDuration: "0.1s",
              transitionTimingFunction: "ease-in-out",
              width:
                chatView === "threads" || chatView === "mass_message"
                  ? "500px"
                  : "400px",
            }}
          >
            {content}
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}
