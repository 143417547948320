export function getWeightUnit(system: "imperial" | "metric"): string {
  if (system === "metric") {
    return "kg";
  } else {
    return "lbs";
  }
}

export function roundedDecimal(num: number) {
  if (num < 100) {
    return num.toPrecision(2);
  } else {
    return Math.round(num).toString();
  }
}

//========================================
// BEGIN GABE SECTION
//========================================

/**
 * Flatten a muly dimensional array to be one less dimension
 *
 * @param a
 * @returns
 */
export function flattenArray<T>(a: T[][]): T[] {
  const a_flat: T[] = a.reduce(
    (accumulator, value) => accumulator.concat(value),
    [],
  );
  return a_flat;
}

/**
 * Copy a workout object by converting to json and then parsing json
 *
 * @param obj
 * @returns
 */
export function copyWorkoutObject<WorkoutObject>(
  obj: WorkoutObject,
): WorkoutObject {
  const copy: WorkoutObject = JSON.parse(JSON.stringify(obj));
  return copy;
}
