import { getAuth } from "firebase/auth";
import { lazy, useEffect, useState } from "react";
import RestrictAccess from "src/components/misc/RestrictAccess";
import { TRAINWELL_API_KEY } from "src/config/config";
import { api } from "src/lib/trainwellApi";
import "swagger-ui-react/swagger-ui.css";
const SwaggerUI = lazy(() => import("swagger-ui-react"));

export default function DocsPage() {
  const [apiSpec, setApiSpec] = useState<any>();

  useEffect(() => {
    async function run() {
      const specs = await api.docs.getNodejs();

      setApiSpec(specs);
    }

    run();
  }, []);

  return (
    <RestrictAccess location={"docs"} showNoAccessMessage>
      <SwaggerUI
        spec={apiSpec}
        onComplete={async (ui) => {
          ui.preauthorizeApiKey("apiKey", TRAINWELL_API_KEY);

          const auth = getAuth();

          const token = await auth.currentUser?.getIdToken();

          ui.preauthorizeApiKey("bearerAuth", token);
        }}
        requestInterceptor={(req) => {
          req["headers"]["accept-version"] = "2023-08-02";

          return req;
        }}
        defaultModelExpandDepth={4}
        defaultModelsExpandDepth={4}
        defaultModelRendering="model"
      />
    </RestrictAccess>
  );
}
