import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DoorBackRoundedIcon from "@mui/icons-material/DoorBackRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import type { Trainer } from "@trainwell/types";
import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import RestrictAccess from "src/components/misc/RestrictAccess";
import SearchField from "src/components/misc/SearchField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { fetchAllTrainers } from "src/slices/trainersSlice";
import CoachCell from "./CoachCell";
import InviteCandidateDialog from "./InviteCandidateDialog";

const teamFilters = {
  trainers: "Trainers",
  staff: "Staff",
  former: "Former",
};

type TeamFilterType = keyof typeof teamFilters;

const drawerWidth = 240;

type Props = {
  type: "employees" | "candidates";
};

export function CoachLayout({ type }: Props) {
  const { trainerId } = useParams();
  const dispatch = useAppDispatch();
  const [selectedTeamFilters, setSelectedTeamFilters] = useState<
    TeamFilterType[]
  >(["trainers"]);
  const coaches = useAppSelector(
    (state) =>
      state.trainers.trainers.filter(
        (trainer) =>
          trainer.is_employee ||
          trainer.was_employee ||
          trainer.is_candidate ||
          trainer.is_staff,
      ),
    shallowEqual,
  );
  const [displayedCoaches, setDisplayedCoaches] = useState<Trainer[]>([]);
  const [search, setSearch] = useState("");
  const coachesStatus = useAppSelector((state) => state.trainers.status);
  const currentCoach = useAppSelector(selectPrimaryTrainer);
  const [candidateDialogOpen, setCandidateDialogOpen] = useState(false);

  let possibleTeamFilters = Object.keys(teamFilters) as TeamFilterType[];

  if (!trainerHasAccess(currentCoach?.access_roles, "access_roles")) {
    possibleTeamFilters = possibleTeamFilters.filter(
      (filter) => filter !== "staff",
    );
  }

  useEffect(() => {
    if (coachesStatus === "idle") {
      dispatch(fetchAllTrainers());
    }
  }, [coachesStatus, dispatch]);

  useEffect(() => {
    sortCoaches();
  }, [search, selectedTeamFilters, coaches, type]);

  function sortCoaches() {
    let newCoaches = JSON.parse(JSON.stringify(coaches)) as Trainer[];

    if (type === "candidates") {
      newCoaches = newCoaches.filter((trainer) => trainer.is_candidate);
    } else if (type === "employees") {
      const coachPool = newCoaches.filter((trainer) => !trainer.is_candidate);
      newCoaches = [];

      if (selectedTeamFilters.includes("trainers")) {
        newCoaches = [
          ...newCoaches,
          ...coachPool.filter(
            (trainer) => !trainer.was_employee && trainer.is_employee,
          ),
        ];
      }

      if (selectedTeamFilters.includes("staff")) {
        newCoaches = [
          ...newCoaches,
          ...coachPool.filter(
            (trainer) => trainer.is_staff && !trainer.was_employee,
          ),
        ];
      }

      if (selectedTeamFilters.includes("former")) {
        newCoaches = [
          ...newCoaches,
          ...coachPool.filter(
            (trainer) => trainer.was_employee && !trainer.is_employee,
          ),
        ];
      }

      newCoaches = newCoaches.filter(
        (trainer, index, self) =>
          index === self.findIndex((c) => c.trainer_id === trainer.trainer_id),
      );
    }

    if (search !== "") {
      newCoaches = newCoaches.filter(
        (trainer) =>
          trainer.full_name!.toLowerCase().indexOf(search.toLowerCase()) !== -1,
      );
    }

    newCoaches.sort((a, b) => {
      return a.full_name > b.full_name ? 1 : -1;
    });

    newCoaches.sort((a, b) => {
      if (a.is_employee && !b.is_employee) {
        return -1;
      } else if (!a.is_employee && b.is_employee) {
        return 1;
      }
      return 0;
    });

    setDisplayedCoaches(newCoaches);
  }

  return (
    <RestrictAccess location={"coach_list"} showNoAccessMessage>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Paper
          square
          elevation={0}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            maxHeight: "100%",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column",
            borderRight: 1,
            borderColor: "divider",
          }}
        >
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
              px: 2,
              py: 1,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Typography variant="h1">
              {type === "candidates" ? "Candidates" : "Team"}
            </Typography>
            <Typography variant="overline">
              Showing {(displayedCoaches ?? []).length}{" "}
              {type === "candidates" ? "candidates" : "members"}
            </Typography>
          </Box>
          <Box sx={{ overflowY: "auto", flex: 1 }}>
            <Box
              sx={{
                p: 1,
                backgroundColor: (theme) => theme.palette.background.paper,
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <SearchField
                value={search}
                onChange={(value) => {
                  setSearch(value);
                }}
                onClear={() => {
                  setSearch("");
                }}
                sx={{ mb: type !== "candidates" ? 1 : undefined }}
              />
              {type !== "candidates" && (
                <Grid container spacing={0.5}>
                  {possibleTeamFilters.map((key, i) => {
                    return (
                      <Grid item key={key}>
                        <Chip
                          key={i}
                          label={teamFilters[key]}
                          onClick={() => {
                            const newSelectedBodyParts = [
                              ...selectedTeamFilters,
                            ];
                            const index = newSelectedBodyParts.indexOf(key);

                            if (index === -1) {
                              newSelectedBodyParts.push(key);
                            } else {
                              newSelectedBodyParts.splice(index, 1);
                            }

                            setSelectedTeamFilters(newSelectedBodyParts);
                          }}
                          variant={
                            !selectedTeamFilters.includes(key)
                              ? "outlined"
                              : "filled"
                          }
                          color={
                            !selectedTeamFilters.includes(key)
                              ? "default"
                              : "blueSurface"
                          }
                          size="small"
                          icon={
                            key === "trainers" ? (
                              <FitnessCenterRoundedIcon />
                            ) : key === "former" ? (
                              <DoorBackRoundedIcon />
                            ) : (
                              <PeopleRoundedIcon />
                            )
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Box
                sx={{
                  borderStyle: "dashed",
                  borderWidth: "2px",
                  borderRadius: 1,
                  borderColor: (theme) => theme.palette.divider,
                  m: 1,
                }}
              >
                <Button
                  fullWidth
                  variant="text"
                  startIcon={<AddRoundedIcon />}
                  onClick={() => {
                    setCandidateDialogOpen(true);
                  }}
                >
                  Invite candidate
                </Button>
              </Box>
            </Box>
            <Stack direction="column">
              {displayedCoaches.map((trainer) => (
                <CoachCell
                  key={trainer.trainer_id}
                  trainerId={trainer.trainer_id}
                  isSelected={trainer.trainer_id === trainerId}
                  type={type}
                />
              ))}
            </Stack>
          </Box>
        </Paper>
        <Box sx={{ flexGrow: 1, overflowX: "hidden" }}>
          <Outlet />
        </Box>
      </Box>
      <InviteCandidateDialog
        open={candidateDialogOpen}
        onClose={() => {
          setCandidateDialogOpen(false);
        }}
      />
    </RestrictAccess>
  );
}
