import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from "@mui/material";
import {
  isBlank,
  useInfluencer,
  useUpdateCampaign,
  type Campaign,
  type CampaignClient,
} from "@trainwell/features";
import { type Client } from "@trainwell/types";
import { useEffect, useState } from "react";
import { api } from "src/lib/trainwellApi";

type CampaignClientData = Pick<
  CampaignClient,
  "is_on_screen" | "is_testimonial" | "user_id"
>;

const blankCampaignClientData: CampaignClientData = {
  is_on_screen: false,
  is_testimonial: false,
};

type Props = {
  influencerId: string;
  campaign: Campaign;
  defaultCampaignClient?: CampaignClient;
  open: boolean;
  onClose: () => void;
};

export function CampaignClientEditDialog({
  influencerId,
  campaign,
  defaultCampaignClient,
  open,
  onClose,
}: Props) {
  const { data: influencer } = useInfluencer({
    influencerId: influencerId,
  });
  const [clientOptions, setClientOptions] = useState<Client[] | null>(null);
  const updateCampaignMutation = useUpdateCampaign();

  const [campaignClientData, setCampaignClientData] =
    useState<CampaignClientData>(
      defaultCampaignClient ?? blankCampaignClientData,
    );

  useEffect(() => {
    const validUsers = (influencer?.users ?? []).filter((u) =>
      Boolean(u.user_id),
    );

    if (open && clientOptions === null && validUsers.length > 0) {
      Promise.all(
        validUsers.map((u) => api.clients.getOne(u.user_id ?? "")),
      )?.then((clients) => {
        setClientOptions(clients);
      });
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setCampaignClientData(defaultCampaignClient ?? blankCampaignClientData);
      setClientOptions(null);
    }
  }, [open, defaultCampaignClient]);

  const handleChangeUserData = (data: Partial<CampaignClientData>) => {
    setCampaignClientData({ ...campaignClientData, ...data });
  };

  const isDataValid =
    defaultCampaignClient || !isBlank(campaignClientData.user_id);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {defaultCampaignClient ? "Edit" : "Create"} campaign client
      </DialogTitle>
      <DialogContent>
        <Stack direction={"column"} spacing={2} sx={{ mt: 2 }}>
          <Autocomplete
            fullWidth
            value={campaignClientData.user_id ?? null}
            options={(clientOptions ?? []).map((client) => client.user_id)}
            onChange={(event, value) => {
              handleChangeUserData({
                user_id: value ?? undefined,
              });
            }}
            getOptionLabel={(option) =>
              clientOptions?.find((c) => c.user_id === option)?.full_name ?? ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Client"
                placeholder="Select a client"
              />
            )}
            renderOption={(props, userId) => {
              const client = clientOptions?.find((c) => c.user_id === userId);
              return (
                <li {...props}>
                  <Box sx={{ width: 24, height: 24, mr: 1 }}>
                    {client?.headshot_url && (
                      <img
                        src={client?.headshot_url}
                        width={24}
                        height={24}
                        style={{
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </Box>
                  {client?.full_name}
                </li>
              );
            }}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={campaignClientData.is_on_screen ?? false}
                  onChange={(event) => {
                    handleChangeUserData({
                      is_on_screen: event.target.checked,
                    });
                  }}
                />
              }
              label="On screen"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={campaignClientData.is_testimonial ?? false}
                  onChange={(event) => {
                    handleChangeUserData({
                      is_testimonial: event.target.checked,
                    });
                  }}
                />
              }
              label="Testimonial"
            />
          </FormGroup>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!isDataValid}
          onClick={async () => {
            const users = [...(campaign?.users ?? [])].filter(
              (u) => u.user_id !== defaultCampaignClient?.user_id,
            );

            const newUser: CampaignClient = {
              ...defaultCampaignClient,
              ...campaignClientData,
            };

            users.push(newUser);

            updateCampaignMutation.mutate({
              campaignId: campaign._id,
              data: {
                users,
              },
            });

            onClose();
          }}
        >
          {defaultCampaignClient ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
