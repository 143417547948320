import {
  DndContext,
  MouseSensor,
  closestCenter,
  pointerWithin,
  useSensor,
  useSensors,
  type CollisionDetection,
} from "@dnd-kit/core";
import { memo, useCallback } from "react";
import { useAppDispatch } from "src/hooks/stateHooks";
import {
  handleDragEndClientPage,
  handleDragOverClientPage,
  setCurrentDraggingInfo,
  setTransferInfo,
  type DraggableDroppableTypeClient,
} from "src/slices/clientSlice";

type Props = {
  children: React.ReactNode;
};

export const ClientLayoutDndContext = memo(function ClientLayoutDndContext({
  children,
}: Props) {
  const dispatch = useAppDispatch();
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 4,
      },
    }),
  );

  const collisionDetectionStrategy: CollisionDetection = useCallback((args) => {
    const activeType = args.active.data.current
      ?.type as DraggableDroppableTypeClient;

    let validDroppableContainers = args.droppableContainers;

    if (activeType === "phase") {
      validDroppableContainers = validDroppableContainers.filter(
        (c) => (c.data.current?.type as DraggableDroppableTypeClient) === "day",
      );
    } else if (
      activeType === "workout_task_past" ||
      activeType === "workout_task"
    ) {
      validDroppableContainers = validDroppableContainers.filter(
        (c) =>
          (c.data.current?.type as DraggableDroppableTypeClient) === "day" ||
          (c.data.current?.type as DraggableDroppableTypeClient) ===
            "workout_task" ||
          (c.data.current?.type as DraggableDroppableTypeClient) ===
            "phase_day" ||
          (c.data.current?.type as DraggableDroppableTypeClient) ===
            "phase_workout",
      );

      if ((args.active.data.current?.phaseType as string) === "multiple") {
        validDroppableContainers = validDroppableContainers.filter(
          (c) =>
            (c.data.current?.type as DraggableDroppableTypeClient) !==
              "phase_day" &&
            (c.data.current?.type as DraggableDroppableTypeClient) !==
              "phase_workout",
        );
      }
    } else if (activeType === "phase_workout") {
      validDroppableContainers = validDroppableContainers.filter(
        (c) =>
          (c.data.current?.type as DraggableDroppableTypeClient) ===
            "phase_day" ||
          (c.data.current?.type as DraggableDroppableTypeClient) ===
            "phase_workout" ||
          (c.data.current?.type as DraggableDroppableTypeClient) ===
            "phase_workout_panel",
      );
    } else if (activeType === "workout") {
      validDroppableContainers = validDroppableContainers.filter(
        (c) =>
          (c.data.current?.type as DraggableDroppableTypeClient) === "day" ||
          (c.data.current?.type as DraggableDroppableTypeClient) ===
            "phase_workout" ||
          (c.data.current?.type as DraggableDroppableTypeClient) ===
            "workout_task" ||
          (c.data.current?.type as DraggableDroppableTypeClient) ===
            "workout_task_past" ||
          (c.data.current?.type as DraggableDroppableTypeClient) ===
            "phase_day",
      );
    } else if (activeType === "phase_day") {
      validDroppableContainers = validDroppableContainers.filter(
        (c) =>
          (c.data.current?.type as DraggableDroppableTypeClient) ===
            "phase_day" && c.id !== "new_day",
      );
    }

    // Phase template days
    if (activeType === "phase_day") {
      return closestCenter({
        ...args,
        droppableContainers: validDroppableContainers,
      });
    }

    return pointerWithin({
      ...args,
      droppableContainers: validDroppableContainers,
    });
  }, []);

  const onDragStart = useCallback(({ active }) => {
    console.log("DND: drag start");

    dispatch(
      setCurrentDraggingInfo({
        originalType: active.data.current?.type,
        originalWeekPlanId: active.data.current?.weekPlanId,
        originalDayIndex: active.data.current?.dayIndex,
        originalHabitWeekId: active.data.current?.habitWeekId,
        originalIndex: active.data.current?.index,
        id: active.id.toString(),
        phaseId: active.data.current?.phaseId,
        workoutId: active.data.current?.workoutId,
      }),
    );
  }, []);

  return (
    <DndContext
      collisionDetection={collisionDetectionStrategy}
      // measuring={{
      //   droppable: {
      //     strategy: MeasuringStrategy.,
      //   },
      // }}
      sensors={sensors}
      onDragStart={onDragStart}
      onDragOver={(event) => {
        dispatch(handleDragOverClientPage(event));
      }}
      onDragEnd={(update) => {
        dispatch(handleDragEndClientPage(update))
          .unwrap()
          .then((d) => {
            if (d && "transfer" in d) {
              dispatch(setTransferInfo(d));
            }
          });

        dispatch(setCurrentDraggingInfo(null));
      }}
    >
      {children}
    </DndContext>
  );
});
