import { Box, Divider, Stack, Typography } from "@mui/material";
import { type TestEvent } from "@trainwell/types";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { selectTestById } from "src/slices/testsSlice";

export function TestEvents() {
  const { testId } = useParams();
  const test = useAppSelector((state) => selectTestById(state, testId ?? ""));
  const [events, setEvents] = useState<TestEvent[]>([]);

  useEffect(() => {
    if (!testId) {
      return;
    }

    setEvents([]);

    api.tests.getEvents(testId).then((data) => {
      const sortedEvents = data.test_events.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });

      setEvents(sortedEvents);
    });
  }, [testId]);

  if (!test) {
    return <Typography>Error finding test</Typography>;
  }

  return (
    <Stack spacing={1} divider={<Divider />}>
      {events.map((event) => (
        <Box key={event._id}>
          <Typography sx={{ fontWeight: "bold" }}>{event.type}</Typography>
          <Typography variant="overline">
            {format(event.date, "MMMM do, yyyy h:mm aaa")}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
}
