import SortRoundedIcon from "@mui/icons-material/SortRounded";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";

export type ShortUrlSort = "newest" | "oldest" | "clicks";

const shortUrlSortOptions: { id: ShortUrlSort; name: string }[] = [
  {
    id: "newest",
    name: "Newest",
  },
  {
    id: "oldest",
    name: "Oldest",
  },
  {
    id: "clicks",
    name: "Clicks",
  },
];

type Props = {
  value: ShortUrlSort;
  onChange: (sort: ShortUrlSort) => void;
  size?: "small" | "medium";
};

export default function ShortUrlSortButton({ value, onChange, size }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title="Sort" enterDelay={1000}>
        <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          size={size}
        >
          <SortRoundedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {shortUrlSortOptions.map((sortOption) => (
          <MenuItem
            onClick={() => {
              onChange(sortOption.id);
              setAnchorEl(null);
            }}
            key={sortOption.id}
            selected={value === sortOption.id}
          >
            {sortOption.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
