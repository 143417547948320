import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  useUpdateCampaign,
  type Campaign,
  type CampaignClient,
} from "@trainwell/features";
import type { Client } from "@trainwell/types";
import { useEffect, useState } from "react";
import { api } from "src/lib/trainwellApi";
import { CampaignClientEditDialog } from "./CampaignClientEditDialog";

type Props = {
  influencerId: string;
  campaign: Campaign;
  campaignUser: CampaignClient;
};

export function CampaignClientCell({
  influencerId,
  campaign,
  campaignUser,
}: Props) {
  const updateCampaignMutation = useUpdateCampaign();
  const [client, setClient] = useState<null | Client | undefined | "error">();
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  useEffect(() => {
    if (campaignUser.user_id) {
      api.clients
        .getOne(campaignUser.user_id)
        .then((data) => {
          setClient(data);
        })
        .catch(() => {
          setClient("error");
        });
    } else {
      setClient(null);
    }
  }, [campaignUser.user_id]);

  if (client === "error") {
    return <Typography>Error finding client</Typography>;
  } else if (client === undefined) {
    return <CircularProgress />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {client?.headshot_url && (
            <Box sx={{ mr: 1 }}>
              <img
                src={client.headshot_url}
                alt={client.full_name}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  objectFit: "cover",
                  display: "block",
                }}
              />
            </Box>
          )}
          <Box>
            <Typography sx={{ mb: 0.5 }}>
              {client?.full_name ?? "Not signed up yet"}
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {campaignUser.is_on_screen && (
                <Chip size="small" label="On screen" />
              )}
              {campaignUser.is_testimonial && (
                <Chip size="small" label="Testimonial" />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <IconButton
        size="small"
        onClick={(event) => {
          setMoreAnchorEl(event.currentTarget);
        }}
      >
        <MoreVertRoundedIcon fontSize="inherit" />
      </IconButton>
      <CampaignClientEditDialog
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
        }}
        influencerId={influencerId}
        campaign={campaign}
        defaultCampaignClient={campaignUser}
      />
      <Menu
        open={Boolean(moreAnchorEl)}
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setMoreAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            setEditDialogOpen(true);

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <EditRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <Box>
          <Divider sx={{ mb: 1, mt: 2 }} />
        </Box>
        <MenuItem
          onClick={async () => {
            const users = [...(campaign.users ?? [])].filter(
              (u) => u.user_id !== campaignUser.user_id,
            );

            updateCampaignMutation.mutate({
              campaignId: campaign._id,
              data: {
                users,
              },
            });

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <DeleteRoundedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            sx={{ color: (theme) => theme.palette.error.main }}
          />
        </MenuItem>
      </Menu>
    </Box>
  );
}
