import {
  DragOverlay,
  defaultDropAnimationSideEffects,
  type DropAnimation,
} from "@dnd-kit/core";
import { createPortal } from "react-dom";
import { useAppSelector } from "src/hooks/stateHooks";
import { snapCornerToCursor } from "../TemplateLibrary/dnd/snapCornerToCursor";
import { PhaseDragOverlay } from "./PhaseDragOverlay";

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

export default function ClientDragOverlay() {
  const activeData = useAppSelector(
    (state) => state.client.currentDraggingInfo,
  );

  return (
    <>
      {createPortal(
        <DragOverlay
          dropAnimation={dropAnimation}
          zIndex={9999}
          modifiers={[snapCornerToCursor]}
        >
          {activeData?.originalType === "phase" ? (
            <PhaseDragOverlay phaseId={activeData.phaseId} />
          ) : activeData?.originalType === "workout" ||
            activeData?.originalType === "workout_task" ||
            activeData?.originalType === "phase_workout" ||
            activeData?.originalType === "workout_task_past" ? (
            <PhaseDragOverlay workoutId={activeData.workoutId} />
          ) : null}
        </DragOverlay>,
        document.body,
      )}
    </>
  );
}
