import type { Graph } from "../AxisGraph";

//Applies a selected set range to a file and returns the updated file
export function applySetRange(range, file, val) {
  if (range.length !== 2) {
    console.log("Invalid Range: Must have length 2", range.length);
    return file;
  }

  const sortedRange = range.sort((a, b) => a - b);

  const xVals = file.data.index;

  const startIndex = xVals.indexOf(sortedRange[0]);
  const endIndex = xVals.indexOf(range[1]);

  if (startIndex === -1 || endIndex === -1) {
    console.log("Invalid Range: Range values are not present in data index");
    return file;
  }

  //Loop through set array and set to appropriate value, inclusive of endpoints
  for (let i = startIndex; i <= endIndex; i += 1) {
    file.data.sets[i] = val;
  }

  return file;
}

export function clearSets(file) {
  for (let i = 0; i < file.data.sets.length; i += 1) {
    file.data.sets[i] = false;
  }

  return file;
}

export function clearReps(file) {
  for (let i = 0; i < file.data.reps.length; i += 1) {
    file.data.reps[i] = false;
  }

  file.collection.n_reps = 0;

  return file;
}

//toggles the presence of a rep around the provided xLocation in the provided file and returns the updated file
export function toggleRep(xValue, file) {
  const searchWindow = 20; //Samples

  const xVals = file.data.index;
  const index = xVals.indexOf(xValue);

  if (index === -1) {
    console.log("Invalid xValue: Value is not present in data index");
    return file;
  }

  const lowSearchIndex = Math.max(0, index - searchWindow / 2);
  const highSearchIndex = Math.min(index + searchWindow / 2, xVals.length - 1);

  let foundRep = false;

  //Look through the search range for a rep. If we find one, remove it and mark foundRep
  for (let i = lowSearchIndex; i < highSearchIndex; i += 1) {
    if (file.data.reps[i]) {
      file.data.reps[i] = false;
      foundRep = true;
      break;
    }
  }

  //If we didn't find a rep, place a rep
  if (!foundRep) {
    file.data.reps[index] = true;
  }

  file.collection.n_reps = Math.max(
    0,
    file.data.reps.filter(Boolean).length - 1,
  );

  return file;
}

export function graphsFromData(sensorData: any) {
  if (!sensorData || sensorData === null) {
    console.log("Invalid File: Null or undefined");
    return null;
  }

  if (
    !(
      "values" in sensorData &&
      "columns" in sensorData &&
      "index" in sensorData &&
      "sets" in sensorData
    )
  ) {
    console.log("Malformed File");
    return null;
  }

  const numRows = sensorData.values.length;
  const numColumnLabels = sensorData.columns.length;
  const numColumns = sensorData.values.map((x) => x.length);
  const row0NumColumns = numColumns[0];

  //All columns must have the same number of rows
  for (let i = 1; i < numColumns.length; i += 1) {
    if (numColumns[i] !== row0NumColumns) {
      console.log(
        "Invalid File: All data rows must have the same number of columns",
        numColumns[i],
        row0NumColumns,
      );
      return null;
    }
    if (numColumns[0] !== numColumnLabels) {
      console.log(
        "Invalid File: All data rows must have the same number of columns as there are column labels",
        numColumns[i],
        numColumnLabels,
      );
      return null;
    }
  }

  //There must be the same amount of indices as value rows
  const numIndices = sensorData.index.length;
  if (
    numIndices !== numRows ||
    sensorData.sets.length !== numRows ||
    sensorData.reps.length !== numRows
  ) {
    console.log(
      "Invalid File: Number of indices, sets, and reps does not match number of data rows",
      numIndices,
      numRows,
    );
    return null;
  }

  const repXLocations = [];
  for (let rowIndex = 0; rowIndex < numRows; rowIndex += 1) {
    if (sensorData.reps[rowIndex]) {
      // @ts-expect-error
      repXLocations.push(sensorData.index[rowIndex]);
    }
  }

  const graphs: Graph[] = [];

  for (
    let columnIndex = 0;
    columnIndex < Math.min(9, row0NumColumns);
    columnIndex += 1
  ) {
    let dataPoints: { x: any; y: any }[] = [];
    const series: { inSet: boolean; dataPoints: { x: any; y: any }[] }[] = [];
    let minY = 0;
    let maxY = 0;
    let previouslyInSet = false;

    for (let rowIndex = 0; rowIndex < numRows; rowIndex += 1) {
      const inSet = sensorData.sets[rowIndex];

      //Keep track of when sets start and stop
      if (!previouslyInSet && inSet) {
        series.push({
          inSet: false,
          dataPoints: [...dataPoints], //Save a shallow copy
        });
        dataPoints = [];
      } else if (previouslyInSet && !inSet) {
        series.push({
          inSet: true,
          dataPoints: [...dataPoints], //Save a shallow copy
        });
        dataPoints = [];
      }

      const yValue = sensorData.values[rowIndex][columnIndex];

      if (yValue <= minY) {
        minY = yValue;
      }

      if (yValue >= maxY) {
        maxY = yValue;
      }

      dataPoints.push({
        x: sensorData.index[rowIndex],
        y: yValue,
      });

      previouslyInSet = inSet;
    }

    //Push the last series
    series.push({
      inSet: previouslyInSet,
      dataPoints: [...dataPoints], //Save a shallow copy
    });

    graphs.push({
      title: sensorData.columns[columnIndex],
      series: series,
      minY: minY,
      maxY: maxY,
      maxX: sensorData.index[numRows - 1],
      repXLocations: repXLocations,
    });
  }

  return graphs;
}

function addCleanerToHistory(file, cleanerName) {
  const now = new Date();

  if (
    file.preprocessing.cleaners &&
    Array.isArray(file.preprocessing.cleaners)
  ) {
    file.preprocessing.cleaners.push(cleanerName);
  } else {
    file.preprocessing.cleaners = [cleanerName];
  }

  if (
    file.preprocessing.cleaning_history &&
    Array.isArray(file.preprocessing.cleaning_history)
  ) {
    file.preprocessing.cleaning_history.push({
      cleaner: cleanerName,
      date: now,
    });
  } else {
    file.preprocessing.cleaning_history = [{ cleaner: cleanerName, date: now }];
  }

  file.preprocessing.date_last_cleaned = now;
}

export function markFileAsClean(file, cleanerName) {
  file.preprocessing.cleaned = true;

  file.preprocessing.status = "clean";

  addCleanerToHistory(file, cleanerName);
}

export function markFileAsUnclean(file, cleanerName) {
  file.preprocessing.cleaned = false;

  file.preprocessing.status = "unclean";

  addCleanerToHistory(file, cleanerName);
}

export function markFileAsTestOnly(file, cleanerName) {
  file.preprocessing.cleaned = false;

  file.preprocessing.status = "test";

  addCleanerToHistory(file, cleanerName);
}

export function prepareFile(file) {
  file.collection.n_reps = Math.max(
    0,
    (file.data.reps?.filter(Boolean) ?? []).length - 1,
  );
  return addRepsArrayIfMissing(file);
}

export function addRepsArrayIfMissing(file) {
  if (!file.data.reps || file.data.reps === null) {
    file.data.reps = Array.from(
      { length: file.data.index.length },
      (v, i) => false,
    );
  }

  return file;
}

export function isNumeric(str: string) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    // @ts-expect-error
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}
