import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {
  Box,
  CardActionArea,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { memo, useState } from "react";
import { useParams } from "react-router-dom";
import WorkoutPreviewPopover from "src/components/ClientPage/WorkoutPreviewPopover";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectWorkoutById, updateWorkout } from "src/slices/phasesSlice";

type Props = {
  draggableId: string;
  workoutId: string;
  isLast?: boolean;
  dayIndex: number;
  onDelete: () => void;
  onCopy: (type: "linked" | "not_linked") => void;
};

export default function PhaseWorkoutCellDraggable({
  draggableId,
  workoutId,
  isLast,
  dayIndex,
  onDelete,
  onCopy,
}: Props) {
  const { setNodeRef, listeners, isDragging, transform, transition } =
    useSortable({
      id: draggableId,
      data: {
        type: "phase_workout",
        workoutId: workoutId,
        dayIndex: dayIndex,
      },
    });

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : undefined,
  };

  return (
    <div ref={setNodeRef} style={style} {...listeners}>
      <PhaseWorkoutCell
        workoutId={workoutId}
        isLast={isLast}
        onDelete={onDelete}
        onCopy={onCopy}
        isDragging={isDragging}
        draggableId={draggableId}
      />
    </div>
  );
}

type CellProps = {
  workoutId: string;
  isLast?: boolean;
  onDelete: () => void;
  onCopy: (type: "linked" | "not_linked") => void;
  isDragging?: boolean;
  draggableId: string;
};

const PhaseWorkoutCell = memo(function PhaseWorkoutCell({
  workoutId,
  isLast,
  onDelete,
  onCopy,
  isDragging,
  draggableId,
}: CellProps) {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const workout = useAppSelector((state) =>
    selectWorkoutById(state, workoutId),
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLElement | null>(null);
  const isOnlyCopyInPhase = useAppSelector(
    (state) =>
      state.phases.phaseEditing?.days_draggable.filter((d) =>
        d.workouts.map((w) => w.workout_id).includes(workoutId),
      ).length === 1,
  );

  if (!workout) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>Error</Typography>
        <IconButton
          size="small"
          color="error"
          onClick={(event) => {
            event.stopPropagation();

            onDelete();
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        >
          <RemoveCircleRoundedIcon fontSize="inherit" />
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <CardActionArea
        disabled={isDragging}
        onContextMenu={(event) => {
          event.preventDefault();

          setAnchorEl(event.currentTarget);
        }}
        href={`/clients/${userId}/workouts/${workout.workout_id}`}
        sx={{
          borderBottom: isLast ? 0 : 1,
          opacity: isDragging ? 0.5 : undefined,
          borderColor: "divider",
          backgroundColor: (theme) => theme.palette.background.paper,
          transition: "background-color 0.2s ease",
        }}
        id={draggableId}
      >
        <Box sx={{ display: "flex", alignItems: "center", px: 1, py: 0.5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography>{workout.name}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();

                  setMoreAnchorEl(event.currentTarget);
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                <MoreVertRoundedIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </CardActionArea>
      <WorkoutPreviewPopover
        workoutId={workoutId}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      />
      <Menu
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(moreAnchorEl)}
        onClose={() => {
          setMoreAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            if (workout.is_extra) {
              dispatch(
                updateWorkout({
                  workout_id: workoutId,
                  is_extra: false,
                }),
              );
            } else {
              dispatch(
                updateWorkout({
                  workout_id: workoutId,
                  is_extra: true,
                }),
              );
            }

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            {workout.is_extra ? (
              <StarRoundedIcon fontSize="small" />
            ) : (
              <StarBorderRoundedIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText
            primary={workout.is_extra ? "Remove from extras" : "Add to extras"}
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            onCopy("not_linked");
            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ContentCopyRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Make a copy" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            onCopy("linked");
            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <LinkRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Make a linked copy" />
        </MenuItem>
        <Box>
          <Divider sx={{ mb: 1, mt: 2 }} />
        </Box>
        <MenuItem
          onClick={() => {
            onDelete();
            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            {isOnlyCopyInPhase ? (
              <DeleteRoundedIcon fontSize="small" color="error" />
            ) : (
              <RemoveCircleRoundedIcon fontSize="small" color="error" />
            )}
          </ListItemIcon>
          <ListItemText
            primary={isOnlyCopyInPhase ? "Delete" : "Remove"}
            sx={{ color: (theme) => theme.palette.error.main }}
          />
        </MenuItem>
      </Menu>
    </>
  );
});
