export function getTrainerName(
  trainerID: string,
  trainerNames: { trainer_id: string; full_name: string }[],
): string {
  const index = trainerNames.findIndex(
    (trainer) => trainer.trainer_id === trainerID,
  );

  if (index === -1) {
    return "Trainer not found";
  } else {
    return trainerNames[index].full_name;
  }
}

export function getCoachTimezone(
  trainerID: string,
  coachTimezones: { trainer_id: string; default_timezone_offset?: number }[],
): number {
  const index = coachTimezones.findIndex(
    (trainer) => trainer.trainer_id === trainerID,
  );

  if (index === -1) {
    return 0;
  } else {
    return coachTimezones[index].default_timezone_offset ?? 0;
  }
}
