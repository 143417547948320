import { Droppable } from "@hello-pangea/dnd";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import {
  EquipmentType as EquipmentType2,
  type EquipmentObject,
} from "@trainwell/types";
import { useMemo, useState } from "react";
import { shallowEqual } from "react-redux";
import { Virtuoso } from "react-virtuoso";
import SearchField from "src/components/misc/SearchField";
import { useAppSelector } from "src/hooks/stateHooks";
import { exerciseGroups, exerciseGroupsFuse } from "src/lib/exercises";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import ExerciseFilterButton from "./ExerciseFilterButton";
import ExerciseSelectCell from "./ExerciseSelectCell";
import type { ExerciseSort } from "./ExerciseSortButton";
import ExerciseSortButton from "./ExerciseSortButton";

export default function ExerciseSelector() {
  const selectedEquipmentKeys = useAppSelector(
    (state) =>
      !state.client.client
        ? []
        : (
            Object.keys(
              state.client.client.equipment_detailed,
            ) as (keyof typeof EquipmentType2)[]
          ).filter((key) => state.client.client!.equipment_detailed[key]),
    shallowEqual,
  );
  const latestSets = useAppSelector(
    (state) => state.client.client?.latest_sets,
    shallowEqual,
  );
  const isTemplate = useAppSelector((state) => state.workout.isTemplate);

  const trainer = useAppSelector((state) => state.trainer.trainer);

  const [search, setSearch] = useState("");
  const [sortMode, setSortMode] = useState<ExerciseSort>("name");
  const [filteredExerciseGroupIds, setFilteredExerciseGroupIds] = useState<
    string[]
  >([]);

  const filteredExerciseGroups = useMemo(() => {
    let list = exerciseGroups.filter((g) =>
      filteredExerciseGroupIds.includes(g.picker_group_id),
    );

    if (search !== "") {
      let refinedSearch = search;

      // Expand exercise shortcuts

      refinedSearch = refinedSearch
        .toLowerCase()
        .split(",")
        .map((t) => t.trim())
        .map((t) => {
          if (t === "mb") {
            return "medicine ball";
          } else if (t === "kb") {
            return "kettlebell";
          } else if (t === "db") {
            return "dumbbell";
          } else if (t === "bb") {
            return "barbell";
          }

          return t;
        })
        .join(",")
        .split(" ")
        .map((t) => {
          if (t === "mb") {
            return "medicine ball";
          } else if (t === "kb") {
            return "kettlebell";
          } else if (t === "db") {
            return "dumbbell";
          } else if (t === "bb") {
            return "barbell";
          }

          return t;
        })
        .join(" ");

      refinedSearch = refinedSearch.replaceAll("mb,", "medicine ball,");
      refinedSearch = refinedSearch.replaceAll("kb,", "kettlebell,");
      refinedSearch = refinedSearch.replaceAll("db,", "dumbbell,");
      refinedSearch = refinedSearch.replaceAll("bb,", "barbell,");

      // Handle AND and OR

      const andTerms = refinedSearch
        .split(",")
        .map((t) => t.trim())
        .filter((t) => !t.includes(" or "));

      const orTerms = refinedSearch
        .split(",")
        .map((t) => t.trim())
        .filter((t) => t.includes(" or "))
        .join(" ")
        .split(" or ")
        .map((t) => t.trim());

      const searchExpression: any = {
        $and: [
          ...andTerms.map((t) => {
            return { tags: t };
          }),
        ],
      };

      if (orTerms.length >= 2) {
        searchExpression.$and.push({
          $or: [
            ...orTerms.map((t) => {
              return { tags: t };
            }),
          ],
        });
      }

      // Useful logging to debug search
      // console.log(`Search: ${search}`);
      // console.log(`Search (refined): ${refinedSearch}`);
      // console.log(
      //   `Search expression: ${JSON.stringify(searchExpression, null, 1)}`,
      // );

      let fusedItems = exerciseGroupsFuse.search(searchExpression);

      const ids = list.map((item) => item.picker_group_id);

      fusedItems = fusedItems.filter((fusedItem) =>
        ids.includes(fusedItem.item.picker_group_id),
      );

      // console.log(fusedItems);

      list = fusedItems.map((fusedItem) => fusedItem.item);

      const favoriteExercises = trainer?.favorite_exercise_ids ?? [];

      list.sort((a, b) => {
        const isAFavorite = favoriteExercises.includes(a.picker_group_id);
        const isBFavorite = favoriteExercises.includes(b.picker_group_id);
        if (isAFavorite && !isBFavorite) {
          return -1;
        } else if (!isAFavorite && isBFavorite) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      const favoriteExercises = trainer?.favorite_exercise_ids ?? [];

      if (sortMode === "last_used") {
        list.sort((a, b) => {
          const isAFavorite = favoriteExercises.includes(a.picker_group_id);
          const isBFavorite = favoriteExercises.includes(b.picker_group_id);
          if (isAFavorite && !isBFavorite) {
            return -1;
          } else if (!isAFavorite && isBFavorite) {
            return 1;
          }

          const lastUsedDateA = latestSets
            ? workoutLib.exercises.getLastUsedDate({
                client: { latest_sets: latestSets },
                exerciseSourceId: a.exercises[0].id,
              })
            : undefined;
          const lastUsedDateB = latestSets
            ? workoutLib.exercises.getLastUsedDate({
                client: { latest_sets: latestSets },
                exerciseSourceId: b.exercises[0].id,
              })
            : undefined;

          if (!lastUsedDateA && lastUsedDateB) {
            return 1;
          } else if (lastUsedDateA && !lastUsedDateB) {
            return -1;
          } else if (!lastUsedDateA && !lastUsedDateB) {
            return 0;
          } else {
            return lastUsedDateB!.getTime() - lastUsedDateA!.getTime();
          }
        });
      } else if (sortMode === "name") {
        list.sort((a, b) => {
          const isAFavorite = favoriteExercises.includes(a.picker_group_id);
          const isBFavorite = favoriteExercises.includes(b.picker_group_id);
          if (isAFavorite && !isBFavorite) {
            return -1;
          } else if (!isAFavorite && isBFavorite) {
            return 1;
          }

          return a.picker_group_id.localeCompare(b.picker_group_id);
        });
      }
    }

    return list;
  }, [
    search,
    filteredExerciseGroupIds,
    sortMode,
    latestSets,
    trainer?.favorite_exercise_ids,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: (theme) => theme.palette.background.paper,
        overflowY: "auto",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: (theme) => theme.palette.background.paper,
          p: 1,
        }}
      >
        <Box>
          <Typography variant="h2">Exercises</Typography>
          <Typography variant="overline">
            {filteredExerciseGroups.length} / {exerciseGroups.length}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ExerciseSortButton
            value={sortMode}
            onChange={(newSort) => {
              setSortMode(newSort);
            }}
            disabled={search !== ""}
            sx={{ mr: 0.5 }}
          />
          <ExerciseFilterButton
            onChange={(newFilteredExerciseGroupIds) => {
              setFilteredExerciseGroupIds(newFilteredExerciseGroupIds);
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          pb: 1,
          px: 1,
          backgroundColor: (theme) => theme.palette.background.paper,
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          alignItems: "center",
        }}
      >
        <SearchField
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
          onClear={() => {
            setSearch("");
          }}
        />
        <Tooltip
          title={
            <>
              <Typography variant="h2" sx={{ mb: 1 }}>
                Search tips
              </Typography>
              <Typography>
                Use commas to seperate different searches, and &apos;or&apos; to
                match anything, ex:{" "}
                <b>
                  <i>biceps or triceps, db</i>
                </b>{" "}
                will look for anything that works our biceps or triceps that
                also uses dumbbells.
              </Typography>
              <Typography sx={{ mt: 1 }}>
                You can search names, equipment, and body parts.
              </Typography>
              <Typography sx={{ fontWeight: "bold", mt: 1 }}>
                Shortcuts
              </Typography>
              <Typography>db = dumbbell</Typography>
              <Typography>kb = kettlebell</Typography>
              <Typography>bb = barbell</Typography>
              <Typography>mb = medicine ball</Typography>
            </>
          }
        >
          <IconButton size="small" sx={{ ml: 0.5 }}>
            <HelpOutlineRoundedIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
        }}
      >
        <Droppable
          droppableId="exercise-list"
          isDropDisabled={true}
          type={"exercise"}
        >
          {(provided) => {
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ height: "100%" }}
              >
                <Virtuoso
                  style={{ height: "100%" }}
                  data={filteredExerciseGroups}
                  itemContent={(index, exerciseGroup) => {
                    const exerciseMasterId =
                      workoutLib.exerciseGroups.getExerciseFromGroupId(
                        exerciseGroup,
                        isTemplate
                          ? undefined
                          : {
                              equipment_detailed: selectedEquipmentKeys.reduce(
                                (o, key) => ({
                                  ...o,
                                  [key]: {
                                    values: [],
                                    notes: null,
                                    photo_url: null,
                                  },
                                }),
                                {} as EquipmentObject,
                              ),
                              latest_sets: latestSets,
                            },
                        undefined,
                      );

                    return (
                      <ExerciseSelectCell
                        exerciseMasterID={exerciseMasterId}
                        exerciseGroupID={exerciseGroup.picker_group_id}
                        index={index}
                      />
                    );
                  }}
                />
              </div>
            );
          }}
        </Droppable>
      </Box>
    </Box>
  );
}
