import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import {
  Box,
  Card,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { useShortUrl } from "@trainwell/features";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { ShortUrlEditDialog } from "../short-urls/ShortUrlEditDialog";

type Props = {
  shortUrlId: string;
};

export function CampaignShortUrlCell({ shortUrlId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { data: shortUrl } = useShortUrl({
    shortUrlId: shortUrlId,
  });
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  if (!shortUrl) {
    return null;
  }

  return (
    <Card variant="outlined" sx={{ p: 1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LinkRoundedIcon fontSize="inherit" />
          <Typography sx={{ fontWeight: "bold", ml: 1 }}>
            Audience short url
          </Typography>
        </Box>
        <IconButton
          size="small"
          onClick={() => {
            setEditDialogOpen(true);
          }}
        >
          <EditRoundedIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Link
          href={`https://go.${shortUrl.domain}/${shortUrl.short_path}`}
          target="_blank"
          sx={{
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          https://go.{shortUrl.domain}/{shortUrl.short_path}
        </Link>
        <Tooltip title="Copy to clipboard" placement="right" disableInteractive>
          <IconButton
            size="small"
            color="default"
            sx={{ ml: 0.5 }}
            onClick={() => {
              navigator.clipboard
                .writeText(
                  `https://go.${shortUrl.domain}/${shortUrl.short_path}`,
                )
                .then(() => {
                  enqueueSnackbar("Copied to clipboard", {
                    variant: "success",
                  });
                });
            }}
          >
            <ContentPasteRoundedIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography>
        <b>{shortUrl.clicks.toLocaleString()}</b> clicks
      </Typography>
      <Typography
        variant="body2"
        sx={{
          overflowWrap: "break-word",
          wordBreak: "break-all",
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        {shortUrl.original_url}
      </Typography>
      <ShortUrlEditDialog
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
        }}
        defaultShortUrl={shortUrl}
      />
    </Card>
  );
}
