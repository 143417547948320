import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectClientMaxes } from "src/slices/clientSlice";
import {
  saveAsTemplate,
  selectSelectedSections,
} from "src/slices/workoutSlice";
import { TagList } from "../misc/TagList";

export default function SaveAsTemplateButton() {
  const dispatch = useAppDispatch();
  const templates = useAppSelector((state) => state.templates.templates);
  const workout = useAppSelector(
    (state) => state.workout.workoutNormalized?.result,
  );
  const sections = useAppSelector(
    (state) => state.workout.workoutNormalized?.entities.sections,
  );
  const clientMaxes = useAppSelector((state) => selectClientMaxes(state));

  const [templateName, setTemplateName] = useState<string | null>(null);
  const [templateTags, setTemplateTags] = useState([]);
  const [possibleTags, setPossibleTags] = useState<string[]>([]);
  const selectedSectionIds = useAppSelector(selectSelectedSections);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const [saveSections, setSaveSections] = useState(false);
  const menuOpen = Boolean(menuAnchorEl);

  useEffect(() => {
    let newPossibleTags: string[] = [];

    for (const template of templates) {
      if ("tags" in template) {
        newPossibleTags = [...newPossibleTags, ...(template.tags ?? [])];
      }
    }

    setPossibleTags([...new Set(newPossibleTags)].sort());
  }, [templates]);

  function updateTemplateName(event: any) {
    setTemplateName(event.target.value);
  }

  function forceCloseSaveTemplate() {
    setMenuAnchorEl(null);
    setTemplateName("");
  }

  function handleSaveAsTemplate(onlySections?: boolean) {
    dispatch(
      saveAsTemplate({
        name: templateName!,
        tags: templateTags,
        onlySections: onlySections,
        clientMaxes: clientMaxes ?? {},
      }),
    );

    setTemplateName(null);
    setTemplateTags([]);
    setMenuAnchorEl(null);
  }

  function addTagHandler(tag: string) {
    const newTags = JSON.parse(JSON.stringify(templateTags));

    const index = newTags.indexOf(tag);

    if (index === -1) {
      newTags.push(tag);
      setTemplateTags(newTags);
    }
  }

  function removeTagHandler(tag: string) {
    const newTags = JSON.parse(JSON.stringify(templateTags));

    const index = newTags.indexOf(tag);

    if (index !== -1) {
      newTags.splice(index, 1);
      setTemplateTags(newTags);
    }
  }

  if (!workout) {
    return <LoadingPage message="Getting your workout ready 💪" />;
  }

  return (
    <>
      <Button
        variant="text"
        size="small"
        disabled={menuOpen}
        onClick={(event) => {
          setMenuAnchorEl(event.currentTarget);

          if (selectedSectionIds.length === 0) {
            setSaveSections(false);
            setTemplateName(workout.name);
          } else {
            setTemplateName(null);
          }
        }}
        sx={{ ml: 1 }}
      >
        Save as template
      </Button>
      {templateName === null ? (
        <Menu
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={() => {
            setMenuAnchorEl(null);
          }}
        >
          <MenuItem
            onClick={() => {
              setSaveSections(false);
              setTemplateName(workout.name);
            }}
          >
            <ListItemIcon>
              <SaveAsRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Entire workout" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSaveSections(true);

              if (selectedSectionIds.length === 1) {
                setTemplateName(
                  sections![selectedSectionIds[0]].section_name ?? "Section",
                );
              } else {
                setTemplateName(workout.name);
              }
            }}
          >
            <ListItemIcon>
              <SaveAsRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={`${selectedSectionIds.length} selected section${
                selectedSectionIds.length === 1 ? "" : "s"
              }`}
            />
          </MenuItem>
        </Menu>
      ) : (
        <Popover
          open={menuOpen}
          onClose={forceCloseSaveTemplate}
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box
            sx={{
              width: "450px",
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              multiline={true}
              fullWidth={true}
              label="Template name"
              onChange={updateTemplateName}
              value={templateName}
              sx={{ mb: 2 }}
            ></TextField>
            <TagList
              value={templateTags.map((t) => ({ id: t, label: t }))}
              options={possibleTags.map((t) => ({ id: t, label: t }))}
              onAdd={addTagHandler}
              onRemove={removeTagHandler}
            />
            <Button
              sx={{ ml: 1 }}
              variant="contained"
              onClick={() => {
                handleSaveAsTemplate(saveSections);
              }}
              fullWidth
            >
              {saveSections
                ? `Save ${selectedSectionIds.length} selected section${
                    selectedSectionIds.length === 1 ? "" : "s"
                  } as template`
                : "Save workout as template"}
            </Button>
          </Box>
        </Popover>
      )}
    </>
  );
}
