import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import type {
  ActionItem,
  ActionItemType,
  ActionItemWorkoutReview,
} from "@trainwell/types";
import { getDay, isPast, parse } from "date-fns";
import {
  getActionItemStatus,
  getNeedsResponseStatus,
  getUnreadStatus,
  willNeedToDoActionItemToday,
  willNeedToDoToday,
} from "src/lib/officeHours";
import { api } from "src/lib/trainwellApi";
import type { Chat } from "./chatSlice";
import { selectAllChats } from "./chatSlice";
import type { RootState } from "./store";
import { selectIsAuditing, selectPrimaryTrainer } from "./trainerSlice";

export const fetchActionItems = createAsyncThunk(
  "actionItems/fetchActionItems",
  async (data: { trainerId: string; refetch?: boolean }) => {
    const { action_items } = await api.actionItems.getUnfinished(
      data.trainerId,
    );

    return action_items;
  },
);

export const fetchCompletedActionItems = createAsyncThunk(
  "actionItems/fetchCompletedActionItems",
  async (data: { trainerId: string; startDate: string }) => {
    const { action_items } = await api.actionItems.getFinished(
      data.trainerId,
      data.startDate,
    );

    return action_items;
  },
);

export const dismissActionItemForWorkoutLog = createAsyncThunk(
  "actionItems/dismissActionItemForWorkoutLog",
  async (workoutLogId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const actionItem = state.actionItems.actionItems.find(
      (actionItem) =>
        (actionItem as ActionItemWorkoutReview).log_id === workoutLogId,
    );

    if (actionItem) {
      dispatch(dismissActionItem({ actionItemId: actionItem.id }));
    }
  },
);

export const dismissActionItemWithType = createAsyncThunk(
  "actionItems/dismissActionItemWithType",
  async (
    data: { userId: string; type: ActionItemType },
    { dispatch, getState },
  ) => {
    const { userId, type } = data;

    const state = getState() as RootState;

    const actionItem = state.actionItems.actionItems.find(
      (actionItem) => actionItem.user_id === userId && actionItem.type === type,
    );

    if (actionItem) {
      dispatch(dismissActionItem({ actionItemId: actionItem.id }));
    }
  },
);

export const dismissActionItem = createAsyncThunk(
  "actionItems/dismissActionItem",
  async (
    data: { actionItemId: string; usedClearButton?: boolean },
    { getState, rejectWithValue },
  ) => {
    const { actionItemId, usedClearButton } = data;

    const state = getState() as RootState;

    const isGhosting = selectIsAuditing(state);

    const actionItem = state.actionItems.actionItems.find(
      (item) => item.id === actionItemId,
    );

    if (isGhosting && !state.trainer.disableGhostingProtections) {
      console.log(
        `Action item: ghosting, skip clearing action item with type: '${actionItem?.type}'`,
      );

      return rejectWithValue({ showToast: false });
    }

    if (!actionItem) {
      throw new Error("Action item not found");
    }

    const response = await api.actionItems.dismiss(
      actionItemId,
      usedClearButton,
    );

    return response;
  },
);

export const createCustomActionItem = createAsyncThunk(
  "actionItems/createCustomActionItem",
  async (
    data: { message: string; userId: string; sendDate?: string },
    { getState },
  ) => {
    const state = getState() as RootState;

    const { action_item } = await api.actionItems.createCustom({
      trainerId: state.trainer.trainer!.trainer_id,
      userId: data.userId,
      message: data.message,
      sendDate: data.sendDate,
    });

    return action_item;
  },
);

export const updateActionItem = createAsyncThunk(
  "actionItems/updateActionItem",
  async (data: { actionItemId: string; dateToSend?: string }) => {
    const { action_item } = await api.actionItems.updateOne(data.actionItemId, {
      dateToSend: data.dateToSend,
    });

    return action_item;
  },
);

// Define a type for the slice state
interface ActionItemsState {
  actionItems: ActionItem[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
  actionItemsCompleted: ActionItem[];
  statusCompleted: "idle" | "loading" | "succeeded" | "failed";
  errorCompleted: string | undefined;
}

// Define the initial state using that type
const initialState: ActionItemsState = {
  actionItems: [],
  status: "idle",
  error: undefined,
  actionItemsCompleted: [],
  statusCompleted: "idle",
  errorCompleted: undefined,
};

export const actionItemSlice = createSlice({
  name: "actionItems",
  initialState,
  reducers: {
    resetActionItems: () => initialState,
    clearActionItemWithType: (
      state,
      action: PayloadAction<{ userId: string; type: string }>,
    ) => {
      const { userId, type } = action.payload;

      const actionItemIndex = state.actionItems.findIndex(
        (actionItem) =>
          actionItem.user_id === userId && actionItem.type === type,
      );

      if (actionItemIndex !== -1) {
        state.actionItems.splice(actionItemIndex, 1);
      }
    },
    clearActionItems: (state, action: PayloadAction<string[]>) => {
      const actionItemIds = action.payload;

      state.actionItems = state.actionItems.filter(
        (actionItem) => !actionItemIds.includes(actionItem.id),
      );
    },
    addActionItem: (state, action: PayloadAction<ActionItem>) => {
      const actionItem = action.payload;

      if (
        state.actionItems.findIndex((item) => actionItem.id === item.id) === -1
      ) {
        state.actionItems.push(actionItem);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActionItems.pending, (state, action) => {
      if (!action.meta.arg.refetch) {
        state.status = "loading";
      }
    });
    builder.addCase(fetchActionItems.fulfilled, (state, action) => {
      console.log("Redux: Got action items");
      state.status = "succeeded";

      let actionItems = action.payload ?? [];

      actionItems = actionItems.filter((item) => !item.date_finished);

      state.actionItems = actionItems;
    });
    builder.addCase(fetchActionItems.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(fetchCompletedActionItems.pending, (state) => {
      state.statusCompleted = "loading";
    });
    builder.addCase(fetchCompletedActionItems.fulfilled, (state, action) => {
      console.log("Redux: Got completed action items: " + action.meta.arg);
      state.statusCompleted = "succeeded";

      const actionItems = action.payload;
      state.actionItemsCompleted.push(...actionItems);
    });
    builder.addCase(fetchCompletedActionItems.rejected, (state, action) => {
      state.statusCompleted = "failed";
      state.errorCompleted = action.error.message;
    });
    builder.addCase(dismissActionItem.fulfilled, (state, action) => {
      const { actionItemId } = action.meta.arg;

      state.actionItems = state.actionItems.filter(
        (actionItem) => actionItem.id !== actionItemId,
      );
    });
    builder.addCase(createCustomActionItem.fulfilled, (state, action) => {
      const newActionItem = action.payload;

      const index = state.actionItems.findIndex(
        (item) => newActionItem.id === item.id,
      );

      if (index === -1) {
        state.actionItems.push(newActionItem);
      }
    });
    builder.addCase(updateActionItem.fulfilled, (state, action) => {
      const newActionItem = action.payload;

      const index = state.actionItems.findIndex(
        (item) => newActionItem.id === item.id,
      );

      if (index === -1) {
        state.actionItems.push(newActionItem);
      } else {
        state.actionItems[index] = newActionItem;
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetActionItems,
  clearActionItemWithType,
  clearActionItems,
  addActionItem,
} = actionItemSlice.actions;

export default actionItemSlice.reducer;

export const selectAllActionItems = (state: RootState) =>
  state.actionItems.actionItems;

export const selectActionItemById = (
  state: RootState,
  actionItemId: string,
) => {
  const item = state.actionItems.actionItems.find(
    (actionItem) => actionItem.id === actionItemId,
  );

  return item;
};

export const makeSelectActionItemsForClient = () => {
  const selectActionItemsForClient = createSelector(
    [selectAllActionItems, (_: RootState, userId: string) => userId],
    (actionItems, userId) =>
      actionItems.filter(
        (actionItem) =>
          actionItem.user_id === userId &&
          !actionItem.date_finished &&
          isPast(new Date(actionItem.date_to_send ?? actionItem.date_created)),
      ),
  );

  return selectActionItemsForClient;
};

export const selectSentActionItems = createSelector(
  [selectAllActionItems],
  (actionItems) =>
    actionItems.filter(
      (actionItem) =>
        !actionItem.date_to_send || isPast(new Date(actionItem.date_to_send)),
    ),
);

export const selectIsBeforeCheckin = createSelector(
  [selectPrimaryTrainer],
  (trainer) => {
    const now = new Date();

    // Sorted from earlier OH to later OH
    const todaysOfficeHours = [
      ...(trainer?.office_hours[getDay(now)] ?? []),
    ].sort((a, b) => a.time_start.localeCompare(b.time_start));

    const convertedTodaysOfficeHours: { startDate: Date; endDate: Date }[] = [];

    todaysOfficeHours.forEach((officeHour) => {
      convertedTodaysOfficeHours.push({
        startDate: parse(officeHour.time_start, "HH:mm", now),
        endDate: parse(officeHour.time_end, "HH:mm", now),
      });
    });

    const earliestOH = convertedTodaysOfficeHours.at(0);

    if (!earliestOH) {
      return null;
    }

    if (
      now.toISOString().localeCompare(earliestOH.startDate.toISOString()) < 0
    ) {
      return true;
    }

    return false;
  },
);

export const selectMostSeverActionItemStatus = createSelector(
  [selectAllActionItems, selectPrimaryTrainer],
  (actionItems, trainer) =>
    actionItems.some(
      (item) => getActionItemStatus(item, trainer!.office_hours) === "error",
    )
      ? "error"
      : actionItems.some(
            (item) =>
              getActionItemStatus(item, trainer!.office_hours) === "warn",
          )
        ? "warn"
        : "ok",
);

export const selectClientAlertCount = createSelector(
  [selectAllActionItems, selectPrimaryTrainer, selectAllChats],
  (actionItems, trainer, chats) => {
    const alertActionItems = actionItems.filter(
      (actionItem) =>
        ((!actionItem.date_to_send ||
          isPast(new Date(actionItem.date_to_send))) &&
          getActionItemStatus(actionItem, trainer!.office_hours) !== "ok") ||
        willNeedToDoActionItemToday(actionItem, trainer!.office_hours),
    );

    const clientChats: Chat[] = [];

    for (const chatId in chats) {
      if (!chats[chatId].isGroupChat) {
        clientChats.push(chats[chatId]);
      }
    }

    const alertChats = clientChats.filter((chat) => {
      const chatWillNeedResponse =
        chat.oldestMessageNeedingResponse &&
        (getNeedsResponseStatus(
          chat.oldestMessageNeedingResponse,
          trainer!.office_hours,
        ) !== "ok" ||
          willNeedToDoToday(
            new Date(chat.oldestMessageNeedingResponse),
            trainer!.office_hours,
          ));

      const chatWillNeedToBeRead =
        chat.oldestUnreadMessageFromClient &&
        (getUnreadStatus(
          chat.oldestUnreadMessageFromClient,
          trainer!.office_hours,
        ) !== "ok" ||
          willNeedToDoToday(
            new Date(chat.oldestUnreadMessageFromClient),
            trainer!.office_hours,
          ));

      return chatWillNeedResponse || chatWillNeedToBeRead;
    });

    const alertCount = new Set([
      ...alertActionItems.map((actionItem) => actionItem.user_id),
      ...alertChats.map((chat) => chat.id),
    ]).size;

    return alertCount;
  },
);

export const selectHasPreCallSurveyActionItem = (
  state: RootState,
  userId: string,
) => {
  const sentActionItems = selectSentActionItems(state);

  const item = sentActionItems.find(
    (actionItem) =>
      actionItem.user_id === userId &&
      actionItem.type === "send_pre_check_in_survey",
  );

  return Boolean(item);
};
