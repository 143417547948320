import InboxRoundedIcon from "@mui/icons-material/InboxRounded";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Popover,
  Typography,
} from "@mui/material";
import type { Message } from "@trainwell/types";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { api } from "src/lib/trainwellApi";
import { DialogTitleWithClose } from "../misc/DialogTitleWithClose";

type Props = {
  userId: string;
  open: boolean;
  onClose: () => void;
};

export function ChatMediaDialog({ userId, open, onClose }: Props) {
  const [messages, setMessages] = useState<Message[] | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popoverImageUrl, setPopoverImageUrl] = useState<string | null>(null);
  const popoverOpen = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    imageURL: string,
  ) => {
    setPopoverImageUrl(imageURL);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverImageUrl(null);
  };

  useEffect(() => {
    if (userId && !messages) {
      api.clients.getChatMedia(userId).then((data) => {
        setMessages(
          data.sort((a, b) =>
            (b.send_date as string).localeCompare(a.send_date as string),
          ),
        );
      });
    }
  }, [userId, messages]);

  useEffect(() => {
    setMessages(null);
  }, [userId]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
        <DialogTitleWithClose onClose={onClose}>
          Chat media
        </DialogTitleWithClose>
        <DialogContent dividers sx={{ p: 0 }}>
          <Box sx={{ height: "600px", overflowY: "auto", p: 2 }}>
            {messages === null ? (
              <LoadingComponent message="Fetching images 🖼️" />
            ) : messages.length === 0 ? (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <InboxRoundedIcon sx={{ fontSize: 52, mb: 2 }} />
                <Typography sx={{ textAlign: "center" }}>No media</Typography>
              </Box>
            ) : (
              <Grid container spacing={2}>
                {messages.map((message, i) => {
                  return (
                    <Grid item xs={4} key={i}>
                      {message.type === "image" ? (
                        <img
                          src={message.media_url ?? ""}
                          alt="progress picture"
                          style={{
                            width: "100%",
                            height: "150px",
                            objectFit: "cover",
                            display: "flex",
                            borderRadius: "8px",
                          }}
                          onMouseEnter={(e) => {
                            handlePopoverOpen(e, message.media_url ?? "");
                          }}
                          onMouseLeave={handlePopoverClose}
                        />
                      ) : (
                        <video
                          controls
                          height={150}
                          style={{
                            width: "100%",
                            borderRadius: "8px",
                            display: "block",
                          }}
                          poster={message.thumbnail_url!}
                        >
                          <source src={message.media_url!} />
                        </video>
                      )}
                      <Typography
                        variant="body2"
                        sx={{ color: (theme) => theme.palette.text.secondary }}
                      >
                        {format(new Date(message.send_date), "MMM d, yyyy")}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            <Popover
              sx={{
                pointerEvents: "none",
              }}
              open={popoverOpen}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {popoverImageUrl && (
                <img
                  src={popoverImageUrl}
                  alt="chat media"
                  width={600}
                  height={600}
                  style={{
                    objectFit: "contain",
                    // width: "600px",
                    // display: "block",
                    borderRadius: "8px",
                  }}
                />
              )}
            </Popover>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
