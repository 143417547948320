import { Box, Typography } from "@mui/material";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectBestSet, selectLatestSet } from "src/slices/clientSlice";
import { getSetString } from "./SetHistoryCell";

type Props = {
  exerciseMasterId: string;
};

export default function ExerciseStats({ exerciseMasterId }: Props) {
  const client = useAppSelector((state) => state.client.client);
  const bestSet = useAppSelector((state) =>
    selectBestSet(state, client!.user_id, exerciseMasterId),
  );
  const latestSet = useAppSelector((state) =>
    selectLatestSet(state, client!.user_id, exerciseMasterId),
  );

  return (
    <Box sx={{ textAlign: "start", display: "flex" }}>
      {latestSet && (
        <Typography
          variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
            mr: 2,
            lineHeight: 1,
          }}
        >
          Latest: {getSetString(latestSet, client!.preferred_weight_system)}
        </Typography>
      )}
      {bestSet && (
        <Typography
          variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
            mr: 2,
            lineHeight: 1,
          }}
        >
          Best: {getSetString(bestSet, client!.preferred_weight_system)}
        </Typography>
      )}
    </Box>
  );
}
