import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { CoachVacation } from "@trainwell/types";
import { getUnixTime } from "date-fns";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "./store";
import {
  removeVacationFromCoach,
  setVacationForCoach,
} from "./trainerEditSlice";

export const fetchAllVacations = createAsyncThunk(
  "vacations/fetchAllVacations",
  async (data: { onlyFetchUpcoming: boolean; trainerId?: string }) => {
    if (data.onlyFetchUpcoming) {
      return await api.vacations.getMany({
        startingAfter: getUnixTime(new Date()),
        trainerId: data.trainerId,
      });
    } else {
      return await api.vacations.getMany({ trainerId: data.trainerId });
    }
  },
);

export const deleteVacation = createAsyncThunk(
  "vacations/deleteVacation",
  async (vacationId: string, { dispatch }) => {
    const response = await api.vacations.removeOne(vacationId);

    dispatch(removeVacationFromCoach(vacationId));

    return response;
  },
);

export const editVacation = createAsyncThunk(
  "vacations/editVacation",
  async (
    data: {
      startDate: number;
      endDate: number;
      type: CoachVacation["type"];
      vacationId: string;
      clientMessage?: string;
      localDateToSend?: number;
    },
    { dispatch },
  ) => {
    const response = await api.vacations.updateOne(data);

    dispatch(setVacationForCoach(response));

    return response;
  },
);

// Define a type for the slice state
interface VacationsState {
  vacations: CoachVacation[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
}

// Define the initial state using that type
const initialState: VacationsState = {
  vacations: [],
  status: "idle",
  error: undefined,
};

export const vacationsSlice = createSlice({
  name: "vacations",
  initialState,
  reducers: {
    resetVacations: () => initialState,
    updateLocalVacation: (state, action: PayloadAction<CoachVacation>) => {
      const newVacation = action.payload;

      const index = state.vacations.findIndex(
        (vacation) => vacation.id === newVacation.id,
      );

      if (index === -1) {
        state.vacations.unshift(newVacation);
      } else {
        state.vacations[index] = newVacation;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllVacations.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchAllVacations.fulfilled, (state, action) => {
      console.log("Redux: Got vacations");
      state.status = "succeeded";

      const { vacations } = action.payload;

      vacations.sort((a, b) => (a.date_start > b.date_start ? 1 : -1));

      state.vacations = vacations;
    });
    builder.addCase(fetchAllVacations.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(deleteVacation.fulfilled, (state, action) => {
      const vacationId = action.meta.arg;

      const index = state.vacations.findIndex(
        (vacation) => vacation.id === vacationId,
      );

      if (index !== -1) {
        state.vacations.splice(index, 1);
      }
    });
    builder.addCase(editVacation.fulfilled, (state, action) => {
      const newVacation = action.payload;

      const index = state.vacations.findIndex(
        (vacation) => vacation.id === newVacation.id,
      );

      if (index !== -1) {
        state.vacations[index] = newVacation;
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetVacations } = vacationsSlice.actions;

export default vacationsSlice.reducer;

export const selectVacationById = (state: RootState, vacationId: string) =>
  state.vacations.vacations.find((vacation) => vacation.id === vacationId);
