import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { addVacation, fetchVacations } from "src/slices/trainerEditSlice";
import CoachVacationCell from "./CoachVacationCell";
import VacationEditDialog from "./VacationEditDialog";

export default function TabVacations() {
  const dispatch = useAppDispatch();
  const trainerEdit = useAppSelector((state) => state.trainerEdit.trainer);
  const [vacationDialogOpen, setVacationDialogOpen] = useState(false);
  const vacations = useAppSelector((state) => state.trainerEdit.vacations);
  const vacationsStatus = useAppSelector(
    (state) => state.trainerEdit.vacationsStatus,
  );

  useEffect(() => {
    if (vacationsStatus === "idle" && trainerEdit?.trainer_id) {
      dispatch(fetchVacations(trainerEdit.trainer_id));
    }
  }, [vacationsStatus, dispatch, trainerEdit?.trainer_id]);

  if (!trainerEdit) {
    return <LoadingPage message="Getting your trainer" />;
  }

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Button
          startIcon={<AddRoundedIcon />}
          onClick={() => {
            setVacationDialogOpen(true);
          }}
          sx={{ mr: 2 }}
        >
          Add vacation
        </Button>
      </Box>
      <Stack direction="column" spacing={2}>
        {vacations.map((vacation, index) => {
          return <CoachVacationCell vacation={vacation} key={index} />;
        })}
      </Stack>
      <VacationEditDialog
        open={vacationDialogOpen}
        onClose={() => {
          setVacationDialogOpen(false);
        }}
        coachDefaultTimezoneOffset={trainerEdit.default_timezone_offset}
        onSave={({
          startTime,
          endTime,
          type,
          clientMessage,
          localDateToSend,
          requiresInterimCoaches,
        }) => {
          setVacationDialogOpen(false);

          dispatch(
            addVacation({
              trainerId: trainerEdit.trainer_id,
              startDate: startTime,
              endDate: endTime,
              type: type,
              clientMessage: clientMessage,
              localDateToSend: localDateToSend,
              requiresInterimCoaches: requiresInterimCoaches,
            }),
          );
        }}
      />
    </Box>
  );
}
