import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Trainer } from "@trainwell/types";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "src/slices/store";

export const fetchAllEmployees = createAsyncThunk(
  "employees/fetchAllEmployees",
  async () => {
    const response = await api.trainers.getAllEmployees();
    return response;
  },
);

// Define a type for the slice state
interface EmployeesState {
  employees: Trainer[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
}

// Define the initial state using that type
const initialState: EmployeesState = {
  employees: [],
  status: "idle",
  error: undefined,
};

export const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    resetEmployees: () => initialState,
    updateEmployee: (state, action: PayloadAction<Trainer>) => {
      const newCoach = action.payload;

      const index = state.employees.findIndex(
        (trainer) => trainer.trainer_id === newCoach.trainer_id,
      );

      if (index !== -1) {
        state.employees[index] = newCoach;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllEmployees.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchAllEmployees.fulfilled, (state, action) => {
      console.log("Redux: Got all employees");
      state.status = "succeeded";

      const coaches = action.payload;

      coaches.sort((a, b) => {
        return a.full_name > b.full_name ? 1 : -1;
      });

      state.employees = coaches;
    });
    builder.addCase(fetchAllEmployees.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetEmployees, updateEmployee } = employeesSlice.actions;

export default employeesSlice.reducer;

export const selectEmployeeById = (state: RootState, trainerID: string) =>
  state.employees.employees.find(
    (employee) => employee.trainer_id === trainerID,
  );
