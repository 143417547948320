import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { api } from "src/lib/trainwellApi";

type Props = {
  open: boolean;
  onClose: () => void;
  trainerId: string;
};

export default function AssignTestClientsDialog({
  open,
  onClose,
  trainerId,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [testClients, setTestClients] = useState<number[]>([]);

  function resetAndClose() {
    onClose();

    setTestClients([]);
  }

  const isValid = testClients.length > 0;

  return (
    <Dialog onClose={resetAndClose} open={open} fullWidth maxWidth="sm">
      <DialogTitleWithClose onClose={resetAndClose}>
        Assign test clients
      </DialogTitleWithClose>
      <DialogContent>
        <Autocomplete
          fullWidth
          multiple
          disableCloseOnSelect
          options={[2, 3, 4, 5]}
          value={testClients}
          renderInput={(params) => (
            <TextField {...params} label="Client numbers" />
          )}
          onChange={(event: any, newValue) => {
            setTestClients(newValue as any);
          }}
          sx={{ my: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={resetAndClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          onClick={() => {
            api.trainers
              .assignTestClients(trainerId, testClients)
              .then((resp) => {
                if (resp.status !== "error") {
                  enqueueSnackbar("Assigned test clients", {
                    variant: "success",
                  });
                } else {
                  enqueueSnackbar("Error assigning test clients", {
                    variant: "error",
                  });
                }

                resetAndClose();
              });
          }}
        >
          Assign test clients
        </Button>
      </DialogActions>
    </Dialog>
  );
}
