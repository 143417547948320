import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";

type Props = {
  open: boolean;
  onClose: (confirmed: boolean) => void;
};

export default function ConfirmTerminateDialog({ open, onClose }: Props) {
  return (
    <Dialog
      onClose={() => {
        onClose(false);
      }}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleWithClose
        onClose={() => {
          onClose(false);
        }}
      >
        Terminate trainer
      </DialogTitleWithClose>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to terminate this trainer? This will disable
          their access to the dash, chat, and iOS app.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            onClose(true);
          }}
        >
          Terminate
        </Button>
      </DialogActions>
    </Dialog>
  );
}
