import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Audit, CoachVacation, Trainer } from "@trainwell/types";
import set from "lodash-es/set";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "./store";

export const fetchTrainerEdit = createAsyncThunk(
  "trainerEdit/fetchTrainerEdit",
  async (trainerID: string) => {
    const coach = await api.trainers.getOne(trainerID);
    return coach;
  },
);

export const fetchVacations = createAsyncThunk(
  "trainerEdit/fetchVacations",
  async (trainerId: string) => {
    const vacations = await api.vacations.getMany({ trainerId: trainerId });
    return vacations;
  },
);

export const fetchTrainerAudits = createAsyncThunk(
  "trainerEdit/fetchTrainerAudits",
  async (trainerId: string) => {
    const coachAudits = await api.audits.getCoachAudits(trainerId);
    const clientAudits = await api.audits.getClientAudits(trainerId, true);
    return [...coachAudits, ...clientAudits];
  },
);

export const createCoachAudit = createAsyncThunk(
  "trainerEdit/createCoachAudit",
  async (trainerId: string, { getState }) => {
    const state = getState() as RootState;

    const trainer = state.trainer.trainer;

    if (!trainer) {
      throw new Error("Trainer not found");
    }

    const response = await api.audits.createOne({
      subjectTrainerId: trainerId,
      auditorTrainerId: trainer.trainer_id,
    });

    return response;
  },
);

export const addVacation = createAsyncThunk(
  "trainerEdit/addVacation",
  async (data: {
    trainerId: string;
    startDate: number;
    endDate: number;
    type: CoachVacation["type"];
    clientMessage?: string;
    localDateToSend?: number;
    requiresInterimCoaches: boolean;
  }) => {
    const response = await api.vacations.create(data);
    return response;
  },
);

// Define a type for the slice state
interface TriainerEditState {
  trainer: Trainer | undefined;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
  vacations: CoachVacation[];
  vacationsStatus: "idle" | "loading" | "succeeded" | "failed";
  audits: (Audit & { client_full_name?: string })[];
  auditsStatus: "idle" | "loading" | "succeeded" | "failed";
}

// Define the initial state using that type
const initialState: TriainerEditState = {
  trainer: undefined,
  status: "idle",
  error: undefined,
  vacations: [],
  vacationsStatus: "idle",
  audits: [],
  auditsStatus: "idle",
};

export const trainerEditSlice = createSlice({
  name: "trainerEdit",
  initialState,
  reducers: {
    resetTrainerEdit: () => initialState,
    updateTrainerEdit: (
      state,
      action: PayloadAction<Partial<Trainer> & Pick<Trainer, "trainer_id">>,
    ) => {
      const update = action.payload;

      if (update.trainer_id === state.trainer?.trainer_id) {
        for (const [key, value] of Object.entries(update)) {
          set(state.trainer, key, value);
        }
      }
    },
    removeVacationFromCoach: (state, action: PayloadAction<string>) => {
      const vacationId = action.payload;

      const index = state.vacations.findIndex(
        (vacation) => vacation.id === vacationId,
      );

      if (index !== -1) {
        state.vacations.splice(index, 1);
      }
    },
    setVacationForCoach: (state, action: PayloadAction<CoachVacation>) => {
      const newVacation = action.payload;

      const index = state.vacations.findIndex(
        (vacation) => vacation.id === newVacation.id,
      );

      if (index !== -1) {
        state.vacations[index] = newVacation;
      }
    },
    updateAudit: (state, action: PayloadAction<Audit>) => {
      const audit = action.payload;

      const index = state.audits.findIndex((a) => a.id === audit.id);

      if (index !== -1) {
        state.audits[index] = audit;
      }
    },
    deleteAudit: (state, action: PayloadAction<string>) => {
      const auditId = action.payload;

      state.audits = state.audits.filter((audit) => audit.id !== auditId);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrainerEdit.pending, (state) => {
      state.status = "loading";

      state.trainer = undefined;
      state.vacations = [];
      state.vacationsStatus = "idle";
      state.audits = [];
      state.auditsStatus = "idle";
    });
    builder.addCase(fetchTrainerEdit.fulfilled, (state, action) => {
      console.log("Redux: Got coach to edit");
      state.status = "succeeded";

      const coach = action.payload;

      state.trainer = coach;
    });
    builder.addCase(fetchTrainerEdit.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(fetchVacations.pending, (state) => {
      state.vacationsStatus = "loading";
    });
    builder.addCase(fetchVacations.fulfilled, (state, action) => {
      console.log("Redux: Got coach vacations");
      state.vacationsStatus = "succeeded";

      const { vacations } = action.payload;

      vacations.sort((a, b) =>
        a.date_start < b.date_start ? 1 : b.date_start < a.date_start ? -1 : 0,
      );

      state.vacations = vacations;
    });
    builder.addCase(fetchVacations.rejected, (state) => {
      state.vacationsStatus = "failed";
    });
    builder.addCase(fetchTrainerAudits.pending, (state) => {
      state.auditsStatus = "loading";
    });
    builder.addCase(fetchTrainerAudits.fulfilled, (state, action) => {
      console.log("Redux: Got coach audits");
      state.auditsStatus = "succeeded";

      const audits = action.payload;

      audits.sort((a, b) =>
        a.date_created < b.date_created
          ? 1
          : b.date_created < a.date_created
            ? -1
            : 0,
      );

      state.audits = audits;
    });
    builder.addCase(fetchTrainerAudits.rejected, (state) => {
      state.auditsStatus = "failed";
    });
    builder.addCase(createCoachAudit.fulfilled, (state, action) => {
      const audit = action.payload;

      state.audits.unshift(audit);
    });
    builder.addCase(addVacation.fulfilled, (state, action) => {
      const vacation = action.payload;

      state.vacations.push(vacation);

      state.vacations.sort((a, b) =>
        a.date_start < b.date_start ? 1 : b.date_start < a.date_start ? -1 : 0,
      );
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetTrainerEdit,
  updateTrainerEdit,
  removeVacationFromCoach,
  setVacationForCoach,
  updateAudit,
  deleteAudit,
} = trainerEditSlice.actions;

export default trainerEditSlice.reducer;
