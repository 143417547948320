import { Box, CardActionArea, Typography, alpha } from "@mui/material";
import {
  testAnalyticsInfo,
  type TestAnalyticsInfoItem,
} from "@trainwell/types";
import { useParams } from "react-router-dom";

type Props = {
  productMetricId: string;
};

export function ProductMertricCell({ productMetricId }: Props) {
  const { productMetricId: queryProductMetricId } = useParams();

  const details = testAnalyticsInfo[productMetricId!] as TestAnalyticsInfoItem;

  const selected = queryProductMetricId === productMetricId;

  return (
    <CardActionArea
      href={`/admin/analytics/product-metrics/${productMetricId}`}
      sx={{
        p: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: 1,
        borderColor: "divider",
        backgroundColor: (theme) =>
          selected
            ? alpha(theme.palette.primary.main, 0.1)
            : theme.palette.background.paper,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            color: (theme) =>
              selected ? theme.palette.primary.main : undefined,
            fontWeight: selected ? "bold" : undefined,
          }}
        >
          {details.name}
        </Typography>
      </Box>
    </CardActionArea>
  );
}
