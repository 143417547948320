import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import type { WorkoutTimes } from "@trainwell/types";
import { addMinutes, format, parse, startOfToday } from "date-fns";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { updateClient } from "src/slices/clientSlice";
import SmartEditSwitch from "./SmartEditSwitch";

export default function ClientSchedule() {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.client.client!.user_id);
  const workoutTimes = useAppSelector(
    (state) => state.client.client!.workout_times,
  );
  const sendLateWorkoutNotifications = useAppSelector(
    (state) => state.client.client!.get_late_workout_notifications,
  );
  const [showTimes, setShowTimes] = useState(false);

  if (!workoutTimes) {
    return null;
  }

  const selectedDays = workoutTimes
    .map((day, index) => (day.has_time ? index : null))
    .filter((day) => day !== null) as number[];

  return (
    <Box>
      <Typography variant="overline" sx={{ textAlign: "left" }}>
        Workout schedule
      </Typography>
      <Card variant="outlined" sx={{ p: 1, width: "auto" }}>
        <ToggleButtonGroup
          value={selectedDays}
          aria-label="days"
          size="small"
          fullWidth
          color="primary"
          sx={{ mb: 0.5 }}
        >
          {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day, dayIndex) => (
            <ToggleButton
              key={dayIndex}
              value={dayIndex}
              aria-label={day}
              sx={{
                textTransform: "none",
                py: 0,
                fontWeight: selectedDays.includes(dayIndex) ? "bold" : "normal",
                opacity: selectedDays.includes(dayIndex) ? undefined : 0.66,
              }}
              onClick={() => {
                const newAvailables = JSON.parse(
                  JSON.stringify(workoutTimes),
                ) as WorkoutTimes[];

                if (newAvailables[dayIndex].has_time === true) {
                  newAvailables[dayIndex].time = null;
                  newAvailables[dayIndex].anytime = null;
                } else {
                  newAvailables[dayIndex].anytime = true;
                }

                newAvailables[dayIndex].has_time =
                  !newAvailables[dayIndex].has_time;

                dispatch(
                  updateClient({
                    user_id: userId,
                    workout_times: newAvailables,
                  }),
                );
              }}
            >
              {day}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {!showTimes ? (
          <Box>
            <Button
              startIcon={<ExpandMoreRoundedIcon />}
              onClick={() => {
                setShowTimes(true);
              }}
              size="small"
              variant="text"
              sx={{ py: 0.25 }}
            >
              Times
            </Button>
          </Box>
        ) : (
          <>
            <SmartEditSwitch
              label="Notify client about late workouts"
              value={sendLateWorkoutNotifications ?? false}
              onSave={(newValue) => {
                dispatch(
                  updateClient({
                    user_id: userId,
                    get_late_workout_notifications: newValue,
                  }),
                );
              }}
              sx={{ mb: 0.5 }}
            />
            {workoutTimes.map((day, i) => {
              if (workoutTimes[i].has_time === true) {
                return (
                  <Card
                    key={i}
                    variant="outlined"
                    sx={{
                      mb: 1,
                      p: 1,
                    }}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: "bold" }}
                        >
                          {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][i]}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <FormControl variant="outlined" size="small">
                          <InputLabel>Time</InputLabel>
                          <Select
                            MenuProps={{ style: { maxHeight: "300px" } }}
                            value={
                              day.anytime === true || !day.time
                                ? "Anytime"
                                : format(
                                    parse(day.time!, "HH:mm", new Date()),
                                    "h:mm aaa",
                                  )
                            }
                            onChange={(event) => {
                              const value = event.target.value as string;

                              let newAvailability: WorkoutTimes = {
                                has_time: true,
                                anytime: true,
                                time: null,
                              };

                              if (value !== "Anytime") {
                                newAvailability = {
                                  has_time: true,
                                  anytime: false,
                                  time: format(
                                    parse(value, "h:mm aaa", new Date()),
                                    "HH:mm",
                                  ),
                                };
                              }

                              const newAvailables = JSON.parse(
                                JSON.stringify(workoutTimes),
                              ) as WorkoutTimes[];

                              newAvailables[i] = newAvailability;

                              dispatch(
                                updateClient({
                                  user_id: userId,
                                  workout_times: newAvailables,
                                }),
                              );
                            }}
                            label="Time"
                          >
                            {[...Array(49).keys()].map((i) => {
                              const dateString = format(
                                addMinutes(startOfToday(), (i - 1) * 30),
                                "h:mm aaa",
                              );
                              if (i === 0) {
                                return (
                                  <MenuItem value={"Anytime"} key={i}>
                                    Anytime
                                  </MenuItem>
                                );
                              }
                              return (
                                <MenuItem value={dateString} key={i}>
                                  {dateString}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                );
              }
            })}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Button
                startIcon={<ExpandLessRoundedIcon />}
                onClick={() => {
                  setShowTimes(false);
                }}
                size="small"
                variant="text"
              >
                Hide times
              </Button>
              <Typography variant="overline">
                In client&apos;s timezone
              </Typography>
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
}
