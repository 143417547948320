import type { SxProps, Theme } from "@mui/material";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

type Props = {
  label: string;
  value: string | undefined;
  possibleValues: { value: string; label: string }[];
  onSave: (newValue: string) => void;
  sx?: SxProps<Theme>;
};

export default function SmartEditAutoComplete({
  label,
  value,
  possibleValues,
  onSave,
  sx = [],
}: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  function save(newValue: string) {
    onSave(newValue);
    setIsEditing(false);
  }

  return (
    <Box
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      sx={Array.isArray(sx) ? sx : [sx]}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
        <Typography variant="h6">{label}</Typography>
        <Box
          sx={{
            opacity: isHovering || isEditing ? undefined : 0,
            pointerEvents: isHovering || isEditing ? undefined : "none",
          }}
        >
          {isEditing ? (
            <Button
              variant="text"
              size="small"
              sx={{
                ml: 1,
                lineHeight: 1.25,
                px: 1,
                py: 0.5,
                minWidth: "0px",
              }}
              onClick={() => {
                setIsEditing(false);
              }}
            >
              Cancel
            </Button>
          ) : (
            <Button
              variant="text"
              size="small"
              sx={{
                ml: 1,
                lineHeight: 1.25,
                px: 1,
                py: 0.5,
                minWidth: "0px",
              }}
              onClick={() => {
                setIsEditing(true);
              }}
            >
              Edit
            </Button>
          )}
        </Box>
      </Box>
      {isEditing ? (
        <Autocomplete
          size="small"
          options={possibleValues}
          renderInput={(params) => <TextField {...params} />}
          getOptionLabel={(option) => option.label}
          value={possibleValues.find((option) => option.value === value)}
          onChange={(event: any, newValue) => {
            if (newValue) {
              save(newValue.value);
            }
          }}
        />
      ) : (
        <Typography>
          {value === undefined
            ? "None"
            : (possibleValues.find((item) => item.value === value)?.label ??
              "Error")}
        </Typography>
      )}
    </Box>
  );
}
