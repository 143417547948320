import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import MoneyOffRoundedIcon from "@mui/icons-material/MoneyOffRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import ConfirmationDialog from "src/components/misc/ConfirmationDialog";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { updateAllClients } from "src/slices/allClientsSlice";
import {
  fetchClientEdit,
  revokeFree,
  scheduleToDelete,
  setClientEdit,
} from "src/slices/clientEditSlice";
import DeleteNowDialog from "./DeleteNowDialog";
import ExtendPauseDialog from "./ExtendPauseDialog";
import SuspendDialog from "./SuspendDialog";

type Props = {
  userId: string;
};

export function ClientMoreButton({ userId }: Props) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const clientEditStatus = useAppSelector((state) => state.clientEdit.status);
  const client = useAppSelector((state) => state.clientEdit.client);
  const [isMakeFreeDialogOpen, setIsMakeFreeDialogOpen] = useState(false);
  const [isSuspendDialogOpen, setIsSuspendDialogOpen] = useState(false);
  const [revokeFreeDialogOpen, setRevokeFreeDialogOpen] = useState(false);
  const [extendPauseDialogOpen, setExtendPauseDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteNowDialogOpen, setDeleteNowDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (clientEditStatus === "idle" && userId) {
      dispatch(fetchClientEdit(userId));
    }
  }, [clientEditStatus, dispatch, userId]);

  if (!client) {
    return <LoadingPage message="Getting your client" />;
  }

  return (
    <Box>
      <IconButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <MoreVertRoundedIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {client.account.plan.price === 0 &&
          client.account.membership.state === "active" && (
            <Tooltip
              title="Has a confirmation dialog"
              disableInteractive
              placement="left"
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);

                  setRevokeFreeDialogOpen(true);
                }}
              >
                <ListItemIcon>
                  <ClearRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={"Revoke free access"} />
              </MenuItem>
            </Tooltip>
          )}
        {client.account.plan.price !== 0 &&
          !client.account.membership.date_deleted && (
            <Tooltip
              title="Has a confirmation dialog"
              disableInteractive
              placement="left"
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);

                  setIsMakeFreeDialogOpen(true);
                }}
              >
                <ListItemIcon>
                  <MoneyOffRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={"Make free"} />
              </MenuItem>
            </Tooltip>
          )}
        {client.account.plan.price !== 0 &&
          !client.account.membership.date_deleted &&
          client.account.membership.state === "active" &&
          !client.account.membership.suspension && (
            <Tooltip
              title="Has a confirmation dialog"
              disableInteractive
              placement="left"
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);

                  setIsSuspendDialogOpen(true);
                }}
              >
                <ListItemIcon>
                  <StopRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={"Suspend"} />
              </MenuItem>
            </Tooltip>
          )}
        {client.account.membership.date_pause_will_end && (
          <Tooltip
            title="Has a confirmation dialog"
            disableInteractive
            placement="left"
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);

                setExtendPauseDialogOpen(true);
              }}
            >
              <ListItemIcon>
                <PauseRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={"Extend pause"} />
            </MenuItem>
          </Tooltip>
        )}
        {!client.account.membership.date_requested_deletion && (
          <Tooltip
            title="Has a confirmation dialog"
            disableInteractive
            placement="left"
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);

                setDeleteDialogOpen(true);
              }}
            >
              <ListItemIcon>
                <DeleteForeverRoundedIcon color="error" fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={"Schedule to delete"}
                sx={{
                  color: (theme) => theme.palette.error.main,
                }}
              />
            </MenuItem>
          </Tooltip>
        )}
        {!client.account.membership.date_deleted && (
          <Tooltip
            title="Has a confirmation dialog"
            disableInteractive
            placement="left"
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);

                setDeleteNowDialogOpen(true);
              }}
            >
              <ListItemIcon>
                <DeleteForeverRoundedIcon color="error" fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={"Delete now"}
                sx={{
                  color: (theme) => theme.palette.error.main,
                }}
              />
            </MenuItem>
          </Tooltip>
        )}
      </Menu>
      <ExtendPauseDialog
        userId={client.user_id}
        open={extendPauseDialogOpen}
        onClose={() => {
          setExtendPauseDialogOpen(false);
        }}
      />
      <DeleteNowDialog
        userId={client.user_id}
        defaultIsTestUser={client.test_user}
        open={Boolean(deleteNowDialogOpen)}
        onClose={() => {
          setDeleteNowDialogOpen(false);
        }}
      />
      <ConfirmationDialog
        open={Boolean(deleteDialogOpen)}
        onClose={(didConfirm) => {
          if (didConfirm) {
            dispatch(scheduleToDelete(client.user_id))
              .unwrap()
              .then(() => {
                enqueueSnackbar("Deleted", {
                  variant: "error",
                });
              })
              .catch(() => {
                enqueueSnackbar("Error deleting", {
                  variant: "error",
                });
              });
          }

          setDeleteDialogOpen(false);
        }}
        variant="error"
        title="Schedule to delete"
        message={`This will immediately cancel the client and remove their access to the app. Their data will be deleted within 30 days in compliance with the GDPR.`}
        confirmButtonText="Cancel and request deletion"
      />
      <Dialog
        onClose={() => {
          setIsMakeFreeDialogOpen(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={isMakeFreeDialogOpen}
      >
        <DialogTitleWithClose
          onClose={() => {
            setIsMakeFreeDialogOpen(false);
          }}
        >
          Convert client to a free plan?
        </DialogTitleWithClose>
        <DialogContent>
          <Typography sx={{ mb: 1 }}>
            <b>{client.full_name}&apos;s</b> subscription will be canceled and
            they&apos;ll be marked as free so they no longer need to pay to use
            trainwell.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setIsMakeFreeDialogOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              const { client: newClient } = await api.clients.setFree(userId);
              dispatch(updateAllClients(newClient));
              dispatch(setClientEdit(newClient));

              enqueueSnackbar("Converted to a free account", {
                variant: "success",
              });
            }}
          >
            Convert to a free plan
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={Boolean(revokeFreeDialogOpen)}
        onClose={(didConfirm) => {
          if (didConfirm) {
            dispatch(revokeFree(client.user_id));
          }

          setRevokeFreeDialogOpen(false);
        }}
        variant="error"
        title="Revoke free access"
        message={`This will remove ${client.full_name}'s access to trainwell immediatly. They will need to re-do onboarding and pay to sign up again.`}
        confirmButtonText="Revoke free access"
      />
      <SuspendDialog
        open={isSuspendDialogOpen}
        onClose={() => {
          setIsSuspendDialogOpen(false);
        }}
        userId={client.user_id}
      />
    </Box>
  );
}
