import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { DialogTitleWithClose } from "./DialogTitleWithClose";

type Props = {
  open: boolean;
  onClose: () => void;
  title?: string;
  message?: string;
};

export default function ErrorDialog({ open, onClose, title, message }: Props) {
  return (
    <Dialog
      onClose={() => {
        onClose();
      }}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleWithClose
        onClose={() => {
          onClose();
        }}
      >
        {title ?? "Something went wrong"}
      </DialogTitleWithClose>
      <DialogContent>
        <Typography>
          {message ?? "The dash encountered an error. Let Reece know."}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onClose();
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
