import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, Button, Card, Stack, Tooltip, Typography } from "@mui/material";
import type { Client } from "@trainwell/types";
import { format, isBefore } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import Label from "src/components/misc/Label";
import SmartEditSelect from "src/components/misc/SmartEditSelect";
import SmartEditSwitch from "src/components/misc/SmartEditSwitch";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getBrand, getCardState, getClientSummary } from "src/lib/client";
import { api } from "src/lib/trainwellApi";
import { fetchClientEdit } from "src/slices/clientEditSlice";
import { updateClient } from "src/slices/clientSlice";

type Props = {
  userId: string;
};

const cardStates: Client["account"]["card"]["state"][] = [
  "invalid_card",
  "no_card",
  "valid_card",
];

const brands: Client["account"]["plan"]["brand"][] = [
  "af",
  "copilot",
  "echelon",
];

export function ClientDetailSidebar({ userId }: Props) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const clientEditStatus = useAppSelector((state) => state.clientEdit.status);
  const client = useAppSelector((state) => state.clientEdit.client);

  useEffect(() => {
    if (clientEditStatus === "idle" && userId) {
      dispatch(fetchClientEdit(userId));
    }
  }, [clientEditStatus, dispatch, userId]);

  if (!client) {
    return <LoadingPage message="Getting your client" />;
  }

  return (
    <Box>
      <Card variant="outlined" sx={{ p: 2, mb: 2 }}>
        <Typography>{getClientSummary(client.account).text}</Typography>
      </Card>
      <Card variant="outlined" sx={{ p: 2, mb: 2 }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Quick links
        </Typography>
        <Stack spacing={2}>
          {client.account.membership.stripe_customer_id && (
            <Button
              fullWidth
              target="_blank"
              href={`https://dashboard.stripe.com/customers/${client.account.membership.stripe_customer_id}`}
              LinkComponent={"a"}
              endIcon={<OpenInNewRoundedIcon />}
              size="small"
            >
              Stripe dash
            </Button>
          )}
          <Button
            fullWidth
            endIcon={<OpenInNewRoundedIcon />}
            size="small"
            onClick={() => {
              api.clients
                .getAccountSession(userId)
                .then((data) => {
                  window.open(data.url, "_blank");
                })
                .catch(() => {
                  enqueueSnackbar("Error getting billing portal url", {
                    variant: "error",
                  });
                });
            }}
          >
            Billing portal
          </Button>
        </Stack>
      </Card>
      <Card variant="outlined" sx={{ p: 2, mb: 2 }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Quick controls
        </Typography>
        <Tooltip
          title="Hides client and chat from trainer. Becomes unhidden when they log in"
          disableInteractive
        >
          <span>
            <SmartEditSwitch
              label="Hidden"
              value={client.account.dashboard.is_hidden}
              onSave={(value) => {
                dispatch(
                  updateClient({
                    user_id: userId,
                    // @ts-expect-error
                    "account.dashboard.is_hidden": value,
                  }),
                );
              }}
              disabled={Boolean(client.account.membership.date_deleted)}
            />
          </span>
        </Tooltip>
        <Tooltip
          title="Sends client's requests to our experimental dev server"
          disableInteractive
        >
          <span>
            <SmartEditSwitch
              label="Debugger"
              value={client.is_debugger ?? false}
              onSave={(value) => {
                dispatch(updateClient({ user_id: userId, is_debugger: value }));
              }}
              disabled={Boolean(client.account.membership.date_deleted)}
            />
          </span>
        </Tooltip>
        <Tooltip
          title="Sends client's requests to our stable cab server"
          disableInteractive
        >
          <span>
            <SmartEditSwitch
              label="Cab"
              value={client.is_cab ?? false}
              onSave={(value) => {
                dispatch(updateClient({ user_id: userId, is_cab: value }));
              }}
              disabled={Boolean(client.account.membership.date_deleted)}
            />
          </span>
        </Tooltip>
        <Tooltip
          title="Gives them a crown and sets up additional logging"
          disableInteractive
        >
          <span>
            <SmartEditSwitch
              label="VIP"
              value={client.is_vip ?? false}
              onSave={(value) => {
                dispatch(updateClient({ user_id: userId, is_vip: value }));
              }}
              disabled={Boolean(client.account.membership.date_deleted)}
            />
          </span>
        </Tooltip>
        <Tooltip
          title="Prevents client from renewing a cancelled subscription, downloading workouts, and sending messages"
          disableInteractive
        >
          <span>
            <SmartEditSwitch
              label="Banned"
              value={client.account.membership.is_banned ?? false}
              onSave={(value) => {
                dispatch(
                  updateClient({
                    user_id: userId,
                    // @ts-expect-error
                    "account.membership.is_banned": value,
                  }),
                );
              }}
              disabled={Boolean(client.account.membership.date_deleted)}
            />
          </span>
        </Tooltip>
        <Tooltip
          title="Prevents client from being able to switch trainers manually"
          disableInteractive
        >
          <span>
            <SmartEditSwitch
              label="Disable manual trainer switching"
              value={client.disable_manual_coach_switching ?? false}
              onSave={(value) => {
                dispatch(
                  updateClient({
                    user_id: userId,
                    disable_manual_coach_switching: value,
                  }),
                );
              }}
              disabled={Boolean(client.account.membership.date_deleted)}
            />
          </span>
        </Tooltip>
        <Tooltip
          title="Excludes client from metrics and compensation"
          disableInteractive
        >
          <span>
            <SmartEditSwitch
              label="Test client"
              value={client.test_user ?? false}
              onSave={(value) => {
                dispatch(
                  updateClient({
                    user_id: userId,
                    test_user: value,
                  }),
                );
              }}
              disabled={Boolean(client.account.membership.date_deleted)}
            />
          </span>
        </Tooltip>
        <SmartEditSwitch
          label="Don't penalize compensation"
          value={client.dont_penalize_compensation ?? false}
          onSave={(value) => {
            dispatch(
              updateClient({
                user_id: userId,
                dont_penalize_compensation: value,
              }),
            );
          }}
          disabled={Boolean(client.account.membership.date_deleted)}
        />
        <Tooltip
          title="trainwell will NEVER send an SMS to this client, automated or otherwise"
          disableInteractive
        >
          <span>
            <SmartEditSwitch
              label="Disable SMS"
              value={client.settings.disable_sms ?? false}
              onSave={(value) => {
                dispatch(
                  updateClient({
                    user_id: userId,
                    // @ts-expect-error
                    "settings.disable_sms": value,
                  }),
                );
              }}
              disabled={Boolean(client.account.membership.date_deleted)}
            />
          </span>
        </Tooltip>
      </Card>
      <Card variant="outlined" sx={{ p: 2, mb: 2 }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Account
        </Typography>
        {client.account.membership.date_account_created && (
          <Label
            label="Account created"
            content={format(
              new Date(client.account.membership.date_account_created),
              "MMMM do, yyyy h:mm aaa",
            )}
          />
        )}
        {client.account.membership.date_membership_started && (
          <Label
            label="Membership started"
            content={format(
              new Date(client.account.membership.date_membership_started),
              "MMMM do, yyyy h:mm aaa",
            )}
          />
        )}
        {client.account.plan.date_trial_end && (
          <Label
            label={
              isBefore(new Date(), new Date(client.account.plan.date_trial_end))
                ? "Trial ends"
                : "Trial ended"
            }
            content={format(
              new Date(client.account.plan.date_trial_end),
              "MMMM do, yyyy h:mm aaa",
            )}
          />
        )}
        <Label
          label="Onboarded"
          content={
            client.account.dashboard.date_onboarded
              ? format(
                  new Date(client.account.dashboard.date_onboarded),
                  "MMMM do, yyyy h:mm aaa",
                )
              : "Not onboarded yet"
          }
        />
        <SmartEditSelect
          label="Brand"
          value={client.account.plan.brand}
          possibleValues={brands.map((state) => {
            return { value: state, label: getBrand(state) };
          })}
          onSave={(value) => {
            dispatch(
              updateClient({
                user_id: userId,
                // @ts-expect-error
                "account.plan.brand": value,
              }),
            );
          }}
        />
        <SmartEditSelect
          label="Card status"
          value={client.account.card.state}
          possibleValues={cardStates.map((state) => {
            return { value: state, label: getCardState(state) };
          })}
          onSave={(value) => {
            dispatch(
              updateClient({
                user_id: userId,
                // @ts-expect-error
                "account.card.state": value,
              }),
            );
          }}
        />
      </Card>
    </Box>
  );
}
