import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { Fade, Paper, Popper, Typography } from "@mui/material";
import type { CoachMetricTemplateSubmodule } from "@trainwell/types";
import React, { useState } from "react";

const styles: Record<string, React.CSSProperties> = {
  hoverTarget: {
    fontSize: "18px",
    margin: "auto 0 auto 4px",
    color: "light blue",
  },
};

type Props = {
  template: CoachMetricTemplateSubmodule;
};

export default function Info({ template }: Props) {
  const [info, setInfo] = useState<HTMLDivElement | null>(null);
  const [openInfo, setOpenInfo] = useState(false);

  const handleOpenInfo = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setInfo(e.currentTarget);
    setOpenInfo(true);
  };

  const handleCloseInfo = () => {
    setInfo(null);
    setOpenInfo(false);
  };

  if (template.explainer_text === null) {
    return null;
  }

  return (
    <div
      style={styles.hoverTarget}
      aria-owns={openInfo ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onMouseEnter={handleOpenInfo}
      onMouseLeave={handleCloseInfo}
    >
      <InfoRoundedIcon sx={{ fontSize: 18 }} />
      <Popper
        open={openInfo}
        anchorEl={info}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper style={{ padding: "12px", maxWidth: "200px" }}>
              <Typography style={{ fontSize: "14px" }}>
                {template.explainer_text}
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
