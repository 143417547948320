import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPhaseTemplateFolderById } from "src/slices/phaseTemplatesSlice";

type Props = {
  folderId: string;
};

export function FolderDragOverlay({ folderId }: Props) {
  const folder = useAppSelector((state) =>
    selectPhaseTemplateFolderById(state, folderId),
  );

  if (!folder) {
    return <Typography>Error</Typography>;
  }

  return (
    <Box
      sx={{
        border: 1,
        borderColor: "divider",
        borderRadius: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
        boxShadow: 7,
        display: "inline-block",
        p: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FolderRoundedIcon
          sx={{
            mr: 1,
            fontSize: 20,
            color: (theme) => theme.palette.text.secondary,
          }}
        />
        <Typography sx={{ whiteSpace: "nowrap" }}>{folder.name}</Typography>
      </Box>
    </Box>
  );
}
