import AccessibilityRoundedIcon from "@mui/icons-material/AccessibilityRounded";
import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";
import DirectionsRunRoundedIcon from "@mui/icons-material/DirectionsRunRounded";
import EmojiPeopleRoundedIcon from "@mui/icons-material/EmojiPeopleRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import LocalHospitalRoundedIcon from "@mui/icons-material/LocalHospitalRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import PanToolRoundedIcon from "@mui/icons-material/PanToolRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PregnantWomanRoundedIcon from "@mui/icons-material/PregnantWomanRounded";
import PsychologyRoundedIcon from "@mui/icons-material/PsychologyRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import SelfImprovementRoundedIcon from "@mui/icons-material/SelfImprovementRounded";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentSatisfiedRoundedIcon from "@mui/icons-material/SentimentSatisfiedRounded";
import SportsHandballRoundedIcon from "@mui/icons-material/SportsHandballRounded";
import TransgenderRoundedIcon from "@mui/icons-material/TransgenderRounded";
import VolunteerActivismRoundedIcon from "@mui/icons-material/VolunteerActivismRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import type {
  TagCommunicationStyle as TagCommunicationStyleType,
  TagExperience as TagExperienceType,
  TagSpecialty as TagSpecialtyType,
} from "@trainwell/types";
import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "src/hooks/stateHooks";

const experienceData: {
  label: string;
  key: TagExperienceType;
  icon: JSX.Element;
}[] = [
  {
    label: "Older populations",
    key: "older_clients",
    icon: <SentimentSatisfiedRoundedIcon />,
  },
  {
    label: "LGBTQ+",
    key: "lgbtq",
    icon: <TransgenderRoundedIcon />,
  },
  {
    label: "Pre/postnatal mothers",
    key: "pre_postnatal",
    icon: <PregnantWomanRoundedIcon />,
  },
  { label: "Plus sized", key: "obesity", icon: <AccessibilityRoundedIcon /> },
  { label: "ADHD", key: "adhd", icon: <PsychologyRoundedIcon /> },
  { label: "Anxiety", key: "anxiety", icon: <PsychologyRoundedIcon /> },
  { label: "Depression", key: "depression", icon: <PsychologyRoundedIcon /> },
  {
    label: "Body dysmorphia",
    key: "body_dysmorphia",
    icon: <AccessibilityRoundedIcon />,
  },
  {
    label: "Auto-immunity",
    key: "auto_immunity",
    icon: <LocalHospitalRoundedIcon />,
  },
  {
    label: "No preference",
    key: "none",
    icon: <RemoveCircleRoundedIcon />,
  },
];

const specialtiesData: {
  label: string;
  key: TagSpecialtyType;
  icon: JSX.Element;
}[] = [
  {
    label: "Weight loss",
    key: "weight_loss",
    icon: <AccessibilityRoundedIcon />,
  },
  {
    label: "Eating healthier",
    key: "eat_healthy",
    icon: <RestaurantRoundedIcon />,
  },
  {
    label: "Mobility & flexibility",
    key: "mobility_flexibility",
    icon: <EmojiPeopleRoundedIcon />,
  },
  {
    label: "Mental wellness",
    key: "mental_wellness",
    icon: <SelfImprovementRoundedIcon />,
  },
  {
    label: "Reducing fatigue",
    key: "reduce_fatigue",
    icon: <DirectionsRunRoundedIcon />,
  },
  {
    label: "Quality of life",
    key: "quality_of_life",
    icon: <SentimentSatisfiedAltRoundedIcon />,
  },
  {
    label: "Strength training",
    key: "strength",
    icon: <FitnessCenterRoundedIcon />,
  },
  {
    label: "Sports performance",
    key: "sports",
    icon: <SportsHandballRoundedIcon />,
  },
  {
    label: "General health",
    key: "general_health",
    icon: <FavoriteRoundedIcon />,
  },
  {
    label: "No preference",
    key: "none",
    icon: <RemoveCircleRoundedIcon />,
  },
];

const communicationStyleData: {
  key: TagCommunicationStyleType;
  leftText: string;
  leftIcon: JSX.Element;
  rightText: string;
  rightIcon: JSX.Element;
}[] = [
  {
    key: "fun_to_focused",
    leftText: "Fun",
    leftIcon: <CelebrationRoundedIcon />,
    rightText: "Focused",
    rightIcon: <MenuBookRoundedIcon />,
  },
  {
    key: "empathetic_to_tough_love",
    leftText: "Empathetic",
    leftIcon: <VolunteerActivismRoundedIcon />,
    rightText: "Tough love",
    rightIcon: <PanToolRoundedIcon />,
  },
  {
    key: "emotional_to_analytical",
    leftText: "Emotional",
    leftIcon: <FavoriteRoundedIcon />,
    rightText: "Analytical",
    rightIcon: <SchoolRoundedIcon />,
  },
  {
    key: "low_touch_to_high_touch",
    leftText: "Low touch",
    leftIcon: <PersonRoundedIcon />,
    rightText: "High touch",
    rightIcon: <PeopleRoundedIcon />,
  },
];

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

type Props = {
  userId: string;
  defaultExpanded?: boolean;
  freeFloating?: boolean;
};

type Tag = { label: string; color: string; icon: JSX.Element }[];

export default function TagsPanel({
  userId,
  defaultExpanded = false,
  freeFloating = false,
}: Props) {
  const onboardingTags = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? state.client.client.onboarding_tags
        : undefined,
    shallowEqual,
  );
  const [specialties, setSpecialties] = useState<Tag>([]);
  const [experience, setExperience] = useState<Tag>([]);
  const [communicationStyle, setCommunicationStyle] = useState<
    {
      labelLeft: string;
      labelRight: string;
      iconLeft: JSX.Element;
      iconRight: JSX.Element;
      value: number;
    }[]
  >([]);

  useEffect(() => {
    // const newIdentity = []
    //   if (
    //     onboardingTags?.identity.includes("language_spanish")
    //   ) {
    //     newIdentity.push({
    //       label: "Speaks Spanish",
    //       color: "#FFE0B2",
    //       icon: <PublicRoundedIcon />,
    //     });
    //   }

    const newSpecialties: typeof specialties = [];

    onboardingTags?.specialties.forEach((tag) => {
      newSpecialties.push({
        label: specialtiesData.find((data) => data.key === tag)?.label ?? "",
        color: "#FFE0B2",
        icon: specialtiesData.find((data) => data.key === tag)?.icon ?? <></>,
      });
    });

    setSpecialties(newSpecialties);

    const newExperience: typeof experience = [];

    onboardingTags?.experience.forEach((tag) => {
      newExperience.push({
        label: experienceData.find((data) => data.key === tag)?.label ?? "",
        color: "#FFE0B2",
        icon: experienceData.find((data) => data.key === tag)?.icon ?? <></>,
      });
    });

    setExperience(newExperience);

    const newCommunicationStyle: typeof communicationStyle = [];

    onboardingTags?.communication_style.forEach((tag) => {
      newCommunicationStyle.push({
        labelLeft:
          communicationStyleData.find((data) => data.key === tag.key)
            ?.leftText ?? "",
        labelRight:
          communicationStyleData.find((data) => data.key === tag.key)
            ?.rightText ?? "",
        iconLeft: communicationStyleData.find((data) => data.key === tag.key)
          ?.leftIcon ?? <></>,
        iconRight: communicationStyleData.find((data) => data.key === tag.key)
          ?.rightIcon ?? <></>,
        value: tag.value * 4,
      });
    });

    setCommunicationStyle(newCommunicationStyle);
  }, [onboardingTags]);

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      disabled={!onboardingTags}
      disableGutters
      elevation={freeFloating ? undefined : 0}
      square={!freeFloating}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">👩‍🎤 Tags</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <>
          {specialties.length >= 1 && (
            <>
              <Typography variant="h3" sx={{ fontWeight: "bold", mb: 1 }}>
                Requested specialties
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0,
                  m: 0,
                }}
                component="ul"
              >
                {specialties.map((data) => {
                  return (
                    <ListItem key={data.label}>
                      <Chip icon={data.icon} label={data.label} />
                    </ListItem>
                  );
                })}
              </Box>
            </>
          )}
          {experience.length >= 1 && (
            <>
              <Typography
                variant="h3"
                sx={{ fontWeight: "bold", mb: 1, mt: 4 }}
              >
                Requested experience
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0,
                  m: 0,
                }}
                component="ul"
              >
                {experience.map((data) => {
                  return (
                    <ListItem key={data.label}>
                      <Chip icon={data.icon} label={data.label} />
                    </ListItem>
                  );
                })}
              </Box>
            </>
          )}
          {experience.length >= 1 && (
            <>
              <Typography
                variant="h3"
                sx={{ fontWeight: "bold", mb: 1, mt: 4 }}
              >
                Preferred communication styles
              </Typography>
              {communicationStyle.map((tag, index) => (
                <Box key={index} sx={{ my: 2 }}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Stack spacing={2} direction="row" alignItems="center">
                        {tag.iconLeft}
                        <Typography>{tag.labelLeft}</Typography>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        justifyContent: "flex-end",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography sx={{ textAlign: "right" }}>
                          {tag.labelRight}
                        </Typography>
                        {tag.iconRight}
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <FormControl
                          component="fieldset"
                          fullWidth
                          hiddenLabel
                          margin="none"
                          disabled
                        >
                          <RadioGroup
                            row
                            name="row-radio-buttons-group"
                            value={tag.value}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label=""
                              sx={{ m: 0 }}
                            />
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label=""
                              sx={{ m: 0 }}
                            />
                            <FormControlLabel
                              value={2}
                              control={<Radio />}
                              label=""
                              sx={{ m: 0 }}
                            />
                            <FormControlLabel
                              value={3}
                              control={<Radio />}
                              label=""
                              sx={{ m: 0 }}
                            />
                            <FormControlLabel
                              value={4}
                              control={<Radio />}
                              label=""
                              sx={{ m: 0 }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </>
          )}
        </>
      </AccordionDetails>
    </Accordion>
  );
}
